import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { DFPAPIEndpoint } from "../../../utils/axios";
import { ControlPanelView } from "./PanelView";
import { sorter, ItemGridView, MainColumns, Columns, EstimateTitle, EmainOrDownload } from "./Cards";
import moment from "moment-timezone";

const TABS = ['details']

const Intent = (props) => {
  const { scheduledTimeOfExecution, relativeExecutionTime, executionTimeModifier, id } = props
  return (
    <div className='flexRow alert alert-info space-btn hor-ctr intent' onClick={() => props.onClick(id)}>
      <span>
        {moment(scheduledTimeOfExecution).format('YYYY-MM-DD HH:mm')} - {relativeExecutionTime}
        {Boolean(executionTimeModifier) && ` (${executionTimeModifier})`}
      </span>
      <EmainOrDownload />
    </div>
  )
}

const CatererIntents = (props) => {
  const { intents, selectedGroup } = props
  const { t } = useTranslation();
  const showIntents = Boolean(selectedGroup) && Boolean(selectedGroup.isSlot)
  const selectedIntents = showIntents && (intents[selectedGroup.model.loc][selectedGroup.model.slot] || [])
  return (
    <div className="flexCol full-flex scrollHide">
      <div className="flexCol flex-ctr hor-ctr w-100 title h4">{t(`pages.controlPanel.views.caterer.alertTitle`)}</div>
      {
        showIntents ?
          <div className="flexCol full-flex scrollY">
            {
              selectedIntents.map((intent, i) => {
                return (
                  <>
                    <Intent key={intent.id}  {...intent} onClick={props.onIntentClick} />
                    {Boolean(intent.delta) && <Intent key={intent.delta.id}  {...{ ...intent.delta, id: intent.id }} onClick={(id) => props.onIntentClick(id, true)} />}
                  </>
                )
              })
            }
          </div>
          :
          <div className="flexCol full-flex flex-ctr hor-ctr">
            <h6 className="h8">{t(`pages.controlPanel.views.caterer.selectGroupForIntents`)}</h6>
          </div>
      }
    </div>
  )
}
// const Columns = [
//   { width: 100, field: 'quantity', headerName: 'pages.controlPanel.views.groupTableColumns.total' },
//   { width: 100, field: 'corporateQuantity', headerName: 'pages.controlPanel.views.groupTableColumns.corporate' },
//   { field: 'webMealQuantity', headerName: 'pages.controlPanel.views.groupTableColumns.preBooked' },
//   { field: 'bobQuantity', headerName: 'pages.controlPanel.views.groupTableColumns.bob' },
// ]
const IntentColumns = [
  { width: 100, field: 'quantity', headerName: 'pages.controlPanel.views.groupTableColumns.total' },
  { width: 100, field: 'previous', headerName: 'pages.controlPanel.views.groupTableColumns.previous' },
  { width: 100, field: 'delta', headerName: 'pages.controlPanel.views.groupTableColumns.previous' },
]
// eslint-disable-next-line no-unused-vars
const ConfirmedCol = [
  { field: 'bobQuantityModified', headerName: 'pages.controlPanel.views.groupTableColumns.confimedBOB' },
]
const CaterCard = (props) => {
  const { model } = props;
  const { t } = useTranslation();
  return (
    <div className="flexCol white-bg carter-card" onClick={props.onClick}>
      <div className="flexRow hor-ctr heading">
        <h6 className="sl-no">{props.slNo}</h6>
        <div className="flexCol full-flex">
          <div className="flexRow hor-ctr">
            <h5 className="h6">{model.code} <code className="small-button-text-regular">{model.slotName}</code></h5>
          </div>
          <h4 className="h7">{model.plan} <code>{moment(model.startTime).tz(moment.tz.guess()).format("DD-MM-YYYY hh:mm A zz")}</code></h4>
          <div className="flexRow">
            <span className="crop">{t(`pages.controlPanel.views.catering.pax`)}: <b>{model.estimateAggregate.pax}</b></span>
            <span className="crop">{t(`pages.controlPanel.views.catering.bc`)}: <b>{model.estimateAggregate.bcPax}</b></span>
            <span className="crop">{t(`pages.controlPanel.views.catering.pc`)}: <b>{model.estimateAggregate.pcPax}</b></span>
            <span className="crop">{t(`pages.controlPanel.views.catering.ecy`)}: <b>{model.estimateAggregate.ecyPax}</b></span>
          </div>
        </div>
      </div>

    </div>
  )
}

export const ControlPanelCaterer = (props) => {
  const { t } = useTranslation();
  const { selectedDate, show } = props;
  const [state, setState] = useState({ tab: 'details', batches: [] })
  useEffect(() => {
    fetchCateringBatches()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])
  const fetchCateringBatches = async () => {
    try {
      const body = { date: moment(selectedDate).startOf('day').format() }
      const res = await DFPAPIEndpoint.post('operations/plugins/caterer-service/actions/listCateringBatches', body);
      let batches = [], slotIntents = {};
      if (res && res.items) {
        batches = {};
        res.items.forEach((item) => {
          const { intents, airport, slot } = item
          const { label } = slot
          item.cateringGroups.forEach((catGroup, i) => {
            const locationCode = catGroup.airport.locationCode
            if (!batches[locationCode]) batches[locationCode] = {};
            if (!batches[locationCode][label]) batches[locationCode][label] = [];
            batches[locationCode][label].push({
              id: `${i}${catGroup.plan}`, name: catGroup.code,
              model: {
                plan: catGroup.plan,
                startTime: catGroup.startTime,
                date: catGroup.date,
                code: catGroup.code,
                locationCode: locationCode,
                cateringGroupId: catGroup.id,
                groupId: catGroup.groupId,
                slot: label,
                slotName: t('pages.controlPanel.views.caterer.batchName', { batch: label }),
                estimateAggregate: catGroup.estimateAggregate
              }
            })
          })
          if (!slotIntents[airport.locationCode]) slotIntents[airport.locationCode] = {};
          if (!slotIntents[airport.locationCode][label]) slotIntents[airport.locationCode][label] = [];
          slotIntents[airport.locationCode][label] = slotIntents[airport.locationCode][label].concat(intents)
        });
        batches = Object.keys(batches).map((loc, i) => {
          const children = Object.keys(batches[loc]).map((slot, j) => {
            return { id: `${j}${loc}${slot}`, name: slot, children: batches[loc][slot], type: 'expand', svg: 'folder', isSlot: true, model: { loc: loc, slot: slot } }
          })
          children.sort(sorter)
          return { id: `${i}${loc}`, name: loc, children: children, type: 'expand', model: { loc: loc } }
        })
        batches.sort(sorter)
      }
      setState((_) => ({ ..._, batches: batches, selectedGroup: null, intents: slotIntents }))
    } catch (error) {
      console.log(error);
    }
  }
  const getCateringBatches = () => {
    let cateringGroups = [];
    const { selectedGroup, batches } = state;
    if (selectedGroup) {
      batches.every((loc) => {
        let shouldContinue = true;
        if (selectedGroup.id === 'root') {
          loc.children.forEach((slot) => {
            cateringGroups = cateringGroups.concat(slot.children)
          })
        } else if (selectedGroup.id === loc.id) {
          selectedGroup.children.forEach((slot) => {
            cateringGroups = cateringGroups.concat(slot.children)
          })
          shouldContinue = false
        } else {
          loc.children.every((slot) => {
            let innerContinue = true;
            if (slot.id === selectedGroup.id) {
              innerContinue = false;
              shouldContinue = false
              cateringGroups = cateringGroups.concat(slot.children)
            } else {
              let cg = slot.children.find((g) => g.id === selectedGroup.id);
              if (cg) {
                innerContinue = false;
                shouldContinue = false;
                cateringGroups.push(cg)
              }
            }
            return innerContinue
          })
        }
        return shouldContinue
      })
    }
    return cateringGroups
  }
  const handleBatchClick = async (group) => {
    try {
      const { cateringGroupId, groupId } = group.model;
      const body = { cateringGroupId, groupId }
      let status = await DFPAPIEndpoint.post('operations/cateringGroups/status', body)
      if (!status) {
        status = { items: [] }
      }
      setState((_) => ({ ..._, cateringGroup: { status, group: group } }))
    } catch (error) {

    }
  }
  const handleConfirm = async () => {
    const { status } = state.cateringGroup
    try {
      setState((_) => ({ ..._, cateringGroup: null }))
      await DFPAPIEndpoint.post('operations/cateringGroups/estimates/confirm', { estimateId: status.id })
    } catch (error) {

    }
  }
  const getCateringModelTitle = () => {
    const title = t(`pages.controlPanel.views.catering.estimates`) + ' - ' + state.cateringGroup.group.name;
    return title + ` - ${state.cateringGroup.status.relativeExecutionTime}`
  }
  const getIntentModalTitle = () => {
    const { scheduledTimeOfExecution, relativeExecutionTime, executionTimeModifier, deltaEntry } = state.selectedIntent
    let intentTitle = `${moment(scheduledTimeOfExecution).format('YYYY-MM-DD HH:mm')} - ${relativeExecutionTime}`;
    if (deltaEntry) {
      intentTitle += ` (Delta)`
    } else if (executionTimeModifier) {
      intentTitle += ` (${executionTimeModifier})`
    }
    return t('pages.controlPanel.views.caterer.intentTitle', { intent: intentTitle })
  }
  const handleIntentClick = async (id, delta) => {
    try {
      let res = await DFPAPIEndpoint.post('operations/plugins/caterer-service/actions/getIntentById', { id });
      if (res) {
        res.items = res.items.map((_) => ({ ..._, previous: _.quantity, delta: 0 }))
      } else {
        res = { items: [] }
      }
      res.deltaEntry = delta
      setState((_) => ({ ..._, selectedIntent: res }))
    } catch (error) {
      console.error('error', error);
    }
  }
  const SubTitle = () => {
    const { startTime, locationCode, slot } = state.cateringGroup.group.model
    return (
      <span className="margBottom10">
        {t('pages.controlPanel.views.caterer.batchName', { batch: '' })}
        <code>CB/{locationCode}/{moment(startTime).startOf("day").format('YYYY-MM-DD')}/{slot}</code>
      </span>
    )
  }
  const batches = getCateringBatches();
  return (
    <ControlPanelView
      TABS={TABS}
      show={show}
      viewId='caterer'
      groups={state.batches}
      selectedDate={selectedDate}
      selectedTab={state.tab}
      onTabSelect={(tab) => setState((_) => ({ ..._, tab: tab }))}
      onSelectGroup={(selectedGroup) => setState((_) => ({ ..._, selectedGroup: selectedGroup }))}
      RightComponent={() => <CatererIntents intents={state.intents || {}} selectedGroup={state.selectedGroup} onIntentClick={handleIntentClick} />}>
      <div className="full-flex pad flexCol scrollHide">
        {!Boolean(state.selectedGroup) &&
          <div className="flexCol flex-ctr hor-ctr full">{t(`pages.controlPanel.views.selectGroup`)}</div>
        }
        {
          Boolean(state.selectedGroup) &&
          <div className="flexCol full scrollHide">
            <div className="flexRow hor-ctr h3 space-btn">
              <h3 className="h4">
                {t(`pages.controlPanel.views.caterer.groupDetails`)}
                {
                  Boolean(state.selectedGroup.model) && Boolean(state.selectedGroup.model.loc) &&
                  <span style={{ marginLeft: 16 }}>
                    <code>{state.selectedGroup.model.loc}</code>
                    {Boolean(state.selectedGroup.model.slot) && <> | <code>{t('pages.controlPanel.views.caterer.batchName', { batch: state.selectedGroup.model.slot })}</code></>}
                  </span>
                }
              </h3>
              {
                Boolean(state.selectedGroup.model) && Boolean(state.selectedGroup.model.loc) && Boolean(state.selectedGroup.model.slot) &&
                <EmainOrDownload />
              }
            </div>
            <div className="flexCol full-flex scrollY">
              {
                batches.map((cg, i) => {
                  return (
                    <CaterCard
                      key={cg.id} model={cg.model} slNo={i + 1}
                      onClick={() => handleBatchClick(cg)} />
                  )
                })
              }
            </div>
          </div>
        }
        {
          Boolean(state.cateringGroup) &&
          <ItemGridView
            showEmail
            TitleComponent={() => {
              return (
                <EstimateTitle cateringGroup={state.cateringGroup} />
              )
            }}
            SubTitle={SubTitle}
            Columns={MainColumns.concat(Columns)}
            confirmed={state.cateringGroup.status.status === 'confirmed'}
            onConfirm={handleConfirm}
            title={getCateringModelTitle()}
            onClose={() => setState((_) => ({ ..._, cateringGroup: null }))}
            cateringGroup={state.cateringGroup}
          />
        }
        {
          Boolean(state.selectedIntent) &&
          <ItemGridView
            confirmed
            SubTitle={() => {
              const { loc, slot } = state.selectedGroup.model
              const { createdAt } = state.selectedIntent
              return (
                <span className="margBottom10">{t('pages.controlPanel.views.caterer.batchName', { batch: '' })}
                  <code>CB/{loc}/{moment(createdAt).startOf("day").format('YYYY-MM-DD')}/{slot}</code>
                </span>
              )
            }}
            Columns={MainColumns.concat(state.selectedIntent.deltaEntry ? IntentColumns : Columns)}
            title={getIntentModalTitle()}
            onClose={() => setState((_) => ({ ..._, selectedIntent: null }))}
            cateringGroup={{ status: { items: state.selectedIntent.items } }}
          />
        }
      </div>
    </ControlPanelView>
  )
}