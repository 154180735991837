/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState ,useEffect} from 'react'
import '../../../assets/styles/modal.scss';
import closeIcon from '../../../images/icons/Icon2.png';
import tick from '../../../assets/icons/tick.svg';
import { useTranslation } from 'react-i18next';

export const Permission = (props) => {
  const [userPerm, setUserPerm] = useState([])
  const { t } = useTranslation();
  let isError = false,
   updateStatusText="";
  if (props.modalFormStatusObj) {
    if (('text' in props.modalFormStatusObj)) {
      updateStatusText= props.modalFormStatusObj.text;
      if(updateStatusText){
        props.setShow(true);
      }
    }
    if (('error' in props.modalFormStatusObj)) {
      isError = props.modalFormStatusObj.error;
    }
  }

    useEffect(() => {
     getPermissionGrid();
     setUserPerm(props.userPermLength>0?props.userPermission:[]);
    }, [props])
    useEffect(() => {
      getPermissionGrid();
     }, [props.permCheck])

  const getPermissionGrid=()=>{
    if(props.userPermLength){
      let scopeArr = props.permList.filter(p => userPerm.map(u => u.scope).filter((item,index) => userPerm.map(u => u.scope).indexOf(item) === index).includes(p.scope));
      let res=scopeArr.map(obj => userPerm.find(o => o.name === obj.name) || obj);
      return res;
    }
    else{
      return [];
    }
   }
  const listItems = getPermissionGrid().map((list) =>
{
  return(<React.Fragment key={list.id}>
    <div>
      <li className="list-group-item" onClick={()=>props.setChangePermState(getPermissionGrid(),list)}>
        <div className='row'>
          <div className='col-xs-11'>
            <p><strong>{list.displayName}</strong></p>
            <div  className='flexRow justifyContentSpaceBetween'>
            <p>{list.description}</p>
            </div>
          </div>
          {list.selected? <img className="permission_selected" alt='tick' src={tick} />:<></>}
        </div>
      </li>
    </div>
  </React.Fragment>)}
 );


  return (
      <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar">
        <div className="dialog__content">
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">Permissions ({props.userRoleName})</h2>
          <hr />
          <div className="dialog__body_container flexRow justifyContentSpaceBetween flexWrap">
            <ul className='list-group'>
                {listItems.length?listItems:<div className='flexRow justifyContentCenter alignItemsCenter fontMedium'>No permissions found!.Click save to load Permissions</div>}
            </ul>
          </div>
        </div>
        {Boolean(updateStatusText) &&props.show&&
            <div className={"dialog__statusBlock " + (isError ? 'alert_error' : 'alert_success')}>
              {updateStatusText}
            </div>}
        <div className="dialog__footer flexCol alignItemsCenter">
        <button className={`done-button`} onClick={props.onSubmit}>{t('buttons.saveChanges')}</button>
      </div>
      </div>
    </div>

  );
}
