import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ManageProductsPage, ManageCategoriesPage, ManageSubCategoriesPage, ManageCombosPage } from "./inner-components";

export const ProductsScreen = (props) => {
  return (
    <div className="flexRow full p-0 m-0">
      <div className='full-flex flexCol width100 p-0 m-0'>
        <Routes>
          <Route path="/" element={<Navigate replace to="manage-products" />} />
          <Route path="/manage-products" element={<ManageProductsPage />} />
          <Route path="/manage-categories" element={<ManageCategoriesPage />} />
          <Route path="/manage-subcategories" element={<ManageSubCategoriesPage />} />
          <Route path="/manage-combos" element={<ManageCombosPage />} />
        </Routes>
      </div>
    </div>
  )
}