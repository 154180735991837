/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../../assets/styles/login.scss'
import i18next from "i18next";
import { useTranslation } from 'react-i18next';
import { languages } from '../../utils/languages';
import { capitalizeFirstLetter, translationLength ,fetchUserPermissions} from '../../utils/commonFunctions';
import { themesList, setTheme, DEFAULT_THEME_INDEX } from '../../utils/themes';
import UserSession from '../../utils/userSessionClass';
import francelang from '../../assets/france.svg';
import spainlang from '../../assets/spainlang.svg';
import uaelang from '../../assets/uaelang.svg';
import { UserEndpoint,EmployeeEndpoint} from "../../utils/axios";
import { Footer } from '../../common';
import { FaEye, FaEyeSlash } from "react-icons/fa";

/**
 * Icons
 */
import logo from '../../assets/logo.svg';
import themeSvg from '../../assets/icons/svg/theme-icon.svg';
import enlang from '../../assets/enlang.svg';
import { ForgotPasswordModal } from '../../common/ForgotPasswordModal';

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const userSession = new UserSession();

  const [themeClassObj, setThemeClassObj] = React.useState(themesList[DEFAULT_THEME_INDEX]);
  const [username, setUsername] = useState('')
  const [domain, setDomain] = useState(process.env.ACCOUNT_DOMAIN || 'akasa')
  const [loginMessage, setLoginMessage] = useState('')
  const [password, setPassword] = useState('')
  const [showPass, setshowPass] = useState(false)
  const [adminLogin, setAdminLogin] = useState(true)
  const [ forgotPwdFormData, setShowForgotPwdForm ] = useState({show: false, username: ''});


  React.useEffect(() => {
    /**
     * Set title
     */
    document.title = t(`company.productName`) + ' ' + t(`pages.login`);

  }, [t]);

  // React.useEffect(() => {
  //  if(username){
  //   authorizer.setPermission(permissions[username]);
  //  }
  // }, [username]);// eslint-disable-line react-hooks/exhaustive-deps


  const currentLanguageCode = i18next.language || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage, t])

  // // Set a Cookie
  // let setCookie = (cName, cValue, expDays) => {
  //   let date = new Date()
  //   date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000)
  //   const expires = 'expires=' + date.toUTCString()
  //   document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/'
  // }

  // let getCookie = name => {
  //   if (!document.cookie) {
  //     return null
  //   }

  //   const xsrfCookies = document.cookie
  //     .split(';')
  //     .map(c => c.trim())
  //     .filter(c => c.startsWith(name + '='))

  //   if (xsrfCookies.length === 0) {
  //     return null
  //   }
  //   return decodeURIComponent(xsrfCookies[0].split('=')[1])
  // }

  // if (themeLoading === 'loading') {
  //   return (
  //     <div>Loading styles...</div>
  //   );
  // }



  React.useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage, t]);

  React.useEffect(() => {
    let theme = themeClassObj;
    if (localStorage.getItem('theme') && typeof localStorage.getItem('theme') === 'object') {
      theme = JSON.parse(localStorage.getItem('theme'));
    }

    setUserTheme(theme);
  }, [themeClassObj]);

  const setUserTheme = (theme = themesList[0]) => {
    setThemeClassObj(theme);
    setTheme(theme);
  };

  const setPermissions=(perArr,userType,userName)=>{
    const permissions = {};
    perArr.forEach(item => {
        const { resource, action } = item;
        if (!permissions[`${userType}-role`]) {
            permissions[`${userType}-role`] = {
                "role": [userName, `${userType}-role`],
            };
        }
        if (permissions[`${userType}-role`][action]) {
            permissions[`${userType}-role`][action].push(resource);
        } else {
            permissions[`${userType}-role`][action] = [resource];
        }
    });
  if(permissions){
    localStorage.setItem('userPermissions', JSON.stringify(permissions));
    localStorage.setItem('userRole', `${userType}-role`);
  }
  }

  const onLoginPress = async () => {
    let axObj;
    if(adminLogin){
      localStorage.setItem('sType',username)
      axObj = UserEndpoint.setHost();
    const body = { admin: true, username: username, password: password };
          try {
      setLoginMessage('');

      let userResp = await axObj.postWithFullResp('login', body);

      localStorage.setItem('token', userResp.headers['x-csrf-token']);

      if (userResp.data) {
        const user = userResp.data;
        const {userType,username}=user;
        const data={'subject':username,'roleName':`${userType}-role`}
       const response= await fetchUserPermissions(userResp,data);
      if(response && response.items.length){
      setPermissions(response.items,userType,username)
        }
        if (user && user.theme) {
          if (themesList.find(value => value.keyname === user.theme.keyname)) {
            setUserTheme(user.theme);
          }
        }
        else {
          user.theme = themesList[0];
          setUserTheme(user.theme);
        }

        userSession.setSession(user);
        setLoginMessage('')
        navigate('/');

      }
    } catch (error) {
      console.log('Log: Error occured while login:', error);
      const erMsg=JSON.parse(error.message);
      setLoginMessage(erMsg?.data?.msg||"Login Error!")
    }


    }
    else{
     if(username){

      axObj = EmployeeEndpoint.setHost();
        const body = {clientName:domain,employee:true,username:username,password:password};

      localStorage.setItem('sType',username);

        try {
          setLoginMessage('');

          let userResp = await axObj.postWithFullResp('login', body);

          localStorage.setItem('token', userResp.headers['x-csrf-token']);

          if (userResp.data) {
            const user = userResp.data;
            const {userType,username}=user;
            const data={'subject':username,'roleName':`${userType}-role`}
            const response= await fetchUserPermissions(userResp,data);
            if(response && response.items.length){
              setPermissions(response.items,userType,username)
              }
            if (user && user.theme) {
              if (themesList.find(value => value.keyname === user.theme.keyname)) {
                setUserTheme(user.theme);
              }
            }
            else {
              user.theme = themesList[0];
              setUserTheme(user.theme);
            }

            userSession.setSession(user);
            setLoginMessage('')
            navigate('/');

          }
        } catch (error) {
          console.log('Log: Error occured while login:', error);
          const erMsg=JSON.parse(error.message);
          setLoginMessage(erMsg?.data?.msg||"Login Error!")
        }




      }
    }


    let timerId = setTimeout(() => {
      clearTimeout(timerId)
      setLoginMessage('')
    }, 2500)
  }


  const showLanguageIcon = (code) => {
    switch (code) {
      case "en":
        return <img className="icon-lang" alt='enlang' src={enlang} />
      case "fr":
        return <img className="icon-lang" alt='france' src={francelang} />
      case "sp":
        return <img className="icon-lang" alt='spainlang' src={spainlang} />
      case "ar":
        return <img className="icon-lang" alt='uaelang' src={uaelang} />
      default:
        return null
    }
  }


  const handleForgotPassword = (e) => {
    setShowForgotPwdForm({ show: true, username: username });
  }
  const handleClearForgotPwd = (e) => {
    setShowForgotPwdForm({ show: false, username: '' });
  }

  const togglePassVisibility = ()=>{
    setshowPass(!showPass)
  }

  return (
    <>
       <ForgotPasswordModal data={forgotPwdFormData} clearForgotPwd={(e) => handleClearForgotPwd(e) } />
    <div className='flexRow'>
      <div className='login-bg height100 width50 flexRow justifyContentCenter alignItemsCenter'>
        <div className='width80 center margBot160'>
          <div><img alt='logo' src={logo} /></div>
          <div className='login-sub color fontRegular'>
            <br />
            {t('pages.loginContent.testimony')}
          </div>
          <br />
          <span className='supporting color fontMedium'>{t('pages.loginContent.testifier')}</span>
          <span className='supporting color fontRegular'> {t('pages.loginContent.testimonyInit')}</span>
        </div>
        <Footer mode={"opened"}/>
      </div>
      <div className='height100 width50 flexRow justifyContentCenter alignItemsCenter right-block'>
        <div>
          <div className="dropdown dropdown-style1">
            <img src={themeSvg} alt="" className='menuitemicon' />
            <button
              className='btn btn-link dropdown-toggle'
              type="button"
              id="dropdownMenuButton2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {themeClassObj?.title === 'Blue' ? t('themeNames.Blue') : t('themeNames.Dark')}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              {themesList.map((row, index) => (
                <li key={index} onClick={() => {
                  setUserTheme(row)
                }}>
                  <h6 className="dropdown-item">
                    {capitalizeFirstLetter(t(`themeNames.${row.title}`))}
                  </h6>
                </li>
              ))}
            </ul>

            <button
              className='btn btn-link dropdown-toggle unset-width'
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {showLanguageIcon(currentLanguage.country_code)} &nbsp;
              {t(`languageNames.${currentLanguage.country_code}`)}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {languages.map(({ code, name, country_code }) => (
                <li key={country_code}
                  onClick={() => {
                    i18next.changeLanguage(code)
                  }}>
                    <h6 className={currentLanguage.country_code!==code?"dropdown-item":"dropdown-item body-text-extrabold"} >
                      <span className='flexRow alignItemsCenter'>{showLanguageIcon(code)}&nbsp;&nbsp;{t(`languageNames.${code}`)}</span>
                    </h6>
                </li>
              ))}
            </ul>

          </div>


          <div style={{marginLeft:'2%'}}>
              <div className='h1 lato'>{t('pages.loginContent.welcome-back')}</div>
          <div className='h4 lato margTop16'>
            {t('pages.loginContent.welcomebackMessage')}
          </div>
          </div>

          <div className="sign-in-field">
   { !adminLogin?<>
         <div className='h4 margTop16'>{t('pages.loginContent.domain')}</div>
          <input
             onChange={e => {
              setDomain(e.target.value)
            }}
            value={domain}
            type='text'
            className='input-field-email'
          /></> :null}
        <div className='h4 margTop16'>{t('pages.loginContent.email')}</div>
          <input
          onChange={e => {
            setUsername(e.target.value)
          }}
          value={username}
            type='text'
            className='input-field-email'
          />
          <div className='h4 margTop16'>{t('pages.loginContent.password')}</div>
             <div style={{position:"relative"}}>
              <input
                onChange={e => setPassword(e.target.value)}
                type={showPass ? "text":"password"}
                value={password}
                className='input-field'
              />
              <button onClick={togglePassVisibility} style={{position:"absolute",top:"12px",right:"30px"}}>
                {!showPass ? <FaEyeSlash style={{color:"#8c918d"}} size={22}/>:<FaEye  style={{color:"#8c918d"}} size={22}/>}
              </button>
             </div>
          </div>

          <div style={{display:'flex',justifyContent:'space-between',paddingLeft:"0.35rem",width:'25.0625rem'}}>
                <div className="remember-days" >
                <label style={{display:'flex',alignItems:'center'}}>
      <input type='checkbox' checked={adminLogin} className='checkbox' onChange={()=>setAdminLogin(!adminLogin)}/>
      <span className='small-body-text-regular-login'>
        {t('pages.loginContent.adminLogin')}
      </span>
    </label>
                </div>
                <div
                  className='text-login-forgot-password pointer'
                  onClick={ (e) => handleForgotPassword(e) }
                >
                  {t('pages.loginContent.forgotPassword')}
                </div>
            </div>
            <div  style={{marginLeft:'1%'}} className='flexCol justifyContentFlexStart'>
                  <button
            disabled={!Boolean(username && password)}
            className='margTop24 sign-in large-button-text-medium'
            onClick={onLoginPress} >
            {t('pages.loginContent.signIn')}
          </button>
          {loginMessage ? (
          <div className='flexRow confirmation_popup_login alert_error'>
          <h6 className="subheading-text-medium-title fontLight">{loginMessage}</h6>
          </div>
          ) : null}

          </div>

        </div>

      </div>
      {
        t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
      }
    </div>
    </>
  )
}