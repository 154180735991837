import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AircraftType,AirportsPage, DevicesPage, SectorsPage, DistributorPage, BaseStationPage, WarehousePage, VendorsPage, ConfigureGalley, StandardGalleyLoading, TrolleyLoading, MenuListing, MenuPlanner,MealPolicy,MealRotation, SalesPage, AircraftTypeAka ,StandardGalleyLoadingAka, AircraftMaster} from "./inner-components";
import { TrolleyLoadingAka } from './inner-components/trolleyLoadingAka';

export const OperationScreen = (props) => {
  return (
    <div className="flexRow full p-0 m-0">
      <div className='full-flex flexCol width100 p-0 m-0'>
        <Routes>
          <Route path="/" element={<Navigate replace to="aircrafts" />} />
          {/* <Route path="/aircrafts" element={<AircraftsPage />} /> */}
          <Route path="/aircraftType">
            <Route path="" element={ <AircraftType /> } />
            <Route path="galley/configure/:id" element={<ConfigureGalley />} />
          </Route>
          <Route path="/trolleyLoading" element={<TrolleyLoading />} />
          <Route path="/trolleyLoadingAka" element={<TrolleyLoadingAka />} />
          <Route path="/aircraftType" element={<AircraftType />} />
          <Route path="/aircraftTypeAka" element={<AircraftTypeAka />} />

          <Route path="/standardGalleyLoading" element={<StandardGalleyLoading />} />
          <Route path="/standardGalleyLoadingAka" element={<StandardGalleyLoadingAka />} />
          <Route path="/menuPlanner" element={<MenuPlanner />} />
          <Route path="/mealPolicy" element={<MealPolicy />} />
          <Route path="/mealRotation" element={<MealRotation />} />

          <Route path="/menuListing" element={<MenuListing />} />
          <Route path="/aircraftMaster" element={<AircraftMaster />} />
          <Route path="/airports" element={<AirportsPage />} />
          <Route path="/stations" element={<BaseStationPage />} />
          <Route path="/devices" element={<DevicesPage />} />
          <Route path="/routes" element={<SectorsPage />} />
          <Route path="/distributors" element={<DistributorPage />} />
          <Route path="/warehouses" element={<WarehousePage />} />
          <Route path="/vendors" element={<VendorsPage />} />
          <Route path="/sales" element={<SalesPage />} />
        </Routes>
      </div>
    </div>
  )
}
