/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { DataGrid, EditModal, Input } from "../../../common";
import mokeData from "../../../assets/json/auditSampleData.json";
import Select from "react-select";
import { customSelSearch, PageSizes } from "../../../utils/configs";
import { getDateFromTimezone } from "../../control-panel/inner-components/Cards";
import moment from "moment";
import usePermissions from "../../../utils/userPermissions";
const eventTypeList = [
  { label: "Employee", value: "employee" },
  { label: "Crew", value: "crew" },
  { label: "Inventory", value: "inventory" },
];

export const AuditEnquiry = () => {
  const [data, setData] = useState(mokeData.items);
  const [filters, setFilters] = useState({
    eventType: [{ label: "Employee", value: "employee" },],
    fromDate: getDate30Back(),
    toDate: new Date(),
  });
  const [state, setState] = useState({ selected: null, isOpenMenu: false });
  const { hasPermission: viewDetailsAuditEnq } = usePermissions('view', "administration.audit-enquiry");

  const Columns = [
    { field: "eventOccurredTime", headerName: "Datetime", flex: 1,valueFormatter:formatDate },
    { field: "txnId", headerName: "Txn ID", flex: 1, minWidth: 250 },
    { field: "recordType", headerName: "Record Type", flex: 1 },
    { field: "user", headerName: "User", flex: 1 },
    { field: "eventType", headerName: "Operation Type", flex: 1 },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      cellRenderer: ActionCellRenderer,
    },
  ];

  function formatDate(params) {
    const {value} = params
    const readableDate = moment(value).format('YYYY-MM-DD H:mm:ss');
    return readableDate
  }

  function ActionCellRenderer(props) {
    if (!props.data) return;
    return (
      <div>
        {viewDetailsAuditEnq?<button
          className="grid-download-btnStyle1"
          onClick={() => viewDetails(props.data)}
        >
          Details
        </button>:null}
      </div>
    );
  }

  function viewDetails(data) {
    setState((prev) => {
      return { ...prev, selected: data };
    });
  }

  const onClose = () => {
    setState((prev) => {
      return { ...prev, selected: null };
    });
  };
  const handleMenuOpen = (isOPen) => {
    setState((prevState) => ({
      ...prevState,
      isOpenMenu: isOPen,
    }));
  };

  function getDate30Back() {
    const today = new Date();
    const fromDate = new Date(today);
    fromDate.setDate(today.getDate() - 30);
    return fromDate;
  }
  function handleEventTypeSel(e) {
    setFilters((prev) => {
      return { ...prev, eventType: [e] };
    });
  }
  function resetDateDefault(type){
    switch (type) {
      case "fromDate":
        setFilters((prev) => {
          return { ...prev, fromDate: getDate30Back() };
        });
        break;
        case "toDate":
          let dataVal = getDateFromTimezone(new Date()).toDate();
          setFilters((prev) => {
            return { ...prev, toDate: dataVal };
          });
        break;

      default:
        break;
    }
  }
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if(!value){
      resetDateDefault(name,value)
      return;
    }
    if (name === "fromDate") {
      let dataVal = getDateFromTimezone(value).toDate();
      const fromDate = new Date(dataVal)
      const today = new Date()
      today.setHours(0,0,0,0)
      if(fromDate > today){
        alert("From date canot be greater than today!")
        resetDateDefault("fromDate")
        return;
      }
      setFilters((prev) => {
        return { ...prev, fromDate: dataVal };
      });
    }
    if (name === "toDate") {
      let dataVal = getDateFromTimezone(value).toDate();
      setFilters((prev) => {
        return { ...prev, toDate: dataVal };
      });
    }
  };
  return (
    <>
      <div className="flexCol full-flex ag-theme-alpine-dark">
        <div className="flexRow margBot10">
          <div className="felxCol margRight11">
            <label className="req margBottom10">Event Type</label>
            <div style={{ marginBottom: "7px" }}>
              <Select
                options={eventTypeList}
                defaultValue={""}
                value={eventTypeList?.filter(function (option) {
                  return (
                    filters.eventType.length &&
                    filters.eventType[0] &&
                    filters.eventType.find((sc) => sc.label === option.label)
                  );
                })}
                onChange={handleEventTypeSel}
                styles={customSelSearch}
                isOpenMenu={state.isOpenMenu}
                onMenuOpen={() => handleMenuOpen(true)}
                onMenuClose={() => handleMenuOpen(false)}
                placeholder="Search Event Type"
                isSearchable={true}
                isClearable={true}
                className="selectMenu-audit"
              />
            </div>
          </div>
          <div className='felxCol margRight11' style={{marginTop:"4px"}}>
          <Input
          label="From Date"
            type="date"
            value={filters.fromDate}
            name="fromDate"
            onChange={handleInputChange}
          />
          </div>
          <div className='felxCol margRight11' style={{marginTop:"4px"}}>
          <Input
          label="To Date"
            type="date"
            value={filters.toDate}
            name="toDate"
            onChange={handleInputChange}
          />
          </div>
        </div>
        <DataGrid
          className="full-flex"
          columns={[...Columns]}
          showDefaultFilters={false}
          rowModelType="clientSide"
          rowData={data}
          columnResize={true}
          rowSelection="multiple"
        />
      </div>
      {state.selected && (
        <EditModal
          showDone={false}
          title={`Transaction-${state.selected.txnId}-${state.selected.eventType}`}
          onClose={onClose}
        >
          <pre>
            {state.selected.changelist.length > 0
              ? JSON.stringify(state.selected.changelist, null, 5)
              : JSON.stringify(state.selected.fullDocument, null, 5)}
          </pre>
        </EditModal>
      )}
    </>
  );
};