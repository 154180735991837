import React from 'react'
import '../assets/styles/styles.scss'


export default function ProgressBar({percent}) {
    const [value, setValue] = React.useState(0);
    React.useEffect(() => {
      setValue(percent);
    }, [percent]);
  return (
    <div>
    <div className="progress-div" style={{ width: '100%' }}>
      <div style={{ width: `${value}%` }} className="progress" />
    </div>
  </div>

  )
}
