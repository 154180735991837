import '../../../assets/styles/modal.scss'

import cancel from '../../../images/icons/Icon2.png'

export default function Modal ({show,detail,close}) {
    const list = []
    for (const [key, value] of Object.entries(detail).slice(1)) {
        if(typeof value !== 'object'){
            if(show === 'view'){
                list.push(
                <div className="card-container2 ">
                        <div className="card-content2">
                        <p className="dialog__description"><div className="small-body-text-regular fontRegular">{key.replace(/([A-Z])/g, ' $1').trim()} </div>
                        <div className="margTop8">{value}</div>
                        </p>
                        </div>
                    </div>
                )
            }else if (show === 'edit'){
                list.push(
                    <div className="card-container2 ">
                        <div className="card-content2">
                        <p className="dialog__description"><div className="small-body-text-regular fontRegular">{key.replace(/([A-Z])/g, ' $1').trim()} </div>
                           {key==='category'||key==='subCategory'? <select  className='edit-input'>
              <option value={value}>{value}</option>
          </select> :<input
                            placeholder={value}
                            type='text'
                            className='edit-input'
                            />}
                        </p>
                        </div>
                    </div>
                )
            }
        } 
    }

    if (show === '') {
        return null;
    }
    return (
    <div>
        <div className="overlay">
        <div className="dialog">
            <div className="dialog__content">
                <img className="modal__icon" alt='cancel' src={cancel} onClick={close}/>
                <h2 className="dialog__title subheading-text-medium fontMedium">{show === 'view' ? 'Details' : 'Edit Item'}</h2>
                <hr></hr>
                <div className="container-fluid dialog__container">
                    <div className="row flexRow justifyContentFlexStart">
                        {list}
                    </div>
                </div>
            </div>
            <div className="dialog__footer">
                {show === 'edit' &&
                   <button className="icon-button" onClick={close}>Done</button>
                }
            </div>
        </div>
        </div>
    </div>
    );
}