import React, { useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { SidebarComponent, TopHeaderComponent } from '../../common';
import { DashboardPage } from "../dashboard";
import { RepPage } from "../reports";
import { DistributorPage } from "../distributors";
import { FlightsPage } from "../flights";
import { InventoryPage } from "../inventory";
import { EmployeesPage } from "../employees";
import { WarehousePage } from '../warehouse';
import { OperationScreen } from "../operations";
import { ProductsScreen } from "../products";
import { EstimationScreen } from "../estimation";
import { ConfigPage } from '../configuration-pal';
import { CashRegisterScreen } from '../cash-register';
import { ControlPanelPage } from '../control-panel';
import { CrewScreen } from '../crews';
import {ConfigMainPage} from "../configuration";
import { AlertswaringsPage } from '../alertsWarnings';
import { SalesScreen } from '../sales';
import { LoyaltyScreen } from '../loyalty';
import AdminPage from '../admin';


const MENU_STATE_EXPAND = 'expanded';
const MENU_STATE_COLLAPSE = 'collapsed';

export const HomePage = (props) => {
  const [menuState, setMenuState] = useState(MENU_STATE_EXPAND);

  const setMenuToggleState = () => {
    const iSideMenuState = menuState === MENU_STATE_EXPAND ? MENU_STATE_COLLAPSE : MENU_STATE_EXPAND
    setMenuState(iSideMenuState);
  }
  return (
    <div className="flexCol full p-0 m-0">
      <TopHeaderComponent onMenu={setMenuToggleState} />
      <div className='full-flex flexCol p-0 m-0'>
        <div className="flexRow full p-0 m-0">
          <SidebarComponent
            menuState={menuState}
            onMenu={(menu) => {
              setMenuState(MENU_STATE_EXPAND);
            }}
          />
          <div className='full-flex flexCol width100 p-0 m-0'>
            <div className="flexCol full content-container">
              <Routes>
                <Route path="/" element={<Navigate replace to="dashboard" />} />
                <Route path="/dashboard/*" element={<DashboardPage />} />
                <Route path="/loyalty/*" element={<LoyaltyScreen />} />
                <Route path="/control-panel/*" element={<ControlPanelPage />} />
                <Route path="/flights/*" element={<FlightsPage />} />
                <Route path="/inventory/*" element={<InventoryPage />} />
                <Route path="/employees/*" element={<EmployeesPage />} />
                <Route path="/distributors/*" element={<DistributorPage />} />
                <Route path="/procurement/*" element={<WarehousePage />} />
                <Route path="/products/*" element={<ProductsScreen />} />
                <Route path="/operations/*" element={<OperationScreen />} />
                <Route path="/estimation/*" element={<EstimationScreen />} />
                <Route path="/configuration/*" element={<ConfigMainPage />} />
                <Route path="/cashRegister/*" element={<CashRegisterScreen />} />
                <Route path="/crews/*" element={<CrewScreen />} />
                <Route path="/reports/*" element={<RepPage />} />
                <Route path="/config/*" element={<ConfigPage />} />
                <Route path="/alertsWarnings/*" element={<AlertswaringsPage />} />
                <Route path="/sales/*" element={<SalesScreen />} />
                <Route path="/admin/*" element={<AdminPage />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
