import React from 'react';
import { Chart as ChartJS, LineElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement } from 'chart.js'
import { Line } from 'react-chartjs-2';
import { GraphContainer } from "./GraphContainer";

ChartJS.register(
  Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement,
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    }
  },
};
export const LineChart = (props) => {
  const data = {
    labels: props.labels,
    datasets: props.datasets
  }
  return (
    <GraphContainer
      title={props.title}
      legendType="square"
      legends={props.legends}
      {...props}
      >
      <Line options={options} datasetIdKey={props.key} data={data} />
    </GraphContainer>
  );
}