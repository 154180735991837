export const editFields=[
  {label: 'Domestic', attribute: 'domestic', type: 'checkbox', disabled: false, required: false},
  {label: 'International', attribute: 'international', type: 'checkbox', disabled: false, required: false},
  {label: 'BOB', attribute: 'bob', type: 'checkbox', disabled: false, required: false},
  {label: 'Corporate', attribute: 'corporate', type: 'checkbox', disabled: false, required: false},
  {label: 'Web Meal', attribute: 'webMeal', type: 'checkbox', disabled: false, required: false},
  {label: 'Perishable', attribute: 'perishable', type: 'checkbox', disabled: false, required: false},
  {label: 'Saleable', attribute: 'saleable', type: 'checkbox', disabled: false, required: false},
  {label: 'Beverage', attribute: 'beverage', type: 'checkbox', disabled: false, required: false},
  {label: 'Merchandise', attribute: 'merchandise', type: 'checkbox', disabled: false, required: false},
  {label: 'Stationary', attribute: 'stationary', type: 'checkbox', disabled: false, required: false},
  {label: 'Group Meal', attribute: 'groupMeal', type: 'checkbox', disabled: false, required: false},
  {label: 'Crew Meal', attribute: 'crewMeal', type: 'checkbox', disabled: false, required: false},
  {label: 'Liquor', attribute: 'liquor', type: 'checkbox', disabled: false, required: false},
  {label: 'Duty-free', attribute: 'dutyFree', type: 'checkbox', disabled: false, required: false}
];
export const editFields2=[
  {label: 'Perishable', attribute: 'perishable', type: 'checkbox', disabled: false, required: false},
  {label: 'Saleable', attribute: 'saleable', type: 'checkbox', disabled: false, required: false},
  {label: 'Beverage', attribute: 'beverage', type: 'checkbox', disabled: false, required: false},
  {label: 'Merchandise', attribute: 'merchandise', type: 'checkbox', disabled: false, required: false},
  {label: 'Stationary', attribute: 'stationary', type: 'checkbox', disabled: false, required: false},
  {label: 'Group Meal', attribute: 'groupMeal', type: 'checkbox', disabled: false, required: false},
  {label: 'Crew Meal', attribute: 'crewMeal', type: 'checkbox', disabled: false, required: false}
];
export const editFields3=[
  {label: 'Perishable', attribute: 'perishable', type: 'checkbox', disabled: false, required: false},
  {label: 'Saleable', attribute: 'saleable', type: 'checkbox', disabled: false, required: false},
  {label: 'Beverage', attribute: 'beverage', type: 'checkbox', disabled: false, required: false},
  {label: 'Merchandise', attribute: 'merchandise', type: 'checkbox', disabled: false, required: false},
  {label: 'Stationary', attribute: 'stationary', type: 'checkbox', disabled: false, required: false},
  {label: 'Liquor', attribute: 'liquor', type: 'checkbox', disabled: false, required: false},
  {label: 'Duty-free', attribute: 'dutyFree', type: 'checkbox', disabled: false, required: false}
];

export const defaultEditFields = [
  {label: 'Item Type', attribute: 'itemType', type: 'select', disabled: false, options: [], required: true },
  {label: 'Item Code', attribute: 'itemCode', type: 'text', disabled: false, required: true },
  {label: 'Item Name', attribute: 'itemName', type: 'text', disabled: false, required: true},
  {label: 'SSR Code', attribute: 'ssrCode', type: 'text', disabled: false},
  {label: 'Category', attribute: 'category', type: 'select', disabled: false, options: [], required: true },
  {label: 'Sub category', attribute: 'subCategory', type: 'select', disabled: true, options: [], required: true },
  {label: 'HSN Code', attribute: 'hsn', type: 'text', disabled: false},
  {label: 'Bar Code', attribute: 'barCode', type: 'text', disabled: false},
  {label: 'Unit', attribute: 'unit', type: 'select', disabled: false, options: [], required: true},
  {label: 'Description', attribute: 'description', type: 'textarea', disabled: false},
];

export const toolTipInfo={authKey:'MSG91 Auth Key',tempId:'MSG91 Template ID of the Otp format',accountAlias:"Used to identify the account in employee login",mandBillPrompt:"If enabled employee will be required to allow or dis-allow bill print everytime",schServMand:"Schedule service  is mandatory for app login",autoConfUplift:"If enabled uplifts will be confirmed automatically",ensStockDuringUplift:"If enabled system will make sure that there is enough stock for every item when an uplift is being confirmed"}