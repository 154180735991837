/* eslint-disable no-self-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import searchIcon from '../../../assets/icons/search.svg';
import viewIcon from "../../../assets/view.svg";
import grnIcon from "../../../assets/check.svg";

import { DataGrid, EditModal} from "../../../common";
import { APIEndpoint } from "../../../utils/axios";
import moment from 'moment';
import { onDownloadPDFFile, translationLength } from "../../../utils/commonFunctions";
import { PageSizes, FilterBodyTemplate } from "../../../utils/configs";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const Columns = [
    { field: 'code', headerName: 'GRN Code', sortable: true, filter: true, checkboxSelection: false,
        minWidth: 190,
        tooltipField: 'code'
    },
    { field: 'invoiceNo', headerName: 'Invoice No', sortable: true, filter: true,
        minWidth: 160,
        tooltipField: 'invoiceNo'
    },
    { field: 'invoiceDate', headerName: 'Invoice Date', sortable: true, filter: true,
        minWidth: 160,
        tooltipField: 'invoiceDate'
    },
    { field: 'warehouseCode', headerName: 'Warehouse', sortable: true, filter: true,
        minWidth: 290,
        tooltipField: 'warehouseName',
        valueGetter: params => params.data ? params.data.warehouseCode + ' (' + params.data?.warehouseName + ')' : '',
    },
    { field: 'vendorCode', headerName: 'Vendor', sortable: true, filter: true,
        minWidth: 200,
        tooltipField: 'vendorName',
        valueGetter: params => params.data ? params.data.vendorCode + ' (' + params.data?.vendorName + ')' : '',
    },
    { field: 'createdAt', headerName: 'Created At', sortable: true, filter: true,
        minWidth: 140,
        tooltipField: 'createdAt',
        valueGetter: params => {
            if ( params.data ) {
                return moment(params.data.createdAt).format('DD-MM-YYYY');
            }
        }
    },
    { field: 'createdBy', headerName: 'Created By', sortable: true, filter: true,
        minWidth: 200,
        tooltipField: 'createdBy.employeeName',
        valueGetter: params => params.data ? params.data.createdBy?.employeeName : ''
    },
    { field: 'items', headerName: 'Summary', sortable: true, filter: true,
        minWidth: 150,
        valueGetter: params => params.data ? params.data.items?.length + ' items' : ''
    }
];

const EditFormFields = [
    { label: 'View GRN Details', type: 'viewGRNDetails', attribute: 'items' }
];

export const GRNForPurchaseOrder = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const urlParams = useParams();
    const warehouseId = urlParams.warehouseId;
    const purchaseOrderId = urlParams.purchaseOrderId;
    const poId = urlParams.id;
    const [poDetails, setPODetails] = useState({});

    const [state, setState] = useState({});
    const [editFormFields, setEditFormFields] = useState(EditFormFields);
    const [perPageLimit, setPerPageLimit] = useState(5);
    const [searchKey, setSearchKey] = useState('');
    const [ isReloadTableData, setIsReloadTableData ] = useState(false);

    const [ modalTitle, setModalTitle ] = useState('View Purchase Order Items');
    const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
    const [modelStyle, setModalStyle] = useState({ maxWidth: '80%' });
    const [actionItems, setActionItems] = useState([]);
    const [modalCustomButtons, setModalCustomButtons] = useState([]);
    const [noResultsMessage, setNoResultsMessage] = useState( 'No Data' );

    useEffect( () => {
        setNoResultsMessage( t('pages.procurementSub.purchaseOrdersSub.noGRNFound') )
    }, [t]);

    useEffect( () => {
        loadPODetailsByAPI();
    }, [warehouseId, purchaseOrderId, poId]);

    const loadPODetailsByAPI = async () => {
        const pODet = await APIEndpoint.get('indents/findById/' + poId ).then( async resp2 => {
            setPODetails(resp2.data);
        });
    };

    useEffect(() => {
        setActionItems([
                {
                    title: '',
                    attribute: 'viewGRNDetails',
                    // detail: [
                    //     {src: suspendIcon, value:"Suspend", title: ''},
                    // ],
                    // width: 90,
                    // maxWidth: 90,
                },
                {
                    title: '',
                    attribute: 'reverseGRN',
                    width: 390,
                }
            ]);

            setIsReloadTableData(true);

    }, []);

    const getActionCellIcons = (cellDef, data) => {

        if (cellDef.attribute === 'viewGRNDetails') {
            return [
                {src: viewIcon, value:"viewGRNDetails", title: 'GRN Details'},
            ];
        }

        if (cellDef.attribute === 'reverseGRN') {
            // if (data && data.status !== 'draft' && data.status !== 'suspended') { }
            return [
                {src: grnIcon, value:"reverseGRN", title: 'Reverse GRNs'},
            ];
        }
        return [];
    };

    const disableButtonStatus = (btnName, newBtnLabel, isDisable=true) => {
        const updatedData = modalCustomButtons.filter( btn => {
            if (btn.name === btnName ) {
                btn.label = newBtnLabel;
                btn.disabled = isDisable
            }
            return btn;
        });

        setModalCustomButtons( updatedData );
    };

    const initiateDownloadGRN = async (poGRNObj) => {
        console.log('Downloading...', poGRNObj.id);

        disableButtonStatus('grnDownloadBtn', 'Downloading...', true);
        setModalFormStatusObj({ text: 'Downloading...' });

        try {
            const inpData ={
                grnId: poGRNObj.id
            };
            let headers = {
                'Accept':'application/pdf',
                'Content-Type': 'application/json;charset=UTF-8'
            }
            await APIEndpoint.post('downloads/grn', inpData, headers).then( async resp => {

                await APIEndpoint.get('downloads/status/' + resp.id).then( async resp2 => {
                    await onDownloadPDFFile('downloads/analytics/' + resp2.fileName + resp2.fileExtension, resp2.fileName );
                    disableButtonStatus('grnDownloadBtn', 'Download', false);
                    setModalFormStatusObj({ text: 'File downloaded successfully.' });
                });
            });
            // onClose();
        }
        catch (err) {
            console.log('Log: Error occured while downloading GRN file,', err.message);
            setModalFormStatusObj({ error: 'Error while downloading file', });
        }
    };

    const onActionInner = (action, row) => {
        let updatedEditFields = [];
        if (action.value === 'reverseGRN') {
            navigate('/procurement/purchase-orders/reversegrn/list/' + row.warehouseId + '/' + row.id);
        }
        else if (action.value === 'viewGRNDetails') {
            /** Here, Resetting default input fields to array then updating */
            setModalStyle( (_prev) => {
                return { ..._prev, maxWidth: '80%'};
            });
            setEditFormFields(EditFormFields);
            updatedEditFields = EditFormFields.filter( field => {
                if (field.type === 'viewGRNDetails') {
                    setModalTitle('GRN - ' + row.code );
                    field.value = row;
                }
                return field;
            });

            setModalCustomButtons([
                /**
                 * Buttons
                 */
                { label: 'Download',
                    name: 'grnDownloadBtn',
                    attribute: 'button',
                    type: 'button',
                    disabled: false,
                    className: 'download-button',
                    action: async (e) => {
                        console.log('Intializing download...');
                        await initiateDownloadGRN(row);

                        return false;
                    }
                }
            ]);
        }
        setEditFormFields( updatedEditFields );

        setState((_prev) => {
            return { ..._prev, selected: { ...row, action: action.value} };
        });
    };

    const onAction = (e, action, row) => {
        onActionInner(action, row);
    };

    const onClose = (e, action, row) => {
        setState((_prev) => {
            return { ..._prev, selected: false };
        });
        setModalFormStatusObj({ text: '' });
    };

    const fetchGRNsForPurchaseOrderRows = async (page=1, perPage=10) => {
        const filterData = JSON.parse(JSON.stringify(FilterBodyTemplate));
        filterData.page = page;
        filterData.limit = perPage;
        filterData.searchParam = searchKey;

        const res = await APIEndpoint.post('warehouses/' + warehouseId + '/purchaseOrders/' + purchaseOrderId + '/grns/process', JSON.stringify(filterData));
        return res;
    };

    const onPerLimitChange = (e) => {
        setPerPageLimit(Number(e.target.value));
    };

    const onSearch = (e) => {
        setSearchKey(e.target.value);
    };

    const onCreateGRNHandler = () => {
        setModalStyle( (_prev) => {
            return { ..._prev, maxWidth: '50%'};
        });

        const updatedEditFields = [
            { label: 'Invoice No.', name: 'invoiceNo', attribute: 'invoiceNo', type: 'text', required: true, disabled: false,
                placeholder: t('pages.procurementSub.purchaseOrdersSub.grnInvoceNo'),
                className: 'text-input' },
            { label: 'Invoice Date', name: 'invoiceDate', attribute: 'invoiceDate', type: 'date', required: true, disabled: false,
                placeholder: t('datePickerPlaceholder'),
                className: 'date-input'}
        ];
        setEditFormFields( updatedEditFields );

        setModalCustomButtons([
            /**
             * Buttons
             */
            { label: t('buttons.proceed'),
                name: 'proceed',
                attribute: 'proceed',
                type: 'button',
                disabled: false,
                className: 'procced-button',
                action: 'onSubmit' // async (e) => { console.log('Submitting your request...', e); return false; }
            }
        ]);
        setModalTitle( t('buttons.proceed') + ' - ' + purchaseOrderId );
        setState( (_prev) => {
          return { ..._prev, selected: { action: 'createGRN' } };
        });
    }

    const onSubmit = async (e, updated) => {
        if(!updated?.invoiceNo){
            setModalFormStatusObj({ text: 'Invoice number is required!', error: 1 });
            return;
        }
        if(!updated?.invoiceDate){
            setModalFormStatusObj({ text: 'Invoice date is required!', error: 1 });
            return;
        }
        try {
            setModalFormStatusObj({ text: 'Submitting...' });
            const body = {
                ...updated,
            };
            if (body.invoiceDate) {
                body.invoiceDate = body.invoiceDate;
            }
            const navigationData = {
                state: {
                    ...location.state,
                    invoicePayload: body
                }
            }
            navigate('/procurement/purchase-orders/'
                + poDetails.warehouseId
                + '/' + poDetails.purchaseOrderId
                + '/' + poDetails.id
                + '/' + JSON.stringify(body)
                +'/grn/create');

        }
        catch(err) {
            setModalFormStatusObj({ error: 'Error: ' + err.data.msg});
        }
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                {
                    <>
                        <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween alignItemsCenter'>
            			<div className="flexRow justifyContentFlexStart">
	                            <button className='page-button'>
	                                <select className="darkBlue-bg" onChange={onPerLimitChange} value={perPageLimit} id="page-size">
	                                    {
	                                    PageSizes.map((_) => {
	                                        return (
	                                        <option key={_.value} value={_.value}>{_.label}</option>
	                                        )
	                                    })
	                                    }
	                                </select>
	                            </button>
	                        </div>

                            <div className="flexRow justifyContentFlexEnd">
                                {poDetails.status !== 'suspended' ? (
                                    <button className="add-item-button margRight" onClick={ onCreateGRNHandler }>
                                        {t('buttons.createGRN')}
                                    </button>
                                ) : null}

	                            <div className="underline">
	                                <span><img  className="icon-size" alt='search' src={searchIcon} /></span>
	                                <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={searchKey}/></span>
	                            </div>
							</div>

                        </div>

                        <DataGrid
                            className="full-flex"
                            columns={Columns}
                            initialPageLimit={perPageLimit}
                            showDefaultFilters={false}
                            searchKey={searchKey}
                            getRows={ fetchGRNsForPurchaseOrderRows }
                            noResultsMessage={ noResultsMessage }
                            rowSelection="multiple"
                            actions={actionItems}
                            getActionCellIcons={getActionCellIcons}
                            onAction={ (e, action, data) => { return onAction(e, action, data) } }
                            onAddItemHandler={ () => {} }
                            columnResize={true}
                            resetDataSource={isReloadTableData}
                        />
                    </>
                }
                {
                    state && Boolean(state.selected) ? (
                        <EditModal
                            title={ modalTitle }
                            customModalStyle={ modelStyle }
                            onClose={onClose}
                            onSubmit={onSubmit}
                            modalFormStatusObj={modalFormStatusObj}
                            fields={ editFormFields.map( (elt) => {
                                    if (state.selected.action === 'viewGRNDetails') {
                                        return {...elt, initialValue: state.selected[elt.attribute] || ''}
                                    }
                                    else {
                                        return {...elt, initialValue: '' }
                                    }
                                })
                            }
                            showFieldsInColumn={true}
                            modalCustomButtons={modalCustomButtons}
                        />
                    )
                    : null
                }
                 {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
            </div>
        </>
    );
}