import React, { useState,useMemo } from "react";
import { DataGrid } from "../../../common";
import viewIcon from "../../../assets/view.svg";
import searchIcon from '../../../assets/icons/search.svg';
import closeIcon from "../../../images/icons/Icon2.png";
import usePermissions from '../../../utils/userPermissions';


const Columns = [
  {field: "firstname",headerName: "First Name",filter: true,flex:1, suppressSizeToFit:true},
  {field: "lastname",headerName: "Last Name",filter: true,flex:1, suppressSizeToFit:true},
  { field: "trips", headerName: "Trips", filter: true ,flex:1, suppressSizeToFit:true},
  { field: "tier", headerName: "Tier",  filter: true,flex:1, suppressSizeToFit:true },
  { field: "points", headerName: " Points",  filter: true,flex:1, suppressSizeToFit:true},
  {field: "status",headerName: "Status",filter: true,flex:1, suppressSizeToFit:true},
  {field: "lastupdate",headerName: "Last Update",filter: true,flex:1, suppressSizeToFit:true},
];



const rowData = [
  {
    firstname: "Alex",
    lastname:"John",
    trips: "2",
    tier:"Base",
    points: "450",
    status: "Active",
    lastupdate: "22 Feb 2024",
    tripDetails: [
      {
        _id: "001",
        flight: "DEL-BLR",
        date: "20 Jan 2024",
        type: "inflight seat upgrade",
        points: "250",
      },
      {
        _id: "002",
        flight: "BOM-BLR",
        date: "22 Feb 2024",
        type: "inflight seat upgrade",
        points: "200",
      },
    ],
  },
  {
    firstname: "Sooraj",
    lastname:"Menon",
    trips: "3",
    tier:"Base",
    points: "600",
    status: "Active",
    lastupdate: "22 Feb 2024",
    tripDetails: [
      {
        _id: "001",
        flight: "DEL-BLR",
        date: "20 Feb 2024",
        type: "inflight seat upgrade",
        points: "250",
      },
      {
        _id: "002",
        flight: "BOM-BLR",
        date: "22 Feb 2024",
        type: "inflight seat upgrade",
        points: "200",
      },
      {
        _id: "003",
        flight: "AHMD-BLR",
        date: "28 Feb 2024",
        type: "inflight seat upgrade",
        points: "150",
      },
    ],
  },
  {
    firstname: "Arjun",
    lastname:"Reddy",
    trips: "5",
    tier:"Platinum",
    points: "1100",
    status: "Active",
    lastupdate: "28 Feb 2024",
    tripDetails: [
      {
        _id: "001",
        flight: "DEL-BOM",
        date: "28 Feb 2024",
        type: "inflight seat upgrade",
        points: "200",

      },
      {
        _id: "002",
        flight: "AHMD-DEL",
        date: "20 Feb 2024",
        type: "inflight seat upgrade",
        points: "200"

      },
      {
        _id: "003",
        flight: "DEL-AHMD",
        date: "10 Feb 2024",
        type: "inflight seat upgrade",
        points: "250",
      },
      {
        _id: "004",
        flight: "BLR-DEL",
        date: "24 Jan 2024",
        type: "inflight seat upgrade",
        points: "200"

      },
      {
        _id: "005",
        flight: "DEL-BLR",
        date: "20 Dec 2023",
        type: "inflight seat upgrade",
        points: "250",

      },
    ],
  },
  {
    firstname: "Richard",
    lastname:"Smith",
    trips: "7",
    tier:"Silver",
    points: "1500",
    status: "Active",
    lastupdate: "01 Mar 2024",
    tripDetails: [
      {
        _id: "001",
        flight: "DEL-BOM",
        date: "01 March 2024",
        type: "inflight seat upgrade",
        points: "200",

      },
      {
        _id: "002",
        flight: "AHMD-DEL",
        date: "29 Feb 2024",
        type: "inflight seat upgrade",
        points: "200"

      },
      {
        _id: "003",
        flight: "DEL-AHMD",
        date: "22 Feb 2024",
        type: "inflight seat upgrade",
        points: "250",
      },
      {
        _id: "004",
        flight: "BLR-DEL",
        date: "20 Feb 2024",
        type: "inflight seat upgrade",
        points: "200"

      },
      {
        _id: "005",
        flight: "DEL-BLR",
        date: "29 Jan 2024",
        type: "inflight seat upgrade",
        points: "250",

      },
      {
        _id: "006",
        flight: "DEL-BOM",
        date: "24 Jan 2024",
        type: "inflight seat upgrade",
        points: "200",

      },
      {
        _id: "007",
        flight: "AHMD-DEL",
        date: "20 Jan 2024",
        type: "inflight seat upgrade",
        points: "200"

      },

    ],
  },
  {
    firstname: "Emma",
    lastname: "Johnson",
    trips: "4",
    tier: "Gold",
    points: "2000",
    status: "Active",
    lastupdate: "28 Feb 2024",
    tripDetails: [
      {
        _id: "001",
        flight: "DEL- AHMD",
        date: "28  Feb 2024",
        type: "inflight meal upgrade",
        points: "500",
      },
      {
        _id: "002",
        flight: "AHMd - BOM",
        date: "28 Feb 2024",
        type: "inflight seat upgrade",
        points: "500",
      },
      {
        _id: "003",
        flight: "BOM - BLR",
        date: "25 Feb 2024",
        type: "inflight seat upgrade",
        points: "500",
      },
      {
        _id: "004",
        flight: "BLR - DEL",
        date: "22 Feb 2024",
        type: "inflight meal upgrade",
        points: "500",
      },
    ],
  },
  {
    firstname: "Sophia",
    lastname: "Garcia",
    trips: "2",
    tier: "Silver",
    points: "800",
    status: "Active",
    lastupdate: "01 Mar 2024",
    tripDetails: [
      {
        _id: "001",
        flight: "DEL-HSR",
        date: "28 Feb 2024",
        type: "inflight seat upgrade",
        points: "400",
      },
      {
        _id: "002",
        flight: "HSR-DEL",
        date: "01 Mar 2024",
        type: "inflight meal upgrade",
        points: "400",
      },
    ],
  },
  {
    firstname: "Liam",
    lastname: "Martinez",
    trips: "6",
    tier: "Platinum",
    points: "1600",
    status: "Active",
    lastupdate: "03 Mar 2024",
    tripDetails: [
      {
        _id: "001",
        flight: "DEL-HSR",
        date: "02 Mar 2024",
        type: "inflight seat upgrade",
        points: "200",
      },
      {
        _id: "002",
        flight: "HSR-DEL",
        date: "25 Feb 2024",
        type: "inflight meal upgrade",
        points: "150",
      },
      {
        _id: "003",
        flight: "DEL - AHMD",
        date: "20 Feb 2024",
        type: "inflight seat upgrade",
        points: "200",
      },
      {
        _id: "004",
        flight: "AHMD - BOM",
        date: "15 Feb 2024",
        type: "inflight seat upgrade",
        points: "200",
      },
      {
        _id: "005",
        flight: "BOM - BLR",
        date: "22 Jan 2024",
        type: "inflight meal upgrade",
        points: "150",
      },
      {
        _id: "006",
        flight: "BLR - DEL",
        date: "15 Jan 2024",
        type: "inflight seat upgrade",
        points: "200",
      },
    ],
  },
];

const actionColumnProperties = {
  pinned: "right",
  minWidth: 80,
  width: 200,
  autoHeight: true,
  tooltipField: "View Items",
};

export const Loyalty = () => {
  const [searchKey,setSearchKey]=useState("");

  const { hasPermission: viewLoyalty } = usePermissions('viewDetails', 'loyalty');

  const [actionItems] = useState([
    {
      title: "Actions",
      attribute: "viewItems",
    },
  ]);
  const [state, setState] = useState({});


  const onAction = (e, action, row) => {
    onActionInner(e, action, row);
  };

  const onSearch=(e)=>{

    setSearchKey(e.target.value)


  }
  const onActionInner = (e, action, row) => {
    if (action.value === "viewItems" && viewLoyalty) {
      setState((_prev) => {
        return { ..._prev, selected: { ...row, action: action.value } };
      });
    }
  };

  const getActionCellIcons = (cellDef, data) => {
    if (cellDef.attribute === "viewItems") {
      // if (data && data.status === 'created') {
      //     return [{src: editIcon2, value:"edit", title: 'Edit'}];
      // } else {}
      return [{ src: viewIcon, value: "viewItems", title: "View Passenger" }];
    }

    return [];
  };

  const onClose = () => {
    setState((_prev) => {
      return { ..._prev, selected: false };
    });
  };

  if (
    state &&
    Boolean(state.selected) &&
    state.selected?.action === "viewItems"
  ) {
    return (
      <div className="flexCol full-flex ag-theme-alpine-dark">
        <div className="justifyContentSpaceBetween flex alignItemsCenter">
          <h4>Passenger Details</h4>
          <img
            className="modal__close_icon"
            alt="Close"
            src={closeIcon}
            onClick={onClose}
            style={{ height: "auto" }}
          />
        </div>
        <div className="passengerDetailsBody" style={{ marginTop: 10 }}>
          <table>
            {state.selected.firstname &&
              state.selected.tripDetails.map((trip, index) => (
                <tr key={index}>
                  <td>
                    <ol>
                      {index + 1} . {trip.flight} - {trip.date} - {trip.type} - {trip.points}
                    </ol>
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className="flexCol full-flex ag-theme-alpine-dark">
       <div className=' flexRow justifyContentCenter margBot10 margTop8 alignItemsCenter'>
          <div className="margBot10 underline">
            <span><img  className="icon-size" alt='search' src={searchIcon} /></span>
            <span><input className="search" style={{color:'#ffffff'}} onChange={onSearch} type="search" placeholder="Search" value={searchKey}/></span>
            </div>
            </div>

      {/* Table Section */}
      <DataGrid
        className="full-flex"
        columns={[...Columns]}
        actionProperties={actionColumnProperties}
        showDefaultFilters={false}
        rowModelType="clientSide"
        actions={actionItems}
        rowData={rowData}
        columnResize={true}
        rowSelection="multiple"
        getActionCellIcons={getActionCellIcons}
        onAction={(e, action, data) => {
          return onAction(e, action, data);
        }}
      />
    </div>
  );
};
