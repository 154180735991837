/* eslint-disable react-hooks/exhaustive-deps */


import React,{useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../assets/styles/styles.scss';
import {downloadReportOptions,MIMETypes} from "../../../../utils/configs";
import {translationLength ,updateButtonTxtStatus,onDownloadCSVFile} from '../../../../utils/commonFunctions';
import { APIEndpoint, DFPAPIEndpoint } from "../../../../utils/axios";
import { NGODatePicker,Loading,EditModal} from "../../../../common";
import searchIcon from '../../../../assets/icons/search.svg';
import closeIcon from '../../../../images/icons/Icon2.png';
import LoadingIn from '../../../../assets/loadingIn.gif';
import AllMultiSelect from "../../../../common/AllSelectCheckbox.jsx";
import downloadMasterIcon from '../../../../assets/icons/download-master.svg';
import { components } from "react-select";
import usePermissions from '../../../../utils/userPermissions';
import moment from 'moment';
import Select from 'react-select';
import {
  SalesReportsDownloadComponent
} from "../../../sales/inner-components/renderers";
import * as ReactDOM from "react-dom";
import { useState } from 'react';

let prevMenu = null;
let timeout;

export const ReportsPage = (props) => {
  const { t } = useTranslation();
  const [isDownloading,setIsDownloading]=useState(false);
  const [state,setState]=useState({disabled:false});
  const [sectors,setSectors]=useState([]);
  const[showUpload, setShowUpload]= useState(false);
  const [reportDownloadErr, setReportDownloadErr] = useState(null);
  const [optionSelectedCrew,setOptionSelectedCrew]=useState([]);
const [optionSelectedSector,setOptionSelectedSector]=useState([]);
  const modelStyle = { maxWidth: '80%' };
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [editFormFields, setEditFormFields] = useState([]);
  const [ modalTitle, setModalTitle ] = useState('View Purchase Order Items');
  const [sectorList, setSectorsList] = useState([]);
  const [reportsType, setReportTypes] = useState([]);
  const [reptType,setReptType]=useState("");
  const [modalCustomButtons, setModalCustomButtons] = useState(null);
  const [, setUpdatedFormFieldValues] = useState({});
  const [stores, setStores] = useState([]);
  const[reportsSelected,setReportSelected]=useState("");
  const [searchKey,setSearchKey]=useState("");
  const [reportOptions, setReportOptions] = useState(downloadReportOptions);
  const [reportOptionsFiltered, setReportOptionsFiltered] = useState(downloadReportOptions);
  const [storeType,setStoreType]=useState("");
const [reportDate,setReportDate]=useState({startDate:"",endDate:""});
const [showDownload,setShowDownload]=useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [reptTypeCash,setReptTypeCash]=useState('');
  const [reptTypeProcurement,setReptTypeProcurement]=useState('');
  const [modalFormData, setModalFormData] = useState({});
  const [selWarehouse, setSelWarehouse] = useState('');
  const [crewName,setCrewName]=useState("");
  const [reportTypes, setReportTypesWareHouse] = useState([]);
  const [,setResponse]=useState(null);
  const [isDownloadPressed,setIsDownloadPressed]=useState(false);
  const [crewMembers,setCrewMembers]=useState([]);
  const [estimateDate, setEstimateDate] = useState(new Date());
  const sType = localStorage.getItem('sType');
  const [cursorDisabled,setCursorDisabled]=useState(false);
  const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);
  const { hasPermission: downloadReports } = usePermissions('download', "reports");

  const ReportFields = [
    { label: 'Report Type', attribute: 'reportType', type: 'select', required: true,disabled:true },
    { label: 'Start Date', attribute: 'startDate', type: 'date', required: true,value:new Date(moment()),maxDate:new Date(moment())},
    { label: 'End Date', attribute: 'endDate', type: 'date', required: true ,value:new Date(moment()),maxDate:new Date(moment())},
  ]
  const [reportsFields, setReportFields] = useState(ReportFields||[]);

useEffect(() => {
  loadReportTypes();
  getWarehouses();
  getSectorList();
}, [])

useEffect(() => {
  if(cursorDisabled){
    setTimeout( () => {
      setCursorDisabled(false);
  }, 7000 );
  }
}, [cursorDisabled])

useEffect(() => {
  if (
    modalFormData && isDownloadPressed &&
    state?.selected &&
    (state.selected.action === "downloadPOReportsPopup" || state.selected.action === "Suspend") &&
    reportsSelected
  ) {
    const action = state.selected.action;
    modalFormData.reportType=reportsSelected;
    validateFormInputs(modalFormData, action);
  }
}, [modalFormData, state, reportsSelected]);

useEffect(() => {
  if(!searchKey){
    prevMenu=null;
    setReportOptionsFiltered(downloadReportOptions);
  }
}, [searchKey])

useEffect(() => {
  if(reportsType.length&&reportsSelected.length){
    setReptType(reportsType.find(rpt=>rpt.label===reportsSelected)?.value)
  }

}, [reportsType,reportsSelected])

useEffect(() => {
  if(state&&state.cashReportTypes&&state.cashReportTypes.length){
    const reportsCash=state.cashReportTypes.find(rpt=>rpt.label===reportsSelected)?.value
    if(reportsCash==="cashierBankTransferReport"){
      handleOnchangeReports(null,{reportType:"cashierBankTransferReport"},null)
         }else{
          setReportFields(ReportFields)
         }
    setReptTypeCash(reportsCash)
  }

}, [reportsSelected,state])

useEffect(() => {
  if(state&&state.selected&&reportTypes.length){
    setReptTypeProcurement(reportTypes.find(rpt=>rpt.label===reportsSelected)?.value)
  }
  if(state.showActionDialog==='report'){
    setReportFields((prev)=>{
      return prev?.map((_) => {
        if(_.label ==='End Date'){
          return { ..._, minDate:new Date(ReportFields[1].value), }

        }
        else {
          return { ..._,}
        }
      })
    })
  }

}, [reportsSelected,reportTypes,state])

useEffect(() => {
  const updateDisabledState = () => {
    if (!reptType) return true;

    switch (reptType) {
      case 'crewCommission':
      case 'crewVariance':
      case 'crewReconVariance':
        return (Boolean(reportDate.startDate) && Boolean(reportDate.endDate) && crewName.length)? false:true
      case 'upliftAuditReport':
        case 'sandboxSectorSalesReport':
          case 'sandboxFlightRoutesReport':
            case 'inventorySnapshot':
              case 'allCrewCommission':
              case 'groupWiseInventorySnapshot':
                case 'mealRefusedReport':
        return (Boolean(reportDate.startDate) && Boolean(reportDate.endDate))? false:true
case 'sandboxSalesSummary':
  case 'sandboxInvoiceSummary':
  return (Boolean(reportDate.startDate) && Boolean(reportDate.endDate) && storeType.length && storeType[0]?.value)? false:true
      case 'customerInteractionsReport':
        return !(optionSelectedCrew.length && optionSelectedSector.length);

      default:
        return true;
    }
  };

  setState((_prev) => ({
    ..._prev,
    disabled: updateDisabledState(),
  }));
}, [reptType, crewName, reportDate,optionSelectedCrew, storeType,optionSelectedSector]);



    const preCompile = {
      warehouse: 'Warehouse',
      selectWarehouses: 'Select Warehouses',
      selectReportType: 'Select Report Type',
  };

  const getMenuGroups = (m, ind) => {
    if (prevMenu === m && !searchKey) {
      return '';
    }
    if(prevMenu === m&&searchKey>=3){
      prevMenu = m;
      return m;
    }
    prevMenu = m;
      return m;

  };
  const Option = props => {
    return (
    <div>
    <components.Option {...props}>
    <input
    type="checkbox"
    checked={props.isSelected}
    onChange={() => null}
    />{" "}
    <label>{props.label}</label>
    </components.Option>
    </div>
    );
    };

  const MultiValue = props => (
    <components.MultiValue {...props}>
    <span>{props.data.label}</span>
    </components.MultiValue>
    );

    const customStyles = {
      input: (defaultStyles) => ({
        ...defaultStyles,
        color: '#fff',
      }),
      control: (defaultStyles) => ({
        ...defaultStyles,
        backgroundColor: "#162E3E",
        padding: "2px",
        width: '17rem',
        marginTop: '0.5rem',
        border: "0.4px solid #FFFFFF",
        boxShadow: "none",
      }),
      option: (defaultStyles) => ({
        ...defaultStyles,
        color: '#fff',
        backgroundColor: '#162E3E',
        textAlign: 'left',
      }),
      singleValue: (defaultStyles) => ({
        ...defaultStyles,
        color: '#fff',
      }),
    };


  const newCustomStyles = {
    input: (defaultStyles) => ({
      ...defaultStyles,
      color: '#fff',
      }),
      control: (defaultStyles) => ({
        ...defaultStyles,
        backgroundColor: "#162E3E",
        padding: "2px",
        width:'17rem',
        marginBottom: '1rem',
        border: "0.4px solid #FFFFFF",
        boxShadow: "none",
        PointerEvent: 'none',
      }),
      option: (defaultStyles) => ({
        ...defaultStyles,
        textAlign: 'left',
      }),
      placeholder: (defaultStyles) => ({
        ...defaultStyles,
        textAlign: 'left',
      })
  };

   const onDownloadReports=async(m)=>{
const {menu,reportsType,disabled}=m;
  if(disabled){
  return null
  }
switch (menu) {
  case "Reconciled Sale":
    setReportSelected(reportsType)
     onDownloadReconciled();
    break;
    case "POS Sales":
      setReportSelected(reportsType)
     onDownloadReport(menu)
      break;
       case "Estimates":
        getWarehouses();
        setShowUpload(true)
      break;
       case "Cash register":
        setReportSelected(reportsType)
        handleDownloadReportBtn();
      break;
       case "Item Master":
        onDownloadItemMaster();
      break;
      case "Price Master":
        onDownloadPriceList();
      break;
      case "Combos":
        onDownloadCombo();
      break;
      case "Vendor Mapping":
        getWarehouses();
        onDowloadVendorMapping();
      break;
      case "Procurement":
        setReportSelected(reportsType)
        onDownLoadPurchaseOrders();
        loadReportTypes();
        getWarehouses();
      break;
      case "Employees":
        onDownloadUsers();
      break;
      case "Activity":
        onDownloadActivityReport();
        break;
          case "Crew Performance":
            setReportSelected(reportsType)
                onDownloadReport(menu);
                getCrewDetails();
                break;

      case "Sectors":
        onDownloadSectors();
      break;
      case "Vendors":

      break;
       case "Distributors":
        onDownloadDistributors();
      break;
      case "Devices Activity":

        break;
        case "Aircraft Operation Status":
onDownloadAircraftOpn()
          break;
  default:
    break;
}
   }
   const onStoreChange=(e)=>{
    if(reptType==="sandboxSalesSummary"||reptType==="sandboxInvoiceSummary"){
        setStoreType([e])

    }
    else{
        setStoreType([])

    }
}

const onCrewChange=(e)=>{
  if(reptType==="crewCommission"||reptType==="crewVariance" ||reptType==="crewReconVariance"){
      setCrewName([e])

  }
  else{
      setCrewName([])

  }
}

function getCrewName(crew){
  const [crewOrg] = crew
  if(crewOrg?.value){
    return [crewOrg?.value]
  }else{
    return [crewOrg]
  }
}

const onDateChangeRep=(e)=>{
  const {name,value}=e.target;
  if(name==='startDate'){
      setReportDate((prev)=>({...prev,startDate:value}))
  }
  else{
      setReportDate((prev)=>({...prev,endDate:value}))
  }
  }
  const onReportTypeChange = (e) => {
    setReptType(e.target.value);
}

   const onDownloadReconciled= async (e) => {
    const action = { value: "downloadReportPopup" };
    onActionInnerRecon(e, action, {});
  };

   const onDownLoadPurchaseOrders = (e) => {
    onActionInner({value: 'downloadPOReportsPopup'}, {});

}

const onDowloadVendorMapping=()=>{
  onActionInnerVendMapp({value: 'vendorMapping'}, {});
}

const onActionInnerRecon = (e, action, row) => {

  ReactDOM.flushSync(() => {
    // let updatedEditFields = [];

   if (action.value === "downloadReportPopup") {
      setState((_prev) => {
        return { ..._prev, selected: { ...row, action: action.value } };
      });
    }
  });
};

const onDownloadReport=async(type)=>{
  setCursorDisabled(true);
if(type==="POS Sales"){
    try {
      const resp = await DOMAIN.get('reports/sandboxReportTypes');
      if (resp.result.length > 0) {
          const options = [{key: 0, label: "Select Report Type", value: ''}];
          resp.result.filter( (fieldValue, index) => {
            options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.id}`});
            return fieldValue;
          });
          setReportTypes(options);
      }
      else{
          console.log("error")
      }


      const respStore = await DOMAIN.get('stores');
      if (respStore.result.length > 0) {

          const options = [{key: 0, label: "Select Route", value: ''}];
          respStore.result.filter( (fieldValue, index) => {
            options.push({key: index+1, label: `${fieldValue.flightNumber} (${fieldValue.originName}-${fieldValue.destinationName})`, value: `${fieldValue.id}`});
            return fieldValue;
          });
          setStores(options);
      }
      else{
          console.log("error")
      }


  } catch (error) {

  }

}else if(type!=='Crew Performance'){
  try {
    const resp = await DOMAIN.get('reports/reportTypes');
    if (resp.result.length > 0) {
        const options = [{key: 0, label: "Select Report Type", value: ''}];
        resp.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.id}`});
          return fieldValue;
        });
        setReportTypes(options);
    }
    else{
        console.log("error")
    }


    const respStore = await DOMAIN.get('stores');
    if (respStore.result.length > 0) {

        const options = [{key: 0, label: "Select Route", value: ''}];
        respStore.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: `${fieldValue.flightNumber} (${fieldValue.originName}-${fieldValue.destinationName})`, value: `${fieldValue.id}`});
          return fieldValue;
        });
        setStores(options);
    }
    else{
        console.log("error")
    }


} catch (error) {

}

}
else{
  try {
    const resp = await DOMAIN.get('reports/crewReportTypes');
    if (resp.result.length > 0) {
        const options = [{key: 0, label: "Select Report Type", value: ''}];
        resp.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.id}`});
          return fieldValue;
        });
        setReportTypes(options);
    }
    else{
        console.log("error")
    }


} catch (error) {

}
}
      setShowDownload(true)


  setCursorDisabled(false);

        }

const onDownloadReportFinal=async()=>{
if(reportsSelected!=='Crew Commission Summary Report' && reportsSelected!=='Crew Commission Report' && reportsSelected!=='Crew Stock Variance Report' && reportsSelected!=='Customer Interactions Report' &&  reportsSelected!=='Crew Reconciliation Variance Report'){
    const body={
    startDate: reportDate.startDate,
    endDate: reportDate.endDate,
    reportType: reptType,
    storeId: storeType.length>0?storeType[0].value:null
}

    let header={
      'Accept':'text/csv'
    }
        try {
        const res= await DOMAIN.post(`sales/reports`,body,header);
        if(res.status==='scheduled'){
          setIsDownloading(true)
          setTimeout(() => {
            const dowId= DOMAIN.get(`downloads/status/${res.id}`);
            dowId.then((val) => {
              if (val.status === "completed") {

                  DOMAIN.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
                      .then((ItemMasterDwnld) => {
                          const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute('download',`${val.fileName}.${val.fileExtension}`);
                          document.body.appendChild(link);
                          link.click();

                          setIsDownloading(false);
                      })
                      .catch((error) => {

                          console.error('Error downloading CSV:', error);
                          setIsDownloading(false);
                      });
              }
          });
            }, 8000);
        }
        }
         catch (error) {

          setIsDownloading(false)
         }
}else{

  let body = {
    startDate: reportDate.startDate,
    endDate: reportDate.endDate,
    reportType: reptType,
  };

  switch (reptType) {
    case 'crewCommission':
    case 'crewVariance':
    case 'crewReconVariance':
      body.crewCode = getCrewName(crewName);
      break;
    case 'customerInteractionsReport':
      body.crewCode = optionSelectedCrew?.map(crew => crew.value);
      body.sectors = optionSelectedSector?.map(sect => sect.value.replace(/\s+/g, ''));
      break;
    case 'allCrewCommission':
      break;
    default:
      break;
  }


  let header = {
    Accept: "text/csv",
  };
  if(body && reptType){
       try {
    setIsDownloading(true);
    const res = await DOMAIN.post(`crew/download/${reptType}`, body, header);
    if (res.status === "scheduled") {
      setTimeout(async () => {
        try {
          setIsDownloading(true);
          const dowId = await DOMAIN.get(`downloads/status/${res.id}`);
          const ItemMasterDwnld = await DOMAIN.get(
            `downloads/analytics/${dowId.fileName}${dowId.fileExtension}`,
          );
          const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${dowId.fileName}.${dowId.fileExtension}`,
          );
          document.body.appendChild(link);
          link.click();
          setIsDownloading(false);
        } catch (error) {
          setIsDownloading(false);
          const err = JSON.parse(error.message);
          const errMsg = err?.data?.error?.title || err?.data?.message;
          setReportDownloadErr(errMsg);
        }
      }, 5000);
    }
  } catch (error) {
    setIsDownloading(false);
  }
  }



}

}
const onDownloadActivityReport=async()=>{
  let header={
    'Accept':'text/csv'
  }
      try {
      const res= await DOMAIN.post(`devices/activityReport`,{},header);

      if(res.status==='scheduled'){

        setTimeout(() => {
          setIsDownloading(true)
          const dowId= DOMAIN.get(`downloads/status/${res.id}`);
          dowId.then((val) => {
            if (val.status === "completed") {
                DOMAIN.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
                    .then((ItemMasterDwnld) => {
                        const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${val.fileName}.${val.fileExtension}`);
                        document.body.appendChild(link);
                        link.click();
                        setIsDownloading(false);
                    })
                    .catch((error) => {

                        console.error('Error downloading CSV:', error);
                        setIsDownloading(false);
                    });
            }
        });
          }, 8000);

      }
      }
       catch (error) {


       }
    }

    const onDownloadAircraftOpn=async()=>{
      let header={
        'Accept':'text/csv'
      }
          try {
          const res= await DOMAIN.post(`aircrafts/operationStats`,{},header);
          if(res.status==='scheduled'){



            setTimeout(() => {
              setIsDownloading(true)
              const dowId= DOMAIN.get(`downloads/status/${res.id}`);
              dowId.then((val) => {
                if (val.status === "completed") {
                    DOMAIN.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
                        .then((ItemMasterDwnld) => {
                            const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${val.fileName}.${val.fileExtension}`);
                            document.body.appendChild(link);
                            link.click();
                            setIsDownloading(false);
                        })
                        .catch((error) => {

                            console.error('Error downloading CSV:', error);
                            setIsDownloading(false);
                        });
                }
            });
              }, 8000);

          }
          }
           catch (error) {


           }
    }
const onActionInner = (action, row) => {
  let updatedEditFields = [];
 if (action.value === 'downloadPOReportsPopup') {

      setModalTitle('Download Reports');

      updatedEditFields = [

          { key: 1, label: 'Report Type', name: 'reportType', id: 'reportType', attribute: 'reportType', type: 'select',

              required: true, className: 'select-search-cls'
              , options: reportTypes,disabled:true

          },
          { key: 2, label: 'Select Warehouses', name: 'selectWarehouses', id: 'selectWarehouses', attribute: 'selectWarehouses', type: 'rcSelectAll', disabled: false,
              required: true, className: 'select-search-cls',
              options: warehouses
          },


          { key: 3, label: 'Start Date', name: 'startDate', id: 'startDate', attribute: 'startDate', type: 'date', required: true, disabled: false,
              placeholder: t('datePickerPlaceholder'),
              className: 'date-input',

          },
          { key: 4, label: 'End Date', name: 'endDate', id: 'endDate', attribute: 'endDate', type: 'date', required: true, disabled: false,
              placeholder: t('datePickerPlaceholder'),
              className: 'date-input',

          },

      ];
      setModalCustomButtons([

          { label: 'Cancel', attribute: 'button', type: 'button', disabled: false, className: 'cancel-button', action: (e) => {
                  onClose();
                  return false;
              }
          },
          { label: 'Download', attribute: 'button', type: 'button', disabled: false, className: 'submit-button'
              , action: 'onSubmit'
          }
      ]);

  }
  setEditFormFields( updatedEditFields );

  setState((_prev) => {
      return { ..._prev, selected: { ...row, action: action.value} };
  });
};



const onActionInnerVendMapp = (action, row) => {
  let updatedEditFields = [];
 if (action.value === 'vendorMapping') {

      setModalTitle('Download Vendor Reports');

      updatedEditFields = [
          { key: 1, label: 'Select Warehouses', name: 'selectWarehouses', id: 'selectWarehouses', attribute: 'selectWarehouses', type: 'rcSelectAll', disabled: false,
              required: true, className: 'select-search-cls',
              options: warehouses
          },

      ];
      setModalCustomButtons([

          { label: 'Cancel', attribute: 'button', type: 'button', disabled: false, className: 'cancel-button', action: (e) => {
                  onClose();
                  return false;
              }
          },
          { label: 'Download', attribute: 'button', type: 'button', disabled: false, className: 'submit-button'
              , action: 'onSubmit'
          }
      ]);

  }
  setEditFormFields( updatedEditFields );

  setState((_prev) => {
      return { ..._prev, selected: { ...row, action: action.value} };
  });
};



   const getWarehouses = async () => {
    try {
    const resp = await DOMAIN.get('warehouses');
    if (resp.result.length > 0) {
        const options = [];
        resp.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.id}`});
          return fieldValue;
        });
        setWarehouses(options);
    }
    } catch (error) {

    }

  }

  const getCrewDetails = async () => {
    const resp = await DOMAIN.get('sandboxing/employees?includeAdmin=no');
    if (resp.result.length > 0) {
        const options = [{key: 0, label: "Select Crew", value: ''}];
        resp.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: `${fieldValue.employeeName} (${fieldValue.employeeCode})`, value: `${fieldValue.employeeCode}`});
          return fieldValue;
        });
        setCrewMembers(options);
    }
    const resp3 = await DOMAIN.get('stores');
    if (resp3.result.length > 0) {
        const options = [{key: 0, label: "Select Sectors", value: ''}];
        resp3.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: `${fieldValue.flightNumber} (${fieldValue.originName}-${fieldValue.destinationName})`, value: `${fieldValue.value}`});
          return fieldValue;
        });
        setSectors(options);
    }
  }


  const getSectorList = async() => {
    try {
           const resp= await APIEndpoint.get('stores')
            const list = [];
            if (resp.success) {
              // eslint-disable-next-line array-callback-return
              resp.result.map((_) => {
                list.push({ value: _.id, id: _.id, label: _.value });
              });

              setSectorsList(list);
            }


    } catch (error) {

    }

    return false;
  };

  const updateEditFormValues = async (e, updatedFormObj) => {

    ReactDOM.flushSync( () => {
        if (e) {
            const selectedDistributor = reportTypes.find( item => item.value === e);

            /** Set to respective fields */
            const updatedEditFields = editFormFields.filter( (field) => {

                if (selectedDistributor ) {
                    if ( field.attribute === 'reportType' ) {
                        field.value = selectedDistributor.label;
                    }
                }

                return field;

            });

            setEditFormFields( _prev => {
                return [ ...updatedEditFields ] ;
            });
        }
    })


};


  const loadReportTypes = async () => {
    try {
       const resp = await APIEndpoint.get('warehousereports/warehousereportTypes');
    if (resp.result.length > 0) {
        const options = [{key: 0, label: preCompile.selectReportType, value: '', id: ''}];
        resp.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.value}`, id: `${fieldValue.id}`});

          return fieldValue;
        });

        setReportTypesWareHouse(options);
    }
    } catch (error) {

    }

}

  const onDateChange=(e)=>{
    let date = e.target.value;
    setEstimateDate(date);
    fetchUpliftEstimates(date);
  }

  const onItemTypeChange = (e) => {
    setSelWarehouse(e.target.value);
    fetchUpliftEstimates(e.target.value);
  }

  const handleChange = (selected) => {
    setOptionSelectedCrew(selected)
  };

  const handleChangeSector = (selected) => {
    setOptionSelectedSector(selected)
  };

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
  };

  const fetchUpliftEstimates = async () => {
    filterData.page = 1;
    filterData.limit = 50;
    filterData.filters = [{targetDate: moment(estimateDate).format('YYYY-MM-DD[T]HH:mm:ss')}, {warehouseId: Number(selWarehouse)}];
    const res = await DOMAIN.post('estimates/getUpliftEstimates', filterData);
    setResponse(res?.success);
    return res;
  }

  const onDownloadDistributors=async()=>{
    let header={
      'Accept':'text/csv'
    }
    try {
    const res= await APIEndpoint.post(`downloads/distributorMaster`,{reportType: "Distributors"},header);
    if(res.status==='scheduled'){
      setTimeout(() => {
        const dowId = APIEndpoint.get(`downloads/status/${res.id}`);
        dowId.then( async (val)=> {
          if(val.status==="completed"){
               APIEndpoint.get(`downloads/analytics/${val.fileName}${val.fileExtension}`) .then((ItemMasterDwnld) => {
              const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', val.fileName+'.csv'); //or any other extension
              document.body.appendChild(link);
              link.click();

          })





            ;

          }
        })
        }, 4000);
    }
    }
      catch (res) {


        console.log(res.message)
      }
  }

  const onDownloadSectors=async()=>{
    let header={
      'Accept':'text/csv'
    }
    try {
    const res= await APIEndpoint.post(`downloads/routes`,{reportType: "sectors"},header);
    if(res.status==='scheduled'){

      setTimeout(() => {
        const dowId = APIEndpoint.get(`downloads/status/${res.id}`);
        dowId.then((val)=> {
          if(val.status==="completed"){
             APIEndpoint.get(`downloads/analytics/${val.fileName}${val.fileExtension}`) .then((ItemMasterDwnld) => {
              const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', val.fileName+'.csv'); //or any other extension
              document.body.appendChild(link);
              link.click();

          });

          }
        })
        }, 2000);
    }
    }
      catch (res) {


        console.log(res.message)
      }
  }


  const onDownloadUsers=async()=>{
    let header={
      'Accept':'text/csv'
    }
        try {
        const res= await DOMAIN.post(`employees/activeUsers`,{},header);
        if(res.status==='scheduled'){

          const dowId=await DOMAIN.get(`downloads/status/${res.id}`);
          if(dowId.status==="completed"){
          DOMAIN.get(`downloads/analytics/${dowId.fileName}${dowId.fileExtension}`).then((ItemMasterDwnld) => {
        const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Active_Users.csv');
          document.body.appendChild(link);
          link.click();

        });

          }
        }
        }
         catch (error) {


          setState((_) => {
            return { ..._,message:{text:error?.data?.message||"Something went wrong!"},};
          })
          setTimeout(() => {
            setState((prevState) => ({
              ...prevState,
              message: null,
            }));
          }, 5000);
         }
      }
// const getStatusMessage=(report)=>{
//   if(report&&isDownloading.length){
//     return isDownloading.find(st=>st.rept===report)?.stat;
//   }
//   else{
//     return null
//   }
// }


  const onDownloadEstimates= async ()=>{
    try {
    const res= await DOMAIN.post(`estimates/download`,{startDate: moment(estimateDate), warehouseId: [selWarehouse]}, {
      'Accept': 'text/csv',
      'Content-Type': 'application/json;charset=utf-8'
    });

    if(res.status==='scheduled'){
      setTimeout(() => {
        checkAndDownloadFile(res)
      }, 5000);
    }
    }
      catch (res) {

        console.log(res.message)
      }
  }

  const onDownloadPriceList=async()=>{
    let header={
      'Accept':'text/csv'
    }
    try {
    const date = new Date().toISOString()
    let request_payload = {
      startDate: date,
      endDate: date,
      listName: state.filterPrice
    }
    const res= await DOMAIN.post(`downloads/priceList`,request_payload,header);
    if(res.status==='scheduled'){
      const dowId=await DOMAIN.get(`downloads/status/${res.id}`);
      if(dowId.status==="completed"){
       DOMAIN.get(`downloads/analytics/${dowId.fileName}${dowId.fileExtension}`).then((ItemMasterDwnld) => {
        const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', dowId.fileName+'.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
       setIsDownloading(true)
    })
 ;
      }
    }
    }
      catch (res) {
        setIsDownloading(false)


        console.log(res.message)
      }
  }

  const checkAndDownloadFile = (res) => {
    const dowId = DOMAIN.get(`downloads/status/${res.id}`);
    dowId.then( async (val)=> {
      if(val.status==="completed"){
        setIsDownloading(false)

        DOMAIN.get(`downloads/analytics/${val.fileName}${val.fileExtension}`).then((ItemMasterDwnld) => {
          const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', val.fileName + val.fileExtension); //or any other extension
          document.body.appendChild(link);
          link.click();
      });
      }
      else {
        setIsDownloading(true)



        // setTimeout(() => {
        //   checkAndDownloadFile(res);
        // }, 7000);

      }
    });
  }



  const handleDownloadReportBtn = async () => {
    setCursorDisabled(true);

    try {
      const [cashierReportTypesRes, cashDrawersRes] = await Promise.all([
        APIEndpoint.get('reports/cashierReportTypes'),
        APIEndpoint.get('cashRegister/cashDrawers')
      ]);

      const cashierReportTypesList = cashierReportTypesRes.result.map(item => ({label: item.value,value: item.id}));
      cashierReportTypesList.unshift({ label: t('pages.cashRegisterContent.cashDeposits.cashReportPlaceholder'), value: "" });

      const cashDrawerDownloadList = cashDrawersRes.result.map((item, index) => ({key: index,label: item.value,value: item.id}));
      cashDrawerDownloadList.unshift({ label: t('pages.cashRegisterContent.cashDrawer.selectPlaceholder'), value: '' });

      setState(prevState => ({
        ...prevState,
        showActionDialog: 'report',
        cashReportTypes: cashierReportTypesList,
        cashDrawerDownload: cashDrawerDownloadList
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDownloadReportSubmit = async (e, updated) => {

    updated={...updated,reportType:reptTypeCash,startDate:updated && updated.startDate?updated.startDate:ReportFields[1].value,endDate:updated && updated.endDate?updated.endDate:ReportFields[2].value}
    let valid = ReportFields.some((_) => {
       return updated[_.attribute]
     })
     if (valid) {
       setState((prevState) => ({
         ...prevState,
         showAPILoading: true,
       }));

         let body ={}
         if(!updated.cashDrawer){
          body= {
             endDate: moment(new Date(updated.endDate)).subtract(1, 'days').format("YYYY-MM-DDTHH:mm:ssZ"),
             reportType: updated.reportType,
             startDate: moment(new Date(updated.startDate)).subtract(1, 'days').format("YYYY-MM-DDTHH:mm:ssZ")
           }
         }else{
           body= {
             endDate: moment(new Date(updated.endDate)).subtract(1, 'days').format("YYYY-MM-DDTHH:mm:ssZ"),
             reportType: updated.reportType,
             cashDrawerId:updated.cashDrawer,
             startDate: moment(new Date(updated.startDate)).subtract(1, 'days').format("YYYY-MM-DDTHH:mm:ssZ")
           }
         }
   let header={
         'Accept':'text/csv'
       }
      try {

             const res= await DOMAIN.post('cashier/download/' + body.reportType, body,header);

           if(res.status==='scheduled'){
       setIsDownloading(true)
             setTimeout(() => {
               const dowId = DOMAIN.get(`downloads/status/${res.id}`);
               dowId.then((val) => {
                 if (val.status === "completed") {
                     DOMAIN.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
                         .then((ItemMasterDwnld) => {
                             const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                             const link = document.createElement('a');
                             link.href = url;
                             link.setAttribute('download', val.fileName + '.csv');
                             document.body.appendChild(link);
                             link.click();
                             setState((_) => ({ ..._,actionDialogData: null,showAPILoading:false }))
                          setIsDownloading(false)

                         })
                         .catch((error) => {
                          setIsDownloading(false)

                             console.error('Error downloading CSV:', error);
                         });

                 }
             });
               }, 8000);
           }
           }
            catch (error) {
              setIsDownloading(false)
             setState((_) => {
               return { ..._,message:{text:error?.data?.message},};
             })
             setTimeout(() => {
               setState((prevState) => ({
                 ...prevState,
                 message: null,
                 actionDialogData: null,showAPILoading:false
               }));
             }, 5000);
            }


     }

   }

  const onDownloadCombo=async()=>{
    let header={
      'Accept':'text/csv'
    }
    try {
    const res= await APIEndpoint.post(`sales/reports`,{reportType: "comboItemMaster"},header);
    if(res.status==='scheduled'){
      setIsDownloading(true)
      setTimeout(() => {
        const dowId = APIEndpoint.get(`downloads/status/${res.id}`);
        dowId.then((val)=> {
          if(val.status==="completed"){
            APIEndpoint.get(`downloads/analytics/${val.fileName}${val.fileExtension}`).then((ItemMasterDwnld) => {
              const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', val.fileName+'.csv'); //or any other extension
              document.body.appendChild(link);
              link.click();
              setIsDownloading(false)

          });
          }
        })
        }, 4000);
    }
    }
      catch (res) {
        setIsDownloading(false)


        console.log(res.message)
      }
  }


  const onDownloadItemMaster=async()=>{
    let header={
      'Accept':'text/csv'
    }
        try {
        const res= await DOMAIN.post(`downloads/itemMaster`,{},header);
        if(res.status==='scheduled'){
          setIsDownloading(true)


          const dowId=await DOMAIN.get(`downloads/status/${res.id}`);
          if(dowId.status==="completed"){

            DOMAIN.get(`downloads/analytics/${dowId.fileName}${dowId.fileExtension}`).then((ItemMasterDwnld) => {
            const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'item_Master_Sample.csv');
            document.body.appendChild(link);
            link.click();
            setIsDownloading(false)

        });

          }
        }
        }
         catch (error) {
          setIsDownloading(false)

          setState((_) => {
            return { ..._,message:{text:error?.data?.message},};
          })
          setTimeout(() => {
            setState((prevState) => ({
              ...prevState,
              message: null,
            }));
          }, 5000);
         }
      }


      const handleModalCancel = () => {
        setState((_) => ({ ..._, showActionDialog: null, actionDialogData: null,showAPILoading:false }))
        setReportFields(ReportFields);
      }

  const onUploadClose = () => {
    setShowDownload(false);
    setReptType("");
    setStoreType("");
  setReportDate({startDate:"",endDate:""});
    setShowUpload(false);
    setCrewName([]);
    setOptionSelectedCrew([]);
    setOptionSelectedSector([]);
    setReptType("");
    setReportDownloadErr(null);
  };
  const onClose = (e, action, row) => {
    setShowDownload(false);
    setEditFormFields([]);
    setIsDownloadPressed(false);
    setReptType("");
    setStoreType("");
    setReportDate({startDate:"",endDate:""});
    setShowUpload(false);
    setCrewName([]);
    setModalFormData({});
    setOptionSelectedCrew([]);
    setOptionSelectedSector([]);
    setReptType("");
    setReportDownloadErr(null);
    setModalFormStatusObj({ text: '' });
    setState((_prev) => {
      return { ..._prev, selected: false };
  });
};


const validateFormInputs = (body, frm='Suspend') => {
  if (!body) {
      setModalFormStatusObj({ error: 'Input validation failed!' });
      return false;
  }

  if ( frm ==='Suspend' ) {
      if (!body.remarks) {
          setModalFormStatusObj({ error: 'Please enter remarks.' });
          return false;
      }
  }

  if ( frm ==='downloadPOReportsPopup' ) {

      if (!body.reportType) {
          setModalFormStatusObj({ error: 'Please select warehouse report type.' });
          return false;
      }
      else if (!body.startDate || !body.endDate) {
          setModalFormStatusObj({ error: 'Please select start date & end date.' });
          return false;
      }
      else if ( !body.selectWarehouses || !body.selectWarehouses.length) {
          setModalFormStatusObj({ error: 'Please select warehouses.' });
          return false;
      }else{
        setModalFormStatusObj({ text: ''});
        return true;
      }
  }
  return true;
};
const onWarehouseChange=(updated)=>{
  let newUpdates = modalFormData ? { ...modalFormData } : {}
  newUpdates['selectWarehouses'] = updated.map(wr=>wr.value) ?? [];
  setModalFormData((_)=>({..._,...newUpdates}));
}


const onModalFormInputChange = (e, newFormData) => {
  const { name, value } = e.target;

  let newUpdates = modalFormData ? { ...modalFormData } : {}
  newUpdates[name] = value;

  setModalFormData((_)=>( {...newFormData,..._ }) );
}

const onSubmit = async (e, updated) => {
  if(e){
    setIsDownloadPressed(true)
  }

  if (updated) {
    if (!updated.reportType) {
      updated.reportType = reportsSelected;
    }
  } else {
    updated = { reportType: reportsSelected };
  }

  if(modalFormData){
    updated={...updated,...modalFormData}
  }

  try {

       if( state.selected.action === 'downloadPOReportsPopup' ) {
          if (!validateFormInputs(updated, 'downloadPOReportsPopup') ) {
              updateButtonTxtStatus(true, e, 'Download', false);
              return false;
          }

          try {
              const reportTypeObj = reportTypes.find( row => updated.reportType === row.value);
              if ( !reportTypeObj ) {
                  setModalFormStatusObj({ error: 'Invalid report type selected, Please check.' });
                  updateButtonTxtStatus(true, e, 'Download', false);
                  return false;
              }

              const downloadPayload = {
                  startDate: moment(updated.startDate).format(),
                  endDate: moment(updated.endDate).format(),
                  warehousereportType: reportTypeObj.id,
                  warehouseId: updated.selectWarehouses
              };

              APIEndpoint.post('warehouse/reports', downloadPayload, { Accept: MIMETypes.csv }).then( resp => {

                  if (resp.status === 'scheduled') {
                    setIsDownloading(true)
                    updateButtonTxtStatus(false, e, 'Processing...', true);


                      checkDownloadStatus(resp.id, e);

                  } else {
                      updateButtonTxtStatus(true, e, 'Download', false);
                  }

              })
              .catch( error => {
                  updateButtonTxtStatus(false, e, 'Download failed', false);
              });
          }
          catch( er ) {
          }
      }
      if(state.selected.action === 'vendorMapping'){
        onDownloadVendorMappingSubmit()
      }

  }
  catch(err) {
    // setModalFormStatusObj({ error: 'Error: ' + err.data.msg });
  }
};
const getWarehousesDetails=()=>{
  if(modalFormData){
      const wareHs=  warehouses.find(w=>w.value===modalFormData?.selectWarehouses)
      return wareHs?.value;
    }

};


const onDownloadVendorMappingSubmit=async()=>{
  let header={
    'Accept':'text/csv'
  }
 let body={
      "warehouseId": getWarehousesDetails(),
      "storeId": null
  }

      try {
      const res= await DOMAIN.post(`downloads/vendorMasterMappings`,{body},header);
      if(res.status==='scheduled'){
        setIsDownloading(true)
        setTimeout(() => {
          const dowId=DOMAIN.get(`downloads/status/${res.id}`);
          dowId.then((val)=> {
            if(val.status==="completed"){
              DOMAIN.get(`downloads/analytics/${dowId.fileName}${dowId.fileExtension}`).then((ItemMasterDwnld) => {
                const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'vendor_mapping_Sample.csv');
                document.body.appendChild(link);
                link.click();
            })}
          })
          }, 4000);
      }
      }
       catch (res) {
        setIsDownloading(false)
         console.log(res.message)
       }
    }

const checkDownloadStatus = async (jobId, e) => {
  await APIEndpoint.get('downloads/status/' + jobId).then( resp => {

      if (resp.status === 'completed') {
        setIsDownloading(true)

          clearTimeout(timeout)
          // updateButtonTxtStatus(true, e, 'Downloaded', false);

          // https://dfp.ngopos.com/api/downloads/analytics/PO%20vs%20GRN%20Report-2022-12-19T19-33--56c0695a-604a-4a38-9097-b8b815063e68.csv

          onDownloadCSVFile('downloads/analytics/' + resp.fileName + resp.fileExtension, 'purchase-order-report-a');
          updateButtonTxtStatus(true, e, 'Downloaded', false, { defaultButtonText: 'Download' });

          setState((_prev) => {
              return { ..._prev, selected: false };
          });
          setModalFormData({});
          setIsDownloadPressed(false);
          setModalFormStatusObj({ text: '' });
          setIsDownloading(false)


      }
      else {

          timeout = setTimeout(() => {
            setIsDownloading(false)


              checkDownloadStatus(jobId, e);
          }, 5000);
      }

  }).catch( error => {
    setIsDownloading(false)
      updateButtonTxtStatus(false, e, 'Download failed', false);
  });
};

const onSearch=(e)=>{

  setSearchKey(e.target.value)

  if(searchKey.length>=3&&reportOptions){
    const filtered = reportOptions.filter((rt) => {
      const {reportsType } = rt;
      const searchTermLower = searchKey.toLowerCase();
      const repType = reportsType.toLowerCase();

      if((repType===searchTermLower)){
      return true;
      }
      else if(repType.includes(searchTermLower)){
      return true
      }
      else{
      return false
      }
      })


      setReportOptionsFiltered([...filtered])
  }

}

const handleOnchangeReports=(selectedValue,data,obj)=>{
  const {reportType}=data;
  if(reportType==="cashierBankTransferReport"){
  setReportFields((prev)=>{
  const cashDrawExist=prev.some(item=>item.attribute==='cashDrawer')
  if(!cashDrawExist){
  return [...prev,{ label: 'Select Station', attribute: 'cashDrawer', type: 'select', required: true }]

  }else{
  return prev

  }

  }

  )
  }
  else{
    setReportFields((prev) => {
      return prev.map((field) => {
        if (field.attribute === 'startDate' && data.startDate) {
          return { ...field, value: new Date(data.startDate) };
        }
        if (field.attribute === 'endDate' && data.endDate) {
          return {
            ...field,value: new Date(data.endDate),minDate: new Date(data.startDate) || null};
        }
        if (field.attribute === 'endDate' && data.startDate && !data.endDate) {
          const startDate = new Date(data.startDate);
          const today = new Date();
          if (startDate <= today) {
            return {...field,value: today,minDate: startDate};
          } else {
            return {...field,value: startDate,minDate: startDate,
            };
          }
        }
        return field;
      });
    });

  }
}
const applyBorderBottom = (index,id) => {
  const rowsWithBorderBottom = [7,13,18,19,20,24,35,37];
  if(!searchKey){
    return rowsWithBorderBottom.includes(index);
  }else{
    return rowsWithBorderBottom.includes(id);
  }
};


    return (
        <>
       <div className="container-fluid dashboard-summary-container"  style={{ overflowY: "scroll", maxHeight: "94vh"}}>
        <div className=' flexRow justifyContentCenter alignItemsCenter'>
          <div className="margBot10 underline">
            <span><img  className="icon-size" alt='search' src={searchIcon} /></span>
            <span><input className="search" style={{color:'#ffffff'}} onChange={onSearch} type="search" placeholder="Search Reports" value={searchKey}/></span>
            </div>
            </div>
            <div className='table-header' style={{ overflowY: "scroll",width:"50%",marginLeft:'25%'}}></div>
              <div className="flexRow justifyContentCenter alignItemsCenter sm-scrollbar" style={{ overflowY: "scroll",width:"50%",marginLeft:'25%'}}>
              <table className="custom-table custom-table-td">
          <thead>
            <tr>
              <th colSpan="2" style={{borderTop:'none',textAlign: 'center',backgroundColor:'#1d3f4f'}}>Download Reports</th>
            </tr>
            </thead>
              <tbody>
              { reportOptionsFiltered.length>0?
              reportOptionsFiltered.map((_,index) => {
                const borderBottomStyle = applyBorderBottom(index,_.id) ? '1px solid #ddd' : 'none';
              return (
              <tr key={_.id}>
              <td className={`custom-table-td`} style={{borderBottom: borderBottomStyle}}>{getMenuGroups(_.menu,index)}</td>
            { downloadReports? <td><span  title={`Download ${(cursorDisabled||_.disabled)?'Not Available':_.reportsType}`} onClick={()=>onDownloadReports(_)} className={(cursorDisabled||_.disabled)?"reports-download cursor-disabled":"reports-download"}>{_.reportsType}</span></td>:<></>}
              </tr>
              )
              })
               :<div style={{margin:'2rem'}} className="dialog__body_container font-weight-margin flexCol alignItemsCenter justifyContentCenter flexWrap">
               No Data
             </div>
              }
            </tbody>
          </table></div>


      </div>


      {showUpload &&
        <div className="overlay" role="dialog">
          <div className="dialog sm-scrollbar">
            <div className="dialog__content">
              <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={onUploadClose} />
              <h2 className="dialog__title subheading-text-medium fontMedium">Download Estimates</h2>
              <hr />

                <div className="dialog__body_container flexRow mt-4 button-align justifyContentFlexEnd flexWrap">
                <div className={`dialog__container_item input-container`}>
                <div className={`dialog__description`}>
                  <> <label className={`textAlignLeft fontRegular req`}>Effective Date</label>
                    <NGODatePicker
                      style={{width: '17rem'}}
                      isclearable={false}
                      value={estimateDate}
                      placeholder={t('effectiveDate')}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChange} />
                  </>
                </div>
                <label className={`textAlignLeft fontRegular req mt-2`}>Warehouses</label>
                <div className={`dialog__description`}>
                  <select className='select-input-galley1'
                  onChange={onItemTypeChange}
                  >
                    {warehouses.map((_) => {
                      return (
                        <option value={_.value} key={_.value}>{_.label}</option>
                      )
                    })}
                  </select>

                </div>
                <button type="button" onClick={onDownloadReportFinal} disabled={state.disabled} className="btn btn-info mt-4" style={{ color: "white",width:"100%" }}>
                 { Boolean(isDownloading)?<div className='flexRow justifyContentCenter alignItemsCenter'><img src={LoadingIn} style={{marginRight:'5px',width:'25px',height:'25px'}} alt="Loading" /> Processing...</div>:<><span><img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} /></span> Download</>}
               </button>

              </div>
                  <div className="col-md-4 col-xs-12">
                  </div>
                </div>
            </div>
          </div>
        </div>
      }

      {
       state&& state.showActionDialog === 'report' &&reportsFields.length&&
        <EditModal
          showDone={false}
          className='cash-sector-add'
          showFieldsInColumn={true}
          modalFormStatusObj={modalFormStatusObj}
          onChange={handleOnchangeReports}
          showLoadingBtn={state.showAPILoading}
          title={t('pages.cashRegisterContent.cashDeposits.downloadReportTitle')}
          fields={reportsFields?.map((_) => {
            if(_.label!=='Select Station'&&_.attribute!=='reportType'){
              return { ..._, options: state.cashReportTypes, initialValue: ''}

            }
            else if(_.attribute==='reportType'){
              return { ..._, options: state.cashReportTypes, initialValue: '',value:reptTypeCash}

            }
            else {
              return { ..._, options: state.cashDrawerDownload, initialValue: ''}
            }
          })}
          onSubmit={handleDownloadReportSubmit}
          onClose={handleModalCancel}>

        </EditModal>
      }


      {state &&
      Boolean(state.selected) &&
      state.selected?.action === "downloadReportPopup" ? (
        <>
          <SalesReportsDownloadComponent
            sectorList={sectorList}
            reportSel={reportsSelected}
            setIsDownloading={setIsDownloading}
            onClose={() => {
              onClose();
            }}
          />
        </>
      ) : null}

      {
                    state && Boolean(state.selected)&&state.selected.action === 'downloadPOReportsPopup' ? (
                        <EditModal
                            title={ modalTitle }
                            customModalStyle={ modelStyle }
                            onClose={onClose}
                            onSubmit={onSubmit}
                            modalFormStatusObj={modalFormStatusObj}
                            modalCustomButtons={modalCustomButtons}
                            fields={ editFormFields.map( (elt) => {
                                    if (state.selected.action === 'downloadPOReportsPopup' ) {
                                      if(elt.attribute==="reportType"){
                                        return {...elt, initialValue:reptTypeProcurement}
                                      }else{return {...elt, initialValue: state.selected[elt.attribute] || ''}}

                                    }
                                    else {
                                        return {...elt, initialValue: '' }
                                    }
                                })
                            }
                            showFieldsInColumn={true}

                            onChange={ (e,updated) => {
                                    setUpdatedFormFieldValues(updated);
                                    if (typeof e === 'string') { // Search select
                                        updateEditFormValues(e, updated);
                                    }
                                   else if(!e && updated){
                                      onWarehouseChange(updated)
                                    }
                                    else {
                                        onModalFormInputChange(e, updated);
                                    }

                                }
                            }
                        />
                    )
                    : null
                }

      {
                    state && Boolean(state.selected)&&state.selected.action === 'vendorMapping' ? (
                        <EditModal
                            title={ modalTitle }
                            customModalStyle={ modelStyle }
                            onClose={onClose}
                            onSubmit={onSubmit}
                            modalFormStatusObj={modalFormStatusObj}
                            modalCustomButtons={modalCustomButtons}
                            fields={ editFormFields.map( (elt) => {
                                    if (state.selected.action === 'vendorMapping' ) {
                                        return {...elt, initialValue: state.selected[elt.attribute] || ''}
                                    }
                                    else {
                                        return {...elt, initialValue: '' }
                                    }
                                })
                            }
                            showFieldsInColumn={true}

                            onChange={ (e, updated) => {
                                    setUpdatedFormFieldValues(updated);

                                    if (typeof e === 'string') { // Search select
                                        updateEditFormValues(e, updated);
                                    }
                                    else {
                                        onModalFormInputChange(e, updated);
                                    }

                                }
                            }
                        />
                    )
                    : null
                }
      {showDownload &&
        <div className="overlay" role="dialog">
          <div className="dialog sm-scrollbar">
            <div className="dialog__content">
              <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={onUploadClose} />
              <h2 className="dialog__title subheading-text-medium fontMedium">Download reports</h2>
              <hr />

                <div className="dialog__body_container flexRow mt-4 button-align justifyContentFlexEnd flexWrap">
                <div style={{fontSize:14}} className={`dialog__container_item input-container`}>
                 <div className={`dialog__description`}>
                <label className={`textAlignLeft fontRegular req mt-2`}>Report Type</label>
                  <select className='select-input-galley1'
                  onChange={onReportTypeChange}
                  value={reptType||null}
                  >
                    {reportsType.map((_) => {
                      return (
                        <option disabled={true} value={_.value} key={_.value}>{_.label}</option>
                      )
                    })}
                  </select>
                  <div className=''>
                { reptType==="crewCommission"||reptType==="crewVariance" ||reptType==="crewReconVariance"?  <>
            <label  className={`flexRow textAlignLeft sub-heading-title-req req margBottom10`}>Select Crew</label>
              <Select
              options={crewMembers}
              value={crewMembers?.filter(function (option) {
                return (
                  crewName.length && crewName[0]&&
                  crewName.find((sc) => sc.label === option.label)
                );
              })}
              onChange={onCrewChange}
              styles={newCustomStyles}
              placeholder="Search Crew"
              isSearchable={true}
              isClearable={true}
            /></> :<></> }
                </div>
                {   reptType==='customerInteractionsReport'?<div className={`dialog__container_item input-container`}>
         <div className={`dialog__description`}>
            <label  className={`flexRow textAlignLeft sub-heading-title-req req margBottom10`}>Select Crew</label>
                <AllMultiSelect
                options={crewMembers.filter(fl=>fl.value!=="")}
                defaultValue={[]}
                isMulti
                styles={ {
                  input: provided => ({
                    ...provided,
                    color: '#fff'
                  })
                ,
                control: (defaultStyles) => ({
                  ...defaultStyles,
                  backgroundColor: "#162E3E",
                  width: '17rem',
                  boxShadow: "none",
                }),
                  option: (defaultStyles) => ({
                  ...defaultStyles,
                  textAlign: 'left',
                }),
                placeholder: (defaultStyles) => ({
                  ...defaultStyles,
                  textAlign: 'left',
                })
              }
              }
                isSearchable
                components={{ Option, MultiValue }}
                onChange={handleChange}
                allowSelectAll={true}
                value={optionSelectedCrew}
                placeholder={`Select Crew`}

              />
              </div>
              </div>
          :<></>}
                <div className={`dialog__container_item input-container`}>
                <div className={`dialog__description`}>
                  <div className='flexCol margTop8 margBottm20'>
                  <> <label className={`textAlignLeft fontRegular req`}>Start Date</label>
                    <NGODatePicker
                      style={{width: '17rem'}}
                      value={reportDate.startDate}
                      name="startDate"
                      isclearable={true}
                      placeholder={t('Start Date')}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChangeRep} />
                  </>
                  </div>
                  <div className='flexCol margTop8 margBottm20'>
                   <> <label className={`textAlignLeft fontRegular req`}>End Date</label>
                    <NGODatePicker
                      style={{width: '17rem'}}
                      value={reportDate.endDate}
                      name="endDate"
                      disabled={!reportDate.startDate}
                      isclearable={true}
                      minDate={reportDate.startDate||null}
                      placeholder={t('End Date')}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChangeRep} />
                  </></div>

                  {   reptType==='customerInteractionsReport'?<div className={`dialog__container_item input-container`}>
         <div className={`dialog__description`}>
            <label  className={`flexRow textAlignLeft sub-heading-title-req req margBottom10`}>Select Sector</label>
              <AllMultiSelect
              options={sectors.filter(fl=>fl.value!=="")}
              defaultValue={[]}
              isMulti
              styles={ {
                input: provided => ({
                  ...provided,
                  color: '#fff'
                })
              ,
              control: (defaultStyles) => ({
                ...defaultStyles,
                backgroundColor: "#162E3E",
                width: '17rem',
                boxShadow: "none",
              }),
              option: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
              }),
              placeholder: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
              })
            }
            }
              isSearchable
              components={{ Option, MultiValue }}
              onChange={handleChangeSector}
              allowSelectAll={true}
              value={optionSelectedSector}
              placeholder={`Select Sector`}

            />
            </div>
            </div>
      :<></>}
                  { reptType==="sandboxSalesSummary"||reptType==="sandboxInvoiceSummary"?  <><label className={`textAlignLeft fontRegular req mt-2`}>Select Route</label>

                <Select
                  options={stores}
                  value={stores?.filter(function (option) {
                    return (
                      storeType.length && storeType[0]&&
                      storeType.find((sc) => sc.label === option.label)
                    );
                  })}
                  onChange={onStoreChange}
                  styles={customStyles}
                  placeholder="Search Route"
                  isSearchable={true}
                  isClearable={true}
                />

                </> :<></> }

                </div>


                </div>

                {reportDownloadErr && (
                  <div className='flexRow justifyContentCenter alignItemsCenter' style={{marginTop:"1.5rem",width:"100%"}}>
                    <p style={{width:"100%"}} className='listErrorMessagesCls'>{reportDownloadErr}</p>
                  </div>
                )}
             <button type="button" onClick={onDownloadReportFinal}  disabled={state.disabled} className="btn btn-info mt-4" style={{ color: "white", width: "100%" }}>
                {Boolean(isDownloading) ? (
                <div className='flexRow justifyContentCenter alignItemsCenter'><img src={LoadingIn} style={{ marginRight: '5px', width: '25px', height: '25px' }} alt="Loading"/> Processing...</div>
                ) : ( <span> <img className="icon-size mt-1" alt="downloadMaster" src={downloadMasterIcon}/>Download</span>)}</button>
                </div>
                </div>
                  <div className="col-md-4 col-xs-12">
                  </div>
                </div>
            </div>
          </div>
        </div>
      }

                {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }


        </>

    );
}