import {CATERER_STATUS_LIST } from "../../../../utils/configs";
import { FaRegTimesCircle } from 'react-icons/fa';
import { FaFlag } from 'react-icons/fa6';
import { FaClipboardQuestion } from "react-icons/fa6";
const getOrderStatusColorCode = (params) => {
  if (params) {
    const orderStatus = params.data.status;
    const ordSts = CATERER_STATUS_LIST.find( _ => _.key === orderStatus );
    if (ordSts) {
      return (
        <>
          { ordSts.value === 'Accepted' && <FaFlag color={ ordSts.colorCode_text } size={15}/> }
          { ordSts.value === 'Rejected' && <FaRegTimesCircle color={ ordSts.colorCode_text } size={15}/> }
          { ordSts.value === 'Clarification' && <FaClipboardQuestion color={ ordSts.colorCode_text } size={15}/> }

          &nbsp;&nbsp;<span>{ordSts.value}</span>
        </>
      );
    }
    else {
      return <>{ordSts}</>
    }
  }
  return null;
}

export const CateringStatusRenderSel = (props) => {
    let dd = props.data ? getOrderStatusColorCode(props) : null;
    return (
        <div className="orderStatus-renderer">
            {dd}
        </div>
    );
};