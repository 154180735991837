import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { DFPAPIEndpoint } from "../../../utils/axios";
import { ControlPanelView } from "./PanelView";
import { sorter } from "./Cards";
import moment from "moment-timezone";

const TABS = ['summary']

const OperationAlerts = (props) => {
  const { t } = useTranslation();
  const { alerts } = props
  return (
    <div className="flexCol full-flex scrollHide">
      <h6 className="flexCol flex-ctr hor-ctr w-100 title h4">{t(`pages.controlPanel.views.operations.alertTitle`)}</h6>
      {
        Boolean(alerts) ?
          <div className="flexCol full-flex scrollY">
            {
              alerts.map(({ message, level }, i) => {
                return (
                  <div key={i} className={`alert alert-${level}`}>{message}</div>
                )
              })
            }
          </div>
          :
          <div className="flexCol full-flex flex-ctr hor-ctr">
            <h6 className="h8">{t(`pages.controlPanel.views.operations.selectGroupForAlerts`)}</h6>
          </div>
      }
    </div>
  )
}
const OperationCard = (props) => {
  const { t } = useTranslation();
  const { slNo, sector } = props
  const { flightNumber, flightDate, origin, destination } = sector
  const getDevicesStatus = () => {
    const total = sector.devices.length, synced = 0;
    return { total, synced }
  }
  const device = getDevicesStatus()
  return (
    <div className="flexCol white-bg carter-card" >
      <div className="flexRow hor-ctr heading">
        <h6 className="sl-no">{slNo}</h6>
        <div className="flexRow space-btn full-flex">
          <div className="flexCol">
            <h5 className="h8">{flightNumber} {origin}-{destination}/{moment(flightDate).tz(moment.tz.guess()).format("DD-MM-YYYY")}</h5>
            <h4 className="small-button-text-regular">{t('pages.controlPanel.views.operations.ofDeviceSysnced', device)}</h4>
          </div>
          <div className="alert alert-danger not-synced">{t('pages.controlPanel.views.operations.synPending')}</div>
        </div>

      </div>
    </div>
  )
}
export const ControlPanelOperations = (props) => {
  const { t } = useTranslation();
  const { selectedDate, show, flightPlans } = props;
  const [state, setState] = useState({ tab: 'summary', groupDetails: {} })
  useEffect(() => {
    setState((_) => ({ ..._, selectedGroup: null, groupDetails: {} }))
  }, [selectedDate])
  const getGroupsInTree = () => {
    if (flightPlans) {
      const { groups } = flightPlans
      let tree = {}
      groups.forEach((group) => {
        const { plan, sectors, tailNumber, id } = group;
        if (!tree[tailNumber]) tree[tailNumber] = {};
        if (!tree[tailNumber][plan]) tree[tailNumber][plan] = { groupId: id, children: [] };
        sectors.forEach((sector) => {
          const { id } = sector;
          tree[tailNumber][plan].children.push({ id: `${tailNumber}${plan}${id}`, name: id, type: 'svg', icon: 'flight' })
        })
      })
      tree = Object.keys(tree).map((tailNumber, j) => {
        const children = Object.keys(tree[tailNumber]).map((plan, i) => {
          const { children, groupId } = tree[tailNumber][plan];
          return { id: `${j}${tailNumber}${plan}`, name: `#${i + 1} ${plan}`, type: 'expand', svg: 'folder', children: children, model: { tailNumber, plan, groupId } }
        })
        children.sort(sorter)
        return { id: tailNumber, name: tailNumber, children: children, type: 'expand', model: { tailNumber } }
      })
      tree.sort(sorter)
      return tree
    }
    return []
  }
  const handleSelectedTail = async (item) => {
    try {
      if (item && item.model && item.model.plan) {
        const { groupDetails } = state
        if (!groupDetails[item.id]) {
          const res = await DFPAPIEndpoint.get(`operations/groups/${item.model.groupId}/sectors/status`)
          groupDetails[item.id] = res
        }
        setState((_) => ({ ..._, selectedGroup: item, groupDetails: { ...groupDetails } }))
      }
    } catch (error) {
      console.error(error)
      alert('Error While Fetching Sector Status')
    }
  }
  const handleProceed = async () => {
    try {
      setState((_) => ({ ..._, proceeding: true }))
      // const { groupId } = state.selectedGroup.model;
      // const res = await DFPAPIEndpoint.post(`operations/group/commit`, { groupId: groupId })
      setState((_) => ({ ..._, proceeding: false }))
    } catch (error) {
      console.error(error)
      alert('Error While Comitting Group')
      setState((_) => ({ ..._, proceeding: false }))
    }
  }
  const tree = getGroupsInTree()
  return (
    <ControlPanelView
      TABS={TABS}
      show={show}
      viewId='operations'
      selectedDate={selectedDate}
      selectedTab={state.tab}
      groups={tree}
      onTabSelect={(tab) => setState((_) => ({ ..._, tab: tab }))}
      onSelectGroup={handleSelectedTail}
      RightComponent={() => {
        return <OperationAlerts alerts={state.selectedGroup ? state.groupDetails[state.selectedGroup.id].alerts : null} />
      }}>
      <div className="full-flex pad flexCol scrollHide">
        {!Boolean(state.selectedGroup) &&
          <div className="flexCol flex-ctr hor-ctr full">{t(`pages.controlPanel.views.selectGroup`)}</div>
        }
        {
          Boolean(state.selectedGroup) &&
          <div className="flexCol full scrollHide">
            <h3 className="h3">{t(`pages.controlPanel.views.operations.groupDetails`)} </h3>
            <div className="flexCol full-flex card-list scrollY">
              {
                state.groupDetails[state.selectedGroup.id].sectors.map((sector, i) => {
                  return (
                    <OperationCard key={sector.id} sector={sector} slNo={i + 1} />
                  )
                })
              }
              <button className="btn btn-primary btn-proceed" onClick={handleProceed} disabled={state.proceeding}>
                <span>{t('pages.controlPanel.buttons.proceedToCommit')}</span>
              </button>
            </div>
          </div>
        }
      </div>
    </ControlPanelView>
  )
}