import { INSTANCE_STATE_LIST } from "../../../../utils/configs";
import { FaLock } from "react-icons/fa6";
import { SlCalender } from "react-icons/sl";
import { BsLightningFill } from "react-icons/bs";
import { IoMdCheckboxOutline } from "react-icons/io";
import { copyToClipBoard } from "../../../../utils/commonFunctions";

const getOrderStatusColorCode = (params) => {
  if (params) {
    const stateIns = params.data?.state;
    const ordSts = INSTANCE_STATE_LIST.find( _ => _.key === stateIns );
    if (ordSts) {
      return (
        <>
          { ordSts.value === 'Active' && <BsLightningFill  color={ ordSts.colorCode_text } size={15}/> }
          { ordSts.value === 'Locked' && <FaLock color={ ordSts.colorCode_text } size={15}/> }
          { ordSts.value === 'Committed' && <IoMdCheckboxOutline color={ ordSts.colorCode_text } size={15}/> }
          { ordSts.value === 'Commit Scheduled' && <SlCalender color={ ordSts.colorCode_text } size={15}/> }
          &nbsp;&nbsp;<span>{ordSts.value}</span>
        </>
      );
    }
    else {
      return <>{ordSts}</>
    }
  }
  return null;
}

export const InstanceStates = (props) => {
    let iconL = props.data ? getOrderStatusColorCode(props) : null;
    return (
        <div className="orderStatus-renderer">
            {iconL}
        </div>
    );
};

export const InstanceName = (props) => {
    return (
        <div style={{cursor:"pointer"}} title="Click to copy!" onClick={e=>copyToClipBoard(e,props.data?.name)} className="orderStatus-renderer">
            {props.data?.name}
        </div>
    );
};