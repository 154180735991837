import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  DFPDraftPage, DiningSchedulePage, PerishableCodes, UpliftSegmentsPage, StandardItemsPage,CrewRoasterPage,
  CorporateSegmentsPage, UpliftEstimatesPage, EstimationRunsPage,CorporateSegmentCreate,UpliftSegmentCreate
} from "./inner-components";

export const EstimationScreen = (props) => {
  return (
    <div className="flexRow full p-0 m-0">
      <div className='full-flex flexCol width100 p-0 m-0'>
        <Routes>
          <Route path="/" element={<Navigate replace to="dfpDraft" />} />
          <Route path="/dfpDraft" element={<DFPDraftPage />} />
          <Route path="/dining-schedule" element={<DiningSchedulePage />} />
          <Route path="/run-estimation" element={<EstimationRunsPage />} />
          <Route path="/perishable-codes" element={<PerishableCodes />} />
          <Route path="/uplift-segments" element={<UpliftSegmentsPage />} />

          <Route path="/uplift-segments">
          <Route path="" element={<UpliftSegmentsPage />} />
          <Route path="segmentuplift/items/:id" element={<UpliftSegmentCreate />} />
            </Route>
          <Route path="/standard-items" element={<StandardItemsPage />} />
          <Route path="/corporate-segments" element={<CorporateSegmentsPage />} />

          <Route path="/corporate-segments">
          <Route path="" element={<CorporateSegmentsPage />} />
          <Route path="segment/items/:id" element={<CorporateSegmentCreate />} />
            </Route>

          <Route path="/uplift-estimates" element={<UpliftEstimatesPage />} />
          <Route path="/crew-roaster" element={<CrewRoasterPage />} />

        </Routes>
      </div>
    </div>
  )
}