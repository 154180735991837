import React, { useEffect, useState } from 'react';
import { DataGrid, Loading, Modal } from "../../../common";
import { useTranslation } from 'react-i18next';
import { CashDepositSectorCard, fetchCashDeposit, DenominationColumns } from "./depositSectorCard";
import moment from 'moment';

export const CashDepositDetailsModal = (props) => {
  const { detail } = props;
  const [deposit, setDeposit] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    _fetchCashDeposit();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const _fetchCashDeposit = async () => {
    let deposit = await fetchCashDeposit(detail)
    setDeposit(deposit)
  };

  function formatDenominations(dinominationData){
    const groupedData = Object.values(
      dinominationData.reduce((acc, item) => {
            if (!acc[item.currencyCode]) {
                acc[item.currencyCode] = {
                    currencyCode: item.currencyCode,
                    currencyName: item.currencyName,
                    currency: item.currencyName,
                    denomination:0,
                    value: 0,
                    netAmount: 0,
                    items: []
                };
            }
    
            acc[item.currencyCode].value += item.value;
            acc[item.currencyCode].denomination += item.denomination;
            acc[item.currencyCode].netAmount += item.netAmount;
            acc[item.currencyCode].items.push(item);
    
            return acc;
        }, {})
    );
    return [...groupedData]
  }

  return (
    <Modal
      showDone={false}
      className='cash-deposit-edit'
      onSubmit={props.onClose}
      onClose={props.onClose}
      title={`${t("pages.cashRegisterContent.cashDeposits.detailsModalTitle")}${detail.code}`}>
      {
        Boolean(deposit) ?
          <div className='flexCol full-flex'>
            {
              Array.isArray(deposit.sectorDeposits) &&
              deposit.sectorDeposits.sort((a,b)=>b?.storeId-a?.storeId).map((sector, index) => {
                return (
                  <CashDepositSectorCard
                    key={index}
                    sector={sector}
                    depositId={deposit.id}
                    cashDrawerId={deposit.cashDrawer.id}
                    flightDate={moment(deposit.createdAt).startOf("day").format()}
                    sectorsInfo={deposit.sectorsInfo[index]} />
                )
              })
            }
            <div className='large-button-text-medium margBottom10'>{t("pages.cashRegisterContent.cashDeposits.denominationsTitle")}</div>
            <DataGrid
              columnResize
              showDefaultFilters={false}
              enableGridSearch={false}
              className='full-flex'
              rowModelType='clientSide'
              columns={DenominationColumns}
              rowData={formatDenominations(deposit.denominations)}
            />
          </div>
          :
          <Loading />
      }
    </Modal>
  )
}