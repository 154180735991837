import { useState, useEffect } from 'react';
import { Authorizer } from 'casbin.js';

const usePermissions = (action, resource) => {

  const [authorizer] = useState(new Authorizer('manual'));
  const [hasPermission, setHasPermission] = useState(false);

  const userRole = localStorage.getItem('userRole');
  const permissions = localStorage.getItem("userPermissions");

  useEffect(() => {
    const initializePermissions = () => {
      if (userRole && permissions && !authorizer.permission) {
        const permissionsObject = JSON.parse(permissions);
        const rolePermissions = permissionsObject[userRole];

        if (rolePermissions) {
          authorizer.setPermission(rolePermissions);
        }
      }
    };

    initializePermissions();
  }, [userRole, permissions,authorizer]);


  useEffect(() => {
    const checkPermission = async () => {
      if (authorizer && authorizer.permission) {
        const permStat=await authorizer.can(action,resource).then(res=>res)
        setHasPermission(permStat)
      }
    };

    checkPermission();
  }, [ action, resource,authorizer]);

  return { hasPermission };
};

export default usePermissions;