/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import { APIEndpoint } from "../../../utils/axios";
import { NGODatePicker,Footer } from "../../../common";
import Select from "react-select";
import closeIcon from '../../../images/icons/Icon2.png'
import pieChartSales from '../../../assets/icons/pi-chart-sales.svg';
import todaySales from '../../../assets/icons/today-sales.svg';
import totalSales from '../../../assets/icons/total-sales.svg';
import todayOrders from '../../../assets/icons/today-orders.svg';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {translationLength} from '../../../utils/commonFunctions';
import downloadMasterIcon from '../../../assets/icons/download-master.svg';


export const CrewSalesPage = (props) => {
  const [state, setState] = useState({});
  const employeeListOptions = useRef(null);
  const [crew, setCrew] = useState({});
  const [sector, setSector] = useState({});
  const [nextMonth, setNextMonth] = useState([]);
  const [prevMonth, setPrevMonth] = useState([]);

	const { t } = useTranslation();
  const [showUpload, setShowUpload] = useState(false);
  const [reportType, setReportType] = useState([]);
  const [selected, setSelected] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [count, setCount] = useState(0);
  const [showCrewUpload, setShowCrewUpload] = useState(false);
  const [isDownloading, setIsDownloading] = useState(true);
  const [form] = useState([{type : null, crew : null, startdate : null, enddate : null}]);

  React.useEffect(() => {
    fetchEmployees();
    crewIncetive();
    setCurrentDate();
  }, []);

  let filterData = {
    startDate: "2022-11-11T00:00:00+05:30",
    endDate: "2022-11-11T00:00:00+05:30",
  };
  
  const crewIncetive = async (val,type) => {
    if(type === 'crew'){
      filterData = {
        employeeId: Number(val),
        startDate: "2022-11-11T00:00:00+05:30",
        endDate: "2022-11-11T00:00:00+05:30",
      };
    }
    if(type === 'dateStart' || type === 'dateEnd'){
      if(type === 'dateStart'){
        filterData = {
          startDate: moment(val).format(),
          endDate: moment(state.filterDateStart).format()??"2022-11-11T00:00:00+05:30",
        };
      }else{
        filterData = {
          startDate:  moment(state.filterDateStart).format()??"2022-11-11T00:00:00+05:30",
          endDate: moment(val).format(),
        };
      }
    }
    const resCrew = await APIEndpoint.post('sales/getCrewIncentiveSummary', filterData);
    const resSector = await APIEndpoint.post('sales/getSectorWiseSales', filterData);
    setCrew(resCrew)
    setSector(resSector);
    return resCrew;
  }

  const setCurrentDate = () => {
    const lastmonth=moment().subtract(1, 'months').startOf('month').format('MMMM, YYYY')
    const nextmonth=moment().add(1, 'months').endOf('month').format('MMMM, YYYY')
    setPrevMonth(lastmonth)
    setNextMonth(nextmonth)
  }
 
  const fetchEmployees = async () => {
    const resp = await APIEndpoint.get('sandboxing/employees?includeAdmin=no');
    if (resp.result.length) {
      employeeListOptions.current = resp.result.map((_pl) => {
        return { value: _pl.employeeId, label: _pl.employeeName+' ('+_pl.employeeCode+')'}
      })
    }
    let date = moment().toDate()
    setState((_) => {
      return { ..._, filterDateStart: date, filterDateEnd: date ,isListReady: true }
    })
  };

  const setDownload = async() => {
    setShowUpload(true);
    const resp = await APIEndpoint.get('reports/crewReportTypes');
    if (resp.result.length) {
      setReportType(resp.result)
    }
  }

  const onUploadClose = () => {
    setShowUpload(false);
  }

  const onDownloadFile = async() => {
    let header={
      'Accept':'text/csv'
    }
    try {
    let request_payload;
    if(form[0].crew){ 
      request_payload = {
        crewCode: form[0].crew,
        reportType: form[0].type,
        endDate: form[0].enddate??"2022-11-13T18:30:00.000Z",
        startDate: form[0].startdate??"2022-11-13T18:30:00.000Z",
      }
    }else{
      request_payload = {
        reportType: form[0].type,
        endDate: form[0].enddate??"2022-11-13T18:30:00.000Z",
        startDate: form[0].startdate??"2022-11-13T18:30:00.000Z",
      }
    }
    const res= await APIEndpoint.post(`crew/download/${form[0].type}`,request_payload,header);
    if(res.status==='scheduled'){
      setTimeout(() => {
        const dowId= APIEndpoint.get(`downloads/status/${res.id}`);
        dowId.then((val)=> {
          if(val.status==="completed"){
              APIEndpoint.get(`downloads/analytics/${val.fileName}${val.fileExtension}`).then((ItemMasterDwnld) => {
              const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', val.fileName+'.csv');
              document.body.appendChild(link);
              link.click();
              setIsDownloading(false);
          })
            }
        })
        }, 4000);
    }
    }
    catch (res) {
      console.log(res.message)
    }
  }

  const handleSelectRouteList = (e) => {
    crewIncetive(e, 'crew')
  }

  const handleFilterDateStartChange = (e) => {
    setState((_) => ({ ..._, filterDateStart: e.target.value, resetDataSource: true }));
    crewIncetive(e.target.value, 'dateStart')
  }

  const handleFilterDateEndChange = (e) => {
    setState((_) => ({ ..._, filterDateEnd: e.target.value,resetDataSource: true }));
    crewIncetive(e.target.value, 'dateEnd')
  }

  const dateChange = (e) => {
    let direction = e.target.value
    let dateCheckStart = state.filterDateStart
    let dateCheckEnd = state.filterDateEnd
    let lastmonth
    let nextmonth

    if(!moment(dateCheckEnd).isValid()){
      lastmonth=moment(dateCheckStart).subtract(1, 'months').endOf('month').format('MMMM, YYYY')
      dateCheckEnd = lastmonth;
    }

    if(!moment(dateCheckStart).isValid()){
      nextmonth=moment(dateCheckEnd).subtract(1, 'months').startOf('month').format('MMMM, YYYY')
      dateCheckStart = nextmonth;
    }

    if(direction === 'prev'){
      if(count !== 0){
        lastmonth=moment(dateCheckStart).subtract(1, 'months').endOf('month').format('MMMM, YYYY')
        nextmonth=moment(dateCheckEnd).subtract(1, 'months').startOf('month').format('MMMM, YYYY')
      }else{
        lastmonth=moment(dateCheckStart).subtract(2, 'months').endOf('month').format('MMMM, YYYY')
        nextmonth=moment(dateCheckEnd).startOf('month').format('MMMM, YYYY')
      }
    }else{
      if(count !== 0){
        lastmonth=moment(dateCheckStart).add(1, 'months').endOf('month').format('MMMM, YYYY')
        nextmonth=moment(dateCheckEnd).add(1, 'months').startOf('month').format('MMMM, YYYY')
      }else{
        lastmonth=moment(dateCheckStart).endOf('month').format('MMMM, YYYY')
        nextmonth=moment(dateCheckEnd).add(2, 'months').startOf('month').format('MMMM, YYYY')
      }

    }
      setCount(count+1)
      setState((_) => {
        return { ..._, filterDateStart:  moment(lastmonth).toDate(), filterDateEnd:  moment(nextmonth).toDate() ,isListReady: true }
      })
      
      setNextMonth(nextmonth)
      setPrevMonth(lastmonth)
  }

  const onValueChange=(event)=> {
    let type = event.target.type;
    let value = event.target.value;
    let name = event.target.name;

    if(type === 'radio'){
      form[0].type = value
      value !== 'allCrewCommission' ? setShowCrewUpload(true) : setShowCrewUpload(false)
      setSelected(value)
    }else if(type === 'select-one'){
      form[0].crew = value
    }else if (name === 'startDate'){
      form[0].startdate = moment(value).utc().format()
      setStartDate(value)
    }else if (name === 'endDate'){
      form[0].enddate = moment(value).utc().format()
      setEndDate(value)
    }
    form[0].type ? setIsDownloading(false) : setIsDownloading(true)
  }

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#0C5061" : "#fff",
      backgroundColor: state.isSelected ? "black" : "#162E3E",
    }),
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
        fontWeight: 900,
        color: 'green',
      }
    }),
    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "white",
      padding: "3px",
      width: '13rem',
      height: "2.66rem",
      border: "0.4px solid #FFFFFF",
      boxShadow: "none",
      fontColor: 'black',
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: 'black' }),
  };

  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
          <div className="flexRow justifyContentFlexEnd alignItemsCenter margBot10 margRight30">
              <span className='upload dropdownStyle1-TextMedium' onClick={() => setDownload()}><img  className="mt-2 icon-size" alt='uploadItem' src={downloadMasterIcon} /><u>Download Reports</u></span>
          </div>
          <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween' style={{paddingLeft: "1.5rem"}}>
            <Select
              key={"crewsales"}
              options={employeeListOptions.current?.map((it) => ({
                value: it.value,
                label: it.label,
              }))}
              styles={customStyles}
              isSearchable
              placeholder="Crew"
              id={"crewsales"}
              onChange={(val) => handleSelectRouteList(val)}
            />
              
              <div className="flexRow justifyContentFlexEnd alignItemsBaseline">
                  <button className="margRight10 add-item-button" value="prev" onClick={dateChange}>
                  {prevMonth}
                  </button>
                    <NGODatePicker
                      className="margRight10"
                      value={state.filterDateStart}
                      name='filterDate'
                      onChange={handleFilterDateStartChange} 
                      />
                    <NGODatePicker
                      value={state.filterDateEnd}
                      name='filterDate'
                      onChange={handleFilterDateEndChange} 
                      />
                  <button className="margLeft10 add-item-button margRight30" value="next" onClick={dateChange}>
                  {nextMonth}
                  </button>
              </div>
            </div>

        <div className="container-fluid dashboard-summary-container sm-scrollbar" id="dashboard-summary-container"  style={{marginTop: '60px'}}>
          <div className="row flexRow justifyContentSpaceEvenly">
            <div className="col-lg-3 card-container-sales-crew ">
              <div className="card-content2">
                <div className="card-icon-block">
                  <img className="icon" alt='Total Commission'  src={pieChartSales} />
                </div>
                <div className="card-heading">Total Commission</div>
                <div className="card-value">RS {crew?.salesCommission}</div>
              </div>
            </div>
  
            <div className="col-lg-3 card-container-sales-crew ">
              <div className="card-content2">
                <div className="card-icon-block">
                  <img className="icon" alt="Total Bob Sales"  src={todaySales}/>
                </div>
                <div className="card-heading">Total Bob Sales</div>
                <div className="card-value">RS {sector?.totalBoBSales}</div>
              </div>
            </div>
  
            <div className="col-lg-3 card-container-sales-crew ">
              <div className="card-content2">
                <div className="card-icon-block">
                  <img className="icon" alt='Cash Sales' src={totalSales} />
                </div>
                <div className="card-heading">Cash Sales</div>
                <div className="card-value">RS {sector?.totalCashSales}</div>
              </div>
            </div>
  
            <div className="col-lg-3 card-container-sales-crew ">
              <div className="card-content2">
                <div className="card-icon-block">
                  <img className="icon" alt="Card Sales" src={todayOrders} />
                </div>
                <div className="card-heading">Card Sales</div>
                <div className="card-value">RS {sector?.totalCardSales}</div>
              </div>
            </div>
            <div className='crew-sales-footer'><Footer/></div>
          </div>
        </div>
      </>
      }
      {
        showUpload &&
        <div className="overlay" role="dialog">
        <div className="dialog sm-scrollbar">
          <div className="dialog__content">
            <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={onUploadClose} />
            <h2 className="dialog__title subheading-text-medium fontMedium">Download Crew Commission Reports</h2>
            <hr />
              <div className="dialog__body_container flexRow flexWrap">
              <div class="file-upload input-group col-md-8 col-xs-12">
              <div className="dialog__container_item input-container upload-align">
                <div className={`dialog__description`}>
                </div>
                <div className={`dialog__description my-4`}>
                  <label className='req mb-2'>Report Type</label>
                  {reportType.map((item, index) => (
                      <div className="radio mt-2">
                      <label>
                        <input
                          type="radio"
                          value={item.id}
                          checked={selected === item.id}
                          onChange={onValueChange}
                        />
                        <span style={{marginLeft:"8px",marginBottom:"7px"}}>{item.value}</span>
                      </label>
                    </div>
                  ))
                }
                </div>
                {showCrewUpload ? 
                <div className={`dialog__description`}>
                  <label className='req'>Select Crew</label>
                  <select className='my-2 select-input' onChange={onValueChange} value={state.storeId} style={{width:"15rem"}}>
                    <option value="">--Select Crew--</option>
                    {employeeListOptions.current?.map((item, index) => (
                      <option key={index} value={item.value}>{item.label}</option>
                    ))
                    }
                  </select>
                </div>
                : null}
                <div className={`dialog__description mt-2`}>
                  <label className='req'>Start Date</label>
                  <NGODatePicker 
                    className='input-margin'
                    name='startDate' 
                    value={startDate} 
                    onChange={onValueChange}
                  />
                </div>
                <div className={`mt-2 dialog__description`}>
                  <label className='req'>End Date</label>
                  <NGODatePicker 
                    className='input-margin'
                    name='endDate' 
                    value={endDate} 
                    onChange={onValueChange}
                  />
                </div>
              </div>
            </div>
            </div>
            <div className="dialog__body_container flexRow mt-4 button-align justifyContentFlexEnd flexWrap">
              <div class="file-upload ng-grid-right input-group col-md-8 col-xs-12">
              <button type="button" disabled={isDownloading}  onClick={onDownloadFile} class="add-item-button"> 
                <span><img className="mt-1 icon-size" alt='downloadMaster' src={downloadMasterIcon} /></span>
                   <span style={{ color: "white" }}>Download</span>
                </button>
              </div>
              <div class="col-md-4 col-xs-12">
              </div>
            </div>
          </div>
        </div>
      </div>
      }
      {
        t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
      }
    </div>
  )
}