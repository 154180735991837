import React, { useState } from 'react';
import { APIEndpoint } from "../../../utils/axios";
import {translationLength} from '../../../utils/commonFunctions';
import employees from '../../../assets/employees.svg';
import { useTranslation } from 'react-i18next';


export const SectorDetailsPage = (props) => {
	const { t } = useTranslation();
	const [details, setDetails] = useState([]);

	React.useEffect(() => {
		fetchDetails();
	}, []);
	
	const fetchDetails = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
		let details = window.localStorage.getItem("sectorDetails");
		let id = JSON.parse(details).id
		let storeId = JSON.parse(details).storeId
		const res = await APIEndpoint.get('sessions/'+storeId+'/'+id);
		console.log(JSON.stringify(res.data.connectedDevices[0].appVersion))
		setDetails(res.data)
		return res;
	}

	return (
		<>
			<div className="container-fluid dashboard-summary-container sm-scrollbar" id="dashboard-summary-container">
				<div className="row flexRow">

					<div className="col-6 col-lg-3 sector-detail-summary ">
						<div className="card-content2">
							<div className="mt-2 card-icon-block">
								<h2 className="dialog__title subheading-text-medium fontMedium">{t('pages.flightsContent.sectors.sectorDetails.summary')}</h2>
								<hr />
							</div>
							<div className="my-4 card-heading">{t('pages.flightsContent.sectors.sectorDetails.base')} : <span className='sector-details'>{details.clusterName}</span></div>
							<div className="my-4 card-heading">{t('pages.flightsContent.sectors.sectorDetails.flightNumber')} : <span className='sector-details'>{details.flightNumber}</span></div>
							<div className="my-4 card-heading">{t('pages.flightsContent.sectors.sectorDetails.origin')} : <span className='sector-details'>{details.originName}({details.origin})</span></div>
							<div className="my-4 card-heading">{t('pages.flightsContent.sectors.sectorDetails.destination')} : <span className='sector-details'>{details.destinationName}({details.destination})</span></div>
							<div className="my-4 card-heading">{t('pages.flightsContent.sectors.sectorDetails.aircraftNumber')} : <span className='sector-details'>{details.aircraftNumber}</span></div>
							<div className="my-4 card-heading">{t('pages.flightsContent.sectors.sectorDetails.flightdate')} : <span className='sector-details'>{details.flightDate}</span></div>
							<div className="my-4 card-heading">{t('pages.flightsContent.sectors.sectorDetails.status')} : <span className='sector-details'>{details.state ? 'offloaded' : 'not offloaded'}</span></div>
							<div className="my-4 card-heading">{t('pages.flightsContent.sectors.sectorDetails.startedAt')} : <span className='sector-details'>{details.startedAt}</span></div>
							<div className="my-4 card-heading">{t('pages.flightsContent.sectors.sectorDetails.offloadedAt')} : <span className='sector-details'>{details.endedAt}</span></div>
							<div className="my-4 card-heading">{t('pages.flightsContent.sectors.sectorDetails.offloadedBy')} : <span className='sector-details'>{details.offloadedBy?.employeeName}({details.offloadedBy?.employeeCode})</span></div>
						</div>
					</div>

					<div className="sector-detail-devices ">

					<div className="col-6 col-lg-3 sector-detail-crew">
						<div className="card-content2" style={{overflow:'auto'}}>
							<div className="my-2 mb-3 card-icon-block">
								<h2 className="dialog__title subheading-text-medium fontMedium">{t('pages.flightsContent.sectors.sectorDetails.connectedDevices')}</h2>
							</div>
							{
								details.connectedDevices?.map((item) => {
									return (
										<div className='row'>
										    <hr />
											<div className='column'>
												<p>{item.appVersion}</p>
												<p>{item.batteryLevel}%</p>
											</div>
											<div className='column middle'> 
												<div className="mb-3 card-heading">{t('pages.flightsContent.sectors.sectorDetails.device')} <div className='sector-details'>{item.macId}</div></div>
												<div className="my-3 card-heading">{t('pages.flightsContent.sectors.sectorDetails.status')} <div className='sector-details'>{item.state}</div></div>
												<div className="my-3 card-heading">{t('pages.flightsContent.sectors.sectorDetails.connectedAt')} <div className='sector-details'>{item.connectedAt}</div></div>
												<div className="my-3 card-heading">{t('pages.flightsContent.sectors.sectorDetails.disconnectedAt')} <div className='sector-details'>{item.disconnectedAt}</div></div>
											</div>
											<div className='column'>
											    <img src={employees} alt="" className=' mb-2 menuitemicon' />
												<div className="mb-3 card-heading">{t('pages.flightsContent.sectors.sectorDetails.employeeCode')} <div className='sector-details'>NA</div></div>
												<div className="my-3 card-heading">{t('pages.flightsContent.sectors.sectorDetails.employeeName')} <div className='sector-details'>NA</div></div>
											</div>
										</div>
									);
								})
							}
							{details.crew?.length === 0 ? t('pages.flightsContent.sectors.sectorDetails.noDevice') : null}
						</div>
					</div>

					<div className="col-6 col-lg-3 sector-detail-crew">
						<div className="card-content2" style={{overflow:'auto'}}>
							<div className="my-2 mb-3 card-icon-block">
								<h2 className="dialog__title subheading-text-medium fontMedium">{t('pages.flightsContent.sectors.sectorDetails.crewInfo')}</h2>
							</div>
							{
								details.crew?.map((item) => {
									return (
										<div className='row'>
										    <hr />
											<div className='column'>
												<img src={employees} alt="" className=' image-center menuitemicon' />
											</div>
											<div className='column middle'> 
												<div className="mb-3 card-heading">{t('pages.flightsContent.sectors.sectorDetails.employeeCode')} <div className='sector-details'>{item.employeeCode}</div></div>
												<div className="my-3 card-heading">{t('pages.flightsContent.sectors.sectorDetails.employeeName')} <div className='sector-details'>{item.employeeName}</div></div>
											</div>
											<div className='column'>
											</div>
										</div>
									);
								})
							}
							{details.crew?.length === 0 ? t('pages.flightsContent.sectors.sectorDetails.nocrew') : null}
						</div>
					</div>

					<div className="col-6 col-lg-3 sector-detail-crew">
						<div className="card-content2" style={{overflow:'auto'}}>
							<div className="my-2 mb-3 card-icon-block">
								<h2 className="dialog__title subheading-text-medium fontMedium">{t('pages.flightsContent.sectors.sectorDetails.crewFromArms')}</h2>
							</div>
							{
								details.crewFromArms?.map((item) => {
									return (
										<div className='row'>
										    <hr />
											<div className='column'>
												<img src={employees} alt="" className=' image-center menuitemicon' />
											</div>
											<div className='column middle'> 
												<div className="mb-3 card-heading">{t('pages.flightsContent.sectors.sectorDetails.employeeCode')} <div className='sector-details'>{item.employeeCode}</div></div>
												<div className="my-3 card-heading">{t('pages.flightsContent.sectors.sectorDetails.employeeName')} <div className='sector-details'>{item.employeeName}</div></div>
											</div>
											<div className='column'>
											</div>
										</div>
									);
								})
							}
							{details.crewFromArms?.length === 0 ? t('pages.flightsContent.sectors.sectorDetails.noInfo') : null}
						</div>
					</div>
				</div>
				</div>
			</div>
			
			{
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
		</>
	);
}