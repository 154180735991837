import {  useState } from "react";
import { useTranslation } from 'react-i18next';
import { Modal, DataGrid } from "../../../common";
import { MainColumns } from "./Cards";
import moment from "moment-timezone";

const Card = (props) => {
  return (
    <div className="flexCol white-bg carter-card" onClick={props.onClick}>
      <div className="flexRow hor-ctr heading">
        <h6 className="sl-no">{props.slNo}</h6>
        <div className="flexCol full-flex">
          <h4 className="h7">{props.group.model.plan} <code>{moment(props.group.model.date).tz(moment.tz.guess()).format("DD-MM-YYYY hh:mm A zz")}</code></h4>
        </div>
      </div>
    </div>
  )
}
const Columns = [
  ...MainColumns,
  { width: 100, field: 'total', headerName: 'pages.controlPanel.views.groupTableColumns.total' },
  { field: 'bc', headerName: 'pages.controlPanel.views.groupTableColumns.businessClass' },
  { field: 'pc', headerName: 'pages.controlPanel.views.groupTableColumns.premiumEconomy' },
  { field: 'ecy', headerName: 'pages.controlPanel.views.groupTableColumns.economy' },
]
export const CateringUplifts = (props) => {
  const { show, selectedGroup } = props
  const [showItems, setShowItems] = useState(null)
  const { t } = useTranslation()
  const getUplifts = () => {
    let uplifts = selectedGroup && selectedGroup.model && selectedGroup.model.uplift;
    if (uplifts && !Array.isArray(uplifts)) {
      uplifts = [uplifts]
    };
    return uplifts
  }
  const uplifts = getUplifts()
  return (
    <div className={`full-flex pad scrollHide ${show ? 'flexCol' : 'hide'}`}>
      {Boolean(!selectedGroup || (selectedGroup && selectedGroup.children)) &&
        <div className="flexCol flex-ctr hor-ctr full">{t(`pages.controlPanel.views.selectGroup`)}</div>
      }
      {
        Boolean(selectedGroup && !selectedGroup.children) &&
        <div className="flexCol full">
          <h3 className="h3">{t(`pages.controlPanel.views.catering.upliftHeading`)} </h3>
          <div className="flexCol full-flex card-list scrollY">
            {
              Boolean(uplifts)
                ?
                uplifts.map((uplift, i) => {
                  return <Card group={selectedGroup} uplift={uplift} key={uplift.id} slNo={i + 1} onClick={() => setShowItems(i)} />
                })
                :
                <h5 className="h8">{t(`pages.controlPanel.views.catering.noUplift`)}</h5>
            }
          </div>
        </div>
      }
      {
        showItems !== null &&
        <Modal
          onClose={() => setShowItems(null)}
          showDone={false}
          title={t(`pages.controlPanel.views.catering.upliftHeading`) + ' - ' + selectedGroup.id}
          overlayClassName="dfp-modal">
          <div className='flexCol' style={{ height: '60vh', width: '100%' }}>
            <DataGrid
              columnResize
              rowModelType='clientSide'
              showDefaultFilters={false}
              className='full-flex'
              columns={Columns}
              rowData={uplifts[showItems].items || []}
            />
          </div>
        </Modal>
      }
    </div>
  )
}