import React from 'react'
import { DataGrid } from '../../../common'

const Columns = [
  {
    field:"slno",
    headerName:"Sl No",
    filter:true,flex:1, suppressSizeToFit:true
  },
  {
    field:"ssrcode",
    headerName:"SSR Codes",
    filter:true,flex:1, suppressSizeToFit:true
  },
  {
    field:"mealname",
    headerName:"Meal Name",
    filter:true,flex:1, suppressSizeToFit:true
  }
]

const rowData = [
  { slno: 1, ssrcode: "BBML", mealname: "baby" },
  { slno: 2, ssrcode: "BLML", mealname: "bland" },
  { slno: 3, ssrcode: "CHML", mealname: "child" },
  { slno: 4, ssrcode: "DBML", mealname: "diabetic" },
  { slno: 5, ssrcode: "FPML", mealname: "fruit Platter" },
  { slno: 6, ssrcode: "GFML", mealname: "gluten-free" },
  { slno: 7, ssrcode: "HNML", mealname: "Hindu" },
  { slno: 8, ssrcode: "KSML", mealname: "kosher" },
  { slno: 9, ssrcode: "LCML", mealname: "low Calorie" },
  { slno: 10, ssrcode: "LFML", mealname: "low Fat" },
  { slno: 11, ssrcode: "LPML", mealname: "low Protein" },
  { slno: 12, ssrcode: "LSML", mealname: "low Sodium" },
  { slno: 13, ssrcode: "MOML", mealname: "Muslim" },
  { slno: 14, ssrcode: "NLML", mealname: "non-lactose" },
  { slno: 15, ssrcode: "ORML", mealname: "Oriental" },
  { slno: 16, ssrcode: "RVML", mealname: "Raw Veg" },
  { slno: 17, ssrcode: "SFML", mealname: "seafood" },
  { slno: 18, ssrcode: "VGML", mealname: "vegetarian" },
  { slno: 19, ssrcode: "VLML", mealname: "vegetarian Lacto" }
]

export const Ssrcodes = () => {
  return (
    <div className="flexCol full-flex ag-theme-alpine-dark">
      {/* <div className='flexRow margTop8 margBot10'>
        <select className='select-item' name="errorType" id="" onChange={errorTypeChangeHandler}>
          <option value="">Error Type</option>
          <option value="IROP">IROP</option>
          <option value="API">API Failure</option>
          <option value="SSR">SSR</option>
        </select>
      </div> */}
      <div className='flexCol full-flex'>
        <DataGrid
        className="full-flex"
        columns={[...Columns]}
        // actionProperties={actionColumnProperties}
        showDefaultFilters={false}
        rowModelType="clientSide"
        // actions={actionItems}
        rowData={rowData}
        columnResize={true}
        rowSelection="multiple"
        // getActionCellIcons={getActionCellIcons}
        // onAction={(e, action, data) => {
        //   return onAction(e, action, data);
        // }}
      />
      </div>
    </div>
  )
}
