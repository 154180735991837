import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels
);

const HorizontalBarChart = ({displayLabel=true,labels,dataS}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        data: dataS,
        backgroundColor: [
            '#4e4b93',
            '#4e4b93a6',
            'yellow',
            'pink',
            'lightblue',
          ],
        borderColor: 'transparent',
        borderWidth: 1,
        height:20
      },
    ],
  };

  const options = {
    indexAxis: 'y',
    scales: {
      x: {
        barPercentage: 0.2,
        beginAtZero: true,
        ticks: {
          stepSize: 50,
          callback: function(value) {
            return value;
          },
        },
        barThickness: 10,
        maxBarThickness: 10,
        minBarLength: 5,
        grid: {
          drawOnChartArea: true,
        },
        offset: true,
      },
      y: {
        ticks: {
        display:!displayLabel ? false:true,
        color:"#fff"
        },
        barThickness: 20,
        maxBarThickness: 30,
        minBarLength: 5,
        grid: {
          drawBorder: false,
        },
        padding: {
          top: 0,
          bottom: 0,
          left: 20,
          right: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        align:"start",
        position:"bottom"
      },
      tooltip: {
        enabled:false,
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${context.raw}`;
          },
        },
      },
      datalabels: {
        display: true,
        align: 'end',
        anchor: 'end',
        formatter: (value) => {
          return `USD ${value.toFixed(2)}`;
        },
        color: '#fff',
        font: {
            size: 12,
        },
      },
      annotation: {
        annotations: {
          zeroLine: {
            type: 'line',
            xMin: 0,
            xMax: 0,
            borderColor: 'red',
            borderWidth: 2,
            label: {
              enabled: false,
            },
          },
        },
      },
    },
  };


  return <Bar data={data} options={options} />;
};

export default HorizontalBarChart;
