import React, { useState,useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
import { Modal } from "../../common";
import { APIEndpoint } from '../../utils/axios';
import DownloadVector from '../../assets/icons/svg/download-vector.svg';
import {downloadFileDashboard} from '../../utils/commonFunctions';
import VerticalDivider from '../../assets/icons/svg/vertical-divider.svg';
import { durationFilterSelect } from '../../utils/configs';
import zoom from '../../assets/icons/zoom.svg';
const GraphCard = (props) => {
  // const { t } = useTranslation();
  const {id,filtersLine,setFilteredWidgetData}=props
  const isSquareLegend = props.legendType === 'square';
  const expander = props.expander === false ? false : true
  const [filters,setFilters]=useState("")

  const onChangeFilters=async(e)=>{
    e.preventDefault();
    let wId=id
     setFilters(e.target.value)
    let filterCriteria=e.target.value;
    try {
    const resp = await APIEndpoint.get(`portal/v1/widget/${wId}?sortBy=${filterCriteria}`);
    if(resp.success){
      setFilteredWidgetData(resp.data)
    }
    }
     catch (error) {
       console.log(error)
     }
    }
	  const onBtExport=()=>{
      downloadFileDashboard(props,null)
      }
    useEffect( () => {
      setFilters(filtersLine?.sortBy)
       }, [filtersLine]);

  return (
    <div className={`card-container3 flexCol chart-container ${props.className || ''}`}>
      <div className='width100 flexRow alignItemsCenter'>
        <div className='width100 flexRow alignItemsCenter mt-3 justifyContentSpaceBetween'>
          <div className='mb-3 component-header-space'>{props.title}</div>
          <div className="cmpHeadingRight flexRow alignItemsCenter justifyContentSpaceEvenly">
        <img className="icon-charts" src={DownloadVector} alt="Download icon" onClick={onBtExport}/>
        <img className="icon-divider-charts" src={VerticalDivider} alt="|" />
        <span>
            <button className='dashboard-border'>
              <select
                className='dashboard-button-report'
                onChange={onChangeFilters} value={filters}>
               {durationFilterSelect.map( (item, index) => (
                    <option key={index} value={item.value}>{item.label}</option>
                ))
                }
              </select>
            </button>
          </span>
                </div>
          
        </div>
      </div>
      <div className="card-content2">
        {props.children}
      </div>
      <div className="flexCol container-fluid sm-scrollbar mt-3 w-100">
        <div className="w-100 flexRow flexWrap">
          {(props.legends || []).map((item, index) => {
            return (
              <div className={`${isSquareLegend ? 'chart-label-bar-chart' : 'chart-label-pie'} col-lg-3`} key={index}>
                <ul className={`${index === 0 || 'chart-label-seperator'}`}>
                  <li className={`${isSquareLegend ? 'dashboard-bullet-bar' : 'dashboard-bullet'}`} style={{ color: item.color }} >
                    <div className="chart-heading-pie">
                      {item.name}
                      {Boolean(item.value) &&
                        <> <br></br>{item.value}</>
                      }
                    </div>
                  </li>
                </ul>
              </div>
            )
          })}
        </div>
      </div>
      {
        expander &&
        <button className='expander' onClick={props.onExpand}>
          <img alt='zoom' src={zoom}/>
        {/* <span className='icon-expand-arrow'></span> */}
        </button>
      }
    </div>
  );
}

export const GraphContainer = (props) => {
  const [expand, setExpand] = useState(false)
  return (
    <>
      <GraphCard {...props} onExpand={() => setExpand(true)} />
      {
        expand &&
        <Modal showDone={false} overlayClassName="chart-container-modal " onClose={() => setExpand(false)}>
          <GraphCard {...props} expander={false} />
        </Modal>
      }
    </>
  )
}





