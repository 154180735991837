import React, { useEffect, useState } from 'react'
import { APIEndpoint } from '../../../utils/axios';
import {EditModal,DeleteConf } from '../../../common';
import editGalleyItem from '../../../assets/icons/editGalley.svg';
import { useTranslation } from 'react-i18next';
import {Loading} from '../../../common/Loading'
import '../../../assets/styles/modal.scss'
import deleteIcon from '../../../assets/icons/deleteRole.svg'
import searchIcon from '../../../assets/icons/search.svg';
import { translationLength } from '../../../utils/commonFunctions';


const FormFields = [
  { label: 'Name', attribute: 'clusterName', type: 'text', required: true, disabled: false },
  { label: 'Address', attribute: 'description', type: 'textarea', required: true, disabled: false, rows: 4 },
]

export const BaseStationPage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({resetDataSource: false, showAddForm: false, editRow: null, detailsRow: null, deleteRows: null });
  const [searchKey, setSearchKey] = useState('');
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });

  useEffect(() => {
    fetchBaseStations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    fetchBaseStations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey])


const onCloseEdit=()=>{
  setState((_) => {
    return { ..._,showAddForm: false, editRow: null, deleteCluster: null ,resetDataSource:modalFormStatusObj.text.length>0?true:false};
  })
  setModalFormStatusObj({text:''})
}


  const fetchBaseStations = async () => {

    try {
      const res = await APIEndpoint.get('cluster/all');
      if(res.success&&searchKey===''){
        res.items=res.result;
      setState((_) => {
        return { ..._,rowData: res.items }
      })

      }
      if(searchKey&&res.success){
        const filteredBaseStations = res.result.filter((stn) => {
              return Object.values(stn)
                .join(" ")
               .toLowerCase()
               .includes(searchKey.toLowerCase());
            });
            res.items=filteredBaseStations;
            setState((_) => {
              return { ..._,resetDataSource:true, rowData: filteredBaseStations }
            })

      }


      return res;
      }
       catch (res) {
         console.log(res.message)
       }


  }


  const handleAction = async (e, action, selectedRowData) => {
    try {
      if (action === 'edit') {
        const res = await APIEndpoint.get('cluster/' + selectedRowData.clusterId);
        setState((_) => {
          return { ..._, editRow: res.result }
        })
      } else if (action === 'delete') {
        setState((_) => {
          return { ..._, deleteCluster: selectedRowData }
        })
      }
    } catch (error) {
      alert(error.message)
    }
  };
  const handleFormSubmit = async (e, values) => {
    if(!values?.clusterName && !values?.description){
      setModalFormStatusObj({error:"Please fill all fields"})
    }else if(!values?.clusterName){
      setModalFormStatusObj({error:"Please fill cluster name"})
    }else{
      setModalFormStatusObj({error:"Please fill description"})
    }
    setState((_) => {
      return { ..._, resetDataSource:false };
    })
    let isValid = true, isEdit = Boolean(state.editRow);
    if (values != null) {
      isValid = FormFields.every(((field) => {
        if (
          field.required && (
            (isEdit && !(values[field.attribute] || state.editRow[field.attribute])) ||
            (!isEdit && !values[field.attribute])
          )
        ) {
          return false;
        }
        return true
      }));
      if (isValid) {
        let res;
        try {
          if (isEdit) {
            values = { ...state.editRow, ...values };
            values.clusterId = Number(values.clusterId)
            res = await APIEndpoint.put('cluster/' + values.clusterId, values);
          } 
          else{
            res = await APIEndpoint.post('clusters/new', values);
          }
          if (res && res.success) {
          setModalFormStatusObj({ text: res.msg });

          const timeId = setTimeout(() => {
                fetchBaseStations();
                setModalFormStatusObj({ text:"" });
                setState((_) => {
                  return { ..._, resetDataSource:true,showAddForm:false,editRow:false };
                })
          }, 1600)
          return () => {
            clearTimeout(timeId)
          }
          }
        } catch (error) {
          setModalFormStatusObj({ error: error.message});
        }
      }
    }
    else if(!isEdit&&!values){
      setState((_) => {
        return { ..._, resetDataSource:true,editRow:false };
      })
    }
    else{
      let res;
      res = await APIEndpoint.put('cluster/' + state.editRow.clusterId, state.editRow);
      if(res.success){
        setModalFormStatusObj({ text: res.msg });

        const timeId = setTimeout(() => {
              fetchBaseStations();
        setModalFormStatusObj({ text:"" });

              setState((_) => {
                return { ..._, resetDataSource:true,showAddForm:false,editRow:false };
              })

        }, 1600)
        return () => {
          clearTimeout(timeId)
        }
      }
    }
  };
  const onSearch = (e) => {
    setSearchKey(e.target.value);
};
  const handleClusterRemove = async () => {
    setState((_) => {
      return { ..._,resetDataSource:false };
    })
    try {
      const resp = await APIEndpoint.put(`cluster/${state.deleteCluster.clusterId}/remove`);
      if (resp.success) {
        setModalFormStatusObj({ text: resp.msg });
        setState((_) => {
          return { ..._,resetDataSource:true };
        })
        const timeId = setTimeout(() => {
        setModalFormStatusObj({ text: '' });
        setState((_) => {
          return { ..._,resetDataSource:true,deleteCluster:false };
        })
        fetchBaseStations();
        }, 1600)
        return () => {
          clearTimeout(timeId)
        }
      }
    }
    catch (error) {
      setModalFormStatusObj({ text: error.data.msg })
    }
    setModalFormStatusObj({text:''})
    // refreshTable()
  }
  const handleAddBaseStation = () => {
    setState((_) => {
      return { ..._, showAddForm: true }
    })
  }

 

    return (

      <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">

   <div className='flexRow width100 ng-grid-right margBtm16 margTop8 justifyContentFlexEnd alignItemsCenter'>

      <div className="underline">
                <span><img className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
                <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={state.searchText} /></span>
              </div>
              </div>

      <div className="grid-container-base">
        <div className="grid-item-base-add">
        <div className="content-base-add">
        <div style={{display:'flex',flexDirection:'column',cursor:'pointer'}} onClick={handleAddBaseStation}>
        <strong style={{display: 'flex',justifyContent:'center',alignItems:'center',fontSize:50}}>+</strong>
        <p style={{marginTop:'1.3rem',fontSize:20}} >Base Station</p>
        </div>
          </div>
        </div>
  
        {state.rowData?state.rowData?.map(stn => (
          <div className="grid-item-base" key={stn.clusterId}>
            <div className="content-base">
            <div title={stn.description} style={{display:'flex',flexDirection:'column'}}>
            <strong style={{display: 'flex',justifyContent:'center',alignItems:'center',fontSize:30}}>{stn.clusterName}</strong>
            <p style={{marginTop:'1.1rem',fontSize:15}}>{stn.description.length>15?stn.description.slice(0,15)+"...":stn.description}</p>
          </div>
  
            </div>
            <div className="overlay_base">
            <img className="basestation_edit" style={{marginTop:'0.2rem',paddingBottom:'0.2rem'}} onClick={()=>handleAction(null,'edit',stn)} src={editGalleyItem} alt='Editbase' />
            <img className="basestation_del"  style={{paddingBottom:'0.2rem'}} onClick={()=>handleAction(null,'delete',stn)} src={deleteIcon} alt='Deletebase' />
            </div>
          </div>
        )):<div style={{margin:0}} className='flexRow justifyContentCenter alignItemsCenter'> <Loading /></div>}
      </div>
      {
        Boolean(state.showAddForm || state.editRow) && (
          <EditModal
            title={
              Boolean(state.editRow) ? t('pages.pagesContent.baseStations.editModalTitle') :
                t('pages.pagesContent.baseStations.addModalTitle')
            }
            onClose={onCloseEdit}
            showFieldsInColumn={true}
            modalFormStatusObj={modalFormStatusObj}
            onSubmit={handleFormSubmit}
            buttonLabel={t('buttons.save')}
            fields={
              FormFields.map((elt) => {
                const isEdit = Boolean(state.editRow)
                return { ...elt, initialValue: isEdit ? state.editRow[elt.attribute] : '' }
              })
            }
          />
        )
      }
       {
        Boolean(state.deleteCluster) && (
          <DeleteConf
            description={t('pages.pagesContent.baseStations.deleteModelDesc', { cluster: state.deleteCluster.clusterName })}
            onContinue={handleClusterRemove}
            modalFormStatusObj={modalFormStatusObj}
            onCloseDelete={()=> setState((_) => {
              return { ..._, deleteCluster:null,resetDataSource: false };
            })
          }
          />
        )
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
      </div>
     

    );


}

