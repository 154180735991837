

import React, { useRef, useState } from 'react'
import {HOST_SERVER } from '../../../utils/configs';
import { APIEndpoint } from '../../../utils/axios';
import { DataGrid, EditModal } from '../../../common';
import { useTranslation } from 'react-i18next';
import Edit2 from "../../../assets/edit2.svg";
import { translationLength, truncateString } from '../../../utils/commonFunctions';
import usePermissions from '../../../utils/userPermissions';


export const ManageCategoriesPage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({});
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [imageDetails,setImageDetails]=useState({});
  const [base64, setBase64] = useState('');
  const tableRef = useRef();
  const sType = localStorage.getItem('sType');
  const { hasPermission: createCategory } = usePermissions('create', "inventory.categories");
  const { hasPermission: editCategory } = usePermissions('edit', "inventory.categories");

  class ImageIndicator {
    init(params) {
      let path;
      const element = document.createElement('span');
      const imageElement = document.createElement('img');
      (params.data && params.data.image) ? path = params.data.image : path = "";
      imageElement.src = `${HOST_SERVER}/${path}`;
      imageElement.width = "35"
      element.appendChild(imageElement);
      this.eGui = element;
    }
    getGui() {
      return this.eGui;
    }
  }

  const Columns = [
    { field: 'catName', headerName: 'Name', sortable: true, minWidth: 165,flex:1,suppressSizeToFit:true,resizable:true,},
    { field: 'description', headerName: 'Description', sortable: true,flex:1, suppressSizeToFit:true,resizable:true,minWidth: 200,valueFormatter:({value})=>truncateString(value,50) },
    { field: 'image',headerName: 'Image', cellRenderer: ImageIndicator, sortable: true,flex:1,suppressSizeToFit:true,resizable:true, minWidth: 175 },
  ];

  const FormFields = [
    { label: 'Category Name', attribute: 'catName', type: 'text', required: true, },
    { label: 'Description', attribute: 'description', type: 'textarea',  disabled: false, rows: 4 },
    { label: 'Browse Image', attribute: 'image', type: 'uploadImage' },
  ]

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const fetchCategories = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    try {
      const res = await APIEndpoint.post('categories/process', filterData);
      return res;
    } catch (error) {

    }

  }


  const handleAction = (e, action, row) => {
    setState((_prev) => {
      return { ..._prev, editRow: row }
    })
  }

  const handleFormSubmit = async (e, values,r) => {
    setModalFormStatusObj({ text: '' });
    let isValid = true, isEdit = Boolean(state.editRow);
    if (isValid) {
      let res,categoryName,description;
      try {
        if (isEdit) {
          values = { ...state.editRow, ...values };
          if (values.catName === '') {
            setModalFormStatusObj({ error: 'Enter Category Name!', });
            return false;
          }
          FormFields.map((elt) => {
            if(elt.attribute === 'catName'){
                categoryName = state.editRow[elt.attribute];
            }
            if(elt.attribute === 'description'){
                description = state.editRow[elt.attribute];
            }
            return 0;
          })
          let link = document.getElementById('upload-img');
          let blob = new Blob([link.value], {type: 'text/plain'});
          let blob_path = URL.createObjectURL(blob);

        let edit_filter=  {
            "category_name": values.catName,
            "description": values.description,
            "editCategory": {
                "id":  values.catId,
                "name": categoryName,
                "description": description
            },
            "imageUrl": state.editRow.image,
            "catImage": {
                "imgName": imageDetails.name,
                "imgType": "image/png",
                "imgSize": imageDetails.size,
                "_data": base64||"",
                "width": 100,
                "height": 100,
                "localSrc":blob_path.replace("http://localhost:3000",`${HOST_SERVER}`)
            }
        }
          res = await APIEndpoint.put('category', edit_filter);
          if(res.success){
            setBase64("");
            setImageDetails({});
          }
        } else {
          if (!values) {
            setModalFormStatusObj({ error: 'Enter Field Values!' });
            return false;
          }
          let link = document.getElementById('upload-img');
          let blob = new Blob([link.value], {type: 'text/plain'});
          let blob_path = URL.createObjectURL(blob);

          let filter = {
            catImage: {
              height:100,
              imgName: imageDetails.name||"",
              imgSize: imageDetails.size||null,
              imgType: "image/png",
              localSrc: blob_path.replace("http://localhost:3000",`${HOST_SERVER}`),
              width: 100,
              _data : base64||""
            } ,
            category_name: values.catName,
            description:  values.description
          }
          res = await APIEndpoint.post('category', filter);
        }
        if (res && res.success) {
          setModalFormStatusObj({ text: res.msg });
          setBase64("");
          setImageDetails({});
          handleReset(e, true)
        }
      } catch (error) {
        setModalFormStatusObj({ error: JSON.stringify(res) });
        let {data}=JSON.parse(error?.message);
        setModalFormStatusObj({ error: data?.msg });
      }
    }
  };
  const encodeImageFileAsURL=(element)=> {
    setImageDetails(element)
    const reader = new FileReader();
    reader.onloadend = () => {
        setBase64(reader.result);
    };
    reader.readAsDataURL(element);
  }

  const handleReset = (e, reset = false) => {
    setModalFormStatusObj({ text: '' });
    setState((_) => {
      return { ..._, resetDataSource: reset ? true : state.resetDataSource, showAddForm: false, editRow: null };
    })
    if (reset) {
      let timerId = setTimeout(() => {
        clearTimeout(timerId);
        setState((_) => {
          return { ..._, resetDataSource: false };
        })
      }, 500)
    }
  }
  const handleAddSubCategory = () => {
    setState((_) => {
      return { ..._, showAddForm: true }
    })
  }
  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      <DataGrid
        ref={tableRef}
        columnResize
        showDefaultFilters
        className="full-flex"
        columns={Columns}
        resetDataSource={state.resetDataSource}
        getRows={fetchCategories}
        rowSelection="multiple"
        pageType={false}
        actions={[
          { detail: editCategory?[{ src: Edit2, value: "edit" ,title:'Edit'}]:[] }
        ]}
        onAction={handleAction}
        addButton={{
          title: t('pages.productsContent.manageCategories.addModalTitle'),
          onClick: handleAddSubCategory
        }}
      />
      {
        Boolean((state.showAddForm && createCategory) ||( state.editRow && editCategory)) && (
          <EditModal
            title={
              Boolean(state.editRow) ? t('pages.productsContent.manageCategories.editModalTitle') :
                t('pages.productsContent.manageCategories.addModalTitle')
            }
            onClose={handleReset}
            onSubmit={handleFormSubmit}
            modalFormStatusObj={modalFormStatusObj}
            encodeImageFileAsURL={encodeImageFileAsURL}
            buttonLabel={Boolean(state.editRow) ? 'Save': 'Add'}
            fields={
              FormFields.map((elt) => {
                const isEdit = Boolean(state.editRow)
                return { ...elt, initialValue: isEdit ? state.editRow[elt.attribute] : '' }
              })
            }
          />
        )
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}
