/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { APIEndpoint, DFPAPIEndpoint } from '../../../../utils/axios';
import { EditModal } from '../../../../common';
import { updateButtonTxtStatus} from "../../../../utils/commonFunctions";
import  LoadingIn from '../../../../assets/loadingIn.gif';

export const SalesReportsDownloadComponent = (props) => {
    const { t } = useTranslation();
    const [ modalTitle ] = useState('Download Reports');
    const [modalCustomButtons, setModalCustomButtons] = useState(null);
    const modelStyle = { maxWidth: '80%' };
    const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
    const [, setUpdatedFormFieldValues] = useState({});
    const [modalFormData, setModalFormData] = useState({});

    const [reportTypes, setReportTypes] = useState([]);
    const [reptType,setReptType]=useState(null);
    const [routes, setRoutes] = useState([]);
    const [editFormFields, setEditFormFields] = useState([]);

    useEffect( () => {

        loadReportTypes();

        if ( props.sectorList.length > 0) {
            popupForm_loadRoutes();
        }


        setUpInitialFormFields();


        setModalCustomButtons([
            /**
             * Buttons
             */
            { label: 'Cancel', attribute: 'button', type: 'button', disabled: false, className: 'cancel-button', action: (e) => {
                    console.log('ModalClosed.');
                    props.onClose();
                    return false;
                }
            },
            { label: 'Download', attribute: 'button', type: 'button', disabled: false, className: 'submit-button'
                , action: 'onSubmit'
            }
        ]);

    }, []);

    useEffect( () => {

        showFieldValidation();

    }, [ editFormFields ]);
    useEffect(() => {
      if(props.reportSel){
        let repSelected=reportTypes.find(rpt=>rpt.label===props.reportSel)
        if(repSelected){
          setReptType(repSelected.value)
          setEditFormFields(editFormFields.filter( (field) => {
            if (field.attribute === 'reportType') {
                field.disabled = true;
                field.options=reportTypes;
                field.value=repSelected.value
            }
            if (field.attribute === 'selectRoute') {
              field.disabled = true;
              field.options=routes;
          }
            return field;
        }) );
        }
      }

    }, [props,reportTypes])


useEffect(() => {
  if(reptType){
    validateAndShowFORMErrors(null)
  }
}, [reptType])

    useEffect( () => {
        if ( reportTypes.length && routes.length > 0 && editFormFields.length > 0&&!reptType ) {
            updateEditFormInputs();
        }
    }, [reportTypes, routes ]);

    const showFieldValidation = () => {
        if ( editFormFields.length > 0) {
            editFormFields.forEach( _ => {
                const foundInpElement = document.getElementById(_.id);

                if (foundInpElement) {

                if (_.required === true) {
                    validateEmptyError( foundInpElement);
                }

                }
                else {
                    console.error('Log: Form element not found!', _);
                }

            });

        }
    };

    const loadReportTypes = async () => {
        //, { signal: newAbortSignal('reportTypesAPI', 5000) }
        try {
          const resp = await DFPAPIEndpoint.get('reports/reportTypes');
        if (resp.result.length > 0) {
            const options = [{key: 0, label: 'Select', value: '', id: ''}];
            resp.result.map( (fieldValue, index) => {
              options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.id}`, id: `${fieldValue.id}`});

              return fieldValue;
            });

            setReportTypes(options);
            // updateEditFormInputs(options, null);
        }
        } catch (error) {

        }

    }

    const popupForm_loadRoutes = async () => {
        if ( props.sectorList.length > 0) {
            const options = [{key: 0, label: 'Select', value: '', id: ''}];

            props.sectorList.filter( (fieldValue, index) => {
                options.push({key: index+1, label: `${fieldValue.label}`, value: `${fieldValue.value}`, id: `${fieldValue.id}`});

                return fieldValue;
            });

            setRoutes(options);
            // updateEditFormInputs(null, options);
        }
    }

    const allFormInputs = [


        { key: 'reportType', label: 'Report Type', name: 'reportType', id: 'reportType', attribute: 'reportType', type: 'select', disabled:false,

            required: true, className: 'select-search-cls',value:reptType?reptType:modalFormData.reportType?modalFormData.reportType:null
            , options: reportTypes

        },
        { key: 'filterByTransactionDate', label: 'Filter by Transaction Date', name: 'filterByTransactionDate', id: 'filterByTransactionDate', attribute: 'filterByTransactionDate', type: 'checkbox',
            required: false, disabled: false,
            // placeholder: '', className: 'text-input'
        },
        { key: 'startDate', label: 'Start Date', name: 'startDate', id: 'startDate', attribute: 'startDate', type: 'date', required: true, disabled: false,
            placeholder: t('datePickerPlaceholder'),
            className: 'date-input',
            // startDate: moment().format()
        },
        { key: 'endDate', label: 'End Date', name: 'endDate', id: 'endDate', attribute: 'endDate', type: 'date', required: true, disabled: false,
            placeholder: t('datePickerPlaceholder'),
            className: 'date-input',
            // endDate: moment().format()
        },
        { key: 'transDate', label: 'Date', name: 'transDate', id: 'transDate', attribute: 'transDate', type: 'date', required: true, disabled: false,
                placeholder: t('datePickerPlaceholder'),
                className: 'date-input',
                // startDate: moment().format()
        },
        { key: 'selectRoute', label: 'Select Route', name: 'selectRoute', id: 'selectRoute', attribute: 'selectRoute', type: 'selectSearch2', disabled: false,
            required: true, className: 'select-search-cls',
            options: routes

        },
    ];
    const setUpInitialFormFields = () => {
      let fields = allFormInputs
      .filter(field => (field.name === 'reportType' || field.name === 'startDate' || field.name === 'endDate' || field.name === 'selectRoute'))

        setEditFormFields( fields );

    };

    const updateEditFormInputs = (reportTypes2=null, routesArr2=null) => {
      if(!reptType){
                const updatedEditFields = editFormFields.filter( (field) => {

            if (reportTypes && field.attribute === 'reportType') {
                field.disabled = reptType?true:false;
                field.options = reportTypes;
                // field.getOptions = () => {
                //     return reportTypes;
                // }
                setModalFormStatusObj({ text: '' });
            }
            else if (routes && field.attribute === 'selectRoute' ) {
                field.disabled = false;
                field.options = routes;
                // field.getOptions = () => {
                //     return routes;
                // }
                setModalFormStatusObj({ text: '' });
            }

            return field;
        });

        setEditFormFields( updatedEditFields );
      }

    };
    const onSectorChange=(e)=>{

      let newUpdates = modalFormData ? { ...modalFormData } : {}
      newUpdates['storeId'] = e.selectRoute ?? '';
      setModalFormData(newUpdates);
      validateAndShowFORMErrors(e);
    }


    const onModalFormInputChange = (e, newFormData) => {
        const { name, value } = e.target;

        let newUpdates = modalFormData ? { ...modalFormData } : {}
        newUpdates[name] = value ?? '';

        setModalFormData( { ...newFormData } );


        validateAndShowFORMErrors(e);
    }

    const validateAndShowFORMErrors = (e) => {

      const { name = '', value = '', required = false, id = '' } = e?.target || {};
        const inpKey = id ?? name;
        /** Step 1 */

        const updtReportTypeFields = () => {
          let updatedEditFields = [];
          switch ( value?value:reptType) {
            case '':

              updatedEditFields = [ ...allFormInputs.filter( _ =>
                ( _.name === 'reportType' || _.name === 'startDate' || _.name === 'endDate' || _.name === 'selectRoute' )
              ) ];

            break;
            case 'salesSummary':

              updatedEditFields = [ ...allFormInputs.filter( _ =>
                ( _.name === 'reportType' || _.name === 'filterByTransactionDate' || _.name === 'startDate' || _.name === 'endDate' || _.name === 'selectRoute' )
              ) ];

            break;
            case 'inventoryTransactionAggregate':

              updatedEditFields = [ ...allFormInputs.filter( _ =>
                ( _.name === 'reportType' || _.name === 'transDate' || _.name === 'selectRoute' )
              ) ];

            break;
            case 'itemwiseAggregate':
              updatedEditFields = [ ...allFormInputs.filter( _ =>
                ( _.name === 'reportType' || _.name === 'startDate' || _.name === 'endDate' || _.name === 'selectRoute' )
              ) ];

            break;
            case 'invoiceReport':
              updatedEditFields = [ ...allFormInputs.filter( _ =>
                ( _.name === 'reportType' || _.name === 'startDate' || _.name === 'endDate' || _.name === 'selectRoute' )
              ) ];
            break;
            case 'invoiceItemWiseReport':
              updatedEditFields = [ ...allFormInputs.filter( _ =>
                ( _.name === 'reportType' || _.name === 'startDate' || _.name === 'endDate' || _.name === 'selectRoute' )
              ) ];
            break;
            case 'sectorSalesReport':
              updatedEditFields = [ ...allFormInputs.filter( _ =>
                ( _.name === 'reportType' || _.name === 'startDate' || _.name === 'endDate' || _.name === 'selectRoute' )
              ) ];
            break;
            default:
            break;
          }

          if (updatedEditFields.length > 0) {
            setEditFormFields( updatedEditFields );

            // updateEditFormInputs(null, null);
          }

        }

        if (name === 'reportType' || ( reptType && reptType.length)) {

            updtReportTypeFields();
        }

        if ( required === true ) {
            validateEmptyError( e.target );
        }

    }

    const validateEmptyError = ( targetElt ) => {
        const { name, value, id } = targetElt;
        const inpKey = id ?? name;

        const inpElt = document.getElementById(inpKey);

        const childValidateMsgDiv = document.createElement('div');
        childValidateMsgDiv.id = inpKey +'_childValidateMsgDiv';
        childValidateMsgDiv.classList = ['validationMsgDiv'];

        const foundChildDiv = document.getElementById(inpKey +'_childValidateMsgDiv');


        if (foundChildDiv) {
          try {
            if (targetElt.parentElement) {
              targetElt.parentElement.removeChild(foundChildDiv);
            }
            else {
              // Fix: Datepicker input not returning event object
              foundChildDiv.remove();
            }
          }
          catch(e) {
            console.error('Log: Error occured while removing existing error message:', e.message);
          }
        }


        if ( value === '' ) {
          childValidateMsgDiv.classList.add('error');
          childValidateMsgDiv.innerHTML = '* Required';
        }

        if(targetElt.parentElement) {
          targetElt.parentElement.appendChild(childValidateMsgDiv);
        }
        else {
          // Fix: Datepicker input not returning event object
          inpElt.appendChild(childValidateMsgDiv);
        }
    };

    const updateEditFormValues = async (e, updatedFormObj) => {

        // ReactDOM.flushSync( () => {
        //     if (e) {
        //         const selectedReportType = reportTypes.find( item => item.value === e);
        //         console.log('selectedReportType:', selectedReportType);

        //         /** Set to respective fields */
        //         // const updatedEditFields = editFormFields.filter( (field) => {

        //         //     if (selectedReportType ) {
        //         //         if ( field.attribute === 'reportType' ) {
        //         //             field.value = selectedReportType.label;
        //         //         }
        //         //     }

        //         //     return field;

        //         // });

        //         // setEditFormFields( _prev => {
        //         //     return [ ...updatedEditFields ] ;
        //         // });
        //     }
        // })
    };




    const validateFormInputs = (body, frm='') => {
        setModalFormStatusObj({ text: '' });

        if (!body) {
            setModalFormStatusObj({ error: 'Please select all the necessary fields!' });
            return false;
        }

        if ( frm ==='downloadReportPopup' ) {
            if (!body.reportType && !reptType) {
                setModalFormStatusObj({ error: 'Please select report type.' });
                return false;
            }
            else {

              switch ( body.reportType ||reptType) {
                case 'salesSummary':

                  if ( !body.startDate || !body.endDate || !body.selectRoute ) {
                    setModalFormStatusObj({ error: 'Please select all required fields(*).' });
                    if(body.startDate && body.endDate && !body.selectRoute){
                      setModalFormStatusObj({ error: 'Please select a Route.' });

                      }
                    return false;
                  }

                break;
                case 'inventoryTransactionAggregate':

                  if ( !body.transDate || !body.selectRoute ) {
                    setModalFormStatusObj({ error: 'Please select all required fields(*).' });
                    if(body.transDate && !body.selectRoute){
                    setModalFormStatusObj({ error: 'Please select a Route.' });

                    }
                    return false;
                  }

                  body.startDate = body.transDate;
                  body.endDate = body.transDate;
                  delete body.transDate;

                break;
                case 'itemwiseAggregate':
                  if ( !body.startDate || !body.endDate || !body.selectRoute ) {
                    setModalFormStatusObj({ error: 'Please select all required fields(*).' });
                    if(body.startDate && body.endDate && !body.selectRoute){
                      setModalFormStatusObj({ error: 'Please select a Route.' });

                      }
                    return false;
                  }

                break;
                case 'invoiceReport':
                  if ( !body.startDate || !body.endDate || !body.selectRoute ) {
                    setModalFormStatusObj({ error: 'Please select all required fields(*).', });
                    if(body.startDate && body.endDate && !body.selectRoute){
                      setModalFormStatusObj({ error: 'Please select a Route.' });

                      }
                    return false;
                  }
                break;
                case 'invoiceItemWiseReport':
                  if ( !body.startDate || !body.endDate || !body.selectRoute ) {
                    setModalFormStatusObj({ error: 'Please select all required fields(*).' });
                    if(body.startDate && body.endDate && !body.selectRoute){
                      setModalFormStatusObj({ error: 'Please select a Route.' });

                      }
                    return false;
                  }
                break;
                case 'sectorSalesReport':
                  if ( !body.startDate || !body.endDate || !body.selectRoute ) {
                    setModalFormStatusObj({ error: 'Please select all required fields(*).', });
                    if(body.startDate && body.endDate && !body.selectRoute){
                      setModalFormStatusObj({ error: 'Please select a Route.' });

                      }
                    return false;
                  }
                break;
                default:
                break;
              }

            }

        }
        return true;
    };

    const onSubmit = async (e, updated) => {

        try {


          updateButtonTxtStatus(true, e, 'Processing...', true, {
            className: 'failedCls',
            defaultButtonText: 'Download',
            loadingImage: LoadingIn
        });
            if ( !validateFormInputs(updated, 'downloadReportPopup' ) ) {
                updateButtonTxtStatus(true, e, 'Download', false);
                return false;
            }

            try {
                const reportTypeObj = reportTypes.find( row => (updated.reportType?updated.reportType:reptType) === row.value);
                if ( !reportTypeObj ) {
                    setModalFormStatusObj({ error: 'Invalid report type selected, Please check.'});
                    updateButtonTxtStatus(true, e, 'Download', false);
                    return false;
                }

                const downloadPayload = {
                    reportType: reportTypeObj.id
                };
                if (updated.startDate) {
                  downloadPayload.startDate = moment(updated.startDate).format();
                }
                if (updated.endDate) {
                  downloadPayload.endDate = moment(updated.endDate).format();
                }
                if ( updated.selectRoute ) {
                  downloadPayload.storeId = updated.selectRoute;
                }
                if (updated.reportType === 'salesSummary') {
                  downloadPayload.filterByTransactionDate = false;
                  if ( updated.filterByTransactionDate === true) {
                    downloadPayload.filterByTransactionDate = true;
                  }
                }



                let header={
                  'Accept':'text/csv'
                }
                  try {

                  const res= await APIEndpoint.post(`sales/reports`,downloadPayload,header);
                  if(res.status==='scheduled'){
                    setTimeout(() => {
                      const dowId = APIEndpoint.get(`downloads/status/${res.id}`);
                      dowId.then((val) => {
                        if (val.status === "completed") {
                            APIEndpoint.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
                                .then((ItemMasterDwnld) => {
                                    const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', val.fileName + '.csv');
                                    document.body.appendChild(link);
                                    link.click();
                                    updateButtonTxtStatus(true, e, 'Downloaded', false, { defaultButtonText: 'Download' });
                                    props.onClose();
                                   typeof props.setIsDownloading==='function' && props.setIsDownloading({rept:"Reconciled",stat:"Success"});
                                    setModalFormStatusObj({ text: '' });

                                })
                                .catch((error) => {
                                  updateButtonTxtStatus(true, e, 'Download', false);

                                });
                        }
                    });
                      }, 8000);
                  }
                  }
                   catch (error) {

                    setTimeout(() => {
                      typeof props.setIsDownloading==='function' &&props.setIsDownloading({rept:"Reconciled",stat:"Failed"});
                    }, 5000);

                   }










              }
              catch( er ) {
                  console.log('Error while downloading report...', er.message);
              }


        }
        catch(err) {
            setModalFormStatusObj({ error:err.data.msg});
        }
    };

    return (
        <EditModal
            className='salesPageModal'
            title={ modalTitle }
            customModalStyle={ modelStyle }
            onClose={ () => props.onClose() }
            onSubmit={onSubmit}
            modalFormStatusObj={modalFormStatusObj}
            modalCustomButtons={modalCustomButtons}
            fields={ editFormFields.map( (elt) => {
              if(elt.attribute==='reportType'&& reptType){
                return {...elt, initialValue: '',disabled:true }
              }else
                    return {...elt, initialValue: '' }
                })
            }
            showFieldsInColumn={true}

            onChange={ (e, updated) => {

              setModalFormStatusObj({text:""})

                    setUpdatedFormFieldValues(updated);

                    if (typeof e === 'string') { // Search select
                        updateEditFormValues(e, updated);
                    }
                    if(!e && updated){
                      onSectorChange(updated)
                    }
                    else {
                        onModalFormInputChange(e, updated);
                    }

                }
            }
        />
    )
};