import React from 'react'

const PassengerInflightDetView = (props) => {
  return (
    <div style={{width:"50%"}}>
        PassengerInflightDetView
        lorem
    </div>
  )
}

export default PassengerInflightDetView