/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import '../assets/styles/modal.scss'
import closeIcon from '../images/icons/Icon2.png'
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import { passwordRegex,emailRegex,mobileNumberRegex } from '../utils/commonFunctions';


export const UpdateEmp = (props) => {
  const {fields,type, deptValues,newDeptAdd,newDesigAdd,setNewDeptAdd,setNewDesigAdd,desigValues,setSubmitFormChanges,section,updatedRoleDes,updatedRoleDep,setSection,resetPasswordchecked,selectSearchOptionsDesignation,setSelectSearchOptionsDepartment,selectSearchOptionsDepartment,setSelectSearchOptionsDesignation,setResetPasswordChecked,cabinState,setCabinState,empState}=props;
  const [updated, setUpdated] = React.useState(null);
  const { t } = useTranslation();
  const [empPassword,setEmpPassword]=useState('');
  const [retypeEmpPassword,setRetypeEmpPassword]=useState('');
  const [FieldCautions,setFieldCautions]=useState({});
  const [FieldErrors,setFieldErrors]=useState({password:false,passwordConf:false,email:false,mobile:false});
  const [passwordUpdates,setPasswordUpdates]=useState({});
  const preCompile = {
    passwordReq: t('warningTexts.passwordReq'),
    passwordMatchCheck:t('warningTexts.passwordMatchCheck'),
    passwordNumeric:t('warningTexts.passwordNumeric'),
    emailValidity:t('warningTexts.emailValidity'),
    mobileNumberValidity:t('warningTexts.mobileNumberValidity'),
    newDepartmentAdd:t('warningTexts.employees.newDepartmentAdd'),
    newDesignationAdd:t('warningTexts.employees.newDesignationAdd'),

  }

useEffect(() => {
    checkPasswordFieldsValidity();
}, [passwordUpdates])

useEffect(() => {
if(deptValues||desigValues){
  setSection({department:deptValues,designation:desigValues})
}
}, [deptValues,desigValues])

useEffect(() => {
  if(updatedRoleDes || updatedRoleDep){
    if(updatedRoleDep){
      setNewDeptAdd(null);
    }
    if(updatedRoleDes){
      setNewDesigAdd(null);
    }
  }
}, [updatedRoleDes,updatedRoleDep])

useEffect(() => {
    buttonStatus(FieldErrors);
}, [FieldErrors])


const buttonStatus=(FieldErrors)=>{
 return !Object.values(FieldErrors).every(v => v === false);
}

  let isError = false,
   updateStatusText="";
  if (props.modalFormStatusObj) {
    if (('text' in props.modalFormStatusObj)) {
      updateStatusText= props.modalFormStatusObj.text;
    }
    if (('error' in props.modalFormStatusObj)) {
      isError = props.modalFormStatusObj.error;
    }
  }

  const checkPasswordFieldsValidity = () => {
    if (Object.keys(passwordUpdates).length > 0) {
      const isNumeric = (value) => /^\d+$/.test(value);
      if (!passwordUpdates.password?.length) {
        setFieldCautions((_) => ({ ..._, password: preCompile.passwordReq }));
        setFieldErrors({ ...FieldErrors, password: true });
      } else if (passwordUpdates.password&&!isNumeric(passwordUpdates.password)) {
        setFieldCautions((_) => ({ ..._, password: preCompile.passwordNumeric }));
        setFieldErrors({ ...FieldErrors, password: true });
      }
      if (isNumeric(passwordUpdates.password)&&!passwordUpdates.passwordConf?.length) {
        setFieldCautions((_) => ({ ..._, password: preCompile.passwordReq }));
        setFieldErrors({ ...FieldErrors, passwordConf: true });
      } else if (passwordUpdates.passwordConf&&!isNumeric(passwordUpdates.passwordConf)) {
        setFieldCautions((_) => ({ ..._, password: preCompile.passwordNumeric }));
        setFieldErrors({ ...FieldErrors, passwordConf: true });
      }
      if (
        passwordUpdates?.password?.length &&
        passwordUpdates?.passwordConf?.length &&
        passwordUpdates?.password !== passwordUpdates?.passwordConf
      ) {

        setFieldCautions((_) => ({ ..._, password: preCompile.passwordMatchCheck }));
        setFieldErrors({ ...FieldErrors, passwordConf: true, password: true });
      } else if (
        passwordUpdates?.password?.length &&
        passwordUpdates?.passwordConf?.length &&
        passwordUpdates?.password === passwordUpdates.passwordConf
      ) {
        setFieldErrors({ ...FieldErrors, passwordConf: false, password: false });
        setFieldCautions({});
      }
      else if (
        !passwordUpdates?.password?.length &&
        !passwordUpdates?.passwordConf?.length
      ) {
        setFieldErrors({ ...FieldErrors, passwordConf: false, password: false });
        setFieldCautions({});
      }
    }
  };

  const checkEmailValidity=(value)=>{
    return emailRegex.test(value);
  }
  const checkMobileNumberValidity=(value)=>{
    return mobileNumberRegex.test(value);
  }

  const handleChange = () => {
    setResetPasswordChecked(!resetPasswordchecked);
  };

  const onChangeCabinStates=(e)=>{
    const { name,checked } = e.target;
    if(name==='cabinCrew'){
      setCabinState({...cabinState,cabinCrew:checked})
    }
    if(name==='cabinCrewAdmin'){
      setCabinState({...cabinState,cabinCrewAdmin:checked})
    }
  }

  const onChangeEmp = (e) => {
    const { name, value } = e.target;
    let newUpdates =  updated ? { ...updated } : {}
    if(name==='password' || name==='passwordConf'){
      if (name==='password' && (value === '' || passwordRegex.test(value) || value !=='')) {
        passwordUpdates[name] = value;
        setEmpPassword(value);
        newUpdates[name] = value;
      }
      if (name==='passwordConf' && (value === '' || passwordRegex.test(value) || value !=='')) {
        passwordUpdates[name] = value;
        setRetypeEmpPassword(value);
        newUpdates[name] = value;
      }
      setPasswordUpdates({...passwordUpdates})
      setSubmitFormChanges({ ...newUpdates,...passwordUpdates,...section});
      checkPasswordFieldsValidity();
    }
    else if(name==='email'){
    let res=checkEmailValidity(value);
    if(res){
      setFieldErrors({...FieldErrors,email:false});
      newUpdates[name] = value;
      setUpdated({...newUpdates});
      setSubmitFormChanges({ ...newUpdates,...passwordUpdates,...section});
    }
    else{
      setFieldErrors({...FieldErrors,email:true});
      setFieldCautions((_)=>({..._,email:preCompile.emailValidity}));
      newUpdates[name] = value;
      setUpdated({...newUpdates});
      setSubmitFormChanges({ ...newUpdates,...passwordUpdates,...section});
    }
    }
    else if(name==='mobile'){
      let res=checkMobileNumberValidity(value);
      if(res){
        setFieldErrors({...FieldErrors,mobile:false});
        newUpdates[name] = value;
        setUpdated({...newUpdates});
        setSubmitFormChanges({ ...newUpdates,...passwordUpdates,...section});
      }
      else{
        setFieldErrors({...FieldErrors,mobile:true});
        setFieldCautions((_)=>({..._,mobile:preCompile.mobileNumberValidity}));
        newUpdates[name] = value;
        setUpdated({...newUpdates,...section});
        setSubmitFormChanges({ ...newUpdates,...passwordUpdates,...section});
      }
      }
    else{
      newUpdates[name] = value;
      setUpdated({...newUpdates,...section});
      setSubmitFormChanges({ ...newUpdates,...passwordUpdates,...section});
    }

  }
  const handleSelectDept=(e)=>{
   setSection({...section,department:e.value})


  }
  const handleSelectDesg=(e)=>{
    setSection({...section,designation:e.value})

  }
  useEffect( () => {
    setSelectSearchOptionsDepartment( empState[2]?.options.map(d=>{if(d.name){
      d.label=d.name
      }return d}));
    setSelectSearchOptionsDesignation( empState[3]?.options.map(d=>{if(d.name){
      d.label=d.name
      }return d}));
  }, [fields] );

  useEffect( () => {
    setSelectSearchOptionsDepartment( empState[2]?.options);
  }, [fields,deptValues] );

  useEffect( () => {
    setSelectSearchOptionsDesignation( empState[3]?.options);
  }, [fields,desigValues] );




  useEffect( () => {
    if(!empState[2].options.find(e=>e.value===section.department)){
      setSelectSearchOptionsDepartment([ ...empState[2]?.options,{key:null,name:section.department,value:section.department,label:section.department}]);
    }
  }, [section.department] );
  useEffect( () => {
    if(!empState[3].options.find(e=>e.value===section.designation)){
      setSelectSearchOptionsDesignation([ ...empState[3]?.options,{key:null,name:section.designation,value:section.designation,label:section.designation}]);
    }
  }, [section.designation] );


  return (
      <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar">
        <div className="dialog__content">
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">{props.title}</h2>
          <hr />
          <h2 className="my-3 subheading-text-medium fontMedium margLeft18">Basic Details</h2>
          <div className="dialog__body_container flexRow justifyContentSpaceEvenly flexWrap">
            <div className='width40 floatLeft'>
               <div className="flexCol justifyContentSpaceBetween ">
                <div className=" dialog__container_item input-container">
                <div className="dialog__description margBtm16">
                  <label className="req">Employee Code</label>
                  <input
                    id='code'
                    type={'text'}
                    name="employeeCode"
                    placeholder={'Employee code'}
                    value={updated?updated[empState[0].attribute]:empState[0].initialValue?empState[0].initialValue:''}
                    onChange={onChangeEmp}
                    className='edit-input-employee'
                    />
                </div>
              </div>
            </div>
            <div  className="flexCol justifyContentSpaceEvenly ">
              <div className=" dialog__container_item input-container">
                <div className="dialog__description margBtm16">
                  <label className="select-employee-designation">Department</label>
                <CreatableSelect
                onChange={handleSelectDept}
                value={selectSearchOptionsDepartment.filter(function(option) {
                return option.value === section?.department;
                })}
                styles={ {
                input: provided => ({
                ...provided,
                color: '#fff'
                })
                }}
                options={selectSearchOptionsDepartment}
                isSearchable
                placeholder="Department"
                />
                </div>
                {newDeptAdd?<h6 className="small-body-text-regular-label-upload">{`${newDeptAdd}  ${preCompile.newDepartmentAdd}`}</h6>:<></>}
              </div>
            </div>
            </div>


            <div className='width40 floatRight'>
                  <div className="flexCol justifyContentSpaceEvenly ">
              <div className=" dialog__container_item input-container">
                <div className="dialog__description margBtm16">
                  <label className="req">Name</label>
                  <input
                    id='name'
                    name="name"
                    placeholder={'Employee Name'}
                    type={'text'}
                    value={updated?updated[empState[1].attribute]:empState[1].initialValue?empState[1].initialValue:''}
                    onChange={onChangeEmp}
                    className='edit-input-employee'
                    />
                </div>
              </div>
            </div>


            <div className="flexCol justifyContentSpaceEvenly ">
              <div className=" dialog__container_item input-container">
                <div className="dialog__description margBtm16">
                <label className="select-employee-designation">Designation</label>
                  <CreatableSelect
                  onChange={handleSelectDesg}
                  value={selectSearchOptionsDesignation.filter(function(option) {
                    return option.value === section?.designation;
                  })}
                  options={updatedRoleDes?updatedRoleDes:selectSearchOptionsDesignation}
                  styles={ {
                    input: provided => ({
                      ...provided,
                      color: '#fff'
                    })
                }}
                  isSearchable
                  placeholder="Designation"
                  />

                </div>
                {newDesigAdd?<h6 className="small-body-text-regular-label-upload">{`${newDesigAdd}  ${preCompile.newDesignationAdd}`}</h6>:<></>}
              </div>
            </div>
            </div>
          </div>

          <h2 className="my-3 subheading-text-medium fontMedium margLeft18">Contact Details</h2>

          <div className="dialog__body_container flexRow justifyContentSpaceEvenly flexWrap">
          <div className='width40'>
              <div className="flexCol justifyContentSpaceEvenly ">
              <div className=" dialog__container_item input-container">
                <div className="dialog__description margBtm16">
                  <label className="">Email</label>
                  <input id="email" type="text" placeholder={"Email Id"} className="edit-input-employee" name="email" attribute="email" onChange={onChangeEmp} value={updated?updated[empState[4].attribute]:empState[4].initialValue?empState[4].initialValue:''} />
                  {FieldErrors.email?<h6 className="small-body-text-regular-label-upload text-danger">{FieldCautions.email}</h6>:<></>}
                </div>
              </div>
            </div>

            <div className="flexCol justifyContentSpaceEvenly ">
              <div className=" dialog__container_item input-container">
                <div className="dialog__description margBtm16">
                  <label className="">Mobile</label>
                  <input id='mobile' type="text" placeholder={"Mobile (eg :+91-XXXXXXXXXX)"} className="edit-input-employee" name="mobile" attribute="amount" onChange={onChangeEmp} value={updated?updated[empState[5].attribute]:empState[5].initialValue?empState[5].initialValue:''} />
                     {FieldErrors.mobile?<h6 className="small-body-text-regular-label-upload text-danger">{FieldCautions.mobile}</h6>:<></>}
                </div>
              </div>
            </div>
            </div>
          <div className='width40'>
              <div className="flexCol justifyContentSpaceEvenly ">
              <div className=" dialog__container_item input-container">
                <div className="dialog__description margBtm16">
                  <label className="">Address</label>
                  <textarea rows="7" cols="40" name="address" placeholder={"Address"} onChange={onChangeEmp} value={updated?updated[empState[6].attribute]:empState[6].initialValue?empState[6].initialValue:''} className='edit-input-employee' />
                </div>
              </div>
            </div>
          </div>


          </div>

          <h2 className="my-3 subheading-text-medium fontMedium margLeft18">Login Details</h2>
           <div className="dialog__body_container flexCol justifyContentSpaceEvenly flexWrap">
          <div className='width40 margLeft18'>
            <div className="flexCol justifyContentSpaceEvenly">
              <div className=" dialog__container_item input-container">
              <div className="dialog__description margBtm16">
                  <label className="req">Username</label>
                  <input  id="userName" type="text" placeholder={"Username"} className="edit-input-employee" onChange={onChangeEmp} value={updated?updated[empState[7].attribute]:empState[7].initialValue?empState[7].initialValue:''} name="userName" attribute="userName" />
                </div>
              </div>
            </div>
</div>

          {
           type==="AddNewEmployee"? <>
           <div className='width40 margLeft18'><div className="flexCol justifyContentSpaceEvenly ">
              <div className=" dialog__container_item input-container">
                <div className="dialog__description margBtm16">
                  <label className="req">Password</label>
                  <input id="pwd" type="password" placeholder={"Pin"} className="edit-input-employee" onChange={onChangeEmp} value={empPassword} name="password" attribute="password" />
         {FieldErrors.password?<h6 className="small-body-text-regular-label-upload text-danger">{FieldCautions.password}</h6>:<></>}

                </div>
              </div>
            </div>

            <div className="flexCol justifyContentSpaceEvenly ">
              <div className=" dialog__container_item input-container">
                <div className="dialog__description margBtm16">
                  <label className="req">Re-type Password</label>
                  <input id="rtyPwd" type="password" placeholder={"Confirm Pin"}className="edit-input-employee" onChange={onChangeEmp} value={retypeEmpPassword} name="passwordConf" attribute="passwordConf" />
         {FieldErrors.passwordConf?<h6 className="small-body-text-regular-label-upload text-danger">{FieldCautions.password}</h6>:<></>}

                </div>
              </div>
            </div>
            </div>

            </>:<>
            <div className='width40 floatLeft margLeft18'>
              <div className="flexCol justifyContentSpaceEvenly">
              <div className=" dialog__container_item input-container">
                <div className="dialog__description__checkbox">
                  <input id="rstPwd" type={"checkbox"} value={ resetPasswordchecked} onChange={handleChange} name="resetPassword" attribute="amount" />
                  <label className="margTop8">Reset Password</label>
                </div>
                <div className='flexCol'>
                   <div className="dialog__description__checkbox flexRow">
                  <input id="cc" type={"checkbox"} value={ cabinState.cabinCrew} onClick={onChangeCabinStates} checked={ cabinState.cabinCrew} name="cabinCrew"  />
                  <label className="margTop8">Cabin Crew</label>
                </div>
                <div className="dialog__description__checkbox flexRow">
                  <input id="cca" type={"checkbox"}  value={ cabinState.cabinCrewAdmin} onClick={onChangeCabinStates} checked={ cabinState.cabinCrewAdmin} name="cabinCrewAdmin" />
                  <label className="margTop8">Cabin Crew Admin </label>
                </div>
                </div>

              </div>
            </div>
            </div>


            </>}
            {
            resetPasswordchecked? <>

    <div className='width40 floatLeft margLeft18 margTop24'>
              <div className="flexCol justifyContentSpaceEvenly ">
                 <div className=" dialog__container_item input-container">
                <div className="dialog__description margBtm16">
                  <label className="">New Password</label>
                  <input id="newPassword" type="password" placeholder={"Pin"} onChange={onChangeEmp} value={empPassword} className="edit-input-employee" name="password" attribute="password" />
                  {FieldErrors.password?<h6 className="small-body-text-regular-label-upload text-danger">{FieldCautions.password}</h6>:<></>}
                </div>
              </div>
              </div>
            </div>
            <div className='width40 floatRight margLeft18'>
            <div className="flexCol justifyContentSpaceEvenly ">
                <div className=" dialog__container_item input-container">
                <div className="dialog__description margBtm16">
                  <label className="">Re-type Password</label>
                  <input id="retypeNewPassword" type="password" placeholder={"Confirm Pin"} onChange={onChangeEmp} value={retypeEmpPassword} className="edit-input-employee" name="passwordConf" attribute="passwordConf" />
                  {FieldErrors.passwordConf?<h6 className="small-body-text-regular-label-upload text-danger">{FieldCautions.password}</h6>:<></>}
                </div>
              </div>
              </div>

            </div>



            </>:<></>}
          </div>
        {Boolean(updateStatusText) ?
          <>
            <div className={ "dialog__statusBlock " + (isError ? 'alert_error' : 'alert_success') }>
              {updateStatusText}
            </div>
          </> : null }
        </div>
          <div className="dialog__footer flexCol alignItemsCenter">
          <button  disabled={buttonStatus(FieldErrors)} className="done-button" onClick={props.onSubmit}>{props.title === 'Edit Item' ? 'Update' : 'Save'}</button>
        </div>
      </div>
    </div>
  );
}

export const UpdateEmployeeModal = (props) => {
  const [resetPasswordchecked, setResetPasswordChecked] = React.useState(false);
  const [empState, setEmpState] = React.useState(props.fields?props.fields:[]);
  const [cabinState,setCabinState]=React.useState({});
  const [section,setSection]=useState({department:null,designation:null});
  const [updatedRoleDep, setUpdatedRoleDep] = React.useState(null);
  const [updatedRoleDes, setUpdatedRoleDes] = React.useState(null);
  const [submitFormChanges,setSubmitFormChanges]=React.useState({});
  const [deptValues,setDeptValues]=React.useState(null);
  const [desigValues,setDesigValues]=React.useState(null);
  const [selectSearchOptionsDepartment, setSelectSearchOptionsDepartment] = useState([]);
  const [selectSearchOptionsDesignation, setSelectSearchOptionsDesignation] = useState([]);

  useEffect( () => {
    if(props.type!=='AddNewEmployee'){
      setCabinState({cabinCrew:empState[10].initialValue?empState[10].initialValue:false,cabinCrewAdmin:empState[11].initialValue?empState[11].initialValue:false})
    };
  }, [] );
  useEffect( () => {
   if(props.fields && props.type!=='AddNewEmployee'){
    setEmpState(props.fields);
    setDeptValues(empState[2].value);
    setDesigValues(empState[3].value);
   }
  }, [props.fields] );

  const onSubmit = async () => {
    props.onSubmit({...submitFormChanges,...cabinState,...section});
    removeContainerOverlay();
  }

const onChangeSelectSearchDepartment = (selectedValue, data) => {
const depObj=empState[2].options.find(dep=>  dep.name===selectedValue);
  setUpdatedRoleDep(depObj?.value);
  let newUpdates={};
  newUpdates['department'] =depObj?.value ;
  props.setSelectionDepartment({ ...newUpdates});
  };
  const onChangeSelectSearchDesignation = (selectedValue,data) => {
   const desObj=empState[3].options.find(des=>  des.name===selectedValue);
   setUpdatedRoleDes(desObj?.value);
   let newUpdates = {}
   newUpdates['designation'] =desObj?.value ;
   props.setSelectionDesignation({ ...newUpdates});
  };

  const onChange = (e, updated) => {
    typeof (props.onChange) === 'function' && props.onChange(e, updated)
  }

  const onClose = (e) => {
    removeContainerOverlay();
    setDesigValues(null);
    setDeptValues(null);
    props.onClose();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <UpdateEmp
      type={props.type}
      title={props.title}
      fields={props.fields}
      resetPasswordchecked={resetPasswordchecked}
      setResetPasswordChecked={setResetPasswordChecked}
      onChangeSelectSearchDepartment={(e)=>onChangeSelectSearchDepartment(e)}
      onChangeSelectSearchDesignation={(e)=>onChangeSelectSearchDesignation(e)}
      onChange={(e,updated) =>onChange(e,updated)}
      deptValues={deptValues}
      modalFormStatusObj={props.modalFormStatusObj}
      setDeptValues={setDeptValues}
      setSubmitFormChanges={setSubmitFormChanges}
      desigValues={desigValues}
      newDeptAdd={props.newDeptAdd}
      setNewDeptAdd={props.setNewDeptAdd}
      newDesigAdd={props.newDesigAdd}
      setNewDesigAdd={props.setNewDesigAdd}
      setDesigValues={setDesigValues}
      empState={empState}
      updatedRoleDep ={updatedRoleDep}
      setUpdatedRoleDep={setUpdatedRoleDep}
      updatedRoleDes ={updatedRoleDes}
      setUpdatedRoleDes={setUpdatedRoleDes}
      setEmpState={setEmpState}
      cabinState={cabinState}
      setCabinState={setCabinState}
      section={section}
      setSection={setSection}
      selectSearchOptionsDepartment={selectSearchOptionsDepartment}
      setSelectSearchOptionsDepartment={setSelectSearchOptionsDepartment}
      selectSearchOptionsDesignation={selectSearchOptionsDesignation}
      setSelectSearchOptionsDesignation={setSelectSearchOptionsDesignation}
      onClose={onClose}
      onSubmit={onSubmit}>
    </UpdateEmp>
  )
}
