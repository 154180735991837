/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { APIEndpoint } from '../../../utils/axios'
import { AgGridReact } from "ag-grid-react";
import moment from 'moment';


const ReconciliationsDfp = ({tab,group}) => {
    const [respReconciliations,setResponseReconciliations]=useState([]);


    const DataLoading=()=>{
      if(respReconciliations.length){
        return ""
      }
      else{
        return 'No Data'
      }
    }

    let message = DataLoading()
    const Columns = [
      { field: 'code',headerName: 'Date', sortable: true, filter: true, flex:1,suppressSizeToFit:true, resizable:true,tooltipField: 'code', minWidth: 270},
      { field: 'warehouseCode',headerName: 'Catering Group No', sortable: true, filter: true,flex:1,suppressSizeToFit:true, resizable:true, minWidth: 150, tooltipField: 'warehouseCode' },
      { field: 'warehouseName', headerName: 'Catering Group',valueGetter: params => {
        return params.data ? `${params.data.flightNumber}-${params.data.origin}-${params.data.destination}` : '';
    },sortable: true, filter: true, suppressSizeToFit:true,flex:1, resizable:true, minWidth: 180, tooltipField: 'warehouseName' },
      { field: 'operationDate',headerName: 'Estimation', valueGetter: params => {
        return params.data ? moment(params.data?.operationDate).format('DD-MM-YYYY') : '';
    }, sortable: true, filter: true,suppressSizeToFit:true, flex:1,resizable:true, minWidth: 180 },

      { field: 'flightDate',headerName: 'Estimation Modification by Catering',sortable: true,valueGetter: params => {
        return params.data ? moment(params.data?.flightDate).format('DD-MM-YYYY') : '';
    },  filter: true,suppressSizeToFit:true,flex:1, resizable:true, minWidth: 260 },
      { field: 'createdBy', headerName: 'Uplift by Caterer', valueGetter: (params) => {
        return params.data?.createdBy?.employeeName;
      },sortable: true, suppressSizeToFit:true,flex:1, resizable:true,filter: true, minWidth: 160 },
      { field: 'createdAt', headerName: 'Uplift Verified by Crew',valueGetter: params => {
        return params.data ? moment(params.data?.createdAt).format('DD-MM-YYYY hh:mm A') : '';
    }, sortable: true, filter: true,suppressSizeToFit:true,flex:1, resizable:true, minWidth: 180 },
      { field: 'state', headerName: 'Sales',valueGetter: params => {
        return params.data ?
            params.data.state[0].toUpperCase() + params.data.state.substring(1) : '';
    },sortable: true, filter: true,suppressSizeToFit:true,flex:1, resizable:true, minWidth: 170 },
      { field: 'progress', headerName: 'Marked as wastage by crew',valueGetter: params => {
        return params.data ?
            params.data.progress[0].toUpperCase() + params.data.progress.substring(1) : '';
    },sortable: true, filter: true,suppressSizeToFit:true,flex:1, resizable:true, minWidth: 150, tooltipField: 'progress' },
    {field:"offloadByCrew",headerName:"Offloaded by crew",suppressSizeToFit:true,flex:1, resizable:true, minWidth: 150},
    {field:"retrivedByCatTeam",headerName:"Retrieved by Catering team",suppressSizeToFit:true,flex:1, resizable:true, minWidth: 150},
    {field:"variance",headerName:"Variance",suppressSizeToFit:true,flex:1, resizable:true, minWidth: 150}
    ];

  useEffect(() => {

      const fetchData = async () => {
        const sectorsList = await getSectorList();
        if (sectorsList.length) {
        fetchReconciliation(sectorsList);
        }
      };

      fetchData();
  }, [group]);




  const getEffectiveDate=(dateChange)=>{

    if(dateChange!==undefined){
      const date = new Date(dateChange);
      const isoString = date.toISOString();
      const formattedDate = isoString.replace('Z', '+05:30');
      return formattedDate;

    }
  }


 const  getReconciliationFilters=(data,sectorsList)=>{
  if(sectorsList.length){
    const store=data[0]||null;
const date=data[1]||null;
const storeId=sectorsList.find(sec=>sec.label.split(' - ').join('-')===store)?.key;
const dateStr=getEffectiveDate(date);
return [storeId,dateStr]
  }
 }


const fetchReconciliation = async (sectorsList) => {
  try {
    const {sectors}=group;

       sectors.map(async sec => {
      const flightAndDate = sec.id.split("/");
      const [storeId,dateStr] = getReconciliationFilters(flightAndDate,sectorsList);
      let selArr=[]
      if(Boolean(storeId)&&dateStr.length){
        if(tab!=='retrieval'){
          selArr = [{ state: null }, { warehouseId: null }, { storeId: String(storeId) }, { flightDate:dateStr}];
        }
        else{
          selArr = [{state: "finalized"}, { warehouseId: null }, { storeId: String(storeId) }, { flightDate:dateStr}];
        }
      let latestValues = {};
      selArr.forEach(obj => {
        Object.entries(obj).forEach(([key, value]) => {
          if (value !== '') {
            latestValues[key] = value;
          }
        });
      });
      let setNewArr = Object.keys(latestValues).map(key => ({ [key]: latestValues[key] }));

      filterData.page = 1;
      filterData.limit = 1000;
      filterData.searchParam = "";
      filterData.filters = setNewArr;

      const res = await APIEndpoint.post('manualRecons/listing', JSON.stringify(filterData));
      if(res.items){
          setResponseReconciliations(prevState => [...prevState, ...res.items]);


          return [...respReconciliations,...res.items];
      }else{
        return [];
      }

    }
    });

  } catch (error) {
    console.log(error);
  }
}
const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const getSectorList = async () => {
    try {
      const resp = await APIEndpoint.get('stores');
      if (resp.success) {
        const options = resp.result.map(fieldValue => ({
          key: fieldValue.id,
          label: `${fieldValue.value}`,
          value: fieldValue.id
        }));
        return options;
      } else {
        console.log('Log: Error while fetching item types API');
        return [];
      }
    } catch (error) {
      console.error('Error while fetching sector list:', error);
      return [];
    }
  };



  return (

    <div className='op-aircraft-container ng-grid-right flexCol full-flex ag-theme-alpine-dark' style={{overflowY:'scroll',height:"75vh"}}>

<><div className="table-header"></div>
<div id='myGrid'className="ag-theme-alpine-dark" style={{height:"100%",whiteSpace:'nowrap !important'}}>
<AgGridReact
          id='ngo-grid'
          suppressCellFocus
          className='ngo-grid'
          columnDefs={Columns}
          loadingOverlayComponent={DataLoading}
          loadingOverlayComponentParams={{ message: message }}
          overlayNoRowsTemplate={`<span>${message}</span>`}
          rowData={respReconciliations.length?respReconciliations : []}
          suppressRowTransform={true}
        />
</div>
        </>
    </div>
  )
}

export default ReconciliationsDfp;