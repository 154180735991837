import React, { useEffect, useState } from 'react';
import { APIEndpoint, DFPAPIEndpoint } from "../../../utils/axios";
import { DataGrid, NGODatePicker,Loading } from "../../../common";
import {translationLength} from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import listIcon from "../../../assets/icons/trolley.svg";
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import { useNavigate } from 'react-router-dom';
import closeIcon from '../../../images/icons/Icon2.png'
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import moment from "moment";

export const UpliftEstimatesPage = (props) => {
  const { t } = useTranslation();
  const [warehouses, setWarehouses] = useState([]);
  const [selWarehouse, setSelWarehouse] = useState('');
  const [resetDataSource, setResetDataSource] = useState('');
  const [response,setResponse]=useState(null);
  const [estimateDate, setEstimateDate] = useState(new Date());
  const[showUpload, setShowUpload]= useState(false);
  const navigate = useNavigate();
  const sType = localStorage.getItem('sType');
  const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

  useEffect(() => {
    getWarehouses();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Columns = [
    { field: 'startingSector', headerName: 'Starting Sector', sortable: true, flex:1,filter: true, width: 240,
      valueGetter: params => params.data?.startingSector?.flightNumber+" ( "+params.data?.startingSector?.origin+"-"+params.data?.startingSector?.destination+" )"
    },
    { field: 'startingSector', headerName: 'Departure Date',flex:1, sortable: true, filter: true,
      valueGetter: params => params.data?moment(params.data?.startingSector?.departureDateTime).format('DD-MM-YYYY'):""
    },
    { field: 'startingSector', headerName: 'Warehouse', flex:1,sortable: true, filter: true,width: 300,
      valueGetter: params => params.data?params.data?.warehouseName+" ( "+params.data?.warehouseCode+" )":""
    },
    { field: 'sectors', headerName: 'Legs', flex:1,sortable: true, filter: true, width: 400, },
    {
      width: 185,headerName: '',
      resizable: true, sortable: true,
      cellRenderer: EstimateComponent
    }
  ];

  function EstimateComponent(props) {
    return (
      <div className="pointer flexRow" >
      {props.data? <img className="edit mx-2" alt='items' src={listIcon} onClick={(e) => onEstimate(props.data)}/>:<></>}
      </div>
    );
  }

  const onEstimate = async (data) => {
    let id = data?.id
    navigate('upliftEstimateDetails/estimates/'+id)
  }

  const getWarehouses = async () => {
    try {
      const resp = await DOMAIN.get('warehouses');
    if (resp.result.length > 0) {
        const options = [{key: 0, label: "Select Warehouse", value: ''}];
        resp.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.id}`});
          return fieldValue;
        });
        setWarehouses(options);
    }
    } catch (error) {
      
    }
    
  }


  const onDateChange=(e)=>{
    let date = e.target.value;
    setEstimateDate(date);
    fetchUpliftEstimates(date);
    setResetDataSource(true);
  }

  const onItemTypeChange = (e) => {
    setSelWarehouse(e.target.value);
    fetchUpliftEstimates(e.target.value);
    setResetDataSource(true);
  }

  const onUploadDistributorMappingClick=()=>{
    getWarehouses();
    setShowUpload(true)
  }

  const onUploadClose = () => {
    setShowUpload(false);
  };

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
  };

  const fetchUpliftEstimates = async () => {
    filterData.page = 1;
    filterData.limit = 50;
    filterData.filters = [{targetDate: moment(estimateDate).format('YYYY-MM-DD[T]HH:mm:ss')}, {warehouseId: Number(selWarehouse)}];
    const res = await DOMAIN.post('estimates/getUpliftEstimates', filterData);
    setResponse(res?.success);
    setResetDataSource(false);
    return res;
  }


  const onDownloadEstimates= async ()=>{
    try {
    const res= await DOMAIN.post(`estimates/download`,{startDate: moment(estimateDate), warehouseId: [selWarehouse]}, { 
      'Accept': 'text/csv',
      'Content-Type': 'application/json;charset=utf-8'
    });
    
    if(res.status==='scheduled'){
      setTimeout(() => {
        checkAndDownloadFile(res)  
      }, 5000);
    }
    }
      catch (res) {
        console.log(res.message)
      }
  }

  const checkAndDownloadFile = (res) => {
    const dowId = DOMAIN.get(`downloads/status/${res.id}`);
    dowId.then( async (val)=> {
      if(val.status==="completed"){
          DOMAIN.get(`downloads/analytics/${val.fileName}${val.fileExtension}`).then((ItemMasterDwnld) => {
          const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', val.fileName + val.fileExtension);
          document.body.appendChild(link);
          link.click();
      })
      }
      else {
        
        setTimeout(() => {
          checkAndDownloadFile(res);
        }, 7000);

      }
    });
  }
  

  return (
    <div className="device-page op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
        <button className='upload dropdownStyle1-TextMedium' onClick={() => onUploadDistributorMappingClick(true)}> <span><img  className="icon-size mt-2" alt='uploadItem' src={uploadItemIcon} /><u>Download Estimates</u></span></button>
        </div>
        <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>
          <div className="mt-2 underline">
          </div>
          <div className='flexRow alignItemsCenter'>
              <select className='select-item select-item-uplifts' onChange={onItemTypeChange} value={selWarehouse} id="item-type" style={{marginRight: '20px'}}>
                  {warehouses.map( (item, index) => (
                      <option key={index} value={item.value}>{item.label}</option>
                  ))
                  }
              </select>
              <NGODatePicker
              style={{height:"29px"}}
              value={estimateDate}
              name='filterDate'
              placeholder={t('effectiveDate')}
              placeholdertext={t('datePickerPlaceholder')}
              onChange={onDateChange} />
          </div>
        </div>
        {
        Boolean(selWarehouse === '' || estimateDate === null) ?
        <div className='flexCol full-flex alignItemsCenter justifyContentCenter'>
        {t('pages.controlPanelMenuSub.nonSelectionUplift')}
        </div>
        : !response ?
        <Loading />
        :
        <DataGrid
        columnResize
        enableGridSearch={false}
        resetDataSource={resetDataSource}
        showDefaultFilters
        className='full-flex'
        columns={Columns}
        getRows={fetchUpliftEstimates}
        />
        }
      {
        t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
      }
      {showUpload &&
        <div className="overlay mt-4" role="dialog">
          <div className="dialog sm-scrollbar">
            <div className="dialog__content">
              <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={onUploadClose} />
              <h2 className="dialog__title subheading-text-medium fontMedium">Download Estimates</h2>
              <hr />

                <div className="dialog__body_container flexRow mt-4 button-align justifyContentFlexEnd flexWrap">
                <div className={`dialog__container_item input-container`}>
                <div className={`dialog__description`}>
                  <> <label className={`fontRegular req`}>Effective Date</label>
                    <NGODatePicker
                      style={{width: '17rem'}}
                      className="input-margin"
                      isclearable={false}
                      value={estimateDate}
                      placeholder={t('effectiveDate')}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChange} />
                  </>
                </div>
                <label className={`fontRegular req mt-2`}>Warehouses</label>
                <div className={`dialog__description`}>
                  <select className='select-input-galley1'
                  onChange={onItemTypeChange}
                  >
                    {warehouses.map((_) => {
                      return (
                        <option value={_.value} key={_.value}>{_.label}</option>
                      )
                    })}
                  </select>

                </div>
                <button type="button" onClick={onDownloadEstimates} className="btn btn-info mt-4" style={{ color: "white" }}>
                  <span><img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} /></span>
                  Download
                </button>

              </div>
                  <div className="col-md-4 col-xs-12">
                  </div>
                </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
