import React from 'react'
import '../assets/styles/modal.scss'
import { useTranslation } from 'react-i18next';
import closeIcon from '../images/icons/Icon2.png'

export const DeleteConf = (props) => {
  const { t } = useTranslation();
  let isError = false,
   updateStatusText="";
   if (props.modalFormStatusObj) {
    if (('text' in props.modalFormStatusObj)) {
      updateStatusText = props.modalFormStatusObj.text;
    }
    if (('error' in props.modalFormStatusObj)) {
      updateStatusText = props.modalFormStatusObj.error;
      isError = props.modalFormStatusObj.error;
    }
  };

  const onClickDelete=()=>{
    props.onCloseDelete()
    removeContainerOverlay()
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar">
        <div className="dialog__content">
        <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={onClickDelete} />
          <div className="dialog__body_container font-weight-margin flexCol alignItemsCenter justifyContentCenter flexWrap">
            <div>{props.description}</div>
          </div>
          {Boolean(updateStatusText)&&
            <div className={"dialog__statusBlock " + (isError ? 'alert_error' : 'alert_success')}>
              {updateStatusText}
            </div>}
        </div>
        <div className="dialog__footer flexRow justifyContentSpaceEvenly margBottm20">
          <button disabled={props.buttonStat?props.buttonStat:false} className="cancel-button" onClick={onClickDelete}>{t('buttons.cancel')}</button>
          <button disabled={props.buttonStat?props.buttonStat:false} className="continue-button" onClick={props.onContinue}>{t('buttons.continue')}</button>
        </div>
      </div>
    </div>
  );
}
