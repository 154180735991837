import React from 'react'
import '../assets/styles/modal.scss'
import { useTranslation } from 'react-i18next';

export const AlertModal = (props) => {
  const { t } = useTranslation();
  let isError = false,
   updateStatusText="";
  if (props.modalFormStatusObj) {
    if (('text' in props.modalFormStatusObj)) {
      updateStatusText= props.modalFormStatusObj.text;
      if(updateStatusText){
        props.setShow(true);
      }
    }
    if (('error' in props.modalFormStatusObj)) {
      isError = props.modalFormStatusObj.error;
    }
  }
  const onClikContinue=()=>{
    props.onContinue();
    removeContainerOverlay();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar" style={props.customModalStyle}>

        <div className="dialog__content">
          <h2 className="dialog__title subheading-text-medium fontMedium">{props.title || 'Alert'}</h2>
          <hr />

          <div className="dialog__body_container font-weight-margin flexCol alignItemsCenter justifyContentCenter flexWrap">
            <div>{props.description}</div>
          </div>
          {Boolean(updateStatusText) &&props.show&&
            <div className={"dialog__statusBlock " + (isError ? 'alert_error' : 'alert_success')}>
              {updateStatusText}
            </div>}
        </div>
        <div className="dialog__footer flexRow justifyContentSpaceEvenly">
          <button className="continue-button" onClick={onClikContinue}>{t('buttons.okay')}</button>
        </div>
      </div>
    </div >
  );

}

