/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { APIEndpoint } from '../../../utils/axios';
import { DataGrid, EditModal,CessViewModal } from '../../../common';
import {translationLength, truncateString} from '../../../utils/commonFunctions';
import usePermissions from '../../../utils/userPermissions';
import { useTranslation } from 'react-i18next';
import editIcon2 from "../../../assets/edit2.svg";
import viewIcon from "../../../assets/view.svg";
import cancelIcon from "../../../assets/icons/cancel.svg";
import searchIcon from '../../../assets/icons/search.svg';
import confirmIcon from "../../../assets/icons/confirm.svg";
import { CessModifModal } from '../../../common/CessModifModal';
import { AddConditionModal } from '../../../common/AddConditionModal';
import moment from 'moment';
import * as ReactDOM from 'react-dom';
import { _ } from 'ag-grid-community';

export const CessMaster = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({});
  const tableRef = useRef();
  const [perPageLimit, setPerPageLimit] = useState(10);
  const [searchKey, setSearchKey] = useState('');
  const [selItemType, setItemType] = useState('');
  const [isReloadTableData, setIsReloadTableData ] = useState(false);
  const [ modalTitle, setModalTitle ] = useState('Edit Cess');
  const [actionItems, setActionItems] = useState([]);
  const [status, setStatus] = useState('');
  const [addFields, setAddFields] = useState([]);
  const [EditFieldsCess, setEditFieldsCess] = useState([]);
  const [variables, setVariables] = useState([]);
  const [hsnCodes, setHSNcodes] = useState([]);
  const [hsnCodesArr, setHSNcodesArr] = useState([]);
  const [regions, setRegions] = useState([]);
  const [regionsArr, setRegionsArr] = useState([]);
  const [regionsSelSearch,setRegionsSelSearch]=useState([]);
  const [hsnCodeSelSearch,setHsnCodeSelSearch]=useState([]);
  const [type, setType] = useState([]);
  const [modalCustomButtons,setModalCustomButtons]=useState([]);
  const [conditions,setConditions]=useState([]);
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [editConditionsVariable,setEditConditionsVariable]=useState("");
  const[addCondition,setAddCondition]=useState(false);
  const [conditionsNewAdd,setConditionsNewAdd]=useState([]);
  const [editObject,setEditObject]=useState({});
  const [updatedCond, setUpdatedCond] = useState(null);
  const [rowData,setRowData]=useState({});
  const [editSubmitCheck, setEditSubmitCheck] = useState(false);
  const { hasPermission: editCessMaster } = usePermissions('edit', "configuration.cess-master");
  const { hasPermission: createCessMaster } = usePermissions('create', "configuration.cess-master");
  const { hasPermission: viewDetailsCessMaster } = usePermissions('viewDetails', "configuration.cess-master");
  const { hasPermission: deleteCessMaster } = usePermissions('delete', "configuration.cess-master");

  let editCessDetails={};
  const preCompile = {
    type:t('selectButtons.selType'),
    hsnCodes:t('selectButtons.selVariable'),
    regions:t('selectButtons.selRegions'),
    operator:t('selectButtons.selOperator'),
    percentage:t('selectButtons.selPercentage'),
    value:t('selectButtons.selValue')
  }

  const AddConditionsFields=[
    {label: 'Variable', attribute: 'variable', type: 'select', disabled: false, options: [], required: true },
    {label: 'Operator', attribute: 'operator', type: 'select', disabled: false, options: [], required: true },
  ];

  const [addCessConditionsFields,setAddCessConditionsFields]=useState(AddConditionsFields);
  const [addNewCessConditionsFields,setAddNewCessConditionsFields]=useState(AddConditionsFields);
  React.useEffect(() => {
    fetchCessMaster();
    fetchHSNCodesAndRegions();
    fetchType();
    fetchType();
    fetchVariables();
  }, [t]);
useEffect(() => {
    setConditionsSelections();
}, [editConditionsVariable])

useEffect(() => {
 if(regionsSelSearch.length){
  selectSearchFieldMenu[0].options=regionsSelSearch
 }
 if(hsnCodeSelSearch.length){
  selectSearchFieldMenu[1].options=hsnCodeSelSearch

 }
}, [regionsSelSearch,hsnCodeSelSearch])

useEffect(() => {
  if(editSubmitCheck){
    onEditConditionsSubmit(updatedCond);
  }
}, [editSubmitCheck])

  useEffect(() => {
    setActionItems([
            {
                title: 'Conditions',
                attribute: 'View',
                minWidth:80,
                Width:100
            },
            {
                title: '',
                attribute: 'Edit',
                minWidth:80,
                Width:100
            },
            {
                title: '',
                attribute: 'Disable',
                minWidth:80,
                Width:100
            },
            {
              title: '',
              attribute: 'Enable',
              minWidth:80,
              Width:100
          }
        ]);
        setIsReloadTableData(true);
}, []);

  React.useEffect( () => {
    refreshTable();
  }, [tableRef]);

  const handleReset = () => {
    setState((_) => {
      return { ..._, resetDataSource: true };
    })
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
      setState((_) => {
        return { ..._, resetDataSource: false };
      })
    }, 500)
  }
  const getActionCellIcons = (cellDef, data) => {

    if (cellDef.attribute === 'View' && viewDetailsCessMaster) {
        return [
            {src: viewIcon, value:"View", title: 'View'},
        ];
    }

    if (cellDef.attribute === 'Edit' && editCessMaster) {
      return [
        {src: editIcon2, value:"Edit", title: 'Edit'},
    ];
    }

    if (cellDef.attribute === 'Disable') {

        if (data && data.active !== true && editCessMaster) {
            return [
                {src: confirmIcon, value:"Enable", title: 'Enable'},
            ];
        }
        else if (data && data.active === true && deleteCessMaster) {
            return [
                {src: cancelIcon, value:"Disable", title: 'Disable'},
            ];
        }
    }

    return [];
};

const EditConditionFieldsModify=(editMenu,updatedMenu)=>{

  let  modifiedConditions={},
  updateCon=updatedMenu.variable,
  editCon=editMenu.variable,
  swCase;
  swCase=typeof updateCon !== 'undefined'?updateCon:editCon
  switch (swCase) {
    case 'region':
        modifiedConditions={variable: updateCon?updateCon:editCon, regions: updatedMenu.regions?updatedMenu.regions:editMenu.regions, operator: updatedMenu.operator?updatedMenu.operator:editMenu.operator}
      break;
      case 'totalTaxPercentage':
        modifiedConditions={variable: updateCon?updateCon:editCon, percentage: updatedMenu.percentage?updatedMenu.percentage:editMenu.percentage, operator: updatedMenu.operator?updatedMenu.operator:editMenu.operator}
        break;
        case 'hsn':
              modifiedConditions={variable: updateCon?updateCon:editCon, hsn: updatedMenu.hsn?updatedMenu.hsn:editMenu.hsn, operator: updatedMenu.operator?updatedMenu.operator:editMenu.operator}
          break;
          case 'gstNumber':
              modifiedConditions={variable: updateCon?updateCon:editCon, gstNumber: updatedMenu.gstNumber?updatedMenu.gstNumber:editMenu.gstNumber, operator: updatedMenu.operator?updatedMenu.operator:editMenu.operator}
            break;
            case 'invoiceDate':
              modifiedConditions={variable: updateCon?updateCon:editCon, invoiceDate: updatedMenu.invoiceDate?updatedMenu.invoiceDate:editMenu.invoiceDate, operator: updatedMenu.operator?updatedMenu.operator:editMenu.operator}
              break;
    default:
      break;
  }

  if(Boolean(modifiedConditions)&&state.selected.action==='editCess'){
  let modObj= editConditionsModifyFields(modifiedConditions,editObject,updatedMenu);
  let ind=  conditions.findIndex((c)=>c===editObject);
  if (ind !== -1){
  conditions[ind] = modObj;

  }
   onAddcessClose();
    }

    if(Boolean(modifiedConditions)&&state.selected.action==='addCess'){
     let modObj= editConditionsModifyFields(modifiedConditions,editObject,updatedMenu)
      let ind=  conditionsNewAdd.findIndex((c)=>c===editObject);
    if (ind !== -1){
      conditionsNewAdd[ind] = modObj;
    }
     onAddcessClose();
    }
}


const SelectSearchFields = [
  {key: 0, label: 'Regions', name: 'regions', id: 'regions', attribute: 'regions', type: 'rcSelectAll'
      , defaultValue: ''
      , value: ''
      , className: 'select-search-cls'
      , options: regionsSelSearch
      , multiple: false
      , search: true
      , autoComplete: 'on'
      , autoFocus: false
      , disabled: false,
      placeholder: 'Select Region'
  },

  {key: 1, label: 'HSN code', name: 'hsn', id: 'hsn', attribute: 'hsn', type: 'rcSelectAll', defaultValue: ''
  , value: ''
  , className: 'select-search-cls'
  , options: hsnCodeSelSearch
  , multiple: false
  , search: true
  , autoComplete: 'on'
  , autoFocus: false
  , disabled: false, placeholder: 'Select HSN' },
];
const [selectSearchFieldMenu,setSelectSearchFieldMenu]=useState(SelectSearchFields);
const editConditionsModifyFields=(values,editObject,updatedObj)=>{

  let [operator,opKey]=relationSelect(values?.operator!=='exists'?values?.operator:'');

    let valObj={},
     val=[],
     predStr,
    predArrayOp,
     predicate
    if(values.variable==='region'){

      let[str, arrayOp]=getPredicate(values,updatedObj.data);
      predStr=str;
      predArrayOp=arrayOp;

      if(predStr&&predArrayOp){
        predicate=`${predStr['label']} ${operator} <${predArrayOp.join(', ')}>`
       let condObj={variable:values.variable,operator:opKey,value:updatedObj.data,predicate};
       return condObj;
      }
    }
    if(values.variable==='totalTaxPercentage'){
      let[str, arrayOp]=getPredicate(values,[]);
      predStr=str;
      predicate=`${predStr['label']} ${operator} ${values.percentage}`
       let condObj={variable:values.variable,operator:opKey,value: parseInt(values.percentage),predicate};
       return condObj;
    }
    if(values.variable==='hsn'){
      let[str, arrayOp]=getPredicate(values,updatedObj.data);
      predStr=str;
      predArrayOp=arrayOp;

      if(predStr){
        predicate=`${predStr['label']} ${operator} <${predArrayOp.join(', ')}>`
       let condObj={variable:values.variable,operator:Boolean(opKey)?opKey:operator,value:predArrayOp,predicate};
       return condObj;
      }

    } if(values.variable==='gstNumber'){
      let value = (values.gstNumber === 'true');
      let[str, arrayOp]=getPredicate(values,val);
      predStr=str;
      predArrayOp=arrayOp;
      if(predStr){
      predicate=`${predStr['label']}  ${value?"":"does not"} ${values.operator}`
       let condObj={variable:values.variable,operator:opKey,value,predicate};
       return condObj;
      }

    }
    if(values.variable==='invoiceDate'){
     let [date,value]= getEffectiveDate(values.invoiceDate)
      date = date.split("-").reverse().join("-");
      let[str, arrayOp]=getPredicate(values,[]);
      predStr=str;
      predArrayOp=arrayOp;
      if(predStr){
        predicate=`${predStr['label']} ${operator} ${date}`
        let condObj={variable:values.variable,operator:opKey,value,predicate};
        return condObj;
        }
    }
  }

  const setConditionsSelections=(val)=>{
    if(state?.selected?.action==='editCess'){
    if(editConditionsVariable==='region'){
      const filArr=addCessConditionsFields.filter(row=>{
        if(row.label!=="Percentage"&&row.label!=="HSN code"&&row.label!=="Total Tax Percentage"&&row.label!=="GSTIN"&&row.label!=="Invoice Date"){
          return row;
        }
      });
      const options=  filArr.map( row => {
        if (row.attribute === "operator" ) {
          row.options = hsnRegionOperator;
        }
        return row;
      });
      setAddCessConditionsFields(options);
      if(val === "one of" || val ==="not one of"){
        if(!addCessConditionsFields.some(p => p.label === "Regions")){
          setAddCessConditionsFields((prev) => [...prev, selectSearchFieldMenu[0]])
        }
      }

    }
    if(editConditionsVariable==='totalTaxPercentage'){
      const filArr=addCessConditionsFields.filter(row=>{
        if(row.label!=="HSN code"&&row.label!=="Regions"&&row.label!=="GSTIN"&&row.label!=="Invoice Date"){
          return row;
        }
      });
      const options=  filArr.map( row => {
        if (row.attribute === "operator" ) {
          row.options = regionBoundaries;
        }
        return row;
      });
      setAddCessConditionsFields(options);
      if(regionBoundaries.some(reg=>reg.value===val)){
        if(!addCessConditionsFields.some(p => p.label === "Percentage")){
          setAddCessConditionsFields((prev) => [...prev, {label: 'Percentage', attribute: 'percentage', type: 'number', disabled: false,required: true }])
        }
      }

    }
    if(editConditionsVariable==='hsn'){
      const filArr=addCessConditionsFields.filter(row=>{
        if(row.label!=="Percentage"&&row.label!=="Regions"&&row.label!=="Total Tax Percentage"&&row.label!=="GSTIN"&&row.label!=="Invoice Date"){
          return row;
        }
      });
      const options=  filArr.map( row => {
        if (row.attribute === "operator" ) {
          row.options = hsnRegionOperator;
        }
        return row;
      });
      setAddCessConditionsFields(options);
      if(hsnRegionOperator.some(reg=>reg.value===val)){
        if(!addCessConditionsFields.some(p => p.label === "HSN code")){
          setAddCessConditionsFields((prev) => [...prev, selectSearchFieldMenu[1]])
        }
    }
    }

    if(editConditionsVariable==='gstNumber'){
      const filArr=addCessConditionsFields.filter(row=>{
        if(row.label!=="Percentage"&&row.label!=="Regions"&&row.label!=="HSN code"&&row.label!=="Total Tax Percentage"&&row.label!=="Invoice Date"){
          return row;
        }
      });
      const options=  filArr.map( row => {
        if (row.attribute === "operator" ) {
          row.options = gstIn;
        }
        return row;
      });
      setAddCessConditionsFields(options);
      if(gstIn.some(reg=>reg.value===val)){
        if(!addCessConditionsFields.some(p => p.label === "GSTIN")){
          setAddCessConditionsFields((prev) => [...prev, {label: 'GSTIN', attribute: 'gstNumber', type: 'select',options:gstValue, disabled: false,required: true }])
        }
      }
    }

    if(editConditionsVariable==='invoiceDate'){
      const filArr=addCessConditionsFields.filter(row=>{
        if(row.label!=="Percentage"&&row.label!=="Regions"&&row.label!=="HSN code"&&row.label!=="GSTIN"&&row.label!=="Total Tax Percentage"){
          return row;
        }
      });
      const options=  filArr.map( row => {
        if (row.attribute === "operator" ) {
          row.options = regionBoundaries;
        }
        return row;
      });
      setAddCessConditionsFields(options);
      if(regionBoundaries.some(reg=>reg.value===val)){
        if(!addCessConditionsFields.some(p => p.label === "Invoice Date")){
          setAddCessConditionsFields((prev) => [...prev, {label: 'Invoice Date', attribute: 'invoiceDate', type: 'date',disabled: false,required: true }])
        }
      }
    }
    }
    else{
      if(editConditionsVariable==='region'){
        const filArr=addNewCessConditionsFields.filter(row=>{
          if(row.label!=="Percentage"&&row.label!=="HSN code"&&row.label!=="Total Tax Percentage"&&row.label!=="GSTIN"&&row.label!=="Invoice Date"){
            return row;
          }
        });
        const options=  filArr.map( row => {
          if (row.attribute === "operator" ) {
            row.options = hsnRegionOperator;
          }
          return row;
        });
        setAddNewCessConditionsFields(options);
        if(val === "one of" || val ==="not one of"){
          if(!addNewCessConditionsFields.some(p => p.label === "Regions")){
            setAddNewCessConditionsFields((prev) => [...prev, selectSearchFieldMenu[0]])
          }
        }
      }
      if(editConditionsVariable==='totalTaxPercentage'){
        const filArr=addNewCessConditionsFields.filter(row=>{
          if(row.label!=="HSN code"&&row.label!=="Regions"&&row.label!=="GSTIN"&&row.label!=="Invoice Date"){
            return row;
          }
        });
        const options=  filArr.map( row => {
          if (row.attribute === "operator" ) {
            row.options = regionBoundaries;
          }
          return row;
        });
        setAddNewCessConditionsFields(options);
        if(regionBoundaries.some(reg=>reg.value===val)){
          if(!addNewCessConditionsFields.some(p => p.label === "Percentage")){
            setAddNewCessConditionsFields((prev) => [...prev, {label: 'Percentage', attribute: 'percentage', type: 'number', disabled: false,required: true }])
          }
        }

      }
      if(editConditionsVariable==='hsn'){
        const filArr=addNewCessConditionsFields.filter(row=>{
          if(row.label!=="Percentage"&&row.label!=="Regions"&&row.label!=="Total Tax Percentage"&&row.label!=="GSTIN"&&row.label!=="Invoice Date"){
            return row;
          }
        });
        const options=  filArr.map( row => {
          if (row.attribute === "operator" ) {
            row.options = hsnRegionOperator;
          }
          return row;
        });
        setAddNewCessConditionsFields(options);
        if(hsnRegionOperator.some(reg=>reg.value===val)){
          if(!addCessConditionsFields.some(p => p.label === "HSN code")){
            setAddNewCessConditionsFields((prev) => [...prev, selectSearchFieldMenu[1]])
          }

      }
      }

      if(editConditionsVariable==='gstNumber'){
        const filArr=addNewCessConditionsFields.filter(row=>{
          if(row.label!=="Percentage"&&row.label!=="Regions"&&row.label!=="HSN code"&&row.label!=="Total Tax Percentage"&&row.label!=="Invoice Date"){
            return row;
          }
        });
        const options=  filArr.map( row => {
          if (row.attribute === "operator" ) {
            row.options = gstIn;
          }
          return row;
        });
        setAddNewCessConditionsFields(options);
        if(gstIn.some(reg=>reg.value===val)){
          if(!addCessConditionsFields.some(p => p.label === "GSTIN")){
            setAddNewCessConditionsFields((prev) => [...prev, {label: 'GSTIN', attribute: 'gstNumber', type: 'select',options:gstValue, disabled: false,required: true }])
          }
        }
      }

      if(editConditionsVariable==='invoiceDate'){
        const filArr=addNewCessConditionsFields.filter(row=>{
          if(row.label!=="Percentage"&&row.label!=="Regions"&&row.label!=="HSN code"&&row.label!=="GSTIN"&&row.label!=="Total Tax Percentage"){
            return row;
          }
        });
        const options=  filArr.map( row => {
          if (row.attribute === "operator" ) {
            row.options = regionBoundaries;
          }
          return row;
        });
        setAddNewCessConditionsFields(options);
        if(regionBoundaries.some(reg=>reg.value===val)){
          if(!addCessConditionsFields.some(p => p.label === "Invoice Date")){
            setAddNewCessConditionsFields((prev) => [...prev, {label: 'Invoice Date', attribute: 'invoiceDate', type: 'date',disabled: false,required: true }])
          }
        }
      }
    }

    }

const onAddConditions=()=>{
  setAddCondition(true);
   if(state.selected.action==='editCess'){
    setModalTitle("Add Condition")
    const options=  addCessConditionsFields.map( row => {
        if (row.attribute === "variable" ) {
          row.options = variables;
        }
        if (row.attribute === "operator" ) {
          row.options = [{key:0, label:preCompile.operator, value: ''}];
        }
        return row;
      });
      setAddCessConditionsFields(options);
      setState( (_prev) => {
        return { ..._prev,menu: {modal: 'addCondition' }}
         });
   }
  else{
    setModalTitle("Add Condition")
    const options=  addNewCessConditionsFields.map( row => {
        if (row.attribute === "variable" ) {
          row.options = variables;
        }
        if (row.attribute === "operator" ) {
          row.options = [{key:0, label:preCompile.operator, value: ''}];
        }
        return row;
      });
      setAddNewCessConditionsFields(options);
      setState( (_prev) => {
        return { ..._prev,menu: {modal: 'addCondition' }}
         });

 }
}
  const refreshTable = () => {
    tableRef.current.refreshTableHandler(selItemType);
  };

  const hsnRegionOperator=[
  {key: 0, label:preCompile.operator, value: ''},
  {key: 1, label:'One Of', value: 'one of'},
  {key: 2, label:'Not One Of', value: 'not one of'}];

  const gstIn=[{key: 0, label:preCompile.operator, value: ''},
    {key: 1, label:'Exists', value: 'exists'}];

const gstValue=[{key: 0, label:preCompile.value, value: ''},
{key: 1, label:'True', value: true},{key: 2, label:'False', value: false}];


  const regionBoundaries=[
  {key: 0, label:preCompile.operator, value: ''},
  {key: 1, label:'Less Than', value: 'less than'},
  {key: 2, label:'Equal To', value: 'equal to'},
  {key: 3, label:'Greater Than', value: 'greater than'},
  {key: 4, label:'Less Than Or Equal To', value: 'less than or equal to'},
  {key: 5, label:'Greater Than Or Equal To', value: 'greater than or equal to'},
  {key: 6, label:'Not Equal to', value: 'not equal to'}];

  const Columns = [
    { field: 'type',headerName: 'Type', sortable: true,flex:1,width:140,minWidth:120},
    { field: 'shortName',headerName: 'Short Name', sortable: true,flex:1,minWidth:120 },
    { field: 'name',headerName: 'Name', sortable: true, flex:1,minWidth:120},
    { field: 'description', headerName: 'Description',sortable: true,flex:1,minWidth:250,valueGetter: (params) => {
      return truncateString(params.data?.description,35);
    } },
    { field: 'amount',headerName: 'Amount', sortable: true,flex:1,minWidth:120 },
    { field: 'active',headerName: 'Status', sortable: true,  valueGetter: (params) => {
      let val=!params.data?"":params.data?.active?'Active':'Inactive';
      setStatus(val);
      return val;
    } ,flex:1,minWidth:150},
  ];

  const EditFormFields = [
    {label: 'Type', attribute: 'type', type: 'select', disabled: false, options: [], required: true },
    {label: 'Name', attribute: 'name', type: 'text', disabled: false, required: true},
    {label: 'Short Name', attribute: 'shortName', type: 'text', disabled: false,note:"(Used in reports)" },
    {label: 'Description', attribute: 'description', type: 'textarea', disabled: false,rows:4},
    {label: 'Value', attribute: 'amount', type: 'number', disabled: false, required: true},
  ];

const relationSelect=(val)=>{
  let operator="",
  addCessOperator="",
  opKey=""
  switch(val) {
    case 'one of':
      operator='in'
      addCessOperator='in'
      opKey='in'
      break;

    case 'not one of':
     operator='not in'
     addCessOperator='nin'
     opKey='nin'
      break;

      case 'less than':
        operator='<'
        opKey='lt'
      addCessOperator=opKey

      break;
      case 'equal to':
        operator='='
        opKey='eq'
      addCessOperator=opKey
      break;
      case 'greater than':
      operator='>'
      opKey='gt'
      addCessOperator=opKey
      break;
      case 'less than or equal to':
      operator='<='
      opKey='lte'
      addCessOperator=opKey
      break;
      case 'greater than or equal to':
      operator='>='
      opKey='gte'
      addCessOperator=opKey
      break;
      case 'not equal to':
      operator='!='
      opKey='neq'
      addCessOperator=opKey
      break;
    default:
      operator=''
      opKey='exists'
      addCessOperator=''

  }
  return[ operator,opKey,addCessOperator];
}


const onAction = async (e, action, selectedRowData) => {
editCessDetails=selectedRowData;
setRowData(selectedRowData);
let updatedEditFields = [];
if(action.value==="View"){
  setConditions(selectedRowData.conditions)
  setState( (_prev) => {
     return { ..._prev, selected: {selectedRowData, action: 'viewCess' }}
      });

}
else if(action.value==='Edit'){
  setModalTitle('Edit Cess');
  setConditions(selectedRowData.conditions);
  setState( (_prev) => {
    return { ..._prev, selected: {selectedRowData, action: 'editCess' }}
     });
if(type.length){
   EditFormFields.map( row => {
      if (row.attribute === "type" ) {
        row.options = type;
      }
      return row;
    });
    setEditFieldsCess(EditFormFields);
}

}
else if(action.value==="Disable"){
  setModalTitle('Disable Cess -' + selectedRowData.name);
  updatedEditFields = [
    {
        label: 'You are about to disable this cess. Are you sure you want to continue?',
        attribute: 'alertMessage', type: 'alertMessage'
    }
];
setModalCustomButtons([{label:'Cancel',attribute:'button',type:"button",disabled:false,className:'cancel-button',action:()=>onClose()},
                     {label:'Confirm',attribute:'button',type:"button",disabled:false,className:'submit-button',action:(state)=>onEnableDisableAction(state)}])
setState( (_prev) => {
  return { ..._prev,selected: {selectedRowData, action: 'Disable' }}
   });
setEditFieldsCess(updatedEditFields);
}
else if(action.value==="Enable"){
  setModalTitle('Enable Cess -' + selectedRowData.name);
  updatedEditFields = [
    {
        label: 'You are about to enable this cess. Are you sure you want to continue?',
        attribute: 'alertMessage', type: 'alertMessage'
    }
];
setModalCustomButtons([{label:'Cancel',attribute:'button',type:"button",disabled:false,className:'cancel-button',action:()=>onClose()},
                     {label:'Confirm',attribute:'button',type:"button",disabled:false,className:'submit-button',action:(state)=>onEnableDisableAction(state)}])
setState( (_prev) => {
  return { ..._prev,selected: {selectedRowData, action: 'Enable' }}
   });
setEditFieldsCess(updatedEditFields);
}
else{
  setModalTitle('Add Cess');
  setState( (_prev) => {
    return { ..._prev, selected: {selectedRowData,  action: 'addCess' }}
  });
}
    /**
     * Set to edit form
     */

  };
  const onFieldChangeHandler = (e) => {

  };

  const onClose = () => {
    setModalFormStatusObj({ text: '' });
    setState((_prev) => {
      return { ..._prev, selected: false,resetDataSource:modalFormStatusObj.text.length>0?true:false }
    });
    setUpdatedCond(null);
    setEditSubmitCheck(false);
    }
    const onAddcessClose = () => {
      setState( (_prev) => {
        return { ..._prev,editMenu:false,menu: false,resetDataSource:true}
      });
      if(state?.selected?.action==='addCess'){
        setModalTitle("Add cess")
      }
      if(state.selected.action==='editCess'){
        setModalTitle("Edit Cess")
      }
      setSelectSearchFieldMenu( _prev => {
        return [ ...SelectSearchFields ] ;
      });
      setAddCondition(false);
      setModalFormStatusObj({ text: ""});
      setAddCessConditionsFields(AddConditionsFields);
      setAddNewCessConditionsFields(AddConditionsFields);
      setEditConditionsVariable("");
      setUpdatedCond(null);
      setEditSubmitCheck(false);
      }

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const fetchCessMaster = async (page=1, perPage=10) => {
    filterData.page = page;
    filterData.limit = perPage;
    filterData.searchParam = searchKey;
    try {
        const res = await APIEndpoint.post('cess/process', JSON.stringify(filterData));
        setIsReloadTableData(true);
        return res;
    } catch (error) {

    }

  }
const fetchHSNCodesAndRegions = async () => {
try {
  const res = await APIEndpoint.get('regionsAndHSNCodes');
  const selSearchDataReg=[];
  const selSearchDataHsn=[];
  if(res.success){
  setHSNcodesArr(res.hsnCodes)
  setRegionsArr(res.regions)
  if (res.regions.length) {
    const options = [{key: 0, label: preCompile.regions, value: ''}];
    res.regions.filter( (fieldValue, index) => {
      options.push({key: index+1, label: fieldValue.name, value: fieldValue.name});
      selSearchDataReg.push({ key: index+1, label: fieldValue.name, value: fieldValue.name });
      return fieldValue;
    });
    setRegionsSelSearch(selSearchDataReg);
    setRegions(options);
  }

  if (res.hsnCodes.length) {
    const options = [{key: 0, label: preCompile.hsnCodes, value: ''}];
    res.hsnCodes.filter( (fieldValue, index) => {
      options.push({key: index+1, label: fieldValue, value: fieldValue});
      selSearchDataHsn.push({ key: index+1, label: fieldValue,  value: fieldValue });
      return fieldValue;
    });
    setHsnCodeSelSearch(selSearchDataHsn);
    setHSNcodes(options);
  }
  }
} catch (error) {

}
  }

  const fetchType =  () => {
    const typeArr=[{key: 1, label: "Percentage", value: 'percentage'}]
    const options = [{key: 0, label: preCompile.type, value: ''},...typeArr];
    setType( options );
    EditFormFields.map( row => {
      if (row.attribute === "type" ) {
        row.options = options;
      }
      return row;
    });

};

const fetchVariables =  () => {
  const variables=[{key: 1, label: "Region", value: 'region'},{key: 2, label: "Total Tax Percentage", value: 'totalTaxPercentage'},{key: 3, label: "HSN Code", value: 'hsn'},{key: 4, label: "GSTIN", value: 'gstNumber'},{key: 5, label: "Invoice Date", value: 'invoiceDate'}]
  const options = [{key: 0, label: preCompile.hsnCodes, value: ''},...variables];
  setVariables( options );
};
const getPredicate=(v,val)=>{

  const str=variables.find(fstr=>fstr.value===v.variable);
  const arrayOp=val.map(c=>c.label||c.name);
    return [str, arrayOp];
}
const getEffectiveDate=(dateChange)=>{
  if(dateChange!==undefined){
      let effTime = moment(dateChange).format('YYYY-MM-DD');
      let istTime= effTime + "T00:00:00+05:30";
      return [effTime,istTime]
  }
}

const editConditionsSubmit=async(values)=>{
 if(values){
  let [operator,opKey]=relationSelect(values?.operator!=='exists'?values?.operator:'');
  let valObj={},
   val=[],
   predStr,
  predArrayOp,
   predicate
   if(state.selected.action==='editCess'){
  if(values?.variable==='region'){
    const filteredRegions = regionsArr.filter(obj =>
      values.data.some(item => item.label === obj.name)
    );
    let[str, arrayOp]=getPredicate(values,values.data);
    predStr=str;
    predArrayOp=arrayOp;
    if(predStr&&predArrayOp){
      predicate=`${predStr['label']} ${operator} <${predArrayOp.join(', ')}>`
     let condObj={variable:values.variable,operator:opKey,value:filteredRegions,predicate};
      conditions.push(condObj);
    }
  }
  if(values?.variable==='totalTaxPercentage'){
    let[str, arrayOp]=getPredicate(values,[]);
    predStr=str;
    predicate=`${predStr['label']} ${operator} ${values.percentage}`
     let condObj={variable:values.variable,operator:opKey,value: parseInt(values.percentage),predicate};
      conditions.push(condObj);
  }
  if(values?.variable==='hsn'){
    const hsnArr = hsnCodesArr.filter((element) =>
  values.data.some((obj) => obj.value === element)
);

    let[str, arrayOp]=getPredicate(values,values.data);
    let predStr=str,
    predArrayOp=arrayOp
    if(predStr){
      predicate=`${predStr['label']} ${operator} <${predArrayOp.join(', ')}>`
     let condObj={variable:values.variable,operator:Boolean(opKey)?opKey:operator,value:hsnArr,predicate};
      conditions.push(condObj);
    }

  } if(values?.variable==='gstNumber'){
    let value = (values.gstNumber === 'true');
    let[str, arrayOp]=getPredicate(values,val);
    predStr=str;
    predArrayOp=arrayOp;
    if(predStr){
    predicate=`${predStr['label']}  ${value?"":"does not"} ${values.operator}`
     let condObj={variable:values.variable,operator:opKey,value,predicate};
      conditions.push(condObj);
    }

  }
  if(values?.variable==='invoiceDate'){
    let[ date,value] = getEffectiveDate(values.invoiceDate);
     date = date.split("-").reverse().join("-");
    let[str, arrayOp]=getPredicate(values,[]);
    predStr=str;
    predArrayOp=arrayOp;
    if(predStr){
      predicate=`${predStr['label']} ${operator} ${date}`
      let condObj={variable:values.variable,operator:opKey,value,predicate};
      conditions.push(condObj);
      }
  }
  onAddcessClose();

}
else{
  if(values?.variable==='region'){
    let[str, arrayOp]=getPredicate(values,values.data);
    predStr=str;
    predArrayOp=arrayOp;
    const filteredRegions = regionsArr.filter(obj =>
      values.data.some(item => item.label === obj.name)
    );
    if(predStr&&predArrayOp){
      predicate=`${predStr['label']} ${operator} <${predArrayOp.join(', ')}>`
     let condObj={variable:values.variable,operator:opKey,value:filteredRegions,predicate};
      conditionsNewAdd.push(condObj);
    }
  }
  if(values?.variable==='totalTaxPercentage'){
    let[str, arrayOp]=getPredicate(values,[]);
    predStr=str;
    predicate=`${predStr['label']} ${operator} ${values.percentage}`
     let condObj={variable:values.variable,operator:opKey,value: parseInt(values.percentage),predicate};
      conditionsNewAdd.push(condObj);
  }
  if(values?.variable==='hsn'){
    let[str, arrayOp]=getPredicate(values,values.data);
    const hsnArr = hsnCodesArr.filter((element) =>
    values.data.some((obj) => obj.value === element)
  );
    let
    predStr=str,
    predArrayOp=arrayOp
    if(predStr){
      predicate=`${predStr['label']} ${operator} <${predArrayOp.join(', ')}>`
     let condObj={variable:values.variable,operator:Boolean(opKey)?opKey:operator,value:hsnArr,predicate};
      conditionsNewAdd.push(condObj);
    }

  } if(values?.variable==='gstNumber'){
    let value = (values.gstNumber === 'true');
    let[str, arrayOp]=getPredicate(values,val);
    predStr=str;
    predArrayOp=arrayOp;
    if(predStr){
    predicate=`${predStr['label']}  ${value?"":"does not"} ${values.operator}`
     let condObj={variable:values.variable,operator:opKey,value,predicate};
      conditionsNewAdd.push(condObj);
    }

  }
  if(values?.variable==='invoiceDate'){
   let[date,value]= getEffectiveDate(values.invoiceDate)
    date = date.split("-").reverse().join("-");
    let[str, arrayOp]=getPredicate(values,[]);
    predStr=str;
    predArrayOp=arrayOp;
    if(predStr){
      predicate=`${predStr['label']} ${operator} ${date}`
      let condObj={variable:values.variable,operator:opKey,value,predicate};
      conditionsNewAdd.push(condObj);
      }
  }
   onAddcessClose();
}

 }





}

const updateEditFormValues = async (e, updatedFormObj) => {
  ReactDOM.flushSync( () => {
      if (e && updatedFormObj&& updatedFormObj.variable==='region' ) {

          const updatedEditFields = selectSearchFieldMenu.filter( (field) => {
            if (field.attribute==='regions' ) {
                field.value = e;
                field.options=regionsSelSearch;
            }
            return field;
        });

    setSelectSearchFieldMenu( _prev => {
      return [ ...updatedEditFields ] ;
    });
      }

      if (e &&updatedFormObj && updatedFormObj.variable==='hsn' ) {
        const updatedEditFields = selectSearchFieldMenu.filter( (field) => {
          if (field.attribute==='hsn' ) {
              field.value = e;
              field.options=hsnCodeSelSearch;
          }
          return field;
      });

  setSelectSearchFieldMenu( _prev => {
    return [ ...updatedEditFields ] ;
  });
    }
  })
};
const onEditConditionsSubmit=(e,updatedCond)=>{
  if(Boolean(state?.editMenu)&&Boolean(updatedCond)){
    EditConditionFieldsModify(state.editMenu,updatedCond);
  }
    if(Boolean(state.menu)&&!Boolean(state?.editMenu)&&state!==undefined){
      editConditionsSubmit(updatedCond);
    }
}

const onEditSubmit = async (updatedCond) => {
const {selected}=state;

  if(selected.action!=='addCess' && Object.keys(rowData).length>0 && updatedCond===null)
  {
     const editCessObj={
      "type": rowData.type,
      "shortName": rowData.shortName,
      "name": rowData.name,
      "description": rowData.description,
      "amount":rowData.amount,
      conditions,
  }

      try {
        const res = await APIEndpoint.post(`cess/${rowData.id}`,editCessObj);
      if(res.success){
       setModalFormStatusObj({ text: res.message });
      handleReset();
      setTimeout(()=>{
        onClose()
      },1500)
        }
      } catch (error) {
  let errRes= getErrorResponseText(error);
  setModalFormStatusObj({ text: errRes });
      }
      // onAddcessClose();
  }

  if(selected.action!=='addCess'&&Boolean(updatedCond)&&Object.keys(rowData).length>0)
  {
     const editCessObj={
      "type": updatedCond.type!==undefined?updatedCond.type:rowData.type,
      "shortName": updatedCond.shortName!==undefined?updatedCond.shortName:rowData.shortName,
      "name": updatedCond.name!==undefined?updatedCond.name:rowData.name,
      "description": updatedCond.description!==undefined?updatedCond.description:rowData.description,
      "amount": updatedCond.amount!==undefined?parseInt(updatedCond.amount):rowData.amount,
      conditions,
  }
      try {
        const res = await APIEndpoint.post(`cess/${rowData.id}`,editCessObj);
        if(res.success){
      setModalFormStatusObj({ text: res.message });
      handleReset();
      setTimeout(()=>{
        onClose()
      },1500)
        }
      } catch (error) {
        let errRes= getErrorResponseText(error);
        setModalFormStatusObj({ text: errRes });
      }
      // onAddcessClose();
  }
  if(selected.action==='addCess'&& updatedCond!==null)
  {
     const editCessObj={
      "type": updatedCond.type!==undefined? updatedCond.type:"percentage",
      "shortName": updatedCond.shortName!==undefined?updatedCond.shortName:"",
      "name": updatedCond.name!==undefined?updatedCond.name:"",
      "description": updatedCond.description!==undefined?updatedCond.description:"",
      "amount": updatedCond.amount!==undefined?parseInt(updatedCond.amount):"",
      "conditions":conditionsNewAdd,
  }
      try {
        const res = await APIEndpoint.post(`cess`,editCessObj);
        if(res.success){
      setModalFormStatusObj({ text: res.message });
      handleReset();
      setTimeout(()=>{
        onClose()
      },1500)
        }
      } catch (error) {
        let errRes= getErrorResponseText(error);
        setModalFormStatusObj({ text: errRes });
      }
      // onAddcessClose();
  }

    };

const getErrorResponseText=(err)=>{
  const errObj=JSON.parse(err.message)?.data
  if(errObj.hasOwnProperty('conflicts')){
    return errObj.conflicts[0].message
    }
    else{
      return errObj.message
    }
}
  const onEnableDisableAction = async () => {
    setModalFormStatusObj({ text: '' });
    let body={};
    if(editCessDetails.active){
    body={active:false};
    }
    else{
      body={active:true};
    }
        try {
          const res = await APIEndpoint.post(`cess/${editCessDetails.id}/state`,body);
          if(res.success){
          setModalFormStatusObj({ text: res.message });
          handleReset();
          }
        } catch (error) {
        setModalFormStatusObj({ text: error.data.message });
        }
      }

  const onRemoveCondition=(id)=>{
    if(state.selected.action==='editCess'){
      if(conditions.length){
        const newCondArray= conditions.filter((c,index)=>index!==id)
        setConditions(newCondArray);
      }
    }else{
      if(conditionsNewAdd.length){
        const newCondArray= conditionsNewAdd.filter((c,index)=>index!==id)
        setConditionsNewAdd(newCondArray);
      }
    }
  }

  const editConditions=(list)=>{
    setEditObject(list);
    setAddCondition(true);
    let conditionsObj={};
    conditionsObj.value=list.value;
    conditionsObj.variable=list.variable;
    let [editVar,editValue,editObject]=variableLabel(list.variable,list.value,conditionsObj);
    let op=operatorSelect(list.operator);
    conditionsObj.operator=op;
    setModalTitle("Edit Condition");
    setState( (_prev) => {
      return { ..._prev,editMenu:conditionsObj,menu: {modal: 'editCondition' }}
    });
    }

const variableLabel=(variable,value,obj)=>{
let editVar,
 editValue,
 editObject;
if(state.selected.action==='editCess'){
  switch (variable) {
    case 'region':
      setAddCessConditionsFields((prev) => [{label: 'Variable', attribute: 'variable', type: 'select', disabled: false, options: variables, required: true },
      {label: 'Operator', attribute: 'operator', type: 'select', disabled: false, options: hsnRegionOperator, required: true },{label: 'Regions', attribute: 'regions', type: 'rcSelectAll', disabled: false, options: regionsSelSearch, required: true }]);
      editValue=obj.value;
      obj['regions'] = obj['value'];
      delete obj['value'];
      editObject=obj;
      editObject['regions']=editValue;
      break;
      case 'totalTaxPercentage':
      editVar="Total Tax Percentage"
      editValue=value.toString();
      obj['percentage'] = obj['value'];
      delete obj['value'];
      editObject=obj;
      setAddCessConditionsFields((prev) => [{label: 'Variable', attribute: 'variable', type: 'select', disabled: false, options: variables, required: true },
      {label: 'Operator', attribute: 'operator', type: 'select', disabled: false, options: regionBoundaries, required: true }, {label: 'Percentage', attribute: 'percentage', type: 'number', disabled: false,required: true }])
      break;
      case 'hsn':
      editVar="HSN Code"
      setAddCessConditionsFields((prev) => [{label: 'Variable', attribute: 'variable', type: 'select', disabled: false, options: variables, required: true },
      {label: 'Operator', attribute: 'operator', type: 'select', disabled: false, options: hsnRegionOperator, required: true }, {label: 'HSN code', attribute: 'hsn', type: 'rcSelectAll',options:hsnCodeSelSearch, disabled: false,required: true }])
      editValue=obj.value;
      obj['hsn'] = obj['value'];
      delete obj['value'];
      editObject=obj;
      editObject['hsn']=editValue;
      break;
      case 'gstNumber':
      editVar="GSTIN"
      editValue=obj.value.toString();
      obj['gstNumber'] = obj['value'];
      delete obj['value'];
      editObject=obj;
      editObject['gstNumber']=editValue;
      setAddCessConditionsFields((prev) => [{label: 'Variable', attribute: 'variable', type: 'select', disabled: false, options: variables, required: true },
      {label: 'Operator', attribute: 'operator', type: 'select', disabled: false, options: gstIn, required: true }, {label: 'GSTIN', attribute: 'gstNumber', type: 'select',options:gstValue, disabled: false,required: true }])
      break;
      case 'invoiceDate':
      editVar="Invoice Date"
      obj['invoiceDate'] = obj['value'];
      obj['invoiceDate']=new Date(obj.value);
      delete obj['value'];
      editObject=obj;
      setAddCessConditionsFields((prev) => [{label: 'Variable', attribute: 'variable', type: 'select', disabled: false, options: variables, required: true },
      {label: 'Operator', attribute: 'operator', type: 'select', disabled: false, options: regionBoundaries, required: true },{label: 'Invoice Date', attribute: 'invoiceDate', type: 'date',disabled: false,required: true }])
      break;
    default:
      break;
  }
}
else{
  switch (variable) {
    case 'region':
      setAddNewCessConditionsFields((prev) => [{label: 'Variable', attribute: 'variable', type: 'select', disabled: false, options: variables, required: true },
      {label: 'Operator', attribute: 'operator', type: 'select', disabled: false, options: hsnRegionOperator, required: true },{label: 'Regions', attribute: 'regions', type: 'rcSelectAll', disabled: false, options: regionsSelSearch, required: true }]);
      editValue=obj.value;
      obj['regions'] = obj['value'];
      delete obj['value'];
      editObject=obj;
      editObject['regions']=editValue;
      break;
      case 'totalTaxPercentage':
      editVar="Total Tax Percentage"
      editValue=value.toString();
      obj['percentage'] = obj['value'];
      delete obj['value'];
      editObject=obj;
      setAddNewCessConditionsFields((prev) => [{label: 'Variable', attribute: 'variable', type: 'select', disabled: false, options: variables, required: true },
      {label: 'Operator', attribute: 'operator', type: 'select', disabled: false, options: regionBoundaries, required: true }, {label: 'Percentage', attribute: 'percentage', type: 'number', disabled: false,required: true }])
      break;
      case 'hsn':
      editVar="HSN Code"
      setAddNewCessConditionsFields((prev) => [{label: 'Variable', attribute: 'variable', type: 'select', disabled: false, options: variables, required: true },
      {label: 'Operator', attribute: 'operator', type: 'select', disabled: false, options: hsnRegionOperator, required: true }, {label: 'HSN code', attribute: 'hsn', type: 'rcSelectAll',options:hsnCodeSelSearch, disabled: false,required: true }])
      editValue=obj.value;
      obj['hsn'] = obj['value'];
      delete obj['value'];
      editObject=obj;
      editObject['hsn']=editValue;
      break;
      case 'gstNumber':
      editVar="GSTIN"
      editValue=obj.value.toString();
      obj['gstNumber'] = obj['value'];
      delete obj['value'];
      editObject=obj;
      editObject['gstNumber']=editValue;
      setAddNewCessConditionsFields((prev) => [{label: 'Variable', attribute: 'variable', type: 'select', disabled: false, options: variables, required: true },
      {label: 'Operator', attribute: 'operator', type: 'select', disabled: false, options: gstIn, required: true }, {label: 'GSTIN', attribute: 'gstNumber', type: 'select',options:gstValue, disabled: false,required: true }])
      break;
      case 'invoiceDate':
      editVar="Invoice Date"
      obj['invoiceDate'] = obj['value'];
      obj['invoiceDate']=new Date(obj.value)
      delete obj['value'];
      editObject=obj;
      setAddNewCessConditionsFields((prev) => [{label: 'Variable', attribute: 'variable', type: 'select', disabled: false, options: variables, required: true },
      {label: 'Operator', attribute: 'operator', type: 'select', disabled: false, options: regionBoundaries, required: true },{label: 'Invoice Date', attribute: 'invoiceDate', type: 'date',disabled: false,required: true }])
      break;
    default:
      break;
  }
}

return [editVar,editValue,editObject];

  }

  const operatorSelect=(val)=>{
    let operator;
    switch(val) {
        case 'in':
        operator='one of'
        break;
        case 'not in':
        operator='not one of'
        break;
        case 'nin':
        operator='not one of'
        break;
        case 'lt':
          operator='less than'
        break;
        case 'eq':
          operator='equal to'
        break;
        case 'gt':
        operator='greater than'
        break;
        case 'lte':
        operator='less than or equal to'
        break;
        case 'gte':
        operator='greater than or equal to'
        break;
        case 'neq':
        operator='not equal to'
        break;
      default:
        operator='exists'
    }
    return operator;
  }

  const onAddItemHandler = () => {
    setModalTitle("Add Cess")
    setState( (_prev) => {
      return { ..._prev, selected: { action: 'addCess' } };
    });

   const newEditField=   EditFormFields.map( row => {
      if (row.attribute === "type" ) {
        row.options = type;
      }
      return row;
    });
    setAddFields(newEditField)
  }

  const onPerLimitChange = (e) => {
    setPerPageLimit(Number(e.target.value));
  };

  const onSearch = (e) => {
      setSearchKey(e.target.value);
  };
  return (
      <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
        <div className='flexRow width100 margBot10 justifyContentSpaceBetween'>
          <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>

              <div className="margLeft40 margBot10 underline">
                  <span><img  className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
                  <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={searchKey}/></span>
              </div>
            { createCessMaster? <div className="margLeft flexRow justifyContentFlexEnd alignItemsBaseline">
                  <button className="add-item-button" onClick={ onAddItemHandler }>
                  {t('buttons.addCess')}
                  </button>
              </div>:<></>}
          </div>
          </div>

          <DataGrid
            ref={tableRef}
            className="full-flex"
            columns={[...Columns]}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            searchKey={searchKey}
            selItemType={selItemType}
            getRows={fetchCessMaster}
            getActionCellIcons={getActionCellIcons}
            resetDataSource={state.resetDataSource}
            rowSelection="multiple"
            actions={actionItems}
            onAction={onAction}
            onAddItemHandler={() => onAddItemHandler()}
            columnResize={true}
          />
        </>
      }
      {
        (state?.selected?.action==='addCess' || state?.selected?.action==='editCess') && Boolean(state?.selected) ? (
          <CessModifModal
            key={state.selected.action}
            title={modalTitle}
            onClose={onClose}
            onSubmit={onEditSubmit}
            cessDetails={state?.selected?.action==="editCess"?conditions:conditionsNewAdd}
            modalCustomButtons={modalCustomButtons}
            modalFormStatusObj={modalFormStatusObj}
            onChange = {onFieldChangeHandler}
            onRemoveCondition={onRemoveCondition}
            onAddConditions={onAddConditions}
            editConditions={editConditions}
            fields={state?.selected?.action==='addCess'?addFields.map( (elt) => {
                  return {...elt}
              }):EditFieldsCess.map( (elt) => {
                return { ...elt, initialValue: state.selected.selectedRowData[elt.attribute] }
                })
            }
          />
        )
        : null
      }

{
        (state?.menu?.modal==='addCondition'|| state?.menu?.modal==='editCondition')&& addCondition &&  Boolean(state?.selected) ? (
          <AddConditionModal
            key={state.selected.action}
            title={modalTitle}
            onClose={onAddcessClose}
            onSubmit={onEditConditionsSubmit}
            setUpdated={setUpdatedCond}
            updated={updatedCond}
            onChange={ (e, updated) => {
              updateEditFormValues(e, updated);
          }}
            modalFormStatusObj={modalFormStatusObj}
            onRemoveCondition={onRemoveCondition}
            setEditSubmitCheck={setEditSubmitCheck}
            setEditConditionsVariable={setEditConditionsVariable}
            setConditionsSelections={setConditionsSelections}
            editConditions={editConditions}
            showFieldsInColumn={true}
            fields={state?.selected?.action==='addCess'?addNewCessConditionsFields.map( (elt) => {
             if(Boolean(state.editMenu)){
               return {...elt,initialValue:state.editMenu[elt.attribute]}
               }
               else{
                return {...elt}
               }
              }):addCessConditionsFields.map( (elt) => {
                    if(Boolean(state.editMenu)){
                      return {...elt,initialValue:state.editMenu[elt.attribute]}
                    }
                    else{
                      return {...elt}
                    }
              })
            }
          />
        )
        : null
      }

      {
        (state?.selected?.action==='Disable' || state?.selected?.action==='Enable')&& Boolean(state?.selected) ? (
          <EditModal
            key={state.selected.action}
            title={ modalTitle }
            onClose={onClose}
            modalFormStatusObj={modalFormStatusObj}
            onSubmit={onEnableDisableAction}
            modalCustomButtons={modalCustomButtons}
            onChange = {onFieldChangeHandler}
            fields={EditFieldsCess.map( (elt) => {
              return { ...elt, initialValue: state.selected.selectedRowData[elt.attribute] }
              })
            }
          />
        )
        : null
      }
      {
       state?.selected?.action==="viewCess" && Boolean(state?.selected) ? (
          <CessViewModal cessDetails={state?.selected?.selectedRowData} conditions={conditions} onClose={onClose} />
        )
        : null
      }
      {
      t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
      }
    </div>
  )
}
