import { loginDateAndTime } from '../utils/commonFunctions';

const LOGIN_TIME = 'login_time';
const LOGIN_DATE = 'login_date';
const USER_ID = 'user_id';
const USERNAME_NGO = "username";
const BEARER_TOKEN = 'bearer_token';
const THEME = 'theme';
const TOKEN = 'token';
const STYPE = 'sType'; // Client type: akasa,sandbox

export default class UserSession {
    setSession(user) {
        const res = loginDateAndTime();
        localStorage.setItem(LOGIN_TIME, res.current.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }))
        localStorage.setItem(LOGIN_DATE, res.date)
        localStorage.setItem(USER_ID, user.user_id);
        localStorage.setItem(USERNAME_NGO, user.username);
        localStorage.setItem(BEARER_TOKEN, user.token);
        if (user.theme) {
            localStorage.setItem(THEME, JSON.stringify(user.theme));
        }
    }
    destroySession() {
        localStorage.removeItem(LOGIN_TIME);
        localStorage.removeItem(LOGIN_DATE);
        localStorage.removeItem(USER_ID);
        localStorage.removeItem(USERNAME_NGO);
        localStorage.removeItem(BEARER_TOKEN);
        localStorage.removeItem(TOKEN); // note this only removing here
        localStorage.removeItem(THEME);
        localStorage.removeItem(STYPE);
    }
    getSessionValues() {
        return {
            [LOGIN_TIME]: localStorage.getItem(LOGIN_TIME),
            [LOGIN_DATE]: localStorage.getItem(LOGIN_DATE),
            [USER_ID]: localStorage.getItem(USER_ID),
            [USERNAME_NGO]: localStorage.getItem(USERNAME_NGO),
            [BEARER_TOKEN]: localStorage.getItem(BEARER_TOKEN),
            [TOKEN]: localStorage.getItem(TOKEN),
            [THEME]: localStorage.getItem(THEME),
            [STYPE]: localStorage.getItem(STYPE),
        }
    }
}
