import {LOI_STATUS_LIST } from "../../../../utils/configs";
import { FaCheck } from "react-icons/fa";
import { RiDraftLine } from "react-icons/ri";
import { FaLock } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
const getOrderStatusColorCode = (params) => {
  if (params) {
    const orderStatus = params.data.status;
    const ordSts = LOI_STATUS_LIST.find( _ => _.key === orderStatus );
    if (ordSts) {
      return (
        <>
          { ordSts.value === 'Approved' && <FaCheck color={ ordSts.colorCode_text } size={15}/> }
          { ordSts.value === 'Suspended' && <FaPause color={ ordSts.colorCode_text } size={15}/> }
          { ordSts.value === 'Draft' && <RiDraftLine color={ ordSts.colorCode_text } size={15}/> }
          { ordSts.value === 'Finalized' && <FaLock color={ ordSts.colorCode_text } size={15}/> }
          &nbsp;&nbsp;<span>{ordSts.value}</span>
        </>
      );
    }
    else {
      return <>{ordSts}</>
    }
  }
  return null;
}

export const LetterOfIndendRenderSel = (props) => {
    let dd = props.data ? getOrderStatusColorCode(props) : null;
    return (
        <div className="orderStatus-renderer">
            {dd}
        </div>
    );
};