import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SalesOverviewNewPage } from "./inner-components";

export const DashboardPage = (props) => {
  return (
    <div className="flexRow full p-0 m-0">
      <div className='full-flex flexCol width100 p-0 m-0'>
        <Routes>
          {/* <Route path="/" element={<Navigate replace to="sales-overview-new" />} />
          <Route path="/sales-overview-new" element={<SalesOverviewNewPage />} />
          {false&&<Route path="/devices-overview" element={null} />}
        {  false&&<Route path="/perfomance" element={null} />}
         { false&&<Route path="/sales-datewise" element={null} />}
          {false&&<Route path="/top-items-more" element={null} />} */}

          <Route path="/" element={<SalesOverviewNewPage />} />
        </Routes>
      </div>
    </div>
  )
}