/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { DFPAPIEndpointGalley } from '../../../utils/axios'
import '../../../assets/styles/styles.scss'
import './menu.scss'
import '../../../assets/styles/inner/table.scss'
import { EditModal } from '../../../common';
import Select from 'react-select'
import editIcon2 from "../../../assets/edit2.svg";
import {sectorTypesList} from '../../../utils/configs'
import {translationLength } from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next'
import usePermissions from '../../../utils/userPermissions'

export const MealPolicy = props => {
  const { t } = useTranslation()
  // const navigate = useNavigate()

  const [state, setState] = useState({sectors:[],aircrafts:[],sectorType:[{key:1,label:'Domestic',value:"domestic"}],sectorTypes:sectorTypesList,isOpenMenu:false,mealMenu:[], sectSel:[{key: 0, label:`Select sector`,label2:'Select sector', value: ''}],resetDataSource: false })
  const [sectorType,setSectorType]=useState([])
  const { hasPermission: editMealPolicy } = usePermissions('edit', "configuration.fsc.meal-policy");

const [createJSON, setCreateJSON] = useState({})
const sType = localStorage.getItem('sType');
// const DOMAIN = (sType === ("dfp"||"regular") ? DFPAPIEndpoint : APIEndpoint);
  useEffect(() => {
    fetchMealPolicy();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

const onClose=()=>{
  setState((prev)=>({...prev,selected:false}))
}

  let handleSectorType = data => {
    setSectorType(data)
    let createj = createJSON
    createj.sectorType = { id: data.value, key: data.label }
    setCreateJSON(createj)
    setState((_)=>{return {..._,sectorType:[data]}})

  }

  const getMealDomesticPolicy=(data,col)=>{
   if(col==='svc'){
    const {band}=data;
return band.label.split('/')[1];
   }
   else if(col==='cat'){
    const {key}=data;
    return key.split('/')[2];
   }
   else if(col==='servPcy'){
    const {key}=data;
    return key.split('/')[1]+key.split('/')[2];
   }
   else{
return "";
   }

  }

  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('user_id', localStorage.getItem('user_id'))
  myHeaders.append('x-csrf-token', localStorage.getItem('token'))
  myHeaders.append('X-Bearer-Token', localStorage.getItem('bearer_token'))

const getmappings=(domItems)=>{
const res=domItems.map((mItem,index)=>
  ( <td style={{ whiteSpace: 'nowrap' }}>{mItem?.meals[0]?.name ? `${mItem?.meals[0]?.id}-${mItem?.meals[0]?.name}` : '-'}</td>))
  return res;
}



const EditFormFieldsInt = [
  {label: 'First Class', attribute: 'firstClass', type: 'text', disabled: false, required: true },
  {label: 'Business Class', attribute: 'businessClass', type: 'text', disabled: false,required: true },
  {label: 'Premium Economy', attribute: 'premEconomy', type: 'text', disabled: false, required: true},
  {label: 'Cockpit Crew', attribute: 'ckptCrew', type: 'text', disabled: false, required: true},
  {label: 'Cabin Crew', attribute: 'cbnCrew', type: 'text', disabled: false, required: true },
  {label: 'VIP', attribute: 'vip', type: 'text', disabled: true, required: true },
];
const EditFormFieldsDom = [
  {label: 'Business Class', attribute: 'businessClass', type: 'select',  options: [],disabled: false, required: true },
  {label: 'Economy', attribute: 'economy', type: 'select', disabled: false, options: [], required: true},
  {label: 'Cockpit Crew', attribute: 'ckptCrew', type: 'select', disabled: false, options: [], required: true},
  {label: 'Cabin Crew', attribute: 'cbnCrew', type: 'select', disabled: false,  options: [], required: true },
];


const [editFieldsInt,setEditFieldsInt]=useState(EditFormFieldsInt);
const [editFieldsDom,setEditFieldsDom]=useState(EditFormFieldsDom);


const updateMealPolicy=(data,ind)=>{
setState((prev)=>({...prev,selected:{action:'editMealPolicy',title:'Edit Meal Policy',type:sectorType,data:data}}));
  const {sectorType}=data
const options = [];
if(state){

  if(state.MealMenuCodes){
  state.MealMenuCodes?.map( (fieldValue, index) => {
    options.push({key: index+1, label: fieldValue.label, value: fieldValue.id});
    return fieldValue;
  })
}
if(sectorType==="dom"){
  const {mappings}=data;
  setEditFieldsDom((_) =>
  _.map((edt) => {
    const matchingMapping = mappings.find((mp) => mp.cabinClass.name === edt.label)
    if (matchingMapping) {
      return { ...edt, value: matchingMapping.meals[0].id,options:options };
    }
    return edt;
  })

);

}
else{
  const {mapping}=data;
  let mealFormat=[];
  for(let i=0;i<=4;i++){
    mealFormat.push(getInternationaMealSplit(data,i===0?'0.5':i===1?"1":i===2?"1.5":i===3?"2":"2.5"))
  }

  setEditFieldsInt((_) =>
      _.map((edt,index) => {
        const matchingMapping = mapping.find((mp) => mp.cabinClass.name === edt.label);
        if (matchingMapping) {
          return { ...edt, value: mealFormat[index] };
        }
        return edt;
      })
    );

}
}



}

const onFieldChangeHandler = (e) => {
  const {name,value}=e.target;

};
const onCloseEditMeals=()=>{
  setState((_)=>({..._,selected:false}))
}
const getInternationaMealSplit=(data,level)=>{
const {mealCode}=data;
const searchKey=`int/${level}/${mealCode}`;
const mapping=state?.mealPolicyInternational.find(ml=>ml.key===searchKey)?.mappings

data.mapping=mapping;
if(data.mapping){
  const res=data.mapping?.map((mItem,index)=>mItem.meals.map(ml=>ml.id))
  return res[0].join("/");
}

}

const getMealSets=(from,to)=>{
  let res=""
 switch (from+"-"+to) {
  case '00:00-03:59':
    res= 'REF'
    break;
    case '04:00-10:59':
      res= 'HBR'
      break;

      case '11:00-14:29':
        res= 'LCH'
        break;

        case '14:30-17:29':
          res= 'REF'
          break;
          case '17:30-23:59':
            res= 'DNR'
            break;

  default:
    break;
 }
 return res;
}
  const fetchMealPolicy = async () => {

    try {

      const [resp,respServCat,respServBand,resposeMealmenu] = await Promise.all([
        DFPAPIEndpointGalley.get('galley/ai/meal-policy/configs'),
        DFPAPIEndpointGalley.get('galley/ai/meal-service-categories'),
        DFPAPIEndpointGalley.get('galley/ai/meal-service-bands'),
        DFPAPIEndpointGalley.get('galley/mealMenuPlan/configs')

      ]);


      if(resp.status&&respServBand.status&&respServCat.status&&resposeMealmenu.status) {
const {mealPolicy}=resp.data;

        setState(prevState => ({
          ...prevState,
          mealPolicyDomestic:mealPolicy.filter(ml=>ml.sectorType==='dom'),
          serviceCatDomestic:respServCat.data.filter(rsp=>rsp.sectorType==='dom'),
          serviceCatInternational:respServCat.data.filter(rsp=>rsp.sectorType==='int'),
          serviceBandInternational:respServBand.data.filter(rsp=>rsp.sectorType==='int'),
          serviceBandDomestic:respServBand.data.filter(rsp=>rsp.sectorType==='dom'),
          mealPolicyInternational:mealPolicy.filter(ml=>ml.sectorType==='int'),
          MealMenuCodes:resposeMealmenu?.data?.mealTypeGroups?.list
        }));
      }
    }

    catch (err) {
      throw err;
    }
  }


  return (
    <div className='op-aircraft-container flexCol full-flex ag-theme-alpine-dark'>
     {
        <>

<div className="flexRow row justifyContentSpaceBetween">


  <>
    <div className="flexRow justifyContentSpaceBetween margBottom18">

{ state && state.sectorTypes? (<div className='felxCol'>
    <label className="req margBottom10">Sector Type</label>

<Select
              options={state.sectorTypes}
              defaultValue={state.sectorType?.label?state.sectorType?.label:''}
              value={state.sectorTypes.filter(function(option) {
                return state.sectorType&&state.sectorType?.find(sc=>sc?.label===option?.label)
              })}
              placeholder='Select Sector Type'
              onChange={handleSectorType}
              isSearchable={true}
              isMulti={false}
              className='selectMenu-meals'
            />

  </div>):<></>}




    </div>
  </>

  <div className="container-fluid dashboard-summary-container" style={{ overflow: "scroll",maxHeight:'85vh' }}>
  {state.sectorType.length && state.sectorType[0].value === 'domestic' ? (
    <div className="flexCol justifyContentCenter alignItemsCenter"  style={{ overflow: "scroll",textAlign: 'center' }}>
      <div>

  <div className='flexRow justifyContentSpaceBetween margBottom18' style={{width:'100%'}}>
    <div className="margRight10">
    <div className='table-header'></div>
<table>
          <thead >
            <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
            <th colSpan="8"  style={{ textAlign: 'center', verticalAlign: 'middle' }}>Domestic Service Band</th>
            </tr>
            <tr  style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
              <th>From</th>
              <th>SVC</th>

            </tr>
          </thead>
          <tbody>
            {state && state.serviceBandDomestic && state.serviceBandDomestic.map((_, index) => (

              <tr key={_.id}>
                <td style={{whiteSpace:'nowrap'}}>{_.from} to {_.to}&nbsp;mins</td>
                <td style={{whiteSpace:'nowrap'}}>{_.mealCode}</td>
              </tr>
            ))}
          </tbody>
        </table>
    </div>
  <div className=''>
  <div className='table-header'></div>

    <table>

          <thead>
            <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
            <th colSpan="8"  style={{ textAlign: 'center', verticalAlign: 'middle' }}>Domestic Meal Service Policy Based on time of departure (24hrs)</th>
            </tr>
            <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
              <th>From</th>
              <th>To</th>
              <th>Category</th>
              <th>BC</th>
              <th>EY</th>
              <th>CO</th>
              <th>CR</th>
            </tr>
          </thead>
          <tbody>
            {state && state.serviceCatDomestic && state.serviceCatDomestic.map((_, index) => (

              <tr key={_.id}>
                <td>{_.from.formattedValue}</td>
                <td>{_.to.formattedValue}</td>
                <td >{_.mealCode}</td>
                <td >{getMealSets(_.from.formattedValue,_.to.formattedValue)}</td>
                <td >{getMealSets(_.from.formattedValue,_.to.formattedValue)}</td>
                <td >{getMealSets(_.from.formattedValue,_.to.formattedValue)}</td>
                <td >{getMealSets(_.from.formattedValue,_.to.formattedValue)}</td>



              </tr>
            ))}
          </tbody>
        </table>
    </div>


  </div>
  <div className='table-header'></div>

        <table>
          <thead>
            <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
            <th colSpan="8"  style={{ textAlign: 'center', verticalAlign: 'middle' }}>Domestic Service Category</th>
            </tr>
            <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
              <th>SVC</th>
              <th>Category</th>
              <th style={{whiteSpace:'nowrap'}}>Domestic Service Category</th>
              <th>BC</th>
              <th>EY</th>
              <th>CO</th>
              <th>CR</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {state && state.mealPolicyDomestic && state.mealPolicyDomestic.map((_, index) => (
              <tr key={_.id}>
                <td>{getMealDomesticPolicy(_,'svc')}</td>
                <td style={{whiteSpace:'nowrap'}}>{getMealDomesticPolicy(_,'cat')}</td>
                <td>{getMealDomesticPolicy(_,'servPcy')}</td>
               {getmappings(_.mappings)}


           {editMealPolicy?<td><img className="icon-size" alt={"edit"} onClick={()=>updateMealPolicy(_,index)} src={editIcon2} /></td>:<td></td>}
              </tr>
            ))}
          </tbody>
        </table>

      </div>



    </div>
  ) :null}

  {state.sectorType.length && state.sectorType[0].value === 'international' ? (
    <div className="flexCol" style={{ overflow: "scroll",textAlign: 'center' }}>
      {/* <div className='flexRow justifyContentSpaceBetween'>
      <div className='margBottom18 margRight10'  style={{width:'50%'}}>
  <div className='table-header'></div>

<table  style={{height:'400px'}}>
          <thead>
            <tr className='sticky'>
            <th colSpan="8"  style={{ textAlign: 'center', verticalAlign: 'middle' }}>International Service Band</th>
            </tr>
            <tr className='sticky'>
              <th>From</th>
              <th>SVC</th>
              <th>Meals</th>
            </tr>
          </thead>
          <tbody>
            {state && state.serviceBandInternational && state.serviceBandInternational.map((_, index) => (

              <tr key={_.id}>
                <td>{_.from} to {_.to}&nbsp;mins</td>
                <td >{_.serviceCode}</td>
                <td>{_.mealCode}</td>
              </tr>
            ))}
          </tbody>
        </table>

  </div>


 <div className='margBottom18' style={{width:'50%'}}>
 <div className='table-header'></div>

<table  style={{height:'400px'}}>
          <thead>
            <tr className='sticky'>
            <th colSpan="9"  style={{ textAlign: 'center', verticalAlign: 'middle' }}>International Meal Uplift</th>
            </tr>
            <tr className='sticky'>
              <th>From</th>
              <th>To</th>
              <th>Category</th>
              <th>0.5</th>
              <th>1</th>
              <th>1.5</th>
              <th>2</th>
              <th>2.5</th>
              <th>Edit</th>

            </tr>
          </thead>
          <tbody>
            {state && state.serviceCatInternational && state.serviceCatInternational.map((_, index) => (

              <tr key={_.id}>
                <td>{_.from.formattedValue}</td>
                <td>{_.to.formattedValue}</td>
                <td>{_.mealCode}</td>
              <td>{getInternationaMealSplit(_,'0.5')}</td>
              <td>{getInternationaMealSplit(_,'1')}</td>
              <td>{getInternationaMealSplit(_,'1.5')}</td>
              <td>{getInternationaMealSplit(_,'2')}</td>
              <td>{getInternationaMealSplit(_,'2.5')}</td>
              <td><img className="icon-size" alt={"edit"}  src={editIcon2} /></td>
              </tr>
            ))}
          </tbody>
        </table>

  </div>
      </div> */}


      <div style={{padding:'5px',fontWeight:'bold',borderRadius:'5px 5px 0px 0px'}} className='spl-row-class flexRow justifyContentCenter margBottom10 fontSemibold'>First Class</div>

<div className='flexRow justifyContentSpaceBetween'>
<div className='margBottom18 margRight10'  style={{width:'50%'}}>
<div className='table-header'></div>

<table  style={{height:'400px'}}>
    <thead>
      <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
        <th>From</th>
        <th>SVC</th>
        <th>Meals</th>
      </tr>
    </thead>
    <tbody>
      {state && state.serviceBandInternational && state.serviceBandInternational.map((_, index) => (

        <tr key={_.id}>
          <td>{_.from} to {_.to}&nbsp;mins</td>
          <td >{_.serviceCode}</td>
          <td>{_.mealCode}</td>
        </tr>
      ))}
    </tbody>
  </table>

</div>


<div className='margBottom18' style={{width:'50%'}}>
<div className='table-header'></div>

<table  style={{height:'400px'}}>
    <thead>

      <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
        <th>From</th>
        <th>To</th>
        <th>0.5</th>
        <th>1</th>
        <th>1.5</th>
        <th>2</th>
        <th>2.5</th>
        <th>Edit</th>

      </tr>
    </thead>
    <tbody>
      {state && state.serviceCatInternational && state.serviceCatInternational.map((_, index) => (

        <tr key={_.id}>
          <td>{_.from.formattedValue}</td>
          <td>{_.to.formattedValue}</td>
        <td>{getInternationaMealSplit(_,'0.5')}</td>
        <td>{getInternationaMealSplit(_,'1')}</td>
        <td>{getInternationaMealSplit(_,'1.5')}</td>
        <td>{getInternationaMealSplit(_,'2')}</td>
        <td>{getInternationaMealSplit(_,'2.5')}</td>
        {editMealPolicy?<td><img className="icon-size" alt={"edit"}  onClick={()=>updateMealPolicy(_,index)}  src={editIcon2} /></td>:null}
        </tr>
      ))}
    </tbody>
  </table>

</div>
</div>

<div  style={{padding:'5px',fontWeight:'bold',borderRadius:'5px 5px 0px 0px'}} className='spl-row-class flexRow justifyContentCenter margBottom10 fontSemibold'>Business Class</div>

<div className='flexRow justifyContentSpaceBetween'>
<div className='margBottom18 margRight10'  style={{width:'50%'}}>
<div className='table-header'></div>

<table  style={{height:'400px'}}>
    <thead>
      <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
        <th>From</th>
        <th>SVC</th>
        <th>Meals</th>
      </tr>
    </thead>
    <tbody>
      {state && state.serviceBandInternational && state.serviceBandInternational.map((_, index) => (

        <tr key={_.id}>
          <td>{_.from} to {_.to}&nbsp;mins</td>
          <td >{_.serviceCode}</td>
          <td>{_.mealCode}</td>
        </tr>
      ))}
    </tbody>
  </table>

</div>


<div className='margBottom18' style={{width:'50%'}}>
<div className='table-header'></div>

<table  style={{height:'400px'}}>
    <thead>

      <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
        <th>From</th>
        <th>To</th>
        <th>0.5</th>
        <th>1</th>
        <th>1.5</th>
        <th>2</th>
        <th>2.5</th>
        <th>Edit</th>

      </tr>
    </thead>
    <tbody>
      {state && state.serviceCatInternational && state.serviceCatInternational.map((_, index) => (

        <tr key={_.id}>
          <td>{_.from.formattedValue}</td>
          <td>{_.to.formattedValue}</td>
        <td>{getInternationaMealSplit(_,'0.5')}</td>
        <td>{getInternationaMealSplit(_,'1')}</td>
        <td>{getInternationaMealSplit(_,'1.5')}</td>
        <td>{getInternationaMealSplit(_,'2')}</td>
        <td>{getInternationaMealSplit(_,'2.5')}</td>
     { editMealPolicy?  <td><img className="icon-size" alt={"edit"} onClick={()=>updateMealPolicy(_,index)}  src={editIcon2} /></td>:null}
        </tr>
      ))}
    </tbody>
  </table>

</div>
</div>
<div  style={{padding:'5px',fontWeight:'bold',borderRadius:'5px 5px 0px 0px'}} className='spl-row-class flexRow justifyContentCenter margBottom10 fontSemibold'>Premium Economy</div>

<div className='flexRow justifyContentSpaceBetween'>
<div className='margBottom18 margRight10'  style={{width:'50%'}}>
<div className='table-header'></div>

<table  style={{height:'400px'}}>
    <thead>
      <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
        <th>From</th>
        <th>SVC</th>
        <th>Meals</th>
      </tr>
    </thead>
    <tbody>
      {state && state.serviceBandInternational && state.serviceBandInternational.map((_, index) => (

        <tr key={_.id}>
          <td>{_.from} to {_.to}&nbsp;mins</td>
          <td >{_.serviceCode}</td>
          <td>{_.mealCode}</td>
        </tr>
      ))}
    </tbody>
  </table>

</div>


<div className='margBottom18' style={{width:'50%'}}>
<div className='table-header'></div>

<table  style={{height:'400px'}}>
    <thead>

      <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
        <th>From</th>
        <th>To</th>
        <th>0.5</th>
        <th>1</th>
        <th>1.5</th>
        <th>2</th>
        <th>2.5</th>
        <th>Edit</th>

      </tr>
    </thead>
    <tbody>
      {state && state.serviceCatInternational && state.serviceCatInternational.map((_, index) => (

        <tr key={_.id}>
          <td>{_.from.formattedValue}</td>
          <td>{_.to.formattedValue}</td>
        <td>{getInternationaMealSplit(_,'0.5')}</td>
        <td>{getInternationaMealSplit(_,'1')}</td>
        <td>{getInternationaMealSplit(_,'1.5')}</td>
        <td>{getInternationaMealSplit(_,'2')}</td>
        <td>{getInternationaMealSplit(_,'2.5')}</td>
       {editMealPolicy? <td><img className="icon-size" alt={"edit"} onClick={()=>updateMealPolicy(_,index)}  src={editIcon2} /></td>:null}
        </tr>
      ))}
    </tbody>
  </table>

</div>
</div>


      <div  style={{padding:'5px',fontWeight:'bold',borderRadius:'5px 5px 0px 0px'}} className='spl-row-class flexRow justifyContentCenter margBottom10 fontSemibold'>Economy Class</div>

      <div className='flexRow justifyContentSpaceBetween'>
 <div className='margBottom18 margRight10'  style={{width:'50%'}}>
 <div className='table-header'></div>

<table style={{height:'400px'}}>
          <thead>
            <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
              <th>From</th>
              <th>SVC</th>
              <th>Meals</th>
            </tr>
          </thead>
          <tbody>
            {state && state.serviceBandInternational && state.serviceBandInternational.map((_, index) => (

              <tr key={_.id}>
                <td>{_.from} to {_.to}&nbsp;mins</td>
                <td >{_.serviceCode}</td>
                <td>{_.mealCode}</td>
              </tr>
            ))}
          </tbody>
        </table>

  </div>


 <div className='margBottom18' style={{width:'50%'}}>
 <div className='table-header'></div>

<table style={{height:'400px'}}>
          <thead>

            <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
              <th>From</th>
              <th>To</th>
              <th>0.5</th>
              <th>1</th>
              <th>1.5</th>
              <th>2</th>
              <th>2.5</th>
              <th>Edit</th>

            </tr>
          </thead>
          <tbody>
            {state && state.serviceCatInternational && state.serviceCatInternational.map((_, index) => (

              <tr key={_.id}>
                <td>{_.from.formattedValue}</td>
                <td>{_.to.formattedValue}</td>
              <td>{getInternationaMealSplit(_,'0.5')}</td>
              <td>{getInternationaMealSplit(_,'1')}</td>
              <td>{getInternationaMealSplit(_,'1.5')}</td>
              <td>{getInternationaMealSplit(_,'2')}</td>
              <td>{getInternationaMealSplit(_,'2.5')}</td>
           {editMealPolicy?   <td><img className="icon-size" alt={"edit"} onClick={()=>updateMealPolicy(_,index)}  src={editIcon2} /></td>:null}
              </tr>
            ))}
          </tbody>
        </table>

  </div>
      </div>





      <div  style={{padding:'5px',fontWeight:'bold',borderRadius:'5px 5px 0px 0px'}} className='spl-row-class flexRow justifyContentCenter margBottom10 fontSemibold'>Cabin Crew</div>

<div className='flexRow justifyContentSpaceBetween'>
<div className='margBottom18 margRight10'  style={{width:'50%'}}>
<div className='table-header'></div>

<table  style={{height:'400px'}}>
    <thead>
      <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
        <th>From</th>
        <th>SVC</th>
        <th>Meals</th>
      </tr>
    </thead>
    <tbody>
      {state && state.serviceBandInternational && state.serviceBandInternational.map((_, index) => (

        <tr key={_.id}>
          <td>{_.from} to {_.to}&nbsp;mins</td>
          <td >{_.serviceCode}</td>
          <td>{_.mealCode}</td>
        </tr>
      ))}
    </tbody>
  </table>

</div>


<div className='margBottom18' style={{width:'50%'}}>
<div className='table-header'></div>

<table  style={{height:'400px'}}>
    <thead>

      <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
        <th>From</th>
        <th>To</th>
        <th>0.5</th>
        <th>1</th>
        <th>1.5</th>
        <th>2</th>
        <th>2.5</th>
        <th>Edit</th>

      </tr>
    </thead>
    <tbody>
      {state && state.serviceCatInternational && state.serviceCatInternational.map((_, index) => (

        <tr key={_.id}>
          <td>{_.from.formattedValue}</td>
          <td>{_.to.formattedValue}</td>
        <td>{getInternationaMealSplit(_,'0.5')}</td>
        <td>{getInternationaMealSplit(_,'1')}</td>
        <td>{getInternationaMealSplit(_,'1.5')}</td>
        <td>{getInternationaMealSplit(_,'2')}</td>
        <td>{getInternationaMealSplit(_,'2.5')}</td>
       {editMealPolicy? <td><img className="icon-size" alt={"edit"} onClick={()=>updateMealPolicy(_,index)}  src={editIcon2} /></td>:null}
        </tr>
      ))}
    </tbody>
  </table>

</div>
</div>



<div  style={{padding:'5px',fontWeight:'bold',borderRadius:'5px 5px 0px 0px'}} className='spl-row-class flexRow justifyContentCenter margBottom10 fontSemibold'>Cockpit Crew</div>

<div className='flexRow justifyContentSpaceBetween'>
<div className='margBottom18 margRight10'  style={{width:'50%'}}>
<div className='table-header'></div>

<table  style={{height:'400px'}}>
    <thead>
      <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
        <th>From</th>
        <th>SVC</th>
        <th>Meals</th>
      </tr>
    </thead>
    <tbody>
      {state && state.serviceBandInternational && state.serviceBandInternational.map((_, index) => (

        <tr key={_.id}>
          <td>{_.from} to {_.to}&nbsp;mins</td>
          <td >{_.serviceCode}</td>
          <td>{_.mealCode}</td>
        </tr>
      ))}
    </tbody>
  </table>

</div>


<div className='margBottom18' style={{width:'50%'}}>
<div className='table-header'></div>

<table  style={{height:'400px'}}>
    <thead>

      <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
        <th>From</th>
        <th>To</th>
        <th>0.5</th>
        <th>1</th>
        <th>1.5</th>
        <th>2</th>
        <th>2.5</th>
        <th>Edit</th>

      </tr>
    </thead>
    <tbody>
      {state && state.serviceCatInternational && state.serviceCatInternational.map((_, index) => (

        <tr key={_.id}>
          <td>{_.from.formattedValue}</td>
          <td>{_.to.formattedValue}</td>
        <td>{getInternationaMealSplit(_,'0.5')}</td>
        <td>{getInternationaMealSplit(_,'1')}</td>
        <td>{getInternationaMealSplit(_,'1.5')}</td>
        <td>{getInternationaMealSplit(_,'2')}</td>
        <td>{getInternationaMealSplit(_,'2.5')}</td>
      { editMealPolicy? <td><img className="icon-size" alt={"edit"} onClick={()=>updateMealPolicy(_,index)}  src={editIcon2} /></td>:null}
        </tr>
      ))}
    </tbody>
  </table>

</div>
</div>




<div  style={{padding:'5px',fontWeight:'bold',borderRadius:'5px 5px 0px 0px'}} className='spl-row-class flexRow justifyContentCenter margBottom10 fontSemibold'>VIP</div>

<div className='flexRow justifyContentSpaceBetween'>
<div className='margBottom18 margRight10'  style={{width:'50%'}}>
<div className='table-header'></div>

<table  style={{height:'400px'}}>
    <thead>
      <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
        <th>From</th>
        <th>SVC</th>
        <th>Meals</th>
      </tr>
    </thead>
    <tbody>
      {state && state.serviceBandInternational && state.serviceBandInternational.map((_, index) => (

        <tr key={_.id}>
          <td>{_.from} to {_.to}&nbsp;mins</td>
          <td >{_.serviceCode}</td>
          <td>{_.mealCode}</td>
        </tr>
      ))}
    </tbody>
  </table>

</div>


<div className='margBottom18' style={{width:'50%'}}>
<div className='table-header'></div>

<table  style={{height:'400px'}}>
    <thead>

      <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
        <th>From</th>
        <th>To</th>
        <th>0.5</th>
        <th>1</th>
        <th>1.5</th>
        <th>2</th>
        <th>2.5</th>
        <th>Edit</th>

      </tr>
    </thead>
    <tbody>
      {state && state.serviceCatInternational && state.serviceCatInternational.map((_, index) => (

        <tr key={_.id}>
          <td>{_.from.formattedValue}</td>
          <td>{_.to.formattedValue}</td>
        <td>{getInternationaMealSplit(_,'0.5')}</td>
        <td>{getInternationaMealSplit(_,'1')}</td>
        <td>{getInternationaMealSplit(_,'1.5')}</td>
        <td>{getInternationaMealSplit(_,'2')}</td>
        <td>{getInternationaMealSplit(_,'2.5')}</td>
        {editMealPolicy?<td><img className="icon-size" alt={"edit"} onClick={()=>updateMealPolicy(_,index)}  src={editIcon2} /></td>:null}
        </tr>
      ))}
    </tbody>
  </table>

</div>
</div>



    </div>
  ) : null}

</div>




</div>


        </>

      }
{
        state && Boolean(state.selected) ? (
          <EditModal
            key={state.selected.action}
            title={state.selected?.title}
            onClose={onCloseEditMeals}
            // modalFormStatusObj={modalFormStatusObj}
            onSubmit={onCloseEditMeals}
            onChange = {onFieldChangeHandler}
            fields={state.selected.type==='dom'?editFieldsDom.map((_) => {
              return { ..._, initialValue: state.selected[_.attribute] }
            }):editFieldsInt.map((_) => {
              return { ..._, initialValue: state.selected[_.attribute] }
            })}
          />
        )
        : null
      }

        {
        t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
      }
    </div>
  )
}