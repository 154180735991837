/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { useTranslation } from "react-i18next";
import 'ag-grid-community/dist/styles/ag-grid.css';
import '../../assets/styles/inner/table.scss';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
import VerticalDivider from '../../assets/icons/svg/vertical-divider.svg';
import SearchVector from '../../assets/icons/svg/search-vector.svg';
import CloseSearch from '../../assets/icons/svg/close.svg';
import { APIEndpoint} from "../../utils/axios";
import { durationFilterSelect } from "../../utils/configs";
import zoom from '../../assets/icons/zoom.svg';
import DownloadVector from '../../assets/icons/svg/download-vector.svg';
import ReloadVector from '../../assets/icons/svg/reload-vector.svg';

export const ListViewGridComponent = (props) => {
  const { title, filtersList, warehouseArr, setFilteredWidgetData, id } = props;
  const { t } = useTranslation();
  let inputRef = useRef();
  const [rowsData, setRowData] = useState([]);
  const [searchSlidebar, setSearchSlidebar] = useState(false);
  const [gridAPI, setGridAPI] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseId, setWarehouseId] = useState(null);
  const [selWarehouse, setSelWarehouse] = useState([]);
  const [filters, setFilters] = useState("");
  const sType = localStorage.getItem('sType');

  useEffect(() => {
    if (props && props.title === 'Physical Quantity of Products Sale Warehouse Wise'&&sType==='akasa') {
      let rowsPqty = props.rowsData.map((row) => ({ soldQty: row.soldQty, product: row.product?.name }))
      setRowData((_) => (rowsPqty))
    } else {
      setRowData(props.rowsData)
    }
    if (filtersList[0]) {
      setSelWarehouse(filtersList[0])
    }
  }, [props.rowsData])

  useEffect(() => {
    reloadGridData();
  }, [filters])

  const changeFilter = (e) => {
    setFilters(e.target.value)
  }
  useEffect(() => {
    if (title === 'Physical quantity of products sale warehouse wise') {
      setWarehouseOptions();
    }
  }, [t, warehouseArr]);

  useEffect(() => {
    if (searchSlidebar) {
      inputRef.current?.focus();
    }
  }, [searchSlidebar]);

  React.useEffect(() => {
    if (filtersList[2]) {
      setWarehouseId(filtersList[2].id)
    }
    if (filtersList[0] && selWarehouse !== '') {
      setSelWarehouse(filtersList[0])
    }
    if (!filters) {
      setFilters(filtersList[1])
    }
  }, [filtersList]);

  const preCompile = {
    warehouse: t('selectButtons.selWarehouse')
  }

  const setWarehouseOptions = async () => {
    const options = [{ key: 0, label: preCompile.warehouse, value: '' }];
    warehouseArr.filter((fieldValue, index) => {
      options.push({ key: index + 1, label: `${fieldValue.value}`, value: `${fieldValue.value}` });
      return fieldValue;
    });
    setWarehouses(options);
  }

  const onReadyHandler = (params) => {
    setGridAPI(params.api);
  }

  const onColumnResizedHandler = (e) => {
  }


  const rowClass = "tableRowClass";
  const getRowClass = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return 'tableRowClass';
    }
  };

  const reloadGridData = async (e) => {
    if (warehouseId) {
      gridAPI.showLoadingOverlay();
      try {
        const resp = await APIEndpoint.get(`portal/v1/widget/${id}?warehouseId=${warehouseId}`);
        if (resp.success) {
          setFilteredWidgetData(resp.data)
          if (resp.data) {
            gridAPI.hideOverlay();
          }
        }
      }
      catch (error) {
        console.log(error)
      }
    }
    if (filters) {
      try {
        gridAPI.showLoadingOverlay();
        const resp = await APIEndpoint.get(`portal/v1/widget/${id}?sortBy=${filters}`);
        if (resp.success) {
          setFilteredWidgetData(resp.data)
          if (resp.data) {
            gridAPI.hideOverlay();
          }
        }
      }
      catch (error) {
      }
    }
  };
  const getFileName = () => {
    const fileName = props.title;
    return `${fileName}.csv`
  }

  const onBtExport = () => {
    gridAPI.exportDataAsCsv({
      fileName: getFileName()
    });
  };

  const onSearchClick = () => {
    setSearchSlidebar(!searchSlidebar);
  }

  const onFilterTextBoxChanged = (id) => {
    gridAPI.setQuickFilter(
      document.getElementById('filter-text-box-' + id).value
    );
  };


  const onItemTypeChange = async (e) => {
    gridAPI.showLoadingOverlay();
    setSelWarehouse(e.target.value);
    let wName = warehouseArr.find(w => w.value === e.target.value)
    if (wName) {
      try {
        const resp = await APIEndpoint.get(`portal/v1/widget/${id}?warehouseId=${wName.id}`);
        if (resp.success) {
          setFilteredWidgetData(resp.data)
          if (props.rowsData.length) {
            gridAPI.hideOverlay();
          }

        }
      }
      catch (error) {
        gridAPI.showNoRowsOverlay();
        console.log(error)
      }
    }
    else {
      gridAPI.showNoRowsOverlay();
      setFilteredWidgetData([])
      setRowData([])
    }
  }

  return (
    <>
      <div className="cmpHeadingBlock flexRow alignItemsCenter justifyContentSpaceBetween">
        <div style={{ whiteSpace: 'nowrap' }} className='mb-3 component-header-space-list cmpHeading'>{props.title || ''}</div>
        <div className="cmpHeadingRight flexRow alignItemsCenter justifyContentFlexEnd">
          <>
            {!searchSlidebar ? <img className="icon-size" src={SearchVector} alt="Search icon" onClick={onSearchClick} /> : <img className="icon-size" src={CloseSearch} alt="Search icon" onClick={onSearchClick} />}
            {searchSlidebar ? <input className='filter-text-box' ref={inputRef} id={`filter-text-box-` + props.id} type="search" placeholder={t('filters.searchText')} onInput={() => onFilterTextBoxChanged(props.id)} /> : null}
            <img className="icon-divider" src={VerticalDivider} alt="|" />
            <img className="icon-size" src={DownloadVector} alt="Download icon" onClick={onBtExport} />
            <img className="icon-size" src={ReloadVector} alt="Reload icon" onClick={reloadGridData} />
          </>
          {(title === 'Most declined card sectors per month/week' || title === 'Physical quantity of products sale warehouse wise') && <img className="icon-divider" src={VerticalDivider} alt="|" />}
          {title === 'Physical quantity of products sale warehouse wise' && <div className={`dialog__description`}>
            <select className='select-input-dashboard-list' onChange={onItemTypeChange} value={selWarehouse}>
              {warehouses.map((item, index) => (
                <option key={index} value={item.value}>{item.label}</option>
              ))
              }
            </select>
          </div>}
          {title === 'Most declined card sectors per month/week' && <span>
            <button className='dashboard-border-listview'>
              <select
                className='dashboard-button-report'
                id="page-size" onChange={changeFilter} value={filters}>
                {durationFilterSelect.slice(0, durationFilterSelect.length - 1).map((item, index) => (
                  <option key={index} value={item.value}>{item.label}</option>
                ))
                }
              </select>
            </button>
          </span>}
        </div>
      </div>

      <div
        id="myGrid"
        className="ag-theme-alpine-dark grid-container-dashboard"
        style={{ minWidth: "600px", padding: '0px', maxWidth: 'calc(100% - 3px )', height: "250px", marginBottom: '1rem' }}
      >
        <AgGridReact
          reactNext={true}
          domLayout="autoWidth"
          headerHeight={36}
          columnDefs={props.columnDefs}
          rowModelType="clientSide" // clientSide,infinite,viewport,serverSide
          rowData={rowsData}
          onGridReady={onReadyHandler}
          onColumnResized={onColumnResizedHandler}
          rowClass={rowClass}
          getRowClass={getRowClass}
          suppressRowClickSelection={true}
          rowDragManaged={false} // true: Enable row dragging
          singleClickEdit={false} // true: Enable edit row
          editType={''} //'': Cell edit, 'fullRow': Edit full row
          animateRows
          componentWrappingElement={'span'}
          rowDataChangeDetectionStrategy="DeepValueCheck" // IdentityCheck|DeepValueCheck|NoCheck
          showToolPanel={true}
          overlayLoadingTemplate={
            `<span className="ag-overlay-loading-center">${t('pages.dashboardContent.dashboard.message.wait')}</span>`
          }
          // CALLBACKS
          isScrolling={() => { console.log('scrolling...'); return false; }}
          cacheQuickFilter={false} // for a performance gain
          sortingOrder={'desc'} // 'asc'|'desc'|null
          accentedSort={false} // true: sort by ascented charecters(Slow performance)
          excludeChildrenWhenTreeDataFiltering={false} // exclude child nodes from filter results

          alwaysShowHorizontalScroll={false}
          suppressColumnVirtualisation={true}
          suppressHorizontalScroll={true}
        />
        <span className="icon-enlarge-list">
          <img alt='zoom' src={zoom} onClick={() => props.handleZoom(props.chartLabel, props, props.options)} />
        </span>
      </div>
    </>);
}