import React from 'react'
import '../assets/styles/modal.scss';
import closeIcon from '../images/icons/Icon2.png';
import { useTranslation } from 'react-i18next';

export const CessViewModal = (props) => {
  const {name}=props.cessDetails;
  const { t } = useTranslation();
  const listItems = props.conditions.map((list,index) =>
{
  return(<React.Fragment key={index}>
        <div className='row'>
         <div className='col-xs-11 wrapper' >
     <p>{list.predicate}</p>
        </div>
        </div>
        </React.Fragment>)}
 );
  return (
      <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar">
        <div className="dialog__content">
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">Conditions- {name}</h2>
          <hr />
          <div className="dialog__body_container flexRow justifyContentSpaceBetween flexWrap">
            <ul className='list-group'>
                {listItems}
            </ul>
          </div>
        </div>
        <div className="dialog__footer flexRow justifyContentSpaceEvenly">
        <button className={`done-button`} onClick={props.onClose}>{t('buttons.done')}</button>
      </div>
      </div>
      </div>
  );
}
