/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"; //, useRef
import { useParams, useNavigate } from 'react-router-dom';
import Spreadsheet from "react-spreadsheet"; // , { createEmptyMatrix }
// import * as ReactDoM from "react-dom";
import { APIEndpoint, DFPAPIEndpoint } from "../../../utils/axios";
import { AlertModal } from "../../../common";
import { DEFAULT_SHEET_ROWS, UPLOADED_LOI_ATTACHMENT_KEY } from "../../../utils/configs";
import { updateButtonTxtStatus, validateCellAndGetData } from "../../../utils/commonFunctions";
import closeIcon from "../../../images/icons/Icon2.png"
import * as ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";

let spreadSheetErrTimout;
const sType = localStorage.getItem('sType');
const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    ITEM_UNIT: 2,
    ITEM_LOI_QTY: 3,
    ITEM_INT_QTY: 4,
    ITEM_RECV_QTY: 5,
    ITEM_QTY: 6,
    ITEM_REMARKS: 7
};
const sheetLabels = ['Item Code', 'Item Name','Unit','LOI Quantity','Indented Quantity','Received Quantity','Quantity', 'Remarks'];
const createEmptySheetRows = (noOfRows = DEFAULT_SHEET_ROWS,sheetData) => {
    let len = sheetData ? +sheetData.length:0
    const rows = [];
    for( var i=0; i < noOfRows; i++) {
        rows.push([
            {
                id: 'itemCode',
                value: '',
                className: 'cellClass cellClass_' + len + '_' + iIndexes.ITEM_CODE,
                width: '200px',
                showPopup: true
            },
            {
                id: 'itemName',
                value: '',
                className: 'cellClass cellClass_' + len + '_' + iIndexes.ITEM_NAME,
                width: '200px',
                showPopup: true
            },
            {
                id: 'unit',
                value: '',
                className: 'cellClass1',
                width: '400px',
                readOnly: false
            },
            {
                id: 'loiQuantity',
                value: '',
                className: 'cellClass1',
                width: '200px'
            },
            {
                id: 'indentedQuantity',
                value: '',
                className: 'cellClass1',
                width: '200px'
            },
            {
                id: 'receviedQuantity',
                value: '',
                className: 'cellClass1',
                width: '200px'
            },

            {
                id: 'quantity',
                value: '',
                className: 'cellClass1',
                width: '200px'
            },

            {
                id: 'remarks',
                value: '',
                className: 'cellClass1',
                width: '200px'
            }
        ]);
        len++
    }
    return rows;
}

/**
 * Automatically creating rows
 */
// const initialData = createEmptyMatrix(10, sheetLabels.length);
/**
 * Manually creating rows
 */
const initialData = [];// createEmptySheetRows(DEFAULT_SHEET_ROWS - 1);

const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 100,
    page: 1,
    searchParam: ''
  };



export const PurchaseOrderCreate = (props) => {
    const urlParams = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [sheetData, setSheetData] = useState(initialData);
    const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
    const [state, setState] = useState({ isInvalidAccess: false, message: '' });
    const modelStyle = { maxWidth: '50%', margin: 'auto 10% 100% 10%' };
    const [LOIDetails, setLOIDetails] = useState(null);
    const [isEditForm, setIsEditForm] = useState(false);
    const [formData, setCreateFormData] = useState(null);
    const [LOIAttachment, setLOIAttachment] = useState(null);
    const [vendorItemsLoi,setVendorItemsLOI]=useState(null);
    const [activeCell, setActiveCell] = useState([])
    const [itemData, setItemData] = useState([])

    const targetEltCodes = document.getElementsByClassName("cellSelectionBlockItemCodes")[0];
    const targetEltNames = document.getElementsByClassName("cellSelectionBlockItemNames")[0];

    // const sheetRef = useRef(null);
    // const LOICELLComponent = (props) =>  {
    //     console.log('LOICELLComponent:', props);
    //     return <>11</>;
    // }
    // const LOIViewComponent = (props) =>  {
    //     console.log('LOIViewComponent:', props);
    //     return <>222</>
    // }

    async function getItemData() {
        try {
            const res = await DOMAIN.get('items/enumerate')
            setItemData(res.items)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect( () => {
        if ( urlParams.hasOwnProperty('formInputs') ) {
            const attachment = localStorage.getItem( UPLOADED_LOI_ATTACHMENT_KEY );
            setLOIAttachment( JSON.parse(attachment) );
            setCreateFormData( JSON.parse(urlParams.formInputs) );
            if (sheetData.length === 0) {
                setSheetData( createEmptySheetRows( DEFAULT_SHEET_ROWS ) );
            }
        } else if ( urlParams.hasOwnProperty('LOI_ID') ) {
            setIsEditForm(true);
            getLOIItems(urlParams.LOI_ID);
        }
    }, [ urlParams ]);

    useEffect( () => {
    if(formData){
        setIsEditForm(true);
        getLOIItems(formData);
    }
    }, [formData]);

    useEffect( () => {
        if (LOIDetails&&LOIDetails.length) {
            getItemData();
            setLOIItemsToSheet(LOIDetails);
        }
    }, [LOIDetails]);

    const getLOIItems = async(data) => {
        const payload={
            "indentId": null,
            "warehouseId": parseInt(data.warehouseName),
            "vendorId": data.vendorId,
            "loiId": data.loiId
        }
        try {
            const [resLOI,resVendor] = await Promise.all([
                APIEndpoint.post('indents/getAutoPopulateLOIItems',payload),
                APIEndpoint.post('warehouses/loi/vendorItems',payload),
              ]);
              if (resLOI.success&&resLOI.items.length&&resVendor.success&&resVendor.items.length) {
                const lOIDetails = resLOI.items;
                setLOIDetails(lOIDetails);
                setVendorItemsLOI(resVendor.items)
            }
        } catch (error) {
            console.log('Invalid page access!, Letter of Intent ID not found.', error.message);

            setState({ ...state, isInvalidAccess: true,
                message: "Invalid page access!, Letter of Intent ID not found, On click OK, you will be redirected to Letter of Intents page." });
        }
    };

    const setLOIItemsToSheet = (LOIDetails) => {

        let updatedSpreadsheetData = Object.assign([], sheetData);
        const items = LOIDetails;
        const itmLngth = items?.length;
        /**
         * Validate whether required items already plotted
         */
        let newRows = [];
        if ( items && itmLngth > 0 && updatedSpreadsheetData.length < itmLngth ) {

            let newlyRequiredRows = itmLngth - updatedSpreadsheetData.length;
            if (newlyRequiredRows < DEFAULT_SHEET_ROWS) {

                newlyRequiredRows = DEFAULT_SHEET_ROWS;
            }
            newRows = createEmptySheetRows(newlyRequiredRows);

        } else {
            newRows = createEmptySheetRows(DEFAULT_SHEET_ROWS);
        }

        updatedSpreadsheetData = [ ...newRows ];

        updatedSpreadsheetData.forEach( (sheetRow, i) => {
            if (items&&items.length&& items[i] ) {
                updatedSpreadsheetData[i][iIndexes.ITEM_CODE].value = items[i].itemCode;
                updatedSpreadsheetData[i][iIndexes.ITEM_NAME].value = items[i].itemName;
                updatedSpreadsheetData[i][iIndexes.ITEM_UNIT].value = items[i].unit;
                updatedSpreadsheetData[i][iIndexes.ITEM_LOI_QTY].value = items[i].loiQuantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_INT_QTY].value = items[i].indentedQuantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_RECV_QTY].value = items[i].receviedQuantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_QTY].value = items[i].quantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_REMARKS].value = items[i].remarks;
                // sheetRef.setCellData(updatedSpreadsheetData[i]);
            }

        });

        // const updatedSpreadsheetData = sheetData.map( (row) => {
        //     const nextRow = [ ...row ];
        //     // nextRow.length = 10;
        //     nextRow.value = ;
        //     return nextRow;
        // } );

        setSheetData( [ ...updatedSpreadsheetData ]);

    };

    const addRowsToSheet = (rowsToAdd = 10) => {
        const newRows = createEmptySheetRows(rowsToAdd,sheetData);
        setSheetData( [...sheetData, ...newRows ]);
    };

    const removeRow = () => {
            let row = activeCell.row;
            let deletedData=sheetData.filter((data,index)=>{
                if(index!==row){
                    return data
                }
            })
            setSheetData(deletedData)
        };

    const saveSheetData = (e) => {
        clearTimeout(spreadSheetErrTimout)
        updateButtonTxtStatus(true, e, 'Saving...', true);

        const postItems = [];

        let inSheetData = [ ...sheetData ];
        inSheetData.map( (cellRow, idx) => {
            const itemCode = validateCellAndGetData(cellRow[iIndexes.ITEM_CODE]);
            const itemName = validateCellAndGetData(cellRow[iIndexes.ITEM_NAME]);
            const quantity = validateCellAndGetData(cellRow[iIndexes.ITEM_QTY]);
            const unit = validateCellAndGetData(cellRow[iIndexes.ITEM_UNIT]);
            const remarks = validateCellAndGetData(cellRow[iIndexes.ITEM_REMARKS]);


            if (itemCode && itemName && quantity && unit && remarks) {
                postItems.push({
                    itemCode,
                    itemName,
                    quantity: parseInt(quantity),
                    unit,
                    remarks
                });
            }
            else if (itemCode || itemName) {
                /**
                 * SET Error status
                 */
                // cellRow[iIndexes.ITEM_UNIT].className += ' errorCellData';
                // cellRow[iIndexes.ITEM_REQ_QTY].className= 'errorCellData';
                // cellRow[iIndexes.ITEM_RECI_QTY].className= 'errorCellData';
                // cellRow[iIndexes.ITEM_QTY].className= 'errorCellData';
                // cellRow[iIndexes.ITEM_REMARKS].className= 'errorCellData';

                updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
                setSpreadsheetErrors([ { message: 'Please fill all required item details.' } ]);
            }
            return cellRow;
        });

        // sheetData.map( (row) => {
        //     const nextRow = [ ...row ];
        //     nextRow.length = 10;
        //     return nextRow;
        // } );

        setSheetData(inSheetData);

        if (!spreadsheetErrors.length && postItems.length === 0) {
            // show global alert message
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'We did not find anything to save!' } ]);
            return false;
        }

        if ( !postItems.length ) {
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            return false;
        }
        let data = {}, apiURL = '';
        if (isEditForm) {
            data = {
                distributorId: LOIDetails.distributorId,
                loiId: urlParams.LOI_ID,
                items: postItems
            };
            apiURL = 'letterOfIndents/create';
        }else {
            data = {
                distributorId: formData.distributorName,
                agreementNumber: formData.agreementNumber,
                expiryDate: formData.expiryDate,
                remarks: formData.remarks,
                attachment: LOIAttachment || null,
                items: postItems
            }
            apiURL = 'letterOfIndents/create';
        }

        APIEndpoint.post(apiURL, data).then( apiResp => {
            /**
             * Update success response
             */
            updateButtonTxtStatus(true, e, 'Redirecting...', true, { defaultButtonText: 'Save'} );
            setSheetData(initialData);
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiResp.msg || 'Successfully created a Letter of Intent.' } ]);
            setTimeout( () => {
                goToLOIHome();
            }, 4000);
        })
        .catch( (error) => {
            const apiData = JSON.parse(error.message);
            updateButtonTxtStatus(false, e, 'Save failed', false, { defaultButtonText: 'Save' });
            if ( apiData?.data.errors ) {
                const errorMsg = apiData.data.errors.message;
                const errorArr = apiData.data.errors.errors;
                setSpreadsheetErrors([ ...spreadsheetErrors, { message: errorMsg, error: 1 },...errorArr ]);
                spreadSheetErrTimout=setTimeout(() => {
                    setSpreadsheetErrors([])
                }, 4000);
            }
            else if (apiData.msg) {
                setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiData.msg, error: 1 } ]);
            }
        });

    };

    const showItemListPopup = (eltPosition, activeCellIn) => {
        if (activeCellIn.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'block';
                targetEltCodes.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltCodes.style.left = eltPosition.left.toFixed() + 'px';
            }
        } else if (activeCellIn.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'block';
                targetEltNames.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltNames.style.left = eltPosition.left.toFixed() + 'px';
            }
        }
    }

    const handleActiveCellChange = (activeCell) => {
        setActiveCell(activeCell)
        console.log('ActiveCell:', activeCell);

        //lsjhjhdsjfh
        if ( sheetData[activeCell.row][activeCell.column] ) {
            const cellDetailsObj = sheetData[activeCell.row][activeCell.column];
            if ( cellDetailsObj.hasOwnProperty('showPopup') && cellDetailsObj.showPopup === true ) {

                const sourceElt = document.getElementsByClassName('cellClass_' + activeCell.row + '_' + activeCell.column)[0];
                if ( sourceElt ) {
                    const eltPosition = sourceElt.getBoundingClientRect();

                    showItemListPopup(eltPosition, activeCell);
                }

            }

        }
    }

    const onClickPopupListItem = (e, row) => {
        setItemToSheet(row);
    }

    const setItemToSheet = (item) => {
        if (Object.keys(item).length === 0) {
            return 0;
        }

        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

        updatedSpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {
                sheetRow[iIndexes.ITEM_CODE].value = item.itemCode;
                sheetRow[iIndexes.ITEM_NAME].value = item.itemName;
            }
            return sheetRow;
        });

        ReactDOM.flushSync( () => {
            setSheetData( [ ...updatedSpreadsheetData ]);
        });

        setActiveCell({});
        hideItemListPopup();
    }

    const hideItemListPopup = () =>{
        // if (activeCell.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'none';
            }
        // } else if (activeCell.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'none';
            }
        // }
    }


    const handleCellBlur = () => {
        console.log('CellBlur.');
    }
    const handleCellKeyDown = (keyboardEvent) => {
        console.log('keyDownEvent.', keyboardEvent.target.value);
    }
    const handleModeChange = (mode) => {
        console.log('ModeCHanged to:', mode);
    };
    const handleCellSelect = (selectedCell) => {
        console.log('SelectedCell:', selectedCell);
    };

    const goToLOIHome = () => {
        navigate('/procurement/letter-of-intents');
    };
    const handleOnContinue = async () => {
        goToLOIHome();
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                <div className="sheet-container sm-scrollbar">
                    <div className="header mb-3 flexRow justifyContentSpaceBetween">
                        <div className="leftBlock alignItemsCenter">
                            <button onClick={ (e) => saveSheetData(e) }
                                className="add-item-button mr-2">Save</button> &nbsp;&nbsp;
                            <button onClick={ (e) => removeRow(e) }
                                className="remove-button ml-2">Delete Row</button>
                        </div>
                        <div className="rightBlock alignItemsCenter">
                            {spreadsheetErrors.length === 1 ? spreadsheetErrors[0].message : (
                                <ul>
                                    {spreadsheetErrors.map(err=>{
                                    return <li>{err.message}</li>
                                    })}
                                </ul>
                            )}
                        </div>
                        <button onClick={goToLOIHome}>
                            <img src={closeIcon} alt=""/>
                        </button>
                    </div>
                    <div className="se-titlebar">
               {<span>Indent for {formData?.loiCode||""}</span>}
                   </div>

                    <Spreadsheet
                        // ref={sheetRef}
                        data={sheetData}
                        columnLabels={sheetLabels}
                        darkMode={true}

                        // getBindingsForCell={[]}
                        onChange={setSheetData}
                        onActivate={handleActiveCellChange}
                        onBlur={handleCellBlur}
                        onKeyDown={handleCellKeyDown}
                        onModeChange={handleModeChange}
                        onSelect={handleCellSelect}

                        // Cell={LOICELLComponent}
                        // DataViewer={LOIViewComponent}

                    />

                    <div className=" flexRow justifyContentFlexStart alignItemsCenter margTop8">
                        <button onClick={ () => addRowsToSheet() }
                            className="add-item-button"title={ t('spreadsheet.addmoreRows') }>{ t('buttons.addRows') }</button> 
                    </div>
                    <div className = "cellSelectionBlockItemCodes sm-scrollbar">
                       <ul>
                        {
                           itemData.length?itemData.map((d,i)=>{
                                return <li key={i} onClick={(e) => onClickPopupListItem(e, d) }>{d.itemCode}</li>
                            }): <li key={0} >No Items Found</li>
                        }
                       </ul>
                    </div>
                    <div className = "cellSelectionBlockItemNames sm-scrollbar">
                       <ul>
                        {
                             itemData.length?itemData.map((d,i)=>{
                                return <li key={i} onClick={(e) => onClickPopupListItem(e, d) }>{d.itemName}</li>
                            }): <li key={0} >No Items Found</li>
                        }
                       </ul>
                    </div>
                </div>


                {
                    Boolean(state.isInvalidAccess) && (
                    <AlertModal
                        title={'Alert: Invalid Access'}
                        customModalStyle={ modelStyle }
                        description={ state.message }
                        onContinue={handleOnContinue}
                        onClose={handleOnContinue}
                    />
                    )
                }

            </div>
        </>
    );
}
