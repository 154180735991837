/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { APIEndpoint } from '../utils/axios';
import '../assets/styles/modal.scss'
import closeIcon from '../images/icons/Icon2.png'
import { useTranslation } from 'react-i18next';

export const Mapping = (props) => {
  const { t } = useTranslation();
  let itemData = [];
  if(props.itemList){
    itemData = props.itemList
  }
  let count = 0;

  let isError = false;
  let updateStatusText = '';

  if (props.modalFormStatusObj) {
    if ( ('text' in props.modalFormStatusObj)) {
      updateStatusText = props.modalFormStatusObj.text;
    }
    if ( ('error' in props.modalFormStatusObj)) {
      isError=props.modalFormStatusObj.error;
    }
  }

  return (
      <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar">
        <div className="dialog__content">
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">{props.data.title.itemName}</h2>
          <hr />
          <div className="dialog__footer flexRow justifyContentSpaceBetween flexWrap">
          <div className='width100'>
          <table id="mapping-table">
            <tr className='sticky'>
              <th width="200">Name</th>
              <th>Quantity</th>
              <th style={{width:"150px"}}>Important</th>
              <th style={{width:"100px"}}>Unit</th>
              <th style={{width:"120px"}}>Actions</th>
            </tr>
            {
            props.data.map_data.items.map((_,index) => {
              count = index;
              return (
              <tr id={`map`+index}>
                <input type="hidden" name={"itemId"+index} value={_.itemId}/>
                <input type="hidden" name={"itemType"+index} value={_.itemType}/>
                <td>
                <select name={"itemName"+index} className='select-input' onChange={props.handleChange}>
                  {
                    itemData.map((en) => {
                      if(_.itemName === en.itemName){
                        return (
                          <option value={en.itemName} key={en._id} selected>{en.itemName}</option>
                        )
                      }else{
                        return (
                          <option value={en.itemName} key={en._id}>{en.itemName}</option>
                        )
                      }
                      
                    })
                  }
                </select> 
                </td>
                <td><input type="number" name={"quantity"+index} className='edit-input' placeholder={_.quantity} onChange={props.handleChange}/></td>
                <td style={{width:"100px"}}>
                  <div className='dialog__description__checkbox'>
                    <input
                      id={"important"+index} 
                      name={"important"+index}
                      type='checkbox'
                      defaultChecked={_.important}
                      value={_.important}
                      onClick={props.handleChange}
                    />
                    <span id={"important-span"+index}> {_.important ? 'True' : 'False'}</span>
                  </div>
                </td>
                <td>{_.unit}</td>
                <td><button className='flexRow width100 hor-ctr space-btn menu-item-head' value={index} onClick={props.onDeleteMapping}>Delete</button>
                </td>
              </tr>
              )
              })
            }
          </table>
      </div>
          </div>
          {updateStatusText ?
          <>
            <div className={ "dialog__statusBlock " + (isError ? 'alert_error' : 'alert_success') }>
              {updateStatusText}
            </div>
          </> : null }
        </div>
        <div className="dialog__footer flexRow justifyContentSpaceEvenly pb-4">
          <button className="uploadImage-button" value={count} onClick={props.onAddServings}>{t('pages.productsContent.manageCombos.addServing')}</button>
          <button type="submit" value={props.data.title.itemId} className="done-button" onClick={props.onSubmit}>{t('pages.productsContent.manageCombos.saveChanges')}</button>
        </div>
      </div>
    </div>
  );
}
export const MappingModal = (props) => {
  const [itemList, setItemList] = useState(false);
  const [form, setForm] = useState([]);
  const [deleteArray, setDeleteArray] = useState([]);


  React.useEffect(() => {
    fetchItemList();
  }, []);

  const onSubmit = (e) => {
    let newForm = form.filter( (fieldValue, index) => {
      console.log(fieldValue.itemId)
      return fieldValue.itemId !== undefined
    });

    const mode=props.title;
    const updated = newForm;
    removeContainerOverlay();
    typeof (props.onSubmit) === 'function' && props.onSubmit(e, updated,mode)
    setDeleteArray([])
  }

  const onDeleteMapping = (i) => {
    const index_value = typeof(i) == 'number' ? i : i.target.value;
    deleteArray.push(index_value)
    if (index_value > -1) { 
      form[index_value] = {}; 
    }
    setForm(form)
    const css = document.querySelectorAll('#map'+index_value)[0];
    css.remove();
  }
  
  window.onDeleteMappingAdditional = (index) => {
    onDeleteMapping(index);
  }

  window.handleChangeAdditional = (data) => {
    let type = 'additional'
    handleChange(data,type);
  }
  
  let map_count = 0;
  const onAddServings = (e) => {
    map_count = e.target.value++;
    map_count = map_count + 1;

    let items = {
      itemId: null,
      itemName: null,
      itemType: null,
      quantity: null,
      important: false,
      unit: null,
    }
    form.push(items)

    const css = document.querySelectorAll('#mapping-table')[0];
    const element = document.createElement('tr');
    element.setAttribute("id", 'map'+map_count);
    css.appendChild(element).innerHTML= mappingInput(map_count);
  }

  const mappingInput=(index)=>{
    return (`
    <td>
      <input type="hidden" name=${`itemId`+index} />
      <input type="hidden" name=${`itemType`+index}/>
      <select id="mySelect" name=${`itemName`+index} class='select-input' onChange="handleChangeAdditional(this)">
        <option value="typing" key="typing"> -- Select Serving Name --</option>
        ${
          itemList.map((_) => {
            return (
              `<option value=${_.itemId} key=${_.itemId}>${_.itemName}</option>`
            )
          })
        }
      </select>  
    </td>
    <td><input type="number" name=${`quantity`+index} class='edit-input' onChange="handleChangeAdditional(this)" /></td>
    <td width="10%">
    <div class='dialog__description__checkbox'>
      <input
        id=${"important"+index} 
        name=${"important"+index}
        type='checkbox'
        defaultChecked="false"
        onClick="handleChangeAdditional(this)"
      />
      <span id=${"important-span"+index}>False</span>
    </div>
    </td>
    <td id=${"unit"+index}></td>
    <td><button class='flexRow width100 hor-ctr space-btn menu-item-head' onclick="onDeleteMappingAdditional(${index})"><u>Delete</u></button></td>
    `
    )
  }

  const fetchItemList = async () => {
    const itemListData = await APIEndpoint.get('items/enumerate');
    if (itemListData.items.length) {
      setItemList(itemListData.items)
      let form_data = props.data.map_data.items.map((en) => {
        let items = {
          itemId: en.itemId,
          itemName: en.itemName,
          itemType: en.itemType,
          quantity: en.quantity,
          important: en.important,
          unit: en.unit,
        }
        return items
      })
      setForm(form_data)
    }
  };

  const onClose = (e) => {
    removeContainerOverlay();
    props.onClose();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  var handleChange = (e,types="normal") => {
    let event;
    let target_value;

    //ADDITIONAL//
    if(types === 'additional'){
      event = e.name
      target_value = e.value

      const event_name = event.replace(/[0-9]/g, '');
      let event_index = Number(event.replace(/\D/g, ""));

      let document_index = event_index;
      if(props.data.map_data.items.length === 0){
        event_index = event_index - 1;
      }
     
      if(event_name === 'itemName'){
        const found = itemList.find(obj => {
          return obj.itemId === Number(target_value);
        });

        form[event_index]['itemId'] = Number(target_value);
        form[event_index]['itemName'] = found.itemName;
        form[event_index]['itemType'] = "Product";
        form[event_index]['unit'] = "NOS";

        let nos_unit = document.getElementById("unit"+document_index);
        nos_unit.innerText = "NOS"
      }

      if(event_name === 'important'){
        form[event_index]['important'] = !form[event_index]['important']

        let important_ui = document.getElementById("important-span"+document_index);
        form[event_index]['important'] ? important_ui.innerText =  "True" : important_ui.innerText = "False"

      }

      if(event_name === 'quantity'){
        form[event_index]['quantity'] = Number(target_value);
      }
    }
    //NORMAL//
    else{
      event = e.target.name
      target_value = e.target.value
  
      const event_name = event.replace(/[0-9]/g, '');
      const event_index = event.replace(/\D/g, "");
  
      if(event_name === 'itemName'){
        const found = itemList.find(obj => {
          return obj.itemName === target_value;
        });
        form[event_index]['itemId'] = found.itemId;
      }
      else if(event_name === 'important'){
        form[event_index]['important'] = !form[event_index]['important']

        let important_ui = document.getElementById("important-span"+event_index);
        let important_check = document.getElementById("important"+event_index);
        if(form[event_index]['important']){
          important_ui.innerText =  "True" 
          important_check.checked = true
        }else{
          important_ui.innerText = "False"
          important_check.checked = false
        }  
      }
      else{
        form[event_index][event_name] = target_value;
      }
    }
  }

  return (
    <Mapping
      data={props.data}
      onClose={onClose}
      onAddServings={onAddServings}
      onDeleteMapping={onDeleteMapping}
      itemList={itemList}
      handleChange={(e) => handleChange(e)}
      onSubmit={(e) => onSubmit(e)}
      modalFormStatusObj={props.modalFormStatusObj}>
    </Mapping>
  )
}