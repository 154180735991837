/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Spreadsheet from "react-spreadsheet"; // , { createEmptyMatrix }
import closeIcon from "../../../images/icons/Icon2.png"
import { APIEndpoint } from "../../../utils/axios";

import {AlertModal } from "../../../common";
import { updateButtonTxtStatus, validateCellAndGetData } from "../../../utils/commonFunctions";
import { DEFAULT_SHEET_ROWS } from "../../../utils/configs";

/**
 * Integration pending
 */
// function getModeOfReturns() {
//     return [
//         { key: 'Credit Note', value: 'Credit Note' },
//         { key: 'Delivery Challan', value: 'Delivery Challan' } 
//     ];
// };

const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    ITEM_UNIT: 2,
    ITEM_REQ_QTY: 3,
    ITEM_RECI_QTY: 4,
    ITEM_RETURNED_QTY: 5,
    ITEM_REVERSE_GRN: 6,
    ITEM_MODE_OF_RETURN: 7,
    ITEM_REASON: 8
};
const sheetLabels = ['Item Code', 'Item Name', 'Unit',
    'Requested Quantity', 'Received Quantity', 'Returned Quantity', 'Reverse GRN',
    'Mode of Return', 'Reason'];

const createEmptySheetRows = (noOfRows = DEFAULT_SHEET_ROWS) => {
    const rows = [];
    for( var i=0; i < noOfRows; i++) {
        rows.push([
            {
                id: 'itemCode',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '200px',
                readOnly: true,
                type: 'string',
                required: true
            },
            {
                id: 'itemName',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '200px',
                readOnly: true,
                type: 'string',
                required: true
            },
            {
                id: 'unit',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '400px',
                readOnly: true,
                type: 'string',
                required: true
            },
            {
                id: 'requestedQuantity',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '200px',
                readOnly: true,
                type: 'string',
                required: true
            },
            {
                id: 'receivedQuantity',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '200px',
                readOnly: true,
                type: 'string'
            },
            {
                id: 'returnedQuantity',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '200px',
                readOnly: true,
                type: 'string'
            },
            {
                id: 'reverseGRN',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '200px',
                type: 'number',
                required: true
            },
            {
                id: 'modeOfReturn',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '200px',
                required: true
            },
            {
                id: 'reason',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '200px',
                required: true
            }
        ]);
    }
    return rows;
}

/**
 * Automatically creating rows
 */
// const initialData = createEmptyMatrix(10, sheetLabels.length);
/**
 * Manually creating rows
 */
 const initialData = [];// createEmptySheetRows(DEFAULT_SHEET_ROWS - 1);

export const ReverseGRNCreate = (props) => {
    const urlParams = useParams();
    const navigate = useNavigate();

    const [reverseGRNDetails, setReverseGRNDetails] = useState({});
    const [state, setState] = useState({ isInvalidAccess: false, message: '' });

    const [sheetData, setSheetData] = useState(initialData);
    const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
    const modelStyle = { maxWidth: '50%', margin: 'auto 10% 100% 10%' };
    const [activeCell, setActiveCell] = useState({});
    const [selectedCellsArr, setSelectedCellsArr] = useState([]);

    useEffect( () => {
        if ( urlParams.hasOwnProperty('grnId') ) {
            console.log( 'grnId is ', urlParams.grnId );
            getLOIDetailsById(urlParams.grnId);
        }
    }, [ urlParams ]);

    const getLOIDetailsById = (grnId) => {
        APIEndpoint.get('purchaseOrders/grns/findById/' + grnId)
        .then( resp => {
            if (resp) {
                const lOIDetails = resp.data;
                setReverseGRNDetails(lOIDetails);
            }
        })
        .catch( error => {
            console.log('Invalid page access!, Reverse GRN details not found for given ID.', error.message);

            setState({ ...state, isInvalidAccess: true,
                message: "Invalid page access!, GRN details are not found, On click OK, you will be redirected to Purchase Orders page." });
        });
    };

    useEffect( () => {
        if (Object.keys(reverseGRNDetails).length > 0) {
            setReverseGRNItemsToSheet(reverseGRNDetails);
        }
    }, [reverseGRNDetails]);

    const setReverseGRNItemsToSheet = (reverseGRNDetails) => {
        if (Object.keys(reverseGRNDetails).length === 0) {
            return 0;
        }
        let updatedSpreadsheetData = Object.assign([], sheetData);
        const items = reverseGRNDetails.items;
        const itmLngth = items.length;
        /**
         * Validate whether required items already plotted
         */
        let newRows = [];
        if ( items && itmLngth > 0 && updatedSpreadsheetData.length < itmLngth ) {

            let newlyRequiredRows = itmLngth - updatedSpreadsheetData.length;
            if (newlyRequiredRows < DEFAULT_SHEET_ROWS) {
                newlyRequiredRows = DEFAULT_SHEET_ROWS;
            }
            newRows = createEmptySheetRows(newlyRequiredRows);

        } else {
            newRows = createEmptySheetRows(DEFAULT_SHEET_ROWS);
        }

        updatedSpreadsheetData = [ ...newRows ];

        updatedSpreadsheetData.forEach( (sheetRow, i) => {
            if ( items[i] ) {
                updatedSpreadsheetData[i][iIndexes.ITEM_CODE].value = items[i].itemCode;
                updatedSpreadsheetData[i][iIndexes.ITEM_NAME].value = items[i].itemName;
                updatedSpreadsheetData[i][iIndexes.ITEM_UNIT].value = items[i].unit;
                updatedSpreadsheetData[i][iIndexes.ITEM_REQ_QTY].value = items[i].requestedQuantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_RECI_QTY].value = items[i].receivedQuantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_RETURNED_QTY].value = items[i].returnedQuantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_REVERSE_GRN].value = '';
                updatedSpreadsheetData[i][iIndexes.ITEM_MODE_OF_RETURN].value = items[i].modeOfReturn;
                updatedSpreadsheetData[i][iIndexes.ITEM_REASON].value = items[i].reason || '';
                // sheetRef.setCellData(updatedSpreadsheetData[i]);
            }

        });

        // const updatedSpreadsheetData = sheetData.map( (row) => {
        //     const nextRow = [ ...row ];
        //     // nextRow.length = 10;
        //     nextRow.value = ;
        //     return nextRow;
        // } );

        setSheetData( [ ...updatedSpreadsheetData ]);

    };

    // useEffect( () => {
        // const updatedSpreadsheetData = sheetData.map( (row) => {
        //     const nextRow = [ ...row ];
        //     nextRow.length = 10;
        //     return nextRow;
        // } );
        // setSheetData(updatedSpreadsheetData);
    // }, [sheetData]);

    const addRowsToSheet = (rowsToAdd = 10) => {
        const newRows = createEmptySheetRows(rowsToAdd);
        setSheetData( [...sheetData, ...newRows ]);
    };

    const removeRow = (e) => {

        let inSheetData = Object.assign([], sheetData);
        /**
         * Remove a last row from the sheet
         */ 
        // setSheetData( (data) => {
        //     return data.slice(0, data.length -1)
        // });

        /**
         * Clear the content of the cell content
         */
        console.log('selectedCellsArr:', selectedCellsArr);

        selectedCellsArr.map( selectedCell => {

            const defaultVal = inSheetData[selectedCell.row][selectedCell.column].defaultValue;
            
            inSheetData[selectedCell.row][selectedCell.column].value = defaultVal || '';

            return selectedCell;
        });

        console.log('Selected cell updatedVal:', inSheetData);
        setSheetData([ ...inSheetData ]);
    };

    const showValidationErrors = (e, inSheetData, errorClass='cellDataErrorCls' ) => {
        let haveErrors = false;
        const postItems = [];
        
        inSheetData.map( (cellRow, rowIdx) => {
            
            let isRowHaveError = false;

            const itemCode = validateCellAndGetData(cellRow[iIndexes.ITEM_CODE]);
            const itemName = validateCellAndGetData(cellRow[iIndexes.ITEM_NAME]);

            if (!itemCode || !itemName ) {
                return cellRow;
            }

            for (let colIdx = 0; colIdx < Object.keys(iIndexes).length; colIdx++) {

                // console.log('cellData:', cellRow[colIdx]);
                
                if ( cellRow[colIdx].required === true) {

                    const fieldValue = validateCellAndGetData(cellRow[colIdx]);

                    if ( !fieldValue ) {

                        // cellRow[colIdx].className = errorClass;
                        haveErrors = true;
                        isRowHaveError = true;

                    }

                }

            }

            if ( !isRowHaveError ) {

                const item = reverseGRNDetails.items.find( row => row.itemCode === cellRow[iIndexes.ITEM_CODE].value );
                if (item) {
                    postItems.push({
                        _id: item._id,
                        index: rowIdx,
                        itemId: item.itemId,
                        itemCode: cellRow[iIndexes.ITEM_CODE].value,
                        itemName: cellRow[iIndexes.ITEM_NAME].value,
                        unit: cellRow[iIndexes.ITEM_UNIT].value,
                        requestedQuantity: parseInt(cellRow[iIndexes.ITEM_REQ_QTY].value),
                        receivedQuantity: parseInt(cellRow[iIndexes.ITEM_RECI_QTY].value),
                        reverseGrnQuantity: parseInt(cellRow[iIndexes.ITEM_REVERSE_GRN].value),
                        modeOfReturn: cellRow[iIndexes.ITEM_MODE_OF_RETURN].value,
                        remarks: item.remarks,
                        reason: cellRow[iIndexes.ITEM_REASON].value
                    });
                }
                
            }

            return cellRow;
        });

        if (haveErrors) {
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            setSpreadsheetErrors([ { message: 'Please fill all required item details.' } ]);
        }

        setSheetData( [ ...inSheetData ]);

        console.log('inSheetData after update:', inSheetData, postItems, haveErrors);
        return {
                sheetData: inSheetData, 
                validRows: postItems,
                haveErrors
            };

    };

    const saveSheetData = (e) => {
        updateButtonTxtStatus(true, e, 'Saving...', true);
        setSpreadsheetErrors([]);

        let inSheetData = Object.assign([], sheetData);

        const respArrayData = showValidationErrors(e, inSheetData);
        
        if (!spreadsheetErrors.length && respArrayData.validRows.length === 0) {
            // show global alert message
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'We did not find anything to save!' } ]);
            return false;
        }
        
        if ( !respArrayData.validRows.length ) {
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            return false;
        }


        const data = {
            id: urlParams.grnId || '',
            warehouseId: urlParams.warehouseId ? parseInt(urlParams.warehouseId) : '',
            grnId: urlParams.grnId || '',
            items: respArrayData.validRows || []
        }

        APIEndpoint.post('reversegrn/finalize333', data)
        .then( apiResp => {
            /**
             * Update success response
             */
            setSheetData(initialData);
            updateButtonTxtStatus(true, e, 'Redirecting...', true, { defaultButtonText: 'Save'} );
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiResp.msg || 'Successfully created reverse GRN.' } ]);
            setTimeout( () => {
                goToLOIHome();
            }, 4000);
        })
        .catch( (error) => {
            const apiData = error.data;

            console.log("Error occured:", apiData);
            if ( apiData.errors ) {
                const errorMsg = apiData.errors.errors[0].message;
                setSpreadsheetErrors([ ...spreadsheetErrors, { message: errorMsg, error: 1 } ]);
            }
            else if (apiData.msg) {
                setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiData.msg, error: 1 } ]);
            }
        });

        return false;
    };

    
    const handleActiveCellChange = (activeCell) => {
        console.log('ActiveCell:', activeCell);
        setActiveCell(activeCell);
    }
    const handleCellBlur = () => {
        console.log('CellBlur.');
        setActiveCell({});
    }

    const handleCellKeyDown = (keyboardEvent) => {
        console.log('keyDownEvent.', keyboardEvent.target.value );
        if (activeCell && Object.keys(activeCell).length > 0) {
            if (sheetData[activeCell.row][activeCell.column].type === 'number' &&  keyboardEvent.target.value ) {
                keyboardEvent.target.value = keyboardEvent.target.value.replace(/[^0-9]$/g, '');
            }
        }
        
        return false;
    }
    
    const handleModeChange = (mode) => {
        console.log('ModeCHanged to:', mode);
        // if (mode === 'edit') {
        //     setEditingCell
        // }
    };
    
    // const handleCellSelect = (selectedCell) => {
    //     console.log('SelectedCell:', selectedCell);
    //     setSelectedCellsArr([ ...selectedCell ]);
    // };

    const onCellCommitHandle = (prevCell, nextCell, coordinates) => {
        console.log('OnCellChangesCommit-prevCell:', prevCell, 'nextCell:', nextCell, 'coordinates:', coordinates)
    }
    const goToLOIHome = () => {
        navigate('/procurement/purchase-orders');
    };
    const handleOnContinue = async () => {
        goToLOIHome();
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                <div className="sheet-container sm-scrollbar">
                    <div className="header m-2 mb-2 flexRow justifyContentSpaceBetween">
                        <div className="leftBlock alignItemsCenter">
                            <button onClick={ (e) => saveSheetData(e) }
                                className="add-item-button m-3">Save</button>
                            <button onClick={ (e) => removeRow(e) }
                                className="remove-button">Delete Row</button>
                        </div>
                        <div className="rightBlock alignItemsCenter">
                            {spreadsheetErrors.length >= 1 ? spreadsheetErrors[0].message : null}
                        </div>
                        <button onClick={goToLOIHome}  >
                            <img src={closeIcon} alt=""/>
                        </button>
                    </div>
                    {/* Entered data: { activeCell && Object.keys(activeCell).length > 0 ? 
                        sheetData[activeCell.row][activeCell.column].type : null }

                        { JSON.stringify(selectedCellsArr) } */}
                    <Spreadsheet
                        // ref={sheetRef}
                        data={sheetData}
                        columnLabels={sheetLabels}
                        darkMode={true}

                        // getBindingsForCell={[]}
                        onChange={setSheetData}
                        onActivate={handleActiveCellChange}
                        onBlur={handleCellBlur}
                        onKeyDown={handleCellKeyDown}
                        onModeChange={handleModeChange}
                        onSelect={setSelectedCellsArr}
                        onCellCommit={onCellCommitHandle}
                        // Cell={LOICELLComponent}
                        // DataViewer={LOIViewComponent}

                    />

                    <div className="m-3 pb-5 flexRow justifyContentFlexStart alignItemsCenter">
                        <button onClick={ () => addRowsToSheet() }
                            className="add-item-button">Add Row</button> &nbsp;<label>10 row at bottom</label>
                    </div>

                </div>
                

                {
                    Boolean(state.isInvalidAccess) && (
                    <AlertModal
                        title={'Alert: Invalid Access'}
                        customModalStyle={ modelStyle }
                        description={ state.message }
                        onContinue={handleOnContinue}
                        onClose={handleOnContinue}
                    />
                    )
                }

            </div>
        </>
    );
}