import { FaArrowRight } from 'react-icons/fa6';

const getSectorDetails = (row) => {
    if (row) {
      
      return (
        <>
          { row.flightNumber ?? '' } | { row.origin ?? ''} <FaArrowRight color={'DC143C'} size={16} /> { row.destination ?? ''}
        </>
      )
    }
    return '';
};

export const SectorRenderer = (props) => {
    const cellValue = getSectorDetails(props.data);// props.valueFormatted ? props.valueFormatted : props.value;
    return (
        <div className="sector-renderer">
            {cellValue}
        </div>
    );
};