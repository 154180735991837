import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';


export const RosterPage = (props) => {
 // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  useEffect(() => {
  }, [])

  return (
    <div className="flexCol width100 ag-theme-alpine-dark cash-reg">
      <div className='flexCol full-flex'>
            <div className='flexCol full-flex alignItemsCenter justifyContentCenter'>
              No Data Available
            </div>
      </div>
    </div>
  )
}