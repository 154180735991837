import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Card } from "./Cards";

export const CateringCatering = (props) => {
  const { show, selectedGroup, groups } = props
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({ cateringGroup: null })
  const { t } = useTranslation()
  const getCateringGroups = () => {
    let cateringGroups = [];
    if (selectedGroup) {
      groups.every((g) => {
        let shouldContinue = true;
        if (selectedGroup.id === 'root') {
          cateringGroups = cateringGroups.concat(g.children)
        } else if (selectedGroup.id === g.id) {
          cateringGroups = cateringGroups.concat(selectedGroup.children);
          shouldContinue = false
        }
        else {
          let cg = g.children.find((chg) => chg.id === selectedGroup.id);
          if (cg) {
            shouldContinue = false
            cateringGroups.push(cg)
          }
        }
        return shouldContinue
      })
    }
    return cateringGroups
  }
  const cateringGroups = getCateringGroups()
  return (
    <div className={`full-flex pad scrollHide ${show ? 'flexCol' : 'hide'}`}>
      {
        !Boolean(selectedGroup) &&
        <div className="flexCol flex-ctr hor-ctr full">{t(`pages.controlPanel.views.selectGroup`)} </div>
      }
      {
        Boolean(selectedGroup) &&
        <div className="flexCol full scrollHide">
          <h3 className="h4">
            {t(`pages.controlPanel.views.catering.cateringHeading`)}
            {Boolean(selectedGroup.model && selectedGroup.model.locationCode) && <code style={{ marginLeft: 16 }}>{selectedGroup.model.locationCode}</code>}
          </h3>
          <div className="flexCol full-flex card-list scrollY">
            {
              cateringGroups.map((group, i) => {
                return (
                  <Card key={group.id} group={group} slNo={i + 1} onClick={() => props.onCateringCard(group)} />
                )
              })
            }
          </div>
        </div>
      }
    </div>
  )
}