/* eslint-disable react-hooks/exhaustive-deps */

import React,{useEffect,useState,useRef} from 'react'
import moment from 'moment';
import { APIEndpoint,DFPAPIEndpoint } from '../../../../utils/axios';
export const  EventLogContainer=(props)=> {
  const{id}=props;

const [eventsLog,setEventsLog]=useState([])
const containerRef = useRef();

const sType = localStorage.getItem('sType');
const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);
  const filterData = {
    page: 1,
    limit: 20,
    filters: []
}

useEffect(() => {
  const updateHeight = () => {
    const availableHeight = window.innerHeight * 0.55;
    if (containerRef.current) {
      containerRef.current.style.maxHeight = `${availableHeight}px`;
    }
  };

  updateHeight();
  window.addEventListener('resize', updateHeight);

  return () => {
    window.removeEventListener('resize', updateHeight);
  };
}, []);

useEffect(() => {
  if(id){
    fetchOperationsPanel(id,1,20);
  }
 }, [id]);
  const fetchOperationsPanel = async (sectId,page=1, perPage=10) => {
    filterData.page = page;
    filterData.limit = perPage;

try {
  const res = await DOMAIN.post(`sandboxing/containerIsolates/${sectId}/events`, JSON.stringify(filterData));
   if(res.items.length>=0){
    setEventsLog(res.items)
   }
} catch (error) {
  console.log("object",error);

}

  }
  const eventLogView=(events)=>{
const listItems = events?.map((list,index) =>
{
  return(<React.Fragment key={index}>
    <div>
      <li className="list-group-item">
        <div className='row'>
          <div className='col-xs-11'>
            <p className='margBottom5'><strong>{getEventConfigMain(list.name)}</strong>&nbsp;<span className='code'>{list.target.name}</span></p>
            <div  className='flexRow justifyContentSpaceBetween'>
            <p className='margBottom5'>{moment(list.timestamp).format('DD-MM-YYYY  hh:mm A')}</p>
           {list.description? <p>{list.description}</p>:<></>}

            </div>
            <p className='margBottom5'>{list.userAgent.appName}@{list.userAgent.appVersion}</p>
            <p className='margBottom5'>{list.userAgent.deviceModel},MAC ID:{list.userAgent.deviceName}</p>


          </div>
        </div>
      </li>
    </div>
  </React.Fragment>)}
 );
 return listItems
  }
const getEventConfig=(st)=>{
const resp=st.split(".");
if(resp[0].includes('isolate')&&!resp[1].includes('sector')){
  return <span style={{ color: '#70CEEA',fontSize:'1rem' }}>Instance {resp[1].charAt(0).toUpperCase()+resp[1].slice(1)}</span>;
}else{
  return <span style={{ color: '#70CEEA',fontSize:'1rem'  }}>{resp[1].replace(/([A-Z])/g, ' $1').trim()}</span>;

}
}
const getEventConfigMain=(st)=>{
  const resp=st.split(".");
  if(st.length<15){
    return <span style={{ color: '#70CEEA',fontSize:'1rem' }}> {resp[0].charAt(0).toUpperCase()+resp[0].slice(1)} {resp[1].charAt(0).toUpperCase()+resp[1].slice(1)}</span>;
  }else{
    return <span style={{ color: '#70CEEA',fontSize:'1rem'  }}>{resp[1].split(/(?=[A-Z])/).map(wrd=>wrd!=='Isolate'?wrd.charAt(0).toUpperCase() + wrd.slice(1):"Instance").join(' ')}</span>;

  }
  }
  const eventlogsItems=()=>{
    const listItems = eventsLog.map((list,index) =>
{
  return(<React.Fragment key={index}>
    <div>
      <li className="list-group-item">
        <div className='row'>
          <div className='col-xs-11'>
          <p className='margBottom5'><strong>{getEventConfig(list.name)}</strong>&nbsp;<span className='code'>{list.target.name}</span></p>
            <div  className='flexRow justifyContentSpaceBetween'>
            <p className='margBottom5'>{moment(list.timestamp).format('DD-MM-YYYY  hh:mm A')}</p>
           {list.description? <p>{list.description}</p>:<></>}

            </div>
            <p className='margBottom5'>{list.userAgent.appName}@{list.userAgent.appVersion}</p>
            <p className='margBottom5'>{list.userAgent.deviceModel},{list.userAgent.deviceName}</p>


          </div>
        </div>
      </li>
    </div>
  </React.Fragment>)}
 );
 return listItems
  }



  return (
          <div ref={containerRef} className={props?.data?"width100 dialog__body_container flexRow justifyContentSpaceBetween flexWrap sm-scrollbar":"dialog__body_container flexRow justifyContentSpaceBetween flexWrap sm-scrollbar"} style={{overflowY:'scroll'}}>
            <ul className='list-group'>
                {eventlogsItems().length?eventlogsItems():eventLogView(props?.data)}
                </ul>
          </div>



  );
}