export const themesList = [
    { title: 'Blue', name: 'blue', keyname: 'themeblue' },
    { title: 'Dark', name: 'dark', keyname: 'themedark' },
    // { title: 'Purple', name: 'purple', keyname: 'themepurple' },
    // { title: 'Light', name: 'light', keyname: 'themelight' },
];
export const DEFAULT_THEME_INDEX = 0;

export const setTheme = (theme = themesList[0]) => {
    console.log("Log: Setting theme.");
    document.documentElement.className = '';
    document.documentElement.classList.add(`theme-${ theme.keyname }`);
    localStorage.setItem('theme', JSON.stringify(theme) );
    console.log("Log: Done setting theme.");
}

export const getCurrentThemeName = () => {
    try {
        const classList = document.documentElement.className.split(' ');
        const themeClassFull = classList.find( (value, index) => value.includes('theme-'));
        return themeClassFull.split('-')[1];
    }
    catch(e) {
        console.log("Log: Error while fetching theme - There is no default theme set");
        setTheme(themesList[0]);
        return themesList[0];
    }
}

export const getCurrentThemeObj = () => {
    try {
        const themeName = getCurrentThemeName();
        const themeObj = themesList.find( (value, index) => value.keyname === themeName);
        return themeObj;
    }
    catch(e) {
        console.log("Log: Error while fetching theme object - Invalid theme defined");
        setTheme(themesList[0]);
        return themesList[0];
    }

}