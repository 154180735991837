/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import ReactSpreadsheet from "react-spreadsheet"; // , { createEmptyMatrix }
import { useTranslation } from 'react-i18next';
import { Modal } from "./Modal";
import { Loading } from './Loading';
import { IconButton } from "./Buttons";

const SpreadsheetDataViewer = (props) => {
  const { cell } = props
  return (
    <div className={`data-viewer ${cell && cell.error ? 'error' : ""}`}>
      <span>{cell ? cell.value : ''}</span>
    </div>
  )
}

const SpreadsheetEditor = (props) => {
  const [state, setState] = useState({ search: props.cell ? props.cell.value : ''})

  const { colDef } = props
  const ref = useRef(null)
  const handleAutomaticFocus = () => {
    let timerId = setInterval(() => {
      if (ref.current) {
        ref.current.focus();
        clearInterval(timerId);
      }
    }, 100)
  }
  useEffect(() => {
    handleAutomaticFocus()
  }, [])
  useEffect(() => {
    return () => {
      if (!ref.current) {
        props.onChange({ value: state.search, updated: true, error: isError() })
      }
    }
  }, [state.search])


  const getOptions = () => {
    if (state.search && colDef.type === 'select') {
      return colDef.options.filter((_) => {
        return _[colDef.labelProp].toLowerCase().includes(state.search.toLowerCase());
      })
    }
    return colDef.options;
  }
  const handleOnChange = (e) => {
    setState((_) => ({ ..._, search: e.target.value }))
  }
  const handleClick = (e, _) => {
    let cellVal = { updated: true }
    if (_) {
      cellVal.value = _[colDef.labelProp]
    } else {
      cellVal.value = state.search;
      cellVal.error = true
    }
    props.onChange(cellVal)
    props.exitEditMode()
  }
  const isError = () => {
    return colDef.type === 'number' && state.search !== '' && Number(state.search) < 1
  }
  const handleBlur = () => {
    props.onChange({ value: state.search, error: isError() })
    props.exitEditMode()
  }
  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && ref.current) {
      ref.current.blur();
      handleBlur()
    }
  }
  const list = getOptions()
  return (
    <div className='data-editor'>
      {
        colDef.type === 'select' ?
          <>
            <input style={{color:"#fff"}} ref={ref} type="text" placeholder='' onChange={handleOnChange} value={state.search} />
            <ul className="drop-down">
              {
                list.length === 0 ?
                  <li className='not-found' onClick={(e) => handleClick(e, null)}>No items Found</li>
                  :
                  list.map((_, i) => {
                    return <li key={i} onClick={(e) => handleClick(e, _)}>{_[colDef.labelProp]}</li>
                  })
              }
            </ul>
          </>
          :
          <input
          style={{backgroundColor:"#000",color:"#fff"}}
            onBlur={handleBlur} onKeyDown={handleKeyDown} ref={ref} type={colDef.type || 'text'} placeholder=''
            value={state.search} onChange={handleOnChange} min={1} />
      }
    </div>
  )
}

export const Spreadsheet = (props) => {
  const { t } = useTranslation();
  // const selectedCell = useRef([])
  const [state, setState] = useState({ rows: []})
  const columns = props.columns.map((_) => t(_.label))
  useEffect(() => {
    createEmptyCellWithData(props.data, 50)
  }, [props.data]);

  const createEmptyCellWithData = (currentData, rowCount) => {
    const rows = [];

    if (currentData.length > 0) {

      currentData.forEach((data) => {
        let row = props.getRow(data)
        rows.push(row);
      });

      for (let i = 0; i < 10; i++) {
        let row = []
        props.columns.forEach((col) => {
          row.push({ value:'' })
        })
        rows.push(row)
      }

      setState((_) => ({ ..._, rows: [ ...rows ] }))
    }
    else {
      const addRow = (data,rowIndex) => {
          const row = [];
          props.columns.forEach((col,colIndex) => {
            row.push({ value: (data && data[col.attribute]) || '',className:`cellClass_${rowIndex}_${colIndex}` })
          })
          rows.push(row)
      };

      let len = rowCount - rows.length;
      for (let index = 0; index < len; index++) {
        addRow(null,index)
      }

      setState((_) => ({ ..._, rows: [..._.rows, ...rows] }))
    }

  }

  const handleCellChange = (updated) => {
    let updatedFormat = updated.map((row) => {
      return props.getRow(row)
    })
    setState((_) => ({ ..._, rows: [...updatedFormat], message: null }))
  }
  const handleSave = async () => {

    // let rowsToSubmit = [], rows = [...state.rows]
    // let valid = rows.every((row, i) => {
    //   let emptyCount = 0, reqEmptyCount = 0, rowObj = { index: i }
    //   props.columns.forEach((colDef, j) => {
    //     if (!row[j].value) {
    //       emptyCount++;
    //       if (colDef.required) {
    //         reqEmptyCount++;
    //       }
    //     } else {
    //       rowObj[colDef.attribute] = row[j].value;
    //       if (colDef.type === 'number') {
    //         rowObj[colDef.attribute] = Number(rowObj[colDef.attribute])
    //       }
    //     }
    //   })
    //   // if Empty Row
    //   let isEmptyRow = emptyCount === props.columns.length
    //   if (isEmptyRow) {
    //     return true
    //   }
    //   // one of the field is required
    //   if (reqEmptyCount > 0) {
    //     rows[i] = rows[i].map((cell) => {
    //       return { ...cell, error: !Boolean(cell.value) }
    //     })
    //     return false
    //   }

    //   rowsToSubmit.push(rowObj);
    //   return true
    // });
    // if (!valid) {
    //   return setState((_) => ({ ..._, rows: [...rows], message: { text: t("spreadsheet.contentError"), success: false } }))
    // }
    try {
      setState((_) => ({ ..._, loading: true, message: null }));
      const res = await props.save(state.rows);
      setState((_) => ({
        ..._,
        saveRecon: false,
        loading: false,
        message: { text: res?.message || t("spreadsheet.savedSuccess"), success: true },
      }));

      props.onSave && props.onSave(res);
    } catch (error) {
      const parseErr=JSON.parse(error.message);
      if (parseErr&&parseErr?.data) {
        setState((_) => ({
          ..._,
          message: { text: parseErr.data.message, error: parseErr.data.errors, success: false },
          loading: false,
        }));
        setErrorFields(parseErr?.data?.errors);
      } else {
        setState((_) => ({
          ..._,
          message: { text: parseErr.data.message, success: false },
          loading: false,
        }));
      }
    }


  }
const setErrorFields=(errData)=>{
  if(errData){

    const errorFields = errData.map(error => error.field);

    const modifiedRows = state.rows.map((row, rowIndex) =>

    row.map((cell, columnIndex) => {
      if (errorFields.includes('itemCode') && columnIndex === 0) {
        return { ...cell, error: true };
      }
      if (errorFields.includes('itemName') && columnIndex === 1) {

        return { ...cell, error: true };
      }
      return cell;
    })
  );
  setState((_) => ({ ..._,rows:modifiedRows}))
  }

}


  const handleDeleted = () => {
    if(state.selectedCell){
        let sortedCell = state.selectedCell.sort((a, b) =>  b.row - a.row);

        let newRows = state.rows.filter((_, index) => {

          return !sortedCell.some(cell => cell.row === index);
      });
        setState((_) => ({ ..._, rows: newRows }))
    }
  }

  const handleCellSelect = (_selectedCell) => {
    if (_selectedCell.length !== 0) {
      setState((_) => ({ ..._, selectedCell: _selectedCell }))
    }
  }
  return (
    <div className={`flexCol spread-sheet-container ${props.className || ''}`}>
      <div className='flexRow alignItemsCenter actions justifyContentSpaceBetween'>
        <div className='flexRow'>
          <IconButton label="Save" className='btn btn-primary' onClick={handleSave} />
          <IconButton label="Delete" disabled={!Boolean(state.selectedCell)} className='btn btn-danger' onClick={handleDeleted} />
        </div>
        {
          Boolean(state.message) &&
          <div className={`cell-alert ${state.message.success ? 'success' : ''}`}>
            {state.message.text}
          </div>
        }
         {
          Boolean(!props.data) &&
          <div className={`cell-alert success`}>
          <Loading size={"40px"} label={"Prefetching field values"} className={"custom-loading"} />
          </div>
        }
      </div>
      <ReactSpreadsheet
        className='spread-sheet'
        columnLabels={columns}
        // onSelect={props.onSelect}
        onSelect={handleCellSelect}
        DataEditor={(p) => <SpreadsheetEditor colDef={props.columns[p.column]} {...p} />}
        // DataViewer={(p) => <SpreadsheetDataViewer colDef={props.columns[p.column]} {...p} />}
        onChange={handleCellChange}
        data={state.rows}
        darkMode={props.darkMode || true}
      />
      <div className='flexRow alignItemsCenter'>
        <IconButton label={ t('buttons.addRows') } className="btn btn-info" title={ t('spreadsheet.addmoreRows') } onClick={() => createEmptyCellWithData(null, 10)} />
      </div>
    </div>
  )
}
export const SpreadsheetModal = (props) => {

  const { isSubModal, showDone, className, onClose, title, ...rest } = props
  return (
    <Modal
      isSubModal={isSubModal}
      showDone={showDone}
      onClose={onClose}
      title={title}
      className={`spreadsheet-modal ${className || ''}`}>
      <Spreadsheet {...rest} />
    </Modal>
  )
}
