import React, { useState } from 'react'
import { DataGrid, Input, NGODatePicker } from '../../../common';
import searchIcon from '../../../assets/icons/search.svg';
import usePermissions from '../../../utils/userPermissions';
import mockData from "../../../assets/json/cardreconciliationData.json"
import moment from 'moment';
import { getDateFromTimezone } from './Cards';
const Columns = [
  {field: "date",headerName: "Flight Date",flex:1,minWidth:100},
  {field: "ftno",headerName: "Flight No",flex:1,minWidth:90},
  {field: "txnDate",headerName: "Txn Datetime",flex:1,minWidth:150,valueGetter: params => {
    return params.data ? moment(params.data?.txnDate).format('DD-MM-YYYY hh:mm A') : '';
}},
  {field: "sector",headerName: "Sector",flex:1,minWidth:80},
  {field: "pnr",headerName: "PNR",flex:1,minWidth:80},
  {field: "seatNumber",headerName: "Seat No",flex:1,minWidth:80},
  {field: "paxName",headerName: "Pax Name",flex:1,minWidth:100},
  // {field: "mobileNumber",headerName: "Mobile Number",flex:1,minWidth:150},
  // {field: "emailId",headerName: "Email ID",flex:1,minWidth:180},
  {field: "cardNo",headerName: "Card No",flex:1,minWidth:120},
  {field: "currency",headerName: "Currency",flex:1,minWidth:90},
  {field: "amount",headerName: "Txn Amount",flex:1,minWidth:100},
  {field: "amountCredited",headerName: "Amount Credited",flex:1,minWidth:130, valueGetter:(params)=>{
    return params?.data?.status === "Completed" ? params?.data?.amount:"0.00"
  }},
  {field: "status",headerName: "Payment Status",flex:1,minWidth:120},
  {field: "invoiceId",headerName: "Order No",flex:1,minWidth:100},
  {field: "orderStatus",headerName: "Order Status",flex:1,minWidth:100},
  {field: "bankTransId",headerName: "Bank Ref.ID",flex:1,minWidth:100},
  {field: "comment",headerName: "Comment",flex:1,minWidth:150},
  // {field: "reTryDate",headerName: "Re.Try Date",flex:1,minWidth:100},
  // {field: "fee",headerName: "Fee",flex:1,minWidth:100},
]

export const CardReconciliation = () => {
  const [data, setData] = useState(mockData)
  const [filterData, setFilterData] = useState({
    flightDate:"",
    flightNumber:"",
    pnrNumber:"",
    orderNumber:""
  })
  const { hasPermission: viewCardRecon } = usePermissions('view', "card-reconciliation");

  function isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  const handleInputChange = (e) => {
    if(!e.target.value){
      setFilterData(prev=>({...prev,flightDate:new Date()}))
      return;
    }
    let { name, value } = e.target;
    if(!isValidDate(value)) return;
    if (name === "flightDate") {
      let dataVal = getDateFromTimezone(value).toDate();
      setFilterData((prev) => {
        return { ...prev, flightDate: dataVal };
      });
    }
  };
  function onSearchInpChange(e){
    let { name, value } = e.target;
    setFilterData(prev=>({...prev,[name]:value}))
  }
  return (
    <div className="flexCol full-flex ag-theme-alpine-dark">
      <div
          className="flex flexRow alignItemsCenter"
        >
          <div className='felxCol m-2'>
            <NGODatePicker
              value={filterData.flightDate}
              name='flightDate'
              onChange={handleInputChange}
              placeholder="Flight Date"
              isClearable={true}
            />
          </div>
          <div className='flexCol m-2'>
          <div className='operations-in'>
            <input 
              className="search-crew" 
              onChange={onSearchInpChange} 
              type="search" 
              name='flightNumber' 
              placeholder="Search Flight Number" 
              value={filterData.flightNumber||null}
            />
          </div>
          </div>
          
          
          <div className="flexCol m-2">
          <div className='operations-in'>
            <input 
             name='pnrNumber'
             className="search-crew" 
             onChange={onSearchInpChange} 
             type="search" 
             placeholder="Search PNR Number" 
             value={filterData.pnrNumber||null}
            />
          </div>
          </div>
          <div className="flexCol m-2">
          <div className='operations-in'>
            <input 
             name='orderNumber'
             className="search-crew" 
             onChange={onSearchInpChange} 
             type="search" 
             placeholder="Search Order Number" 
             value={filterData.pnrNumber||null}
            />
          </div>
          </div>
        </div>

    {viewCardRecon?<DataGrid
        className="full-flex"
        columns={[...Columns]}
        showDefaultFilters={false}
        rowModelType="clientSide"
        rowData={data}
        columnResize={true}
        rowSelection="multiple"
      />:<DataGrid
      className="full-flex"
      columns={[...Columns]}
      showDefaultFilters={false}
      rowModelType="clientSide"
      rowData={[]}
      columnResize={true}
      rowSelection="multiple"
    />}
    </div>
  );
}