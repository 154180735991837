import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { BankTransferCreate, CashBankTransferPage, CashDepositCreate, CashDepositsPage, CashDrawersPage } from "./inner-components";

export const CashRegisterScreen = (props) => {
  return (
    <div className="flexRow full p-0 m-0">
      <div className='full-flex flexCol width100 p-0 m-0'>
        <Routes>
          <Route path="/" element={<Navigate replace to="cashDrawers" />} />
          <Route path="/cashDrawers" element={<CashDrawersPage />} />
          <Route path="/bankTransfers" element={<CashBankTransferPage />} />
          <Route path="/bankTransfers/create" element={<BankTransferCreate />} />
          <Route path="/cashDeposits" element={<CashDepositsPage />} />
          <Route path="/cashDeposits/create" element={<CashDepositCreate />} />
        </Routes>
      </div>
    </div>
  )
}
