import { useState,useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import LoadingGIF from '../assets/loadingIn.gif';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ReactPdfViewer(props) {
  const [fileHolder, setFileHolder] = useState(null);
  const [pageWidth, setPageWidth] = useState(null);
  const [pageHeight, setPageHeight] = useState(null);

 useEffect(() => {
  if(props.fileHolder){
    setFileHolder(props.fileHolder)
  }
 }, [props])

  function onDocumentLoadSuccess(pdf) {
    const { numPages } = pdf._pdfInfo;
    props.setNumPages(numPages);
    props.setPageNumber(1);
    loadPageDimensions(pdf, 1)
    props.setPageNumber(1);

  }
  const loadPageDimensions=async(pdf, pageNumber)=> {
    const page = await pdf.getPage(pageNumber);
    const viewport = page.getViewport({ scale: 1 });
    setPageWidth(viewport.width);
    setPageHeight(viewport.height);
  }

  function calculateScale(containerWidth, containerHeight) {
    if (!pageWidth || !pageHeight || !containerWidth || !containerHeight) {
      return 1;
    }

    const scaleWidth = containerWidth / pageWidth;
    const scaleHeight = containerHeight / pageHeight;
    return Math.min(scaleWidth, scaleHeight);
  }

  return (
    <div className="container-pdf-view all-page-container width50 sm-scrollbar">
      <div className="flexRow justifyContentCenter alignItemsCenter textAlignCenter margBottom10">{props.fileHolder?.name||""}</div>
        <Document
          file={fileHolder}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<div className="flexRow justifyContentCenter alignItemsCenter" ><span style={{marginTop:'20vh'}}><img src={LoadingGIF} className='icon-size' style={{width:'25px',height:'25px'}} alt="Loading" />Loading ...</span></div>}
        >
           <Page
          key={`page_${props.pageNumber}`}
          pageNumber={props.pageNumber}
          scale={calculateScale(pageWidth, pageHeight)}
        />
        </Document>
    </div>
  );
}