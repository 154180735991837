/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useParams ,useNavigate} from 'react-router-dom';
import Spreadsheet, { createEmptyMatrix } from "react-spreadsheet";
import { APIEndpoint ,DFPAPIEndpoint} from "../../../utils/axios";
import { updateButtonTxtStatus, validateCellAndGetData } from "../../../utils/commonFunctions";
import closeIcon from "../../../images/icons/Icon2.png";
import * as ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";
import { DEFAULT_SHEET_ROWS, UPLOADED_LOI_ATTACHMENT_KEY } from "../../../utils/configs";

let spreadSheetErrTimout;

const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    ITEM_UNIT: 2,
    ITEM_REQ_QTY: 3,
    ITEM_RECI_QTY: 4,
    ITEM_QTY: 5,
    ITEM_REMARKS: 6
};
const sType = localStorage.getItem('sType');
const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);
const grnSheetLabels = ['Item Code', 'Item Name', 'Unit', 'Requested Quantity', 'Received Quantity', 'Quantity', 'Remarks'];

/**
 * Automatically creating rows
 */
// const initialData = createEmptyMatrix(10, grnSheetLabels.length);
/**
 * Manually creating rows
 */
const createEmptySheetRows = (noOfRows = DEFAULT_SHEET_ROWS, grnSheetData) => {
let len = grnSheetData ? +grnSheetData.length:0
const initialData = [];
for( var i=0; i < noOfRows; i++) {
    initialData.push([
        {
            id: 'itemCode',
            value: '',
            className: 'cellClass cellClass_' + len + '_' + iIndexes.ITEM_CODE,
            width: '200px',
            showPopup: true
        },
        {
            id: 'itemName',
            value: '',
            className: 'cellClass cellClass_' + len + '_' + iIndexes.ITEM_NAME,
            width: '200px',
            showPopup: true
        },
        {
            id: 'unit',
            value: '',
            className: 'cellClass1',
            width: '400px',
            readOnly: false
        },
        {
            id: 'requestedQty',
            value: '',
            className: 'cellClass1',
            width: '200px',
            readOnly: false,
        },
        {
            id: 'receivedQty',
            value: '',
            className: 'cellClass1',
            width: '200px',
            readOnly: false
        },
        {
            id: 'quantity',
            value: '',
            className: 'cellClass1',
            width: '200px'
        },
        {
            id: 'remarks',
            value: '',
            className: 'cellClass1',
            width: '200px'
        }
    ]);
    len++
}
return initialData;
}
const initialData = [];// createEmptySheetRows(DEFAULT_SHEET_ROWS - 1);

const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 100,
    page: 1,
    searchParam: ''
  };

export const GRNForPurchaseOrderCreate = (props) => {
    const urlParams = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation(); 
    const { warehouseId, purchaseOrderId, id: poId, invoiceInputs } = urlParams;
    const invoiceObj = JSON.parse(invoiceInputs);
    const [itemData, setItemData] = useState([])
    const [grnSheetData, setGrnSheetData] = useState(createEmptySheetRows( DEFAULT_SHEET_ROWS ));
    const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
    const [activeCell, setActiveCell] = useState([])

    const targetEltCodes = document.getElementsByClassName("cellSelectionBlockItemCodes")[0];
    const targetEltNames = document.getElementsByClassName("cellSelectionBlockItemNames")[0];

    useEffect( () => {
        // const updatedSpreadsheetData = grnSheetData.map( (row) => {
        //     const nextRow = [ ...row ];
        //     nextRow.length = 10;
        //     return nextRow;
        // } );

        // setGrnSheetData(updatedSpreadsheetData);
    }, [grnSheetData]);

    const goBack = () => {
        navigate(-1); //navigates back to the previous page
    };
    useEffect(()=>{
        getItemData()
    },[])

    //Api call for fetch purchase order items

    async function getItemData() {
        try {
        const res = await DOMAIN.post('indents/process',JSON.stringify(filterData))
        const purchase_items = res.items.flatMap(itemGroup => itemGroup.items);
        console.log(purchase_items,"flattenedItems");
        setItemData(purchase_items);
        } catch (error) {
            console.log(error)
        }
    }

    const addRowsToSheet = (rowsToAdd = 10) => {
        const newRows = createEmptySheetRows(rowsToAdd,grnSheetData);
        setGrnSheetData([...grnSheetData,...newRows])
    };

    const removeRow = () => {
        hideItemListPopup();
        let jsonSpreadsheetData = JSON.stringify(grnSheetData);
        let inSheetData = JSON.parse(jsonSpreadsheetData);
        if (Object.keys(activeCell).length === 0) {
          inSheetData.pop();
          setGrnSheetData([...inSheetData]);
        } else {
          let selectedRowIndex = activeCell.row;
          if (selectedRowIndex || selectedRowIndex === 0) {
            inSheetData.splice(selectedRowIndex, 1);
            setGrnSheetData([...inSheetData]);
          }
        }
      }
  
    // save create grn 

    const saveSheetData = (e) => {
        clearTimeout(spreadSheetErrTimout)
        updateButtonTxtStatus(true, e, 'Saving...', true);
        const postItems = [];

        let inSheetData = [ ...grnSheetData ];
        inSheetData.map( (cellRow, idx) => {
            const itemCode = validateCellAndGetData(cellRow[iIndexes.ITEM_CODE]);
            const itemName = validateCellAndGetData(cellRow[iIndexes.ITEM_NAME]);
            const unit = validateCellAndGetData(cellRow[iIndexes.ITEM_UNIT]);
            const requestedQty = validateCellAndGetData(cellRow[iIndexes.ITEM_REQ_QTY]);
            const receivedQty = validateCellAndGetData(cellRow[iIndexes.ITEM_RECI_QTY]);
            const quantity = validateCellAndGetData(cellRow[iIndexes.ITEM_QTY]);
            const remarks = validateCellAndGetData(cellRow[iIndexes.ITEM_REMARKS]);

            
            if (itemCode && itemName && unit && requestedQty && receivedQty && quantity && remarks) {
                postItems.push({
                    itemCode,
                    itemName,
                    unit,
                    requestedQuantity: parseInt(requestedQty),
                    receivedQuantity: parseInt(receivedQty),
                    quantity: parseInt(quantity),
                    remarks
                });
            }
            else if (itemCode || itemName) {
                /**
                 * SET Error status
                 */
                // cellRow[iIndexes.ITEM_UNIT].className += ' errorCellData';
                // cellRow[iIndexes.ITEM_REQ_QTY].className= 'errorCellData';
                // cellRow[iIndexes.ITEM_RECI_QTY].className= 'errorCellData';
                // cellRow[iIndexes.ITEM_QTY].className= 'errorCellData';
                // cellRow[iIndexes.ITEM_REMARKS].className= 'errorCellData';
                updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
                setSpreadsheetErrors([ { message: 'Please fill all required item details.' } ]);
            }
            return cellRow;
        });

        // grnSheetData.map( (row) => {
        //     const nextRow = [ ...row ];
        //     nextRow.length = 10;
        //     return nextRow;
        // } );

        setGrnSheetData(inSheetData);

        if (!spreadsheetErrors.length && postItems.length === 0) {
            // show global alert message
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'We did not find anything to save!' } ]);
            return false;
        }
        
        if ( !postItems.length ) {
            return false;
        }

        const data = {
            id: purchaseOrderId,
            warehouseId,
            grnId: null,
            invoiceNo: (invoiceObj.invoiceNo || ''),
            invoiceDate: (invoiceObj.invoiceDate || ''),
            items: postItems
        }
        // GET /api/purchaseOrders/findById/{poid}
        // GET /api/purchaseOrders/grns/findById/' + $scope.grnId

        APIEndpoint.post('purchaseOrders/saveGrn', data)
        .then( apiResp => {
            /**
             * Update success response
             */
            updateButtonTxtStatus(true, e, 'Redirecting...', true, { defaultButtonText: 'Save'} );
            setGrnSheetData(initialData);
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiResp.msg || 'Successfully created GRN.' } ]);
            setTimeout( () => {
                goBack();
            }, 4000);
        })
        .catch( (error) => {
            // api response error handling
            const apiData = JSON.parse(error.message);
            updateButtonTxtStatus(false, e, 'Save failed', false, { defaultButtonText: 'Save' });
            setSpreadsheetErrors([]);
            ReactDOM.flushSync( () => {
                console.log("Error occured while saving transfer:", apiData);
                if ( apiData?.data.errors ) {
                    const errorMsg = apiData.data.errors.message;
                    const errorArr = apiData.data.errors.errors[0].message;
                    setSpreadsheetErrors([ ...spreadsheetErrors, { message: errorMsg, error: 1 },...errorArr ]);
                    spreadSheetErrTimout=setTimeout(() => {
                        setSpreadsheetErrors([])
                    }, 4000);
                }
                else if (apiData.msg) {
                    setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiData.msg, error: 1 } ]);
                    
                }
                else {
                    setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'Something went wrong, please try again later!', error: 1 } ]);
                    
                }
            });
           
        });
        
    };
      
     // create popup itemlist 

       const showItemListPopup = (eltPosition, activeCellIn) => {
        if (activeCellIn.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'block';
                targetEltCodes.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltCodes.style.left = eltPosition.left.toFixed() + 'px';
            }
        } else if (activeCellIn.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'block';
                targetEltNames.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltNames.style.left = eltPosition.left.toFixed() + 'px';
            }
        }
    }

    const handleActiveCellChange = (activeCell) => {
        setActiveCell(activeCell)
        console.log('ActiveCell:', activeCell);
        if ( grnSheetData[activeCell.row][activeCell.column] ) {
            const cellDetailsObj = grnSheetData[activeCell.row][activeCell.column];
            if ( cellDetailsObj.hasOwnProperty('showPopup') && cellDetailsObj.showPopup === true ) {
                
                const sourceElt = document.getElementsByClassName('cellClass_' + activeCell.row + '_' + activeCell.column)[0];
                if ( sourceElt ) {
                    const eltPosition = sourceElt.getBoundingClientRect();
                    

                    showItemListPopup(eltPosition, activeCell);
                }
                
            }
            
        }
    }

    const onClickPopupListItem = (e, row) => {
        setPurchaseorderItemToSheet(row);
    }
    const setPurchaseorderItemToSheet = (item) => {
        if (Object.keys(item).length === 0) {
            return 0;
        }

        let jsonSpreadsheetData = JSON.stringify( grnSheetData);
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

        updatedSpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {
                sheetRow[iIndexes.ITEM_CODE].value = item.itemCode;
                sheetRow[iIndexes.ITEM_NAME].value = item.itemName;
                sheetRow[iIndexes.ITEM_UNIT].value = item.unit;
                sheetRow[iIndexes.ITEM_REQ_QTY].value = item.requestedQuantity;
                sheetRow[iIndexes.ITEM_RECI_QTY].value = item.receivedQuantity;
            }
            return sheetRow;
        });

        ReactDOM.flushSync( () => {
            setGrnSheetData( [ ...updatedSpreadsheetData ]);
        });
        
        setActiveCell({});
        hideItemListPopup();
    }

    const hideItemListPopup = () =>{
        // if (activeCell.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'none';
            }
        // } else if (activeCell.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'none';
            }
        // }
    }

    const handleCellBlur = () => {
        console.log('CellBlur.');
    }
    const handleCellKeyDown = (keyboardEvent) => {
        console.log('keyDownEvent.', keyboardEvent.target.value);
    }
    const handleModeChange = (mode) => {
        console.log('ModeCHanged to:', mode);
    };
    const handleCellSelect = (selectedCell) => {
        console.log('SelectedCell:', selectedCell);
    };
    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                <div className="sheet-container sm-scrollbar">
                    <div className="header m-2 mb-2 flexRow justifyContentSpaceBetween">
                        <div className="leftBlock alignItemsCenter">
                            <button onClick={ (e) => saveSheetData(e) }
                                className="add-item-button m-3">Save</button>
                            <button onClick={ () => removeRow() }
                                className="remove-button">Delete Row</button>
                        </div>
                        <div className="rightBlock alignItemsCenter">
                            {spreadsheetErrors.length >= 1 ? spreadsheetErrors[0].message : null}
                        </div>
                        <button onClick={goBack} >
                            <img src={closeIcon} alt=""/>
                        </button>
                    </div>

                    <Spreadsheet data={grnSheetData}
                        onChange={setGrnSheetData}
                        columnLabels={grnSheetLabels}
                        darkMode={true}
                        // getBindingsForCell={[]}
                        onActivate={handleActiveCellChange}
                        onBlur={handleCellBlur}
                        onKeyDown={handleCellKeyDown}
                        onModeChange={handleModeChange}
                        onSelect={handleCellSelect}
                    />

                    <div className="m-3 pb-5 flexRow justifyContentFlexStart alignItemsCenter">
                        <button onClick={ () => addRowsToSheet() }
                            className="add-item-button" title={ t('spreadsheet.addmoreRows') }>{ t('buttons.addRows') }</button>
                    </div>
                    <div className = "cellSelectionBlockItemCodes sm-scrollbar">
                       <ul>
                        {
                            itemData.map((d,i)=>{
                                return <li key={i} onClick={(e) => onClickPopupListItem(e, d) }>{d.itemCode}</li>
                            })
                        }
                       </ul>
                    </div>
                    <div className = "cellSelectionBlockItemNames sm-scrollbar">
                       <ul>
                        {
                            itemData.map((d,i)=>{
                                return <li key={i} onClick={(e) => onClickPopupListItem(e, d) }>{d.itemName}</li>
                            })
                        }
                       </ul>
                    </div>
                </div>
                
            </div>
        </>
    );
}