import React from 'react'
import HorizontalBarChart from '../../../../common/BarHorizondal'

const BarData = {
    salesLeft:{
        data:[60.95, -20,40, -40.13,20],
        labels:['NULL', 'DOMLUX', 'MERCHANDISE', 'NON-PERISHABLE',"PERISHABLE"]
    },
    qtyLeft:{
        data:[4, 0,-3, -4,-3],
        labels:['NULL', 'DOMLUX', 'MERCHANDISE', 'NON-PERISHABLE',"PERISHABLE"]
    },
    salesRight:{
        data:[42.5],
        labels:['LGK-SIN|SIN-LGK', '', '', '',""]
    },
    qtyRight:{
        data:[0],
        labels:['LGK-SIN|SIN-LGK', '', '', '',""]
    }
}

const Legend = ({label,color})=>{
    return (
        <div style={{display:"flex",alignItems:"center",gap:"3px"}}>
            <span style={{width:"10px",height:"10px",backgroundColor:color}}></span>
            <span>{label}</span>
        </div>
    )
}

const IrTopCards = () => {
  return (
    <div className='IrTopCardsWrapper'>
        <div className='IrTopCard'>
        <div className='IrTopCardInner'>
        <p>Sales Variance by product category</p>
        <div className='canvasWrapper'>
          <div className='canvasWrapper__inner'>
            <HorizontalBarChart dataS={BarData.salesLeft.data} labels={BarData.salesLeft.labels} />
            <div
              style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
            >
              <p>Sales Variance</p>
            </div>
          </div>
          <div className='canvasWrapper__inner'>
            <HorizontalBarChart displayLabel={false} dataS={BarData.qtyLeft.data} labels={BarData.qtyLeft.labels} />
            <div className='canvasTitle'>
              <p>Quantity Variance</p>
            </div>
          </div>
        </div>
        <div className='legendWrapper'>
            <Legend label="NULL" color="#4e4b93"/>
            <Legend label="DOMLUX" color="#4e4b93a6"/>
            <Legend label="MERCHANDISE" color="yellow"/>
            <Legend label="NON-PERISHABLE" color="pink"/>
            <Legend label="PERISHABLE" color="lightblue"/>
        </div>
        </div>
      </div>
      <div className='IrTopCard'>
        <div style={{border:"1px solid #fff",padding:"0.5rem",borderRadius:10,height:"100%"}}>
        <p>Top 10 Sales Variance by sector pairing</p>
        <div style={{ display: "flex" }}>
          <div className='canvasWrapper__inner'>
            <HorizontalBarChart dataS={BarData.salesRight.data} labels={BarData.salesRight.labels} />
            <div className='canvasTitle'>
              <p>Sales Variance</p>
            </div>
          </div>
          <div
            className='canvasWrapper__inner'
          >
            <HorizontalBarChart displayLabel={false} dataS={BarData.qtyRight.data} labels={BarData.qtyRight.labels} />
            <div className='canvasTitle'>
              <p>Quantity Variance</p>
            </div>
          </div>
        </div>
        </div>
      </div>
        </div>
  )
}

export default IrTopCards