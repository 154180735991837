import React, {  useState, useEffect } from 'react'
import moment from "moment";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../../assets/styles/styles.scss';
import LoadingGIF from '../../../../assets/loadingIn.gif'


const localizer = momentLocalizer(moment);
moment.locale("en-GB");
const MenuCalendar=(props)=>{

  const getRandomBackgroundColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const [state,setState]=useState({menu:[],navCount:0, key:getRandomBackgroundColor(), currentView: 'month',allViews:["day","month"],selectedMenu:{}})
  useEffect(() => {
  if(props.data?.mealMenu?.length){

      setState((prev) => ({
          ...prev,
          mealPlan:props.data.mealMenu,
          menu:props.data?.mealMenu.map((fl) => ({
            id:fl._id,
            title: `${fl.menuPlanName}-${fl.status.charAt(0).toUpperCase()+fl.status.slice(1)}`,
             start:getDateRanges(fl.fromDate),
             end:getDateRanges(fl.toDate),
             bgColor:fl.statusColorCode
          })),
          key:getRandomBackgroundColor()
      }
     ))
  }
  else{
    setState((prev) => ({
      ...prev,
      mealPlan:[],
      menu:[],
      key:getRandomBackgroundColor()
  }
 ))
  }
  }, [props]);




  const handleSelect=()=>{

  }

const viewMenuDetails=(m)=>{
    if(m){
       const selectedMealPlan= props.data?.mealMenu.find(meal=>meal._id===m.id)

      if(selectedMealPlan){
        props.actions(selectedMealPlan,'viewMenuPlan')
      }

    }


}

const MyToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.onNavigate('PREV');
     setState((prev)=>{return{...prev,navCount:state.navCount-1}})

    };

    const goToNext = () => {
      toolbar.onNavigate('NEXT');
     setState((prev)=>{return{...prev,navCount:state.navCount+1}})

    };

    const goToCurrent = () => {
      toolbar.onNavigate('TODAY');
     setState((prev)=>{return{...prev,navCount:0}})

    };

    const goToMonthView = () => {
     setState((prev)=>{return{...prev,navCount:0,key:getRandomBackgroundColor(),currentView:'month',allViews:['day','month']}})
    };
    const goToDayView = () => {
      setState((prev)=>{return{...prev,navCount:state.navCount+1,key:getRandomBackgroundColor(),allViews:['day'],currentView:'day'}})

    };

    return (
        <div className="rbc-toolbar">
        <span className="rbc-btn-group">
        <button type="button" className="nav-button" onClick={goToBack}>
        &lt;
        </button>
       { state.navCount!==0?<button type="button" className="nav-button" onClick={goToCurrent}>
        {state.currentView==='day'?'Today':'This Month'}
        </button>:<></>}
        <button type="button"  className="nav-button" onClick={goToNext}>
        &gt;
        </button>
        </span>
        <span className="rbc-toolbar-label">{toolbar.label}</span>
       { state.currentView!=='day'?<span className="rbc-btn-group right">
        <button type="button" className="nav-button" onClick={goToDayView}>
        Today
        </button>
        </span>:<></>}
       { state.currentView!=='month'?<span className="rbc-btn-group right">
        <button type="button" className="nav-button" onClick={goToMonthView}>
        Month View
        </button>
        </span>:<></>}
        </div>
    );
  };


  const formats = {
    dayFormat: (date, culture, localizer) =>
      localizer.format(date, 'ddd', culture),
  };

    const eventPropGetter = (event, start, end, isSelected) => {

        const style = {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor: event.bgColor,
        color: 'white',
        overflowY:'scroll',
        fontWeight:'700',
        fontSize:'14px',
        maxHeight:'40px',
        };

        return { style };
      };


      const getDateRanges=(dt)=>{
      const [year, month, day] = dt.split("T")[0].split("-").map(Number);
      return new Date(year, month-1, day, 23, 59, 59);
      }


    return(<>
      {state.menu.length ||state.defaultDate? (<div className='container-fluid dashboard-summary-container' style={{height:'80vh',overflowY:'scroll'}}>
      <Calendar
          localizer={localizer}
          key={state.key}
          views={state.allViews}
          selectable
          defaultView={state.currentView}
          onSelectEvent={(event) => viewMenuDetails(event)}
          onSelectSlot={handleSelect}
          eventPropGetter={eventPropGetter}
          components={{ toolbar: MyToolbar }}
          formats={formats}
          style={{
            pointerEvents: props.data.isOpenMenu ? 'none' : 'auto',
            filter: props.data.isOpenMenu ? 'blur(2px)' : 'none',
            height: '80vh',
            overflowY: 'auto',
          }}
          defaultDate={props.data.defaultDate||new Date()}
          events={state.menu}
        /></div>


      ) : (
        <div className='flexRow justifyContentCenter alignItemsCenter'><img src={LoadingGIF}  className='icon-size' style={{margLeft:'5px',marginTop:'35vh',width:'25px',height:'25px'}} alt="Loading" /><span style={{marginTop:'34.5vh'}}>Loading... <div className='loading-dots'>
        <span className='loaderdots-button' style={{'--d':'200ms'}}></span>
        <span className='loaderdots-button' style={{'--d':'400ms'}}></span>
        <span className='loaderdots-button' style={{'--d':'600ms'}}></span>
        </div></span></div>
      )}
</>)


}

export default MenuCalendar;