import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";

import { ControlPanelInner,CalendarControlHomePanelInner, InventoryReconciliation, CardReconciliation } from "./inner-components";

export const ControlPanelPage = () => {
    return (
        <div className="flexRow full p-0 m-0">
            <div className="full-flex flexCol width100 p-0 m-0">
                <Routes>
                    <Route path="/" element={<Navigate replace to="home"/>} />
                    {/* <Route path="/home" element={<ControlPanelHome/>}/> */}
                    <Route path="/home" element={<ControlPanelInner />} />
                    <Route path="/calendar/home" element={<CalendarControlHomePanelInner />} />
                    <Route path="/in-reconciliations" element={<InventoryReconciliation />} />
                    <Route path="/card-reconciliations" element={<CardReconciliation />} />
                </Routes>
            </div>
        </div>
    )
};
