import React, { useEffect, useRef, useState } from 'react'

const SpreadsheetEditorC = (props) => {
    const [state, setState] = useState({ search: props.cell ? props.cell.value : ''})

    const { colDef } = props
    const ref = useRef(null)
    const handleAutomaticFocus = () => {
      let timerId = setInterval(() => {
        if (ref.current) {
          ref.current.focus();
          clearInterval(timerId);
        }
      }, 100)
    }
    useEffect(() => {
      handleAutomaticFocus()
    }, [])
    useEffect(() => {
      return () => {
        if (!ref.current) {
          props.onChange({ value: state.search, updated: true, error: isError() })
        }
      }
    }, [state.search])
  
  
    const getOptions = () => {
      if (state.search && colDef.type === 'select') {
        return colDef.options.filter((_) => {
          return _[colDef.labelProp].toLowerCase().includes(state.search.toLowerCase());
        })
      }
      return colDef.options;
    }
    const handleOnChange = (e) => {
      setState((_) => ({ ..._, search: e.target.value }))
    }
    const handleClick = (e, _) => {
      let cellVal = { updated: true }
      if (_) {
        cellVal.value = _[colDef.labelProp]
      } else {
        cellVal.value = state.search;
        cellVal.error = true
      }
      props.onChange(cellVal)
      props.exitEditMode()
    }
    const isError = () => {
      return colDef.type === 'number' && state.search !== '' && Number(state.search) < 1
    }
    const handleBlur = () => {
      props.onChange({ value: state.search, error: isError() })
      props.exitEditMode()
    }
    const handleKeyDown = (e) => {
      if (e.keyCode === 13 && ref.current) {
        ref.current.blur();
        handleBlur()
      }
    }
    const list = getOptions()
    return (
      <div className='data-editor'>
        {
          colDef.type === 'select' ?
            <>
              <input style={{color:"#fff"}} ref={ref} type="text" placeholder='' onChange={handleOnChange} value={state.search} />
              <ul className="drop-down">
                {
                  list.length === 0 ?
                    <li className='not-found' onClick={(e) => handleClick(e, null)}>No items Found</li>
                    :
                    list.map((_, i) => {
                      return <li key={i} onClick={(e) => handleClick(e, _)}>{_[colDef.labelProp]}</li>
                    })
                }
              </ul>
            </>
            :
            <input
            style={{backgroundColor:"#000",color:"#fff"}}
              onBlur={handleBlur} onKeyDown={handleKeyDown} ref={ref} type={colDef.type || 'text'} placeholder=''
              value={state.search} onChange={handleOnChange} min={1} />
        }
      </div>
    )
}

export default SpreadsheetEditorC