/* eslint-disable no-unused-vars */
import { saveAs } from 'file-saver';

import { APIEndpoint,CSBNPORATLAPIEndpoint } from '../utils/axios';
import { BUTTON_STATUSUPDATE_INTERVAL, DOWNLOAD_STATUSCHECK_INTERVAL } from "./configs";
import LoadingGIF from '../assets/loadingIn.gif';
export const capitalizeFirstLetter = (strTxt) => {
    try {
        return strTxt.charAt(0).toUpperCase() + strTxt.slice(1);
    }
    catch (e) {
        return strTxt;
    }
}
export const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];
export  const loginDateAndTime=()=>{
    var current = new Date();

    var date = current.getDate() + " " + monthNames[current.getMonth()]
    + " " + current.getFullYear()
    return {
        current: current,
        date: date
    };
}
export  const  formatDate=(date) =>{
    const [, mmm, d, y, h, m, s, _, zh, zm] = date.match(/[+-]\d\d|\w+/g);
    const M = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(mmm) / 3;
   return new Date(Date.UTC(y, M, d, h, m, s)).toJSON().slice(0, 19) + zh+":"+zm;
}
export function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because month index starts from 0
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
export const getSalesPeriod=(date)=>{
    let current = new Date(date);
    let newStr=current.toString().slice(4,10)+","+current.toString().slice(11,15)
    return newStr;
}

export const isActiveSubMenuCssClass = (inpTxt, compareText) => {
    return inpTxt === compareText ? 'menu-item-selected' : '';
}

export const fetchUserPermissions=async(hdr=null,payload={})=>{
    if(hdr.data){
        const header={
            'Accept': 'application/json',
            'content-type':"application/json; charset=utf-8",
            user_id:hdr.data.user_id,
            'X-Bearer-Token':hdr.data.token,
            'X-Csrf-Token':hdr.headers['x-csrf-token']
        };
        try {
              const res = await fetch( CSBNPORATLAPIEndpoint.getFullPath(`users/permissions`),
              {
                  method: 'POST',
                  body: JSON.stringify(payload),
                  headers: header
              });
              if (res.ok) {
                const data = await res.json();
                return data;
            } else {
                console.error('Error fetching users:', res.status);
            }
            } catch (error) {

            }
    }

    }


export const onDownloadCSVFile= async (endPoint, fileName)=>{
    try {
        const header={
            ...APIEndpoint.getHeaders().headers,
            'Accept': 'text/csv',
        };
        const res = await fetch( APIEndpoint.getFullPath(`${endPoint}`),
        {
            method: 'GET',
            responseType: 'arrayBuffer',
            headers: header
        });
        const blob = await res.blob();
        saveAs(blob, `${fileName}.csv`);
    }
    catch (res) {
        console.log('Error while downloading csv file: ', res.message)
    }
}

export const onDownloadXlsFile= async (endPoint, fileName)=>{
    try {
        const header={
            ...APIEndpoint.getHeaders().headers,
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        };
        const res = await fetch( APIEndpoint.getFullPath(`${endPoint}`),
        {
            method: 'GET',
            responseType: 'arrayBuffer',
            headers: header
        });
        const blob = await res.blob();
        saveAs(blob, `${fileName}.xlsx`);
    }
    catch (res) {
        console.log('Error while downloading xls file: ', res.message)
    }
}

export const errorFormatter= (error=null)=>{
    if(error){
        let regex = /'([^']+)'/;
        let matchString=error.match(regex)

        if(matchString&&Array.isArray(matchString)&&matchString.length){
           let errMsg=matchString[0].replace(/^'|'$/g, '').replace(/([A-Z])/g, ' $1').trim();
               errMsg= errMsg.charAt(0).toUpperCase()+errMsg.slice(1);
               return error.replace(matchString[0],errMsg)
        }
    }else{
        return "Please provide all the required fields!"
    }

}

export const onDownloadZIPFile= async (endPoint, fileName)=>{
    try {

        const header={
            ...APIEndpoint.getHeaders().headers,
            'Accept': 'application/zip',
        };
        const res = await fetch( APIEndpoint.getFullPath(`${endPoint}`),
        {
            method: 'GET',
            responseType: 'arrayBuffer',
            headers: header
        });
        const blob = await res.blob();
        saveAs(blob, `${fileName}`);
    }
    catch (res) {
        console.log('Error while downloading zip file: ', res.message)
    }
}
export const onDownloadPDFFile= async (endPoint, fileName, requestProps = { method: 'GET', payload: {} })=>{
    try {
        const { method, payload } = requestProps;

        const header={
            ...APIEndpoint.getHeaders().headers,
            'Accept': 'application/pdf',
        };
        let fetchData = {
            method: method ?? 'GET',
            responseType: 'arrayBuffer',
            headers: header,
        };

        if ( method === 'POST') {
            fetchData.body = payload ? JSON.stringify(payload) : {};
        }

        const res = await fetch( APIEndpoint.getFullPath(`${endPoint}`), fetchData );
        // console.log('Log: Download response content: ', res);
        const blob = await res.blob();
        saveAs(blob, `${fileName}.pdf`);
    }
    catch (res) {
        console.log('Error while downloading pdf file: ', res.message)
    }
}

export const downloadFileDashboard = (dataA=null,dataB=null) => {
    if ((dataA && Object.keys(dataA).length) || (dataB && Object.keys(dataB).length)) {
        const headers = (
          (dataA.labels !== undefined ? dataA.labels : dataA.labelsData.map(l => l.name)) ||
          (dataB && dataB.labels !== undefined ? dataB.labels : dataB.labelsData.map(l => l.name))
        );
        const dataCont = (dataA.datasets[0].data || dataB.datasets[0].data).join(',');

        const xlsContent = `sep=,\r\n${headers}\r\n${dataCont}`;
        const dataUri = 'data:application/vnd.ms-excel;charset=utf-8,' + encodeURIComponent(xlsContent);

        const a = document.createElement('a');
        a.href = dataUri;
        a.download = `${(dataA.title?dataA.title:dataA.widgetName) || (dataB.title?dataB.title:dataB.widgetName)}.xls`;
        a.click();
      }
};

export const downloadFileCaterer = (data=null) => {
    const headerRow = Object.keys(data).join('\t');
    const dataRow = Object.values(data).join('\t');

    const xlsContent = `sep=\\t\n${headerRow}\n${dataRow}`;
    const blob = new Blob([xlsContent], { type: 'application/vnd.ms-excel' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${data['Invoice Id']}-${data['Caterer Name']}.xls`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
};


export const onDownloadImageFile = async (endPoint, fileName, requestProps = { method: 'GET', payload: {} }) => {
    try {
        const { method, payload } = requestProps;

        const header = {
            ...APIEndpoint.getHeaders().headers,
            'Accept': 'image/jpeg',
        };

        let fetchData = {
            method: method ?? 'GET',
            responseType: 'arrayBuffer',
            headers: header,
        };

        if (method === 'POST') {
            fetchData.body = payload ? JSON.stringify(payload) : {};
        }

        const res = await fetch(APIEndpoint.getFullPath(`${endPoint}`), fetchData);
        const blob = await res.blob();
        saveAs(blob, `${fileName}.jpg`); // Save as a JPEG file
    } catch (error) {
        console.log('Error while downloading image file:', error.message);
    }
};
let stsChkInterval = 0;
let stsChkId;
export const checkDownloadStatus = async (jobId, e) => {

        return APIEndpoint.get('downloads/status/' + jobId).then( resp => {
            if (resp.status === 'completed') {
                clearInterval(stsChkId);
                console.log(jobId + ': Download status check: completed', JSON.stringify(resp));

                updateButtonTxtStatus(true, e, 'Downloaded', false);
                // onDownloadPDFFile('downloads/invoice', 'order_invoice_' + row.id, { method: 'POST', payload: { orderId: row.id } } );

                if ( resp.fileExtension && resp.fileExtension === '.zip' ) {
                    onDownloadZIPFile('downloads/analytics/' + resp.fileName+resp.fileExtension, resp.fileName+resp.fileExtension);
                }

            }
            else if (resp.status === 'scheduled') {

                if ( stsChkInterval < 3) {
                    console.log('Log: Check Status Interval:' + stsChkInterval);

                    // stsChkInterval++;
                    // checkDownloadStatus(jobId, e);

                    stsChkId = setInterval( () => {
                        console.log(jobId + ": Download status check: After Interval", JSON.stringify(resp));
                        stsChkInterval++;
                        checkDownloadStatus(jobId, e);
                    }, 6000);
                } else {
                	clearInterval(stsChkId);
                }


            }
            else if (resp.status === 'failed') { // Failure case-if status is 200
                clearInterval(stsChkId);


            }
            return resp;
        }).catch( error => {
        	clearInterval(stsChkId);
            updateButtonTxtStatus(false, e, 'Download failed', false);
            return error;
        });

}

export const getRemarks = (remarks='', comment='') => {
    if (remarks && comment ) {
        return remarks + ' | ' + comment;
    }
    else if (remarks ) {
        return remarks;
    }
    else if ( comment ) {
        return comment;
    }
    return '';
};

let timeoutId = 0;
export const updateButtonTxtStatus = (success = false, e, txt = 'Download', disabled=true,
    extraProps = { className: 'failedCls', defaultButtonText: 'Download', loadingImage: LoadingGIF }) => {

   
    e.target.classList.remove(extraProps.className);

    if (disabled) {
        e.target.setAttribute('disabled', true);
    } else {
        e.target.removeAttribute('disabled');
    }

    if (success) {
        e.target.classList.remove(extraProps.className);
    } else {
        e.target.classList.add(extraProps.className);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            updateButtonTxtStatus(true, e, extraProps.defaultButtonText, false, { ...extraProps });
        }, BUTTON_STATUSUPDATE_INTERVAL);
    }

  
    const imgElement = e.target.querySelector('img');
    if (imgElement) {
        imgElement.remove();
    }


    e.target.innerText = txt;

    if (txt === 'Processing...') {
      
        const img = document.createElement('img');
        img.src = extraProps.loadingImage; 
        img.alt = 'Processing...';
        img.style.marginRight = '5px'; 
        img.style.width = '25px'; 
        img.style.height = '25px'; 
        e.target.insertBefore(img, e.target.firstChild); 
    }
};


export const validateCellAndGetData = (cellData,recon=false) => {
    if ( cellData && cellData.value && !recon ) {
        if ( cellData.hasOwnProperty('type') && cellData.type === 'number' ) {
            return parseInt(cellData.value);
        }
        else {
            return cellData.value;
        }
    }else if(cellData && recon){
        if ( cellData.hasOwnProperty('type') && cellData.type === 'number' ) {
            return parseInt(cellData.value);
        }
        else {
            return cellData.value;
        }
    }
    return '';
};

// let timeoutId2 = 0;
export const fileUploadStatusShow = (e, msg) => {
    e.target.setAttribute('disabled', true);
    const statusShowElt = e.target.parentNode.parentNode.getElementsByClassName('file_upload_status_container')[0];
    statusShowElt.innerHTML = "<p>" + msg + "</p>";

    // clearTimeout(timeoutId2);
    // timeoutId2 = setTimeout(() => {
    //     statusShowElt.innerHTML = "";
    // }, BUTTON_STATUSUPDATE_INTERVAL);
}

export const fileUploadStatusHide = (e, msg) => {
    e.target.removeAttribute('disabled');
    const statusShowElt = e.target.parentNode.parentNode.getElementsByClassName('file_upload_status_container')[0];
    if (msg) {
        statusShowElt.innerHTML = "<p>" + msg + "</p>";
    } else {
        statusShowElt.innerHTML = "";
    }
}

export const passwordRegex=/^[0-9\b]+$/;
export const emailRegex=/\S+@\S+\.\S+/;
export const mobileNumberRegex=/^[0-9]{12}$/;
export const passwEx= /^([a-zA-Z0-9_-]){5,15}$/;
export const translationLength=5;

/**
 * Convert an object to URL Query String
 * @param {*} obj
 * @returns String
 */
export const objectToQueryString = (obj) => {
    const keyValuePairs = [];
    for( const key in obj) {
      if ( obj.hasOwnProperty(key) ) {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(obj[key]);
        keyValuePairs.push(`${encodedKey}=${encodedValue}`)
      }
    }
    return keyValuePairs.join('&');
};

export function debounce(cb, delay) {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
      cb(...args)
     }, delay);
    }
}

export function copyToClipBoard(e,text){
    const {x,y} = e.target.getBoundingClientRect()
    navigator.clipboard.writeText(text);
    const div = document.createElement("div")
    div.textContent = "Copied!"
    div.classList.add("toastCustom")
    div.style.top = `${y}px`
    div.style.left = `${x+10}px`
    document.body.appendChild(div)
    setTimeout(() => {
        document.body.removeChild(div)
    }, 2000);
}
export function truncateString(str, maxLength,tooltip=false) {
    if(!str) return;
    if (str.length <= maxLength) {
      return str;
    }
    if(tooltip){
        return <span title={str}>{str.slice(0, maxLength - 3) + "..."}</span>;
    }
    return str.slice(0, maxLength - 3) + "..."

  }

