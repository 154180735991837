import React from 'react';
import { Route, Routes,Navigate } from 'react-router-dom';
import { ConfigurationPage, SSR  } from "./inner-components";

export const ConfigPage = (props) => {
  return (
    <div className="flexRow full p-0 m-0">
      <div className='full-flex flexCol width100 p-0 m-0'>
        <Routes>
        <Route path="/" element={<Navigate replace to="configuration" />} />
          <Route path="/configuration" element={<ConfigurationPage />} />
          <Route path="/ssr" element={<SSR />} />

        </Routes>
      </div>
    </div>
  )
}