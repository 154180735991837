// import {  useState } from "react";
import { useTranslation } from 'react-i18next';
import { DataGrid, Modal } from "../../../common";
import { CART_ICON } from "../../../assets/icons/customSVG/cartIcon";
import CALENDAR_ICON from "../../../assets/icons/calendar.svg";
import FOLDER_OPEN_ICON from "../../../assets/icons/folder-open.svg";
import FOLDER_CLOSE_ICON from "../../../assets/icons/folder-close.svg";
import DOWNLOAD_ICON from "../../../assets/icons/download-master.svg";
import EMAIL_ICON from "../../../assets/icons/email.svg";
import FLIGHT_ICON from "../../../assets/flights.svg";
import moment from "moment-timezone";

export const MainColumns = [
  { width: 50, field: '',flex:1,suppressSizeToFit:true, resizable:true, headerName: '#', valueGetter: "node.rowIndex + 1", pinned: 'left', className: 'fff' },
  { width: 120, minWidth:120,flex:1,suppressSizeToFit:true, resizable:true,field: 'itemCode', filter: 'agTextColumnFilter', headerName: 'pages.controlPanel.views.groupTableColumns.itemCode', pinned: 'left' },
  { width: 120,minWidth:180,flex:1,suppressSizeToFit:true, resizable:true, field: 'itemName', filter: 'agTextColumnFilter', headerName: 'pages.controlPanel.views.groupTableColumns.itemName', pinned: 'left' },
]
export const Columns = [
  // { width: 200,field: 'fc', headerName: 'pages.controlPanel.views.groupTableColumns.fc' },
  { field: 'bc', flex:1,suppressSizeToFit:true, resizable:true,headerName: 'pages.controlPanel.views.groupTableColumns.businessClass' },
  { field: 'pc',flex:1,suppressSizeToFit:true, resizable:true, headerName: 'pages.controlPanel.views.groupTableColumns.premiumEconomy' },
  { field: 'ecy',flex:1,suppressSizeToFit:true, resizable:true, headerName: 'pages.controlPanel.views.groupTableColumns.economy' },
  // { field: 'bobQuantityModified', headerName: 'pages.controlPanel.views.groupTableColumns.modified' },
  // { field: 'bobQuantityConfirmed', headerName: 'pages.controlPanel.views.groupTableColumns.confimed' },
];
export const EstimateColumns = [
  { width: 120, minWidth:90,flex:1,suppressSizeToFit:true, resizable:true,field: 'quantity',pinned: 'left', headerName: 'pages.controlPanel.views.groupTableColumns.total' },
  { minWidth:80,field: 'fcQuantity',flex:1,suppressSizeToFit:true, resizable:true, width: 130,headerName: 'pages.controlPanel.views.groupTableColumns.fc' },
  { minWidth:90,field: 'bcQuantity',flex:1,suppressSizeToFit:true, resizable:true,width: 130, headerName: 'pages.controlPanel.views.groupTableColumns.businessClass' },
  { minWidth:90,field: 'pcQuantity',flex:1,suppressSizeToFit:true, resizable:true,width: 130, headerName: 'pages.controlPanel.views.groupTableColumns.premiumEconomy' },
  { field: 'ecyQuantity', flex:1,suppressSizeToFit:true, resizable:true,width: 130, minWidth:90,headerName: 'pages.controlPanel.views.groupTableColumns.economy' },
  { field: 'crewMealQuantity',flex:1,suppressSizeToFit:true, resizable:true,width: 130, minWidth:70, headerName: 'pages.controlPanel.views.groupTableColumns.crew' },
  { field: 'cockpitMealQuantity',flex:1,suppressSizeToFit:true, resizable:true,width: 130, minWidth:80, headerName: 'pages.controlPanel.views.groupTableColumns.cockpit' }
];
const getTreeSVGIcon = (type) => {
  // eslint-disable-next-line default-case
  switch (type) {
    case 'date': return CALENDAR_ICON;
    case 'flight': return FLIGHT_ICON;
  }
}
export const getDateFromTimezone = (dateTimestr, timezone) => {
  let date;
  if (timezone === 'utc') {
    date = moment.utc(dateTimestr)
  } else {
    date = moment(dateTimestr).tz('Asia/Kolkata')
  }
  return date
}
export const TreeNode = (props) => {
  const { node } = props
  return (
    <div {...props} draggable={false} className="flexRow tree-node hor-ctr" >
      {
        node.data.type === 'expand' ?
          <>
            {
              node.data.svg === 'folder' ?
                <img src={node.isOpen ? FOLDER_OPEN_ICON : FOLDER_CLOSE_ICON} className="node-icon svg" alt="" />
                :
                <i className={`icon-arrow-right font node-icon ${node.isOpen ? 'open' : ''}`} />
            }
          </>
          : node.data.type === 'svg' ?
            <img src={getTreeSVGIcon(node.data.icon)} className="node-icon svg" alt="" />
            :
            <CART_ICON bgColor="transparent" className="node-icon svg" color="#ffffff" width="18" height="18" />
      }
      <span>{node.data.name}</span>
    </div>
  )
}
export const Breadcrumb = (props) => {
  const { path } = props
  return (
    <div className="flexRow hor-ctr breadcrumb">
      {
        path.map((_, i) => {
          return (
            <>
              <span className="">{_.name}</span>
              {i < (path.length - 1) && <i className="icon-arrow-right br-icon" />}
            </>
          )
        })
      }
    </div>
  )
}
export const Tabs = (props) => {
  const { TABS, onClick, selectedTab, view } = props;
  const { t } = useTranslation()
  return (
    <div className="flexRow cnt-tabs">
      {
        TABS.map((tab) => {
          return (
            <div onClick={() => onClick && onClick(tab)}
              className={`tab ${selectedTab === tab ? 'active' : ''}`} key={tab}>
              {t(`pages.controlPanel.views.${view}.tabs.${tab}`)}
            </div>
          )
        })
      }
    </div>
  )
}
export const ItemGridView = (props) => {
  const { t } = useTranslation()
  // eslint-disable-next-line no-unused-vars
  const { status, group } = props.cateringGroup
  const { confirmed, SubTitle, showEmail } = props;
  return (
    <Modal
      onClose={props.onClose}
      onSubmit={props.onConfirm}
      buttonLabel={t('confirm')}
      title={props.title} showDone={!confirmed}
      overlayClassName="dfp-modal">
      <div className='flexCol' style={{ height: '60vh', width: '100%' }}>
        {
          (Boolean(SubTitle) || Boolean(showEmail)) && (
            <div className="flexRow space-btn margBottom10">
              {Boolean(SubTitle) && <SubTitle />}
              {Boolean(showEmail) && <EmainOrDownload />}
            </div>
          )
        }
        <DataGrid
          columnResize
          rowModelType='clientSide'
          showDefaultFilters={false}
          className='full-flex'
          columns={props.Columns || Columns}
          rowData={status.items || []}
        />
      </div>
    </Modal>
  )
}

export const Card = (props) => {
  const { estimates, date, plan } = props.group.model || {};
  const { t } = useTranslation()
  // const [state, setState] = useState({ open: false })
  const estimate = estimates && estimates.length > 0 ? estimates[estimates.length - 1] : {}
  return (
    <div className="flexCol white-bg carter-card" >
      <div className="flexRow hor-ctr heading" onClick={props.onClick}>
        <h6 className="sl-no">{props.slNo}.</h6>
        <div className="flexCol full-flex">
          <div className="flexRow hor-ctr margBottom10">
            <h5 className="h6 marg0">{props.group.id}</h5>
            {/* <EmainOrDownload /> */}
          </div>
          <h4 className="h7">{plan} <br /> <code>{moment(date).tz(moment.tz.guess()).format("DD-MM-YYYY hh:mm A zz")}</code></h4>
          <div className="flexRow">
            <span className="crop">{t(`pages.controlPanel.views.catering.pax`)}: <b>{estimate.pax || '0'}</b></span>
            <span className="crop">{t(`pages.controlPanel.views.catering.bc`)}: <b>{estimate.bcPax || '0'}</b></span>
            <span className="crop">{t(`pages.controlPanel.views.catering.pc`)}: <b>{estimate.pcPax || '0'}</b></span>
            <span className="crop">{t(`pages.controlPanel.views.catering.ecy`)}: <b>{estimate.ecyPax || '0'}</b></span>
          </div>
        </div>
      </div>
    </div>
  )
}

export const getSelectedGroupPath = (groups, selectedGroup) => {
  const path = []
  const findInChildren = (item) => {
    let added = false
    if (item) {
      if (item.id === selectedGroup.id) {
        path.push(item)
      }
      if (item.children) {
        const found = item.children.find((i) => {
          return i.id === selectedGroup.id
        });
        if (found) {
          added = true
          path.push(found)
        } else {
          item.children.find((i) => {
            const isAdded = findInChildren(i);
            if (isAdded) {
              added = true
              path.unshift(i)
            }
            return null
          });
        }
      }
    }
    return added
  }
  if (selectedGroup && selectedGroup.id !== groups[0].id) {
    findInChildren(groups[0])
  }
  path.unshift(groups[0])
  return path
}
export const sorter = (a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
}
export const EstimateTitle = (props) => {
  const { status } = props.cateringGroup
  const { t } = useTranslation();
  return (
    <>
      {
        status.cateringStatus === 'confirmed' &&
        <span className="caption-regular title-chip">{t(`pages.controlPanel.views.finalizedByCatering`)}</span>
      }
      {
        status.status === 'confirmed' &&
        <span className="caption-regular title-chip">{t(`pages.controlPanel.views.confirmByCatering`)}</span>
      }
    </>
  )
}
export const EmainOrDownload = (props) => {
  const showEmail = props.showEmail !== undefined ? props.showEmail : true;
  const showDownload = props.showDownload !== undefined ? props.showDownload : true;
  console.log(props, showEmail, showDownload);
  return (
    <div className="flexRow hor-ctr emaildownload">
      {
        showDownload &&
        <button className="btn" onClick={props.onDownload}>
          <img src={DOWNLOAD_ICON} className="icon" alt="" />
        </button>
      }
      {
        showEmail &&
        <button className="btn" onClick={props.onEmail}>
          <img src={EMAIL_ICON} className="icon" alt="" />
        </button>
      }
    </div>
  )
}
