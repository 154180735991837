import React from "react";
import { useEffect, useState } from "react";
import { DFPAPIEndpoint } from "../utils/axios";
import moment from "moment";

export const SalesDetailsView = (props) => {
    const [od, setOrderDetails] = useState(null);
    const { tableData } = props;
    const DATE_FORMAT='MMM DD, YYYY hh:mm:ss A';

    useEffect( () => {
        fetchOrderDetails(tableData.id);
    }, [tableData]);

    const fetchOrderDetails = async (id) => {
        console.log('Fetching order details' + id);
        const detObj = await DFPAPIEndpoint.get('/orders/findById/' + id);
        setOrderDetails(detObj.order);
    }
    return (
        <>
            {od ? 
            <>
                <h6 className="mb-3">Order Details</h6>
                <table>
                    <tr>
                        <th style={{width:"50%"}}>Pay Mode</th><td style={{width:"50%"}}>{od.paymentType}</td>
                    </tr>
                    <tr>
                        <th>Sale Type</th><td>{od.saleType}</td>
                    </tr>
                    <tr>
                        <th>Currency</th><td>{od.currencyInfo.currencyName}</td>
                    </tr>
                </table>

                <table className="mt-3"
                    frame="true" bgcolor="transparent" rules="rows" summary="Show order items">
                    <thead>
                        <tr>
                            <th>Items</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {od.orderItems.map( item => {
                            return (<tr>
                                <td style={{width:"300px"}}>{item.itemName}</td>
                                <td>{item.quantity}</td>
                                <td style={{width:"300px"}}>{item.itemPrice}</td>
                                <td style={{width:"300px"}}>{item.totalAmount}</td>
                            </tr>);
                        })}
                        
                    </tbody>
                    <tfoot>
                        <tr className="spl-row-class">
                            <td colSpan={3}>Total Amount</td>
                            <td>{od.currencyInfo.currencyCode} {od.totalAmount ?? 0.0}</td>
                        </tr>
                    </tfoot>
                </table>

                <h6 className="mt-4 mb-3">Flight Info</h6>
                <table>
                    <tr>
                        <th style={{width:"50%"}}>Flight Number</th><td style={{width:"50%"}}>{od.flightNumber}</td>
                    </tr>
                    <tr>
                        <th>Origin</th><td>{od.origin}</td>
                    </tr>
                    <tr>
                        <th>Destination</th><td>{od.destination}</td>
                    </tr>
                    <tr>
                        <th>Aircraft Number</th><td>{od.aircraftNumber}</td>
                    </tr>
                </table>

                <h6 className="mt-4 mb-3">Timeline</h6>
                <table>
                    <tr>
                        <th style={{width:"50%"}}>Ordered At</th><td style={{width:"50%"}}>{ moment(od.timestampDateTime).format(DATE_FORMAT) ?? null}</td>
                    </tr>
                    <tr>
                        <th>Synched At</th><td>{ moment(od.syncedAt).format(DATE_FORMAT) ?? null}</td>
                    </tr>
                </table>

                <h6 className="mt-4 mb-3">Customer Details</h6>
                <table>
                    <tr>
                        <th style={{width:"50%"}}>Customer Name</th><td style={{width:"50%"}}>{od.bookingInfo.customerName ?? ''}</td>
                    </tr>
                    <tr>
                        <th>Seat</th><td>{od.bookingInfo.seatNumber ?? ''}</td>
                    </tr>
                    <tr>
                        <th>PNR</th><td>{od.bookingInfo.pnr ?? ''}</td>
                    </tr>
                    <tr>
                        <th>Mobile</th><td>{od.bookingInfo.mobile ?? ''}</td>
                    </tr>
                </table>

                <h6 className="mt-4 mb-3">Crew Details</h6>
                <table>
                    <tr>
                        <th style={{width:"50%"}}>Employee Code</th><td style={{width:"50%"}}>{od.crew.employeeCode ?? ''}</td>
                    </tr>
                    <tr>
                        <th>Employee Name</th><td>{od.crew.employeeName ?? ''}</td>
                    </tr>
                </table>
            </>
            : <div className="loadingDiv">Loading...</div>}
        </>
    )
}