import React from "react";
import { Route, Routes} from "react-router-dom";
import { Loyalty } from "./inner-components/Loyalty";

export const LoyaltyScreen = (props) => {
  return (
    <div className="flexRow full p-0 m-0">
      <div className="full-flex flexCol width100 p-0 m-0">
        <Routes>
          <Route path="/" element={<Loyalty />} />
       
        </Routes>
      </div>
    </div>
  );
};
