/* eslint-disable no-unused-vars */
import React from "react";
import moment from "moment";
import editIcon from "../assets/edit.svg";
import deleteIcon from '../assets/delete.svg'

export const MenuPlannerView = (props) => {
      const {
      sectorDetails,
      sectorType,
      cateringStationDetails,
      caterer,catererDetails,
      aircraftTypeId, aircraftTypeDetails,
      cabin,fromDate,toDate,createdAt,sourceStation,status,
      menuSetting}=props.tableData;

    return (
        <>
            {menuSetting&&menuSetting.length>0 ?
            <>
            <div className="flexRow justifyContentSpaceBetween">
            <h6 className="mb-3">Menu Details</h6>

             <div className="flexRow justifyContentFlexEnd">{status!=='expired'?<img  style={{marginLeft:'5px',width:'35px',height:'25px',border:'1px solid #70CEEA',borderRadius:'4px',backgroundColor:"#70CEEA"}} className='aircraft-actions' alt='edit' onClick={() =>{props.action(props.tableData,'editMenuPlan')}} src={editIcon}/>:<></>}

        <img   style={{marginLeft:'5px',width:'35px',height:'25px',border:'1px solid  #F06548',borderRadius:'4px',backgroundColor:' #F06548'}} className='aircraft-actions' alt='delete'  onClick={() =>{props.action(props.tableData,'deleteMenuPlan')}} src={deleteIcon}/>
              </div>

            </div>
                <table>
                <tr>
                        <th style={{width:"50%"}}>Sector Type</th><td style={{width:"50%"}}>{sectorType.label}</td>
                    </tr>
                    <tr>
                        <th style={{width:"50%"}}>Sector</th><td style={{width:"50%"}}>{sectorDetails.value}</td>
                    </tr>
                    <tr>
                        <th style={{width:"50%"}}>Warehouse</th><td style={{width:"50%"}}>{cateringStationDetails.warehouseName + '(' + cateringStationDetails.warehouseCode + ')'}</td>
                    </tr>
                    <tr>
                        <th style={{width:"50%"}}>Status</th><td style={{width:"50%"}}>{`${status.charAt(0).toUpperCase()+status.slice(1)}` }</td>
                    </tr>
                    <tr>
                        <th style={{width:"50%"}}>Caterer</th><td style={{width:"50%"}}>{catererDetails.vendorName + '('+catererDetails.vendorCode+')'}</td>
                    </tr>
                    <tr>
                        <th>From Date</th><td>{moment(fromDate).format('DD/MM/YY')}</td>
                    </tr>
                    <tr>
                        <th>To Date</th><td>{moment(toDate).format('DD/MM/YY')}</td>
                    </tr>
                    <tr>
                        <th> Menu Created At</th><td>{moment(createdAt).format('DD/MM/YY')}</td>
                    </tr>
                </table>

                <table className="mt-3"
                    frame="true" bgcolor="transparent" rules="rows" summary="Show order items">
                   <thead>
    <tr>
      <th colSpan="2" style={{ textAlign: "center" }}>Menu</th>
    </tr>
    <tr>
      <th>Meal Type</th>
      <th>Items/Brands</th>
    </tr>
  </thead>
  <tbody>
    {menuSetting?.sort((a,b)=>{
const catIdA=parseInt(a.mealCategoryId.split('-').pop())
const catIdB=parseInt(b.mealCategoryId.split('-').pop())
return catIdA-catIdB;
    }).map((cat,catIndex) => (
     <>
      { cat.mealsSelection.length>0? <tr  key={cat.mealCategoryId}>
          <td colSpan="2"  className="spl-row-class"  style={{ textAlign: "center",width:"50%",fontSize:'125%',fontWeight:'bold' }} >{cat.mealName||""}</td>
        </tr>:<></>}
        {cat.mealsSelection.map((meal,mealIndex) =>
          meal.items.map((item,itemIndex) => (
            <>
            {mealIndex === 0 || itemIndex === 0 || meal.mealType !== menuSetting[catIndex].mealsSelection[mealIndex - 1].mealType ? (
            <tr key={item.itemId}>
              {mealIndex === 0 || itemIndex === 0 ? (
                <td>{meal.mealType}</td>
              ) : (
                <td></td>
              )}
              <td style={{width:"50%" }} >{item.itemName}</td>
            </tr>
          ) : (
            <tr key={item.itemId}>
              <td></td> {/* Empty cell */}
              <td  style={{width:"50%" }}>{item.itemName}</td>
            </tr>
          )}
            </>
          ))
        )}
      </>
    ))}
  </tbody>
</table>

            </>
            : <div className="loadingDiv">Loading...</div>}
        </>
    )
}