/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Tree } from "react-arborist";
import { getSelectedGroupPath, TreeNode, Tabs, Breadcrumb } from "./Cards";
import moment from "moment";

export const ControlPanelView = (props) => {
  const { t } = useTranslation();
  const { groups, selectedDate, show, TABS, selectedTab, viewId, RightComponent } = props;
  const treeRef = useRef(null)
  const resizeRef = useRef(null)
  const listRef = useRef(null)
  const contentRef = useRef(null)
  const rightRef = useRef(null)
  const resizeEle = useRef({ leftWidth: 0, x: 0, y: 0 })
  const getRootItem = () => {
    const dateString = moment(selectedDate).format('YYYY-MM-DD')
    return { icon: 'date', type: 'svg', id: 'root', name: t(`pages.controlPanel.views.${viewId}.dateGroup`, { date: dateString }) }
  }
  const [state, setState] = useState({ contentHeight: 200, selectedGroup: null, groups: [getRootItem()] })
  const getTree = () => {
    const dateString = moment(selectedDate).format('YYYY-MM-DD')
    const root = { icon: 'date', type: 'svg', id: 'root', name: t(`pages.controlPanel.views.${viewId}.dateGroup`, { date: dateString }) }
    return [{ ...root, children: groups }]
  }
  const getContainerHeight = () => {
    let contentHeight
    if (contentRef.current) {
      contentHeight = contentRef.current.clientHeight
    }
    if (contentHeight && state.contentHeight !== contentHeight) {
      setState((_) => ({ ..._, contentHeight }))
    }
  }

  useEffect(() => {
    let timer = setTimeout(() => {
      clearTimeout(timer);
      if (treeRef.current && treeRef.current.root) {
        treeRef.current.root.children.forEach((dateNode) => {
          if (!treeRef.current.isOpen(dateNode.id)) {
            treeRef.current.open(dateNode.id);
          }
        })
      }
    }, 100)
  }, [groups])
  useEffect(() => {
    if (show) {
      getContainerHeight()
    }
  }, [show])
  const handleGroupSelect = (group) => {
    const { selectedGroup } = state;
    if (!selectedGroup || (selectedGroup && selectedGroup.id !== group.id)) {
      setState((_) => ({ ..._, selectedGroup: group }))
      props.onSelectGroup && props.onSelectGroup(group)
    }
  }
  const Node = (props) => {
    const { node } = props
    const handleClick = (e) => {
      node.toggle();
      node.handleClick(e)
      handleGroupSelect(node.data)
    }
    return (
      <TreeNode {...props} onClick={handleClick} />
    );
  }
  const handleMove = (e) => {
    const leftSide = resizeRef.current.previousElementSibling;
    const rightSide = resizeRef.current.nextElementSibling
    resizeRef.current.style.cursor = 'col-resize';
    document.body.style.cursor = 'col-resize';
    leftSide.style.userSelect = 'none';
    leftSide.style.pointerEvents = 'none';
    rightSide.style.userSelect = 'none';
    rightSide.style.pointerEvents = 'none';
    const { x, y, leftWidth } = resizeEle.current
    const dx = e.clientX - x;
    const dy = e.clientY - y;

    const newLeftWidth = ((leftWidth + dx) * 100) / resizeRef.current.parentNode.getBoundingClientRect().width;
    leftSide.style.width = `${newLeftWidth}%`;
  }
  const handleMouseUp = (e) => {
    const leftSide = resizeRef.current.previousElementSibling;
    const rightSide = resizeRef.current.nextElementSibling
    resizeRef.current.style.removeProperty('cursor');
    document.body.style.removeProperty('cursor');

    leftSide.style.removeProperty('user-select');
    leftSide.style.removeProperty('pointer-events');

    rightSide.style.removeProperty('user-select');
    rightSide.style.removeProperty('pointer-events');

    // Remove the handlers of `mousemove` and `mouseup`
    document.removeEventListener('mousemove', handleMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }
  const handleMouseDown = (e) => {
    const leftSide = resizeRef.current.previousElementSibling
    resizeEle.current.x = e.nativeEvent.clientX;
    resizeEle.current.y = e.nativeEvent.clientY;
    resizeEle.current.leftWidth = leftSide.getBoundingClientRect().width;
    document.addEventListener('mousemove', handleMove);
    document.addEventListener('mouseup', handleMouseUp);
  }
  const treeNodes = getTree();
  const path = getSelectedGroupPath(treeNodes, state.selectedGroup)
  return (
    <div className={`full view-sections scrollHide ${show ? 'flexRow' : 'hide'}`}>
      <div className="flexRow full-flex scrollHide" ref={contentRef} >
        <div className="flexCol list scrollHide" id="tree-list" ref={listRef} style={{ height: state.contentHeight }}>
          <Tree openByDefault={false} ref={treeRef} height={600} width={500} data={treeNodes} className="tree">{Node}</Tree>
        </div>
        <div className="resizer" ref={resizeRef} onMouseDown={handleMouseDown}></div>
        <div className="flexCol full-flex scrollHide" style={{ height: state.contentHeight }}>
          <Tabs TABS={TABS} view={viewId} selectedTab={selectedTab} onClick={props.onTabSelect} />
          <Breadcrumb path={path} />
          {props.children}
        </div>
      </div>
      <div className="flexCol alerts scrollHide" style={{ height: state.contentHeight }}>
        <RightComponent />
      </div>
    </div>
  )
}