import React, { useState } from 'react'
import '../assets/styles/modal.scss'
import closeIcon from '../images/icons/Icon2.png'
import { useTranslation } from 'react-i18next';

export const Cart = (props) => {
  const { t } = useTranslation();

  return (
      <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar">
        <div className="dialog__content">
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">{props.title}</h2>
          <hr />
          { props.errorMsg ?
          <div id="cartInput" className="dialog__body_container flexWrap">
            <button className="add-item-button margLeft" value={0} onClick={props.onAddCart}>+ Add</button> (Upto 4)
            {
              props.errorMsg ?
              <span className="floatRight error-msg">Reached the maximum of 4</span>
            :null
            }
            <div className="dialog__center flexRow mt-4">
              <div>
                <label className="small-body-text-regular fontRegular">Cart Number*</label><br></br>
                <input id="cartNo" type='text' className='edit-input' />
              </div>
              <div className="mx-4">
                <label className="small-body-text-regular fontRegular">Seal Number*</label><br></br>
                <input id="sealNo" type='text' className='edit-input' />
             </div>
             <button className="mt-4 modal-remove-button" onClick={props.onRemoveCart}> <img className="" alt='Close' src={closeIcon} /></button>
            </div>
          </div> :
          <div className="dialog__footer flexRow justifyContentSpaceEvenly">
            <h6>{t('pages.inventoryContent.uplifts.noCartsAdded')}</h6>
          </div> }
        </div>
        <div className="dialog__footer flexRow justifyContentSpaceEvenly">
          <button className="uploadImage-button" onClick={props.onClose}>{t('pages.inventoryContent.uplifts.close')}</button>
          <button className="done-button" onClick={props.onSubmit}>{t('pages.inventoryContent.uplifts.saveCarts')}</button>
        </div> 
      </div>
    </div>
  );
}
export const CartModal = (props) => {
  const [count, setCount] = useState(0);
  const [errorMsg, setErrorMsg] = useState(false);

  const onSubmit = (e) => {
    removeContainerOverlay();
  }

  const onClose = (e) => {
    removeContainerOverlay();
    props.onClose();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  const onAddCart = (e) =>{
    setCount(count + 1)
    if(count < 3){
      var newText = document.createElement( 'div' ); 
      newText.innerHTML = (`
        <div  id=${'cart'+count} class="dialog__center flexRow mt-4">
        <div>
          <label class="small-body-text-regular fontRegular">Cart Number*</label><br/>
          <input id="cartNo" type='text' class='edit-input' />
        </div>
        <div class="mx-4">
          <label class="small-body-text-regular fontRegular">Seal Number*</label><br/>
          <input id="sealNo" type='text' class='edit-input' />
       </div>
       <button class="mt-4 modal-remove-button" onClick=${onRemoveCart()}> <img class="" alt='Close' src=${closeIcon} /></button>
      </div>
        `);
      (document.querySelectorAll('#cartInput')[0]).appendChild(newText);
    }else{
      setErrorMsg(true);
    }
  }

  const onRemoveCart = () => {
   
  }

  return (
    <Cart
      title={props.title}
      onClose={onClose}
      onAddCart={onAddCart}
      onRemoveCart={onRemoveCart}
      errorMsg={errorMsg}
      onSubmit={(e) => onSubmit(e)}>
    </Cart>
  )
}