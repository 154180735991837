import React, { useState } from 'react';
import uploadItemIcon from '../assets/icons/upload-items.svg';
import DeviceStatus from '../assets/icons/device-util.svg';
import downloadMasterIcon from '../assets/icons/download-master.svg';
import { useTranslation } from 'react-i18next';
import EditIcon from '../assets/edit.svg';
import DeleteIcon from '../assets/delete.svg';
import LoadingIn from '../assets/loadingIn.gif';
import { APIEndpoint } from '../utils/axios';

export const EditIconButton = (props) => {
  return (
    <button {...props}>
      <img src={EditIcon} className="edit-icon" title={"Capture Denominations"} alt='edit Denomination' />
    </button>
  )
}
export const DeleteIconButton = (props) => {
  return (
    <button {...props}>
      <img src={DeleteIcon} className="icon" alt='delete Denomination' />
    </button>
  )
}

export const IconButton = (props) => {
  const { loading, icon, label, className, ...rest } = props
  return (
    <button className={className==='reports_button'? "reports_button":className!=='reports_button'?`upload dropdownStyle1-TextMedium ${className || ''}`:`upload dropdownStyle1-TextMedium`} {...rest}>
      {
        Boolean(loading)&&className!=='reports_button' ?
          <img src={LoadingIn} alt='loading' className='loading' />
          : Boolean(icon) ?
            <img className="icon-size mt-1" alt='uploadItem' src={icon} />
            : null
      }
      <span>{label}</span>
    </button>
  )
}
export const UploadButton = (props) => {
  return (
    <IconButton icon={uploadItemIcon} label={props.label || 'Upload Items'} {...props} />
  )
}
export const DeviceStat = (props) => {
  return (
    <IconButton icon={DeviceStatus} label={props.label || 'Device Statistics'} {...props} />
  )
}

export const DownloadButton = (props) => {
  const { t } = useTranslation()
  const { onClick, downloadLink,fileName, label, ...rest } = props;
  const [loading, setLoading] = useState(false)
  const handleClick = async (e) => {
    if (Boolean(downloadLink)) {
      setLoading(true);
      try {
        let payload = props.payload || {}
        let res = await APIEndpoint.post(downloadLink, payload, { 'Accept': 'text/csv' });
        if(res.status==='scheduled'){
          typeof props.updateStatus === 'function' &&props.title&&props.title==="Download Vendors" && props.updateStatus({ rept: "Vendors", stat: "Downloading..." });
          typeof props.updateStatus === 'function' &&props.title&&props.title==="Download Devices Activity" && props.updateStatus({ rept: "Devices Activity", stat: "Downloading..." });

          setTimeout(() => {
            const dowId = APIEndpoint.get(`downloads/status/${res.id}`);
            dowId.then( async (val)=> {
              if(val.status==="completed"){
                 APIEndpoint.get(`downloads/analytics/${val.fileName}${val.fileExtension}`).then((ItemMasterDwnld) => {
                  const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                  const link = document.createElement('a');
                  let _fileName = val.fileName + val.fileExtension
                  link.href = url;
                  link.setAttribute('download',  _fileName);
                  document.body.appendChild(link);
                  link.click();
                  typeof props.updateStatus === 'function' &&props.title&&props.title==="Download Vendors" && props.updateStatus({ rept: "Vendors", stat: "Success" });
                  typeof props.updateStatus === 'function' &&props.title&&props.title==="Download Devices Activity" && props.updateStatus({ rept: "Devices Activity", stat: "Success" });
                  setLoading(false);
                  });
              }
            })
            }, 7000);
        }
        }
        catch (res) {
          typeof props.updateStatus === 'function' &&props.title&&props.title==="Download Vendors" && props.updateStatus({ rept: "Vendors", stat: "Failed" });
          typeof props.updateStatus === 'function' &&props.title&&props.title==="Download Devices Activity" && props.updateStatus({ rept: "Devices Activity", stat: "Failed" });
          console.log(res.message)
        }
      // setLoading(false);
    } else if (typeof onClick === 'function') {
      onClick(e)
    }
  }
  return (
    <IconButton
      icon={downloadMasterIcon}
      loading={loading}
      onClick={handleClick}
      label=<u>{loading ? t('buttons.downloading') : (props.label || 'Download Master')}</u>
      {...rest} />
  )
}