import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { CateringCatering } from "./CateringCatering";
import { CateringUplifts } from "./CateringUplifts";
// import { CateringReconciliations } from "./CateringReconciliations";
import { ControlPanelView } from "./PanelView";
import { DFPAPIEndpoint } from "../../../utils/axios";
import { ItemGridView, MainColumns, Columns, EstimateTitle, EmainOrDownload } from "./Cards";
import moment from "moment-timezone";

const TABS = ['catering', 'uplifts']

const Estimates = (props) => {
  const { t } = useTranslation();
  const { selectedGroup } = props
  return (
    <div className="flexCol full-flex scrollHide">
      <h6 className="flexCol flex-ctr hor-ctr w-100 title h4">{t(`pages.controlPanel.views.catering.estimates`)}</h6>
      {
        Boolean(selectedGroup && selectedGroup.model && selectedGroup.model.estimates) ?
          <div className="flexCol full-flex scrollY">
            {
              selectedGroup.model.estimates.map(estimate => {
                return (
                  <div className="flexRow hor-ctr alert alert-info estimate w-100 space-btn" key={estimate.id} onClick={() => props.onEstimate(estimate)}>
                    <span className="h7">{moment(estimate.scheduledTimeOfExecution).tz(moment.tz.guess()).format("DD-MM-YYYY hh:mm")} - {estimate.relativeExecutionTime}</span>
                    <EmainOrDownload />
                  </div>
                )
              })
            }
          </div>
          :
          <div className="flexCol full-flex flex-ctr hor-ctr">
            <h6 className="h8">{t(`pages.controlPanel.views.catering.selectGroupForEstimate`)}</h6>
          </div>
      }
    </div>
  )
}

export const ControlPanelCatering = (props) => {
  const { t } = useTranslation();
  const { selectedDate, show, flightPlans } = props;
  const [state, setState] = useState({ tab: 'catering', selectedGroup: null })
  const getGroupsInTree = () => {
    if (flightPlans) {
      const { groups } = flightPlans
      let tree = {}
      if (groups) {
        groups.forEach((group, i) => {
          group.cateringGroups.forEach((catGroup, j) => {
            const locationCode = catGroup.airport.locationCode
            if (!tree[locationCode]) tree[locationCode] = [];
            tree[locationCode].push({
              id: catGroup.code, name: catGroup.code, isOpen: false, model: {
                lavel: i, index: j,
                code: catGroup.code,
                locationCode: locationCode,
                plan: catGroup.plan,
                date: catGroup.startTime,
                cateringGroupId: catGroup.id,
                estimates: catGroup.estimates,
                latestEstimate: catGroup.latestEstimate,
                uplift: catGroup.uplift
              },
            })
          })
        })
      }
      tree = Object.keys(tree).map((key, i) => {
        return { id: key, name: key, children: tree[key], type: 'expand', isOpen: false, model: { lavel: 0, index: i, locationCode: key } }
      })
      tree.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })
      return tree;
    }
    return []
  }
  const handleConfirm = async () => {
    const { status } = state.cateringGroup
    try {
      setState((_) => ({ ..._, cateringGroup: null }))
      await DFPAPIEndpoint.post('operations/cateringGroups/estimates/cateringConfirm', { estimateId: status.id })
    } catch (error) {

    }
  }
  const handleEstimateClick = async (estimate) => {
    const { selectedGroup } = state
    try {
      const res = await DFPAPIEndpoint.get('operations/cateringGroups/estimates/' + estimate.id)
      if (res && res.estimate) {
        setState((_) => ({ ..._, cateringGroup: { status: res.estimate, group: selectedGroup } }))
      }
    } catch (error) {

    }
  }
  const handleCateringClick = async (group) => {
    try {
      const status = await DFPAPIEndpoint.post('operations/cateringGroups/status', { cateringGroupId: group.model.cateringGroupId })
      if (status) {
        setState((_) => ({ ..._, cateringGroup: { status, group: group } }))
      }
    } catch (error) {

    }
  }
  const getCateringModelTitle = () => {
    const { model, id } = state.cateringGroup.group
    let title = t(`pages.controlPanel.views.catering.estimates`) + ' - ' + id;
    if (model.latestEstimate) {
      title += ` - ${model.latestEstimate.relativeExecutionTime}`
    }
    return title
  }
  const groups = getGroupsInTree()
  console.log("state.cateringGroup", state.cateringGroup);
  return (
    <ControlPanelView
      TABS={TABS}
      show={show}
      viewId='catering'
      groups={groups}
      selectedDate={selectedDate}
      selectedTab={state.tab}
      onTabSelect={(tab) => setState((_) => ({ ..._, tab: tab }))}
      onSelectGroup={(selectedGroup) => setState((_) => ({ ..._, selectedGroup: selectedGroup }))}
      RightComponent={() => {
        return <Estimates selectedGroup={state.selectedGroup} groups={groups} onEstimate={handleEstimateClick} />
      }}>
      <CateringCatering
        show={state.tab === TABS[0]}
        selectedGroup={state.selectedGroup}
        groups={groups}
        onCateringCard={handleCateringClick}
      />
      <CateringUplifts show={state.tab === TABS[1]} selectedGroup={state.selectedGroup} groups={groups} />
      {/* <CateringReconciliations show={state.tab === TABS[2]} group={state.selectedGroup} groups={groups} /> */}
      {
        Boolean(state.cateringGroup) &&
        <ItemGridView
          showEmail
          TitleComponent={() => {
            return (
              <EstimateTitle cateringGroup={state.cateringGroup} />
            )
          }}
          SubTitle={() => {
            const { locationCode, code } = state.cateringGroup.group.model
            return (
              <span className="margBottom10">
                <code>{locationCode}</code> | <span>{code}</span>
              </span>
            )
          }}
          onConfirm={handleConfirm}
          title={getCateringModelTitle()}
          Columns={MainColumns.concat(Columns)}
          confirmed={state.cateringGroup.status.cateringStatus === 'confirmed'}
          onClose={() => setState((_) => ({ ..._, cateringGroup: null }))}
          cateringGroup={state.cateringGroup}
        />
      }
    </ControlPanelView>
  )
}