export const languages = [
  {
    code: 'sp',
    name: 'Español',
    country_code: 'sp',
  },
    {
      code: 'fr',
      name: 'Français',
      country_code: 'fr',
    },
    {
      code: 'en',
      name: 'English',
      country_code: 'en',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'ar',
    },
];