/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import '../assets/styles/modal.scss'
import closeIcon from '../images/icons/Icon2.png'
import { gstTypes } from '../utils/configs'
import deleteIcon from '../assets/delete.svg'
import { APIEndpoint } from '../utils/axios';
import Currency from '../assets/json/currency.json'
import Select from 'react-select'

const filterData = {
  action: 4,
  collection: '',
  filterOperation: 'or',
  filters: [],
  limit: 1000,
  page: 1,
  searchParam: ''
};
export const CartAdd = (props) => {

  let isError = false;
const {itemId,title}=props;

  const customStyles = {
    input: (defaultStyles) => ({
      ...defaultStyles,
      color: '#fff',
    }),
    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#162E3E",
      width: '12.5rem',
      height:'38px',
      marginBottom:'3px', 
      border: "0.4px solid #FFFFFF",
      boxShadow: "none",
    }),
    option: (defaultStyles) => ({
      ...defaultStyles,
      color: '#fff',
      backgroundColor: '#162E3E',
      textAlign: 'left',
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: '#fff',
    }),
    placeholder:(defaultStyles)=>({
      ...defaultStyles,
    whiteSpace:'no-wrap'
    })
  }


  let updateStatusText = '';
  if (props.modalFormStatusObj) {
    if ( ('text' in props.modalFormStatusObj)) {
      updateStatusText = props.modalFormStatusObj.text;
    }
    if ( ('error' in props.modalFormStatusObj)) {
      isError=props.modalFormStatusObj.error;
      updateStatusText = props.modalFormStatusObj.error;
    }
  }

const onChangeCurrency=(e)=>{
  props.setCurrencySel([e])
}

const onChangeGStType=(e)=>{
  props.setGstType(e.target.value)
}

  return (
      <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar">
        <div className="dialog__content">
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">{props.title}</h2>
          <hr />

          <h2 className="my-3 subheading-text-medium fontMedium">Basic Details</h2>
          <div class="dialog__body_container flexRow justifyContentSpaceBetween flexWrap">

            <div class="flexCol justifyContentSpaceEvenly ">
              {itemId.length?<div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="req">Item Code</label>

                    <Select
                    options={itemId}
                    id="itemCode"
                    defaultValue={props.itemsSelected[0]?.label?props.itemsSelected[0]?.label:''}
                    value={itemId.filter(function(option) {
                    return props.itemsSelected.length &&  props.itemsSelected.find(sc=>sc?.value===option?.value)
                    })}
                       styles={{
                    ...customStyles,
                    control: (defaultStyles, state) => ({
                      ...defaultStyles,
                      cursor: state.isDisabled || title === 'Edit Item' ? 'not-allowed' : 'pointer',
                      backgroundColor:'#162f3e', 
                    }),
                  }}
                  
                    placeholder='Select Item Code'
                    onChange={props.selectOptions}
                    isSearchable
                    isMulti={false}
                    isDisabled={ title==='Edit Item'}
                    isClearable
                    className='selectMenu'
                    />

         
                </div>
              </div>:null}
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
            { itemId.length? <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="req">Item Name</label>
                  <Select
                  options={itemId.map(item=>({...item,label:item.name}))}
                  id="itemName"
                  defaultValue={props.itemsSelected[0]?.name?props.itemsSelected[0]?.name:''}
                  value={itemId.map(item=>({...item,label:item.name})).filter(function(option) {
                  return props.itemsSelected.length &&  props.itemsSelected.find(sc=>sc?.value===option?.value)
                  })}
                  styles={{
                    ...customStyles,
                    control: (defaultStyles, state) => ({
                      ...defaultStyles,
                      cursor: state.isDisabled || title === 'Edit Item' ? 'not-allowed' : 'pointer',
                      backgroundColor:'#162f3e', 
                    }),
                  }}
                  
                  placeholder='Select Item Name'
                  onChange={props.selectOptions}
                  isSearchable
                  isDisabled={title==='Edit Item'}
                  isMulti={false}
                  isClearable
                  className='selectMenu'
                  />

                </div>
              </div>:null}
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="req">Category</label>
                  <input disabled id="category" type="select" placeholder="--Select Category--" className='select-input' name="name" attribute="name" value={props.editData?.category?.value} />
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="req">Sub Category</label>
                  <input disabled id="subCategory" type="select" placeholder="--Select Sub Category--"  className='select-input' name="name" attribute="name" value={props.editData?.subCategory?.value} />
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="">Barcode</label>
                  <input disabled id="barcode" type="text" placeholder="Barcode" class="edit-input" name="shortName" attribute="shortName" value={props.editData?.barcode} />
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="">Section</label>
                  <input disabled id="section" type="select" placeholder="--Select Section--"  className='select-input' name="shortName" attribute="shortName"  value={props.editData?.section} />
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="req">Unit</label>
                  <input disabled id="unit" type="select" placeholder="--Select Unit--"  className='select-input' name="amount" attribute="amount"  value={props.editData?.unit} />
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="req">Base Unit</label>
                  <input disabled id="baseUnit" placeholder="Base Unit" type="number" class="edit-input" name="amount" attribute="amount"  value={props.editData?.baseUnit} />
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="req">Color Code</label>
                  <input disabled id="colorCode" placeholder="Color Code" type="number" class="edit-input" name="amount" attribute="amount"  value={props.editData?.colorCode} />
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container margTop36">
                <div class="dialog__description">
                  <label class="req">Replenishable</label>
                  <input disabled id="replenishable" placeholder="Replenishable" type="number" class="edit-input" name="amount" attribute="amount"  value={props.editData?.replenishable} />
                  <p className='small-body-text-regular-subscript'>(Place an order even after stock is exhausted)</p>
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="">Description</label>
                  <textarea disabled id="description" placeholder="Description" name="description" attribute="description" rows="4" class="edit-input" value={props.editData?.description}/>
                </div>
              </div>
            </div>
          </div>

          <h2 className="my-3 subheading-text-medium fontMedium">Images</h2>
          <div class="row">
            <div class="col-xs-11 wrapper" style={{ marginLeft: '12px' }}>
              <p><h6>No Images Added</h6>
              </p>
            </div>
          </div>
{props.priceList?<>    <h2 className="my-3 subheading-text-medium fontMedium">Price Details</h2>
          <div class="dialog__body_container flexRow justifyContentSpaceBetween flexWrap">

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="req">Price List</label>
                  <input disabled id="" type="select" className='select-input' name="shortName" attribute="shortName" value={props.priceList} />
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="req">Price</label>
                  <input  id="itemPrice" type="text" class="edit-input" name="amount" attribute="amount" value={props.editData?.itemPrice} />
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="">HSN</label>
                  <input disabled id="hsn"  type="text" class="edit-input" name="name" attribute="name" value={props.editData?.hsn}/>
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="req">GST Type</label>
                  <select
                  id="gstType"
                  name="type"
                  onChange={onChangeGStType}
                  value={props.gstType}
                  attribute="type"
                  className='select-input'
              >
              {
                gstTypes.map((_, index) => {
                    return (
                      <option value={_.value} key={index}>{_.label}</option>
                    )
                })
              }
            </select>

                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="req">CGST</label>
                  <input id="cgst" disabled type="text" class="edit-input" name="shortName" attribute="shortName" value={props.editData?.cgst} />
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="req">SGST</label>
                  <input id="sgst" disabled type="text" class="edit-input" name="shortName" attribute="shortName" value={props.editData?.sgst} />
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="req">UTGST</label>
                  <input  id="utgst" disabled type="number" class="edit-input" name="amount" attribute="amount" value={props.editData?.utgst}/>
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="req">IGST</label>
                  <input id="igst" disabled type="number" class="edit-input" name="amount" attribute="amount"  value={props.editData?.igst}/>
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="req">CESS</label>
                  <input  id="cess" disabled type="number" class="edit-input" name="amount" attribute="amount"  value={props.editData?.cess}/>
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="">Service Charge</label>
                  <input id="" type="number" class="edit-input" name="amount" attribute="amount"  value={props.editData?.serviceCharge}/>
                </div>
              </div>
            </div>

          </div>

          <h2 className="my-3 subheading-text-medium fontMedium">International Pricing</h2>
           <div class="dialog__body_container flexRow justifyContentSpaceBetween flexWrap">
            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
              <div class="dialog__description">
                  <label class="req">Currency</label>

                    <Select
                    options={Currency.map((item,index)=>({...item,key:index,label:`${item.currency}(${item.code})`,value:item.code}))}
                    id="currency"
                    defaultValue={props.currencySel[0]?.name?props.currencySel[0]?.name:''}
                    value={Currency.map((item,index)=>({...item,key:index,label:`${item.currency}(${item.code})`,value:item.code})).filter(function(option) {
                    return props.currencySel.length &&  props.currencySel.find(sc=>sc?.label===option?.label)
                    })}
                    styles={customStyles}
                    placeholder='Start Typing a currency name...'
                    onChange={onChangeCurrency}
                    isSearchable
                    isMulti={false}
                    isClearable
                    className='selectMenu'
                    />

                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                  <label class="">Amount</label>
                  <input id="amount" type="number" class="edit-input" name="amount" attribute="amount" />
                </div>
              </div>
            </div>

            <div class="flexCol justifyContentSpaceEvenly ">
              <div class=" dialog__container_item input-container">
                <div class="dialog__description">
                <button type='submit' className="btn btn-primary" onClick={props.onAddCurrency}>Add</button>
                </div>
              </div>
            </div>
          </div>
          Prices
          <div class="row">
            { props.currencyItem ?
            <div class="col-xs-11 wrapper" style={{ marginLeft: '12px',background: 'none' }}>
              <p>No Prices Added Yet</p>
            </div>
            :
            <table id="table-currency" className="my-2" style={{ marginLeft: '12px' , width: '98%'}} border="1">
              <tbody id='table-currency-body'>
                <tr id="cur-0">
                  <td style={{border:'none'}}>{props.initialCurrency?.currency}</td>
                  <td style={{border:'none'}}>{props.initialCurrency?.amt}</td>
                  <td style={{border:'none',width:"8%"}}><img  style={{height:"27px"}} alt='currency-delete' src={deleteIcon}  onClick={props.handleDelete}/></td>
                </tr>
              </tbody>
          
            </table>
          }
          </div></>:null}


        {updateStatusText ?
          <>
            <div className={ "dialog__statusBlock " + (isError ? 'alert_error' : 'alert_success') }>
              {updateStatusText}
            </div>
          </> : null }
        </div>
        <div className="dialog__footer flexCol alignItemsCenter">
          <button className="done-button" onClick={props.onSubmit}>{props.title === 'Edit Item' ? 'Update' : 'Save'}</button>
        </div>
      </div>
    </div>
  );
}

export const AddProductModal = (props) => {
  const [currencyItem,setCurrencyItem] = useState(true);
  const [currencySel,setCurrencySel] = useState([]);
  const [currencyCount,setCurrencyCount] = useState(0);
  const [itemsSelected,setItemsSelected]=useState([]);
  const [initialCurrency,setInitialCurrency] = useState('');
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [updated, setUpdated] = useState([]);
  const [gstType,setGstType]=useState("");
  const [editData, setEditData] = useState({});
  const [itemId, setItemId] = useState([]);

  useEffect( () => {
    if(props.title === 'Edit Item'){
      onEditFields()
    }
    fetchItemsList();
  }, [] );

  useEffect( () => {
    if(editData && itemId.length){
      setGstType(editData.gstType)
    setItemsSelected(itemId.filter(il=>il.value===editData.itemId))
    }
  }, [editData,itemId] );

  useEffect(() => {
    setModalFormStatusObj({...props.modalFormStatusObj})
  }, [props.modalFormStatusObj])

  const fetchItemsList = async () => {
    try {
      const itemsRes = await APIEndpoint.get('items/enumerate', filterData);
    const itemIdList = [{ key: 0, name: "Select Item Name", label: "Select Item Code" }];

    if (itemsRes) {
      itemsRes.items.filter( row => {
          itemIdList.push({ key: row.itemId, name: row.itemName, value: row.itemId, label: row.itemCode });
          return row;
      });
      setItemId(itemIdList)
    }
    } catch (error) {

    }

  };
  const onAddCurrency = async(e) => {
    setCurrencyCount(currencyCount + 1);
    const amount = document.getElementById('amount').value

    if(!currencySel.length || !amount){
      setModalFormStatusObj({ error: "Enter Currency and Amount" });
      return 0
    }
    let currency_array = {
      id: currencyCount,
      currency: currencySel.length?currencySel[0]?.value:"",
      amt: document.getElementById('amount').value
    }
    setCurrencySel([]);
    document.getElementById('amount').value = ""
    setCurrencyItem(false)
    if(currencyCount === 0){
      setInitialCurrency(currency_array);
    }
    const tbody = document.getElementById('table-currency-body');
    if(tbody){
      const element = document.createElement('tr');
      element.setAttribute("id", 'cur-'+currencyCount);
      tbody.appendChild(element).innerHTML= tableInput(currency_array);
    }
    setModalFormStatusObj({ text: "" });
  }

  const tableInput=(data)=>{

    return (`
    <td style="border:none">${data.currency}</td>
    <td style="border:none">${data.amt}</td>
    <td style="border:none;width:8%;cursor:pointer"><img style="height:27px" alt='currency-delete' src=${deleteIcon}  onClick="handleDelete(${data.id})"/></td>
    `
    )
  }

  const mapEditCurrency = async(prices)=>{
    setCurrencyItem(false)
    const css = document.getElementById('table-currency');

    prices.map((_) => {
      var row = css.insertRow(0);
      row.setAttribute("id", 'cur-'+currencyCount);
      var cell1 = row.insertCell(0);
      cell1.style.borderStyle = "none";
      var cell2 = row.insertCell(1);
      cell2.style.borderStyle = "none";
      var cell3 = row.insertCell(2);
      cell3.style.borderStyle = "none";

      cell1.innerHTML = _.currencyName+"("+_.currencyCode+")";
      cell2.innerHTML = _.amount;
      cell3.innerHTML = `<img className="icon" style="height:27px" alt='currency-delete' src=${deleteIcon}  onClick="handleDelete(${currencyCount})"/>`
      setCurrencyCount(currencyCount+1)
      return 0
  })
  }

  window.handleDelete = (id) => {
    if(typeof id === 'object'){
      id = 0;
    }
    const css = document.querySelectorAll('#cur-'+id)[0];
    css.remove();
    const tr_exist = document.querySelector(`[id^="cur-"]`)
    console.log(!tr_exist);
    if(!tr_exist){
      setCurrencyItem(true)
    }
  }
  const selectOptions = async (e) => {
 
    setItemsSelected([e])
    if(![e].includes(null)){

    const itemData = await APIEndpoint.get('inventory/items/'+[e][0].value, filterData);
    let result = itemData.result
    if(result){
      const {hsn}=result
      const res2=await APIEndpoint.get(`taxes/?limit=${1}&hsn=${parseInt(hsn)}`);
      if(res2.success){
        const {sgst,utgst,igst,cess,cgst,hsn,id}=res2.items[0]
        document.getElementById('hsn').value =hsn
        document.getElementById('sgst').value = sgst
        document.getElementById('utgst').value = utgst
        document.getElementById('cgst').value = cgst
        document.getElementById('igst').value = igst
        document.getElementById('cess').value = cess
    document.getElementById('barcode').value = result.barcode
    document.getElementById('baseUnit').value = result.baseUnit
    document.getElementById('section').value = result.section
    document.getElementById('unit').value = result.unit
    document.getElementById('colorCode').value = result.colorCode
    document.getElementById('replenishable').value = result.replenishable
    document.getElementById('description').value = result.description
    document.getElementById('category').value = result.category.value
    document.getElementById('subCategory').value = result.subCategory.value
    if(result.hsn){
      document.getElementById('hsn').disabled = true
    }

    let data = {
      gstType:gstType,
      hsn: result.hsn,
      intlPrices: [],
      itemCode: result.itemCode,
      itemName: result.itemName,
      itemPrice: 55,
      listName: props.priceList,
      taxDetails: {
        cess,
        cgst,
        hsn,
        id,
        igst,
        sgst,
        utgst
      },
    }
    setUpdated(data)
    }
      }
    }else{
      document.getElementById('hsn').value =""
        document.getElementById('sgst').value = ""
        document.getElementById('utgst').value = ""
        document.getElementById('cgst').value = ""
        document.getElementById('igst').value = ""
        document.getElementById('cess').value = ""
       document.getElementById('itemName').value = ""
    document.getElementById('barcode').value = ""
    document.getElementById('baseUnit').value =""
    document.getElementById('section').value =""
    document.getElementById('unit').value =""
    document.getElementById('colorCode').value = ""
    document.getElementById('replenishable').value = ""
    document.getElementById('description').value =""
    document.getElementById('category').value = ""
    document.getElementById('subCategory').value =""


  }
  }
  const onSubmit = async (e) => {
    if(updated.length === 0){
      setModalFormStatusObj({ error: "Select Item Name or Code" });
    }
    setCurrencyCount(0)
    var Row = document.querySelectorAll(`[id^="cur-"]`)
    let intlPrices = [];

    Row.forEach(head => {
      var Cells = head.getElementsByTagName("td");
      intlPrices.push({
        currencyCode: Cells[0].innerText.slice(-4,-1), currencyName: Cells[0].innerText.slice(0,-5), amount:Cells[1].innerText
      })
      return 0
    });
    updated.intlPrices = intlPrices
    updated.gstType=gstType
    props.onSubmit(e, updated)
    removeContainerOverlay();
  }

  const onEditFields = async() => {
    let itemId = props.fields.itemId
    try{
      const itemData = await APIEndpoint.get('item/'+itemId+'?pl='+props.priceList, filterData);
      let result = itemData.result
      setEditData(result)
      setUpdated(result)
      if(result.intlPrices && result.intlPrices?.length>0){
        mapEditCurrency(result.intlPrices);
      }
    }catch(e){

      console.log("error: "+e)
    }
  }

  const onClose = (e) => {
    removeContainerOverlay();
    props.onClose();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <CartAdd
      title={props.title}
      onClose={onClose}
      priceList={props.priceList}
      onAddCurrency={(e) => onAddCurrency(e)}
      selectOptions={(e) => selectOptions(e)}
      itemsSelected={itemsSelected}
      itemId={itemId}
      setCurrencySel={setCurrencySel}
      setItemsSelected={setItemsSelected}
      setGstType={setGstType}
      gstType={gstType}
      currencySel={currencySel}
      editData={editData}
      currencyItem={currencyItem}
      initialCurrency={initialCurrency}
      handleDelete={(e) => window.handleDelete(e)}
      modalFormStatusObj={modalFormStatusObj}
      onSubmit={(e) => onSubmit(e)}>
    </CartAdd>
  )
}
