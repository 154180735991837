
import React, { useState, useEffect } from 'react'
import '../../../assets/styles/modal.scss'
import closeIcon from '../../../images/icons/Icon2.png'
import { DFPAPIEndpointGalley } from '../../../utils/axios'


export const CreateAft = (props) => {
const {editData,airlines}=props;
  const [state,setState]=useState({aircraft:"",variant:"",type:"",bodyType:"",fcCap:null,bcCap:null,peCap:null,ecCap:null})
  const [classCapacity,setClassCapacity]=useState([]);
  const [classCapacityConfig,setClassCapacityConfig]=useState([]);
const [showStatus,setShowStatus]=useState(false);
const [buttonStatus,setButtonStatus]=useState(false);
const [showModalStatus,setShowModalStatus]=useState("");

    const aircraftTypeList = [
    {key: 0, label: "Select Type", value: ''},
    {key: 1, label: "Long Haul", value: 'longHaul'},
    {key: 2, label: "Short Haul", value: 'shortHaul'}
  ]

  const bodyTypeList = [
    {key: 0, label: "Select Body Type", value: ''},
    {key: 1, label: "Wide Body", value: 'wideBody'},
    {key: 2, label: "Narrow Body", value: 'narrowBody'}
  ]


  const aircraftTypeListAkasa=[{key: 0, label: "Select Type", value: ''},
  {key: 1, label: "Short Haul", value: 'shortHaul'}]

  const bodyTypeListAkasa = [
    {key: 0, label: "Select Body Type", value: ''},
    {key: 1, label: "Narrow Body", value: 'narrowBody'}
  ]

    useEffect(() => {
    if(editData&&Object.keys(editData).length&&classCapacityConfig.length>0){
    const {model,variant,aircraftType,bodyType,classCapacity}=editData;
    setState({aircraft:model,variant,type:aircraftType==='Long Haul'?'longHaul':'shortHaul',bodyType:bodyType==='Wide Body'?'wideBody':'narrowBody',fcCap:classCapacity.find(fc=>fc.classCode===classCapacityConfig[0].classCode)?.seatCapacity?classCapacity.find(fc=>fc.classCode===classCapacityConfig[0].classCode)?.seatCapacity:0,bcCap:classCapacity.find(bc=>bc.classCode===classCapacityConfig[1].classCode)?.seatCapacity?classCapacity.find(bc=>bc.classCode===classCapacityConfig[1].classCode)?.seatCapacity:0,peCap:classCapacity.find(pc=>pc.classCode===classCapacityConfig[2].classCode)?.seatCapacity?classCapacity.find(pc=>pc.classCode===classCapacityConfig[2].classCode)?.seatCapacity:0,ecCap:classCapacity.find(ec=>ec.classCode===classCapacityConfig[3].classCode)?.seatCapacity?classCapacity.find(ec=>ec.classCode===classCapacityConfig[3].classCode)?.seatCapacity:0})
    }
    }, [editData,props,classCapacityConfig])

    useEffect(() => {
     fetchConfigClassCodes()
      }, [props])

    useEffect(() => {
      if(editData&&Object.keys(editData).length){
        setButtonStatus(false);
      }

      if (!state['aircraft']|| !state['bodyType']|| !state['type']||!state['variant']) {
        setButtonStatus(true);
      }
      if (state['aircraft']&& state['bodyType']&& state['type']&&state['variant']){
        setButtonStatus(false);
      }
      }, [editData,state])


    useEffect(() => {

    if(state &&state.fcCap&&classCapacityConfig.length>0){
    const i = classCapacity.findIndex(x => x.classCode === classCapacityConfig[0].classCode)
    if(i>-1){
    classCapacity.splice(i, 1);
    }
    setClassCapacity(( currentValue ) => [ ...currentValue, {classCode:  classCapacityConfig[0].classCode, seatCapacity: state.fcCap?state.fcCap:0, className:  classCapacityConfig[0].label} ])

    }
    if(!state&&classCapacityConfig.length>0){
    const i = classCapacity.findIndex(x => x.classCode ===  classCapacityConfig[0].classCode)
    if(i>-1){
    classCapacity.splice(i, 1);
    }
    setClassCapacity(( currentValue ) => [ ...currentValue])

    }

    if(state.bcCap&&classCapacityConfig.length>0){
    const i = classCapacity.findIndex(x => x.classCode ===  classCapacityConfig[1].classCode)
    if(i>-1){
    classCapacity.splice(i, 1);
    }
    setClassCapacity(( currentValue ) => [ ...currentValue, {classCode:  classCapacityConfig[1].classCode, seatCapacity: state.bcCap?state.bcCap:0, className:  classCapacityConfig[1].label} ])
    }
    if(!state&&classCapacityConfig.length>0){
    const i = classCapacity.findIndex(x => x.classCode ===  classCapacityConfig[1].classCode)
    if(i>-1){
    classCapacity.splice(i, 1);
    }
    setClassCapacity(( currentValue ) => [ ...currentValue ])

    }
    if(state&&state.peCap&&classCapacityConfig.length>0){
    const i = classCapacity.findIndex(x => x.classCode ===  classCapacityConfig[2].classCode)
    if(i>-1){
    classCapacity.splice(i, 1);
    }
    setClassCapacity(( currentValue ) => [ ...currentValue, {classCode:  classCapacityConfig[2].classCode, seatCapacity: state.peCap?state.peCap:0, className: "Premium omy"} ])
    }
    if(!state&&classCapacityConfig.length>0){
    const i = classCapacity.findIndex(x => x.classCode ===  classCapacityConfig[1].classCode)
    if(i>-1){
    classCapacity.splice(i, 1);
    }
    setClassCapacity(( currentValue ) => [ ...currentValue ])

    }
    if(state&&state.ecCap&&classCapacityConfig.length>0){
    const i = classCapacity.findIndex(x => x.classCode === classCapacityConfig[3].classCode)
    if(i>-1){
    classCapacity.splice(i, 1);
    }
    setClassCapacity(( currentValue ) => [ ...currentValue, {classCode: classCapacityConfig[3].classCode, seatCapacity: state.ecCap?state.ecCap:0, className: "omy"}])
    }
    if(!state&&classCapacityConfig.length>0){
    const i = classCapacity.findIndex(x => x.classCode === classCapacityConfig[3].classCode)
    if(i>-1){
    classCapacity.splice(i, 1);
    }
    setClassCapacity(( currentValue ) => [ ...currentValue])

    }
    if((!state.fcCap||!state.bcCap||!state.ecCap||!state.peCap)&&classCapacityConfig.length>0){
    classCapacity.map(cf=>{ if(cf.classCode===classCapacityConfig[0].classCode||cf.classCode===classCapacityConfig[1].classCode||cf.classCode===classCapacityConfig[2].classCode||cf.classCode===classCapacityConfig[3].classCode){
    cf.seatCapacity=0
    }
    return cf})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])


    const onChangeInputs=(e)=>{
    const {name,value}=e.target;
    switch (name) {
    case "aircraft":
    setState((_prev) => {
    return { ..._prev, aircraft: value || "" };
    });

    break;
    case "variant":
    setState((_prev) => {
    return { ..._prev, variant: value || "" };
    });
    break;

    case "type":
    setState((_prev) => {
    return { ..._prev, type: value || ""};
    });
    break;

    case "bodyType":
    setState((_prev) => {
    return { ..._prev, bodyType: value || ""};
    });
    break;


    case "fcCap":
    setState((_prev) => {
    return { ..._prev, fcCap: value || null };
    });
    break;

    case "bcCap":
    setState((_prev) => {
    return { ..._prev, bcCap: value || null };
    });
    break;

    case "peCap":
    setState((_prev) => {
    return { ..._prev, peCap: value || null };
    });
    break;

    case "ecCap":
    setState((_prev) => {
    return { ..._prev, ecCap: value || null};
    });
    break;
    default:
    setState((_prev) => {
    return { ..._prev };
    });
    break;
    }
    }

    const fetchConfigClassCodes=async()=>{
      try {
        const resp = await DFPAPIEndpointGalley.get('galley/mealMenuPlan/configs');
        if(resp.data&&resp.data.classCodes?.list?.length>0){
        setClassCapacityConfig(resp.data.classCodes?.list?.map(cl=>({
          classCode: cl.id,
          seatCapacity: 0,
          className: cl.label
      })))
        }
        }
        catch (error) {

        }
    }

    const onSaveAircraftDetails=async()=>{
    setButtonStatus(true);
    const payload= {
    model: state.aircraft,
    variant:state.variant,
    aircraftType: state.type==='longHaul'?'Long Haul':'shortHaul'?'Short Haul':"",
    bodyType:state.bodyType==='narrowBody'?'Narrow Body':'wideBody'?'Wide Body':"",
    classCapacity:!classCapacity.length?classCapacityConfig:classCapacity,
    aircraftFor: airlines
    }
    const hasEmptyKeys=(payload)=>{
    for (let key in payload) {
    if (payload[key] === null || payload[key].length === 0 || payload[key] === undefined) {
    return false;
    }
    }
    return true;
    }

    if(payload && hasEmptyKeys(payload) && !Object.keys(editData).length){
    try {
    const resp = await DFPAPIEndpointGalley.post(`galley/aircraftTypes`,payload);
    if(resp.success){
    setShowStatus(!showStatus);
    props.setModalFormStatusObj({ text:resp.message })
    setShowModalStatus(resp.message)
    setTimeout(() => {
    setShowStatus(!showStatus)
    setShowModalStatus("")
    props.setModalFormStatusObj({ text:"" })
    props.onClose();
    }, 1600);
    setButtonStatus(false);
    }
    }
    catch (error) {
    setShowStatus(!showStatus);
    setShowModalStatus(error)
    setTimeout(() => {
    setShowStatus(!showStatus)
    setShowModalStatus("")
    props.onClose();
    }, 1600);
    }
    }
    else{
    const editBody={
    model: state.aircraft,
    variant: state.variant,
    aircraftType: state.type==='longHaul'?'Long Haul':'shortHaul'?'Short Haul':"",
    bodyType:state.bodyType==='narrowBody'?'Narrow Body':'wideBody'?'Wide Body':"",
    classCapacity:!classCapacity.length?editData.classCapacity:classCapacity,
    galleys:editData.galleys,
    aircraftFor: airlines
    }

    if(Object.keys(editData).length){
       try {
    const resp = await DFPAPIEndpointGalley.put(`galley/aircraftTypes/${editData.id}`,editBody);
    if(resp.success){
    setShowStatus(!showStatus);
    props.setModalFormStatusObj({ text:resp.message })
    setShowModalStatus(resp.message)
    setTimeout(() => {
    setShowStatus(!showStatus)
    setShowModalStatus("")
    props.setModalFormStatusObj({ text:"" })
    props.onClose();
    }, 2500);
    setButtonStatus(false);
    }
    }
    catch (error) {
    setShowStatus(!showStatus);
    setButtonStatus(false);
    setShowModalStatus(error)
    setTimeout(() => {
    setShowStatus(!showStatus)
    props.onClose();
    setShowModalStatus("")
    }, 2500);
    }
    }
    }
    }


  return (
      <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar">
        <div className="dialog__content">
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">{props.title}</h2>
          <hr />
          <div className="dialog__body_container flexRow justifyContentSpaceBetween flexWrap">
            <div className="flexCol justifyContentSpaceEvenly ">
              <div className=" dialog__container_item input-container">
                <div className="dialog__description">
                  <label className="req">Aircraft</label>
                  <input
                    id='aircraft'
                    type={'text'}
                    name="aircraft"
                    placeholder={'Aircraft'}
                    value={state.aircraft || ""}
                    onChange={onChangeInputs}
                    className='edit-input-galley-aircraft'
                    />
                </div>
              </div>
            </div>

            <div className="flexCol justifyContentSpaceEvenly ">
              <div className=" dialog__container_item input-container">
                <div className="dialog__description">
                {props.airlines!=='akasa'?<label className="req">Variant</label>:<label className="req">Sub-fleet</label>}

                  <input
                    id='variant'
                    name="variant"
                    placeholder={props.airlines!=='akasa'?'variant':'sub-fleet'}
                    type={'text'}
                    value={state.variant|| ""}
                    onChange={onChangeInputs}
                    className='edit-input-galley-aircraft'
                    />
                </div>
              </div>
            </div>


            <div className="flexCol justifyContentSpaceEvenly ">
              <div className=" dialog__container_item input-container">
                <div className="dialog__description">

                  <label className="req">Type</label>
                 {props.airlines!=='akasa'? <select className='select-input-aircraft' name='type' onChange={onChangeInputs} value={state.type||""}>
                    {
                      aircraftTypeList.map((_, index) => {
                          return (
                            <option value={_.value} key={index}>{_.label}</option>
                          )
                      })
                    }
            </select>:<select className='select-input-aircraft' name='type' onChange={onChangeInputs} value={state.type||""}>
                    {
                      aircraftTypeListAkasa.map((_, index) => {
                          return (
                            <option value={_.value} key={index}>{_.label}</option>
                          )
                      })
                    }
            </select>}
                </div>
              </div>
            </div>

            <div className="flexCol justifyContentSpaceEvenly ">
              <div className=" dialog__container_item input-container">
                <div className="dialog__description">
                  <label className="req">Body Type</label>
           {props.airlines!=='akasa'? <select className='select-input-aircraft' name='bodyType' onChange={onChangeInputs}  value={state.bodyType||""}>
                    {
                      bodyTypeList.map((_, index) => {
                          return (
                            <option value={_.value} key={index}>{_.label}</option>
                          )
                      })
                    }
              </select>: <select className='select-input-aircraft' name='bodyType' onChange={onChangeInputs}  value={state.bodyType||""}>
                    {
                      bodyTypeListAkasa.map((_, index) => {
                          return (
                            <option value={_.value} key={index}>{_.label}</option>
                          )
                      })
                    }
              </select>}
                </div>
              </div>
            </div>
          </div>
{props.airlines!=='akasa'?<div className='dialog__body_container flexRow justifyContentSpaceBetween flexWrap'>
             <div className='flexRow margTop36'>
                <div className='width50'>
                  <div className="flexCol justifyContentCenter ">
                          <div className=" dialog__container_item input-container aircraft_modal">
                            <div className="dialog__description">
                                <label className='flexRow'>First Class (FC)</label>
                              <input
                                id='type'
                                name="fcCap"
                                placeholder={'capacity'}
                                type={'number'}
                                value={state.fcCap === null ? '' : state.fcCap}
                                onChange={onChangeInputs}
                                className='edit-input-galley-aircraft'
                                />
                            </div>
                          </div>
                        </div>
                </div>
              </div>
              <div className='flexRow margTop36'>
                <div className='width60'>
                <div className="flexCol justifyContentCenter ">
                          <div className=" dialog__container_item input-container aircraft_modal">
                            <div className="dialog__description">
                                <label className='flexRow' >Business Class (BCL)</label>
                              <input
                                id='type'
                                name="bcCap"
                                placeholder={'capacity'}
                                type={'number'}
                                value={state.bcCap === null ? '' : state.bcCap}
                                onChange={onChangeInputs}
                                className='edit-input-galley-aircraft'
                                />
                            </div>
                          </div>
                        </div>
                </div>
              </div>
              <div className='flexRow margTop36'>
                <div className='width70'>
                  <div className="flexCol justifyContentCenter ">
                          <div className=" dialog__container_item input-container aircraft_modal">
                            <div className="dialog__description">
                              <label className='flexRow'>Premium Economy (PCY)</label>
                              <input
                                id='type'
                                name="peCap"
                                placeholder={'capacity'}
                                type={'number'}
                                value={state.peCap === null ? '' : state.peCap}
                                onChange={onChangeInputs}
                                className='edit-input-galley-aircraft'
                                />
                            </div>
                          </div>
                        </div>
                </div>
              </div>
              <div className='flexRow margTop36'>
              <div className='width50'>
                <div className="flexCol justifyContentCenter ">
                          <div className=" dialog__container_item input-container aircraft_modal">
                            <div className="dialog__description">
                              <label className='flexRow'>Economy (ECY)</label>
                              <input
                                id='type'
                                name="ecCap"
                                placeholder={'capacity'}
                                type={'number'}
                                value={state.ecCap === null ? '' : state.ecCap}
                                onChange={onChangeInputs}
                                className='edit-input-galley-aircraft'
                                />
                            </div>
                          </div>
                        </div>
              </div>
</div>



     </div>:<div className='dialog__body_container flexRow justifyContentSpaceBetween flexWrap'>
              <div className='flexRow margTop36'>
              <div className='width50'>
                <div className="flexCol justifyContentCenter ">
                          <div className=" dialog__container_item input-container aircraft_modal">
                            <div className="dialog__description">
                              <label className='flexRow'>Economy</label>
                              <input
                                id='type'
                                name="ecCap"
                                placeholder={'capacity'}
                                type={'number'}
                                value={state.ecCap === null ? '' : state.ecCap}
                                onChange={onChangeInputs}
                                className='edit-input-galley-aircraft'
                                />
                            </div>
                          </div>
                        </div>
              </div>
</div>
     </div>}

     <div className="dialog__footer flexCol alignItemsCenter margTop24">
          <button  disabled={buttonStatus} className="done-button" onClick={onSaveAircraftDetails}>Save</button>
        </div>

         {Boolean(showStatus) ?
          <>
            <div className={ "dialog__statusBlock " + (false ? 'alert_error' : 'alert_success') }>
              {showModalStatus}
            </div>
          </> : null }
        </div>

      </div>

    </div>
  );
}

export const CreateAircraft = (props) => {

  const onClose = (e) => {
    removeContainerOverlay();
    props.onClose();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <CreateAft
     title={props.title}
     airlines={props.airlines}
      onClose={onClose}
      editData={props.editData?props.editData:{}}
      setModalFormStatusObj={props.setModalFormStatusObj}
      >
    </CreateAft>
  )
}


