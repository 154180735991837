import React, { useState, useEffect, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import Modal from "../../../../views/flights/components/ViewComponent";
import view from '../../../../assets/view.svg'
import edit from '../../../../assets/edit.svg'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'

export const VendorPage = (props) => {
  const [rowData, setRowData] = useState([])
  const [showModal, setShowModal] = useState('');
  const [rowProp, setRowProp] = useState([])
  // eslint-disable-next-line no-unused-vars
  const gridRef = useRef(null)

  const close = () => {
    setShowModal('');
  }

  function LinkComponent(props) {
    setRowProp(props.value);
    return (
      <div>
        <img className="icon" alt='view' src={view} onClick={() => setShowModal('view')} />
        <img className="icon" alt='edit' src={edit} onClick={() => setShowModal('edit')} />
      </div>
    );
  }

  const [columnDefs] = useState([
    { field: 'code', sortable: true, filter: true },
    { field: 'name', sortable: true, filter: true },
    { field: 'name2', sortable: true, filter: true },
    { field: 'mobile', sortable: true, filter: true },
    { field: 'email', sortable: true, filter: true },
    { field: 'address', sortable: true, filter: true },
    { field: 'gstin', sortable: true, filter: true },
    {
      headerName: 'Action', cellRenderer: "LinkComponent", valueGetter: function (params) {
        return params.data;
      }
    }
  ])


  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('user_id', localStorage.getItem('user_id'))
  myHeaders.append('x-csrf-token', localStorage.getItem('token'))
  myHeaders.append('X-Bearer-Token', localStorage.getItem('bearer_token'))

  var raw = JSON.stringify({
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  })

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    credentials: 'include',
    body: raw,
    redirect: 'follow'
  }

  useEffect(() => {
    fetch('https://dfp.ngopos.com/api/vendors/process', requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log("sector", JSON.parse(result))
        setRowData(JSON.parse(result).items)
      })
      .catch(error => console.log('error', error))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className='ag-theme-alpine-dark'
      style={props.tableGridSettings}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        pagination={true}
        paginationPageSize={15}
        frameworkComponents={{
          LinkComponent
        }}
      />
      <Modal show={showModal} detail={rowProp} close={close} />
    </div>
  )
}
