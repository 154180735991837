/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import moment from "moment";

import { APIEndpoint, DFPAPIEndpoint } from '../../../../utils/axios';
import { EditModal } from '../../../../common';
import { updateButtonTxtStatus, onDownloadCSVFile } from "../../../../utils/commonFunctions";
import { MIMETypes, DOWNLOAD_STATUSCHECK_INTERVAL } from "../../../../utils/configs";


export const SalesReportsDownloadComponent = (props) => {
    const { t } = useTranslation();
    const [ modalTitle ] = useState('Download Reports');// setModalTitle('Download Reports');
    const [modalCustomButtons, setModalCustomButtons] = useState(null);
    const modelStyle = { maxWidth: '80%' };
    const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
    const [updatedFormFieldValues, setUpdatedFormFieldValues] = useState({});
    const [modalFormData, setModalFormData] = useState({});
    // const [state, setState] = useState({});

    const [reportTypes, setReportTypes] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [editFormFields, setEditFormFields] = useState([]);

    useEffect( () => {
        
        loadReportTypes();
    
        if ( props.sectorList.length > 0) {
            popupForm_loadRoutes();
        }
        

        setUpInitialFormFields();
        
        

        setModalCustomButtons([
            /**
             * Buttons
             */
            { label: 'Cancel', attribute: 'button', type: 'button', disabled: false, className: 'cancel-button', action: (e) => { 
                    console.log('ModalClosed.');
                    props.onClose();
                    return false; 
                }
            },
            { label: 'Download', attribute: 'button', type: 'button', disabled: false, className: 'submit-button'
                , action: 'onSubmit'
                // , action: (e) => {
                //     console.log('Started downloading reports...');
                //     // onDownloadXlsFile('samples/downloads/Distributor_Mappings_Upload_Sample', 'Distributor_Mappings_Upload_Sample');
                //     return false;
                // }
            }
        ]);

    }, []);
    
    useEffect( () => {

        showFieldValidation();
        
    }, [ editFormFields ]);

    useEffect( () => {
        if ( reportTypes.length && routes.length > 0 && editFormFields.length > 0 ) {
            updateEditFormInputs();
        }
    }, [reportTypes, routes ]);

    const showFieldValidation = () => {
        if ( editFormFields.length > 0) {
            console.info('Log: Showing form validation errors.');

            editFormFields.map( _ => {

                const foundInpElement = document.getElementById(_.id);
                if (foundInpElement) {

                if (_.required === true) {
                    validateEmptyError( foundInpElement);
                }

                }
                else {
                    console.error('Log: Form element not found!', _);
                }
                return null

            });

        }
    };

    const loadReportTypes = async () => {
        //, { signal: newAbortSignal('reportTypesAPI', 5000) }
        try {
          const resp = await DFPAPIEndpoint.get('reports/reportTypes');
        if (resp.result.length > 0) {
            const options = [{key: 0, label: 'Select', value: '', id: ''}];
            resp.result.map( (fieldValue, index) => {
              options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.id}`, id: `${fieldValue.id}`});
              
              return fieldValue;
            });
            
            setReportTypes(options);
            // updateEditFormInputs(options, null);
        }
        } catch (error) {
          
        }
        
    }
      
    const popupForm_loadRoutes = async () => {
        if ( props.sectorList.length > 0) {
            const options = [{key: 0, label: 'Select', value: '', id: ''}];

            props.sectorList.filter( (fieldValue, index) => {
                options.push({key: index+1, label: `${fieldValue.label}`, value: `${fieldValue.value}`, id: `${fieldValue.id}`});
                
                return fieldValue;
            });

            setRoutes(options);
            // updateEditFormInputs(null, options);
        }
    }
    
    const allFormInputs = [
        // { label: 'View Order Details', type: 'viewOrderDetails', attribute: 'items' }
        
        // { key: 1, label: 'Report Type', name: 'reportType', id: 'reportType', attribute: 'reportType', type: 'select', disabled: false,
        //     options: reportTypes || [], required: true, className: 'text-input' },
        
        { key: 'reportType', label: 'Report Type', name: 'reportType', id: 'reportType', attribute: 'reportType', type: 'select', disabled: false,
            
            required: true, className: 'select-search-cls' 
            , options: reportTypes
            // , getOptions: () => {
            //     return getReportTypeOptions();
            // }

            // , defaultValue: ''
            // , value: ''
            // , className: 'select-search-cls'
            // , getOptions: () => {
            //     return
            // }
            /**
             * multiple(false): true/false
             * search(false): true/false
             * closeOnSelect(true): true/false
             * debounce(0): Number of ms to wait until calling get options when searching.
             * autoComplete(off): Options are on/off
             * autoFocus(false): true/false
             */
            // , multiple: false
            // , search: true
            // // , closeOnSelect: false
            // // , debounce: 1000
            // , autoComplete: 'on'
            // , autoFocus: false
            // , placeholder: 'Start typing...'
        },
        { key: 'filterByTransactionDate', label: 'Filter by Transaction Date', name: 'filterByTransactionDate', id: 'filterByTransactionDate', attribute: 'filterByTransactionDate', type: 'checkbox',
            required: false, disabled: false,
            // placeholder: '', className: 'text-input' 
        },
        { key: 'startDate', label: 'Start Date', name: 'startDate', id: 'startDate', attribute: 'startDate', type: 'date', required: true, disabled: false,
            placeholder: t('datePickerPlaceholder'),
            className: 'date-input',
            // startDate: moment().format()
        },
        { key: 'endDate', label: 'End Date', name: 'endDate', id: 'endDate', attribute: 'endDate', type: 'date', required: true, disabled: false,
            placeholder: t('datePickerPlaceholder'),
            className: 'date-input',
            // endDate: moment().format()
        },
        { key: 'transDate', label: 'Date', name: 'transDate', id: 'transDate', attribute: 'transDate', type: 'date', required: true, disabled: false,
                placeholder: t('datePickerPlaceholder'),
                className: 'date-input',
                // startDate: moment().format()
        },
        { key: 'selectRoute', label: 'Select Route', name: 'selectRoute', id: 'selectRoute', attribute: 'selectRoute', type: 'select', disabled: false,
            required: true, className: 'select-search-cls',
            options: routes
            // getOptions: getReportTypeOptions()
            
            // , defaultValue: ''
            // , value: []
            
            // , getOptions: () => {
            //     return
            // }
            /**
             * multiple(false): true/false
             * search(false): true/false
             * closeOnSelect(true): true/false
             * debounce(0): Number of ms to wait until calling get options when searching.
             * autoComplete(off): Options are on/off
             * autoFocus(false): true/false
             */
            // , multiple: true
            // , search: true
            // , closeOnSelect: false
            // , debounce: 1000
            // , autoComplete: 'on'
            // , autoFocus: false
            // , placeholder: 'Start typing...'
        },
    ];
    const setUpInitialFormFields = () => {
        let fields = [ ...allFormInputs.filter( _ => 
          ( _.name === 'reportType' || _.name === 'startDate' || _.name === 'endDate' || _.name === 'selectRoute' )
        ) ];
    
        console.info('Setting initial fields:', fields);
        setEditFormFields( fields );
        
    };

    const updateEditFormInputs = (reportTypes2=null, routesArr2=null) => {
        console.info('Log: Updating form '+ editFormFields.length +' fields with reportType & route select options');
        const updatedEditFields = editFormFields.filter( (field) => {
            
            if (reportTypes && field.attribute === 'reportType') {
                field.disabled = false;
                field.options = reportTypes;
                // field.getOptions = () => {
                //     return reportTypes;
                // }
                setModalFormStatusObj({ text: '' });
            }
            else if (routes && field.attribute === 'selectRoute' ) {
                field.disabled = false;
                field.options = routes;
                // field.getOptions = () => {
                //     return routes;
                // }
                setModalFormStatusObj({ text: '' });
            }
    
            return field;
        });
        
        setEditFormFields( updatedEditFields );
        console.log('Log: Form fields updated with select options.');
    };

    const onModalFormInputChange = (e, newFormData) => {
        const { name, value } = e.target;
    
        let newUpdates = modalFormData ? { ...modalFormData } : {}
        newUpdates[name] = value ?? '';
    
        setModalFormData( { ...newFormData } );
    
        
        validateAndShowFORMErrors(e);
    }
    
    const validateAndShowFORMErrors = (e) => {
        const { name, value, required, id } = e.target;
        const inpKey = id ?? name;
        console.info('Log: Validating field (' + inpKey + ') value (' + value + ') for errors....');
    
        
        /** Step 1 */
        const updtReportTypeFields = () => {
          let updatedEditFields = [];
    
          switch ( value ) {
            case '':
            
              updatedEditFields = [ ...allFormInputs.filter( _ => 
                ( _.name === 'reportType' || _.name === 'startDate' || _.name === 'endDate' || _.name === 'selectRoute' )
              ) ];
    
            break;
            case 'salesSummary':
            
              updatedEditFields = [ ...allFormInputs.filter( _ => 
                ( _.name === 'reportType' || _.name === 'filterByTransactionDate' || _.name === 'startDate' || _.name === 'endDate' || _.name === 'selectRoute' )
              ) ];
    
            break;
            case 'inventoryTransactionAggregate':
            
              updatedEditFields = [ ...allFormInputs.filter( _ => 
                ( _.name === 'reportType' || _.name === 'transDate' || _.name === 'selectRoute' )
              ) ];
              
            break;
            case 'itemwiseAggregate':
              updatedEditFields = [ ...allFormInputs.filter( _ => 
                ( _.name === 'reportType' || _.name === 'startDate' || _.name === 'endDate' || _.name === 'selectRoute' )
              ) ];
    
            break;
            case 'invoiceReport':
              updatedEditFields = [ ...allFormInputs.filter( _ => 
                ( _.name === 'reportType' || _.name === 'startDate' || _.name === 'endDate' || _.name === 'selectRoute' )
              ) ];
            break;
            case 'invoiceItemWiseReport':
              updatedEditFields = [ ...allFormInputs.filter( _ => 
                ( _.name === 'reportType' || _.name === 'startDate' || _.name === 'endDate' || _.name === 'selectRoute' )
              ) ];
            break;
            case 'sectorSalesReport':
              updatedEditFields = [ ...allFormInputs.filter( _ => 
                ( _.name === 'reportType' || _.name === 'startDate' || _.name === 'endDate' || _.name === 'selectRoute' )
              ) ];
            break;
            default:
            break;
          }
          
          if (updatedEditFields.length > 0) {
            console.log('Form fields updated.');
            setEditFormFields( updatedEditFields );

            // updateEditFormInputs(null, null);
          }
    
        }
    
        if (name === 'reportType') {
          
            updtReportTypeFields();
        }
    
        if ( required === true ) {
            validateEmptyError( e.target );
        }
    
    }
    
    const validateEmptyError = ( targetElt ) => {
        const { name, value, id } = targetElt;
        const inpKey = id ?? name;
        // console.info('Log: ' + inpKey + ' field is required(value is "' + value + '").');
    
        const inpElt = document.getElementById(inpKey);
        
        const childValidateMsgDiv = document.createElement('div');
        childValidateMsgDiv.id = inpKey +'_childValidateMsgDiv';
        childValidateMsgDiv.classList = ['validationMsgDiv'];
    
        const foundChildDiv = document.getElementById(inpKey +'_childValidateMsgDiv');
        // IF error message div already created then remove it
        if (foundChildDiv) {
          try {
            if (targetElt.parentElement) {
              targetElt.parentElement.removeChild(foundChildDiv);
            }
            else {
              // Fix: Datepicker input not returning event object
              foundChildDiv.remove();
            }
          }
          catch(e) {
            console.error('Log: Error occured while removing existing error message:', e.message);
          }
        }
    
    
        if ( value === '' ) {
          childValidateMsgDiv.classList.add('error');
          childValidateMsgDiv.innerHTML = '* Required';
        }
    
        if(targetElt.parentElement) {
          targetElt.parentElement.appendChild(childValidateMsgDiv);
        }
        else {
          // Fix: Datepicker input not returning event object
          inpElt.appendChild(childValidateMsgDiv);
        }
    };

    const updateEditFormValues = async (e, updatedFormObj) => {

        // ReactDOM.flushSync( () => {
        //     if (e) {
        //         const selectedReportType = reportTypes.find( item => item.value === e);
        //         console.log('selectedReportType:', selectedReportType);
    
        //         /** Set to respective fields */
        //         // const updatedEditFields = editFormFields.filter( (field) => {
    
        //         //     if (selectedReportType ) {
        //         //         if ( field.attribute === 'reportType' ) {
        //         //             field.value = selectedReportType.label;
        //         //         }
        //         //     }
    
        //         //     return field;
    
        //         // });
    
        //         // setEditFormFields( _prev => {
        //         //     return [ ...updatedEditFields ] ;
        //         // });
        //     }
        // })
    };

    const onClose = () => {
        props.onClose();
        // setState((_prev) => {
        //   return { ..._prev, selected: false }
        // })
    }

    const checkDownloadStatus = async (jobId, e) => {
        await APIEndpoint.get('downloads/status/' + jobId).then( resp => {
    
            if (resp.status === 'completed') {
                console.log('Download status check: Completed', JSON.stringify(resp));
                // updateButtonTxtStatus(true, e, 'Downloaded', false);
    
                // https://dfp.ngopos.com/api/downloads/analytics/PO%20vs%20GRN%20Report-2022-12-19T19-33--56c0695a-604a-4a38-9097-b8b815063e68.csv
    
                onDownloadCSVFile('downloads/analytics/' + resp.fileName + resp.fileExtension, 'purchase-order-report-a');
                updateButtonTxtStatus(true, e, 'Downloaded', false, { defaultButtonText: 'Download' });
    
                // setState((_prev) => {
                //     return { ..._prev, selected: false };
                // });
                props.onClose();

                setModalFormStatusObj({ text: '' });
                
            }
            else {
                checkDownloadStatus(jobId, e);
    
                setInterval( () => {
                    console.log("Download status check: After Interval", JSON.stringify(resp));
                    checkDownloadStatus(jobId, e);
                }, DOWNLOAD_STATUSCHECK_INTERVAL);
            }
    
        }).catch( error => {
            console.log('Current DownloadLOI error:', error);
            updateButtonTxtStatus(false, e, 'Download failed', false);
        });
    };

    const validateFormInputs = (body, frm='') => {
        setModalFormStatusObj({ text: '' });
        
        if (!body) {
            setModalFormStatusObj({ error: 'Input validation failed!' });
            return false;
        }
    
        if ( frm ==='downloadReportPopup' ) {
          console.info('body:', body);
    
            if (!body.reportType) {
                setModalFormStatusObj({ error: 'Please select report type.' });
                return false;
            }
            else {
              
              switch ( body.reportType ) {
                case 'salesSummary':
    
                  if ( !body.startDate || !body.endDate || !body.selectRoute ) {
                    setModalFormStatusObj({ error: 'Please fill all required fields(*).' });
                    return false;
                  }
        
                break;
                case 'inventoryTransactionAggregate':
                
                  if ( !body.transDate || !body.selectRoute ) {
                    setModalFormStatusObj({ error: 'Please fill all required fields(*).', });
                    return false;
                  }
    
                  body.startDate = body.transDate;
                  body.endDate = body.transDate;
                  delete body.transDate;
    
                break;
                case 'itemwiseAggregate':
                  if ( !body.startDate || !body.endDate || !body.selectRoute ) {
                    setModalFormStatusObj({ error: 'Please fill all required fields(*).', });
                    return false;
                  }
        
                break;
                case 'invoiceReport':
                  if ( !body.startDate || !body.endDate || !body.selectRoute ) {
                    setModalFormStatusObj({ error: 'Please fill all required fields(*).', });
                    return false;
                  }
                break;
                case 'invoiceItemWiseReport':
                  if ( !body.startDate || !body.endDate || !body.selectRoute ) {
                    setModalFormStatusObj({ error: 'Please fill all required fields(*).' });
                    return false;
                  }
                break;
                case 'sectorSalesReport':
                  if ( !body.startDate || !body.endDate || !body.selectRoute ) {
                    setModalFormStatusObj({ error: 'Please fill all required fields(*).' });
                    return false;
                  }
                break;
                default:
                break;
              }
    
            }
    
        }
        return true;
    };

    const onSubmit = async (e, updated) => {

        try {
    
        //   if ( state.selected.action === 'downloadReportPopup' ) {
            updateButtonTxtStatus(true, e, 'Reports downloading...', true);
            console.log('Started downloading reports...', updated);
    
            if ( !validateFormInputs(updated, 'downloadReportPopup' ) ) {
                updateButtonTxtStatus(true, e, 'Download', false);
                return false;
            }
    
            try {
                const reportTypeObj = reportTypes.find( row => updated.reportType === row.value);
                if ( !reportTypeObj ) {
                    setModalFormStatusObj({ error: 'Invalid report type selected, Please check.' });
                    updateButtonTxtStatus(true, e, 'Download', false);
                    return false;
                }
    
                const downloadPayload = {
                    reportType: reportTypeObj.id
                };
                if (updated.startDate) {
                  downloadPayload.startDate = moment(updated.startDate).format();
                }
                if (updated.endDate) {
                  downloadPayload.endDate = moment(updated.endDate).format();
                }
                if ( updated.selectRoute ) {
                  downloadPayload.storeId = updated.selectRoute;
                }
                if (updated.reportType === 'salesSummary') {
                  downloadPayload.filterByTransactionDate = false;
                  if ( updated.filterByTransactionDate === true) {
                    downloadPayload.filterByTransactionDate = true;
                  }
                }
    
                APIEndpoint.post('sales/reports', downloadPayload, { Accept: MIMETypes.csv }).then( resp => {
    
                    if (resp.status === 'scheduled') {
    
                        console.log("Download status check: Initial");
                        checkDownloadStatus(resp.id, e);
    
                    } else {
                        updateButtonTxtStatus(true, e, 'Download', false);
                    }
    
                })
                .catch( error => {
                    console.log('Log: DownloadLOI error:', error);
                    updateButtonTxtStatus(false, e, 'Download failed', false);
                });
              }
              catch( er ) {
                  console.log('Error while downloading report...', er.message);
              }
    
              // onDownloadXlsFile('samples/downloads/Distributor_Mappings_Upload_Sample', 'Distributor_Mappings_Upload_Sample');
              // updateButtonTxtStatus(true, e, 'Downloaded', false, { defaultButtonText: 'Download' });
              // return false;
        //   }
        //   else if ( state.selected?.action === 'viewItems' ) {
        //     //   setState((_prev) => {
        //     //       return { ..._prev, selected: false };
        //     //   });
        //   }
        }
        catch(err) {
            setModalFormStatusObj({ error: 'Error: ' + err.data.msg, });
        }
    };

    return (
        <EditModal
            className='salesPageModal'
            title={ modalTitle }
            customModalStyle={ modelStyle }
            onClose={ () => props.onClose() }
            onSubmit={onSubmit}
            modalFormStatusObj={modalFormStatusObj}
            modalCustomButtons={modalCustomButtons}
            fields={ editFormFields.map( (elt) => {
                    return {...elt, initialValue: '' }
                })
            }
            showFieldsInColumn={true}

            onChange={ (e, updated) => {

                    setUpdatedFormFieldValues(updated);

                    if (typeof e === 'string') { // Search select
                        updateEditFormValues(e, updated);
                    }
                    else {
                        onModalFormInputChange(e, updated);
                    }

                }
            }
        />
    )
};