import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { RosterPage, PerformancePage } from "./inner-components";


export const CrewScreen = (props) => {
  return (
    <div className="flexRow full p-0 m-0">
      <div className='full-flex flexCol width100 p-0 m-0'>
        <Routes>
          <Route path="/" element={<Navigate replace to="roster" />} />
          <Route path="/roster" element={<RosterPage />} />
          <Route path="/performance" element={<PerformancePage />} />
        </Routes>
      </div>
    </div>
  )
}
