import React,{useEffect,useRef,useState} from 'react'
import moment from 'moment';
import { APIEndpoint } from '../../../../utils/axios';
import LoadingGIF from '../../../../assets/loadingIn.gif';

export const  Schedulecontainer=({id})=> {
  const [scheduledata, setScheduleData] = useState({});
  const [loading, setLoading] = useState(false);
  const containerRef=useRef();

  useEffect(() => {
   if(id){
    getSectorData(id);
   }

  }, [id]);

  useEffect(() => {
    const updateHeight = () => {
      const availableHeight = window.innerHeight * 0.55;
      if (containerRef.current) {
        containerRef.current.style.maxHeight = `${availableHeight}px`;
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const getSectorData = async (sectorId) => {
    setLoading(true);

    try {
      const res = await APIEndpoint.get(`sandboxing/isolates/${sectorId}/schedule`);
      if (res) {
      setLoading(false);
        setScheduleData(res.schedule||{});

      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getSectors=(sect)=>{

  }
  return (
    <>
<div ref={containerRef} className="op-aircraft-container flexRow full-flex ag-theme-alpine-dark" style={{overflowY:'scroll'}}>
{scheduledata&&scheduledata?<><div style={{float:'left'}} className="justifyContentFlexStart">
<div className='margBottom10'>
                  <label className='fontExtrabold'></label>
                  <button style={{width:'6rem',whiteSpace:'nowrap'}} className="status-dynamic dynamic-sl" onClick={()=>getSectorData(id)}>Refresh</button>
                </div>
                {loading?<img src={LoadingGIF}  className='icon-size' style={{width:'25px',height:'25px'}} alt="Loading" />:<><div className='margBottom10'>
                  <label className='fontExtrabold'>Last Updated</label>
                  <div>{moment(scheduledata.lastUpdatedAt).format('DD-MM-YYYY')}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Source</label>
                  <div>{scheduledata.source}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Sectors</label>
                { scheduledata.sectors&&scheduledata.sectors.length? <div>{getSectors(scheduledata.sectors)}</div>:<div>No sectors scheduled for this aircraft</div>}
                </div></>}





              </div>
</>
:<></>}
</div>

</>
  )
}
