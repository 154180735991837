import React, { useRef, useState } from 'react'
import { APIEndpoint } from '../../../utils/axios';
import { DataGrid,NGODatePicker } from '../../../common';
import {translationLength} from '../../../utils/commonFunctions';
import { PageSizes } from "../../../utils/configs";
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Columns = [
  { field: 'storeName',headerName: 'Sector', sortable: true,suppressSizeToFit:true, flex:1,resizable:true, filter: true },
  { field: 'flightDate',headerName: 'Flight Date', sortable: true,suppressSizeToFit:true,flex:1, resizable:true, filter: true },
  { field: 'warehouseName', headerName: 'Target Warehouse',sortable: true,suppressSizeToFit:true, flex:1,resizable:true, filter: true },
  { field: 'status',headerName: 'Status', sortable: true,suppressSizeToFit:true, resizable:true,flex:1, filter: true },
  { field: 'openedAt',headerName: 'Committed At', sortable: true,suppressSizeToFit:true,flex:1, resizable:true, filter: true },
];
 const Status=[
  {key:1,label:"Pending Reconciliation",value:"pending"},
  {key:2,label:"Reconciled",value:"processed"}
]

export const Offloads = (props) => {
  const { t } = useTranslation();
  // const [state, setState] = useState();
  const tableRef = useRef();
  const [perPageLimit, setPerPageLimit] = useState(10);
  // eslint-disable-next-line no-unused-vars
  const [searchKey, setSearchKey] = useState('');
  const [date, setDate] = useState(new Date());
  const [storesList, setStoresList] = useState([]);
  const [statusSelect, setStatusSelect] = useState('');
  const [storeSelect, setStoreSelect] = useState('');
  const[storeArray,setStoreArray]=useState([])
  const[statusArray,setStatusArray]=useState([])
  const[storeId,setStoreId]=useState('')
  const preCompile = {
    itemType: t('selectButtons.selSector'),
    flightTime: t('selectButtons.selFlightTime'),
    status: t('selectButtons.selStatus')
  }

  React.useEffect(() => {
    fetchStores();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    fetchOffloads();
    fetchStatus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId,date,statusSelect]);

  React.useEffect( () => {
    refreshTable();
  }, [setStoreSelect, tableRef]);


  const refreshTable = () => {
    tableRef.current.refreshTableHandler(setStoreSelect);
  };

const onDateSelect = (e) => {
  setDate(e.target.value)
}

const getEffectiveDate=(dateChange)=>{
  if(dateChange!==undefined){
      let effTime = moment(dateChange).format('YYYY-MM-DD');
      return effTime + "T00:00:00+05:30";
  }
}


  const fetchStores = async () => {
    try {
      const resp = await APIEndpoint.get('stores');
    if (resp.result.length > 0) {
      setStoreArray(resp.result)
      const options = [{key: 0, label: preCompile.itemType, value: ''}];
      resp.result.filter( (fieldValue, index) => {
        options.push({key: index+1, label: `${fieldValue.flightNumber} (${fieldValue.originName} - ${fieldValue.destinationName})`, value: `${fieldValue.flightNumber}(${fieldValue.originName}-${fieldValue.destinationName})`});
        return fieldValue;
      });

      setStoresList( options );

    }
    else {
      console.log('Log: Error while fetching item types API');
    }
    } catch (error) {
      
    }
    
  };

  const fetchStatus =  () => {
      const options = [{key: 0, label: preCompile.status, value: ''},...Status];
      setStatusArray( options );
  };

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const fetchOffloads = async (page=1, perPage=10) => {
let selArr=[{storeId},{status: statusSelect},{flightDate:  getEffectiveDate(date)}]
    filterData.page = page;
    filterData.limit = perPage;
    filterData.searchParam = searchKey;
selArr.map((_)=>Object.keys(_).forEach(key => {
  if (_[key] === '') {
    delete _[key];
  }
}))
let setArr=selArr.filter((_)=>Object.keys(_).length!==0);

 let setNewArr=filterData.filters.concat(setArr);
 filterData.filters=setNewArr;
 if(filterData.filters.length){
    try {
        const res = await APIEndpoint.post('reconciliation/getRecons', JSON.stringify(filterData));
        return res;
     }
     catch (error) {
     }
 }else{
  return []
 }


  }

  const onPerLimitChange = (e) => {
    setPerPageLimit(Number(e.target.value));
  };


  const onStoreChange = (e) => {
      setStoreSelect(e.target.value);
        const storeNum = e.target.value.substring(0, e.target.value.indexOf('('));
        const l= getStoreId(storeNum)
        setStoreId(l)
  }
  const onStatusChange=(e)=>{
    setStatusSelect(e.target.value);

  }
  const getStoreId=(storeNum)=>{
    if(storeArray.length){
      let storeSelObj=storeArray.find((i)=>{return storeNum===i.flightNumber})
        return storeSelObj?.id;
      }
  }

  return (
      <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>

          <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>

              <button className='page-button'>
                  <select className="darkBlue-bg" onChange={onPerLimitChange} value={perPageLimit} id="page-size">
                      {
                      PageSizes.map((_) => {
                          return (
                          <option key={_.value} value={_.value}>{_.label}</option>
                          )
                      })
                      }
                  </select>
              </button>

              <div className="flexRow alignItemsBaseline">
                  <button className='item-button'>
                  <select className='select-item' onChange={onStoreChange} value={storeSelect} id="item-type">
                      {storesList.map( (item, index) => (
                          <option key={index} value={item.value}>{item.label}</option>
                      ))
                      }
                  </select>
                  </button>
              </div>

              <div className="flexRow justifyContentFlexEnd alignItemsBaseline">
                  <button className='item-button'>
                  <select className='select-item' onChange={onStatusChange} value={statusSelect} id="item-type">
                      {statusArray.map( (item, index) => (
                          <option key={index} value={item.value}>{item.label}</option>
                      ))
                      }
                  </select>
                  </button>
              </div>
              <div className={`dialog__description`}>
                                 <NGODatePicker
                                    isclearable={false}
                                    value={date}
                                    placeholder={t('effectiveDate')}
                                    placeholdertext={t('datePickerPlaceholder')}
                                    onChange={onDateSelect} />
                            </div>

          </div>

          <DataGrid
            ref={tableRef}
            className="full-flex"
            columns={[...Columns]}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            searchKey={searchKey}
            getRows={fetchOffloads}
            noResultsMessage={ 'No offloads found!' }
            rowSelection="multiple"
            columnResize={true}
          />
        </>
      }
      {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}