/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { APIEndpoint } from '../../../utils/axios'
import { FaArrowRight, FaFile, FaFlag, FaList, FaMoneyBill1, FaRegCreditCard } from 'react-icons/fa6';
import moment from 'moment';
import { ORDER_PAYMENT_TYPE_LIST, ORDER_STATUS_LIST } from '../../../utils/configs';
import { FaRegTimesCircle } from 'react-icons/fa';
import { getOrderSaleType } from '../../sales/inner-components/reconcilled';
import { AgGridReact } from 'ag-grid-react';
import { OrdNoRenderer, OrderStatusRenderSel, PaymentTypeRenderSel, SectorRenderer } from '../../sales/inner-components/renderers';

const DATE_FORMAT = "DD-MM-YYYY"; // hh:mm A

const Columns = [
  {
    field: "orderNo",
    headerName: "Sector",
    minWidth: 184,
    tooltipField: "flightNumber",
    autoHeight: true,
    pinned: "left",
    cellRendererSelector: (params) => {
      return {
        component: SectorRenderer,
      };
    },
  },
  {
    field: "orderNo",
    headerName: "Order Number",
    sortable: false,
    filter: false,
    minWidth: 240,
    tooltipField: "orderNo",
    autoHeight: true,
    cellRendererSelector: (params) => {
      return {
        component: OrdNoRenderer,
      };
    },
  },
  {
    field: "timestampDateTime",
    headerName: "Order Date",
    sortable: false,
    filter: false,
    minWidth: 150,
    tooltipField: "timestampDateTime",
    autoHeight: true,
    valueGetter: (params) => {
      return params.data
        ? moment(params.data?.timestampDateTime).format(DATE_FORMAT)
        : "";
    },
  },
  {
    field: "orderStatus",
    headerName: "Order Status",
    sortable: false,
    filter: false,
    minWidth: 140,
    tooltipField: "orderStatus",
    autoHeight: true,
    cellRendererSelector: (params) => {
      return {
        component: OrderStatusRenderSel,
      };
    },
  },
  {
    field: "saleTypeRaw",
    headerName: "Sale Type",
    sortable: false,
    filter: false,
    tooltipField: "saleTypeRaw",
    minWidth: 150,
    autoHeight: true,
    valueGetter: (params) => {
      return params.data ? getOrderSaleType(params.data.saleTypeRaw) : "";
    },
  },
  {
    field: "paymentTypeRaw",
    headerName: "Payment Type",
    sortable: false,
    filter: false,
    minWidth: 160,
    tooltipField: "paymentTypeRaw",
    autoHeight: true,
    cellRendererSelector: (params) => {
      return {
        component: PaymentTypeRenderSel,
      };
    },
  },
  {
    field: "totalAmount",
    headerName: "Total Amount",
    sortable: false,
    filter: false,
    minWidth: 160,
    autoHeight: true,
    valueGetter: (params) => {
      return params.data
        ? params.data.currencyInfo.currencyCode + " " + params.data.totalAmount
        : "";
    },
  },
];

const CalendarSales = (props) => {
  const [salesData, setSalesData] = useState([])
  const {group:{sectors
  }} = props
  const {dateUTC} = props.group
  const sectorIds = sectors.map(sector=>{
    return sector.store.id
  })

  useEffect(() => {
    if(sectorIds.length > 0){
      sectorIds.forEach(id => {
        fetchSalesData(id)
      });
    }
  }, [])

  const filterData = {
    action: 4,
    collection: "",
    filterOperation: "or",
    filters: [{orderDate:dateUTC}],
    limit: 1000,
    page: 1,
    searchParam: "",
    sort: [{ field: "timestampDateTime", order: "desc" }],
  };
  
  async function fetchSalesData(storeId) {
    filterData.filters = []
    filterData.filters.push({ storeId: storeId });
    filterData.filters.push({orderDate:dateUTC});
    try {
        const res = await await APIEndpoint.post(
            "orders/process",
            JSON.stringify(filterData),
          );
          setSalesData(prev=>[...prev,...res.items])
    } catch (error) {
        console.log(error)
    }
  }

  const getOrderStatusColorCode = (orderStatus) => {
    if (orderStatus) {
      const ordSts = ORDER_STATUS_LIST.find( _ => _.key === orderStatus );
      if (ordSts) {
        return (
          <>
            { ordSts.value === 'Accepted' && <FaFlag color={ ordSts.colorCode_text } size={15}/> }
            { ordSts.value === 'Cancelled' && <FaRegTimesCircle color={ ordSts.colorCode_text } size={15}/> }
            &nbsp;&nbsp;<span style={{ fontWeight: 'bold'}} >{ordSts.value}</span>
          </>
        );
      }
      else {
        return <>{ordSts}</>
      }
    }
    return null;
  }

  const getOrderPaymentType = (paymentType) => {
    if (paymentType) {
      if (paymentType) {
        const ptObj = ORDER_PAYMENT_TYPE_LIST.find( _ => _.key === paymentType );
        if (ptObj) {
          return (
            <>
            { ptObj.value === 'SSR' && <FaFile color={ ptObj.colorCode_text ?? '' } size={16}/> }
            { ptObj.value === 'Cash' && <FaMoneyBill1 color={ ptObj.colorCode_text ?? '' } size={16}/> }
            { ptObj.value === 'Card' && <FaRegCreditCard color={ ptObj.colorCode_text ?? '' } size={16}/> }
            { ptObj.value === 'Multiple' && <FaList color={ ptObj.colorCode_text ?? '' } size={16}/> }
              &nbsp;&nbsp;<span style={{ fontWeight: 'bold'}} >{ ptObj.value }</span>
            </>
          )
        }
      }
      return paymentType;
    }
  }

  const getLoadingStatus = ()=>{
    return 'Please Wait while loading data...';
  }
    
  return (
    <div className='op-aircraft-container ng-grid-right flexCol full-flex ag-theme-alpine-dark' style={{overflowX:"scroll",height:"72vh"}}>
      <>
        <div className="table-header"></div>
          <AgGridReact
            id='ngo-grid'
            suppressCellFocus
            className='ngo-grid'
            columnDefs={Columns}
            overlayLoadingTemplate={getLoadingStatus()}
            rowData={salesData.length?salesData : []}
            suppressRowTransform={true}
          />
        </>
    </div>
  )
}

export default CalendarSales