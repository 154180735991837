import React, { useState, useEffect } from 'react'
import '../assets/styles/modal.scss'
import closeIcon from '../images/icons/Icon2.png'
import { APIEndpoint } from '../utils/axios';

export const CartSub = (props) => {
  const [values,setValues]= useState(props.selected || {})
  let isError = false;
  let updateStatusText = '';
  
  if (props.modalFormStatusObj) {
    if ( ('text' in props.modalFormStatusObj)) {
      updateStatusText = props.modalFormStatusObj.text;
    }
    if ( ('error' in props.modalFormStatusObj)) {
      isError=props.modalFormStatusObj.error;
    }
  }
  
  return (
      <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar">
        <div className="dialog__content">
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">{props.title}</h2>
          <hr />
          <div className="dialog__body_container flexCol alignItemsCenter">
            <div className='dialog__container_item input-container'>
            <div className={`dialog__description`}>
              <label className='req'>Category</label>
              <select 
              // {...rest} 
              id="category-select"
              className='select-input'
              value={values.catName?values.catName:""}
              onChange={(e) => setValues(prev => ({ ...prev, catName: e.target.value }))}
              >
              {
                props.options.map((_) => {
                  if(_.key===0 && _.label==="Select Sub Category"){
                    return (
                      <option value={_.value} key={_.value}>{_.label}</option>
                    )
                  }
                  else{
                    return (
                    <option value={_.value} key={_.value}>{_.label}</option>
                  )
                  }
                })
              }
            </select>
            </div>
            <div>
            <label className="req mt-4">Name</label>
            <div id="sealNo" className="dialog__center flexRow">
                <input id="subCat" type='text' className='subCat edit-input' placeholder='Sub Category Name'
                   value={values.name?values.name:""}
                   onChange={(e)=>setValues(prev => ({ ...prev, name: e.target.value }))}
                />
              <button className="additional-button button5" onClick={props.onAdd}>+</button>
            </div>  
          </div>
          <div className="dialog__footer">
          <button style={{marginLeft:"20px"}} className="done-button" onClick={props.onSubmit}>Save</button>
        </div>
          </div>
         
        </div>
        {updateStatusText ?
          <>
            <div className={ "dialog__statusBlock " + (isError ? 'alert_error' : 'alert_success') }>
              {updateStatusText}
            </div>
          </> : null }
        </div>
      </div>
    </div>
  );
}
export const SubCategoryModal = (props) => {
  const [count, setCount] = useState(0);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    filterCategories()
  }, [])

  const onSubmit = (e) => {
    let selectVal = document.querySelectorAll("#category-select")[0].value;
    let val = document.querySelectorAll(".subCat");

    const found = options.find(obj => {
      return obj.value === selectVal;
    });

    let array = [];
    val.forEach((it) =>{
      array.push({subCategory: it.value})
    })
    let updated = [{
      category: {id : found.key, value : selectVal},
      sub_categories: array
    }]
    props.onSubmit(e, updated)
    removeContainerOverlay();
  }

  const onClose = (e) => {
    removeContainerOverlay();
    props.onClose();
  }

  const filterCategories = async () => {
    const resp = await APIEndpoint.get('categories');
    if (resp.result.length > 0) {
      const optionsVal = [{key: 0, label: 'Select Category', value: ''}];
      resp.result.filter( (fieldValue, index) => {
        optionsVal.push({key: fieldValue.id, label: fieldValue.value, value: fieldValue.value});
        return fieldValue;
      });
      setOptions(optionsVal)
    } else {
      console.log('Log: Error while fetching category');
    }
  };

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  const onAdd = (e) =>{
    setCount(count + 1)
      var newText = document.createElement( 'div' ); 
      newText.innerHTML = (`
        <div  id=${"sealNo"+count} class="dialog__center flexRow">
          <input type='text' id=${"subCat"+count} class='subCat edit-input' placeholder='Sub Category Name'/>
          <button class="additional-button button5" onclick="onRemove(${count})">-</button>
        </div>
      `);
      (document.querySelectorAll('#sealNo')[0]).before(newText);
  }

  window.onRemove = (count) => {
    document.getElementById("sealNo"+count).remove()
  }

  window.onAdd = (count) => {
    onAdd(count)
  }

  return (
    <CartSub
      title={props.title}
      onClose={onClose}
      options={options}
      onAdd={onAdd}
      modalFormStatusObj={props.modalFormStatusObj}
      onSubmit={(e) => onSubmit(e)}
      selected={props.editRow}>
    </CartSub>
  )
}