import React from 'react'
import { render } from "react-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import "react-select-search/style.css";
import './assets/styles/styles.scss'

// import App from "./App";
import Login from "./pages/login/Login";
import { HomePage } from "./pages/home";

const rootElement = document.getElementById("root");

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // lng: document.querySelector('html').lang, // if you're using a language detector, do not define the lng option
    supportedLngs: ['en', 'ar', 'fr','sp'],
    fallbackLng: "en",
    detection: {
      order: ['path', 'cookie', 'htmlTag', 'localStorage', 'sessionStorage', 'navigator', 'path', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
    react: {
      useSuspense: false
    }
  });

render(
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/*" element={<HomePage />} />
    </Routes>
  </BrowserRouter>
  ,
  rootElement
);
