import React, { forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";

const DateInput = forwardRef((props, ref) => {
  return (
    <input
      placeholder={props.placeholder || ''}
      {...props}
      type="text"
      ref={ref}
      className='edit-input'
    />
  );
});

export const NGODatePicker = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { placeholder, value, className, id, minDate, maxDate,filterDate,dateFormat,selectsStart,selectsEnd, disabled,required } = props;
  const isclearable = props.isclearable !== undefined ? props.isclearable : true;
  const isOPen = props.isOpen !== undefined ? props.isOpen : false;
  const onCalendarOpen = typeof props.onCalendarOpen === 'function' ? props.onCalendarOpen : null;
  const onCalendarClose = typeof props.onCalendarClose === 'function' ? props.onCalendarClose : null;



  return (
    <div className={`date-input ${className || ''}`}>
    {onCalendarOpen&& onCalendarClose ?<DatePicker
        id={id ? id : ''}
        isclearable={isclearable}
        selectsStart={selectsStart||false}
        selectsEnd={selectsEnd||false}
        isOpen={isOPen}
        isClearable={props.isClearable}
        onCalendarOpen={onCalendarOpen}
        onCalendarClose={onCalendarClose}
        dateFormat={dateFormat||"dd/MM/yyyy"}
        selected={value || null}
        placeholderText={placeholder || t('datePickerPlaceholder')}
        customInput={<DateInput {...props} ref={ref} />}
        minDate={minDate}
        maxDate={maxDate}
        filterDate={filterDate}
        disabled={props.isDisabled?props.isDisabled:props.disabled?props.disabled:false}
        required={required ? true : false}
        onChange={(_v) => {
          props.onChange && props.onChange({ target: { ...props, value: _v } });
        }}
      />:<DatePicker
        id={id ? id : ''}
        isclearable={isclearable}
        selectsStart={selectsStart||false}
        selectsEnd={selectsEnd||false}
        dateFormat={dateFormat||"dd/MM/yyyy"}
        selected={value || null}
        disabled={props.isDisabled?props.isDisabled:props.disabled?props.disabled:false}
        placeholderText={placeholder || t('datePickerPlaceholder')}
        customInput={<DateInput {...props} ref={ref} />}
        minDate={minDate}
        maxDate={maxDate}
        required={required ? true : false}
        onChange={(_v) => {
          props.onChange && props.onChange({ target: { ...props, value: _v } });
        }}
      />}
    </div>
  );
});
