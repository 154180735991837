import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { FlightAircraftsPage, SectorPage, OffloadSummaryPage, SectorDetailsPage, CrewSalesPage } from "./inner-components";

export const FlightsPage  = (props) => {
  return (
    <div className="flexRow full p-0 m-0">
      <div className='full-flex flexCol width100 p-0 m-0'>
        <Routes>
          <Route path="/" element={<Navigate replace to="aircrafts" />} />
          <Route path="/aircrafts" element={<FlightAircraftsPage />} />
          <Route path="/sectors" element={<SectorPage />} />
          <Route path="/offload-summary" element={<OffloadSummaryPage />} />
          <Route path="/crew-sales" element={<CrewSalesPage />} />
          <Route path="/sector-details" element={<SectorDetailsPage />} />
        </Routes>
      </div>
    </div>
  )
}
