/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState,useEffect} from 'react'
import '../assets/styles/modal.scss'
import closeChart from '../images/icons/closeChart.png'
import deltaup from '../assets/icons/deltaup.svg'
import searchIcon from '../assets/icons/search.svg';

import { AgGridReact } from "ag-grid-react";
import { useTranslation } from 'react-i18next';
import {BarCharts,PieChart}  from '../components/dashboard';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import ProgressBar from './ProgressBar';

export const ViewCharts = (props) => {
  const {chartType,chartZoom,chartData,info,label,chartOptions,filtersChartZoom,setFiltersChartZoom,onClose}=props;
  const [percent1,setPercent1]=useState(0);
  const [percent2,setPercent2]=useState(0);
  const { t } = useTranslation();
  const [searchKey, setSearchKey] = useState('');
  const [listData,setListData]=useState([]);
  const sType = localStorage.getItem('sType');

useEffect(() => {
 if(info){
  setPercent1(info.salesInfo.orders/100)
  setPercent2(info.salesInfo.income/100)
  setCardTitle(label)
 }
}, [info,label])

useEffect(() => {
  if(!searchKey){
    if(chartData?.rowsData&&chartData.title==='Physical Quantity of Products Sale Warehouse Wise'&&sType==='akasa'){
      let chartsProd=chartData?.rowsData.map(item=>({soldQty: item.soldQty, product: item.product?.name}));
      setListData(chartsProd);
      }else{
        setListData(chartData?.rowsData||[])
      }
  }
 }, [searchKey])

 const onSearch = (e) => {
  setSearchKey(e.target.value);
  if(searchKey&&chartData.rowsData){
    setListData(chartData.rowsData.filter((list) => {
      const searchTermLower = searchKey.toLowerCase();
if(Object.values(list).toString().toLowerCase().replaceAll(",","").includes(searchTermLower)){
return true;
}
else{
return false
}
    }))
  }

};

const setCardTitle=(label)=>{
  let title=''
switch (label) {
  case 'totalSales':
    title= "Total Sales"
    break;
    case 'totalOrders':
    title= "Total Orders"
    break;
    case 'currMonthSales':
    title= "Current Month Sales"
    break;case 'currMonthOrders':
    title= "Current Month Orders"
    break;
  default:
    break;
}
return title
}
const rowClass = "tableRowClass";
const getRowClass = (params) => {
  if (params.node.rowIndex % 2 === 0) {
    return 'tableRowClass';
  }
};
  const getSalesInfo=(data)=>{
    const res=data.map(_=>{

      return(<>
                <div className='margRight2' style={{width:'100%'}} >
                  <p className='subheading-text-small-title' style={{marginTop:'1rem'}}> Total Revenue </p>
                    <span className='order_numbers'>${_.revenue.total}</span>
                  </div>
                  <div className='margRight2'style={{width:'100%'}}>
                  <p className='sales_mode caption-light'> Total Orders </p>
                    <span style={{fontSize: '20px'}}>{_.revenue.orders}</span>
                  </div>
                  <div className='margRight2' style={{width:'100%'}} >
                  <p className='subheading-text-small-title' style={{marginTop:'1rem'}}> From Campaigns </p>
                    <span className='order_numbers'>${_.campaign.total}</span>
                  </div>
                  <div className='margRight2'style={{width:'100%'}}>
                  <p className='sales_mode caption-light'> orders </p>
                    <span style={{fontSize: '20px'}}>{_.campaign.orders}</span>
                  </div>
                  <div className='margRight2' style={{width:'100%'}} >
                  <p className='subheading-text-small-title' style={{marginTop:'1rem'}}> From Automations </p>
                    <span className='order_numbers'>${_.automation.total}</span>
                  </div>
                  <div className='margRight2'style={{width:'100%'}}>
                   <p className='sales_mode caption-light'> orders </p>
                    <span style={{fontSize: '20px'}}>{_.automation.orders}</span>
                  </div>
</>)
    })
    return res;
  }

  const showChartViews=()=>{
    if(chartType!=='cards'){
     return(<div className={`overlay_dashboard ${props.isSubModal ? 'sub' : ''}`} role="dialog">
      <div className="dashboard_enlarge sm-scrollbar">
        <div  id="salesCard" className={`dialog__content ${props.className || ''}`}>
          <img className="modal__close_icon" alt='Close' src={closeChart} onClick={onClose} />
            {chartType==='bar'&&<BarCharts chartZoom={chartZoom} filtersChartZoom={filtersChartZoom} setFiltersChartZoom={setFiltersChartZoom} data={chartData} options={chartOptions}/>}
            {chartType==='pie'&&<PieChart chartZoom={chartZoom} filtersChartZoom={filtersChartZoom} setFiltersChartZoom={setFiltersChartZoom} data={chartData}/>}
            {chartType==='list'&&<><div className="list-title" style={{marginBottom:'1rem'}}>{props.chartData?.title}
            <div className='flexRow width100 justifyContentCenter margBtm16 margTop8 alignItemsCenter'>
        <div className="underline">
        <span><img className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
        <span><input className="search-layout" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={searchKey} /></span>
        </div>
        </div>
            </div>
              <div className="ag-theme-alpine-dark">
                <AgGridReact
                  reactNext={true}
                  columnDefs={ chartData.columnDefs }
                  rowModelType="clientSide" // clientSide,infinite,viewport,serverSide
                  rowData={listData}
                  rowHeight={36}
                  rowClass={rowClass}
                  getRowClass={getRowClass}
                  domLayout='autoHeight'
                  rowSelection={ 'multiple' } // single|multiple
                  isScrolling={ () => { console.log('scrolling...'); return false; } }
                  cacheQuickFilter={false} // for a performance gain
                  sortingOrder={'desc'} // 'asc'|'desc'|null
                  accentedSort={false} // true: sort by ascented charecters(Slow performance)
                  excludeChildrenWhenTreeDataFiltering={false} // exclude child nodes from filter results
                  alwaysShowHorizontalScroll={true}
                  suppressColumnVirtualisation={true}
                />
              </div>


              </>
            }
        </div>
        </div>
        </div>)
      }
    else{
  return(<div className={`overlay_dashboard ${props.isSubModal ? 'sub' : ''}`} role="dialog">
          <div className="dashboard_enlarge sales_main_desciption sm-scrollbar">
            <div id="salesCard" className={`dialog__content_salescard ${props.className || ''}`}>
              <img className="modal__close_icon" alt='Close' src={closeChart} onClick={onClose} />
              <h1 className="subheading-text-medium h2"> {setCardTitle(label)}</h1>
              <hr/>
              <div className='sales_quartermain'>
                 <p class="sales_mode">Orders<span>{info.salesInfo.orders}</span></p>
                <ProgressBar percent={percent1}/>
               <p class="sales_mode caption-light">Monthly Target<span>{info.salesInfo.monthlyTargetOrders}%</span></p>
              </div>
              <div className='sales_quartermain'>
                 <p class="sales_mode">Income<span>{info.salesInfo.income}</span></p>
                 <ProgressBar percent={percent2}/>
               <p class="sales_mode caption-light">Monthly Target<span>{info.salesInfo.monthlyTargetIncome}%</span></p>
              </div>
              <p class="sales_mode">Quality score Change</p>
              <div  className='score_display'>
                <div className='sales_stat'>
                <h6 className='sales_mode caption-light margLeft8'> Failed Rows </h6>
                 <h1>{info.qualityScoreChange.failedRows} <span style={{fontSize: '20px'}}>%</span></h1>
                </div>
                <div className='sales_stat'>
                <h6 className='sales_mode caption-light margLeft8'>Delta </h6>
                <h1><span><img className="icon-size-sales_delta" alt='deltaup' src={deltaup}/></span>{info.qualityScoreChange.delta} <span style={{fontSize: '20px'}}>%</span></h1>
                </div>
                <div className='sales_stat'>
                <h6 className='sales_mode caption-light margLeft8'>Runs </h6>
                <h1 > {info.qualityScoreChange.runs} <span style={{fontSize: '20px'}}>%</span></h1>
                </div>
              </div>
              <div style={{display:'flex'}}>
                <div className='sales_quarter_second padding3'>
                  {getSalesInfo(info.salesData)}
                <div className ='vertical_spereator padding3'></div>
              </div>
               {/* <div className='chart_sales padding3'>
                      <Bar
                          data={ chartData}
                          options={chartOptions}
                          />
                    </div>  */}
              </div>
              </div>
          </div>
        </div>)
}
  }
    return (
      <> {showChartViews()}</>

      );
}

export const ShowChartFullScr = (props) => {
  const onCloseView = (e) => {
    removeContainerOverlay();
    props.onClose();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <ViewCharts
      onClose={onCloseView}
      chartType={props.chartType}
      chartZoom={props.chartZoom}
      filtersChartZoom={props.filtersChartZoom}
      setFiltersChartZoom={props.setFiltersChartZoom}
      info={props.salesData}
      label={props.chartLabel}
      chartData={props.chartType==='cards'?props.chartData:props.chartData}
      chartOptions={props.chartType==='cards'?props.chartOptions:props.chartOptions}
    />
  )
}
