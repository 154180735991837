import React, { useState } from "react";
import '../assets/styles/styles.scss'


export const TooltipLabel = (props) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };
  const instructionLabel=(props)=>{
    return(<div id ='dFyn' className={`Tooltip-Tip ${props.direction || "top"}`}>
  {props.content}
      </div>)
  }

  return (
    <div
      className="Tooltip-Wrapper"
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {props.children}
      {active && (
      instructionLabel(props)
      )}
    </div>
  );
};
