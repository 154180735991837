import originAxios from "axios";
import { HOST_SERVER } from "./configs";
//DFP login**********************
// export const HOST_SERVER = process.env.REACT_APP_API_SERVER || 'https://dfp.ngopos.com';
// export const DFP_HOST_SERVER = process.env.REACT_APP_DFP_API_SERVER || 'https://dfp.ngopos.com';

// //Akasa login********************
// export const HOST_SERVER = process.env.REACT_APP_API_SERVER || 'https://akasa-test-svcs.ngopos.com';
// export const DFP_HOST_SERVER = process.env.REACT_APP_DFP_API_SERVER || 'https://akasa-test-svcs.ngopos.com';
// export const AI_HOST_SERVER = process.env.REACT_APP_AI_API_SERVER || 'https://ngo3-sandbox-svcs.ngopos.com';


const BEARER_TOKEN = "bearer_token";
const TOKEN = "token";
const USER_ID = "user_id";
const CSRF_TOKEN = 'X-CSRF-Token';

const HEADERS = {
  "Accept": "application/json",
  "Content-Type": "application/json"
}

function errorhandler(errorRes) {
  const { response } = errorRes;

  if (response) {
    if (response.status === 401) {
      console.log('Log: User session has been expired!');
      window.location = '/login';
    }
    throw new Error( JSON.stringify({ status: errorRes.response.status, data: errorRes.response.data }) );
  } else {
    throw new Error( JSON.stringify({ data: { message: "Error occured!", success: false, error: errorRes } } ));
  }
}

class MyAxios {
  _endpoint = '';
  _host = '';

  constructor(endpoint, customURL = false) {

    if (customURL) {
      this._host = endpoint;
      this._endpoint = endpoint;
    }
    else {

      this._host = HOST_SERVER + endpoint;
      this._endpoint = endpoint;

      const hostType = localStorage.getItem('sType');
      if ( hostType ) {
          this._host = HOST_SERVER + endpoint;
      }
    }

    this.axiosInst = originAxios.create({
      baseURL: this._host,
      withCredentials: true
    });
  }

  setHost() {
    const hostType = localStorage.getItem('sType');

    if ( hostType ) {
     
        this._host = HOST_SERVER + this._endpoint;
    }

    this.axiosInst = originAxios.create({
      baseURL: this._host,
      withCredentials: true
    });

    return this;
  }

  getFullPath(path) {
    return `${this._host}${path}`
  }
   getHeaders(additional = {}) {
    let newheaders = {},
      userId = localStorage.getItem(USER_ID),
      bearerToken = localStorage.getItem(BEARER_TOKEN),
      token = localStorage.getItem(TOKEN);

    if (bearerToken) {
      newheaders['X-Bearer-Token'] = bearerToken;
    }
    if (token) {
      newheaders[CSRF_TOKEN] = token;
    }
    if (userId) {
      newheaders['user_id'] = userId;
    }
    const {signal, ...rest } = additional;

    const retObj = { headers: { ...HEADERS, ...newheaders, ...rest } }
    if (signal) {
      retObj.signal = signal;
    }

    return retObj;

  }

  async responseHandler(response) {
    if (response.headers[CSRF_TOKEN]) {
      localStorage.setItem(TOKEN, response.headers[CSRF_TOKEN]);
    }
    return response.data;
  }
  get(path, newheaders, returnFullResp = false) {
    this.setHost();

    if (returnFullResp === true) {
      return this.axiosInst.get(path, this.getHeaders(newheaders));
    } else {
      return this.axiosInst.get(path, this.getHeaders(newheaders)).then(this.responseHandler).catch(errorhandler);
    }
  }
  post(path, body, newheaders) {
    this.setHost();

    return this.axiosInst.post((this._host + path), body, this.getHeaders(newheaders))
      .then(this.responseHandler)
      .catch(errorhandler)
  }
  postWithFullResp(path, body, newheaders) {
    this.setHost();

    return this.axiosInst.post((this._host + path), body, this.getHeaders(newheaders))
      .then(response => response)
      .catch(errorhandler)
  }
  delete(path, newheaders) {
    this.setHost();

    return this.axiosInst.delete((this._host + path), this.getHeaders(newheaders))
      .then(this.responseHandler)
      .catch(errorhandler)
  }
  put(path, body, newheaders) {
    this.setHost();

    return this.axiosInst.put((this._host + path), body, this.getHeaders(newheaders))
      .then(this.responseHandler)
      .catch(errorhandler)
  }
  patch(path, body, newheaders) {
    this.setHost();

    return new Promise((resolve, reject) => {
      this.getHeaders(newheaders)
        .then(headers => {
          if (body instanceof FormData) {
            headers['Content-Type'] = 'multipart/form-data'
          }
          return this.axiosInst.patch(this._host + path, body, headers)
        })
        .then(res => {
          return this.responseHandler(res)
        }).then(resolve).catch(reject)
    }).catch(errorhandler)
  }
}


export const APIEndpoint = new MyAxios('/api/');
export const UserEndpoint = new MyAxios('/user/');
export const EmployeeEndpoint = new MyAxios('/employee/');
export const AssetEndpoint = new MyAxios('/');

export const DFPAPIEndpoint = new MyAxios('/api/');
export const DFPAPIEndpointGalley = new MyAxios('/');
export const DFPUserEndpoint = new MyAxios('/user/');
export const DFPCALNDREndpoint= new MyAxios('/api/operations/api/v1/')
export const CSBNPORATLAPIEndpoint = new MyAxios('/api/portal/v1/authz/');

