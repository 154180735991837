import React from "react";
import { getDateFromTimezone } from "./Cards";

const CountTable = () => {
  return (
    <div className="flexCol">
      <table className="sub-table">
        <thead>
          <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}}>
            <th>Eco</th>
            <th>Pre Eco</th>
            <th>Business</th>
            <th>First</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>10</td>
            <td>10</td>
            <td>10</td>
            <td>10</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export const CateringSummary = (props) => {
  const { group, timezone } = props
  return (
    <div className="flexCol cntrl-calender-summary oy-auto">
<table>
          <tbody>
          <tr>
            <td>Flight Date</td>
            <td>{getDateFromTimezone(group.date, timezone).format("YYYY-MM-DD z")}</td>
          </tr>
          <tr>
            <td>STD</td>
            <td>{getDateFromTimezone(group.startTime, timezone).format("YYYY-MM-DD HH:mm z")}</td>
          </tr>
          <tr>
            <td>ETD</td>
            <td>{getDateFromTimezone(group.endTime, timezone).format("YYYY-MM-DD HH:mm z")}</td>
          </tr>
          <tr>
            <td>Tail Number</td>
            <td>{group.sectors.length > 0 ? group.sectors[0].tailNumber : ''}</td>
          </tr>
          <tr>
            <td>Pax Summary</td>
            <td>
              <CountTable />
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{textAlign:'center',fontWeight:'bold'}}>Sectors</td>
          </tr>
          {
            group.sectors.map((sector, index) => {
              return (
                <tr key={index}>
                  <td>{sector.origin} {">"} {sector.destination}</td>
                  <td>
                    <CountTable />
                  </td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
    </div>
  )
}