import React, { Component } from 'react';

export default class CustomTooltip extends Component {
  getReactContainerClasses() {
    return ['custom-tooltip'];
  }

  render() {
    const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex)
      .data;
    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: this.props.bgColor || '#7DA4B1', color: this.props.color || '#FFFFFF' }}
      >
        <p>
          <span>{data[this.props.field]}</span>
        </p>
      </div>
    );
  }
}