import React, { useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import { useTranslation } from "react-i18next";

import searchIcon from '../../../assets/icons/search.svg';
import viewIcon from "../../../assets/view.svg";

import { DataGrid, EditModal } from "../../../common";
import { APIEndpoint } from "../../../utils/axios";
import { PageSizes, FilterBodyTemplate } from "../../../utils/configs";
import { translationLength } from "../../../utils/commonFunctions";

const Columns = [
    { field: 'code', headerName: 'GRN Code', sortable: true, filter: true, checkboxSelection: false,
        minWidth: 190,
        tooltipField: 'code'
    },
    { field: 'warehouseCode', headerName: 'Warehouse', sortable: true, filter: true,
        minWidth: 290,
        tooltipField: 'warehouseName',
        valueGetter: params => params.data ? params.data.warehouseCode + ' (' + params.data?.warehouseName + ')' : '',
    },
    { field: 'vendorCode', headerName: 'Vendor', sortable: true, filter: true,
        minWidth: 200,
        tooltipField: 'vendorName',
        valueGetter: params => params.data ? params.data.vendorCode + ' (' + params.data?.vendorName + ')' : '',
    },
    { field: 'createdAt', headerName: 'Created At', sortable: true, filter: true,
        minWidth: 140,
        tooltipField: 'createdAt',
        valueGetter: params => {
            if ( params.data ) {
                return moment(params.data.createdAt).format('DD-MM-YYYY');
            }
        }
    },
    { field: 'createdBy', headerName: 'Created By', sortable: true, filter: true,
        minWidth: 200,
        tooltipField: 'createdBy.employeeName',
        valueGetter: params => params.data ? params.data.createdBy?.employeeName : ''
    },
    { field: 'items', headerName: 'Summary', sortable: true, filter: true,
        minWidth: 150,
        valueGetter: params => params.data ? params.data.items?.length + ' items' : ''
    }
];

const EditFormFields = [
    { label: 'View GRN Details', type: 'viewReverseGRNDetails', attribute: 'items' }
];

export const ReverseGRNList = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const urlParams = useParams();
    const warehouseId = urlParams.warehouseId;
    const grnId = urlParams.grnId;

    const [state, setState] = useState({});
    const [editFormFields, setEditFormFields] = useState(EditFormFields);
    const [perPageLimit, setPerPageLimit] = useState(5);
    const [searchKey, setSearchKey] = useState('');
    const [ isReloadTableData, setIsReloadTableData ] = useState(false);

    const [ modalTitle, setModalTitle ] = useState('View Purchase Order Items');
    const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
    const [modelStyle, setModalStyle] = useState({ maxWidth: '80%' });
    const [actionItems, setActionItems] = useState([]);
    const [modalCustomButtons, setModalCustomButtons] = useState([]);
    const [noResultsMessage, setNoResultsMessage] = useState( 'No Data' );

    useEffect( () => {
        setNoResultsMessage( t('pages.procurementSub.purchaseOrdersSub.noReverseGRNFound') );
    }, [t]);

    useEffect(() => {
        setActionItems([
                {
                    title: '',
                    attribute: 'viewReverseGRNDetails',
                    // detail: [
                    //     {src: suspendIcon, value:"Suspend", title: ''},
                    // ],
                    // width: 90,
                    // maxWidth: 90,
                },
                // {
                //     title: '',
                //     attribute: 'reverseGRN',
                //     width: 390,
                // }
            ]);
        setIsReloadTableData(true);
    }, []);

    const getActionCellIcons = (cellDef, data) => {
        if (cellDef.attribute === 'viewReverseGRNDetails') {
            return [
                {src: viewIcon, value:"viewReverseGRNDetails", title: 'Details'},
            ];
        }
        return [];
    };

    const onActionInner = (action, row) => {
        let updatedEditFields = [];

        if (action.value === 'viewReverseGRNDetails') {
            /** Here, Resetting default input fields to array then updating */
            setModalStyle( (_prev) => {
                return { ..._prev, maxWidth: '80%'};
            });
            setEditFormFields(EditFormFields);
            updatedEditFields = EditFormFields.filter( field => {
                if (field.type === 'viewReverseGRNDetails') {
                    setModalTitle('Reverse GRN - ' + row.code );
                    field.value = row;
                }
                return field;
            });
            setModalCustomButtons([[]]); // [[]]: To show no button
        }
        setEditFormFields( updatedEditFields );
        setState((_prev) => {
            return { ..._prev, selected: { ...row, action: action.value} };
        });
    };
    const onAction = (e, action, row) => {
        onActionInner(action, row);
    };

    const onClose = (e, action, row) => {
        setState((_prev) => {
            return { ..._prev, selected: false };
        });
        setModalFormStatusObj({ text: '' });
    };

    const fetchReverseGRNRows = async (page=1, perPage=10) => {
        const filterData = JSON.parse(JSON.stringify(FilterBodyTemplate));
        filterData.page = page;
        filterData.limit = perPage;
        filterData.searchParam = searchKey;

        const res = await APIEndpoint.post('warehouses/' + warehouseId + '/reversegrns/' + grnId + '/process', JSON.stringify(filterData));
        return res;
    };

    const onPerLimitChange = (e) => {
        setPerPageLimit(Number(e.target.value));
    };

    const onSearch = (e) => {

        ReactDOM.flushSync( () => {
            setSearchKey( _prev => {
                return e.target.value
            } );

            fetchReverseGRNRows();
        } );

    };

    const onCreateReverseGRNHandler = () => {
        navigate('/procurement/purchase-orders/reversegrn/create/' + warehouseId + '/' + grnId );
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                {
                    <>
                        <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween alignItemsCenter'>
            			<div className="flexRow justifyContentFlexStart">
	                            <button className='page-button'>
	                                <select className="darkBlue-bg" onChange={onPerLimitChange} value={perPageLimit} id="page-size">
	                                    {
	                                    PageSizes.map((_) => {
	                                        return (
	                                        <option key={_.value} value={_.value}>{_.label}</option>
	                                        )
	                                    })
	                                    }
	                                </select>
	                            </button>
	                        </div>

                            <div className="flexRow justifyContentFlexEnd">
                                <button className="add-item-button margRight" onClick={ onCreateReverseGRNHandler }>
                                    {t('buttons.createReverseGRN')}
                                </button>

	                            <div className="underline">
	                                <span><img  className="icon-size" alt='search' src={searchIcon} /></span>
	                                <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={searchKey}/></span>
	                            </div>
							</div>

                        </div>

                        <DataGrid
                            className="full-flex"
                            columns={Columns}
                            initialPageLimit={perPageLimit}
                            showDefaultFilters={false}
                            // searchKey={searchKey}
                            getRows={ fetchReverseGRNRows }
                            noResultsMessage={ noResultsMessage }
                            rowSelection="multiple"
                            actions={actionItems}
                            getActionCellIcons={getActionCellIcons}
                            onAction={ (e, action, data) => { return onAction(e, action, data) } }
                            onAddItemHandler={ () => {} }
                            columnResize={true}
                            resetDataSource={isReloadTableData}
                        />
                    </>
                }
                {
                    state && Boolean(state.selected) ? (
                        <EditModal
                            title={ modalTitle }
                            customModalStyle={ modelStyle }
                            onClose={onClose}
                            // onSubmit={onSubmit}
                            modalFormStatusObj={modalFormStatusObj}
                            fields={ editFormFields.map( (elt) => {
                                    if (state.selected.action === 'viewReverseGRNDetails') {
                                        return {...elt, initialValue: state.selected[elt.attribute] || ''}
                                    }
                                    else {
                                        return {...elt, initialValue: '' }
                                    }
                                })
                            }
                            showFieldsInColumn={true}
                            modalCustomButtons={modalCustomButtons}
                        />
                    )
                    : null
                }
                 {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
            </div>
        </>
    );
}