import React from 'react'

export const CollapsebleSectorInstance = (props) => {
  const {className,selectedItemIndex ,id} = props;
  return (
    <div className={`flexCol collapsable ${className || ''}`}>
    {(id===selectedItemIndex)?<div className={`flexCol container ${selectedItemIndex ? 'open' : ''}`}>
        <div className='flexCol content' style={{paddingBottom:'1rem'}}>
          {props.children}
        </div>
      </div>:<></>}
    </div>
  );
}