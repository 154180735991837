import React, {useEffect,useState} from 'react'
import '../assets/styles/styles.scss'
import footerlogo from "../assets/footerlogo.svg";
import footerline from "../assets/footerline.svg";
import { useTranslation } from 'react-i18next';



export const Footer=({mode}) =>{
  const [menuState, setmenuState] = useState(true);
  useEffect(() => {
      setmenuState(mode);
  }, [mode])
  const footerState = `footer${menuState === 'collapsed' ? '_collapsed' :menuState==='opened'?"-login":" "}`;
  const { t } = useTranslation();
  return (
    <div className={footerState}>
    <img src={footerlogo} alt="logo" className='footer-logo' />
    &nbsp;&nbsp;

    {
      (footerState !== 'footer_collapsed' && footerState !== 'footer-login') ? (
        <>
          {t('company.poweredBy')} &nbsp;&nbsp;{t('company.brandName')}
          <span className="registered-mark">®</span>
        </>
      ) : footerState === 'footer-login' ? (
        <div className='flexRow' style={{ color: '#fff' }}>
            {t('company.poweredBy')} &nbsp;{t('company.brandName')}
          <span style={{fontSize:'0.8rem' }} className="registered-mark">®</span>
        </div>
      ) : (
        <div className="flexCol" style={{ fontSize: '10px', marginLeft: '10px' }}>
          {t('company.poweredBy')} &nbsp;
          <div className='flexRow'>
            {t('company.brandName')} <span className="registered-mark">®</span>
          </div>

        </div>
      )
    }

    {
      (footerState !== 'footer_collapsed' || footerState === 'footer-login') && (
        <img src={footerline} alt="footer line" className="footer-logo" />
      )
    }

    <div style={{ color: `${footerState === 'footer-login' ? "#fff" : ""}` }}>
      v3.0.0
    </div>
  </div>

  )
}
