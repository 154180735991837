import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ManageEmployeePage, ManageRolesPage,UpdatePOSLogin  } from "./inner-components";

export const EmployeesPage = (props) => {
  return (
    <div className="flexRow full p-0 m-0">
      <div className='full-flex flexCol width100 p-0 m-0'>
        <Routes>
          <Route path="/" element={<Navigate replace to="employees" />} />
          <Route path="/employees" element={<ManageEmployeePage />} />
          <Route path="/roles" element={<ManageRolesPage />} />
          <Route path="/update-pos-login" element={<UpdatePOSLogin />} />
        </Routes>
      </div>
    </div>
  )
}
