import React, { useState } from 'react'
import '../assets/styles/modal.scss'
import closeIcon from '../images/icons/Icon2.png'

export const Action = (props) => {
  return (
      <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar">
        <div className="dialog__content">
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">{props.title}</h2>
          <hr />
          <div className="dialog__footer flexRow justifyContentSpaceEvenly">
            {props.fields.map((item)=>{
              return <h6>{item}</h6>
            })}
          </div>
        </div>
        <div className="dialog__footer flexRow justifyContentSpaceEvenly">
          <button className="done-button" onClick={props.onClose}>Done</button>
        </div>
      </div>
    </div>
  );
}
export const UpliftActionModal = (props) => {
  const [updated, setUpdated] = useState(null);

  const onChange = (e) => {
    const { name, value } = e.target;
    if(e.target.name==="itemType"){
      props.setItemTypeValue(e.target.value)
    };
    let newUpdates = updated ? { ...updated } : {}
    newUpdates[name] = value;
    setUpdated({ ...newUpdates });
    typeof (props.onChange) === 'function' && props.onChange(e,updated)
  }

  const onSubmit = (e) => {
    const mode=props.title;
    const updated = props.fields[0].value;
    removeContainerOverlay();
    typeof (props.onSubmit) === 'function' && props.onSubmit(e, updated,mode)
  }

  const onClose = (e) => {
    removeContainerOverlay();
    props.onClose();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <Action
      title={props.title}
      fields={props.fields}
      onClose={onClose}
      onChange={onChange}
      onSubmit={(e) => onSubmit(e)}>
    </Action>
  )
}