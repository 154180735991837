

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import { AgGridReact } from "ag-grid-react";
import "../assets/styles/inner/table.scss";
import "../assets/styles/styles.scss";
import {ReactComponent as DeleteIcon} from '../assets/icons/delete.svg';

// import { PageSizes } from "../utils/configs";
import { useTranslation } from 'react-i18next';
import CustomTooltip from './customTooltip.jsx';

/**
 * Icons
 */
import searchIcon from '../assets/icons/search.svg';
import LoadingGIF from '../assets/loading.gif';

const DataLoading = (props) => {
  return (
    <div>
      <span>{props.message}</span>
    </div>
  )
}

const addContainerOverlay = () => {
  /**
   * Add overlay to parent class
   */
  (document.querySelectorAll('.content-container')[0]).classList.add('modal-overlay-bg2');
};

const ActionCell = (props) => {
  const { colDef, data } = props;
  const onActionClick = (e, action) => {
    addContainerOverlay();
    if (typeof colDef.onAction === "function") {
      colDef.onAction(e, action, data);
    }
  }
  const IconDetails = (typeof colDef.getIconsDetail === "function") ? colDef.getIconsDetail(colDef, data) : (colDef.actions.detail || [])
  return (
    data ?
      <div className='flexCol width100 flex-ctr tblActionBtnsContainer'>
        {
          <div key={colDef.index} className='flexRow'>
            {
              IconDetails.map((_, jidx) => {
                return (
                  <div className='icon-aircraft-container'><div className={`pointer flexRow ${_.className || ''}`} onClick={(e) => onActionClick(e, _)} key={ jidx }>
                    {
                      Boolean(_.src) && <img className={'edit mx-1'} alt={props.title || 'action'} src={_.src} />
                    }
                    {_.title ? <span className="tooltip-action">{_.title}</span> : null}

                  </div>
                  </div>
                )
              })
            }
            {
              Boolean(colDef.actions.title) &&
              <span>{props.title}</span>
            }
          </div>
        }
      </div>
      : null
  )
}
const ButtonCell = (props) => {
  const { t } = useTranslation();
  const { node, colDef } = props;

  const handleClick = (e) => {
    colDef.onClick && colDef.onClick(e, colDef.field, node.data)
  }
  const isShowButton = (colDef, node) => {
    if (colDef && colDef.hasOwnProperty('refName')) {
      if ((colDef['refName'] === 'LOIAgreementDownload' && node.data['attachment'])) {
        return true;
      }
      else if ((colDef['refName'] === 'LOIReportDownload'
        && (node.data['status'] === 'finalized' || node.data['status'] === 'approved'))) {
        return true;
      }
    }
    else if (!colDef || !colDef.hasOwnProperty('refName')) {
      return true;
    }
    return false;
  }
  return (
    (node.data && (colDef.renderAlways || (colDef.renderIfValueExist && node.data[colDef.field]))) ?
      <div className='flexCol alignItemsCenter justifyContentCenter'>

         {
           (isShowButton(colDef, node)) ?
             <>
               <button className={`${colDef.className || ''}`} onClick={handleClick}>{t(colDef.buttonTextKey)}</button>
             </>
            :
             null
        }
      </div>
      : null
  )
}

export const DataGrid = forwardRef((props, ref) => {
  const { RightComponent, className, initialPageLimit = 10, searchKey = '', rowSelection = "multiple", setDelPop, remove, delConf, setRemove,getRowHeight, onRemoveEmployee, showDefaultFilters = true } = props; //, frameworkComponents=null
  const enableGridSearch = props.enableGridSearch !== undefined ? props.enableGridSearch : true;
  const { t } = useTranslation();
  const gridApi = useRef(null);
  const [state, setState] = useState({ searchText: '', disableDelete: true })
  const [pageLimit, setPageLimit] = useState(null);
  const [message, setMessage] = useState('')
  const [noResultsMessage, setNoResultsMessage] = useState('');
  const [pageNo] = useState(1);
  const [empDelId, setEmpDelId] = useState([]);
  const isClientSideScroll = props.rowModelType === 'clientSide'


  useEffect(() => {
    if (gridApi && gridApi.current) {
      gridApi.current.sizeColumnsToFit();
      gridApi.current.gridOptionsWrapper.setProperty('cacheBlockSize', pageLimit);
      gridApi.current.setDatasource(getDataSouce())
    }
  }, [pageLimit]);

  useEffect(() => {
    if ( gridApi.current ) {
      setMessage('Please wait, reloading data.');
      gridApi.current.gridOptionsWrapper.setProperty('cacheBlockSize', pageLimit);
      gridApi.current.setDatasource(getDataSouce())
    let selectedNodes = gridApi.current.getSelectedRows();
      setState((p) => {
        return { ...p, disableDelete:selectedNodes &&  selectedNodes.length === 0 }
      }) 
    }
  }, [props.resetDataSource]);


  useEffect(() => {
    if (gridApi && gridApi.current) {
      setMessage('Please wait, loading data.');
      gridApi.current.setDatasource(getDataSouce());
      gridApi.current.sizeColumnsToFit();
    }
  }, [props.selWarehouse, props.date,props.storeId,props.warehouseId,props.reconStateSel,props.noResultsMessage])

  useEffect(() => {
    setMessage(props.noResultsMessage);
    setNoResultsMessage(props.noResultsMessage) // Refreshing message variable
  }, [props.noResultsMessage]);

  useEffect(() => {
    if (props.initialPageLimit !== pageLimit && gridApi && gridApi.current) {
      setPageLimit(props.initialPageLimit);
    }
  }, [props.initialPageLimit]);

  useEffect(() => {
    if (remove || delConf) {
      removeEmployee();
      setRemove(false);
    }
  }, [remove, delConf]);

  useEffect(() => {
    if (props.empSpec) {
      props.setButtonDisable(state.disableDelete);
    }
  }, [state.disableDelete]);


  useImperativeHandle(
    ref, () => ({
      refreshTableHandler(selItemType) {
        if (gridApi && gridApi.current) {
          gridApi.current.setDatasource(getDataSouce());
        }
      }
    })
  );
  useImperativeHandle(
    ref, () => ({
      refreshTableHandler(selDepartment) {
        if (gridApi && gridApi.current) {
          gridApi.current.setDatasource(getDataSouce());
        }
      }

    })
  );
  useImperativeHandle(
    ref, () => ({
      refreshTableHandler(selDesignation) {
        if (gridApi && gridApi.current) {
          gridApi.current.setDatasource(getDataSouce());
        }
      }

    })
  );

  useEffect(() => {
    setNewDataSourceForSeach(searchKey)
  }, [searchKey])

  useEffect(() => {
    if (isClientSideScroll && gridApi.current) {
      if (state.searchText) {
        gridApi.current.setQuickFilter(state.searchText);
      } else {
        gridApi.current.setQuickFilter(null);
      }
    } else if (state.searchText || state.searchText==="") {
      setNewDataSourceForSeach(state.searchText)
    }
  }, [state.searchText])

  const setNewDataSourceForSeach = (iSearchText) => {
    if (gridApi && gridApi.current) {
      gridApi.current.setQuickFilter(searchKey);
      gridApi.current.setDatasource(getDataSouce());
    }
  }

  const removeEmployee = () => {
    if (gridApi && gridApi.current) {
      let selectedNodes = gridApi.current.getSelectedRows();
      let employeeId = selectedNodes.map(node => node.employeeId);
      setEmpDelId(employeeId);
      if (selectedNodes.constructor === Array && selectedNodes.length > 0) {
        setDelPop(true);
      }
      if (delConf && selectedNodes.length) {
        onRemoveEmployee(empDelId);
      }
    }
  }
const handleRowClicked=(e)=>{
  return false;
}
  const getDataSouce = (val) => {
    return {
      getRows: async (params) => {
        let page = 0;
        if (val === 'inc') {
          page = Number(pageNo + 1)
        } else if (val === 'dec') {
          page = Number(pageNo - 1)
        } else {
          page = params.endRow / (pageLimit || 10);
        }
        let res;
        try {
          if (page !== 0) {
            let isFirstRequest = params.startRow === 0;
            if (isFirstRequest) {
              setMessage('Please wait, data is fetching...');
              gridApi.current.showNoRowsOverlay();
            }
            try {
              res = await props.getRows(page, pageLimit, state.searchText ? state.searchText:searchKey);
            } catch (error) {

              let errMsg = 'Failed to fetch data!';
              if ( typeof error.message === 'string' ) {
                const errObj = JSON.parse(error.message);
                errMsg = errObj.data.message;
              }

              if (error.data) {
                if (error.data.message) {
                  errMsg = error.data.message;
                }
                else if (error.data.msg) {
                  errMsg = error.data.msg;
                }

              }
              else if ( error.message ) {
                const errObj = JSON.parse(error.message);

                if ( errObj?.data?.msg ) {
                  errMsg = errObj?.data?.msg;
                }
                else if ( errObj?.data?.message ) {
                  errMsg = errObj?.data?.message;
                }
              }

              console.error('Log: Error occured while fetching rows:', errMsg );

              // Axios Cancelled error: CanceledError {message: 'canceled', name: 'CanceledError', code: 'ERR_CANCELED'}
              const cancelledError = error.data?.error?.code ?? null;
              if (cancelledError && cancelledError === 'ERR_CANCELED') {

                res = await props.getRows(page, pageLimit, state.searchText);

              }
              else {
                setMessage(errMsg);
                gridApi.current.showNoRowsOverlay();
                params.failCallback();
                return
              }

            }

            if (isFirstRequest) {
              gridApi.current.hideOverlay();
            }

            if ((res.count === 0 || res.items.length === 0 )&&props.noResultsMessage === undefined) {
              setMessage('No Data');
              gridApi.current.showNoRowsOverlay();
              params.failCallback();
            }
            else if((res.count === 0 || res.items.length === 0 )&& (props.noResultsMessage||!message)){
              setMessage(noResultsMessage);
              gridApi.current.showNoRowsOverlay();
              params.failCallback();
            } else{
              params.successCallback(res.items, res.count);
              setMessage("");

            }
          }
        } catch (error) {
          setMessage('Error occured while fetching rows!')
          params.failCallback()
        }
      }
    }
  }

  const rowClass = "tableRowClass";
  const getRowClass = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return 'tableEvenRowClass';
    }
  };
  const onGridReady = useCallback((params) => {
    gridApi.current = params.api;
    if (props.columnResize) {
      gridApi.current.sizeColumnsToFit()
    }
    setPageLimit(initialPageLimit)
  })
  const getLoading = (params) => {
    const { node } = params
    if (node && node.data === undefined) {
      const loadingElement = <img src={LoadingGIF} className='cell-loading' alt='cell loading' />;

      setTimeout(() => {
        const loadingImg = document.querySelectorAll('.cell-loading');
        if (loadingImg) {
          loadingImg.forEach(loadingImg => {
            loadingImg.style.display = 'none';
          });
        }
      },500);
     return loadingElement;
    } else if (params.value) {
      // otherwise just display node ID (or whatever you wish for this column)
      return params.value;
    } else {
      return null
    }
  }
  const getColumns = () => {
    let actionCols = [], finalColumns = [...props.columns];
    finalColumns = finalColumns.map((_) => {
      let _n = { ..._, headerName: t(_.headerName) };
      if (_n.cellType === 'button') {
        _.cellRenderer = ButtonCell;
        _.onClick = _.onClick ? _.onClick : props.onButtonCellClick;
      }
      return _n;
    });

    if (props.actions) {
      props.actions.forEach((ele, index) => {
        let actionCol = {
          field: ele.title,
          attribute: ele.attribute,
          getIconsDetail: props.getActionCellIcons,
          cellRenderer: ActionCell,
          actions: props.actions[index],
          onAction: props.onAction,
          width: ele.width || 120,
          minWidth: ele.minWidth || 210,
          maxWidth: ele.maxWidth|| 250
        };

        if ( props.hasOwnProperty('actionProperties') ) {

          if (props.actionProperties.hasOwnProperty('pinned') ) {
            actionCol.pinned = props.actionProperties.pinned || 'right';
          }
          if (props.actionProperties.hasOwnProperty('width') ) {
            actionCol.width = props.actionProperties.width;
          }
          if (props.actionProperties.hasOwnProperty('minWidth') ) {
            actionCol.minWidth = props.actionProperties.minWidth;
          }
          if (props.actionProperties.hasOwnProperty('autoHeight') ) {
            actionCol.autoHeight = props.actionProperties.autoHeight;
          }
          if (props.actionProperties.hasOwnProperty('tooltipField') ) {
            actionCol.tooltipField = props.actionProperties.tooltipField;
          }
          if (props.actionProperties.hasOwnProperty('cellRenderer') ) {
            actionCol.cellRenderer = props.actionProperties.cellRenderer;
          }
        }

        actionCols.push(actionCol)
      });

      finalColumns = [...props.columns, ...actionCols];

    }
    if (!isClientSideScroll) {
      finalColumns[0].cellRenderer = getLoading;
    }

    return finalColumns;
  }

  // const onPerLimitChange = (e) => {
  //   if (showDefaultFilters) {
  //     setPageLimit(Number(e.target.value));
  //   } else {
  //     props.onPerLimitChange(e);
  //   }
  // };

  const onSearch = (e) => {
    setState((p) => {
      return { ...p, searchText: e.target.value }
    })
  };
  const onSelectionChanged = (e) => {
    let selectedNodes = gridApi.current.getSelectedRows();
    setState((p) => {
      return { ...p, disableDelete: selectedNodes.length === 0 }
    })
  }
  return (
    <>
      {/* { message ?
        <div className='flexRow justifyContentCenter gridStatusBlock'>
          <p>{message}</p>
        </div>
      : null } */}

      <div className={`flexCol ${className || ''}`}>

        {
          showDefaultFilters && (
            <div className='flexRow width100 ng-grid-right margBtm16 margTop8 justifyContentSpaceBetween alignItemsCenter'>
              {
                enableGridSearch &&
                <div className={`underline ${props.pageType === undefined ? "" : "margLeft40"}`}>
                  <span><img className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
                  <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={state.searchText} /></span>
                </div>
              }
              {
                (Boolean(props.addButton) || Boolean(props.deleteButton) || Boolean(RightComponent)) &&
                <div className="flexRow justifyContentFlexEnd alignItemsCenter">
                  {
                    Boolean(props.addButton) &&
                    <button className={`add-item-button ${props.addButton.className || ''}`} onClick={props.addButton.onClick}>
                      {props.addButton.title || 'Add Item'}
                    </button>
                  }
                  {
                    (Boolean(props.deleteButton)) &&
                    <> 
                    <button
                      disabled={state.disableDelete}
                      className={`remove-button ${props.deleteButton.className || ''}`}
                      onClick={(e) => {
                        if (props.deleteButton.onClick) {
                          props.deleteButton.onClick(e, gridApi.current.getSelectedRows())
                        }
                      }}>
                  <DeleteIcon className="icon-size removeEmpIcon" alt='deleteIcon' /> {props.deleteButton.title || 'Delete Item'}
                    </button></>
                   
                  }
                  {
                    Boolean(RightComponent) &&
                    <RightComponent />
                  }
                </div>
              }
            </div>
          )
        }
        <div className="table-header"></div>
        <div id='myGrid'className="ag-theme-alpine-dark" style={{height:"100%",whiteSpace:'nowrap !important'}}>     <AgGridReact
          id='ngo-grid'
          suppressCellFocus
          className='ngo-grid'
          columnDefs={getColumns()}
          onGridReady={onGridReady}
          rowBuffer={0}
          onSelectionChanged={onSelectionChanged}
          getRowHeight={getRowHeight}
          enableCellTextSelection
          rowSelection={rowSelection} // Options - allows click selection of rows
          rowModelType={props.rowModelType || 'infinite'}
          cacheBlockSize={pageLimit}
          cacheOverflowSize={2}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={1}
          maxBlocksInCache={10}
          onColumnResized={props.onColumnResizedHandler ? (event) => props.onColumnResizedHandler(event) : null}
          suppressRowClickSelection={true} // Prevents row selection on row click
          onRowClicked={handleRowClicked}
          suppressPaginationPanel={true}
          rowClass={rowClass}
          getRowClass={getRowClass}
          loadingOverlayComponent={DataLoading}
          loadingOverlayComponentParams={{ message: message }}
          overlayNoRowsTemplate={props && Object.keys(props).includes('rowData') && !props.rowData?.length ? `<span class="margTop40">No Items Found</span>` : `<span>${message}</span>`}
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          columnResize={props.columnResize}
          rowData={props.rowModelType === 'clientSide' ? props.rowData : null}
          isFullWidthRow={props.isFullWidthRow}
          fullWidthCellRenderer={props.fullWidthCellRenderer}
          components={{
            loadingRenderer: function (params) {
              if (params.value !== undefined) {
                return params.value;
              } else {
                return '<img src="../images/loading.gif">';
              }
            },
            customTooltip: function (params) {
              return CustomTooltip;
            }
          }}
          frameworkComponents={{
            customCellRenderer: props.customCellRenderer||null,
          }}
          enableBrowserTooltips={false}
          tooltipShowDelay={0}
          suppressRowTransform={true}
        /></div>

      </div>

    </>
  )
});

