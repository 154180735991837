

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { APIEndpoint, DFPAPIEndpoint } from '../../../utils/axios';
import { DataGrid,NGODatePicker,Modal,EditModal} from '../../../common';
import {translationLength,errorFormatter } from '../../../utils/commonFunctions';
import OrderListing from './renderers/orderListing';
import { ORDER_SALE_TYPE_LIST_DYNAMIC } from '../../../utils/configs';
import closeIcon from '../../../images/icons/Icon2.png';
import { IoArrowForwardOutline } from "react-icons/io5";
import {
  EventLogContainer,
    InstanceName,
    InstanceStates,
  } from "./renderers";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import searchIcon from '../../../assets/icons/search.svg';
import deposit from "../../../assets/deposit.svg";
import LoadingGIF from '../../../assets/loadingIn.gif';
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import SectorInstanceCard from './sectorInstanceCard';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import usePermissions from '../../../utils/userPermissions';
import {customSelSearch} from "../../../utils/configs";
import SalesSummary from './renderers/salesSummary';


const EditFormFields = [
    {label: 'Aircraft', attribute: 'aircraft', type: 'multiSelect', disabled: false, options: [], required: true },
    {label: 'Start Date', attribute: 'startDate', type: 'date', disabled: false, required: true },
  ];

export const DynamicPage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({emptInst:false,showOrders:false,resetDataSource:false,tailNum:[{key: 0, label: "Select Tail Number", value: ''}]});
  const tableRef = useRef();
  const navigate = useNavigate();
  const [perPageLimit, ] = useState(10);
  const [showDownload,setShowDownload]=useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [reportsType, setReportTypes] = useState([]);
  const [status, setStatus] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [searchKeyOrders, setSearchKeyOrders] = useState("");
  const [saleType, setSaleType] = useState("");
  const [stores, setStores] = useState([]);
  const [selItemType, setItemType] = useState('');
  const [reptType,setReptType]=useState("");
  const [storeType,setStoreType]=useState("");
  const [saleTypeCheck,setSaleTypeCheck]=useState(false);
  const [orderId,setOrderId]=useState("");
  const [reload,setReload]=useState(false);
  const [ tailNumbers, setTailNumbers ] = useState([])
  const [ aircrafts, setAircrafts ] = useState([])
const [reportDate,setReportDate]=useState({startDate:"",endDate:""});
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [isDownloading, setIsDownloading] = useState(false);

  const { hasPermission: createInstances } = usePermissions('create', 'sales.instances');
  const { hasPermission: editInstances } = usePermissions('edit', 'sales.instances');
  const { hasPermission: viewInstances } = usePermissions('viewDetails', 'sales.instances');
  const { hasPermission: downloadInstances } = usePermissions('download', 'sales.instances');

  const sType = localStorage.getItem('sType');
  const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

  useEffect( () => {
    fetchAircrafts();
      }, []);

      useEffect(() => {
        if (state.emptInst || state.instanceChange || state.tailNum || state.startDate || state.InstanceState || state.sectors ||
            state.emptInst === "" || state.instanceChange === "" || state.tailNum === "" || state.startDate === "" || state.InstanceState === "" || state.sectors === "") {
            fetchOperationsPanel();
        }
    }, [state]);


          useEffect( () => {
            if(state&&state.showOrders){
              fetchOrderDetails();
            }
               }, [saleTypeCheck,saleType,status,paymentType,searchKeyOrders]);

          useEffect( () => {
          setIsDownloading(false)
            }, [reptType]);

    const InstanceState=[{id:1,label:'Active',value:'open'},{id:2,label:'Locked',value:'locked'},{id:3,label:'Commit Scheduled',value:'commitScheduled'},{id:4,label:'Committed',value:'committed'}]
const fetchOrderDetails=async()=>{
  const filterData={
    page: 1,
    limit: 20,
    source: "isolate"
}

  let filters = [ {
    "sectorId": orderId,
    "sortBySaletype": saleTypeCheck
}];

  const filterConditions = [
    { condition: status, filter: {status } },
    { condition: saleType, filter: { saleType } },
    { condition: paymentType, filter: { paymentType } },
    { condition: searchKeyOrders, filter: { searchKey:searchKeyOrders } },
  ];


  filterConditions.forEach(({ condition, filter }) => {
    if (condition) {
      const existingFilterIndex = filters.findIndex(existingFilter => {
        return Object.keys(existingFilter).every(key => filter.hasOwnProperty(key));
      });

      if (existingFilterIndex !== -1) {
        const existingFilter = filters[existingFilterIndex];
        filters[existingFilterIndex] = { ...existingFilter, ...filter };
      } else {
        filters.push(filter);
      }
    }
  });
  filterData.filters = filters;

  try {
    const res = await APIEndpoint.post(`sandboxing/isolatedOrders/listing`,filterData);
   if(res.count){
    setState((prev)=>({...prev,showOrders:true,selected2:{action:'orders',data:res?.items}}))
   }else{
    setState((prev)=>({...prev,showOrders:true,selected2:{action:'orders',data:res?.items}}))

   }
 }
 catch (error) {


 }

}


const sectorTitle=()=>{
  const {action}=state.selected2?state.selected2:state.selected;
  if(action==='sectorAttachments'||action==='viewEventLogs'){
    const { selected = state.selected2 } = state;
return(<div className='flexRow justifyContentCenter'>
  <div className='flexRow'>
    {action==='sectorAttachments'?<div className='flexRow'>Sector attached to instance<div className='instance-member-name'>{selected.name}</div>
    <button className='small-body-text-regular fontExtrabold key-value btn-sync'>
      <img style={{height:'40px',width:"40px",backgroundColor:"#4c5c81",borderRadius:"4px"}} src={deposit} onClick={()=>setReload(!reload)} alt='refresh-sectors' />
      </button>
    </div>:<div className='flexRow'>Event Log-<div className='instance-member-name'>{(state?.selected&&state?.selected.data)?state?.selected?.data[0]?.target.name:state?.selected2.data[0]?.target.name}</div></div>}

  </div>
</div>)
  } else if(action==='uplifts'){
    const {selected2}=state;
    return(<div className='flexRow justifyContentCenter'>
      <div className='flexRow'>
        <div className='flexRow'>Uplift from <div className='instance-member-name'>{selected2.data?.warehouse?.warehouseCode}</div></div>

      </div>
    </div>)
  }
  else if(action==='orders'){
    const {name}=state.data?.transferOutReport?.sector?state.data?.transferOutReport?.sector:state?.flightDetails;
    return(<div className='fexCol justifyContentCenter'>
      <div className='flexRow justifyContentCenter'>
        <div className='flexRow'>Orders - <div className='instance-member-name'>{name||'None'}</div></div>
          <button className='small-body-text-regular fontExtrabold key-value btn-sync'>
          <img style={{height:'40px',width:"40px",backgroundColor:"#4c5c81",borderRadius:"4px"}} src={deposit} onClick={()=>setReload(!reload)} alt='refresh-sectors' />
          </button>
      </div>
      <div className='flexRow justifyContentFlexStart'><div className={`flexRow dialog__description__checkbox`}>
              <input
                  id={''}
                  type={'checkbox'}
                  onClick={()=>setSaleTypeCheck(!saleTypeCheck)}
                  checked={saleTypeCheck}
                  value={saleTypeCheck}
                />
                <label htmlFor={""} style={{fontSize:"1rem",fontWeight:'bold'}}className={`margTop8`} >Sort By SaleType</label>
            </div>
            </div>
    </div>)
  }
  else if(action==='stockMovement'){
    const {name}=state.data?.transferOutReport?.sector?state.data?.transferOutReport?.sector:state.selected2.flightDetails;

    return(<div className='flexRow justifyContentCenter'>
      <div className='flexRow'>
        <div className='flexRow'>Stock movement -<div className='instance-member-name'>{name}</div></div>
      </div>
    </div>)
  }
  else if(action==='transferOut'){
    const {name}=state.data?.transferOutReport?.sector;

    return(<div className='flexRow justifyContentCenter'>
      <div className='flexRow'>
        <div className='flexRow'>Transfers -<div className='instance-member-name'>{name}</div></div>
      </div>
    </div>)
  }
  else if(action==='transferIn'){
    const {name}=state.data?.transferInReport?.sector;


    return(<div className='flexRow justifyContentCenter'>
      <div className='flexRow'>
        <div className='flexRow'>Transfers-<div className='instance-member-name'>{name}</div></div>
      </div>
    </div>)
  }
  else if(action==='salesSummary'){
    const {name}=state.data?.transferOutReport?.sector;

    return(<div className='flexRow justifyContentCenter'>
      <div className='flexRow'>
        <div className='flexRow'>Sales summary -<div className='instance-member-name'>{name}</div></div>
      </div>
    </div>)
  }
  else{
    return(<></>)
  }

}
  const fetchAircrafts = useCallback(
    async () => {
      try {
             const resp = await DOMAIN.get('sandboxing/aircrafts');
      if (resp.result.length ) {
            const options1 = [];
            resp.result.filter( (fieldValue, index) => {
              options1.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.id}`});
              return fieldValue;
            });
       setTailNumbers(options1)

       const options2 = [{key: 0, label: "Select Aircraft", value: ''}];
       resp.result.filter( (fieldValue, index) => {
         options2.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.id}`});
         return fieldValue;
       });
      setAircrafts(options2)
      }
      else {
      }
      } catch (error) {

      }

    },
    [],
  )

  const getRowHeight = (params) => {
    const numSectors = params?params.length : 0;
    return 85 + numSectors * 20;
  };

  const onClickSectorInsAttach=async(data)=>{
    setState( (_prev) => {
      return { ..._prev, selected: {...data,  action: 'sectorAttachments' },resetDataSource:false}
    });

  }

  const showEventLogs=async(event,obj)=>{
    const {id}=event;

    const filterData={
      "page": 1,
      "limit": 20,
      "filters": []
  }


    try {
      const res = await APIEndpoint.post(`sandboxing/isolatedSectors/${id}/events`,filterData);
      if (res.items) {
        setState((prev)=>({...prev,selected2:{action:'viewEventLogs',data:res.items}}))
      }
    } catch (error) {
    }
  }



  const SectorsCellRenderer = ({ value }) => {
    if(value&&value.length){
       const uniqueSectors = [...new Set(value.map(sector => sector.key))];
    return (
      <div className='sector-container' style={{width:'100%',maxWidth:'275px'}}>
        {uniqueSectors.length > 0 ? uniqueSectors.map((key, index) => (
          <span key={index} title={key} className="sector-label">{key.split("/")[0]||null}</span>
        )) : "None"}
      </div>
    );
    }else if(Array.isArray(value)&&!value.length){
      return (
        <div className='sector-container'>
None
        </div>)
    }
    else{
      return (
        <div className='sector-container'>

        </div>)
    }
  };

const dynamicMenuRender=(props)=>{
  return(
    editInstances && viewInstances && <div className='sector-container'>
      {props && props.data &&
    <span onClick={()=>onClickSectorInsAttach(props.data)} className="sector-button-operations">...</span>
  }
  </div>
    )
}

const paymentTypeDist=[
  { field: 'paymentType',headerName: 'Payment Type',valueGetter: (params) => {
    if (params.data&&params.data.paymentType) {
       return params.data.paymentType==='none'?"SSR":params.data.paymentType.charAt(0).toUpperCase()+params.data.paymentType.slice(1)
    }
    return null;
  }, sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'totalNumberOfOrders',headerName: 'Number of Orders', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'amount',headerName: 'Amount', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
]

const saleTypeDist=[
  { field: 'saleType',headerName: 'Sale Type', sortable: true,flex:1, filter: true,suppressSizeToFit:true,valueGetter: (params) => {
    if (params.data&&params.data.saleType) {
       return ORDER_SALE_TYPE_LIST_DYNAMIC.find(ord=>ord.key===params.data.saleType)?.value
    }
    return null;
  }, resizable:true, minWidth: 155 },
  { field: 'totalNumberOfOrders',headerName: 'Number Of Orders', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'amount',headerName: 'Amount', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
]
const currencyDist=[
  { field: 'code',headerName: 'Currency', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'totalNumberOfOrders',headerName: 'Number of Orders', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'amount',headerName: 'Amount', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
]

const TransferIn=[
  { field: 'itemCode',headerName: 'Item Code', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'itemName',headerName: 'Item Name',tooltipField: 'itemName',valueGetter: (params) => {
    if (params.data&&params.data.itemName) {
       return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
    }
    return null;
  }, sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'quantity',headerName: 'Quantity', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'unit',headerName: 'Unit', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
]

const Uplifts=[
  { field: 'itemCode',headerName: 'Item Code', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'itemName',headerName: 'Item Name', tooltipField: 'itemName',valueGetter: (params) => {
    if (params.data&&params.data.itemName) {
       return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
    }
    return null;
  },sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'bob',headerName: 'BOB', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'corporate',headerName: 'Corporate', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'webMeal',headerName: 'Web Meal', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'total',headerName: 'Total', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'unit',headerName: 'Unit', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 }
]



const StockMvmnt=[
  { field: 'itemCode',headerName: 'Item Code', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'itemName',headerName: 'Item Name', sortable: true,flex:1,tooltipField: 'itemName',valueGetter: (params) => {
    if (params.data&&params.data.itemName) {
       return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
    }
    return null;
  }, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 165 },
  { field: 'openingStock',headerName: 'Opening Stock', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'uplift',headerName: 'Uplift', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'transferIn',headerName: 'Transfer In',valueGetter: (params) => {
    if (params.data&&params.data.transferIn) {
       return params.data.transferIn
    }else{
      return 0;
    }
  }, sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'variance',headerName: 'Variance',valueGetter: (params) => {
    if (params.data&&params.data.variance) {
       return params.data.variance
    }else{
      return 0;
    }
  }, sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'wastage',headerName: 'Wastage', valueGetter: (params) => {
    if (params.data&&params.data.wastage) {
       return params.data.wastage
    }else{
      return 0;
    }
  },sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'wastageFromPos',headerName: 'Wastage (from POS)',valueGetter: (params) => {
    if (params.data&&params.data.wastageFromPos) {
       return params.data.wastageFromPos
    }else{
      return 0;
    }
  }, sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'offload',headerName: 'Offload',valueGetter: (params) => {
    if (params.data&&params.data.offload) {
       return params.data.offload
    }else{
      return 0;
    }
  }, sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'order',headerName: 'Order', valueGetter: (params) => {
    if (params.data&&params.data.order) {
       return params.data.order
    }else{
      return 0;
    }
  },sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'transferOut',headerName: 'Transfer Out',valueGetter: (params) => {
    if (params.data&&params.data.transferOut) {
       return params.data.transferOut
    }else{
      return 0;
    }
  }, sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'closingStock',headerName: 'Closing Stock', valueGetter: (params) => {
    if (params.data&&params.data.closingStock) {
       return params.data.closingStock
    }else{
      return 0;
    }
  },sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'unit',headerName: 'Unit', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },

]

  const Columns = [
    { field: 'name',headerName: 'Instance Name', sortable: true,flex:1,suppressSizeToFit:true, resizable:true, minWidth: 155,pinned:'left', cellRendererSelector: (params) => {
        return {
          component: InstanceName,
        };
      } },
    { field: 'aircraft',headerName: 'Tail Number', sortable: true, flex:1,suppressSizeToFit:true, resizable:true, minWidth: 130,
      tooltipField:"aircraft.aircraftNumber",  valueGetter: (params) => {
        if (params.data&&params.data.aircraft) {
           return params.data.aircraft.aircraftNumber?params.data.aircraft.aircraftNumber:"";
        }
        return null;
      },
    },
    { field: 'startDate', headerName: 'Start Date',valueGetter: params => {
      return params.data ? moment(params.data?.startDate).format('DD-MM-YYYY') : '';
  },sortable: true,flex:1,suppressSizeToFit:true, resizable:true, minWidth: 175 },
    { field: 'createdAt',headerName: 'Created At',valueGetter: params => {
      return params.data ? moment(params.data?.createdAt).format('DD-MM-YYYY hh:mm A') : '';
  },sortable: true, flex:1,suppressSizeToFit:true, resizable:true, minWidth: 175 },
    { field: 'state',headerName: 'Instance State', cellRendererSelector: (params) => {
        return {
          component: InstanceStates,
        };
      }, sortable: true,flex:1,suppressSizeToFit:true, resizable:true, minWidth: 155 },
    { field: 'sectors',headerName: 'Sectors',cellRendererFramework: SectorsCellRenderer,flex:1,minWidth: 275,sortable: true, resizable:true },
    {
      headerName:'',
      flex:1, minWidth: 85,width:90,sortable: true, resizable:true,
        cellRendererFramework: dynamicMenuRender,
        valueGetter: function(params) {
          return params;
        }
      },
    {
      headerName:'Actions',
      pinned:'right',
       autoResize:true,
        width:180,
        resizable: false,
        suppressSizeToFit:true, sortable: true,
        cellRenderer: actionsDynamic,
        valueGetter: function(params) {
          return params;
        }
      }
  ];

  const handleReset = () => {
    setState((_) => {
      return { ..._, resetDataSource:!state.resetDataSource};
    })
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
    setModalFormStatusObj({ text:""});
      handleModalCancel();
    }, 1000)
  }
  const filterData = {action: 4, filters: [], limit: 50, page: 1, sort: [{field: "startDate", order: "desc"}]}
  const setSector = (data,type) => {
    const {id,name}=data;
    if(type==='details'){
      const encodedName = encodeURIComponent(name);
      navigate(`/sales/dynamic/sector/containerIsolate/${encodedName}/${id}`);
    }


  };


  const newCustomStyles = {
    input: (defaultStyles) => ({
      ...defaultStyles,
      color: '#fff',
      }),
      control: (defaultStyles) => ({
        ...defaultStyles,
        backgroundColor: "#162E3E",
        padding: "2px",
        width:'17rem',
        marginBottom: '1rem',
        border: "0.4px solid #FFFFFF",
        boxShadow: "none",
        PointerEvent: 'none',
      }),
      option: (defaultStyles) => ({
        ...defaultStyles,
        textAlign: 'left',
      }),
  };

const getVarianceReport=async(inst)=>{
  const {id}=inst;
  const button = document.getElementById(id);

 const data= {
    reportType: "bifurcatedReconReport",
    id: id
}
const header={
  ...APIEndpoint.getHeaders().headers,
  'Accept':'text/csv',
};

  try {
    if (button) {
      button.className = "grid-download-btnStyle1 processCls";
      button.innerHTML = "Processing...";
      button.disabled=true
    }
    const res = await DOMAIN.post('sales/reports', data,header);
    if(res.status==='scheduled'){
      setTimeout(() => {
        const dowId= DOMAIN.get(`downloads/status/${res.id}`);
        dowId.then((val) => {
          if (val.status === "completed") {
              DOMAIN.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
                  .then((ItemMasterDwnld) => {
                      const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', val.fileName + val.fileExtension);
                      document.body.appendChild(link);
                      link.click();
                      if (button) {
                        button.className = "grid-download-btnStyle1";
                        button.innerHTML = "Variance";
                        button.disabled=false
                      }
                  })
                  .catch((error) => {

                      console.error('Error downloading CSV:', error);
                      if (button) {
                        button.className = "grid-download-btnStyle1 failedCls";
                        button.innerHTML = "Failed";
                        button.disabled=false
                      }
                  });
          }
      });
        }, 8000);
    }

    }
       catch (error) {
        if (button) {
          button.className = "grid-download-btnStyle1 failedCls";
          button.innerHTML = "Failed";
          button.disabled=true
          setTimeout(() => {
            button.className = "grid-download-btnStyle1";
            button.innerHTML = "Variance";
            button.disabled=false
          }, 3000);
        }

       }

}


  function actionsDynamic(props){
    return (
      props.data?
          <div className='flexRow margTop5'>
       {props.data&&props.data?.state!=='committed'? <><div className='icon-aircraft-container'>
        <button className="grid-download-btnStyle1" onClick={(e) => setSector(props.data, 'details')}>Details</button>
        </div>
    </>:<>
        <div className='icon-aircraft-container'>
        <button className="grid-download-btnStyle1" onClick={(e) => setSector(props.data, 'details')}>Details</button>
        </div>
        <div className='icon-aircraft-container'>
        <button id={props.data.id} className="grid-download-btnStyle1" onClick={() => getVarianceReport(props.data)}>Variance</button>
        </div>
        </>}
      </div>
         : <></>
    );
  }


  const fetchOperationsPanel = async (page=1, perPage=10) => {
    const startDateF = moment(state.startDate).format('YYYY-MM-DDTHH:mm:ssZ');
    filterData.page = page;
    filterData.limit = perPage;
    filterData.searchParam = searchKey;

    if (state) {
      let filters = [];


      const filterConditions = [
        { condition: state.emptInst, filter: { excludeEmptyIsolates: true } },
        { condition: state.instanceChange, filter: { name: state.instanceChange } },
        { condition: state.tailNum, filter: { aircraftId: state.tailNum[0]?.value||null } },
        { condition: state.startDate, filter: { startDate: startDateF } },
        { condition: state.InstanceState, filter: { state: state.InstanceState } },
        { condition: state.sectors, filter: { sectors: state.sectors } }
      ];


      filterConditions.forEach(({ condition, filter }) => {
        if (condition) {
          const existingFilterIndex = filters.findIndex(existingFilter => {
            return Object.keys(existingFilter).every(key => filter.hasOwnProperty(key));
          });

          if (existingFilterIndex !== -1) {
            const existingFilter = filters[existingFilterIndex];
            filters[existingFilterIndex] = { ...existingFilter, ...filter };
          } else {
            filters.push(filter);
          }
        }
      });
      filterData.filters = filters;
    }
    try {
      const res = await DOMAIN.post('sandboxing/containerIsolates/listing', JSON.stringify(filterData));
        if(res.items.length >= 10){
          return res;
        }
        else if(res.items.length <= 10){
          return {items:res.items,count:res.items.length}
        }
        else{
          return {items:[],count:0}
        }
    } catch (error) {

    }

  }
  const inventoryDetails=async(data,type,editDocDetails=null,obj)=>{

    setOrderId(data.id)
if(type==='uplifts'){
const {id}=data?.uplifts[0];
try {
  const res = await APIEndpoint.get(`sandboxing/uplifts/${id}`);

 if(res){
  setState((prev)=>({...prev,data,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res?.uplift}}))
 }
}
catch (error) {


}
}else if(type==='transferIn'){
  const {id}=data?.transferInReport;
  try {
    const res = await APIEndpoint.get(`sandboxing/inventoryReports/${id}`);
   if(res){
    const {sector}=res.inventoryReport;
    setState((prev)=>({...prev,data,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res?.inventoryReport}}))
   }
 }
 catch (error) {


 }

}else if(type==='salesSummary'){
   const {id}=data;
  try {
    const res = await APIEndpoint.get(`sandboxing/isolatedSectors/${id}/salesSummary`);
   if(res){
    setState((prev)=>({...prev,data,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res?.salesSummary}}))
   }
 }
 catch (error) {


 }

}
else if(type==='transferOut'){
  const {id}=data?.transferOutReport;
  try {
    const res = await APIEndpoint.get(`sandboxing/inventoryReports/${id}`);
   if(res){
    setState((prev)=>({...prev,data,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res?.inventoryReport}}))
   }
 }
 catch (error) {


 }

}
else if(type==='orders'){

  const filterData={
    page: 1,
    limit: 20,
    source: "isolate"
}

  let filters = [ {
    "sectorId": data.id,
    "sortBySaletype": saleTypeCheck
}];

  filterData.filters = filters;

  try {
    const res = await APIEndpoint.post(`sandboxing/isolatedOrders/listing`,filterData);
   if(res.count){
    setState((prev)=>({...prev,data,showOrders:true,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res?.items}}))
   }
   else{
    setState((prev)=>({...prev,data,showOrders:true,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res?.items}}))

   }
 }
 catch (error) {


 }

}
else {
  const {id}=data;
  try {
    const res = await APIEndpoint.get(`sandboxing/isolatedSectors/${id}/inventorySnapshot`);
   if(res){

    setState((prev)=>({...prev,data,flightDetails:obj,selected2:{flightDetails:obj,action:'stockMovement',data:res.inventorySnapshot}}))
   }
 }
 catch (error) {


 }

}

  }
const onDownloadActivityReport=async()=>{
  let header={
    'Accept':'text/csv'
  }
    try {
    const res= await DOMAIN.post(`devices/activityReport`,{},header);
    if(res.status==='scheduled'){
      setTimeout(() => {
        setIsDownloading(true)
        const sapRep=APIEndpoint.get('downloads/status/'+ res.id)
        sapRep.then((val) => {
          if (val.status === "completed") {
              DOMAIN.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
                  .then((ItemMasterDwnld) => {
                      const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', `${val.fileName}${val.fileExtension}`);
                      document.body.appendChild(link);
                      link.click();
                      setIsDownloading(false)
                  })
                  .catch((error) => {

                      console.error('Error downloading CSV:', error);
                      setIsDownloading(false);
                  });
                  setState((_prev) => {
                    return { ..._prev, selected: false };
                });
                setModalFormStatusObj({ text: '' });
          }
      });
        }, 8000);
    }
    }
     catch (error) {
      setState((_) => {
        return { ..._,message:{text:error?.data?.message},};
      })
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          message: null,
        }));
      }, 5000);
       setIsDownloading(false)
     }
  }

  const handleCreateInstance=async(e,updated)=>{
if(updated){
   try {
    const res = await APIEndpoint.post(`sandboxing/containerIsolates`,{aircraftId:tailNumbers.find(aId=>aId.label===updated.aircraft)?.value,startDate:updated.startDate});
   if(res.message){
    setModalFormStatusObj({ text: res.message });
    handleReset();
   }
 }
 catch (error) {
  let {data}=JSON.parse(error.message)

  if(data?.errors && data?.errors[0]?.message.length){
   let errorMessageFormatted= errorFormatter(data?.errors[0]?.message)
  setModalFormStatusObj({ error: errorMessageFormatted });

  }else if(data?.message){
    setModalFormStatusObj({error:data.message})
  }else{
  setModalFormStatusObj({ error: "Error creating Instance" });
  }
 }
}

  }

  const handleModalCancel=()=>{
   setState((prev)=>({...prev,selected:false}))
   setModalFormStatusObj({ text:""});
  }

  const handleModalCancelEvent=()=>{

    for(let i in state){
      if(i==='selected2'){
        delete state[i];
      }
    }
    setModalFormStatusObj({ text:""});
   }

 const onDownloadReport=async()=>{
  if(reptType==='activityReport'){
    setIsDownloading(true);
    onDownloadActivityReport();
  }
  else{
    try {
    const resp = await DOMAIN.get('reports/sandboxReportTypes');
    if (resp.result.length > 0) {
        const options = [{key: 0, label: "Select Report Type", value: ''},{key:1,label: "Activity Report", value: 'activityReport'}];
        resp.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.id}`});
          return fieldValue;
        });
        setReportTypes(options);
    }
    else{
        console.log("error")
    }


    const respStore = await DOMAIN.get('stores');
    if (respStore.result.length > 0) {

        const options = [{key: 0, label: "Select Route", value: ''}];
        respStore.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: `${fieldValue.flightNumber} (${fieldValue.originName}-${fieldValue.destinationName})`, value: `${fieldValue.value}`});
          return fieldValue;
        });
        setStores(options);
    }
    else{
        console.log("error")
    }


} catch (error) {

}

setShowDownload(true)
let body={}
if(reportDate.startDate&&reportDate.endDate&&reptType){
  body={
    startDate: reportDate.startDate,
    endDate: reportDate.endDate,
    reportType: reptType,
    storeId: storeType.length>0?storeType:null
}
 let header={
      'Accept':'text/csv'
    }
        try {
        const res= await DOMAIN.post(`sales/reports`,body,header);
        if(res.status==='scheduled'){
          setIsDownloading(true)

          DOMAIN.get(`downloads/status/${res.id}`).then( resp => {

            if (resp.status === 'scheduled') {

                checkDownloadStatus(resp.id);

            } else {
            }

        })
        .catch( error => {
        });
        }
        }
         catch (error) {
          setState((_) => {
            return { ..._,message:{text:error?.data?.message},};
          })
          setTimeout(() => {
            setState((prevState) => ({
              ...prevState,
              message: null,
            }));
          }, 5000);
           setIsDownloading(false)
         }
}

  }



      }

      const checkDownloadStatus = async (dowId, e) => {
        await APIEndpoint.get('downloads/status/'+ dowId).then( resp => {
          if (resp.status === 'completed') {
            setTimeout(() => {
              setIsDownloading(true)
              const sapRep=APIEndpoint.get('downloads/status/'+ dowId)
              sapRep.then((val) => {
                if (val.status === "completed") {
                    DOMAIN.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
                        .then((ItemMasterDwnld) => {
                            const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${resp.fileName.split("-")[0]}.csv`);
                            document.body.appendChild(link);
                            link.click();
                            setIsDownloading(false);
                        })
                        .catch((error) => {

                            console.error('Error downloading CSV:', error);
                            setIsDownloading(false);
                        });
                        setState((_prev) => {
                          return { ..._prev, selected: false };
                      });
                      setModalFormStatusObj({ text: '' });
                }
            });
              }, 8000);


            }
            else {
                let timeout = setTimeout(() => {
                    checkDownloadStatus(dowId, e);
                }, 5000);
            }

        }).catch( error => {

        });
    };

  const onAddItemHandler = () => {

    /**
     * Set to edit form
     */
     EditFormFields.forEach( editRow => {

      if(editRow.attribute === 'aircraft'){
        editRow.options=aircrafts;
      }
      return editRow;
    });
    if(EditFormFields.length){
    setState( (_prev) => {return { ..._prev, selected:'createInstance'}});

    }
  }
  // const onPerLimitChange = (e) => {
  //   setPerPageLimit(Number(e.target.value));
  // };

  const onSearch = (e) => {
      setSearchKey(e.target.value);
  };

  const onItemTypeChange = (e) => {
      setItemType(e.target.value);
  }

  const onReportTypeChange = (e) => {
    setReptType(e.target.value);
}
const onStoreChange=(e)=>{
    if(reptType==="sandboxSalesSummary"||reptType==="sandboxInvoiceSummary"){
        setStoreType([e])

    }
    else{
        setStoreType([e])

    }
}
const onColumnResizedHandler=(e)=>{

  if (e && e.column?.getColId() === 'sectors') {
    const newWidth = e?.column.actualWidth;

    const sectorCells = document.querySelectorAll('.sector-container');
    sectorCells.forEach(cell => {
      cell.style.maxWidth = `${newWidth}px`;
    });
  }
}

  const onDateChange=(e)=>{
    setState( (_prev) => {
        return { ..._prev,startDate:e.target.value,resetDataSource:!state.resetDataSource}
      });
  }
const onDateChangeRep=(e)=>{
const {name,value}=e.target;
if(name==='startDate'){
    setReportDate((prev)=>({...prev,startDate:value}))
}
else{
    setReportDate((prev)=>({...prev,endDate:value}))
}
}
  const handleEmptyInstanceCheck=()=>{
    setState( (_prev) => {
        return { ..._prev,emptInst:!state.emptInst,resetDataSource:!state.resetDataSource}
      });
  }
  const onInstanceChange=(e)=>{
    setState( (_prev) => {
        return { ..._prev,instanceChange:e.target.value,resetDataSource:!state.resetDataSource}
      });
  }

  const onSearchSectors=(e)=>{
    setState( (_prev) => {
        return { ..._prev,sectors:e.target.value,resetDataSource:!state.resetDataSource}
      });
  }
const onInstanceStateChange=(e)=>{
    setState( (_prev) => {
        return { ..._prev,InstanceState:e.target.value,resetDataSource:!state.resetDataSource}
      });
}
  const onUploadClose = () => {
    setShowDownload(false);
    setReportDate({startDate:"",endDate:""})
    setReptType("");
  }
  const onChangeTailNumber=(e)=>{
    setState( (_prev) => {
        return { ..._prev,tailNum:[e],resetDataSource:!state.resetDataSource}
      });
  }


const orderListChange={
  status,
  setStatus,
 paymentType, setPaymentType,
saleType, setSaleType,
searchKeyOrders, setSearchKeyOrders,
saleTypeCheck,setSaleTypeCheck
}

  return (
      <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>

          <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
          {
          Boolean(state.message) &&
          <div className={`validationMsgDiv error`}>
            {state.message.text}
          </div>
        }
          </div>

          <div className='flexRow margTop8 justifyContentSpaceBetween'>
          <div className='width100Menu justifyContentFlexEnd flexRow  '>
          <div className="underline ">
          <span><img  className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
            <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={searchKey}/></span>
            </div>
          </div>
        <div className='flexRow width100  justifyContentFlexEnd'>
           <div className=' margLeft flexRow justifyContentFlexEnd alignItemsBaseline'>
           <div className={`flexRow dialog__description__checkbox margRight40`}>
              <input
                  id={''}
                  type={'checkbox'}
                  onClick={handleEmptyInstanceCheck}
                  checked={state.emptInst}
                  value={state.emptInst}
                />
                <label htmlFor={""} className={`margTop10`} >Exclude Empty Instances</label>
            </div>
       {downloadInstances ? <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
              <button className='download dropdownStyle1-TextMedium' onClick={onDownloadReport}><span><img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} /><u className={`download__banner_${isDownloading ? "active" : ""}`}>Download Reports</u></span></button>

          </div>:<></>}
           </div>
          </div>

          </div>

          <div className="flexRow gap10 width100 justifyContentFlexStart alignItemsCenter">
            <div className='operations-in'>
            <input className="search-crew" onChange={onInstanceChange} type="search" placeholder=" Search Instance Name" value={state.instanceChange||null}/>
            </div>

            {state && state.tailNum? (<div className='felxCol' style={{marginBottom:'0.3rem'}}>
            <Select
              options={tailNumbers}
              defaultValue={state.tailNum[0]?.label ? state.tailNum[0]?.label : ''}
              value={tailNumbers?.filter(function (option) {
                return (
                  state.tailNum.length && state.tailNum[0]&&
                  state.tailNum.find((sc) => sc.label === option.label)
                );
              })}
              onChange={onChangeTailNumber}
              styles={customSelSearch}
              isOpenMenu={state.isOpenMenu}
              placeholder="Search Tail Number"
              isSearchable={true}
              isClearable={true}
              className="selectMenu-meals"
            />
          </div>):<></>}

            <button className="orderStatus-div">
            <>
                    <NGODatePicker
                      style={{width: '13rem',border:'1.48px solid #fff'}}
                      value={state.startDate}
                      dateFormat="dd/MM/yyy"
                      name='startDate'
                      className="input-margin"
                      isclearable={false}
                      placeholder={t('Start Date')}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChange} />
                  </>
            </button>

            <button className="saleType-div">
              <select
                className="select-item darkBlue-bg"
                onChange={onInstanceStateChange}
                value={state.InstanceState}
                id="saleTypeId"
              >
                <option key="" value="">
                 Instance State
                </option>
                {InstanceState.map((_) => {
                  return (
                    <option key={_.key} value={_.value}>
                      {_.label}
                    </option>
                  );
                })}
              </select>
            </button>

            <div className='operations-in'>
            <input className="search-crew" onChange={onSearchSectors} type="search" placeholder="Search Sectors" value={state.sectors||null}/>
            </div>

          { createInstances && <div className="flexRow width100 justifyContentFlexEnd">
            <button className="add-item-button" onClick={ onAddItemHandler }>
                 + Create Instance
                  </button>
            </div>}
          </div>

          <DataGrid
            ref={tableRef}
            className="full-flex"
            columns={[...Columns]}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            searchKey={searchKey}
            onColumnResizedHandler={onColumnResizedHandler}
            selItemType={selItemType}
            getRows={fetchOperationsPanel}
            getRowHeight={getRowHeight}
            rowSelection="multiple"
            onAddItemHandler={() => onAddItemHandler()}
            columnResize={true}
            resetDataSource={state.resetDataSource}
          />
        </>
      }

{
       state&&state.selected === 'createInstance' &&
        <EditModal
          showDone={true}
          className='cash-sector-add'
          showFieldsInColumn={true}
          modalFormStatusObj={modalFormStatusObj}
          title={"Create Instance"}
          buttonLabel={"Create"}
          fields={
            EditFormFields.map((elt) => {
              return { ...elt}
            })
          }
          onSubmit={handleCreateInstance}
          onClose={handleModalCancel}>
        </EditModal>
      }

{
       state&&state.selected&&state.selected.action === 'sectorAttachments' &&
        <Modal
        className='cash-deposit-summary'
        title={``}
        TitleComponent={sectorTitle}
        onSubmit={handleModalCancel}
        onClose={handleModalCancel}>
        {<SectorInstanceCard eventLogs={showEventLogs} inventoryDetails={inventoryDetails} reload={reload} header={state.selected}/> }
      </Modal>
      }

{
       state&&state.selected2&&state.selected2.action === 'viewEventLogs' &&
        <Modal
        className='cash-deposit-summary'
        title={``}
        TitleComponent={sectorTitle}
        onSubmit={handleModalCancelEvent}
        onClose={handleModalCancelEvent}>
        {<EventLogContainer id={null} reload={reload} data={state.selected2.data}/> }
      </Modal>
      }

{
       state&&state.selected2&&state.selected2.action === 'orders' &&
        <Modal
        className='cash-deposit-summary'
        title={``}
        TitleComponent={sectorTitle}
        onSubmit={handleModalCancelEvent}
        onClose={handleModalCancelEvent}>
        {<OrderListing id={null} {...orderListChange} reload={reload} flightDetails={state?.flightDetails||{}} dataOrder={state.selected2}/> }
      </Modal>
      }

{
       state&&state.selected2&&(state.selected2.action === 'transferOut'||state.selected2.action === 'transferIn'||state.selected2.action === 'stockMovement'||state.selected2.action === 'uplifts') &&
        <Modal
        className='cash-deposit-summary'
        title={``}
        TitleComponent={sectorTitle}
        onSubmit={handleModalCancelEvent}
        onClose={handleModalCancelEvent}>
          <div className='flexCol full-flex' style={{height:'90vh'}}>
             <div className='fitcontent justifyContentSpaceBetween display wrapper-sector-dynamic'>
              <div className='flexRow sector-banner display justifyContentSpaceBetween'>
                <div className='flexRow justifyContentFlexStart'>
                   <div className='sector-number fontMedium'>{state.selected2.flightDetails?.flightNumber}</div>
                   <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow sector-tag'>{state.selected2.flightDetails?.origin}</div>
                <div className='flexRow fontMedium'>{state.selected2.flightDetails?.originName}</div>
                </div>
                </div>

                <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow'> <IoArrowForwardOutline size={17} color={'#F06548'}/></div>
                <div className='flexRow fontMedium'>{state.selected2.flightDetails?.key?.split("/")[1]?state.selected2.flightDetails?.key?.split("/")[1]:state.selected2.flightDetails?.key?.split("T")[0]}</div>
                </div>
               <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow sector-tag'>{state.selected2.flightDetails?.destination}</div>
                <div className='flexRow fontMedium'>{state.selected2.flightDetails?.destinationName}</div>
                </div>
              </div>
              </div>
            <DataGrid
            rowData={state.selected2.action === 'transferIn'?state?.selected2.data?.items:state.selected2.action === 'transferOut'?state?.selected2.data?.items:state.selected2.action === 'uplifts'?state?.selected2.data?.items:state?.selected2.data?.items}
            columnResize
            ref={tableRef}
            className='full-flex'
            showDefaultFilters={false}
            enableGridSearch={false}
            columns={(state.selected2.action === 'transferIn'||state.selected2.action==='transferOut')?TransferIn:state.selected2.action==='uplifts'?Uplifts:StockMvmnt}
            rowModelType='clientSide'
            />
              <div className='flexRow fitContent'>
                  <div style={{float:'left'}} className="  margTop8 flexCol width50 justifyContentFlexStart">

                  <div className='margBottom10'>
                  <label className='fontExtrabold'>Created At</label>
                  <div>{moment(state.selected2.flightDetails?.createdAt).format('DD-MM-YYYY hh:mm A')}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Created By</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {state.selected2.flightDetails?.createdBy?.employeeName}
                    </div>
                    <div className='flexRow'>
                    {state.selected2.flightDetails?.createdBy?.employeeCode}
                    </div>
                    </div>
                </div>
                <div className='margBottom10'>
           {  state.selected2.action!=='uplifts'?<><label className='fontExtrabold'>Created Using</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {state.selected2.flightDetails?.userAgent?.appName}@ {state.selected2.flightDetails?.userAgent?.appVersion}
                    </div>
                    <div className='flexRow'>
                    {state.selected2.flightDetails?.userAgent?.deviceModel},MAC ID:{state.selected2.flightDetails?.userAgent?.macId}
                    </div>
                    </div></>:  <><label className='fontExtrabold'>Warehouse</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {state.selected2.flightDetails?.warehouse?.warehouseCode}
                    </div>

                    </div></> }
                </div>
              </div>



              </div>
          </div>
      </Modal>
      }


    {
    state&&state.selected2&&state.selected2.action === 'salesSummary' &&
    <SalesSummary sectorTitle={sectorTitle} handleModalCancelEvent={handleModalCancelEvent} state={state} paymentTypeDist={paymentTypeDist} saleTypeDist={saleTypeDist} currencyDist={currencyDist} />
    }


     {showDownload &&
        <div className="overlay" role="dialog">
          <div className="dialog sm-scrollbar">
            <div className="dialog__content">
              <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={onUploadClose} />
              <h2 className="dialog__title subheading-text-medium fontMedium">Download reports</h2>
              <hr />

                <div className="dialog__body_container flexRow mt-4 button-align justifyContentCenter flexWrap">
                <div className={`dialog__container_item input-container`}>
                 <div className={`dialog__description`}>
                <label className={`fontRegular req mt-2 menuLeft`}>Report Type</label>
                  <select className='select-input-galley1'
                  onChange={onReportTypeChange}
                  >
                    {reportsType.map((_) => {
                      return (
                        <option value={_.value} key={_.value}>{_.label}</option>
                      )
                    })}
                  </select>

                <div className={`dialog__container_item input-container`}>
                <div className={`dialog__description`}>
         {     reptType!=='activityReport'? <><> <label className={`fontRegular req menuLeft`}>Start Date</label>
                    <NGODatePicker
                      style={{width: '17rem'}}
                      value={reportDate.startDate}
                      name="startDate"
                      isclearable={true}
                      placeholder={t('Start Date')}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChangeRep} />
                  </>
                  <> <label className={`fontRegular req menuLeft`}>End Date</label>
                    <NGODatePicker
                      style={{width: '17rem'}}
                      value={reportDate.endDate}
                      name="endDate"
                      isclearable={true}
                      placeholder={t('End Date')}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChangeRep} />
                  </></> :<></>}
             { reptType==="sandboxSalesSummary"||reptType==="sandboxInvoiceSummary"?  <>
             <div className='flexCol'>
               <label className={`fontRegular req mt-2 textAlignLeft`}>Select Route</label>
               <Select
             options={stores}
             value={stores?.filter(function (option) {
               return (
                 storeType.length && storeType[0]&&
                 storeType.find((sc) => sc.label === option.label)
               );
             })}
             onChange={onStoreChange}
             styles={newCustomStyles}
             placeholder="Search Crew"
             isSearchable={true}
             isClearable={true}
             className=""
           />
             </div>
             </> :<></>






             } </div>


                </div>


                <button type="button" onClick={onDownloadReport} disabled={state.disabled} className="btn btn-info mt-4" style={{ color: "white",width:"100%" }}>
                  { isDownloading?<div className='flexRow justifyContentCenter alignItemsCenter'><img src={LoadingGIF} className='icon-size' style={{width:'25px',height:'25px'}} alt="Loading" /> Processing...</div>:<span><span><img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} /></span> Download</span>}
                </button>
              </div>
</div>

                  {/* <div className="col-md-4 col-xs-12">
                  </div> */}
                </div>
            </div>
          </div>
        </div>
      }
      {
      t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
      }
    </div>
  )
}