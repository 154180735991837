/* eslint-disable react-hooks/exhaustive-deps */


import React, { useEffect, useState } from 'react';
import { IoMdGitMerge } from "react-icons/io";
import { IoPerson } from "react-icons/io5";
import {Modal} from '../../../../common';
import { APIEndpoint, DFPAPIEndpoint } from '../../../../utils/axios';
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import tick from '../../../../assets/icons/tick.svg';
import LoadingGIF from '../../../../assets/loading.gif';
import { IoArrowForwardOutline } from "react-icons/io5";
import { MdLeakRemove } from "react-icons/md";
import { MdAddBox,MdDelete,MdEditSquare } from "react-icons/md";
import deposit from "../../../../assets/deposit.svg";
import moment from 'moment';
import { CollapsebleSectorInstance } from '../../../../common';

function SectorsEditIsolate(props) {
const {loading,delOffld}=props;
  const sType = localStorage.getItem('sType');
const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);
  const [reload,setReload]=useState(false);
  const [state, setState] = useState({});
  const [sectorIsolateData, setSectorIsolateData] = useState({});
  const [seletedSec, setSelectedSec] = useState([]);
  const [sectors,setSectors]=useState(props.header)
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const handleView = (id) => {
    setSelectedItemIndex(prevIndex => (prevIndex === id ? null : id));
  };

useEffect(() => {
props.setEditedSectors(sectors);
}, [sectors])

useEffect(() => {
  if(props.navigateInstanceId){
    handleView(props.navigateInstanceId)
  }
  }, [props.navigateInstanceId])

const getSectorData = async (id) => {

  try {
    const res = await APIEndpoint.get(`sandboxing/containerIsolates/${id}`);
    if (res.isolate) {
      setSectorIsolateData((prev) => ({ ...prev, ...res.isolate }));

    }
  } catch (error) {

  }
};


useEffect(() => {
  if (props.id) {
    const { id } = props;
    getSectorData(id);
  }
}, [props,delOffld]);

useEffect(async() => {
  if(props.delOffld){
    const resEditDoc =await props.deleteRefresh();
    if(resEditDoc && resEditDoc.sectors){
      setSectors(resEditDoc.sectors);
    }
  }

}, [delOffld]);

const CrewLabels = (list) => {
  if (list.length) {
    return (
      <div className="crew-container">
        {list.map((item, index) => (
          <div className="employee-crew" key={item.employeeId}>
            <div className='flexRow'>
              {item.employeeName}&nbsp;({item.role})
            </div>
            <div className='flexRow'>{item.employeeCode}</div>
          </div>
        ))}
      </div>
    );
  } else {
    return "No crew members added yet";
  }
}


  const getSectorStatus = (sec) => {
    switch (sec) {
      case 'open':
        return <div className="status-instance" style={{ backgroundColor: '#03a5fc' }}>Active</div>;
      case 'locked':
        return <div className="status-instance" style={{ backgroundColor: '#f03630' }}>Locked</div>;
        case 'cancelled':
        return <div className="status-dynamic" style={{ backgroundColor: '#f03630' }}>Cancelled</div>;
        case 'unknown':
        return <div className="status-dynamic" style={{ backgroundColor: '#1d3f4f' }}>Unknown</div>;
      case 'committed':
        return <div className="status-instance" style={{ backgroundColor: '#09ab16' }}>Committed</div>;
        case 'completed':
          return <div className="status-dynamic" style={{ backgroundColor: '#09ab16' }}>Completed</div>;
      case 'commitScheduled':
        return <div className="status-instance" style={{ backgroundColor: '#f28016' }}>Commit Scheduled</div>;
      default:
        return "";
    }
  };

  const getSyncStatus = (sec) => {
    switch (sec) {
      case 'syncPending':
        return <div className="status-dynamic" style={{ backgroundColor: '#f03630' }}>Sync Pending</div>;
      default:
        return<><div className="status-dynamic" style={{ backgroundColor: '#09ab16' }}>Synced</div>&nbsp;<span>100%</span></> ;
    }
  };

const detachSector=async(secId)=>{
  const payload={
    isolateId: props.id,
    sectorId:secId
}
  try {
    const resDetach = await DOMAIN.post('sandboxing/containerIsolates/isolatedSectors/detach',payload);
  if(resDetach.message.length){
    props.setLoadEditSectors(true);
    props.setActionStatus({action:'Detach Sector',status:'Sector detached successfully'})
  }
  else{
  }
  } catch (error) {

  }
}
const upliftRefresh=async(e,uId)=>{
props.saveEdits(e,'upliftRefresh',uId)
}
const arrangeSectors=(pos,index)=>{

  switch (pos) {
    case 'up':
      if(index>0){
        props.setActionStatus({action:'Move Up',status:'Sector moved up'})
        props.setSort(true);
            setSectors(prev=>{
      let updatedSector=[...prev];
      let temp=updatedSector[index];
      updatedSector[index]=updatedSector[index-1];
      updatedSector[index-1]=temp;
      return updatedSector;
    })
      }

      break;
      case 'down':
        if(index<sectors.length-1){
        props.setActionStatus({action:'Move Down',status:'Sector moved down'})
        props.setSort(true);
          setSectors(prev=>{
           let updatedSector=[...prev];
            let temp=updatedSector[index];
            updatedSector[index]=updatedSector[index+1];
            updatedSector[index+1]=temp;
            return updatedSector;
          })
        }

        break;

    default:
      break;
  }
}

const mergeSectors=async(data)=>{
  setState( (_prev) => {
    return { ..._prev, selected: {data,action: 'mergeSectors' }}
  });

}

const getPermissionGrid=()=>{
  if(state.selected){
      const {data}=state?.selected;
return sectors.filter((sec)=>sec.key===data.key).filter((fl=>fl.name!==data.name)).map(m=>({...m,mergeSel:false}))
  }
  else{
    return []
  }

}

const setMergeState=(arr,obj)=>{

setSelectedSec(arr.map(sec=>{
  if(sec.name===obj.name){
    return {...sec,mergeSel:!sec.mergeSel}
  }else{
    return {...sec,mergeSel:false}
  }

}))
}
const listItems = !seletedSec.length?getPermissionGrid().map((list,index) =>
{
  return(<React.Fragment key={index}>

        <li className="list-group-item">
        <div className='row'>
          <div  style={{width:'100%'}}>
            <div   onClick={()=>setMergeState( getPermissionGrid(),list)} className='flexRow justifyContentSpaceBetween'style={{padding:'5px'}}>
            <div className='flexRow justifyContentFlexStart'  ><strong>{list?.name}</strong>{list?.isolateAffinity?.name?<span className='code'>{list?.isolateAffinity?.name}</span>:<></>} </div>  <div className='flexRow justifyContentFlexEnd'><IoPerson size={17} style={{ marginRight: '3px' }} /><span style={{ paddingTop:'3px'}}>{list.createdBy.employeeName} @ {moment(list.createdAt).format('DD-MM-YYYY  hh:mm A')} <span className='margLeft8'>{list.mergeSel? <img width={'20px'} height={'20px'} alt='tick' src={tick} />:<></>}</span></span></div>
            </div>
          </div>
        </div>
      </li>

  </React.Fragment>)}
 ):seletedSec.map((list,index) =>
 {
   return(<React.Fragment key={index}>

         <li className="list-group-item">
         <div className='row'>
           <div  style={{width:'100%'}}>
             <div   onClick={()=>setMergeState(seletedSec,list)} className='flexRow justifyContentSpaceBetween'style={{padding:'5px'}}>
             <div className='flexRow justifyContentFlexStart'  ><strong>{list?.name}</strong>{list?.isolateAffinity?.name?<span className='code'>{list?.isolateAffinity?.name}</span>:<></>} </div>  <div className='flexRow justifyContentFlexEnd'><IoPerson size={17} style={{ marginRight: '3px' }} /><span style={{ paddingTop:'3px'}}>{list.createdBy.employeeName} @ {moment(list.createdAt).format('DD-MM-YYYY  hh:mm A')} <span className='margLeft8'>{list.mergeSel? <img width={'20px'} height={'20px'} alt='tick' src={tick} />:<></>}</span></span></div>
             </div>
           </div>
         </div>
       </li>

   </React.Fragment>)}
  );

  const handleMergeSectors=async()=>{

    const targetSector=seletedSec.find(fl=>fl.mergeSel);
    if(targetSector&&state.selected){
      const {data}=state.selected;
    const payload={
      "isolateId": props.id,
      "documentId": props.editDetails?.id,
      "edits": [
          {
              "name": "MergeSectors",
              "params": {
                  "target": {
                      id: targetSector.id,
                      key: targetSector.key,
                      name: targetSector.name
                  },
                  "source": {
                      "id": data.id,
                      "key": data.key,
                      "name": data.name
                  }
              }
          }
      ]
  }

  try {

    const res = await APIEndpoint.post(`sandboxing/containerIsolates/editDocument/edits`,payload);
  if(res){
  props.setActionStatus({action:'MergeSectors',status:'Sectors Merged'});
  handleModalCancelEvent();
  }

  } catch (error) {

  }
    }


  }


const handleModalCancelEvent=()=>{
  setState( (_prev) => {
    return { ..._prev, selected:false}
  });
  setSelectedSec([]);
}


  return (
    <>
    <ul className='list-group' style={{ display: 'flex', flexWrap: 'wrap',width:'100%' }}>
     {

     loading ? (
      <li className='flexRow justifyContentCenter alignitemsCenter'>
        <div className='flexRow'>
          <img src={LoadingGIF} style={{ width: '25px', height: '25px' }} alt="Loading" />
          <div className="margTop5">Setting up the Editor...</div>
        </div>
      </li>
    ) :
     sectors && sectors?.length? (
        sectors?.map((item, index) => (
          <React.Fragment key={index}>
            <li
              className={`flexRow col-xs-11 wrapper-sector justifyContentSpaceBetween ${
                index === selectedItemIndex ? 'selected' : ''
              }`}
              onClick={() => handleView(item.id)}
              style={{ cursor: 'pointer' }}
            >
              <div className="flexRow justifyContentCenter alignItemsCenter">
                <span className="justifyContentFlexStart instance-member fontExtrabold">{item?.key?.split("/")[0]}</span><span className="margLeft5 fontSemibold">{item.key.split("/")[1]}</span>
              </div>
              <div className="flexRow">
                <span className="justifyContentFlexEnd instance-member">
                  <IoPerson size={15} style={{ marginRight: '7px' }} />
                  {item.createdBy.employeeName}

                </span>
              </div>
            </li>
            {selectedItemIndex === item.id && (
              <CollapsebleSectorInstance id={item.id} selectedItemIndex={selectedItemIndex}>
              <div className='fitcontent justifyContentSpaceBetween col-xs-11 wrapper-sector'>

              <div className='flexRow justifyContentSpaceBetween'>
            {props.header.length>1?<div className='flexRow justifyContentFlexStart'>
            {index===0?<button className='grid-download-btnStyle1' title='Move down' style={{backgroundColor:'#001B2B',border:'none'}} onClick={()=>arrangeSectors("down",index)}><FaLongArrowAltDown /></button>:index===props.header.length-1?
            <button className='grid-download-btnStyle1' title='Move up' style={{backgroundColor:'#001B2B',border:'none'}} onClick={()=>arrangeSectors("up",index)}><FaLongArrowAltUp /></button>:<><button title='Move up' className='grid-download-btnStyle1' style={{backgroundColor:'#001B2B',border:'none'}}  onClick={()=>arrangeSectors("up",index)}><FaLongArrowAltUp /></button>
            <button className='grid-download-btnStyle1' title='Move down'  style={{backgroundColor:'#001B2B',border:'none'}}  onClick={()=>arrangeSectors("down",index)}><FaLongArrowAltDown /></button></>}


            <div onClick={()=>mergeSectors(item)} className='margLeft8' title='Merge sectors'>
            <button style={{backgroundColor:'#006385',border:'none'}} className='grid-download-btnStyle1'>
            <IoMdGitMerge />
            </button>
            </div>
            </div>:<></>}

                <div className='flexRow justifyContentFlexEnd' title='Detach sector'>
                   <button className='grid-download-btnStyle1' style={{backgroundColor:'red',border:'none'}} onClick={()=>detachSector(item.id)}><MdLeakRemove /></button>

                </div>


              </div>


              <div className='flexRow sector-banner justifyContentSpaceBetween'>
                <div className='flexRow justifyContentFlexStart'>
                   <div className='sector-number fontMedium'>{item.flightNumber}</div>
                   <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow sector-tag'>{item.origin}</div>
                <div className='flexRow fontMedium'>{item.originName}</div>
                </div>
                </div>

                <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow'> <IoArrowForwardOutline size={17} color={'#F06548'}/></div>
                <div className='flexRow fontMedium'>{item.key.split("/")[1]}</div>
                </div>
               <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow sector-tag'>{item.destination}</div>
                <div className='flexRow fontMedium'>{item.destinationName}</div>
                </div>
              </div>
              <div className='flexRow fitContent'>
                  <div style={{float:'left'}} className="flexCol width50 justifyContentFlexStart">
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Sector ID</label>
                  <div>{item.name}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Operation Status</label>
                  <div>{getSectorStatus(sectors[index].operationStatus)}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Created At</label>
                  <div>{moment(item.createdAt).format('DD-MM-YYYY hh:mm A')}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Created By</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {item.createdBy.employeeName}
                    </div>
                    <div className='flexRow'>
                    {item.createdBy.employeeCode}
                    </div>
                    </div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Created Using</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {item.userAgent.appName}@ {item.userAgent.appVersion}
                    </div>
                    <div className='flexRow'>
                    {item.userAgent.deviceModel},MAC ID:{item.userAgent.macId}
                    </div>
                    </div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Started At</label>
                  <div>{moment(item.startedAt).format('DD-MM-YYYY hh:mm A')}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Ended At</label>
                  <div>{moment(item.endedAt).format('DD-MM-YYYY hh:mm A')}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Price List</label>
                  <div>{sectors[index].priceList}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Seat Price List</label>
                  <div>{sectors[index].seatPriceListName||'Unknown'}</div>
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Event Log</label>
                  <button className="status-dynamic dynamic-sl" onClick={()=>props.eventLogs(item,sectorIsolateData.sectors[index],props.editDetails)}>View Event Logs</button>
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Sales Summary</label>
               { sectors[index].state!=='committed'&& sectors[index]?.transferOutReport?  <button className="status-dynamic dynamic-sl" onClick={()=>props.inventoryDetails(sectors[index],'salesSummary',props.editDetails,sectorIsolateData?.sectors[0])}>{sectors[index]?.transferOutReport?.sector?.name}</button>:<>None</>}

                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>PAX Reported by Crew </label>
                  <div>{sectors[index].pax?sectors[index].pax:'Not Available'}</div>
                </div>
                <div>
                  <label className='fontExtrabold'>Crew</label>
                  <div>{CrewLabels(sectors[index].crew)}</div>
                </div>

              </div>
              <div className="flexCol width50">

              <div className='margBottom10'>
                  <label className='fontExtrabold'>Sync status</label>
                  <div>{getSyncStatus(sectors[index].syncStatus)}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Synced At</label>
                  <div>{moment(sectors[index].syncedAt).format('DD-MM-YYYY hh:mm A')}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Number of Orders To Sync</label>
                  <div>{sectors[index].numberOfItemsToSync?sectors[index].numberOfItemsToSync:'Not available'}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Number of Orders Synced</label>
                  <div>{sectors[index].numberOfItemsSynced?sectors[index].numberOfItemsSynced:'Not available'}</div>
                </div>


                <div className='flexCol margBottom10'>
                  <div className='flexRow' title='Refresh Uplifts'>
                  <label className='fontExtrabold'>Uplifts</label>
                 <button className='small-body-text-regular fontExtrabold key-value btn-sync' onClick={(e)=>upliftRefresh(e,sectors[index])}>
                  <img style={{height:'18px',width:"18px",marginLeft:"0.5rem",backgroundColor:'#3e687d',borderRadius:"5px",border:"1px solid #4c5c81"}} src={deposit} onClick={()=>setReload(!reload)} alt='refresh-sectors' />
                  </button>
                  </div>
               {  sectors[index]?.uplifts.length? <button className="status-dynamic" onClick={()=>props.inventoryDetails(sectors[index],'uplifts',props.editDetails,sectorIsolateData.sectors[index])} style={{ width:'17rem',whiteSpace:'nowrap',backgroundColor: '#06cc48' }}>Uplift from {sectors[index]?.uplifts[0].warehouse?.warehouseCode}</button>:<>None</>}
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Transfer In</label>
                { sectors[index]?.transferInReport? <button className="status-dynamic" onClick={()=>props.inventoryDetails(sectors[index],'transferIn',props.editDetails,sectorIsolateData.sectors[index])} style={{ width:'17rem',whiteSpace:'nowrap',backgroundColor: '#06cc48' }}>{sectors[index]?.transferInReport?.sector?.name}</button>:<>None</>}
                </div>
                <div className='flexCol margBottom10'>
                  <div className='flexRow'>
                  <label className='flexRow fontExtrabold margRight'>Offloads</label>
                  {!sectors[index]?.offloadReport?<span style={{cursor:'pointer'}} onClick={()=>props.inventoryDetails(sectors[index],'createOffloads',props.editDetails,sectorIsolateData.sectors[index],props.editDetails)}><MdAddBox size={18} color='#3e687d'/></span>:<><span style={{cursor:'pointer'}} onClick={()=>props.inventoryDetails(sectors[index],'editOffloads',props.editDetails.sectors[index],sectorIsolateData.sectors[index],props.editDetails)}><MdEditSquare size={18} color='#3e687d'/></span>&nbsp;&nbsp;<span style={{cursor:'pointer'}} onClick={()=>props.inventoryDetails(sectors[index],'deleteOffloads',props.editDetails.sectors[index],sectorIsolateData.sectors[index],props.editDetails)}><MdDelete size={18} color='#d9534f'/></span></>}

                  </div>
                { sectors[index]?.offloadReport ? <button className="status-dynamic" onClick={()=>props.inventoryDetails(sectors[index],'offloads',props.editDetails.sectors[index],sectorIsolateData.sectors[index])} style={{ width:'17rem',whiteSpace:'nowrap'}}>{sectors[index]?.offloadReport.sector.name}</button>:<>None</>}
                </div>



                <div className='flexCol margBottom10'>
                  <div className='flexRow'>
                  <label className='flexRow fontExtrabold margRight'>Wastages</label>
                  {!sectors[index]?.wastageReport?<span style={{cursor:'pointer'}} onClick={()=>props.inventoryDetails(sectors[index],'createWastages',props.editDetails,sectorIsolateData.sectors[index],props.editDetails)}><MdAddBox size={18} color='#3e687d'/></span>:<><span style={{cursor:'pointer'}} onClick={()=>props.inventoryDetails(sectors[index],'editWastages',props.editDetails.sectors[index],sectorIsolateData.sectors[index],props.editDetails)}><MdEditSquare size={18} color='#3e687d'/></span>&nbsp;&nbsp;<span style={{cursor:'pointer'}} onClick={()=>props.inventoryDetails(sectors[index],'deleteWastages',props.editDetails.sectors[index],sectorIsolateData.sectors[index],props.editDetails)}><MdDelete size={18} color='#d9534f'/></span></>}

                  </div>
                { sectors[index]?.wastageReport ? <button className="status-dynamic dynamic-act" onClick={()=>props.inventoryDetails(sectors[index],'wastages',props.editDetails.sectors[index],sectorIsolateData.sectors[index])} style={{ width:'17rem',whiteSpace:'nowrap'}}>{sectors[index]?.wastageReport.sector.name}</button>:<>None</>}
                </div>

                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Orders</label>
                  {  sectors[index].state!=='committed'&&sectors[index]?.name?  <button className="status-dynamic dynamic-act" onClick={()=>props.inventoryDetails(sectors[index],'ordersEditDoc',props.editDetails,sectorIsolateData.sectors[index])}>{sectors[index]?.name}</button>:<>None</>}

                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Transfer Out</label>
               { sectors[index]?.transferOutReport?  <button className="status-dynamic dynamic-act" onClick={()=>props.inventoryDetails(sectors[index],'transferOut',props.editDetails,sectorIsolateData.sectors[index])}>{sectors[index]?.transferOutReport?.sector?.name}</button>:<>None</>}
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Uplift Audit Report  </label>
                  <div>{"None"}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Transfer Audit Report </label>
                  <div>{"None"}</div>
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Stock Movement Overview </label>
                 {sectors[index]?.name? <button className="status-dynamic dynamic-sl" onClick={()=>props.inventoryDetails(sectors[index],'stockMvmnt',props.editDetails,sectorIsolateData.sectors[index])}>{sectors[index]?.name||"None"}</button>:<>None</>}
                </div>
              </div>


              </div>
            </div>
              </CollapsebleSectorInstance>
            )}
          </React.Fragment>
        ))
      ) : (
        <li className='flexRow'>
          <div className='flexRow col-xs-11 wrapper-sector justifyContentCenter alignitemsCenter'>No sectors attached to this instance yet.</div>
        </li>
      )}
    </ul>





{
  state&&state.selected&&(state.selected.action === 'mergeSectors') &&<div className='flexRow width100'>
       <Modal
   className='cash-deposit-summary'
   title={`Merge Sectors to ${state.selected.data?.name}`}
   onSubmit={handleMergeSectors}
   onClose={handleModalCancelEvent}
   buttonLabel={'Merge'}
   disabled={!seletedSec.some(sl=>sl.mergeSel)}
   >
     <div className='flexCol full-flex'>
        <div className='fitcontent justifyContentSpaceBetween display wrapper-sector-dynamic'>
         <div className='flexRow sector-banner justifyContentSpaceBetween'>
           <div className='flexRow justifyContentFlexStart'>
              <div className='sector-number fontMedium'>{state.selected.data?.flightNumber}</div>
              <div className='flexCol justifyContentCenter alignItemsCenter'>
           <div className='flexRow sector-tag'>{state.selected.data?.origin}</div>
           <div className='flexRow fontMedium'>{state.selected.data?.originName}</div>
           </div>
           </div>

           <div className='flexCol justifyContentCenter alignItemsCenter'>
           <div className='flexRow'> <IoArrowForwardOutline size={17} color={'#F06548'}/></div>
           <div className='flexRow fontMedium'>{state.selected.data?.key?.split("/")[1]?state.selected.data?.key?.split("/")[1]:state.selected.data?.key?.split("T")[0]}</div>
           </div>
          <div className='flexCol justifyContentCenter alignItemsCenter'>
           <div className='flexRow sector-tag'>{state.selected.data?.destination}</div>
           <div className='flexRow fontMedium'>{state.selected.data?.destinationName}</div>
           </div>
         </div>
         </div>

         {listItems.length?<div className="flexRow justifyContentCenter alert alert-info mt-8 ng-scope">
                Following sectors can be merged to the above sector. Make sure that you select the right sector for
                merge.If you make a mistake while merging, you can undo the changes by removing the merged sector.

            </div>:<div className="alert alert-info mt-8 ng-scope" >
            None of the sectors currently attached to instance <code className="ng-binding">{props.instance?.name}</code> can be merged to
            sector <code className="ng-binding">{state.selected.data.key}</code>. Use 'Find Sector' option to look for mergeable sectors, attach them to instance
            and retry merge.
        </div>}

            <div className="dialog__body_container flexRow justifyContentSpaceBetween flexWrap">
            <ul className='list-group' style={{overflowY:'scroll'}}>
                {listItems.length?listItems:< ></>}
            </ul>
          </div>

     </div>
 </Modal>
  </div>

 }


 </>
  )




}

export default SectorsEditIsolate;