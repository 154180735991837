import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { DistributorsPage, VendorPage } from "./inner-components";

export const DistributorPage = (props) => {
  return (
    <div className="flexRow full p-0 m-0">
      <div className='full-flex flexCol width100 p-0 m-0'>
        <Routes>
          <Route path="/" element={<Navigate replace to="distributors" />} />
          <Route path="/distributors" element={<DistributorsPage />} />
          <Route path="/vendor" element={<VendorPage />} />
        </Routes>
      </div>
    </div>
  )
}