/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { APIEndpoint,DFPAPIEndpoint } from "../../../utils/axios";
import { useLocation,useNavigate} from "react-router-dom";
import { FilterBodyTemplate } from "../../../utils/configs";
import { DataGrid, Loading, Input, NGODatePicker, Modal, EditModal} from "../../../common";
import {translationLength} from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import { CashDepositDetailsModal } from "./cashDepositDetails";
import { CashDepositEditModal } from "./cashDepositEdit";
import { SectorSelect, getPendingSectors } from "./depositSectorCard"
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import editIcon from "../../../assets/edit.svg"
import deleteIcon from "../../../assets/icons/deleteRole.svg"
import moment from 'moment';
import usePermissions from '../../../utils/userPermissions';


const DepositSummaryColumns = [
  { field: 'currency', headerName: 'pages.cashRegisterContent.cashDeposits.summaryTableColumns.currency', },
  { field: 'value', headerName: 'pages.cashRegisterContent.cashDeposits.summaryTableColumns.value', },
]

const ActionButtons = [
  { title: "Edit", className: 'btn-info', action: 'edit' },
  { title: "Delete", className: 'btn-danger', action: 'delete' },
  { title: "Submit", className: 'btn-primary', action: 'submit' },
]


export const CashDepositsPage = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [state, setState] = useState({
    cashDrawerList: null, filterDate: '', filterState: "", filterCashDrawer: '',
    resetDataSource: false,denomId:location.state?.depositId||null
  });
  const [modalCustomButtons,setModalCustomButtons]=useState([]);
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const sType = localStorage.getItem('sType');
  const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

  const { hasPermission: createCashDrawer } = usePermissions('create', "cashier.cashDrawer");
  const { hasPermission: editCashDrawer } = usePermissions('edit', "cashier.cashDrawer");
  const { hasPermission: viewcashDrawer } = usePermissions('viewDetails', 'cashier.cashDrawer');
  const { hasPermission: savecashDrawer } = usePermissions('save', 'cashier.cashDrawer');
  const { hasPermission: deletecashDrawer } = usePermissions('delete', 'cashier.cashDrawer');
  const { hasPermission: downloadcashDrawer } = usePermissions('download', 'cashier.cashDrawer');


  const getStateList = () => {
    return [
      { label: t('pages.cashRegisterContent.cashDeposits.statePlaceHolder'), value: "" },
      { label: t('pages.cashRegisterContent.cashDeposits.stateDraft'), value: "draft" },
      { label: t('pages.cashRegisterContent.cashDeposits.stateFinalized'), value: "finalized" },
    ]
  }
  const NewDepositFields = [
    { label: 'Cash Drawer', attribute: 'cashDrawerId', type: 'selectSearch2', required: true },
    { label: 'Date', attribute: 'targetDate', type: 'date', required: true },
    { label: 'Employee', attribute: 'employeeId', type: 'multiSelect',required: true },
  ]

  const ReportFields = [
    { label: 'Report Type', attribute: 'reportType', type: 'select', required: true },
    { label: 'Start Date', attribute: 'startDate', type: 'date', required: true,value:new Date(moment()),maxDate:new Date(moment())},
    { label: 'End Date', attribute: 'endDate', type: 'date', required: true ,value:new Date(moment()),maxDate:new Date(moment())},
  ]
  const [reportsFields, setReportFields] = useState(ReportFields||[]);


  useEffect(() => {
    fetchCashDrawersList();
  }, [t])

  useEffect(() => {
    if(state.selectedSector){
      getDisableState()
    }
    if(state.showActionDialog==='report'){
      setReportFields((prev)=>{
        return prev?.map((_) => {
          if(_.label ==='End Date'){
            return { ..._, minDate:new Date(ReportFields[1].value), }

          }
          else {
            return { ..._,}
          }
        })
      })
    }
  }, [state])


  useEffect(() => {
    if (location.state) {
      navigate(location.pathname, { replace: true, state: null });
    }
  }, [location, navigate]);


  useEffect(() => {
    const fetchCashData = async () => {
      if (state.denomId) {
        try {
          const cashDep = await fetchCashBalance(1, 4000, "");

          if (cashDep && cashDep.items) {
            const casDepObj = cashDep.items.find(c => c.id === state.denomId);
            if (casDepObj) {
              onAction(casDepObj, 'edit');
            }
          }
        } catch (error) {

        }
      }
    };
    fetchCashData();
  }, [state.denomId]);

  const onAction = (row, action) => {
    let data = row;
    switch (action) {
      case 'edit':
      case 'adddeposit': break;
      case 'delete':
      case 'submit':
      case 'summary':
      data.rows = (row.aggregate || []).map((_) => {
      return { currency: `${_.currencyName}(${_.currencyCode})`, value: _.value }
      })
      break;
      default:
      break
    }
    setState((_) => ({ ..._, showActionDialog: action, actionDialogData: { ...data }}))
  }
  const cellRenderer = (params) => {
    let state = Boolean(params && params.data) ? params.data.state : null;
    if (state) {
      return (
        <div className='flexCol'>
          {
            state === 'draft' ?
              <div className='flexRow actions'>
                {
                  ActionButtons.map((btn) => {
                    if (btn.title === "Edit") {
                      return (
                        <div className="alignItemsCenter icon-aircraft-container">
                          <img
                            className="aircraft-actions"
                            style={{margin:'0.4rem 0rem 0.4rem 0rem'}}
                            alt="edit"
                            onClick={() => onAction(params.data, btn.action)}
                            src={editIcon}
                          />
                          <span className="tooltip-action">Edit</span>
                        </div>
                      );
                    } else if (btn.title === "Delete") {
                      return (
                        <div className="alignItemsCenter icon-aircraft-container">
                          <img
                            className="aircraft-actions"
                            alt="delete"
                            style={{marginBottom:'0.4rem'}}
                            onClick={() => onAction(params.data, btn.action)}
                            src={deleteIcon}
                          />
                          <span className="tooltip-action">Delete</span>
                        </div>
                      );
                    } else {
                      return (
                        <button
                          key={btn}
                          className={`grid-download-btnStyle1`}
                          onClick={() => onAction(params.data, btn.action)}
                        >
                          <span>{btn.title}</span>
                        </button>
                      );
                    }
                  })
                }
              </div>
              :
            <div className='flexRow actions'>
              <button className='grid-download-btnStyle1'  onClick={() => onAction(params.data, 'details')}>
                <span>Details</span>
              </button>
            </div>
          }
        </div>
      )
    }
    return null;
  }

  function layoutComponent(props) {

    return (
      props.data && viewcashDrawer ?
      <div>
        <button className="grid-download-btnStyle1" onClick={(e) => showDepositDetails(props.data)}>View Summary</button>
      </div> : <div></div>
    );
  }
  const Columns = [
    { field: 'code', headerName: 'pages.cashRegisterContent.cashDeposits.tableColumns.code', resizable:true,
    suppressSizeToFit:true, sortable: true,minWidth:230,  tooltipField: 'code'},
    { field: 'cashDrawerText', headerName: 'pages.cashRegisterContent.cashDeposits.tableColumns.cashDrawerText', resizable:true,
    suppressSizeToFit:true, sortable: true},
    { field: 'date', headerName: 'pages.cashRegisterContent.cashDeposits.tableColumns.date',resizable:true,
    suppressSizeToFit:true, sortable: true },
    { field: 'crewName', headerName: 'pages.cashRegisterContent.cashDeposits.tableColumns.crewName',resizable:true,
    suppressSizeToFit:true, sortable: true },
    { field: 'createdByName', headerName: 'pages.cashRegisterContent.cashDeposits.tableColumns.createdByName',resizable:true,
    suppressSizeToFit:true, sortable: true },
    { field: 'createdAtText', headerName: 'pages.cashRegisterContent.cashDeposits.tableColumns.createdAtText', resizable:true,
    suppressSizeToFit:true, sortable: true},
    { field: 'stateText', headerName: 'pages.cashRegisterContent.cashDeposits.tableColumns.stateText',resizable:true,
    suppressSizeToFit:true, sortable: true },
    {field:'',headerName:'pages.cashRegisterContent.cashDeposits.tableColumns.summary',pinned:'right',
    minWidth: 140, maxWidth: 200,
    resizable:true,
    suppressSizeToFit:true, sortable: true,
    cellRenderer: layoutComponent,
    valueGetter: function(params) {
      return params.data;
    }
  },
  { field: '', headerName: 'pages.cashRegisterContent.cashDeposits.tableColumns.actions',pinned:'right', className: 'btn-view-report',resizable:true,
  suppressSizeToFit:true, sortable: true,minWidth: 160,maxWidth:200},
  ]
  const getColumns = () => {
    let columns = Columns.map((_) => ({ ..._ }));
    columns[columns.length - 1].cellRenderer = cellRenderer;
    return columns
  }
  const fetchCashDrawersList = async () => {
    try {
       const res = await APIEndpoint.get('cashRegister/cashDrawers');
    const list = (res.result || []).map((_,index) => ({ key:index, label: _.value ,value: _.id,}));
    const listDownloadBankTransfer = (res.result || []).map((_,index) => ({ key:index, label: _.value ,value: _.id,}));
    list.unshift({ label: t('pages.cashRegisterContent.cashDrawer.selectPlaceholder'), value: '' })
    listDownloadBankTransfer.unshift({ label: t('pages.cashRegisterContent.cashDrawer.selectPlaceholder'), value: '' })
    setState((_) => ({ ..._, cashDrawerList: list,cashDrawerDownload:listDownloadBankTransfer }))
    } catch (error) {

    }

  }
  const fetchCashBalance = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    const filterData = JSON.parse(JSON.stringify(FilterBodyTemplate));
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    filterData.filters = [];
    if (state.filterDate) filterData.filters.push({ targetDate: moment(state.filterDate).toISOString() });
    if (state.filterState) filterData.filters.push({ state: state.filterState });
    if (state.filterCashDrawer) filterData.filters.push({ cashDrawerId: state.filterCashDrawer });

    const res = await APIEndpoint.post('cashRegister/deposits/listing', filterData);
    if (res.items) {
      const statuss = getStateList();
      res.items = res.items.map((_) => {
        const date = moment(_.createdAt);
        const state = statuss.find((s) => s.value === _.state)
        return {
          ..._,
          cashDrawerText: _.cashDrawer.airport.locationName,
          date: date.format("DD-MM-YYYY"),
          createdAtText: date.format("DD-MM-YYYY hh:mm a"),
          createdByName: `${_.createdBy.employeeName} (${_.createdBy.employeeCode})`,
          crewName: `${_.crew.employeeName} (${_.crew.employeeCode})`,
          stateText: state ? state.label : _.state
        }
      })
    }
    return res;
  }

  const showDepositDetails=(data)=>{
  setState((_) => ({ ..._, showActionDialog: 'details', actionDialogData: { ...data } }))
  }
  const handleFilterInput = (e) => {
    setState((_) => ({ ..._, [e.target.name]: e.target.value, resetDataSource: true }));
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
      setState((_) => ({ ..._, resetDataSource: false }));
    }, 100)
  }
  const handleModalCancel = () => {
    setState((_) => ({ ..._, showActionDialog: null, actionDialogData: null,showAPILoading:false }))
    setReportFields(ReportFields);
  }

  const handleAPI = async (apiCallback, onComplete) => {
    setState((prevState) => ({ ...prevState, showAPILoading: true }));
    try {
      await apiCallback()
        .then((res) => {
          setState((prevState) => ({...prevState,showAPILoading: false,resetDataSource: true,message: { success: true, message: res.message },}));
          setTimeout(() => {
            setState((prevState) => ({...prevState,showActionDialog: null,actionDialogData: null,message: null,resetDataSource: false,newDepositFormData: null,}));
            if (onComplete) onComplete(res);
          }, 1500);

          return res;
        })
        .catch((err) => {
          let errorMessage = 'Something went wrong. Please try again after some time.';
          try {
            const { data } = JSON.parse(err?.message);
            errorMessage = data?.message || errorMessage;
          } catch (e) {

          }
          setState((prevState) => ({...prevState,showAPILoading: false,message: { success: false, message: setModalFormStatusObj({ error: errorMessage }) },}));
          setTimeout(() => {
            setModalFormStatusObj({ text: "" });
            setState((prevState) => ({ ...prevState, message: null }));
          }, 1500);

          return null;
        });
    } catch (error) {
      setState((prevState) => ({ ...prevState, showAPILoading: false }));
      return null;
    }
  };

  const handleDelete = async () => {
    const { actionDialogData } = state;
    const apiCallback = () => {
      return APIEndpoint.post('cashRegister/deposits/delete/' + actionDialogData.id);
    }
    await handleAPI(apiCallback)
  }
  const handleSubmit = async () => {
    const { actionDialogData } = state;
    const apiCallback = () => {
      return APIEndpoint.post('cashRegister/deposits/finalize/' + actionDialogData.id);
    }
    await handleAPI(apiCallback)
  }
  const handleAddDeposit = async () => {
    await fetchCashDrawersList()
    const res = await APIEndpoint.get('sandboxing/employees')
    const list = (res.result || []).map((_,index) => ({ key:index,value: `${_.employeeId}`, label: `${_.employeeName}( ${_.employeeCode})` }));
    list.unshift({ label: t('pages.cashRegisterContent.cashDeposits.selectEmpPlaceholder'), value: '' })
    setModalCustomButtons([{label:'Create',attribute:'button',type:"button",disabled:true,className:'submit-button',action:'onSubmit'}])
    setState((_) => ({ ..._, showActionDialog: 'adddeposit', employeesList: list, pendingSectors: null }))
  }

    const handleOnchangeReports=(selectedValue,data,obj)=>{
      const {reportType}=data;
      if(reportType==="cashierBankTransferReport"){
      setReportFields((prev)=>{
      const cashDrawExist=prev.some(item=>item.attribute==='cashDrawer')
      if(!cashDrawExist){
      return [...prev,{ label: 'Select Station', attribute: 'cashDrawer', type: 'select', required: true }]

      }else{
      return prev

      }

      }

      )
      }
      else{
        setReportFields((prev) => {
          return prev.map((field) => {
            if (field.attribute === 'startDate' && data.startDate) {
              return { ...field, value: new Date(data.startDate) };
            }
            if (field.attribute === 'endDate' && data.endDate) {
              return {
                ...field,value: new Date(data.endDate),minDate: new Date(data.startDate) || null};
            }
            if (field.attribute === 'endDate' && data.startDate && !data.endDate) {
              const startDate = new Date(data.startDate);
              const today = new Date();
              if (startDate <= today) {
                return {...field,value: today,minDate: startDate};
              } else {
                return {...field,value: startDate,minDate: startDate,
                };
              }
            }

            if (reportType !== "cashierBankTransferReport" && field.label === 'Select Station') {
              return null;
            }
            return field;
          }).filter(field => field !== null);
        });

      }
    }
  const handleAddDepositOnChange = async (selectedValue,data,obj) => {
    const {attribute ,name} = data;
    const value=selectedValue?.value
    if (attribute === 'employeeId' || (attribute||name) === 'targetDate') {
      const newDepositFormData = state.newDepositFormData || {}
      if((attribute||name) === 'targetDate'){
        let formattedDate = moment(new Date(data.value)).format("YYYY-MM-DDTHH:mm:ssZ");
        newDepositFormData[attribute||name]=formattedDate
      }
      else{
        newDepositFormData[attribute] = value;
      }
      setState((_) => ({ ..._, newDepositFormData: { ...newDepositFormData } }))

      if (newDepositFormData.employeeId && newDepositFormData.targetDate) {
        setState((_) => ({ ..._, pendingSectors: 'wait' }))
        try {
          const res = await getPendingSectors(newDepositFormData.employeeId, newDepositFormData.targetDate)
          if(res.success){
            setState((_) => ({ ..._, pendingSectors: res.sectors?.sort((a,b)=>a?.storeId-b?.storeId), selectedSector: {}, pendingSectorsError: '' }))
          }
        } catch (error) {
          setState((_) => ({ ..._, pendingSectors: [], selectedSector: {}, pendingSectorsError: error.data?.message }))
        }
      }else if(!newDepositFormData.employeeId && attribute === 'employeeId'){
        setState((_) => ({ ..._, pendingSectors: []}))
      }
    }
  }
  const getNewDepositFields = () => {
    const getOptions = (field) => {
      let options;
      switch (field) {
        case 'cashDrawerId': options = state.cashDrawerList; break;
        case 'employeeId': options = state.employeesList; break;
        default:break
      }
      return options
    }
    return NewDepositFields.map((_) => {
      return {
        ..._,
        options: getOptions(_.attribute)
      }
    })
  }
  const handleCreateDeposit = async (e, updated) => {
    let valid = NewDepositFields.every(field => {
      if (!updated[field.attribute]) {
        return false
      }
      return true
    })
    const selectedCount = Object.values(state.selectedSector || {}).filter((_) => _).length;
    if (valid && selectedCount > 0 && !state.showAPILoading) {
      const body = { ...updated }
      // body.employeeId = Number(body.employeeId);
      let emp_id = state.newDepositFormData.employeeId
      body.employeeId = Number(emp_id);
      body.targetDate = moment(body.targetDate).startOf('day')
      body.sectors = [];
      state.pendingSectors.forEach(({ aircraftNumber, storeId, flightDate, key }) => {
        if (state.selectedSector[key]) {
          body.sectors.push({
            aircraftNumber, storeId, flightDate
          })
        }
      })
      await handleAPI(() => APIEndpoint.post('cashRegister/deposits', body), (res) => onAction(res.cashDeposit, 'edit'))
    }
  }
  const handleDownloadReportBtn = async () => {
    let res = await APIEndpoint.get('reports/cashierReportTypes')
    const list = res.result.map(_ => ({ label: _.value, value: _.id }));
    list.unshift({ label: t('pages.cashRegisterContent.cashDeposits.cashReportPlaceholder'), value: "" })
    setState((_) => ({ ..._, showActionDialog: 'report', cashReportTypes: list }))
  }

  const handleDownloadReportSubmit = async (e, updated) => {
    updated={...updated,startDate:updated.startDate?updated.startDate:ReportFields[1].value,endDate:updated.endDate?updated.endDate:ReportFields[2].value}
   let valid = ReportFields.every((_) => {
      return updated[_.attribute]
    })
    if (valid) {
      setState((prevState) => ({
        ...prevState,
        showAPILoading: true,
      }));

        let body ={}
        if(!updated.cashDrawer){
         body= {
            endDate: moment(new Date(updated.endDate)).subtract(1, 'days').format("YYYY-MM-DDTHH:mm:ssZ"),
            reportType: updated.reportType,
            startDate: moment(new Date(updated.startDate)).subtract(1, 'days').format("YYYY-MM-DDTHH:mm:ssZ")
          }
        }else{
          body= {
            endDate: moment(new Date(updated.endDate)).subtract(1, 'days').format("YYYY-MM-DDTHH:mm:ssZ"),
            reportType: updated.reportType,
            cashDrawerId:updated.cashDrawer,
            startDate: moment(new Date(updated.startDate)).subtract(1, 'days').format("YYYY-MM-DDTHH:mm:ssZ")
          }
        }
  let header={
        'Accept':'text/csv'
      }
     try {

            const res= await DOMAIN.post('cashier/download/' + body.reportType, body,header);

          if(res.status==='scheduled'){
            setTimeout(() => {
              const dowId = DOMAIN.get(`downloads/status/${res.id}`);
              dowId.then((val) => {
                if (val.status === "completed") {
                    DOMAIN.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
                        .then((ItemMasterDwnld) => {
                            const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', val.fileName + '.csv');
                            document.body.appendChild(link);
                            link.click();
                            setState((_) => ({ ..._,actionDialogData: null,showAPILoading:false }))
                        })
                        .catch((error) => {

                            console.error('Error downloading CSV:', error);
                        });

                }
            });
              }, 8000);
          }
          }
           catch (error) {
            setState((_) => {
              return { ..._,message:{text:error?.data?.message},};
            })
            setTimeout(() => {
              setState((prevState) => ({
                ...prevState,
                message: null,
                actionDialogData: null,showAPILoading:false
              }));
            }, 5000);
           }


    }

  }

  function getDisableState(){
    const disabledState = Object.keys(state.selectedSector).some(key=>state.selectedSector[key] === true);
    setModalCustomButtons([{label:'Create',attribute:'button',type:"button",disabled:!disabledState,className:'submit-button',action:'onSubmit'}])
  }


  return (
    <div className="flexCol width100 ag-theme-alpine-dark cash-reg">
      {
        state.cashDrawerList === null ?
          <Loading />
          :
          <>
            {downloadcashDrawer?<div className="flexRow justifyContentFlexEnd alignItemsCenter margBot10">
              <span className='upload dropdownStyle1-TextMedium' onClick={handleDownloadReportBtn}><img  className="mt-2 icon-size" alt='uploadItem' src={downloadMasterIcon} /><u>Download Reports</u></span>
            </div>:<></>}
            <div className='flexCol full-flex'>
              <DataGrid
                columnResize
                showDefaultFilters
                enableGridSearch
                className='full-flex'
                columns={getColumns()}
                getRows={fetchCashBalance}
                RightComponent={(props) => {
                  return (
                    <div className='flexRow alignItemsCenter'>
                      <Input
                        type='select'
                        style={{width:'10rem',paddingRight:"1rem"}}
                        className='filter-cash'
                        name='filterCashDrawer'
                        value={state.filterCashDrawer}
                        options={state.cashDrawerList}
                        onChange={handleFilterInput}
                      />
                      <Input
                        type='select'
                        name='filterState'
                        style={{width:'10rem'}}
                        className='filter-cash'
                        value={state.filterState}
                        options={getStateList()}
                        onChange={handleFilterInput}
                      />
                      <NGODatePicker
                        value={state.filterDate}
                        name='filterDate'
                        className='filter-cash'
                        onChange={handleFilterInput} />
                     { createCashDrawer?<button className="add-item-button"
                        style={{marginLeft:'.8rem'}}
                        onClick={handleAddDeposit}>
                        <i className='icon-plus add-icon' />
                        {t('pages.cashRegisterContent.cashDeposits.addnewDepositBtn')}
                      </button>:<></>}
                    </div>
                  )
                }}
                resetDataSource={state.resetDataSource}
                onButtonCellClick={(e, field, row) => {
                  onAction(row, 'summary');
                }}
              />
            </div>
          </>
      }
      {
        state.showActionDialog === 'summary' && viewcashDrawer &&
        <Modal
          className='cash-deposit-summary'
          title={`${t('pages.cashRegisterContent.cashDeposits.depositSummart')}${state.actionDialogData.code}`}
          onSubmit={handleModalCancel}
          onClose={handleModalCancel}>
          <DataGrid
            columnResize
            showDefaultFilters={false}
            enableGridSearch={false}
            className='full-flex'
            rowModelType='clientSide'
            columns={DepositSummaryColumns}
            rowData={state.actionDialogData.rows}
          />
        </Modal>
      }
      {
        state.showActionDialog === 'details' &&
        <CashDepositDetailsModal
          detail={state.actionDialogData}
          onClose={() => setState((_) => ({ ..._, showActionDialog: null, actionDialogData: null }))} />
      }
      {
        (state.showActionDialog === 'edit') && editCashDrawer &&
        <CashDepositEditModal
          detail={state.actionDialogData}
          onClose={() =>setState((_) => ({ ..._, showActionDialog: null,denomId:null}))} />
      }
      {
        state.showActionDialog === 'delete' && deletecashDrawer &&
        <Modal
          buttonLabel={t("Delete")}
          className='cash-deposit-summary'
          title={`${t('pages.cashRegisterContent.cashDeposits.deleteModalTitle')}${state.actionDialogData.code}`}
          onSubmit={handleDelete}
          modalFormStatusObj={modalFormStatusObj}
          showLoadingBtn={state.showAPILoading}
          onClose={handleModalCancel}>
          <div className='flexCol width100'>
            <div className='alert alert-warning'>
              {t('pages.cashRegisterContent.cashDeposits.deleteWarning')}
            </div>
            <DataGrid
              columnResize
              showDefaultFilters={false}
              enableGridSearch={false}
              className='full-flex'
              rowModelType='clientSide'
              columns={DepositSummaryColumns}
              rowData={state.actionDialogData.rows}
            />
            {
              Boolean(state.showAPILoading) &&
              <Loading />
            }
            {
             Boolean(state.message) && Boolean(state.message.message) &&
              <div className={`alert alert-${state.message.success ? 'success' : 'danger'}`}>
                {state.message.message}
              </div>
            }
          </div>
        </Modal>
      }
      {
        state.showActionDialog === 'submit' && savecashDrawer &&
        <Modal
          buttonLabel={t("Submit")}
          className='cash-deposit-summary'
          title={`${t('pages.cashRegisterContent.cashDeposits.submitModalTitle')}${state.actionDialogData.code}`}
          onSubmit={handleSubmit}
          modalFormStatusObj={modalFormStatusObj}
          showLoadingBtn={state.showAPILoading}
          onClose={handleModalCancel}>
          <div className='flexCol width100'>
            <div className='alert alert-warning'>
              {t('pages.cashRegisterContent.cashDeposits.submitWarning')}
            </div>
            <DataGrid
              columnResize
              showDefaultFilters={false}
              enableGridSearch={false}
              className='full-flex'
              rowModelType='clientSide'
              columns={DepositSummaryColumns}
              rowData={state.actionDialogData.rows}
            />
        
            {
              Boolean(state.message) &&  Boolean(state.message.message) &&
              <div className={`alert alert-${state.message.success ? 'success' : 'danger'}`}>
                {state.message.message}
              </div>
            }
          </div>
        </Modal>
      }
      {
        state.showActionDialog === 'adddeposit' &&
        <EditModal
          showDone={false}
          className='cash-sector-add'
          showFieldsInColumn={true}
          title={t('pages.cashRegisterContent.cashDeposits.addnewDepositBtn')}
          fields={getNewDepositFields()}
          onSubmit={handleCreateDeposit}
          modalFormStatusObj={modalFormStatusObj}
          showLoadingBtn={state.showAPILoading}
          onClose={handleModalCancel}
          modalCustomButtons={modalCustomButtons}
          onChange={handleAddDepositOnChange}>
          <div className='flexCol justifyContentSpaceEvenly '>
            <SectorSelect
              label='Sectors'
              sectors={state.pendingSectors}
              selected={state.selectedSector}
              onSelect={(key) => {
                setState((_) => ({ ..._, selectedSector: { ...state.selectedSector, [key]: !Boolean(state.selectedSector[key]) } }))
              }}
              placeholder={state.pendingSectorsError || t('pages.cashRegisterContent.cashDeposits.addDepositSectorPlaceholder')}
            />
          </div>
     
   
        </EditModal>
      }
      {
        state.showActionDialog === 'report' &&reportsFields.length&&
        <EditModal
          showDone={false}
          className='cash-sector-add'
          showFieldsInColumn={true}
          modalFormStatusObj={modalFormStatusObj}
          onChange={handleOnchangeReports}
          showLoadingBtn={state.showAPILoading}
          title={t('pages.cashRegisterContent.cashDeposits.downloadReportTitle')}
          fields={reportsFields?.map((_) => {
            if(_.label!=='Select Station'){
              return { ..._, options: state.cashReportTypes, initialValue: '' }

            }
            else {
              return { ..._, options: state.cashDrawerDownload, initialValue: '' }
            }
          })}
          onSubmit={handleDownloadReportSubmit}
          onClose={handleModalCancel}>
        </EditModal>
      }
      {
      t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
      }
    </div>
  )
}