import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
// import { AircraftsPage, AircraftType,AirportsPage, DevicesPage, SectorsPage, DistributorPage, BaseStationPage, WarehousePage, VendorsPage, ConfigureGalley, StandardGalleyLoading, TrolleyLoading, MenuListing, MenuPlanner, SalesPage, AircraftTypeAka ,StandardGalleyLoadingAka} from "./inner-components";
// import { TrolleyLoadingAka } from './inner-components/trolleyLoadingAka';
import { DynamicPage, ReconcilledPage } from "./inner-components";
import { ContainerIsolate } from "./inner-components/renderers";
import { OffloadReportCreate } from "./inner-components/renderers/offloadCreate";

export const SalesScreen = (props) => {
  return (
    <div className="flexRow full p-0 m-0">
      <div className="full-flex flexCol width100 p-0 m-0">
        <Routes>
          <Route path="/" element={<Navigate replace to="crewreported" />} />
          <Route path="/crewreported" element={<DynamicPage />} />
          <Route path="/reconcilled" element={<ReconcilledPage />} />
          {/* <Route path="/passenger" element={<PassengerDetails />} /> */}

          <Route path="/dynamic">
          <Route path="" element={<DynamicPage />} />
          <Route path="sector/containerIsolate/:name/:id" element={<ContainerIsolate />} />
          <Route path="sector/containerIsolate/offload/:name/:id/:edDocId/:secInstId" element={<OffloadReportCreate />} />
          <Route path="sector/containerIsolate/wastage/:name/:id/:edDocId/:secInstId" element={<OffloadReportCreate />} />
            </Route>
        </Routes>
      </div>
    </div>
  );
};
