
import React ,{useState,useEffect} from  'react';
import {Bar} from 'react-chartjs-2';
import zoom from '../../assets/icons/zoom.svg';
import { APIEndpoint } from '../../utils/axios';
import VerticalDivider from '../../assets/icons/svg/vertical-divider.svg';
import { durationFilterSelect} from '../../utils/configs';
import usePermissions from '../../utils/userPermissions';
import {downloadFileDashboard} from '../../utils/commonFunctions';
import DownloadVector from '../../assets/icons/svg/download-vector.svg';
import {
  Chart, CategoryScale, LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend} from 'chart.js';

Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

  export const options_flights = {
    plugins: {
      title: {
        display: false,
        text: 'Total Flights',
      },
      legend: {
        display: false
    },
    },
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          color: '#ffffff1f'
           }
      },
      y: {
        stacked: true,
        grid: {
          color: '#ffffff1f'
           }
      },
    },
  };

export const BarCharts = ( { chartLabel, data,options,filtersBar,filtersChartZoom,setFilteredWidgetData,handleZoom,setFiltersChartZoom,chartZoom })=> {
const [filters,setFilters]=useState("thisWeek");
const [responseChart,setResponseChart]=useState({});
const [,setDateRangeFilters]=useState({});
const { hasPermission } = usePermissions();
const changeFilter=async(e)=>{
  e.preventDefault();
  let wId=data.widgetId
  setFilters(e.target.value)
  setFiltersChartZoom(e.target.value)
  let filterCriteria=e.target.value;

if(!chartZoom){
  try {
  const resp = await APIEndpoint.get(`portal/v1/widget/${wId}?sortBy=${filterCriteria}`);
  if(resp.success){
    setFilteredWidgetData(resp.data)
}
  }
   catch (error) {
     console.log(error)
   }
}else{
  try {
    const resp = await APIEndpoint.get(`portal/v1/widget/${wId}?sortBy=${filterCriteria}`);
    if(resp.success){
      setResponseChart(resp.data);
  }
    }
     catch (error) {
       console.log(error)
     }
}

}
const setView=()=>{
  if(filters){
    setFiltersChartZoom(filters)
    handleZoom(chartLabel,data,options)
  }
}
const onBtExport=()=>{
if(Object.keys(responseChart).length > 0){
  downloadFileDashboard(responseChart)
}else{
  downloadFileDashboard(data,responseChart)
}

}


useEffect( () => {
 setFilters(filtersBar?.sortBy);
 if(filtersBar && (Object.keys(filtersBar).includes('appliedFilters')||Object.keys(filtersBar).includes('startDate'))){
    filtersBar.appliedFilters? setDateRangeFilters(filtersBar.appliedFilters):setDateRangeFilters(filtersBar)
 }
}, [filtersBar]);

  return (
      <>
      <div className='w-100 flexRow alignItemsCenter' key={data.widgetId}>
      <div className='w-100 flexRow alignItemsCenter mt-3 justifyContentSpaceBetween'>
        <div style={{whiteSpace:'nowrap'}} className='mb-3 component-header-space'>{data.title}</div>
 <div className="flexRow alignItemsCenter jusitifyContentSpaceEvenly">
       <img className="icon-charts" src={DownloadVector} alt="Download icon" onClick={onBtExport}/>
        <img className="icon-divider-charts" src={VerticalDivider} alt="|" />
        <span>
        <button className='dashboard-border'>
          <select
          className='dashboard-button-report'
          onChange={changeFilter} value={!chartZoom?filters:filtersChartZoom}>
         	    {durationFilterSelect.map( (item, index) => (
                    <option key={index} value={item.value}>{item.label}</option>
                ))
                }
          </select>
        </button>
        </span>
        </div>

      </div>
    </div>
    <div className="card-content2">
     { !Boolean(Object.keys(responseChart).length)?<Bar
        datasetIdKey={ data.widgetId }
        data={ data }
        options={{...options,plugins:{...options.plugins,datalabels:{display:false}}}}
      />:<Bar
      datasetIdKey={ responseChart.widgetId }
      data={ responseChart }
      options={{...options,plugins:{...options.plugins,datalabels:{display:false}}}}
    />}
    </div>

    <div className="container-fluid sm-scrollbar mt-4">

        <div className=" flexRow justifyContentSpaceEvenly">
          {data.datasets.map((item:any,index:number) => {
            return (
              <div className={`${!chartZoom ? 'chart-label-bar-chart col-lg-3' : 'zoom-container'}`} key={index}>
                <ul className={`${index === 0 || 'chart-label-seperator'}`}>
                  <li className='dashboard-bullet-bar' style={{color:item.backgroundColor}} >
                    <div className="chart-heading-pie" style={{paddingLeft:'4px'}}>{item.label}</div>
                  </li>
                </ul>
              </div>
            )
          })}
           <span className="icon-enlarge">
             {!chartZoom && <img alt='zoom' src={zoom} onClick={()=>setView()} />}
          </span>
        </div>
        </div>
    </>
    )
}
