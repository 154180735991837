/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { DFPAPIEndpointGalley } from "../../../utils/axios";
import { translationLength } from "../../../utils/commonFunctions";
import "../../../assets/styles//styles.scss";
import { useTranslation } from "react-i18next";
import downloadMasterIcon from "../../../assets/icons/download-master.svg";
import plus from "../../../images/icons/plus.png";
import minus from "../../../images/icons/minus.png";
import trolley from "../../../assets/images/wheels.png";
import usePermissions from '../../../utils/userPermissions';
import { saveAs } from "file-saver";
import { useMemo } from "react";

export const TrolleyLoadingAka = (props) => {

  const row28 = {row1: 0,row2: 0,row3: 0,row4: 0,row5: 0,row6: 0,row7: 0,row8: 0,row9: 0,row10: 0,row11: 0,row12: 0,row13: 0,row14: 0,row15: 0,row16: 0,row17: 0,row18: 0,row19: 0,row20: 0,row21: 0,row22: 0,row23: 0,row24: 0,row25: 0,row26: 0,row27: 0,row28: 0};

  const row10 = {
    row1: 0,
    row2: 0,
    row3: 0,
    row4: 0,
    row5: 0,
    row6: 0,
    row7: 0,
    row8: 0,
    row9: 0,
    row10: 0,
  };

  const { t } = useTranslation();
  const galleyIndex = null;
  const savedGalley = { stat: false, galleyIndex };
  const [showGalley, setShowGalley] = useState(false);
  const [enumerateItems, setEnumerateItems] = useState([]);
  const searchKey = "";
  const [staticCountSmu] = useState(10);
  // let staticCount = 28;
  // let staticCountSmu = 10;
  const [status, setStatus] = useState("");
  const [toggleState, setToggleState] = useState(null);
  const [savedTab, setSavedTab] = useState([]);
  const [aircraftOptions, setAircraftOptions] = useState({});
  const [galleyDetails, setGalleyDetails] = useState([]);
  const [galleyTabs, setGalleyTabs] = useState([{}]);
  const [aircraftId, setAircraftId] = useState([{}]);
  const [galleyArr, setGalleyArr] = useState([]);
  const [rackLoadingSelHalf, setRackLoadingSelHalf] = useState([]);
  const [rackLoadingSelFull, setRackLoadingSelFull] = useState([]);
  const [clickTrolley, setClickTrolley] = useState("smu");
  const [airCraftLoadingSel, setAircraftLoadingSel] = useState({
    model: "",
    galley: "",
    businessClass: false,
    economy: false,
    premEconomy: false,
  });
  const [cartsStatus, setCartsStatus] = useState({
    fullCart: 0,
    halfCart: 0,
    smu: 0,
    stowage: 0,
  });

  const { hasPermission: createTrolleyPlan } = usePermissions('create', "configuration.fsc.trolley-loading-plan");
  const { hasPermission: editTrolleyPlan } = usePermissions('edit', "configuration.fsc.trolley-loading-plan");
  const { hasPermission: deleteTrolleyPlan } = usePermissions('delete', "configuration.fsc.trolley-loading-plan");
  const { hasPermission: viewDetailsTrolleyPlan } = usePermissions('view', "configuration.fsc.trolley-loading-plan");

  const [halfRowDetails, setHalfRowDetails] = useState({ half1: row28 });
  const [fullRowDetails, setFullRowDetails] = useState({ full1: row28 });
  const [fullRowDetailsFront, setFullRowDetailsFront] = useState({
    fullfront1: row28,
  });
  const [smuRowDetails, setSmuRowDetails] = useState({ smu1: row10 });

  const [halfDetails, setHalfDetails] = useState({});
  const [fullDetails, setFullDetails] = useState({});
  const [smuDetails, setSmuDetails] = useState({});
  const [, setStowageDetails] = useState({});
  const [indexValue, setIndexValue] = useState({});

  useEffect(() => {
    fetchAirtcraftsType();
    getItemsEnumerate();
  // eslint-disable-next-line no-use-before-define
  }, [])

  useEffect(() => {
    if (aircraftOptions?.galleyNames) {
      setToggleState(aircraftOptions.galleyNames[1]["value"]);
    }
  }, [aircraftOptions?.galleyNames]);

  useEffect(() => {
    if (galleyDetails) {
      const actModel = galleyDetails.reduce(
        (acc, item, index) => [
          ...acc,
          // { key: index + 1, label: `${item.key}`, value:item.model },
          { key: index + 1, label: `${item.model}`, value: `${item.key}` },
        ],
        [{ key: 0, label: "Select Aircraft", value: "" }]
      );

      if (actModel.length) {
        setAircraftOptions({ model: actModel });
      }
    }
  }, [galleyDetails]);

  const filterData = useMemo(
    () => ({
      action: 4,
      collection: "",
      filterOperation: "or",
      filters: [],
      limit: 10,
      page: 1,
      searchParam: "",
    }),
    []
  );

  const fetchAirtcraftsType = useCallback(
    async (page = 1, perPage = 50) => {
      filterData.page = page;
      filterData.limit = perPage;
      filterData.filters = [
        {
          aircraftFor: "akasa",
        },
      ];
      filterData.searchParam = searchKey;
      try {
          const res = await DFPAPIEndpointGalley.post(
        "galley/aircraftTypes/process",
        filterData
      );
      if (res) {
        setGalleyDetails(res.items);
      }
      } catch (error) {

      }

    },
    [filterData]
  );

  const getItemsEnumerate = async () => {
    try {
      const res = await DFPAPIEndpointGalley.get('api/items/enumerate');
    let items = res.items;
    items.unshift({_id: null, itemId: null, itemName: "Select Item", unit: null, itemCode:null});
    if(res){
      setEnumerateItems(items);
    }
    } catch (error) {

    }

  };

  const getGalleyTabs = () => {
    const res = galleyArr.map((gl, index) => {
      let color = "black";
      savedTab.filter((c) => {
        if (c.name === gl.name) {
          c.value ? (color = "green") : (color = "black");
        }
        return true;
      });
      galleyArr[index]["color"] = color;
      return (
        <>
          <button
            key={index}
            style={{ width: "180px", marginTop: ".95rem" }}
            className={
              toggleState === gl.name && !savedGalley.galleyIndex
                ? "tabs active-tabs"
                : toggleState === gl.name && savedGalley.galleyIndex
                ? "tabs active-tabs-save"
                : "tabs"
            }
            onClick={() => toggleTab(gl)}
          >
            <span
              className="dropdownStyle1-TextMedium"
              style={{ color: gl.color }}
            >
              {gl.name}
            </span>
          </button>
          <br />
        </>
      );
    });
    return res;
  };

  const onAircraftChange = (e) => {
    setAircraftLoadingSel((prev) => {
      return { ...prev, model: e.target.value };
    });
    const galleySelected = galleyDetails.find(
      (gl) => gl.key === e.target.value
    );
    if (galleySelected) {
      setGalleyArr(galleySelected.galleys);
      toggleTab(galleySelected.galleys[0]);
      setAircraftId(galleySelected.id);
    }
  };

  const onRackChange = (e) => {
    let stateType = e.target.name === 'racksFull' ? rackLoadingSelFull : rackLoadingSelHalf;
    let rackId = e.target.id;
    let rackVal = e.target.value;
    let member = stateType;

    member.find((ele,ind) =>{
      if(ele.val[0] === rackId){
        if(ele.val[1] !== rackVal){
          member[ind].val[1] = rackVal
        }
      }
      return null
    })
    e.target.name === 'racksFull' ? setRackLoadingSelFull(member) : setRackLoadingSelHalf(member);
    galleyFilter(indexValue,"rack");
  };

  const toggleTab = (index) => {
    setToggleState(index?.name);
    setGalleyTabs(index);
    galleyFilter(index);
    setShowGalley(true);
  };

  const galleyFilter = (index,type) => {
    setIndexValue(index);
    let fullMerge = [];
    let halfMerge = [];
    let smuMerge = [];
    let stowageMerge = [];

    index?.carts?.forEach((element, item) => {
      if (element.cartConfigCount?.typesConfiguredQty > 0) {
        if (element.cartQuantityAllots[0].cartType === "fullCart") {
          element.cartQuantityAllots.map((ele, it) => {
            fullMerge.push({ id: element._id, code: element.code, data: ele });
            return 0;
          });
        } else if (element.cartQuantityAllots[0].cartType === "halfCart") {
          element.cartQuantityAllots.map((ele, it) => {
            halfMerge.push({ id: element._id, code: element.code, data: ele });
            return 0;
          });
        }
        if (element.cartQuantityAllots[0].cartType === "smu") {
          element.cartQuantityAllots.map((ele, it) => {
            smuMerge.push({ id: element._id, code: element.code, data: ele });
            return 0;
          });
        } else {
          stowageMerge.push(element);
        }
        return 0;
      }
    });
    setFullDetails(fullMerge);
    setHalfDetails(halfMerge);
    setSmuDetails(smuMerge);
    setStowageDetails(stowageMerge);

    getGalleyCount(smuMerge, halfMerge, fullMerge, stowageMerge, type);
    stateLoop(smuMerge, halfMerge, fullMerge, stowageMerge);
  };

  const getGalleyCount = (smu, half, full, stowage, type) => {
    let smuCnt = smu.length;
    let halfCnt = half.length;
    let fullCnt = full.length;
    let stowageCnt = 0;
    setCartsStatus({
      fullCart: fullCnt,
      halfCart: halfCnt,
      smu: smuCnt,
      stowage: stowageCnt,
    });
    if(type === undefined){
      let member = [];
      if(member.length === 0){
        let id = 0;
        let type = "";
        half.map((item,ind) => {
          id = item.data._id;
          type = item.data.items.leftColumn[0] === undefined ? "slot" : item.data.items.leftColumn[0]?.rackType;
          member.push({val : [id,type]});
          setRackLoadingSelHalf(member)
          return null
      })
        full.map((item,ind) => {
          id = item.data._id;
          type = item.data.items.leftColumn[0] === undefined ? "slot" : item.data.items.leftColumn[0]?.rackType;
          member.push({val : [id,type]});
          setRackLoadingSelFull(member)
          return null
        })
      }
    }
  };

  const onChangeHandler = (value, type, index, subIndex, code, side) => {
    const ele = document.getElementById(
      "select-" + type + "-" + index + "-" + subIndex + "-" + code + "-" + side
    );
    ele.setAttribute("attrValue", value.value);
    ele.setAttribute("attrName", value.label);
  };

  const refreshTrolleyData = async () => {
    const res = await DFPAPIEndpointGalley.get(
      "/galley/aircraftTypes/" + aircraftId
    );
    if (res) {
      setGalleyArr(res.data.galleys);
      // toggleTab(res.data.galleys[0]);
    }

  };

  let smu = [];
  let half = [];
  let full = [];
  let stowage = [];

  const formatStructure = () => {
    stowage = {
      _id: smuDetails[0]?.id,
      cartQuantityAllots: [],
    };
    if (smu.length === 0) {
      smu = {
        _id: smuDetails[0]?.id,
        cartQuantityAllots: [],
      };
      smuDetails.map((item, index) => {
        smu.cartQuantityAllots.push({
          _id: item.data._id,
          items: {
            leftColumn: [],
            rightColumn: [],
          },
        });
        return 0;
      });
    }
    if (half.length === 0) {
      half = {
        _id: halfDetails[0]?.id,
        cartQuantityAllots: [],
      };
      halfDetails.map((item, index) => {
        half.cartQuantityAllots.push({
          _id: item.data._id,
          items: {
            leftColumn: [],
            rightColumn: [],
          },
        });
        return 0;
      });
    }
    if (full.length === 0) {
      full = {
        _id: fullDetails[0]?.id,
        cartQuantityAllots: [],
      };
      fullDetails.map((item, index) => {
        full.cartQuantityAllots.push({
          _id: item.data._id,
          items: {
            leftColumn: [],
            rightColumn: [],
          },
        });
        return 0;
      });
    }
  };

  const saveTrolleyPlan = async () => {
    formatStructure();
    const ele = document.querySelectorAll('[id^="quantity"]');
    for (let i = 0; i < ele.length; i++) {
      let cart_id = ele[i].getAttribute("code");
      let row = ele[i].getAttribute("row");
      let subrow = ele[i].getAttribute("subrow");
      let cart_type = ele[i].getAttribute("carttype");
      let rack_size = ele[i].getAttribute("rack");
      let side = ele[i].getAttribute("side");

      let quantity;
      let attrVal;

      if (ele[i].value) {
        quantity = ele[i].value;
        const sele = document.getElementById(
          "select-" +
            cart_type +
            "-" +
            row +
            "-" +
            subrow +
            "-" +
            cart_id +
            "-" +
            side
        );
        if (sele.getAttribute("attrValue")) {
          attrVal = sele.getAttribute("attrValue");
        }
        let cart_rack_value = "";
        const rack_ele = document.querySelectorAll('[code^="racks"]');
        for (let i = 0; i < rack_ele.length; i++) {
          let cart_rack_id = rack_ele[i].getAttribute("id");
          if(cart_rack_id === cart_id){
            cart_rack_value = rack_ele[i].value;
            break;
          }
        }

        let cart = {
          itemId: attrVal,
          quantity: Number(quantity),
          row: Number(row),
          subRow: Number(subrow),
          rackSize: rack_size,
          drawerName: "Drawer " + rack_size,
          rackType: cart_rack_value || 'drawer'
        };

        let allot;
        if (cart_type === "smu") {
          allot = smu?.cartQuantityAllots.find((it) => it._id === cart_id);
        } else if (cart_type === "half") {
          allot = half?.cartQuantityAllots.find((it) => it._id === cart_id);
        } else {
          allot = full?.cartQuantityAllots.find((it) => it._id === cart_id);
        }
        side === "left"
          ? allot.items?.leftColumn?.push(cart)
          : allot.items?.rightColumn?.push(cart);
      }
    }

    const body = {
      aircraftTypeId: aircraftId,
      galleyId: galleyTabs._id,
      applyThisCartDesignToAircraft: false,
      applyThisCartDesignToGalley: false,
      carts: [smu, half, full, stowage],
    };
    try {
      const resp = await DFPAPIEndpointGalley.post(
        `galley/trolley/loading/plan`,
        body
      );

      if (resp.success) {
        refreshTrolleyData();
        setStatus(resp.message);
        setTimeout(() => {
          setStatus('');
        }, 3000);
        setSavedTab((current) => [
          ...current,
          { name: galleyTabs.name, value: true },
        ]);
      }
    } catch (error) {
      setStatus(error.data.message||error.message||'Something went wrong!.please try again after sometime.');
    }
  };
  const downloadTroleyPlan = async () => {
    // const res = await DFPAPIEndpointGalley.postWithFullResp('galley/trolley/loading/plan/download', {aircraftTypeId: aircraftId}, {responseType: 'blob'})
    const path = DFPAPIEndpointGalley.getFullPath(
      "galley/trolley/loading/plan/download"
    );
    let headers = DFPAPIEndpointGalley.getHeaders();
    headers = headers.headers;
    // delete headers['Accept']
    // delete headers['Content-Type'];
    try {
      const res = await fetch(path, {
        method: "POST",
        body: JSON.stringify({ aircraftTypeId: aircraftId, rackType: '' }),
        headers: headers,
      });
      const file = await res.blob();
      saveAs(file, `galary-plan.xlsx`);
    } catch (error) {
      console.log(error);
    }
  };
  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#0C5061" : "#fff",
      backgroundColor: state.isSelected ? "#fff" : "#162E3E",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#162E3E",
      padding: "3px",
      width: "40rem",
      border: "0.4px solid #FFFFFF",
      boxShadow: "none",
      fontColor: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
  };

  const customStylesfull = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#0C5061" : "#fff",
      backgroundColor: state.isSelected ? "#fff" : "#162E3E",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#162E3E",
      padding: "3px",
      // width: '17rem',
      border: "0.4px solid #FFFFFF",
      boxShadow: "none",
      fontColor: "#FFFFFF",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
  };

  const onClickTrolley = (e) => {
    setClickTrolley(e);
  };

  const stateLoop = (smu, half, full, stowage) => {
    smu?.map((ele, index) => {
      setSmuRowDetails((_) => {
        return { ..._, ["smu" + index]: row10 };
      });
      ele.data?.items.leftColumn?.forEach((it) => {
        if (it?.subRow > 0) {
          setSmuRowDetails((_) => {
            return {
              ..._,
              ["smu" + index]: {
                ..._["smu" + index],
                ["row" + it?.row]: it?.subRow,
              },
            };
          });
        }
      });
      return 0;
    });

    half?.map((ele, index) => {
      setHalfRowDetails((_) => {
        return { ..._, ["half" + index]: row28 };
      });
      ele.data?.items.leftColumn?.forEach((it) => {
        if (it?.subRow > 0) {
          setHalfRowDetails((_) => {
            return {
              ..._,
              ["half" + index]: {
                ..._["half" + index],
                ["row" + it?.row]: it?.subRow,
              },
            };
          });
        }
      });
      return 0;
    });

    full?.map((ele, index) => {
      setFullRowDetails((_) => {
        return { ..._, ["full" + index]: row28 };
      });
      setFullRowDetailsFront((_) => {
        return { ..._, ["fullfront" + index]: row28 };
      });

      ele.data?.items.leftColumn?.forEach((it) => {
        if (it?.subRow > 0) {
          setFullRowDetails((_) => {
            return {
              ..._,
              ["full" + index]: {
                ..._["full" + index],
                ["row" + it?.row]: it?.subRow,
              },
            };
          });
        }
      });
      ele.data?.items.rightColumn?.forEach((it) => {
        if (it?.subRow > 0) {
          setFullRowDetailsFront((_) => {
            return {
              ..._,
              ["fullfront" + index]: {
                ..._["fullfront" + index],
                ["row" + it?.row]: it?.subRow,
              },
            };
          });
        }
      });
      return 0;
    });
  };

  const trolleyRow = (
    type,
    index,
    row,
    num,
    code,
    finalsave,
    finalsave_right,
    rack_type
  ) => {
    if (type === "half") {
      return (
        <>
          <tr id={code + `-half-` + index}>
            <td className="no-padding">
              {trolleyQuantity(index, 0, code, type, index, finalsave, "left")}
            </td>
            <td className="no-padding">
              {trolleySearch(index, 0, code, type, finalsave, row, "left")}
            </td>
            <td rowSpan={halfRowDetails[row]?.[num] + 1}>{rack_type === 'drawer' ? "Drawer" : ""} {rack_type === 'drawer' ? index : index*0.5}</td>
            <td style={{width:"10%"}}>
              <div
                onClick={() =>
                  setHalfRowDetails((_) => {
                    return {
                      ..._,
                      [row]: {
                        ..._[row],
                        [num]: halfRowDetails[row]?.[num] + 1,
                      },
                    };
                  })
                }
              >
                <img src={plus} className="trolley-plus" alt="" />
              </div>
            </td>
          </tr>
          {[...Array(halfRowDetails[row]?.[num])].map((elementInArray, ind) => {
            return (
              <tr>
                <td className="no-padding">
                  {trolleyQuantity(
                    index,
                    ind + 1,
                    code,
                    type,
                    index,
                    finalsave,
                    "left"
                  )}
                </td>
                <td className="no-padding">
                  {trolleySearch(
                    index,
                    ind + 1,
                    code,
                    type,
                    finalsave,
                    row,
                    "left"
                  )}
                </td>
                <td style={{width:"10%"}}>
                  <div
                    onClick={() =>
                      setHalfRowDetails((_) => {
                        return {
                          ..._,
                          [row]: {
                            ..._[row],
                            [num]: halfRowDetails[row]?.[num] - 1,
                          },
                        };
                      })
                    }
                  >
                  {deleteTrolleyPlan?  <img src={minus} className="trolley-plus" alt="" />:null}
                  </div>
                </td>
              </tr>
            );
          })}
        </>
      );
    } else if (type === "full") {
      return (
        <>
          <tr id={code + `-full-left-` + index}>
            <td className="no-padding">
              {trolleyQuantity(index, 0, code, type, index, finalsave, "left")}
            </td>
            <td className="no-padding">
              {trolleySearch(index, 0, code, type, finalsave, row, "left")}
            </td>
            <td className="no-wrap" rowSpan={fullRowDetails[row]?.[num] + 1}>
            {rack_type === 'drawer' ? "Drawer" : ""} {rack_type === 'drawer' ? index : index*0.5}
            </td>
            <td style={{width:"10%"}}>
              <div
                onClick={() =>
                  setFullRowDetails((_) => {
                    return {
                      ..._,
                      [row]: {
                        ..._[row],
                        [num]: fullRowDetails[row]?.[num] + 1,
                      },
                    };
                  })
                }
              >
              {createTrolleyPlan?  <img src={plus} className="trolley-plus" alt="" />:null}
              </div>
            </td>
          </tr>
          {[...Array(fullRowDetails[row]?.[num])].map((elementInArray, ind) => {
            return (
              <tr>
                <td className="no-padding">
                  {trolleyQuantity(
                    index,
                    ind + 1,
                    code,
                    type,
                    index,
                    finalsave,
                    "left"
                  )}
                </td>
                <td className="no-padding">
                  {trolleySearch(
                    index,
                    ind + 1,
                    code,
                    type,
                    finalsave,
                    row,
                    "left"
                  )}
                </td>
                <td style={{width:"10%"}}>
                  <div
                    onClick={() =>
                      setFullRowDetails((_) => {
                        return {
                          ..._,
                          [row]: {
                            ..._[row],
                            [num]: fullRowDetails[row]?.[num] - 1,
                          },
                        };
                      })
                    }
                  >
                    <img src={minus} className="trolley-plus" alt="" />
                  </div>
                </td>
              </tr>
            );
          })}
        </>
      );
    } else if (type === "fullfront") {
      return (
        <>
          <tr id={code + `-full-right-` + index}>
            <td className="no-padding">
              {trolleyQuantity(
                index,
                0,
                code,
                type,
                index,
                finalsave_right,
                "right"
              )}
            </td>
            <td className="no-padding">
              {trolleySearch(
                index,
                0,
                code,
                type,
                finalsave_right,
                row,
                "right"
              )}
            </td>
            <td
              className="no-wrap"
              rowSpan={fullRowDetailsFront[row]?.[num] + 1}
            >
              {rack_type === 'drawer' ? "Drawer" : ""} {rack_type === 'drawer' ? index : index*0.5}
            </td>
            <td style={{width:"10%"}}>
              <div
                onClick={() =>
                  setFullRowDetailsFront((_) => {
                    return {
                      ..._,
                      [row]: {
                        ..._[row],
                        [num]: fullRowDetailsFront[row]?.[num] + 1,
                      },
                    };
                  })
                }
              >
                <img src={plus} className="trolley-plus" alt="" />
              </div>
            </td>
          </tr>
          {[...Array(fullRowDetailsFront[row]?.[num])].map(
            (elementInArray, ind) => {
              return (
                <tr>
                  <td className="no-padding">
                    {trolleyQuantity(
                      index,
                      ind + 1,
                      code,
                      type,
                      index,
                      finalsave_right,
                      "right"
                    )}
                  </td>
                  <td className="no-padding">
                    {trolleySearch(
                      index,
                      ind + 1,
                      code,
                      type,
                      finalsave_right,
                      row,
                      "right"
                    )}
                  </td>
                  <td style={{width:"10%"}}>
                    <div
                      onClick={() =>
                        setFullRowDetailsFront((_) => {
                          return {
                            ..._,
                            [row]: {
                              ..._[row],
                              [num]: fullRowDetailsFront[row]?.[num] - 1,
                            },
                          };
                        })
                      }
                    >
                      <img src={minus} className="trolley-plus" alt="" />
                    </div>
                  </td>
                </tr>
              );
            }
          )}
        </>
      );
    } else {
      return (
        <>
          <tr id={code + `-smu-` + index}>
            <td className="no-padding">
              {trolleyQuantity(index, 0, code, type, index, finalsave, "left")}
            </td>
            <td className="no-padding">
              {trolleySearch(index, 0, code, type, finalsave, row, "left")}
            </td>
            <td  style={{width:"10%"}} rowSpan={smuRowDetails[row]?.[num] + 1}>{index * 0.5}</td>
            <td style={{width:"10%"}}>
              <div
                onClick={() =>
                  setSmuRowDetails((_) => {
                    return {
                      ..._,
                      [row]: {
                        ..._[row],
                        [num]: smuRowDetails[row]?.[num] + 1,
                      },
                    };
                  })
                }
              >
                <img src={plus} className="trolley-plus" alt="" />
              </div>
            </td>
          </tr>
          {[...Array(smuRowDetails[row]?.[num])].map((elementInArray, ind) => {
            return (
              <tr>
                <td className="no-padding">
                  {trolleyQuantity(
                    index,
                    ind + 1,
                    code,
                    type,
                    index,
                    finalsave,
                    "left"
                  )}
                </td>
                <td className="no-padding">
                  {trolleySearch(
                    index,
                    ind + 1,
                    code,
                    type,
                    finalsave,
                    row,
                    "left"
                  )}
                </td>
                <td style={{width:"10%"}}>
                  <div
                    onClick={() =>
                      setSmuRowDetails((_) => {
                        return {
                          ..._,
                          [row]: {
                            ..._[row],
                            [num]: smuRowDetails[row]?.[num] - 1,
                          },
                        };
                      })
                    }
                  >
                    <img src={minus} className="trolley-plus" alt="" />
                  </div>
                </td>
              </tr>
            );
          })}
        </>
      );
    }
  };

  const trolleyQuantity = (
    index,
    subIndex,
    code,
    type,
    rack,
    finalsave,
    side
  ) => {
    let defvalue;
    finalsave?.map((em) => {
      if (em.cartid === code && em.row === index && em.subRow === subIndex) {
        defvalue = em.quantity;
      }
      return 0;
    });
    return (
      <>
        <input
          key={`quantity-` + type + "-" + index + "-" + subIndex + "-" + code}
          id={`quantity` + code}
          defaultValue={defvalue}
          side={side}
          row={index}
          subrow={subIndex}
          carttype={type}
          code={code}
          disabled={!editTrolleyPlan}
          rack={rack}
          type="number"
          min="0"
          className="edit-input"
          name="quantity"
          attribute="shortName"
        />
      </>
    );
  };

  const trolleySearch = (index, subIndex, code, type, finalsave, row, side) => {
    let defvalue;
    let defcode;
    finalsave?.map((em) => {
      if (em.cartid === code && em.row === index && em.subRow === subIndex) {
        defcode = em.cartid;
        enumerateItems.map((data) => {
          if (data._id === em.itemId) {
            defvalue = { label: data.itemName, value: em.itemId };
          }
          return 0;
        });
      }
      return 0;
    });
    setTimeout(() => {
      if (defcode === code && defvalue !== undefined) {
        const ele = document.getElementById(
          "select-" +
            type +
            "-" +
            index +
            "-" +
            subIndex +
            "-" +
            code +
            "-" +
            side
        );
        if (ele) {
          ele.setAttribute("attrValue", defvalue.value);
          ele.setAttribute("attrName", defvalue.label);
        }
      }
    }, 800);
    clearTimeout();

    return (
      <>
        <Select
          key={
            "select-" +
            type +
            "-" +
            index +
            "-" +
            subIndex +
            "-" +
            code +
            "-" +
            side
          }
          defaultValue={defvalue}
          options={enumerateItems.map((it) => ({
            value: it._id,
            label: it.itemName,
          }))}
          styles={
            type === "full" || type === "fullfront"
              ? customStylesfull
              : customStyles
          }
          isSearchable
          placeholder="Select Item"
          id={
            "select-" +
            type +
            "-" +
            index +
            "-" +
            subIndex +
            "-" +
            code +
            "-" +
            side
          }
          onChange={(val) =>
            onChangeHandler(val, type, index, subIndex, code, side)
          }
        />
      </>
    );
  };

  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
          <div className="row-trolley">
            <div className="column-trolley" style={{ width: "20%" }}>
              <div>
                <label className="req mb-2 margBottom10">
                  Aircraft Model :
                  <br />
                  <div className="margTop8">
                    {aircraftOptions?.model?.length ? (
                      <button className="saleType-div">
                        <select
                          className={`select-items-tr-ak`}
                          onChange={onAircraftChange}
                          value={airCraftLoadingSel?.model}
                          id="saleTypeId"
                        >
                          {aircraftOptions.model?.map((item, index) => (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </label>
              </div>
              <div className="flexRow width100 margBot10">
                <div className="alignItemsCenter">
                  {/* {galleyArr.length? <label className='req mt-4'>Galleys :</label>:<></>} */}
                  <div className="bloc-tabs-trolley">
                    {airCraftLoadingSel.model.length>0&&galleyArr.length ? getGalleyTabs() : <></>}
                  </div>

                  {Boolean(status) ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          marginTop: "1rem",
                        }}
                        className={
                          "confirmation_popup_galley " +
                          (!Boolean(status) ? "alert_error" : "alert_success")
                        }
                      >
                        {status}
                      </div>
                    </>
                  ) : null}

                  <div className="column-trolley" style={{ width: "20%" }}>
                    {airCraftLoadingSel.model.length>0&&showGalley && (
                      <div className="row tab-pane">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-3">
                          <div className="nav-menu-items">
                            <div className="trolley-detail">
                              <button
                                className="upload mt-3 text-center"
                                style={{
                                  maxWidth: "200px",
                                  marginLeft: "40px",
                                  marginRight: "8px",
                                }}
                              >
                                <span>
                                  <u>Galley Details</u>
                                </span>
                              </button>
                              <span
                                className="upload dropdownStyle1-TextMedium"
                                onClick={downloadTroleyPlan}
                              >
                                <img
                                  className="mt-2 icon-size"
                                  alt="uploadItem"
                                  src={downloadMasterIcon}
                                />
                              </span>
                              <ul style={{ marginTop: "0" }}>
                                <div
                                  className={`trolley-bullet ${
                                    clickTrolley === "smu" && "tb-tabs"
                                  }`}
                                  onClick={(e) => onClickTrolley("smu")}
                                >
                                  {cartsStatus?.smu} SMU
                                </div>
                                <div
                                  className={`trolley-bullet ${
                                    clickTrolley === "half" && "tb-tabs"
                                  }`}
                                  onClick={(e) => onClickTrolley("half")}
                                >
                                  {cartsStatus?.halfCart} Half Carts
                                </div>
                                <div
                                  className={`trolley-bullet ${
                                    clickTrolley === "full" && "tb-tabs"
                                  }`}
                                  onClick={(e) => onClickTrolley("full")}
                                >
                                  {cartsStatus?.fullCart} Full Carts
                                </div>
                                {/* <div className='trolley-bullet' onClick={(e)=> onClickTrolley("stowage") }>{cartsStatus?.stowage} Stowage</div> */}
                              </ul>
                            </div>
                          </div>
                        </div>

                        <button
                          style={{
                            width: "170px",
                            maxWidth: "200px",
                            marginLeft: "16px",
                            marginTop: "23px",
                          }}
                          className="done-button subheading-text-medium-title fontLight"
                          onClick={saveTrolleyPlan}
                        >
                          {Boolean(status) ? (
                            <>Saved Successfully</>
                          ) : (
                            <>Save</>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="column-trolley" style={{ width: "80%" }}>
              <div className="container-fluid dashboard-summary-container">
                <div className="container-fluid">
                  <div className="dialog__footer flexRow justifyContentSpaceBetween flexWrap margRight30">
                    <div></div>
                  </div>
                </div>

                <div
                  style={{ position: "relative", marginBottom: "400px" }}
                  id="galleyDesign1"
                >
                  {airCraftLoadingSel.model.length>0&&showGalley && viewDetailsTrolleyPlan && (
                    <div className="container-fluid first">
                      <div
                        style={{ position: "relative", marginBottom: "400px" }}
                        id="galleyDesign"
                      >
                        <div className="container-fluid first">
                          <div className="row tab-pane">
                            {smuDetails.map((elementInArray, indexcart) => {
                              let typeid = smuDetails.id;
                              let code = elementInArray.data._id;
                              let track = indexcart;
                              let hidden =
                                clickTrolley === "smu" ? false : true;

                              let filterSave = [];
                              elementInArray.data.items?.leftColumn.map(
                                (saveitem, saveindex) => {
                                  const newArray = {
                                    ...saveitem,
                                    cartid: code,
                                    typeid: typeid,
                                    track: track,
                                  };
                                  filterSave.push(newArray);
                                  return 0;
                                }
                              );

                              return (
                                <div
                                  hidden={hidden}
                                  className=""
                                  style={
                                    indexcart === 0
                                      ? { marginTop: "10px" }
                                      : { marginTop: "50px" }
                                  }
                                >
                                  <label className="req mb-2">
                                    Standard Container: {elementInArray.code}{" "}
                                  </label>
                                  <div className="dialog__container_item input-container"></div>
                                  <table
                                    id={
                                      `trolley-table-smu` + elementInArray.code
                                    }
                                  >
                                    <tr className="header-white">
                                      <th style={{ width: "3%" }}>Quantity</th>
                                      <th style={{ width: "25%" }}>
                                        Item Name
                                      </th>
                                      <th></th>
                                      <th>Add</th>
                                    </tr>
                                    {[...Array(staticCountSmu)].map(
                                      (element, index) => {
                                        index = index + 1;
                                        let attr = "row" + index;
                                        let row = "smu" + track;
                                        let type = "smu";
                                        return trolleyRow(
                                          type,
                                          index,
                                          row,
                                          attr,
                                          code,
                                          filterSave
                                        );
                                      }
                                    )}
                                  </table>
                                  <div className="mt-3">
                                    Apply this Design :<br />
                                    <input type="checkbox" className="mt-3" />
                                    <span className="mb-2">
                                      {" "}
                                      &nbsp;&nbsp;Aircraft
                                    </span>
                                    <input
                                      type="checkbox"
                                      className="mt-3"
                                      style={{ marginLeft: "2.5rem" }}
                                    />
                                    <span className="mb-2">
                                      {" "}
                                      &nbsp;&nbsp;Galley
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                            {halfDetails.map((elementInArray, index) => {
                              let rackInd = 0;
                              rackLoadingSelHalf.find((e,i) => {
                                  if(e.val[0] === elementInArray.data._id){
                                    rackInd = i;
                                  }
                                  return null
                              })
                              let rackType = rackLoadingSelHalf[rackInd]?.val[1];
                              let finalRackVal = 0;
                              rackType === "drawer" ? finalRackVal = 7 : finalRackVal = 28;

                              let typeid = halfDetails.id;
                              let code = elementInArray.data._id;
                              let track = index;
                              let hidden = clickTrolley === "half" ? false : true;

                              let filterSave = [];
                              elementInArray.data.items?.leftColumn.map(
                                (saveitem, saveindex) => {
                                  const newArray = {
                                    ...saveitem,
                                    cartid: code,
                                    typeid: typeid,
                                    track: track,
                                  };
                                  filterSave.push(newArray);
                                  return 0;
                                }
                              );

                              return (
                                <div
                                  hidden={hidden}
                                  style={
                                    index === 0
                                      ? { marginTop: "10px" }
                                      : { marginTop: "50px" }
                                  }
                                >
                                  <label className="req mb-2">
                                    Half Size Cart: {elementInArray.code} - {rackType}
                                  </label>
                                  <div className="floatRight mb-3">
                                    {aircraftOptions?.model?.length ? (
                                      <button className="margRight30">
                                          <select code="racks" name="racksHalf"  value={rackType} id={code} className="select-item-rack mb-2" onChange={onRackChange}>
                                            <option value="slot">Slot</option>
                                            <option value="drawer">Drawer</option>
                                          </select>
                                      </button>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="dialog__container_item input-container"></div>
                                  <table
                                    id={
                                      `trolley-table-half` + elementInArray.code
                                    }
                                  >
                                    <tr className="header-white">
                                      <th style={{ width: "13%" }}>Quantity</th>
                                      <th style={{ width: "55%" }}>
                                        Item Name
                                      </th>
                                      <th></th>
                                      <th>Add</th>
                                    </tr>
                                    {
                                        [...Array(finalRackVal)].map(
                                          (element, index) => {
                                            index = index + 1;
                                            let attr = "row" + index;
                                            let row = "half" + track;
                                            let type = "half";

                                            return trolleyRow(
                                              type,
                                              index,
                                              row,
                                              attr,
                                              code,
                                              filterSave,
                                              null,
                                              rackType
                                            );
                                          }
                                        )
                                    }
                                  </table>
                                  <img
                                    className="Images"
                                    src={trolley}
                                    alt=""
                                    style={{ width: "34rem" }}
                                  />
                                  <div className="mt-3">
                                    Apply this Design :<br />
                                    <input type="checkbox" className="mt-3" />
                                    <span className="mb-2">
                                      {" "}
                                      &nbsp;&nbsp;Aircraft
                                    </span>
                                    <input
                                      type="checkbox"
                                      className="mt-3"
                                      style={{ marginLeft: "2.5rem" }}
                                    />
                                    <span className="mb-2">
                                      {" "}
                                      &nbsp;&nbsp;Galley
                                    </span>
                                  </div>
                                </div>
                              );
                            })}

                            {fullDetails.map((elementInArray, index) => {
                               let rackInd = 0;
                               rackLoadingSelFull.find((e,i) => {
                                   if(e.val[0] === elementInArray.data._id){
                                     rackInd = i;
                                   }
                                   return null
                               })
                               let rackType = rackLoadingSelFull[rackInd]?.val[1];
                               let finalRackVal = 0;
                               rackType === "drawer" ? finalRackVal = 7 : finalRackVal = 28;

                              let typeid = fullDetails.id;
                              let code = elementInArray.data._id;
                              let track = index;
                              let hidden =
                                clickTrolley === "full" ? false : true;

                              let filterSave = [];
                              elementInArray.data.items?.leftColumn.map(
                                (saveitem, saveindex) => {
                                  const newArray = {
                                    ...saveitem,
                                    cartid: code,
                                    typeid: typeid,
                                    track: track,
                                  };
                                  filterSave.push(newArray);
                                  return 0;
                                }
                              );

                              let filterSave2 = [];
                              elementInArray.data.items?.rightColumn.map(
                                (saveitem, saveindex) => {
                                  const newArray2 = {
                                    ...saveitem,
                                    cartid: code,
                                    typeid: typeid,
                                    track: track,
                                  };
                                  filterSave2.push(newArray2);
                                  return 0;
                                }
                              );

                              return (
                                <div>
                                  <div
                                    classname="full-row"
                                    hidden={hidden}
                                    style={
                                      index === 0
                                        ? { marginTop: "10px", display: "flex" }
                                        : { marginTop: "50px", display: "flex" }
                                    }
                                  >
                                    <div
                                      classname="full-column"
                                      style={{ width: "50%", float: "left" }}
                                    >
                                      <label className="req mb-2">
                                        Full Size Cart: {elementInArray.code} -
                                        Back
                                      </label>
                                      <div className="dialog__container_item input-container"></div>
                                      <table
                                        id={`trolley-table-full-back` + index}
                                      >
                                        <tr className="header-white">
                                          <th style={{ width: "7%" }}>
                                            Quantity
                                          </th>
                                          <th style={{ width: "79%" }}>
                                            Item Name
                                          </th>
                                          <th style={{ width: "7%" }}></th>
                                          <th style={{ width: "7%" }}>Add</th>
                                        </tr>
                                        {[...Array(finalRackVal)].map(
                                          (element, index) => {
                                            index = index + 1;
                                            let attr = "row" + index;
                                            let row = "full" + track;
                                            let type = "full";

                                            return trolleyRow(
                                              type,
                                              index,
                                              row,
                                              attr,
                                              code,
                                              filterSave,
                                              null,
                                              rackType
                                            );
                                          }
                                        )}
                                      </table>
                                    </div>

                                    <div
                                      classname="full-column"
                                      style={{
                                        width: "50%",
                                        marginLeft: "2rem",
                                      }}
                                    >
                                      <label className="req mb-2">
                                        Full Size Cart: {elementInArray.code} -
                                        Front
                                      </label>
                                      <div className="floatRight">
                                        {aircraftOptions?.model?.length ? (
                                        <button className="margRight30">
                                          <select code="racks" name="racksFull"  value={rackType} id={code} className="select-item-rack" onChange={onRackChange}>
                                            <option value="slot">Slot</option>
                                            <option value="drawer">Drawer</option>
                                          </select>
                                        </button>
                                      ) : (
                                      <></>
                                    )}
                                  </div>
                                      <div className="dialog__container_item input-container"></div>
                                      <table
                                        id={`trolley-table-full-front` + index}
                                      >
                                        <tr className="header-white">
                                          <th style={{ width: "7%" }}>
                                            Quantity
                                          </th>
                                          <th style={{ width: "79%" }}>
                                            Item Name
                                          </th>
                                          <th style={{ width: "7%" }}></th>
                                          <th style={{ width: "7%" }}>Add</th>
                                        </tr>
                                        {[...Array(finalRackVal)].map(
                                          (element, index) => {
                                            index = index + 1;
                                            let attr = "row" + index;
                                            let row = "fullfront" + track;
                                            let type = "fullfront";

                                            return trolleyRow(
                                              type,
                                              index,
                                              row,
                                              attr,
                                              code,
                                              filterSave,
                                              filterSave2,
                                              rackType
                                            );
                                          }
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                  {clickTrolley === "full" ? (
                                    <>
                                      <img
                                        className="Images"
                                        src={trolley}
                                        alt=""
                                        style={{ width: "34rem" }}
                                      />
                                      <div className="mt-3">
                                        Apply this Design :<br />
                                        <input
                                          type="checkbox"
                                          className="mt-3"
                                        />
                                        <span className="mb-2">
                                          {" "}
                                          &nbsp;&nbsp;Aircraft
                                        </span>
                                        <input
                                          type="checkbox"
                                          className="mt-3"
                                          style={{ marginLeft: "2.5rem" }}
                                        />
                                        <span className="mb-2">
                                          {" "}
                                          &nbsp;&nbsp;Galley
                                        </span>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              );
                            })}
                            {(clickTrolley === "full" &&
                              cartsStatus.fullCart === 0) ||
                            (clickTrolley === "half" &&
                              cartsStatus.halfCart === 0) ||
                            (clickTrolley === "smu" && cartsStatus.smu === 0) ||
                            (clickTrolley === "stowage" &&
                              cartsStatus.stowage === 0) ? (
                              <div
                                style={{
                                  textAlign: "center",
                                  marginTop: "20%",
                                }}
                              >
                                No Data Available
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      }
      {t("pages").length <= translationLength && (
        <div className="overlay_hide sub"></div>
      )}
    </div>
  );
};