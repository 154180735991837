import React, {useEffect, useRef, useState } from 'react';
import { APIEndpoint, DFPAPIEndpointGalley } from "../../../utils/axios";
import { DataGrid, EditModal, Loading, UploadButton, UploadModal } from "../../../common";
import { onDownloadXlsFile, translationLength } from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import Edit2 from "../../../assets/edit2.svg";
import SettingsIcon from "../../../assets/settings.svg";
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import LoadingGIF from '../../../assets/loadingIn.gif';
import usePermissions from '../../../utils/userPermissions';
const valueGetter = (params) => {
  const { colDef, data } = params;
  if (!data) {
    return '';
  }
  switch (colDef.field) {
    case 'origin':
      return `${data.originName} (${data.origin})`;
    case 'destination':
      return `${data.destinationName} (${data.destination})`;
    default:
      return '';
  }
}

const Columns = [
  { field: 'flightNumber', headerName: 'Flight Number', sortable: true, filter: true, minWidth: 105,flex:1, suppressSizeToFit:true },
  { field: 'aircraftTypeId', headerName: 'AircraftType', sortable: true, filter: true, minWidth: 155,flex:1, suppressSizeToFit:true,
  valueGetter: params => params.data ? params.data?.aircraftTypeDetails?.key : '',
  },
  { field: 'origin', headerName: 'Origin', sortable: true, valueGetter: valueGetter, filter: true, minWidth: 150, flex:1, suppressSizeToFit:true },
  { field: 'destination', headerName: 'Destination', sortable: true, valueGetter: valueGetter, filter: true, minWidth: 150, flex:1, suppressSizeToFit:true },
  { field: 'clusterName', headerName: 'Base Station', sortable: true, filter: true, minWidth: 160,flex:1, suppressSizeToFit:true },

];

const AddFormField = [
  { label: 'Flight Number', attribute: 'flightNumber', type: 'text', required: true, },
  { label: 'AircraftType', attribute: 'aircraftTypeId', type: 'select', required: true, options: 'aircraftType'},
  { label: 'Origin', attribute: 'origin', type: 'select', required: true, options: 'airports' },
  { label: 'Destination', attribute: 'destination', type: 'select', required: true, options: 'airports' },
  { label: 'Base Station', attribute: 'clusterId', type: 'select', required: true, options: 'clusters' },
]

const EditFormField = [
  { label: 'Name', attribute: 'storeName', type: 'text', required: true, disabled: true },
  { label: 'Code', attribute: 'storeCode', type: 'text', required: true, disabled: true },
  { label: 'Base Station', attribute: 'clusterId', type: 'select', required: true, options: 'clusters' },
  { label: 'International', attribute: 'international', type: 'checkbox', disabled: true, className: 'disabled-checkbox' },
]

const DetailsFormField = [
  { label: 'Tax Id', attribute: 'gstin', type: 'text'},
  { label: 'Tax Type', attribute: 'gstType', type: 'radio-split',required:true,disabled:false },
  { label: 'Max Card Amount', attribute: 'maxAmount', type: 'text',  },
]

export const SectorsPage = (props) => {
  const aircraftOptions = useRef(null);
  const clusterOptions = useRef(null);
  const Fields = useRef([]);
  const { t } = useTranslation();
  const [state, setState] = useState({ isListReady: false, filterBase: '', resetDataSource: false, showUpload: false });

  const aircraftTypeOptions = useRef(null);
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [isDownloading, setIsDownloading] = useState(false);
  const [ uploadedFile, setUploadedFile ] = useState([])
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');

  const { hasPermission: editSectors } = usePermissions('edit', "configuration.fsc.sectors");
  const { hasPermission: createSectors } = usePermissions('create', "configuration.fsc.sectors");
  const { hasPermission: uploadSectors } = usePermissions('upload', "configuration.fsc.sectors");
  const { hasPermission: downloadSectors } = usePermissions('download', "configuration.fsc.sectors");


    useEffect(() => {
     fetchDefinedList();
     // eslint-disable-next-line react-hooks/exhaustive-deps
     },[]);
     useEffect(() => {
      const fetchBase=async()=>{
        try {
           const res= await fetchSectors();
          if(res.count){
            setState((currentState) => {
              return { ...currentState,resetDataSource: true };
            });
          }
        } catch (error) {

        }

      }
      fetchBase();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[state.filterBase]);
  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const fetchSectors = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    if (state.filterBase) {
      filterData.filters = [{ cluster: Number(state.filterBase) }]
    }
    try {
    const res = await APIEndpoint.post('routes/process', filterData);
    return res;
    } catch (error) {

    }


  }

  const fetchDefinedList = async () => {
  try{
    const clusters = await APIEndpoint.get('clusters');
    const airports = await APIEndpoint.get('airports');
    const aircraftType = await DFPAPIEndpointGalley.get('galley/aircraftTypes');

    if (airports.items.length) {
      aircraftOptions.current = airports.items.map((_a) => {
        return { value: _a.locationCode, label: `${_a.locationCode} (${_a.locationName})` }
      })
    }
    if (clusters.result.length) {
      clusterOptions.current = clusters.result.map((_c) => {
        return { value: _c.id, label: _c.value }
      })
    }
    if (aircraftType.result.length) {
      aircraftTypeOptions.current = aircraftType.result.map((_c) => {
        return { value: _c.id, label: _c.value }
      })
    }
    Fields.current = getFormFields()
    setState((_) => {
      return { ..._, isListReady: true,resetDataSource:false }
    })


   }catch (error) {
    console.log("fetchDefinedList error",error)
    return null;
  }
 };
  const handleModalClose = () => {
    setState((currentState) => {
      return { ...currentState, showAddForm: false, showUpload: false, editRow: null, detailsRow: null, deleteRows: null };
    });
    setModalFormStatusObj({ text: '' });
  }
  const handleAddSector = () => {
    setState((_) => {
      return { ..._, showAddForm:createSectors? true:false }
    })
  }
  const getFormFields = (aircraftType) => {
    return AddFormField.map((_) => {
      let options = [];
      switch (_.attribute) {
        case 'clusterId':
          options = [...(clusterOptions.current || [])]
          options.unshift({ label: t('pages.pagesContent.sectors.baseStationSelectPlaceholder'), value: '' })
          break;
        case 'origin':
          options = [...(aircraftOptions.current || [])]
          options.unshift({ label: t('pages.pagesContent.sectors.originSelectPlaceholder'), value: '' })
          break;
        case 'destination':
          options = [...(aircraftOptions.current || [])]
          options.unshift({ label: t('pages.pagesContent.sectors.destinationSelectPlaceholder'), value: '' })
          break;
          case 'aircraftTypeId':
            options = [...(aircraftTypeOptions.current || [])]
            options.unshift({ label: t('pages.pagesContent.sectors.aircraftTypeSelectPlaceholder'), value: '' })
            break;
          default:
            console.warn(`Unhandled attribute: `,_.attribute);
            break;
      }
      return { ..._, options: options }
    })
  }
  const handleAddSectorSubmit = async (e, values) => {
    let isValid = true;
    if (values != null) {
      isValid = Fields.current.every(((field) => {
        if (field.required && !values[field.attribute]) {
          setModalFormStatusObj({ error:"Please fill out all required fields" });
          return false;
        } else if ((values.origin === values.destination)) {
          setModalFormStatusObj({ error:"Origin and Destination cannot be same" });
          return false;
        }
        return true
      }));
      if (isValid) {
        values.aircraftType = values.aircraftTypes;
        values.clusterName = Number(values.clusterName);
        try {
          const res = await APIEndpoint.post('route/new', values);
          if (res && res.success) {
            setModalFormStatusObj({ text: res.message });
            const timeId = setTimeout(() => {
              setModalFormStatusObj({ text: '' });
              setState(prevState => ({
                ...prevState,
                resetDataSource: true,
                showAddForm: false,
                editRow: false
              }));
            }, 1600)
            return () => clearTimeout(timeId);
          } else {
            setModalFormStatusObj({ error: res.message });
          }
        } catch (error) {
          let {data}=JSON.parse(error.message);
          setModalFormStatusObj({error:data?.message})
        }
      }
    }
  }
  const handleSelectBaseStation = (e) => {
    setState((_) => {
      return { ..._,filterBase: e.target.value, resetDataSource: false, showAddForm: false, editRow: null }
    });
  }
  const handleRowAction = async (e, action, row) => {
    let editRow = null, detailsRow = null;
    if (action.value === 'edit' && editSectors) {
      const store = await APIEndpoint.get('store/' + row.storeId);
      if (store.success) {
        editRow = store.result;
        if (!editRow) {
          editRow = { storeCode: '', storeName: '', international: false, clusterId: '' }
        }
        editRow.storeId = row.storeId
      } else {
        alert('Unable to fetch Store')
      }
    } else {
      detailsRow = JSON.parse(JSON.stringify(row))
    }
    setState((currentState) => {
      return { ...currentState, editRow: editRow, detailsRow: detailsRow,resetDataSource:false }
    })
  }
  const handleEditSectorSubmit = async (e, values) => {
    setModalFormStatusObj({ text: '' });
    if (values != null) {
      if (values.clusterId !== '') {
        values = {
          ...state.editRow,
          ...values,
          clusterName: Number(values.clusterName),
          activePayments: []
        }
        try {
          const res = await APIEndpoint.post('route/update/' + state.editRow.storeId,  values  );
          if (res && res.success) {
            setModalFormStatusObj({ text: res.msg });
          }
        } catch (error) {
          setModalFormStatusObj({ error: error.data.msg||"Something went wrong!" });
        }
      }
    }else{
      try {
         const res = await APIEndpoint.post('route/update/' + state.editRow.storeId,  state.editRow  );
        if (res && res.success) {
          setModalFormStatusObj({ text: res.msg });

        }
      } catch (error) {
        setModalFormStatusObj({ error: error?.data?.msg ||"Something went wrong!"});
      }
    }
    setTimeout(() => {
      setModalFormStatusObj({ text:'' });
      setState((currentState) => {
        return { ...currentState, editRow: null,resetDataSource:true }
      })
      // handleSelectBaseStation({ target: { value: state.filterBase } });
    }, 1600);
  }
  const handleSaveStore = async (e, values) => {

      const data=
        {
          storeId: state?.detailsRow?.storeId,
          indentEnabled: false,
          dailyStockReset: false,
          enableSubcategories: false,
          inventoryType: "servings",
          maxCardAmount:values?.maxAmount?Number(values.maxAmount):null,
          gstType:values?.inclusiveGst?'inclusive':values?.exclusiveGst?'exclusive':null,
          gstIn:values?.gstin||null
      }

    const modData=Object.fromEntries(Object.entries(data).filter(([key,value])=>(value!==null&&value!=="")))

        try {
          const res = await APIEndpoint.post('store/services', modData);
          if (res.success) {
            setModalFormStatusObj({text:'Successfully saved store settings'})
            const timeId = setTimeout(() => {
              setModalFormStatusObj({ text: '' });
              setState(prevState => ({
                ...prevState,
                resetDataSource: true,
                showAddForm: false,
                detailsRow:false,
                editRow: false
              }));
            }, 1600);

            return () => clearTimeout(timeId);
          }

        } catch (error) {
          setModalFormStatusObj({error:error.data.msg||'some error happened'})

        }


  }

  const handleUploadSector = async (e) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0]);
    return APIEndpoint.post('routes/upload', formData, { 'Content-Type': "multipart/form-data" })
  }

  const onUploadClose = () => {
    setUploadedFile([]);
    setUploadFileStatusBlock('');
    setState((_) => ({ ..._, showUpload: false }))
  }

  const onUploadFileChangeHandler = async (e) => {
    const file=e.target.files[0];
    setUploadedFile(e.target.files[0]);
    setUploadFileStatusBlock('<p>Uploading...</p>');

    const formData = new FormData();
    formData.append('file', file);
    try {
      const res = await APIEndpoint.post(`routes/upload`, formData);
      setUploadedFile([]);
      setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
    } catch (error) {
      e.target.value = null;
      setUploadedFile([]);
      let { status, data } = JSON.parse( error.message );
      const msgHeading = data?.Error || data?.message || error.message;
      let msgContent = "";
      if (status === 400 && data?.errors) {
            data.errors.forEach( _ => {
            const lineNum = _.lineNumber;
            const errorMsgArr = [];
            _.errors.forEach(erObj => {
              errorMsgArr.push( erObj.field?erObj.field:"" + ' | ' + erObj.message + ' \n<br>' );
            });

            msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
        });
      } else if(status===0 && !data){
        msgContent += `Error occurred while uploading sector!<br>Please try again.`;
    }
      else {
        msgContent += `Error occurred while uploading sector!<br>Please fix the errors and re-upload.`;
      }

      setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                              );
    }
};


  const onDownloadSampleFile = async (e) => {
    onDownloadXlsFile('samples/downloads/Routes_Upload_Sample', 'Routes_Upload_Sample')
  }

  const onDownloadSectors=async()=>{
    let header={
      'Accept':'text/csv'
    }
    try {
      setIsDownloading(true)
    const res= await APIEndpoint.post(`downloads/routes`,{reportType: "sectors"},header);
    if(res.status==='scheduled'){
      setTimeout(() => {
        const dowId = APIEndpoint.get(`downloads/status/${res.id}`);
        dowId.then((val)=> {
          if(val.status==="completed"){
            APIEndpoint.get(`downloads/analytics/${val.fileName}${val.fileExtension}`).then((ItemMasterDwnld) => {
              const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', val.fileName+'.csv'); //or any other extension
              document.body.appendChild(link);
              link.click();
              setIsDownloading(false)
          });

          }
        })
        }, 6000);
    }
    }
      catch (res) {
        console.log(res.message)
        setIsDownloading(false)
      }
  }

  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        Boolean(state.isListReady) ?
        <>
      { uploadSectors && downloadSectors? <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
            <UploadButton
              onClick={() => setState((_) => ({ ..._, showUpload: true }))}
              label={<span><u>{t('pages.pagesContent.sectors.uploadBtn')}</u></span>} />
            <button className='download dropdownStyle1-TextMedium' onClick={onDownloadSectors}><span>{isDownloading?<img src={LoadingGIF} alt='loading' className='cell-loading' />:<img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} />}<u className={`download__banner_${isDownloading ? "active" : ""}`}>{isDownloading?t('filters.downloadingMaster'):t('filters.downloadsectors')}</u></span></button>
          </div>:<></>}
          <DataGrid
            columnResize
            resetDataSource={state.resetDataSource}
            showDefaultFilters
            className='full-flex'
            columns={Columns}
            pageType={false}
            getRows={fetchSectors}
            addButton={{
              title: t('pages.pagesContent.sectors.addButtonTitle'),
              onClick: handleAddSector,
              className: 'margRight'

            }}
            RightComponent={() => {
              return (
                <button className='saleType-div m-2'><select className='select-item darkBlue-bg' onChange={handleSelectBaseStation} value={state.filterBase} id="saleTypeId">
                  <option value="">{t('pages.pagesContent.sectors.clusterSelectPlaceholder')}</option>
                  {clusterOptions.current.map((item, index) => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))
                  }
                </select></button>

              )
            }}
            actions={[
              { detail: [{ src: Edit2, value: "edit",title:'Edit' }, { src: SettingsIcon, value: 'settings',title:'Settings' }] }
            ]}
            onAction={handleRowAction}
          />
          {
            state.showAddForm &&
            <EditModal
              title={t('pages.pagesContent.sectors.addModalTitle')}
              modalFormStatusObj={modalFormStatusObj}
              onClose={handleModalClose}
              onSubmit={handleAddSectorSubmit}
              fields={Fields.current}
            />
          }
          {
            Boolean(state.editRow) &&
            <EditModal
              title={t('pages.pagesContent.sectors.editModalTitle')}
              modalFormStatusObj={modalFormStatusObj}
              onClose={handleModalClose}
              onSubmit={handleEditSectorSubmit}
              fields={EditFormField.map((elt) => {
                return {
                  ...elt,
                  initialValue: state.editRow[elt.attribute],
                  options: elt.attribute === 'clusterId' ? Fields.current[4].options : []
                }
              })
              }
            />
          }
        </>
      :<Loading/>}
      {
        state.showUpload &&
        <UploadModal
          modelTitle={t('pages.pagesContent.sectors.uploadBtn')}
          onUploadClose={onUploadClose}
          onDownloadSampleFile={onDownloadSampleFile}
          onUploadFileChangeHandler={onUploadFileChangeHandler}
          uploadedFile={uploadedFile}
          uploadFileStatusBlock={uploadFileStatusBlock}

        />
      }
      {
        Boolean(state.detailsRow) &&
        <EditModal
          buttonLabel={t('pages.pagesContent.sectors.btnSaveStoreSettings')}
          title={t('pages.pagesContent.sectors.updateStoreSettings')}
          onClose={handleModalClose}
          onSubmit={handleSaveStore}
          modalFormStatusObj={modalFormStatusObj}
          fields={DetailsFormField.map((elt) => {
            return {
              ...elt,
              initialValue: state.detailsRow[elt.attribute]
            }
          })
          }
        />
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}