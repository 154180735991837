import { FaArrowRight,FaCopy } from 'react-icons/fa6';
import { copyToClipBoard } from '../../../../utils/commonFunctions';

const getSectorDetails = (row) => {
    if (row) {
      
      return (
        <>
          { row.flightNumber ?? '' } | { row.origin ?? ''} <FaArrowRight color={'DC143C'} size={16} /> { row.destination ?? ''}
        </>
      )
    }
    return '';
};

export const SectorRenderer = (props) => {
    const cellValue = getSectorDetails(props.data);// props.valueFormatted ? props.valueFormatted : props.value;
    return (
        <div className="sector-renderer">
            {cellValue}
        </div>
    );
};

export const OrdNoRenderer = (props) => {
  if(props.data&&props.data?.orderNo){
    return (
      <div className="orderNo-renderer">
           <span>{props.data?.orderNo}</span>
           <button title='Copy to clipboard' onClick={(e)=>copyToClipBoard(e,props.data?.orderNo)} className='margLeft5'>
             <FaCopy/>
           </button>
       </div>
   );
  }else
  return (
     null
  );
};