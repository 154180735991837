/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import '../../../assets/styles/modal.scss'
import closeIcon from '../../../images/icons/Icon2.png'
import Select from 'react-select';
import { customStyles } from '../../../utils/configs';


export const GalleyLoad = (props) => {
const {editGalley,trolleyName,airlines,displayItems,cartOptions,setCartOptions,cartsGalleyLoading,trolleyItems,imageGalley,tab}=props;
  const [state,setState]=useState({cartItems:[],cartType:"",cartOptions});
  const [selectedOption, setSelectedOption] = useState(null);
  const [galleycartsConfig,setGallyCartsConfig]=useState({});
  const [cartInfo,setCartInfo]=useState("");

useEffect(() => {
    setState((prev)=>{return {...prev,cartItems:selectedOption,cartType:state.cartType?state.cartType:getCartType()}});
}, [selectedOption])

useEffect(() => {
  hasEmptyKeys(state)
}, [state])

useEffect(() => {
  if(cartsGalleyLoading){
    setGallyCartsConfig(cartsGalleyLoading.find(f=>f.index===tab))
  }
}, [cartsGalleyLoading])


useEffect(() => {
  const {cartType,id,code,typesOfItems}=editGalley;
  if(galleycartsConfig && editGalley && trolleyName===id&&galleycartsConfig?.index===tab){
    setState({code,cartItems:typesOfItems,cartType})
    setSelectedOption(typesOfItems)
  }
}, [editGalley,tab,galleycartsConfig])

useEffect(() => {

  if(trolleyName &&imageGalley){
    trolleyFinder(trolleyName,imageGalley);
  }
}, [trolleyName])

const getButtonStatus=()=>{
  if ((Array.isArray(state.cartItems)&& state.cartItems.length) && state.cartType.length > 0) {
    return false;
  }

  return true;
}


const newStylesViewItems = {
  control: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: "#162E3E",
    padding: "3px",
    color: "#ffff",
    border: "0.4px solid #FFFFFF",
    boxShadow: "none",
    PointerEvent: 'none',
  })
};

const getCartType=()=>{
  let cType="";
if(cartOptions&&cartOptions==='0'){
  cType='stowage'
}
if(cartOptions&&cartOptions==='1'){
  cType='smu'
}
return cType

}

const cartTypeListBottom = [
    {key: 0, label: "Select Cart Type", value: ''},
    {key: 1, label: "Full Cart", value: 'fullCart'},
    {key: 2, label: "Half Cart", value: 'halfCart'},
  ]

  // const cartTypeListTop = [
  //   {key: 0, label: "Select Cart Type", value: ''},
  //   {key: 1, label: "SMU", value: 'smu'},
  //   {key: 2, label: "Stowage", value: 'stowage'},
  // ]

  const hasEmptyKeys=()=>{
    if (!state?.cartType || !state?.cartItems?.length) {
      return true;
    } else {
      return false;
    }
  }
const trolleyFinder=(trolleyName,imageGalley)=>{
if(airlines==='pal'){
  if(imageGalley.includes('galleyMain')||imageGalley.includes('galley5')||imageGalley.includes('galley6')){
    if(trolleyName && /box[1-7]$/.test(trolleyName)){
      setCartOptions("1")
    }
    else if(trolleyName && /box11$/.test(trolleyName) &&imageGalley.includes('galley5')){
      setCartOptions("0")
    }
    else if(trolleyName && /box12$/.test(trolleyName)){
      setCartOptions("0")
    }
    else{
      setCartOptions("2")
    }
}
if(imageGalley.includes('galley4')){
  if(trolleyName && /box[1-5]$/.test(trolleyName)){
    setCartOptions("1")
  }
  else if(trolleyName && /box[6]$/.test(trolleyName)){
    setCartOptions("0")
  }
  else{
    setCartOptions("2")
  }
}
}
if(airlines==='akasa'){
 if(imageGalley.includes('galley1akasa')){
  if(trolleyName && /^box(?:38|50)$/.test(trolleyName)){
    setCartOptions("0")
  }
    else if(trolleyName &&/^box([2][4-9]|[3][0-9]|[4][0-1]|4[7-9]|39)$/.test(trolleyName)
    ){
      setCartOptions("1")
    }
    else if(trolleyName &&/^box(?:4[2-6])$/.test(trolleyName)){
      setCartOptions("2")
    }
    else{

    }
}

if(imageGalley.includes('galley2akasa')){
  if(trolleyName && /box(5[1-7])$/.test(trolleyName)  ){
    setCartOptions("1")
  }
  else if(trolleyName && /box(5[8-9]|60)$/.test(trolleyName)){
    setCartOptions("2")
  }
  else{
    setCartOptions("0")
  }
}

if(imageGalley.includes('galley3akasa')){
  if(trolleyName && /box(62|63)$/.test(trolleyName)){
    setCartOptions("1")
  }
  else if(trolleyName && /box(66|65)$/.test(trolleyName)){
    setCartOptions("2")
  }
  else{
    setCartOptions("0")
  }
}

}





}

const selectOptionChange=(e,data)=>{
if(e){
    setState((prev)=>{return {...prev,cartType:e.target.value}});
}
}

  return (
    <div className="overlay_galley sm-scrollbar" role="dialog">
    <div className="dialog_galley_load">
    <div className="dialog__content sm-scrollbar" >
      <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />

 { !displayItems.length? <><h2 className="dialog__title subheading-text-medium fontMedium">{`${props.title} (${cartOptions==='1'?"SMU":cartOptions==='0'?'Stowage':"Full Cart/Half Cart"})`}</h2>
      <hr />
      <div className='flexCol flexWrap alignItemsCenter justifyContentCenter'>
    <div className="dialog__body_container flexCol alignItemsCenter">
          <div className="flexCol justifyContentCenter">
          <div className=" dialog__container_item input-container margTop24">
            <div className="dialog__description">
            <label className="req margBottom10">Items</label>
          <Select
                defaultValue={selectedOption}
                value={trolleyItems.filter(function(option) {
                  return selectedOption&&selectedOption.find(sc=>sc.name===option.value)
                })}
                onChange={setSelectedOption}
                options={trolleyItems||[]}
                styles={customStyles}
                isMulti
                isSearchable
                placeholder="Choose Items"
              />

            </div>
          </div>
         { cartOptions&&cartOptions==='2'? <div className="flexCol justifyContentCenter">
          <div className=" dialog__container_item input-container margTop24">
            <div className="dialog__description">
            <label className="req">Cart Type</label>
              <select className='select-input-galley1' name='cartType' onChange={(e)=>selectOptionChange(e,null)} value={state.cartType}>
          {
            cartTypeListBottom.map((_, index) => {
                return (
                  <option value={_.value} key={index}>{_.label}</option>
                )
            })
          }
        </select>
            </div>
          </div>
          </div>:<></>
}

      </div>
        </div>
      </div>




    <div className="dialog__footer flexCol alignItemsCenter">
      <button className="done-button" disabled={hasEmptyKeys()} onClick={ ()=>props.onSubmit({...state,...galleycartsConfig,cartOptions})}>Done</button>
    </div></> :<>
    <h2 className="dialog__title subheading-text-medium fontMedium">{`${props.title} (${cartOptions==='1'?"SMU":cartOptions==='0'?'Stowage':"Full Cart/Half Cart"})`}</h2>
      <hr />
      <div className='flexCol flexWrap alignItemsCenter justifyContentCenter'>
    <div className="dialog__body_container flexCol alignItemsCenter">
          <div className="flexCol justifyContentCenter">
          <div className=" dialog__container_item input-container margTop24">
            <div className="dialog__description">
            <label className="req margBottom10">Items</label>
          <Select
                value={trolleyItems.filter(function(option) {
                  return displayItems&&displayItems.find(sc=>sc.props.children===option.value)
                })}
                styles={{...newStylesViewItems, indicatorsContainer: (provided) => ({
                  ...provided,
                  display: 'none',
                }), option: (provided, state) => ({
                  ...provided,
                  color: state.isDisabled ? '#fff' : '#fff',
                })}}

                isMulti
                isDisabled={true}
                placeholder="Choose Items"
              />

            </div>
          </div>

      </div>
        </div>
      </div>

    </> }

      {Boolean(cartInfo) ?
      <>
        <div className={ "dialog__statusBlock " + (false ? 'alert_error' : 'alert_success') }>
          {cartInfo}
        </div>
      </> : null }
    </div>

    </div>

    </div>
  );
}

export const GalleyLoadingModal = (props) => {

const [cartOptions,setCartOptions]=useState(null);

  const onClose = (e) => {
    removeContainerOverlay();
    props.onClose();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <GalleyLoad
     title={props.title}
      onClose={onClose}
      airlines={props.airlines}
      trolleyName={props.trolleyName}
      galleyCarts={props.galleyCarts}
      setGalleyCarts={props.setGalleyCarts}
      editGalley={props.editGalley}
      tab={props.tab}
      trolleyItems={props.trolleyItems}
      imageGalley={props.imageGalley}
      displayItems={props.displayItems}
      setCartOptions={setCartOptions}
      cartsGalleyLoading={props.cartsGalleyLoading}
      cartOptions={cartOptions}
      galleyCartsDet={props.galleyCartsDet}
      setModalFormStatusObj={props.setModalFormStatusObj}
      onSubmit={props.onSubmit}
      >
    </GalleyLoad>
  )
}
