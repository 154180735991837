import React, { useState } from 'react'

export const Collapseble = (props) => {
  const { Header, className } = props;
  const [open, setOpen] = useState(props.open || false)
  const handleHeaderClick = () => {
    setOpen(!open);
  }
  return (
    <div className={`flexCol collapsable ${className || ''}`}>
      <div className='flexCol' onClick={handleHeaderClick}>
        {Header}
      </div>
      <div className={`flexCol container ${open ? 'open' : ''}`}>
        <div className='flexCol content'>
          {props.children}
        </div>
      </div>
    </div>
  );
}