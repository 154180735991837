export const mealTypeList = [
    { key: '', value: '', label: ' -- Select-- ' },
    { key: 'Appetiser', value: 'Appetiser', label: 'Appetiser' },
    { key: 'Mains', value: 'Mains', label: 'Mains' },
    { key: 'Snack Mains', value: 'Snack Mains', label: 'Snack Mains' },
    { key: 'Snacks', value: 'Snacks', label: 'Snacks' },
    { key: 'Dessert', value: 'Dessert', label: 'Dessert' },
    { key: 'Special Meal', value: 'Special Meal', label: 'Special Meal' }
];

export const mealCategoryList = [
    { key: '', value: 'Select Type', label: ' -- Select-- ' },
    { key: 'Breakfast', value: 'Breakfast', label: 'Breakfast' },
    { key: 'Lunch', value: 'Lunch', label: 'Lunch' },
    { key: 'Dinner', value: 'Dinner', label: 'Dinner' }
];

export const cabinList = [
    { key: '', value: 'Select Cabin Type', label: ' -- Select-- ' },
    { key: 'fc', value: 'fc', label: 'First Class', 'systemCode': 'fc' },
    { key: 'bcl', value: 'bcl', label: 'Business Class', 'systemCode': 'bc' },
    { key: 'pcy', value: 'pcy', label: 'Premium Economy', 'systemCode': 'pc' },
    { key: 'ecy', value: 'ecy', label: 'Economy Class', 'systemCode': 'ecy' }
];

export const sectorResToSelectOptions = ( rows ) => {
    const selData = [{ key: '', value: 'Select Sector', label: ' -- Select-- ' }];
    rows.forEach( ( row, index) => {
        selData.push({ key: index, name: row.storeCode, value: row.storeId, label: row.storeCode })
    });
    return selData;
}
export const caterersResToSelectOptions = ( rows ) => {
    const selData = [{ key: '', value: 'Select Caterer', label: ' -- Select-- ' }];
    rows.forEach( ( row, index) => {
        selData.push({ key: index, value: row.id, name: row.name + ' ' + row.name2, label: row.name + ' ' + row.name2 })
    });
    return selData;
}
export const warehouseResToSelectOptions = ( rows ) => {
    const selData = [{ key: '', value: 'Select Catering station', label: ' -- Select-- ' }];
    rows.forEach( ( row, index) => {
        selData.push({ key: index, value: row.id, name: row.value, label: row.value })
    });
    return selData;
}