import { useTranslation } from 'react-i18next';
import '../assets/styles/modal.scss'

import closeIcon from '../images/icons/Icon2.png'
import { useState } from 'react';
import { UserEndpoint } from '../utils/axios';

export const ForgotPasswordModal = (props) => {
    const { t } = useTranslation();

    const [ username, setUsername ] = useState('');
    const [ APIResp, setAPIResp ] = useState({ isExecuted: false, success: false, message: '' });
    const [errors, setErrors] = useState({ username: '' });
    
    const onCloseDialog = () => {
        setUsername('');
        setAPIResp({ isExecuted: false, success: false, message: '' });
        setErrors({ username: '' })
        props.clearForgotPwd();
    }


    const handleForgotPwdForm = async (e) => {
        e.preventDefault();
        setAPIResp({ isExecuted: false, success: false, message: '' });

        const submitBtnObj = document.getElementsByClassName('continue-button')[0];
        
        if ( !username ) {
            setErrors({...errors, username: 'Enter username'});
            return false;
        }
        
        const payload = {
            username: username
        };


        submitBtnObj.setAttribute('disabled', 'true');
        await UserEndpoint.post('password/reset', payload)
        .then( (resp) => {
            submitBtnObj.removeAttribute('disabled');
            setAPIResp({ isExecuted: true, success: true, message: 'We have sent reset password details to your registered email.' }); //resp.msg
            setTimeout( () => {
                onCloseDialog();
            }, 6000 );
        })
        .catch( (err) => {
            submitBtnObj.removeAttribute('disabled');

            let statusObj = JSON.parse(err.message);
            let msg = 'Error occured while submitting your request.';
            if (statusObj.status === 400) {
                msg = statusObj.data.msg;
            }
            setAPIResp({ isExecuted: true, success: false, message: msg });
        });

        console.log('Forgot pwd form data:', payload);
        return false;
    };

    const onUsernameChange = (e) => {
        setUsername( e.target.value );
        setAPIResp({ isExecuted: false, success: false, message: '' });

        if ( !e.target.value ) {
            setErrors({...errors, username: 'Enter username'});
        } else {
            setErrors({...errors, username: ''});
        }
    }

    return <>
    { props.data.show === true && 
        <>
            <div className="overlay2" role="dialog">
                <div className="dialog2 sm-scrollbar">
                    <form onSubmit={ (e) => handleForgotPwdForm(e)}>

                        <div className="dialog__header pt-4">
                            <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={ () => onCloseDialog() } />
                            <h2 className="dialog__title subheading-text-medium fontMedium">{t('pages.loginContent.forgotPasswordDg.title')}</h2>
                            <hr />
                        </div>

                        <div className="dialog__content mt-0">
                            <div className="dialog__body_container flexRow justifyContentSpaceBetween flexWrap">

                                <div className="flexCol justifyContentSpaceEvenly ">
                                    <div className=" dialog__container_item input-container">
                                        <div className="dialog__description">
                                            <label className="req">{t('pages.loginContent.forgotPasswordDg.enterUsername')}:</label>
                                            <input type="text" className="edit-input" name="username" attribute="username" placeholder={t('pages.loginContent.forgotPasswordDg.enterUsername')} value={username} onChange={ (e) => onUsernameChange(e) } />
                                            {errors.username ? <>
                                                <div className='validationMsgDiv error'>{errors.username}</div>
                                            </> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </div>

                            {APIResp.isExecuted === true ?
                            <>
                                <div className={ "dialog__statusBlock mt-0 " + ( !APIResp.success ? 'alert_error' : 'alert_success') }>
                                { APIResp.message }
                                </div>
                            </> : null }
                            
                        <div className="dialog__footer flexRow justifyContentSpaceEvenly pt-2 mb-4">
                            <button type="button" className="cancel-button" onClick={ () => onCloseDialog() }>{t('buttons.cancel')}</button>
                            <button type="submit" className="continue-button">{t('buttons.proceed')}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    } 
  </>  
};