import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AuditEnquiry } from './inner-components/AuditEnquiry'

const AdminPage = () => {
  return (
    <div className="flexRow full p-0 m-0">
      <div className='full-flex flexCol width100 p-0 m-0'>
        <Routes>
          <Route path="/auditEnquiry" element={<AuditEnquiry />} />
        </Routes>
      </div>
    </div>
  )
}

export default AdminPage