/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from "react";
import { APIEndpoint } from "../../../utils/axios";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";

const Columns = [
  { field: 'tailNumber',headerName: 'Sector',flex:1 },
  { field: 'tailNumber',headerName: 'Tail Number',flex:1, minWidth: 200 },
  { field: 'flightDate', headerName: 'Flight Date',flex:1, minWidth: 135,valueGetter: params => {
    return params.data ? moment(params.data?.flightDate).format('DD-MM-YYYY') : ''; }},
  { field: 'offloadedAt',headerName: 'Offloaded At',flex:1, minWidth: 135 ,valueGetter: params => {
    return params.data ? moment(params.data?.offloadedAt).format('DD-MM-YYYY') : ''; }},
  { field: 'offloadedBy',headerName: 'Offloaded By',flex:1, minWidth: 155 },
  { field: 'paymentSummary', headerName: 'Payment',flex:1, minWidth: 125 },
];

export const Calenderoffload = (props) => {
  const {group}=props
  const [calenderOffload, setCalenderOffload] = useState([]);
  const {group:{sectors} } = props
  const sectorIds = sectors.map(sector=>{
    return sector.store.id
  })

 useEffect(() => {
    if(sectorIds.length > 0 ){
      sectorIds.forEach(id => {
       fetchSectorList(id)
      });
    }
  }, [])

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };
 

 async function fetchSectorList(storeId) {
    filterData.filters = []
    filterData.filters.push({ storeId: storeId });
    let flightDate = moment(group.dateUTC).startOf('day').toString();
    filterData.filters.push({ flightDate: flightDate });
    try {
        const res =await APIEndpoint.post('offloadSummary/get',filterData);
        setCalenderOffload(prev=>[...prev,...res.items])
    } catch (error) {
        console.log(error)
    }
  }

 return(
    <div className='op-aircraft-container ng-grid-right flexCol full-flex ag-theme-alpine-dark' style={{overflowY:'scroll',height:"75vh"}}>
    <>
      <div className="table-header"></div>
        <AgGridReact
          id='ngo-grid'
          suppressCellFocus
          className='ngo-grid'
          columnDefs={Columns}
          rowData={calenderOffload.length?calenderOffload : []}
          suppressRowTransform={true}
        />
    </>
    </div>
  )
}