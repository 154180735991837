import { ORDER_PAYMENT_TYPE_LIST } from "../../../../utils/configs";
import { FaFile, FaMoneyBill1, FaRegCreditCard, FaList } from 'react-icons/fa6';

const getOrderPaymentType = (params) => {
  if (params) {
    const paymentType = params.data.paymentTypeRaw;
    if (paymentType) {
      const ptObj = ORDER_PAYMENT_TYPE_LIST.find( _ => _.key === paymentType );
      if (ptObj) {
        return (
          <>
          { ptObj.value === 'SSR' && <FaFile color={ ptObj.colorCode_text ?? '' } size={16}/> }
          { ptObj.value === 'Cash' && <FaMoneyBill1 color={ ptObj.colorCode_text ?? '' } size={16}/> }
          { ptObj.value === 'Card' && <FaRegCreditCard color={ ptObj.colorCode_text ?? '' } size={16}/> }
          { ptObj.value === 'Multiple' && <FaList color={ ptObj.colorCode_text ?? '' } size={16}/> }
            &nbsp;&nbsp;<span >{ ptObj.value }</span>
          </>
        )
      }
    }
    return paymentType;
  }
}

export const PaymentTypeRenderSel = (props) => {
    let dd = props.data ? getOrderPaymentType(props) : null;
    return (
        <div className="paymentType-renderer">
            {dd}
        </div>
    );
};