
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import '../../../assets/styles/modal.scss'
import '../../../assets/styles/inner/_hierarchy.scss'
import { DFPAPIEndpoint, DFPAPIEndpointGalley ,APIEndpoint} from '../../../utils/axios'
import { validationMenuPlanner } from '../../../utils/configs';
import {NGODatePicker} from '../../../common'
import plus from '../../../images/icons/plus.png';
import minus from '../../../images/icons/minusgaly.png';
import LoadingGIF from '../../../assets/loadingIn.gif';
import closeIcon from '../../../images/icons/Icon2.png';
import Select from 'react-select'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const AddMenuPl = props => {
const {options,data}=props
const{selected}=options
const { t } = useTranslation();
const [sectors,setSectors]=useState(options?.sectors)
const [airCrafts,setAircrafts]=useState(options?.aircrafts)
const [sectorTypes,setSectorTypes]=useState(options?.sectorTypes)
const [caterers,setCaterers]=useState([])
const [warehouses,setWarehouses]=useState(options?.warehouses)
const [mealMenu,setMealMenu]=useState(options?.menuSettings)
const [mealMenuEdit,setMealMenuEdit]=useState([]);
const [state,setState]=useState({applyToAll:false,overrideMenu:false,cateringStation:[]});
const [catererLoading,setCatererLoading]=useState(false);
const [caterersMenu,setCaterersMenu]=useState([]);
const [caterer,setCaterer]=useState([])
const [aircraft, setAircraft] = useState()
const [sectorType,setSectorType]=useState()
const [saving, setSaving] = useState(false)
const [res, setRes] = useState([])
const [mealList, setMealList] = useState([])
const [modalFormStatusObj,setModalFormStatusObj]=useState({text:""})
const [createJSON, setCreateJSON] = useState({})
const [removeMeals,setRemoveMeals]=useState({})
const [mealTypeGroup,setMealTypeGroup]=useState([]);
const [errorSaving, setErrorSaving] = useState(false)
const [inputChanged, setInputChanged] = useState(false);
const sType = localStorage.getItem('sType');
const [mealId, setmealId] = useState(null);
const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);
const [mealSetsContBreakfast, setmealSetsContBreakfast] = useState([{ id: 1 }]);

const customNoOptionsMessage = () => {
  if(state?.sourceStation&&state.sourceStation.length>0){
    return 'No Warehouse Found'
  }
  else{
    return "Please Select a Sector"
  }
};


const mealsItems = (cat, des) => {
  const { mealTypeCode, items } = des;
  let options = [];
  let uniqueItemNames = [];

  if (mealMenu) {
    mealMenu?.forEach((m) => {
      if (m.mealCategoryCode ||m.mealCategoryId === cat) {
        m.mealsSelection.forEach((meal, index) => {
          if (meal.mealType.toLowerCase() === mealTypeCode) {
            meal.items.forEach((itm) => {
              if (!uniqueItemNames.includes(itm.itemName) && items.some((ml) => itm.itemName === ml.itemName)) {
                options.push({
                  key: options.length + 1,
                  label: itm.itemName,
                  value: itm.itemId,
                });
                uniqueItemNames.push(itm.itemName);
              }
            });
          }
        });
      }
    });
  }

  return options;
};
const getMealEditAl=(m)=>{
const mTy= mealTypeGroup.find(mt=>mt.value===m.id);
return [mTy]
}

const getMealEditAlClass=(k)=>{
  const mTy= options?.mealMenuClass?.find(mt=>mt.value===k.id);
  return [mTy]
}


useEffect(() => {
  if(options&&mealMenuEdit.length>0){
    const alcoholicCategoryIds = ["alcoholicBeverages"];
    const nonAlcoholicCategoryIds = ["nonAlcoholic"];

      const organizedMeals = {};
      mealMenuEdit.forEach((meal) => {
      const { mealCategoryCode } = meal;


      if(mealCategoryCode==="continentalBreakfast"){

      meal.mealsSelection.forEach(ml=>{
      if(ml.mealTypeCode==='appetizer'){
      const options=mealsItems(mealCategoryCode,ml)
      const key = `contApp${meal.mealCategoryId.split("-")[1]}`;
      const key2 = `mealName${meal.mealCategoryId.split("-")[1]}`;
      const key3 = `mealTypeGroup${meal.mealCategoryId.split("-")[1]}`;
      const key4 = `class${meal.mealCategoryId.split("-")[1]}`;


      setState((prev) => ({
      ...prev,
      [key]:[...options],
      [key2]:meal.mealName,
      [key3]:getMealEditAl(meal.mealTypeGroup),
      [key4]:getMealEditAlClass(meal.classCode),
      }));
      }
      if(ml.mealTypeCode==='main'){
      const options=mealsItems(mealCategoryCode,ml)
      const key = `contMain${meal.mealCategoryId.split("-")[1]}`;
      const key2 = `mealName${meal.mealCategoryId.split("-")[1]}`;
      const key3 = `mealTypeGroup${meal.mealCategoryId.split("-")[1]}`;
      const key4 = `class${meal.mealCategoryId.split("-")[1]}`;

      setState((prev) => ({
      ...prev,
      [key]:[...options],
      [key2]:meal.mealName,
      [key3]:getMealEditAl(meal.mealTypeGroup),
      [key4]:getMealEditAlClass(meal.classCode),
      }));
      }

      if(ml.mealTypeCode==='dessert'){
      const options=mealsItems(mealCategoryCode,ml)
      const key = `contDes${meal.mealCategoryId.split("-")[1]}`;
      const key2 = `mealName${meal.mealCategoryId.split("-")[1]}`;
      const key3 = `mealTypeGroup${meal.mealCategoryId.split("-")[1]}`;
      const key4 = `class${meal.mealCategoryId.split("-")[1]}`;

      setState((prev) => ({
      ...prev,
      [key]:[...options],
      [key2]:meal.mealName,
      [key3]:getMealEditAl(meal.mealTypeGroup),
      [key4]:getMealEditAlClass(meal.classCode),
      }));
      }
      if(ml.mealTypeCode==='beverages'){
        const options=mealsItems(mealCategoryCode,ml)
        const key = `beverages${meal.mealCategoryId.split("-")[1]}`;
        const key2 = `mealName${meal.mealCategoryId.split("-")[1]}`;
        const key3 = `mealTypeGroup${meal.mealCategoryId.split("-")[1]}`;
        const key4 = `class${meal.mealCategoryId.split("-")[1]}`;

        setState((prev) => ({
        ...prev,
        [key]:[...options],
        [key2]:meal.mealName,
        [key3]:getMealEditAl(meal.mealTypeGroup),
        [key4]:getMealEditAlClass(meal.classCode),
        }));
        }
      })
      }


      if (!organizedMeals[mealCategoryCode]) {
      organizedMeals[mealCategoryCode] = [];
      }
      organizedMeals[mealCategoryCode].push(meal);
      });
      setState((prev) => ({
        ...prev,
        ...organizedMeals,
      }));
    mealMenuEdit.forEach((category) => {
      const mealCategoryCode = category.mealCategoryCode;
      if (alcoholicCategoryIds.includes(mealCategoryCode)) {
        category.mealsSelection.forEach((mealSelection) => {
          handleBeverages(mealSelection.items.map((item, index) => ({
            key: index,
            label: item.itemName,
            value: item.itemId,
          })), {name:mealSelection.mealTypeCode});

        });
      }
      else if (nonAlcoholicCategoryIds.includes(mealCategoryCode)) {
        category.mealsSelection.forEach((mealSelection) => {
          handleBeverages(mealSelection.items.map((item, index) => ({
            key: index,
            label: item.itemName,
            value: item.itemId,
          })), {name:mealSelection.mealTypeCode});
        });

      }
    });
  }

}, [mealMenuEdit,options])


  useEffect(()=>{
    setSectors(options?.sectors)
    setAircrafts(options?.aircrafts)
    setSectorTypes(options?.sectorTypes)
    setWarehouses(options?.warehouses)
    setMealMenu(options?.menuSettings)
    setMealTypeGroup(options?.mealTypesGroup)
    if(selected.action!=='createMenuPlan'&&options){
      getEditFields(selected);
      setMealMenuEdit(options.selected?.menuSetting);
    }
  },[options, selected])

  useEffect(()=>{
    if(options&&mealMenuEdit.length>0&&!Object.keys(removeMeals).length&& !inputChanged){
      for (let i in state) {
        if (i === 'continentalBreakfast' && Array.isArray(state[i])) {
          let k=[]
          state[i].forEach(ml=>{
           k.push(parseInt(ml.mealCategoryId.split("-")[1]))
          })
          const newMealSets = Array.from({ length: Math.max(...k) }, (_, index) => ({
            id: index + 1,
          }));
          const numbersArray = Object.keys(state)
          .map(key => parseInt((key.match(/\d+/)||[])[0]))
          .filter((value, index, self) => self.indexOf(value) === index);

          setmealSetsContBreakfast(newMealSets.filter(item => numbersArray.includes(item.id)));
        }

      }
    }
  },[state])


  useEffect(()=>{
  if(Object.values(removeMeals).length){
    const id = removeMeals.id.toString();

    for (let key in state) {
      if (key.endsWith(id)) {
        delete state[key];
      }
  }

  }},[removeMeals])



  useEffect(()=>{
    if(selected&&selected.action==='editMenuPlan'&&caterers.length){
      const catR= caterers?.find(cx => cx?.value===selected?.catererDetails?._id);
      setState((prevState) => {
        return { ...prevState,caterer:[catR]};
        });
    }
   },[caterers,selected])


  useEffect(()=>{
   if(state&& state?.sourceStation){
    fetchVendorMappings();
   }
  },[state?.sourceStation])

   const getEditFields= useCallback(
    (edField)=>{
      if(edField){
      let catrStn;
const sectType=sectorTypes.find(sx=>edField.sectorType.id===sx.value);
      const souRc=sectors?.find(sx=>sx.label===edField.sectorDetails?.value)
      const catrStnMain=data?.warehouse?.find(wx=>wx.itemId===Number(edField.cateringStation))?.warehouseName;
      if(catrStnMain&&catrStnMain.length){
         catrStn=warehouses.find(wx=>wx.label===catrStnMain)
      }
      const axCt=airCrafts.find(ax=>ax.value===edField.aircraftTypeId)
      const cLs=options.mealMenuClass?.find(ml=>ml.value===edField.cabin)
        setState((prevState) => {
        return { ...prevState,menuName:selected?.menuPlanName,applyToAll:selected?.applyMenuToSectorsFromThisSource,overrideMenu:selected?.overrideOtherMenuPlans,sourceStation: [souRc] ,sectorType:[sectType],cateringStation:[catrStn],aircraftType:[axCt],class:[cLs],fromDate:new Date(edField.fromDate),toDate:new Date(edField.toDate)};
        });
          }
        },
    [airCrafts,caterers, data?.warehouse, options.mealMenuClass, sectors,selected?.menuPlanName, selected?.overrideOtherMenuPlans,selected?.applyMenuToSectorsFromThisSource, warehouses],
  )


    const filterData = useMemo(() => ({
      action: 4,
      collection: '',
      filterOperation: 'or',
      filters: [],
      limit: 2000,
      page: 1,
      searchParam: ''
  }), []);
const fetchVendorMappings = useCallback(
  async (page=1, perPage=3000) => {
    if(state&&state.cateringStation?.length>0){
    setCatererLoading(true);
    filterData.page = page;
    filterData.limit = perPage;
    filterData.searchParam = null;
    filterData.filters=[{warehouseId: state?.cateringStation[0].key}];
    const res = await DOMAIN.post('vendorMappings', JSON.stringify(filterData));
    if(res.items){
      let vendorDetails=res.items[0]?.listName||""
      setCaterers([]);
      const options=[]
      setCaterersMenu(res.items)
      if (res.items.length > 0) {
        res.items.forEach((fieldValue, index) => {
          if (res.items.length > 1 && index !== 0 && fieldValue.listName === vendorDetails) {
            return;
          }else{
            options.push({ key: index + 1, label: `${fieldValue.vendorName} ${fieldValue.vendorCode}`, value: fieldValue._id });
          }
        });
      }

    setCaterers(options)
    setCatererLoading(false);
    }
            }
        },
  [DOMAIN, filterData, state],
)
;
  const newCustomStyles = {

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#162E3E",
      padding: "3px",
      border: "0.4px solid #FFFFFF",
      boxShadow: "none",
      PointerEvent: 'none',
    })
  };


  const preCompile = {
   contBreakFast:t('pages.configurationPALSub.addMenuPlanner.continentalBreakfast'),
   breakFast:t('pages.configurationPALSub.addMenuPlanner.breakFast'),
   applyMenu:t('pages.configurationPALSub.addMenuPlanner.applyMenu'),
   beverages:t('pages.configurationPALSub.addMenuPlanner.beverages'),
   appetizer:t('pages.configurationPALSub.addMenuPlanner.appetizer'),
   main:t('pages.configurationPALSub.addMenuPlanner.main'),
   dessert:t('pages.configurationPALSub.addMenuPlanner.dessert'),
   morningsSnack:t('pages.configurationPALSub.addMenuPlanner.morningsSnack'),
   lunch:t('pages.configurationPALSub.addMenuPlanner.lunch'),
   eveningSnack:t('pages.configurationPALSub.addMenuPlanner.eveningSnack'),
   dinner:t('pages.configurationPALSub.addMenuPlanner.dinner'),
   supper:t('pages.configurationPALSub.addMenuPlanner.supper'),
   alcoholicBeverages:t('pages.configurationPALSub.addMenuPlanner.alcoholicBeverages'),
   nonAlcoholicBeverages:t('pages.configurationPALSub.addMenuPlanner.nonAlcoholicBeverages'),
   whisky:t('pages.configurationPALSub.addMenuPlanner.alcoholicBeveragesList.whisky'),
   brandy:t('pages.configurationPALSub.addMenuPlanner.alcoholicBeveragesList.brandy'),
   vodka:t('pages.configurationPALSub.addMenuPlanner.alcoholicBeveragesList.vodka'),
   cognac:t('pages.configurationPALSub.addMenuPlanner.alcoholicBeveragesList.cognac'),
   whiteRum:t('pages.configurationPALSub.addMenuPlanner.alcoholicBeveragesList.whiteRum'),
   darkRum:t('pages.configurationPALSub.addMenuPlanner.alcoholicBeveragesList.darkRum'),
   beer:t('pages.configurationPALSub.addMenuPlanner.alcoholicBeveragesList.beer'),
   redWine:t('pages.configurationPALSub.addMenuPlanner.alcoholicBeveragesList.redWine'),
   whiteWine:t('pages.configurationPALSub.addMenuPlanner.alcoholicBeveragesList.whiteWine'),
   sparklingWine:t('pages.configurationPALSub.addMenuPlanner.alcoholicBeveragesList.sparklingWine'),
   fortifiedWine:t('pages.configurationPALSub.addMenuPlanner.alcoholicBeveragesList.fortifiedWine'),
   liquers:t('pages.configurationPALSub.addMenuPlanner.alcoholicBeveragesList.liquers'),
   freshJuice:t('pages.configurationPALSub.addMenuPlanner.nonAlcoholicBeveragesList.freshJuice'),
   cannedJuice:t('pages.configurationPALSub.addMenuPlanner.nonAlcoholicBeveragesList.cannedJuice'),
   cannedSmoothies:t('pages.configurationPALSub.addMenuPlanner.nonAlcoholicBeveragesList.cannedSmoothies'),
   energyDrinks:t('pages.configurationPALSub.addMenuPlanner.nonAlcoholicBeveragesList.energyDrinks'),
   softDrinks:t('pages.configurationPALSub.addMenuPlanner.nonAlcoholicBeveragesList.softDrinks'),
   infusedWater:t('pages.configurationPALSub.addMenuPlanner.nonAlcoholicBeveragesList.infusedWater'),
   sparklingWater:t('pages.configurationPALSub.addMenuPlanner.nonAlcoholicBeveragesList.sparklingWater'),
   water:t('pages.configurationPALSub.addMenuPlanner.nonAlcoholicBeveragesList.water')
  }

  let handleSource = data => {
  setInputChanged(true);

    let createj = createJSON
    createj.origin = data
    setCreateJSON(createj)
    setWarehouses(options.warehouses?.filter(wr=>wr.value===data.value))
    if(options.warehouses?.filter(wr=>wr.value===data.value).length>0){
     setState((_)=>{return {..._,cateringStation:[options.warehouses?.filter(wr=>wr.value===data.value)[0]],caterer:[]}})
    }

    setState((_)=>{return {..._,sourceStation:[data]}})
  }

  let handleMealType =( data) => {
  setInputChanged(true);

    let createj = createJSON
    createj.class = data
    setCreateJSON(createj)
    setState((_)=>{return {..._,[`mealTypeGroup${mealId}`]:[data]}})
  }

  let handleClass = data => {
  setInputChanged(true);

    let createj = createJSON
    createj.class = data
    setCreateJSON(createj)
    setState((_)=>{return {..._,[`class${mealId}`]:[data]}})
  }


  let handleAircraftType = data => {
  setInputChanged(true);

    setAircraft(data)
    let createj = createJSON
    createj.aircraft = { id: data.value, key: data.label }
    setCreateJSON(createj)
    setState((_)=>{return {..._,aircraftType:[data]}})

  }
  let handleSectorType = data => {
  setInputChanged(true);

    setSectorType(data)
    let createj = createJSON
    createj.sectorType = { id: data.value, key: data.label }
    setCreateJSON(createj)
    setState((_)=>{return {..._,sectorType:[data]}})

  }

  let handleCateringStation = data => {
  setInputChanged(true);

    setState((_)=>{return {..._,cateringStation:[data],caterer:[]}})
  }

  let handleCaterer = data => {
  setInputChanged(true);

    setState((_)=>{return {..._,caterer:[data]}})

    setCaterer(data)
    let meals = []
    res.forEach((itm) => {
      if (itm.vendor.code === data.value) {
        meals.push({ label: itm.itemName, value: itm.itemCode });
      }
    });
    setMealList(meals)
    let createj = createJSON
    createj.caterer = data.value
    setCreateJSON(createj)
  }



  const updateMealCategory = (key, mealCategoryCode, mealCategoryLabel, mealTypeId, mealType, data) => {
    return setState((prevState) => {
      const existingMealsSelection = (prevState[mealCategoryCode]?.mealsSelection || []).filter(
        (meal) => meal.mealTypeCode !== mealTypeId
      );

      existingMealsSelection.push({
        mealTypeCode:mealTypeId,
        mealTypeId:`${mealTypeId}-${key[key.length-1]}`,
        mealType,
        items: data.map((d) => ({
          itemId: d?.value || "",
          itemName: d?.label || "",
          quantity: 1,
        })),
      });
    const newMealsSelection = existingMealsSelection.filter((meal) => meal.items);
    const existingMenuCategories = prevState[mealCategoryCode]?prevState[mealCategoryCode].filter(mn=>mn.mealsSelection.length>0):[];

    switch (mealCategoryCode) {
    case 'continentalBreakfast':
    mealSetsContBreakfast.forEach((ml) => {
    if (key.includes(ml.id.toString())) {
    const mealCategoryId = mealCategoryCode + "-" + ml.id;
    const existingIndex = existingMenuCategories.findIndex(item => item.mealCategoryId === mealCategoryId);
    let meals = existingIndex !== -1 ? existingMenuCategories[existingIndex].mealsSelection.slice() : [];
    meals.push(...newMealsSelection);

        const latestMealsMap = {};
      for (const meal of meals) {
        const mealTypeCode = meal.mealTypeCode;
        latestMealsMap[mealTypeCode] = meal;
      }
      meals = Object.values(latestMealsMap);

        if (existingIndex !== -1) {

          existingMenuCategories[existingIndex] = {
            mealCategoryCode,
            mealCategoryId,
            mealCategoryLabel,
            mealsSelection:meals.filter(ml=>ml.items.length>0),
          };
        } else {

          existingMenuCategories.push({
            mealCategoryCode,
            mealCategoryId,
            mealCategoryLabel,
            mealsSelection:meals.filter(ml=>ml.items.length>0),
          });
        }
      }
    });
    break;
  default:
    break;
}
      return {
        ...prevState,
        [key]: data,
        [mealCategoryCode]: existingMenuCategories
      };
    });
  };

  let handleContinentalBreakfast =( data,level) => {
  setInputChanged(true);
    updateMealCategory(level, "continentalBreakfast", "Continental Breakfast", "appetizer", "Appetizer", data);
  }

  let handleContinentalMain = (data,level) => {
  setInputChanged(true);

    updateMealCategory(level, "continentalBreakfast", "Continental Breakfast", "main", "Main", data);
  }

  let handleContinentalDessert = ( data,level) => {
  setInputChanged(true);

    updateMealCategory(level,"continentalBreakfast", "Continental Breakfast", "dessert", "Dessert", data);
  }

  let handleBeveragesMain = ( data,level) => {
  setInputChanged(true);

    updateMealCategory(level,"continentalBreakfast", "Continental Breakfast", "beverages", "Beverages", data);
  }

  let onChangeInputFromDate = e => {
  setInputChanged(true);

    setState((_)=>{return {..._,fromDate:e.target.value}})
  }

  let onChangeInputToDate = e => {
  setInputChanged(true);

    setState((_)=>{return {..._,toDate:e.target.value}})
  }

  const handleApplyToAll=()=>{
    setState((_)=>{return {..._,applyToAll:!state.applyToAll}})
  }
  const handleOverrideMenu=()=>{
  setInputChanged(true);

    setState((_)=>{return {..._,overrideMenu:!state.overrideMenu}})
  }



  const updateMenuSelection = (prevState, mealTypeCode, mealTypeLabel, data, mealCategory) => {
  setInputChanged(true);

    const existingMealsSelection = (prevState[mealCategory]?.mealsSelection || []).filter(
      (meal) => meal.mealTypeCode !== mealTypeCode
    );

    const newMealSelection = {
      mealTypeId:`${mealTypeCode}-1`,
      mealTypeCode,
      mealType: mealTypeLabel.charAt(0).toUpperCase()+mealTypeLabel.slice(1),
      items: data.map((d) => ({
        itemId: d?.value || "",
        itemName: d?.label || "",
        quantity: 1,
      })),
    };


    const newMenuContDessert = {
      mealCategoryId: mealCategory === "alcoholicBeverages" ? "alcoholicBeverages-1" : "nonAlcoholicBeverages-1",
      mealCategoryLabel: mealCategory === "alcoholicBeverages" ? "Alcoholic-Beverages" : "Non-Alcoholic Beverages",
      mealCategoryCode: mealCategory === "alcoholicBeverages" ? "alcoholicBeverages" : "nonAlcoholic",
      mealsSelection: [...existingMealsSelection, newMealSelection],
    };
    const bevArray = [...(prevState[mealCategory] || []), newMenuContDessert];

    const consolidatedCategories = {};

  bevArray.forEach((mealCategory) => {

    const { mealCategoryCode } = mealCategory;

    if (!consolidatedCategories[mealCategoryCode]) {
      consolidatedCategories[mealCategoryCode] = { ...mealCategory };
    } else {
      const existingMealTypeIds = consolidatedCategories[mealCategoryCode].mealsSelection.map(
        (meal) => meal.mealTypeId
      );

      mealCategory.mealsSelection.forEach((mealSelection) => {
        const existingIndex = existingMealTypeIds.indexOf(mealSelection.mealTypeId);

        if (existingIndex !== -1) {
          consolidatedCategories[mealCategoryCode].mealsSelection[existingIndex] = mealSelection;
        } else {
          consolidatedCategories[mealCategoryCode].mealsSelection.push(mealSelection);
        }
      });
    }
  });
  Object.values(consolidatedCategories).forEach((category) => {
    category.mealsSelection = category.mealsSelection.filter((meal) => meal.items.length > 0);
  });
    return {
      ...prevState,
      [mealTypeCode]: data,
      [mealCategory]: (Object.values(consolidatedCategories)),
    };
  };

  const handleBeverages = useCallback(
     (data, details) => {
      switch (details.name) {
        case 'whisky':
        case 'brandy':
        case 'vodka':
        case 'cognac':
        case 'whiteRum':
        case 'darkRum':
        case 'beer':
        case 'redWine':
        case 'whiteWine':
        case 'sparklingWine':
        case 'fortifiedWine':
        case 'liquers':
          setState((prevState) =>
            updateMenuSelection(prevState, details.name, details.name.replace(/([A-Z])/g, ' $1').trim(), data, "alcoholicBeverages")
          );
          break;

        case 'freshJuice':
        case 'cannedJuice':
        case 'cannedSmoothies':
        case 'energyDrinks':
        case 'softDrinks':
        case 'infusedWater':
        case 'sparklingWater':
        case 'water':
          setState((prevState) =>
            updateMenuSelection(prevState, details.name, details.name.replace(/([A-Z])/g, ' $1').trim(), data, "nonAlcoholic")
          );
          break;

        default:
          break;
      }
    },
    [],
  )



const getSectors=(s,inc=null)=>{
  if(s&&s.length&&!inc){
    const sObj=data.sectors?.find(ds=>ds?.value===s[0]?.label)?.destination
    return sObj;
  }
  else if(s&&s.length&&inc==='sectorId'){
    const sObj=data.sectors?.find(ds=>ds?.value===s[0]?.label).id
    return sObj;
  }
  else{
    const sObj=data.sectors?.find(ds=>ds?.value===s[0]?.label)
    return sObj;
  }
}
const getCateringStation=(s,id=null)=>{
if(s&&!id){
  const sObj= data.warehouse.find(ds=>ds.warehouseName===s[0]?.label)?.itemId
  return sObj
}
else{
  const sObj= data.warehouse.find(ds=>ds.warehouseName===s[0]?.label)
  return sObj
}

}
const getCaterer=(s,inc=null)=>{
  if(s&&!inc){
    const sObj=caterersMenu?.find(ct=>ct._id===s)?.vendorId
    return sObj
  }
  else{
    const sObj=caterersMenu?.find(ct=>ct._id===s)
    return sObj
  }

}
const getAircraft=(s)=>{
  const sObj=data.aircraft.find(ct=>ct.value===s[0]?.label)?.id
  return sObj

}

const handleMenuName=(e)=>{
  const {value,name}=e.target
  setInputChanged(true);

setState((_)=>{return{..._,[name]:value}})
}

const handleMealName = (e) => {
  setInputChanged(true);

  const {value,name}=e.target;
  setState((_)=>{return{..._,[name]:value}})
};




const handleReset = () => {
  let timerId = setTimeout(() => {
    props.onClose();
    setModalFormStatusObj({ text: '' });
    clearTimeout(timerId);
  }, 2000);
};
const checkFieldsValidity=()=>{
  const allReqFields=validationMenuPlanner.every(field=>state.hasOwnProperty(field))
  const allKeys=Object.keys(state);
  const remKeys=allKeys.filter(ak=>!validationMenuPlanner.includes(ak))
  const allArraysNotEmpty = remKeys.some(key => {
    const value = state[key];
    return Array.isArray(value) && value.length > 0;
  });

if(allArraysNotEmpty&&remKeys.length>2&&allReqFields){
  return true
}
else{
  return false
}
}
  let saveMenuDetails = async() => {
if(1){

  const menuPlanData= {
    "menuPlanName":state.menuName,
    "sectorId": 2978,
            "sectorDetails": {
                "id": "2978",
                "value": "024 - BLR - BOM",
                "origin": "BLR",
                "flightNumber": "024",
                "destination": "BOM",
                "originName": "Bengaluru",
                "destinationName": "Mumbai",
                "_id": "65d9056487362d335eaa3397"
            },
            "sourceStation": "BLR",
            "destinationStation": "BOM",
            "cateringStation": 3,
            "cateringStationDetails": {
                "country": "India",
                "gstIn": "29AACCG2599K1Z1",
                "stateName": "Karnataka",
                "stateCode": "KA",
                "warehouseAddress": "ROOM NO.04, & 181, BENGALURU INTERNATIONAL AIRPORT LIMITED DEVANHALI, BANGLORE - 560300",
                "warehouseCode": "BLRWH",
                "warehouseName": "Bangalore Warehouse",
                "clusterId": "6",
                "itemId": "3",
                "cluster": {
                    "_id": "652e4fbb98e3fe92ec46016b"
                },
                "_id": "65d9056487362d335eaa3395"
            },
            "caterer": "94dc1836-ddb8-4f3f-a2c9-32d735411b53",
            "catererDetails": {
                "listName": "VendorMappings-1658230020271",
                "itemId": 52,
                "itemCode": "HOTMEAL11001002",
                "itemType": "Product",
                "itemName": "Nissin Cup Noodles Hot Manchow (NOVG)",
                "vendorId": "94dc1836-ddb8-4f3f-a2c9-32d735411b53",
                "vendorCode": "INFPL(BOM)",
                "vendorName": "Indo Nissin Foods",
                "gstType": "SGST",
                "hsn": "190230",
                "dateCreated": "2022-07-19T11:27:01.042Z",
                "dateModified": "2022-07-19T11:27:01.042Z",
                "cess": 0,
                "utgst": 6,
                "igst": 12,
                "sgst": 6,
                "cgst": 6,
                "costPrice": 30,
                "_id": "62d695055310893c0d9cde0a",
                "deleted": false
            },
            "sectorType": {
              "id": state.sectorType[0]?.value,
              "label": state.sectorType[0]?.label
          },
    "fromDate": moment(state.fromDate).format('YYYY-MM-DD[T]HH:mm:ss'),
    "toDate":moment(state.toDate).format('YYYY-MM-DD[T]HH:mm:ss'),
    "aircraftTypeId": getAircraft(state.aircraftType),
    "cabin": "fc",
    "applyMenuToSectorsFromThisSource": state.applyToAll,
    "overrideOtherMenuPlans": state.overrideMenu,
    "menuSetting":[]
}
  for (const key in state) {

    const createObjectsArray = (data) => {
      const objectsArray = [];

      for (const key in data) {
        if (key.startsWith('mealName')) {
          const index = key.match(/\d+/)[0];
          const mealName = data[`mealName${index}`];
          const mealTypeGroup = data[`mealTypeGroup${index}`][0];
          const classObj = data[`class${index}`][0];

          const obj = {
            mealName,
            mealTypeGroup:{
              "id": mealTypeGroup.value,
              "label": mealTypeGroup.label
          },
            classCode: {
              "id": classObj.value,
              "label": classObj.label
          },


          };
          const continentalBreakfastKey = `continentalBreakfast-${index}`;
          const continentalBreakfast = data.continentalBreakfast.filter(item => item.mealCategoryId === continentalBreakfastKey);
          obj.continentalBreakfast = continentalBreakfast.length > 0 ? continentalBreakfast[0] : null;


          objectsArray.push(obj);
        }
      }

      return objectsArray;
    };


    const result = createObjectsArray(state);
    const menuSettingsAi=result?.map(obj => {
      const continentalBreakfastData = obj.continentalBreakfast;
      return {
          mealName: obj.mealName,
          mealTypeGroup: obj.mealTypeGroup,
          classCode: obj.classCode,
          mealCategoryId: continentalBreakfastData.mealCategoryId,
          mealCategoryCode: continentalBreakfastData.mealCategoryCode,
          mealCategoryLabel: continentalBreakfastData.mealCategoryLabel,
          _id: "65689b25cdb7e99190183f0f",
          mealsSelection: continentalBreakfastData.mealsSelection
      };
  })
  menuPlanData.menuSetting=menuSettingsAi

}

// const mealsToArray = (key, dataArray) => {
//   if (key in state) {
//     const keyArray = [];
//     state[key].forEach((item) => {
//       keyArray.push(item);
//     });
//     dataArray.push(...keyArray);
//   }
// };

// mealsToArray("continentalBreakfast", menuPlanData.menuSetting);

  if(selected.action!=='editMenuPlan'){

     try{
    const res=await DFPAPIEndpointGalley.post('galley/mealMenuPlan/create',menuPlanData)
    if(res.success){
    setModalFormStatusObj({text:res?.message})
    setSaving(false)
    handleReset();
    }
    }
    catch(err){
    setSaving(false)
      setModalFormStatusObj({text:err.data?.message||"Error while saving"})
    }
  }
  else{
    try{
      const res=await DFPAPIEndpointGalley.put(`galley/mealMenuPlan/${selected?._id}`,menuPlanData)
      if(res.success){
      setModalFormStatusObj({text:res?.message})
      setSaving(false)
      handleReset();
      }
      }
      catch(err){
      setSaving(false)
        setModalFormStatusObj({text:err.data?.message||'Validation failed'})
      }
  }



}else{
  setErrorSaving(true)
}
let timerId = setTimeout(() => {
  clearTimeout(timerId);
  setModalFormStatusObj({text:''})
  setErrorSaving(false);
}, 1200)
  }
const handleMealOptions=(cat,item)=>{
let options = [];
if(mealMenu){
  mealMenu?.forEach((m) => {
  if (m.mealCategoryLabel === cat) {
    m.mealsSelection.forEach((meal) => {
      if (meal.mealType === item) {
          options = meal.items.map((fieldValue, index) => ({
          key: index + 1,
          label: fieldValue.itemName,
          value: fieldValue.itemId,
        }))
      }
    });
  }
});
}
return options;

  }
  const closeMenuPlanner=()=>{
    setMealMenuEdit([]);
    props.onClose()

  }


  const handleAddMealSet = (mealCatergory,edit=null) => {
  setInputChanged(true);
    let newId;
    switch (mealCatergory) {
        case 'Continental Breakfast':
        newId = mealSetsContBreakfast[mealSetsContBreakfast.length-1];
        setmealSetsContBreakfast([...mealSetsContBreakfast, { id: newId['id']+1 }]);
        break;
        default:
        break;
    }

  };
  const handleRemoveMealSet = (id,m) => {
  setInputChanged(true);
    setRemoveMeals({id,mealsCatergory:m})
    switch (m) {
      case 'Continental Breakfast':
      const updatedmealSetsContBreakfast = mealSetsContBreakfast.filter(set => set.id !== id);
      setmealSetsContBreakfast(updatedmealSetsContBreakfast);
      break;
      default:
      break;
    }

  };

  const generateMealSet = (mealType,mealCatergory=null, selectHandler, stateKey, mealSetId) => (
    <div key={mealType} className={mealType==='appetizer'?'flexMenu justifyContentSpaceBetween margLeft23':'flexMenu justifyContentFlexEnd margLeft23'}>
      {mealType === 'appetizer'?<><div className='width20Menu'>

      <div className='flexColMenu margTop5'>
      <label className='reqm flexRow'>Meal Name</label>
      <div className='flexMenu'>
        <input
          className='menu-plannertext'
          name={`mealName${mealSetId}`}
          value={state[`mealName${mealSetId}`]?state[`mealName${mealSetId}`]:""}
          onChange={handleMealName}
        />
      </div>

    </div>
    </div>

    <div className='width20Menu'>

    <div className='flexColMenu'>
    <label className='reqm flexRow'>Meal Type</label>
    <div className='flexMenu'  onFocus={()=>setmealId(mealSetId)}>
    <Select
    defaultValue={state[`mealTypeGroup${mealSetId}`]?.label?state[`mealTypeGroup${mealSetId}`]?.label:''}
    options={mealTypeGroup}
    placeholder='Select Meal Type'
    value={options.mealTypesGroup?.filter(function(option) {
    return state[`mealTypeGroup${mealSetId}`]&&state[`mealTypeGroup${mealSetId}`].find(sc=>sc.label===option.label)
    })}
    onChange={handleMealType}
    isSearchable={true}
    isMulti={false}
    className='selectMenu'
    />
    &nbsp;&nbsp;

    </div>
    </div>
</div>

<div className='width20Menu'>
<div className='flexColMenu'>
<label className='reqm flexRow'>Class</label>
  <div className='flexMenu' onFocus={()=>setmealId(mealSetId)}>
  <Select
  defaultValue={state[`class${mealSetId}`]?.label?state[`class${mealSetId}`]?.label:''}
  options={options.mealMenuClass}
  placeholder='Select Class'
  value={options.mealMenuClass?.filter(function(option) {
  return state[`class${mealSetId}`]&&state[`class${mealSetId}`].find(sc=>sc.label===option.label)
  })}
  onChange={handleClass}
  isSearchable={true}
  isMulti={false}
  className='selectMenu'
  />
  &nbsp;&nbsp;

  </div>
  </div>

</div></>:<></>}

      <div className={mealType!=='appetizer'?'margRight11':''}>{preCompile[mealType]}</div>

      <div className='width30Menu justifyContentSpaceBetween flexRow margTop8'>
          <Select
            defaultValue={state[stateKey+mealSetId] ? state[stateKey+mealSetId] : []}
            options={handleMealOptions(preCompile.contBreakFast, preCompile[mealType])}
            placeholder={`Select ${mealType}`}
            value={handleMealOptions(preCompile.contBreakFast, preCompile[mealType]).filter(function (option) {
              return state[stateKey+mealSetId] && state[stateKey+mealSetId].find(sc => sc.label === option.label)
            })}
            onChange={(selectedOption)=>selectHandler(selectedOption,stateKey+mealSetId)}
            styles={newCustomStyles}
            isSearchable={true}
            isMulti={true}
            className='selectMenu'
          />
      {((mealSetId === 1 && mealType === 'appetizer')) && (
      <img src={plus} className="menuplanner-icons" alt="add" onClick={() => handleAddMealSet(mealCatergory)} />
      )}
      {((mealSetId !== 1 && mealType === 'appetizer')) && (
      <img src={minus} className="menuplanner-icons" alt="remove" onClick={() => handleRemoveMealSet(mealSetId, mealCatergory)} />
      )}
      {((mealSetId === 1 &&(( stateKey === 'mornMain')||( stateKey === 'evnMain')||( stateKey === 'supMain')))) && (
      <img src={plus} className="menuplanner-icons" alt="add" onClick={() => handleAddMealSet(mealCatergory)} />
      )}
      {((mealSetId !== 1&&(( stateKey === 'mornMain')||( stateKey === 'evnMain')||( stateKey === 'supMain')))) && (
      <img src={minus} className="menuplanner-icons" alt="remove" onClick={() => handleRemoveMealSet(mealSetId, mealCatergory)} />
      )}
      </div>

    </div>
  );


  const createMenu=()=>{
    return(<>
        <br />
        <div style={{width:'100%'}} className={`overlay ${props.isSubModal ? 'sub' : ''} ${props.overlayClassName || ''}`} role="dialog">
        <div className="dialog sm-scrollbar" style={props.customModalStyle}>
        <div className={`dialog__content ${props.className || ''}`}>
      <div className='width90Menu'>
        <h2 className='dialog__title subheading-text-medium justifyContentCenter alignItemsCenter fontMedium'>
      {props.title}
      <img  style={{justifyContent:'flexEnd'}} className="modal__close_icon" alt='Close' src={closeIcon} onClick={closeMenuPlanner} />
        </h2>
      </div>
      <hr style={{margin:'0 auto',width:'95%'}} />
      <br />
      <div className='flexMenu justifyContentSpaceBetween margLeft23'>
        <div className='width30Menu'>

<div className='flexColMenu margTop5'>
      <label className='reqm flexRow'>Menu Name</label>
      <input
          className='menu-plannertext'
          name="menuName"
          value={state.menuName?state.menuName:""}
          onChange={handleMenuName}
        />
    </div>



<br/>

<div className='flexColMenu'>
            <label className='reqm flexRow'>Warehouse</label>
            <Select
              defaultValue={state.cateringStation?.label?state.cateringStation?.label:''}
              options={warehouses}
              placeholder={customNoOptionsMessage()}
              value={warehouses?.filter(function(option) {
                return state.cateringStation&&state.cateringStation.find(sc=>sc.label===option.label)
              })}
              onChange={handleCateringStation}
              isSearchable={false}
              noOptionsMessage={customNoOptionsMessage}
              styles={{...newCustomStyles, indicatorsContainer: (provided) => ({
                ...provided,
                display: 'none',
              }), option: (provided, state) => ({
                ...provided,
                color: state.isDisabled ? 'white' : 'white',
              })}}
              isMulti={false}
              isDisabled={true}
              className='selectMenu'
            />
          </div>

<br/>

<div className='flexColMenu'>
<div className=''>
              <label className='reqm flexRow'>From Date</label>

         <NGODatePicker
                      value={state.fromDate}
                      selectsStart={true}
                      minDate={new Date()}
                      dateFormat="dd/MM/yyy"
                      name='fromDate'
                      className='margRight'
                      onChange={onChangeInputFromDate} />

            </div>
</div>



          <br />


        </div>
        <div className='width30Menu'  style={{ marginTop:'0.6%'}}>

        <div className='flexColMenu'>
            <label className='reqm flexRow'>Sector Type</label>
            <Select
              options={sectorTypes}
              defaultValue={state.sectorType?.label?state.sectorType?.label:''}
              value={options.sectorTypes.filter(function(option) {
                return state.sectorType&&state.sectorType.find(sc=>sc.label===option.label)
              })}
              placeholder='Select Sector Type'
              onChange={handleSectorType}
              isSearchable={true}
              isMulti={false}
              className='selectMenu'
            />
          </div>
          <br />

          <div className='flexColMenu'>
            <div className='flexRow'>
            <label className='reqm flexRow'>Caterer  </label>
          {catererLoading?<img src={LoadingGIF} className='icon-size' style={{width:'20px',height:'18px'}} alt="Loading" />:<></>}
            </div>
            <Select
              defaultValue={state.caterer?.label?state.caterer?.label:''}
              options={caterers}
              placeholder='Select Caterer'
              value={caterers.filter(function(option) {
                return state?.caterer&&state?.caterer.find(sc=>sc?.key===option?.key)
              })}
              isDisabled={caterers?.length === 0}
              onChange={handleCaterer}
              isSearchable={true}
              isMulti={false}
              className='selectMenu'
            />
          </div>
<br/>
          <div className='flexColMenu'>

          <label className='reqm flexRow'>To Date</label>

          <NGODatePicker
          value={state.toDate}
          selectsEnd={true}
          minDate={new Date()}
          dateFormat="dd/MM/yyy"
          name='toDate'
          className='margRight'
          onChange={onChangeInputToDate} />

          </div>
          <br/>

        </div>
        <div className='width30Menu'  style={{ marginBottom:'4%'}}>



        <div className='flexColMenu'>
            <label className='reqm margTop10'>Sector</label>
            <div className='flexMenu'>
            <Select
                defaultValue={state.sourceStation?.label?state.sourceStation?.label:''}
                options={sectors}
                placeholder='Select Sector'
                value={options.sectors.filter(function(option) {
                  return state.sourceStation&&state.sourceStation.find(sc=>sc.label===option.label)
                })}
                onChange={handleSource}
                isSearchable={true}
                isMulti={false}
                className='selectMenu'
              />
              &nbsp;&nbsp;

            </div>
          </div>





          <br/>







          <div className='flexColMenu'>



<br />


<label className='flexRow'>Aircraft Type <span style={{color:"#feb246"}} className='margLeft5'>(optional)</span></label>
  <Select
    options={airCrafts}
    defaultValue={state.aircraftType?.label?state.aircraftType?.label:''}
    value={options.aircrafts.filter(function(option) {
      return state.aircraftType&&state.aircraftType.find(sc=>sc.label===option.label)
    })}
    placeholder='Select Aircraft'
    onChange={handleAircraftType}
    isSearchable={true}
    isMulti={false}
    className='selectMenu'
  />
</div>
<br />

          <div className='flexColMenu'>
          <div  style={{float:'left'}} className={`dialog__description__checkbox flexRow margTop16 justifyContentFlexStart`}>
        <input
          id={''}
          type={'checkbox'}
          onChange={(e)=>handleOverrideMenu(e.target.checked)}
          checked={state.overrideMenu}
          value={state.overrideMenu}
          />

                <label className='margTop10 flexRow'>Override Regular Menu</label>
            </div></div>




        </div>
      </div>
      <div className='flexMenu justifyContentFlexStart margLeft23'>


        </div>

      <br />
      <hr style={{margin:'0 auto',width:'95%'}}/>
      <br />
      <div>
        {mealSetsContBreakfast.map((mealSet) => (
        <div key={mealSet.id}>
        {generateMealSet('appetizer','Continental Breakfast', handleContinentalBreakfast, 'contApp',mealSet.id)}
        {generateMealSet('main',null, handleContinentalMain, 'contMain',mealSet.id)}
        {generateMealSet('dessert',null, handleContinentalDessert, 'contDes',mealSet.id)}
        {generateMealSet('beverages',null, handleBeveragesMain, 'beverages',mealSet.id)}

        {mealSetsContBreakfast.length>1? <hr style={{ margin: '1rem auto', width:'85%', border: 'none', borderTop: '2px dotted',color:"#70b4e1" }} />
        :<></>}
        </div>
      ))}
    </div>
      <br />
      <hr style={{margin:'0 auto',width:'95%'}} />
      <br/>

      <div className='flexMenu justifyContentSpaceEvenly width90Menu'>
        <div className='width30Menu'></div>

        <div className='width30Menu saveBtnMenu  h7'>
{/*
        <div className={`dialog__description__checkbox flexRow`}>
        <input
          id={''}
          type={'checkbox'}
          onChange={(e)=>handleApplyToAll(e.target.checked)}
          checked={state.applyToAll}
          value={state.applyToAll}
          />

                <label className='margTop10'>{preCompile.applyMenu}</label>
            </div> */}
        </div>
        <div className='width30Menu'></div>
      </div>
      {/* {state.applyToAll?<div style={{marginTop:'2.5rem'}} className='flexRow justifyContentCenter alignItemsCenter'>
    <div className='flexRow subheading-text-medium-title fontLight text-danger'>
    *Menu plan will be applied to all the sectors from this origin
  </div>
</div>:<></>} */}
      <br />
      <br />
      <br />
      <div className='flexMenu justifyContentSpaceEvenly width90Menu'>
        <div className='width30Menu'></div>

        <div className='width30Menu saveBtnMenu'>
          <button
            disabled={saving}
            className='done-button'
            onClick={saveMenuDetails}
          >
            {saving ? 'SAVING...' : 'SAVE'}
          </button>{' '}
        </div>
        <div className='width30Menu'></div>
      </div>

      {Boolean(modalFormStatusObj?.text) ?
        <>
          <div  style={{display:'flex',width:'100%'}} className={ "confirmation_popup " + (!modalFormStatusObj?.text.length ? 'alert_error' : 'alert_success') }>
        {modalFormStatusObj?.text}
        </div>
        </> : null }

        {errorSaving ? (
          <div className='flexRow confirmation_popup_login alert_error'>
          <h6 className="subheading-text-medium-title fontLight">Please provide all the required fields</h6>
          </div>
          ) : null}
 </div>
 </div>
 </div>

    </>

    )
  }



  const editMenu=()=>{
    return(<>
        <br />
        <div style={{width:'100%'}} className={`overlay ${props.isSubModal ? 'sub' : ''} ${props.overlayClassName || ''}`} role="dialog">
        <div className="dialog sm-scrollbar" style={props.customModalStyle}>
        <div className={`dialog__content ${props.className || ''}`}>

        <div className='width90Menu'>
        <h2 className='dialog__title subheading-text-medium justifyContentCenter alignItemsCenter fontMedium'>
      {props.title}
      <img  style={{justifyContent:'flexEnd'}} className="modal__close_icon" alt='Close' src={closeIcon} onClick={closeMenuPlanner} />
        </h2>
      </div>
      <hr style={{margin:'0 auto',width:'95%'}} />
      <br />
      <div className='flexMenu justifyContentSpaceBetween margLeft23'>
        <div className='width30Menu'>

<div className='flexColMenu margTop5'>
      <label className='reqm flexRow'>Menu Name</label>
      <input
          className='menu-plannertext'
          name="menuName"
          value={state.menuName?state.menuName:""}
          onChange={handleMenuName}
        />
    </div>



<br/>

<div className='flexColMenu'>
            <label className='reqm flexRow'>Warehouse</label>
            <Select
              defaultValue={state.cateringStation?.label?state.cateringStation?.label:''}
              options={warehouses}
              placeholder={customNoOptionsMessage()}
              value={warehouses?.filter(function(option) {
                return state.cateringStation&&state.cateringStation.find(sc=>sc.label===option.label)
              })}
              onChange={handleCateringStation}
              isSearchable={false}
              noOptionsMessage={customNoOptionsMessage}
              styles={{...newCustomStyles, indicatorsContainer: (provided) => ({
                ...provided,
                display: 'none',
              }), option: (provided, state) => ({
                ...provided,
                color: state.isDisabled ? 'white' : 'white',
              })}}
              isMulti={false}
              isDisabled={true}
              className='selectMenu'
            />
          </div>

<br/>

<div className='flexColMenu'>
<div className=''>
              <label className='reqm flexRow'>From Date</label>

         <NGODatePicker
                      value={state.fromDate}
                      selectsStart={true}
                      minDate={new Date()}
                      dateFormat="dd/MM/yyy"
                      name='fromDate'
                      className='margRight'
                      onChange={onChangeInputFromDate} />

            </div>
</div>



          <br />


        </div>
        <div className='width30Menu'  style={{ marginTop:'0.6%'}}>

        <div className='flexColMenu'>
            <label className='reqm flexRow'>Sector Type</label>
            <Select
              options={sectorTypes}
              defaultValue={state.sectorType?.label?state.sectorType?.label:''}
              value={options.sectorTypes.filter(function(option) {
                return state.sectorType&&state.sectorType.find(sc=>sc.label===option.label)
              })}
              placeholder='Select Sector Type'
              onChange={handleSectorType}
              isSearchable={true}
              isMulti={false}
              className='selectMenu'
            />
          </div>
          <br />

          <div className='flexColMenu'>
            <div className='flexRow'>
            <label className='reqm flexRow'>Caterer  </label>
          {catererLoading?<img src={LoadingGIF}style={{margLeft:'5px',margTop:'5px',width:'20px',height:'18px'}} alt="Loading" />:<></>}
            </div>
            <Select
              defaultValue={state.caterer?.label?state.caterer?.label:''}
              options={caterers}
              placeholder='Select Caterer'
              value={caterers.filter(function(option) {
                return state?.caterer&&state?.caterer.find(sc=>sc?.key===option?.key)
              })}
              isDisabled={caterers?.length === 0}
              onChange={handleCaterer}
              isSearchable={true}
              isMulti={false}
              className='selectMenu'
            />
          </div>
<br/>
          <div className='flexColMenu'>

          <label className='reqm flexRow'>To Date</label>

          <NGODatePicker
          value={state.toDate}
          selectsEnd={true}
          minDate={new Date()}
          dateFormat="dd/MM/yyy"
          name='toDate'
          className='margRight'
          onChange={onChangeInputToDate} />

          </div>
          <br/>

        </div>
        <div className='width30Menu'  style={{ marginBottom:'4%'}}>



        <div className='flexColMenu'>
            <label className='reqm margTop10'>Sector</label>
            <div className='flexMenu'>
            <Select
                defaultValue={state.sourceStation?.label?state.sourceStation?.label:''}
                options={sectors}
                placeholder='Select Sector'
                value={options.sectors?.filter(function(option) {
                  return state.sourceStation&&state.sourceStation.find(sc=>sc?.label===option?.label)
                })}
                onChange={handleSource}
                isSearchable={true}
                isMulti={false}
                className='selectMenu'
              />
              &nbsp;&nbsp;

            </div>
          </div>





          <br/>







          <div className='flexColMenu'>



<br />


<label className='flexRow'>Aircraft Type <span style={{color:"#feb246"}} className='margLeft5'>(optional)</span></label>
  <Select
    options={airCrafts}
    defaultValue={state.aircraftType?.label?state.aircraftType?.label:''}
    value={options.aircrafts.filter(function(option) {
      return state.aircraftType&&state.aircraftType.find(sc=>sc.label===option.label)
    })}
    placeholder='Select Aircraft'
    onChange={handleAircraftType}
    isSearchable={true}
    isMulti={false}
    className='selectMenu'
  />
</div>
<br />

          <div className='flexColMenu'>
          <div  style={{float:'left'}} className={`dialog__description__checkbox flexRow margTop16 justifyContentFlexStart`}>
        <input
          id={''}
          type={'checkbox'}
          onChange={(e)=>handleOverrideMenu(e.target.checked)}
          checked={state.overrideMenu}
          value={state.overrideMenu}
          />

                <label className='margTop10 flexRow'>Override Regular Menu</label>
            </div></div>




        </div>
      </div>
      <br />
      <hr style={{margin:'0 auto',width:'95%'}}/>
      <br />
      <div>
      {mealSetsContBreakfast.map((mealSet) => (
        <div key={mealSet.id}>
        {generateMealSet('appetizer','Continental Breakfast', handleContinentalBreakfast, 'contApp',mealSet.id)}
        {generateMealSet('main',null, handleContinentalMain, 'contMain',mealSet.id)}
        {generateMealSet('dessert',null, handleContinentalDessert, 'contDes',mealSet.id)}
        {generateMealSet('beverages',null, handleBeveragesMain, 'beverages',mealSet.id)}

        {mealSetsContBreakfast.length>1? <hr style={{ margin: '1rem auto', width:'85%', border: 'none', borderTop: '2px dotted',color:"#70b4e1" }} />
        :<></>}
      </div>
      ))}
      </div>

      <br />
      <div className='flexMenu justifyContentSpaceEvenly width90Menu'>
        <div className='width30Menu'></div>

        <div className='width30Menu saveBtnMenu  h7'>
{/*
        <div className={`dialog__description__checkbox flexRow`}>
        <input
          id={''}
          type={'checkbox'}
          onChange={(e)=>handleApplyToAll(e.target.checked)}
          checked={state.applyToAll}
          value={state.applyToAll}
          />

                <label className='margTop10'>{preCompile.applyMenu}</label>
            </div>

          <input type='checkbox'></input> &nbsp;&nbsp;{preCompile.applyMenu} */}
        </div>


        <div className='width30Menu'></div>


      </div>
      {/* {state.applyToAll?<div style={{marginTop:'2.5rem'}} className='flexRow justifyContentCenter alignItemsCenter'>
    <div className='flexRow subheading-text-medium-title fontLight text-danger'>
    *Menu plan will be applied to all the sectors from this origin
  </div>
</div>:<></>} */}
      <br />
      <div className='flexMenu justifyContentSpaceEvenly width90Menu'>
        <div className='width30Menu'></div>

        <div className='width30Menu saveBtnMenu'>
          <button
            disabled={saving}
            className='done-button'
            onClick={saveMenuDetails}
          >
            {saving ? 'SAVING...' : 'SAVE'}
          </button>{' '}
        </div>
        <div className='width30Menu'></div>
      </div>

      {Boolean(modalFormStatusObj?.text) ?
        <>
          <div  style={{display:'flex',width:'100%'}} className={ "confirmation_popup " + (!modalFormStatusObj?.text.length ? 'alert_error' : 'alert_success') }>
        {modalFormStatusObj?.text}
        </div>
        </> : null }

        {errorSaving ? (
          <div className='flexRow confirmation_popup_login alert_error'>
          <h6 className="subheading-text-medium-title fontLight">Please provide all the required fields</h6>
          </div>
          ) : null}

</div>
</div>

</div>

    </>)
  }




  return (
    <div className='sm-scrollbar dialogMenu' style={{overflowX:'scroll'}}>
   {options&&selected&&selected.action!=='editMenuPlan'?createMenu():editMenu()}
    </div>
  )
}

export const AddMenuPlanner = props => {
  const onClose = e => {
    removeContainerOverlay()
    props.setState((prev)=>{return{ ...prev,selected:false}})
    props.onClose()
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    document
      .querySelectorAll('.content-container')[0]
      .classList.remove('modal-overlay-bg2')
  }

  return (
    <AddMenuPl
      title={props.title}
      options={props.options}
      data={props.data}
      setState={props.setState}
      onClose={onClose}
      setModalFormStatusObj={props.setModalFormStatusObj}
    ></AddMenuPl>
  )
}