import React, { useState } from 'react'
import '../assets/styles/modal.scss'
import closeIcon from '../images/icons/Icon2.png'
import { useTranslation } from 'react-i18next';

export const Confirm = (props) => {
  const { t } = useTranslation();

  let isError = false,
  updateStatusText="";
 if (props.modalFormStatusObj) {
   if (('text' in props.modalFormStatusObj)) {
     updateStatusText= props.modalFormStatusObj.text;
   }
   if (('error' in props.modalFormStatusObj)) {
     isError = props.modalFormStatusObj.error;
   }
 }

  return (
      <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar">
        <div className="dialog__content">
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">{props.title}</h2>
          <hr />
          <div className="dialog__footer flexRow justifyContentSpaceEvenly flexWrap">
            {Boolean(props.description)?<h6>{props.description}</h6>:<h6>{t('pages.inventoryContent.uplifts.confirmUpliftMsg')}</h6>}
          </div>
          {updateStatusText.length>0 ?
        <>
          <div className={ "dialog__statusBlock " + (isError ? 'alert_error' : 'alert_success') }>
            {updateStatusText}
          </div>
        </> : null }
        </div>
        <div className="dialog__footer flexRow justifyContentSpaceEvenly">
          <button className="uploadImage-button" disabled={props.showLoadingApi?props.showLoadingApi:false} onClick={props.onClose}>{t('pages.inventoryContent.uplifts.close')}</button>
          <button className="done-button" disabled={props.showLoadingApi?props.showLoadingApi:false} onClick={props.onSubmit}>{t('pages.inventoryContent.uplifts.confirm')}</button>
        </div>
        {props.error?.length ?
          <div className="dialog__statusBlock alert_error mb-5">
            {props.error}
          </div> : null
        }
      </div>
    </div>
  );
}
export const ConfirmModal = (props) => {
  const [updated, setUpdated] = useState(null);

  const onChange = (e) => {
    const { name, value } = e.target;
    if(e.target.name==="itemType"){
      props.setItemTypeValue(e.target.value)
    };
    let newUpdates = updated ? { ...updated } : {}
    newUpdates[name] = value;
    setUpdated({ ...newUpdates });
    typeof (props.onChange) === 'function' && props.onChange(e,updated)
  }

  const onSubmit = (e) => {
    const mode=props.title;
    const updated = props.fields[0].initialValue;
    removeContainerOverlay();
    typeof (props.onSubmit) === 'function' && props.onSubmit(e, updated,mode)
  }

  const onClose = (e) => {
    removeContainerOverlay();
    props.onClose();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <Confirm
      title={props.title}
      onClose={onClose}
      error={props.error}
      description={props.description}
      modalFormStatusObj={props.modalFormStatusObj}
      onChange={onChange}
      onSubmit={(e) => onSubmit(e)}>
    </Confirm>
  )
}