/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */

import React, {useEffect,useState } from 'react'
import { APIEndpoint, DFPAPIEndpoint } from '../../../utils/axios';
import { NGODatePicker} from '../../../common';
import {translationLength,errorFormatter } from '../../../utils/commonFunctions';
import closeIcon from '../../../images/icons/Icon2.png';
import LoadingIn from '../../../assets/loadingIn.gif';
import { crewInc,sectSales } from '../../../utils/configs';
import moment from 'moment';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import AllMultiSelect from "../../../common/AllSelectCheckbox.jsx";
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import monitor from '../../../assets/icons/monitor.svg'
import { components } from "react-select";
import pieChartIcon from '../../../assets/icons/pi-chart-sales.svg';
import totalOrders from '../../../assets/icons/total-orders.svg';
import totalSales from '../../../assets/icons/total-sales.svg';



export const PerformancePage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({emptInst:false,resetDataSource:false,disabled:false});
const [optionSelectedCrew,setOptionSelectedCrew]=useState([]);
const [optionSelectedSector,setOptionSelectedSector]=useState([]);
  const [errorMessage,SetErrorMessage]=useState("");
  const [showDownload,setShowDownload]=useState(false);
  const [reportsType, setReportTypes] = useState([]);
  const [reportDownloadErr, setReportDownloadErr] = useState(null);
  const [reptType,setReptType]=useState("");
  const [crewMembers,setCrewMembers]=useState([]);
  const [sectors,setSectors]=useState([]);
  const [dashboardCards,setDashBoardCards]=useState([]);
  const [crewName,setCrewName]=useState([]);
  const [currentDate1, setCurrentDate1] = useState(moment().subtract(1, 'month'));
  const [currentDate2, setCurrentDate2] = useState(moment().add(1, 'month'));
const [reportDate,setReportDate]=useState({startDate:new Date(moment()),endDate:new Date(moment())});
  const [isDownloading, setIsDownloading] = useState(false);
  const sType = localStorage.getItem('sType');
  const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

  useEffect(() => {
    getCrewMembers();
    getCrewIncentiveAndSales();

  }, [])


  useEffect(() => {
    if(!reptType){
      getCrewIncentiveAndSales();
    }
  }, [reportDate,crewName])

  useEffect(() => {
    const updateDisabledState = () => {
      if (!reptType) return true;

      switch (reptType) {
        case 'crewCommission':
        case 'crewVariance':
        case 'crewReconVariance':
          return !crewName.length;

        case 'allCrewCommission':
          return false;

        case 'customerInteractionsReport':
          return !(optionSelectedCrew.length && optionSelectedSector.length);

        default:
          return true;
      }
    };

    setState((_prev) => ({
      ..._prev,
      disabled: updateDisabledState(),
    }));
  }, [reptType, crewName, optionSelectedCrew, optionSelectedSector]);



const getCrewMembers=async()=>{
    try {
    const resp2 = await DOMAIN.get('sandboxing/employees?includeAdmin=no');
      if (resp2.result.length > 0) {
        const options = [];
        resp2.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: `${fieldValue.employeeName} (${fieldValue.employeeCode})`, value: `${fieldValue.employeeCode}`});
          return fieldValue;
        });
        setCrewMembers(options);
    }
    } catch (error) {

    }


}


const Option = props => {
  return (
  <div>
  <components.Option {...props}>
  <input
  type="checkbox"
  checked={props.isSelected}
  onChange={() => null}
  />{" "}
  <label>{props.label}</label>
  </components.Option>
  </div>
  );
  };

const MultiValue = props => (
  <components.MultiValue {...props}>
  <span>{props.data.label}</span>
  </components.MultiValue>
  );

const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    position: "relative",
    zIndex: state.isOpenMenu ? 9999 : null,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999, // Set a high z-index for the portal
  }),


};

const newCustomStyles = {
  input: (defaultStyles) => ({
    ...defaultStyles,
    color: '#fff',
    }),
    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#162E3E",
      padding: "2px",
      width:'17rem',
      marginBottom: '1rem',
      border: "0.4px solid #FFFFFF",
      boxShadow: "none",
      PointerEvent: 'none',
    })
};
const getCrewIncentiveAndSales=async()=>{
  setIsDownloading(true)
 const body= {
    startDate: moment(reportDate.startDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").format(),
    endDate:moment(reportDate.endDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").format()
}
try {
  const resp1 = await DOMAIN.post('sales/getCrewIncentiveSummary',body);
  const resp2 = await DOMAIN.post('sales/getSectorWiseSales',body);

if (resp1&&resp2) {
  const randId=Math.floor(Math.random() * (10 - 1 + 1)) + 1;
  setDashBoardCards([{crewIncentive:crewInc.find(cr=>cr.id===randId),sectorWiseSale:sectSales.find(sc=>sc.id===randId)}]);
  setIsDownloading(false)
}else{
  setIsDownloading(false)
}
} catch (error) {
  const err=JSON.parse(error.message)

  if(err.data&&err.data?.errors){
    let errorMessageFormatted= errorFormatter(err.data.errors[0]?.message)
    SetErrorMessage(errorMessageFormatted||"Error in fetching data!");
    setTimeout(() => {
      SetErrorMessage("")
      }, 4000);
   }else{
    SetErrorMessage("Error in fetching data!");
    setTimeout(() => {
      SetErrorMessage("")
      }, 4000);
   }


}
}

const onCrewChange=(e)=>{
  setCrewName([e])
  setReportDownloadErr(null)
}
const onCrewFilterChange=(e)=>{
setCrewName([e])
}


  const handlePrevMonth = () => {
    setCurrentDate1(prevDate => prevDate.clone().subtract(1, 'month'));
    setCurrentDate2(prevDate => prevDate.clone().subtract(1, 'month'));
    setReportDate((prev)=>({...prev,startDate:moment().startOf('month').subtract(1, 'month').toDate(),endDate:moment().startOf('month').subtract(1, 'month').toDate()}))

  };

  const handleNextMonth = () => {
    setCurrentDate1(prevDate => prevDate.clone().add(1, 'month'));
    setCurrentDate2(prevDate => prevDate.clone().add(1, 'month'));
    setReportDate((prev)=>({...prev,startDate:moment().startOf('month').add(1, 'month').toDate(),endDate:moment().startOf('month').add(1, 'month').toDate()}))

  };

  const onDownloadActivityReport=async()=>{

    try {
      const resp = await DOMAIN.get('reports/crewReportTypes');
      if (resp.result.length > 0) {
          const options = [{key: 0, label: "Select Report Type", value: ''}];
          resp.result.filter( (fieldValue, index) => {
            options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.id}`});
            return fieldValue;
          });
          setReportTypes(options);
      }
      else{
          console.log("error")
      }

        const resp2 = await DOMAIN.get('sandboxing/employees?includeAdmin=no');
        if (resp2.result.length > 0) {
            const options = [{key: 0, label: "Select Crew", value: ''}];
            resp2.result.filter( (fieldValue, index) => {
              options.push({key: index+1, label: `${fieldValue.employeeName} (${fieldValue.employeeCode})`, value: `${fieldValue.employeeCode}`});
              return fieldValue;
            });
            setCrewMembers(options);
        }
        const resp3 = await DOMAIN.get('stores');
        if (resp3.result.length > 0) {
            const options = [{key: 0, label: "Select Sectors", value: ''}];
            resp3.result.filter( (fieldValue, index) => {
              options.push({key: index+1, label: `${fieldValue.flightNumber} (${fieldValue.originName}-${fieldValue.destinationName})`, value: `${fieldValue.value}`});
              return fieldValue;
            });
            setSectors(options);
        }


setShowDownload(true);
  }
   catch (error) {

  }

    }

    function getCrewName(crew){
      const [crewOrg] = crew
      if(crewOrg?.value){
        return [crewOrg?.value]
      }else{
        return [crewOrg]
      }
    }

    const onDownloadReportFinal = async () => {
      let body = {
        startDate: reportDate.startDate,
        endDate: reportDate.endDate,
        reportType: reptType,
      };

      switch (reptType) {
        case 'crewCommission':
        case 'crewVariance':
        case 'crewReconVariance':
          body.crewCode = getCrewName(crewName);
          break;
        case 'customerInteractionsReport':
          body.crewCode = optionSelectedCrew?.map(crew => crew.value);
          body.sectors = optionSelectedSector?.map(sect => sect.value.replace(/\s+/g, ''));
          break;
        case 'allCrewCommission':
          break;
        default:
          break;
      }


      let header = {
        Accept: "text/csv",
      };
      if(body && reptType){
           try {
        setIsDownloading(true);
        const res = await DOMAIN.post(`crew/download/${reptType}`, body, header);
        if (res.status === "scheduled") {
          setTimeout(async () => {
            try {
              setIsDownloading(true);
              const dowId = await DOMAIN.get(`downloads/status/${res.id}`);
              const ItemMasterDwnld = await DOMAIN.get(
                `downloads/analytics/${dowId.fileName}${dowId.fileExtension}`,
              );
              const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                `${dowId.fileName}.${dowId.fileExtension}`,
              );
              document.body.appendChild(link);
              link.click();
              setIsDownloading(false);
            } catch (error) {
              setIsDownloading(false);
              const err = JSON.parse(error.message);
              const errMsg = err?.data?.error?.title || err?.data?.message;
              setReportDownloadErr(errMsg);
            }
          }, 5000);
        }
      } catch (error) {
        setIsDownloading(false);
      }
      }

    };



  const onReportTypeChange = (e) => {
    setReptType(e.target.value);
    setCrewName([]);
    setOptionSelectedCrew([]);
    setOptionSelectedSector([]);
    setReportDownloadErr(null);
}



  const handleChange = (selected) => {
    setOptionSelectedCrew(selected)
  };

  const handleChangeSector = (selected) => {
    setOptionSelectedSector(selected)
  };

const onDateChangeRep=(e)=>{
const {name,value}=e.target;
if(name==='startDate'){
    setReportDate((prev)=>({...prev,startDate:value}))
}
else{
    setReportDate((prev)=>({...prev,endDate:value}))
}
}

  const onUploadClose = () => {
    setShowDownload(false);
    setCrewName([]);
    setOptionSelectedCrew([]);
    setOptionSelectedSector([]);
    setReptType("");
    setReportDownloadErr("");
  }


  const salesAndOrders=(items)=>{
  if(items&&items.length){

         return(
       <>
       <div className='margTop10'></div>

            <div className="col-lg-3 card-container-sales-header card-sales"  style={{backgroundColor:"#1d3f4f",padding:'10px',marginRight:'0.6rem',borderRadius:'5px'}} key={1 + '-1fincial-year-sales'}>
              <>
              <div className="card-content2">
                <div className="h6">
                    Total Commission
                </div>
                { isDownloading?<div className='h4 flexRow justifyContentCenter alignItemsCenter'><img src={LoadingIn}style={{width:'25px',height:'25px'}} alt="Loading" /></div>:   <div className="h4">{t('pages.dashboardContent.salesOverview.rs')} {items[0]?.crewIncentive?.salesCommission}</div>                }

            </div>
            <div className='dashboard-header-footer'>
                <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={totalSales} />
            </div>
              </>


        </div>
        <div className="col-lg-3 card-container-sales-header card-sales"  style={{backgroundColor:"#1d3f4f",padding:'10px',marginRight:'0.6rem',borderRadius:'5px'}} key={2 + '-1fincial-year-sales'}>
            <div className="card-content2">
                <div className="h6">
                    Total BoB sales
                </div>
                { isDownloading?<div className='h4 flexRow justifyContentCenter alignItemsCenter'><img src={LoadingIn}style={{width:'25px',height:'25px'}} alt="Loading" /></div>:   <div className="h4">{t('pages.dashboardContent.salesOverview.rs')}{items[0]?.sectorWiseSale?.totalBoBSales}</div>                }


            </div>

            <div className='flexRow justifyContentSpaceBetween dashboard-header-footer'>
            <div >Crew Share:&nbsp;{t('pages.dashboardContent.salesOverview.rs')}{items[0].sectorWiseSale?.crewShare}</div>

                <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={monitor} />
            </div>


        </div>
        <div className="col-lg-3 card-container-sales-header card-sales"  style={{backgroundColor:"#1d3f4f",padding:'10px',marginRight:'0.6rem',borderRadius:'5px'}} key={3 + '-1fincial-year-sales'}>
            <div className="card-content2">
                <div className="h6">
                    Cash sales
                </div>
                { isDownloading?<div className='h4 flexRow justifyContentCenter alignItemsCenter'><img src={LoadingIn}style={{width:'25px',height:'25px'}} alt="Loading" /></div>:   <div className="h4">{t('pages.dashboardContent.salesOverview.rs')} {items[0].sectorWiseSale?.totalCashSales}</div>                }


            </div>

            <div className='dashboard-header-footer'>
                <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={totalOrders} />
            </div>




        </div>
        <div className="col-lg-3 card-container-sales-header card-sales"  style={{backgroundColor:"#1d3f4f",padding:'10px',borderRadius:'5px'}} key={4 + '-1fincial-year-sales'}>
            <div className="card-content2">
                <div className="h6">
                    Card sales
                </div>
                { isDownloading?<div className='h4 flexRow justifyContentCenter alignItemsCenter'><img src={LoadingIn}style={{width:'25px',height:'25px'}} alt="Loading" /></div>:   <div className="h4">{t('pages.dashboardContent.salesOverview.rs')}  {items[0].sectorWiseSale?.totalCardSales}</div>                }
            </div>
            <div className='dashboard-header-footer'>
                <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={pieChartIcon} />
            </div>
        </div>

  </>

  )

  }
  else{
    return(<></>)
  }
    // eslint-disable-next-line array-callback-return




}


  return (
      <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
          <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
              <button className='download dropdownStyle1-TextMedium' onClick={onDownloadActivityReport}><span><img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} /><u className={`download__banner_${isDownloading ? "active" : ""}`}>Download Reports</u></span></button>

          </div>
          <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
          {
          Boolean(state.message) &&
          <div className={`validationMsgDiv error`}>
            {state.message.text}
          </div>
        }
          </div>
          <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>
            <button className="saleType-div m-2">
               {/* <select
                  className={!crewName?"select-item darkBlue-bg":"select-item widthSelect darkBlue-bg"}
                  onChange={onCrewChange}
                  value={crewName}
                  id="saleTypeId"
                  style={{width:280}}
                >
                  {crewMembers.map((_) => {
                    return (
                      <option key={_.value} value={_.value}>
                        {_.label}
                      </option>
                    );
                  })}
                </select> */}



            <Select
              options={crewMembers}
              value={crewMembers?.filter(function (option) {
                return (
                  crewName.length && crewName[0]&&
                  crewName.find((sc) => sc.label === option.label)
                );
              })}
              onChange={onCrewFilterChange}
              styles={customStyles}
              placeholder="Search Crew"
              isSearchable={true}
              isClearable={true}
              className="selectMenu-meals"
            />


              </button>
              <div className='flexRow justifyContentFlexEnd'>
              <button onClick={handlePrevMonth} style={{height:'2.4rem'}} className="margTop9-5 add-item-button">
              {currentDate1.format('MMMM YYYY')}
                  </button><div className='flexRow justifyContentSpacebetween'>
                     <NGODatePicker
                      style={{width: '12rem'}}
                      value={reportDate.startDate}
                      className="margLeft5"
                      name="startDate"
                      isclearable={true}
                      placeholder={t('Start Date')}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChangeRep} />
                      <NGODatePicker
                      style={{width: '12rem'}}
                      value={reportDate.endDate}
                      className="margLeft5"
                      name="endDate"
                      isclearable={true}
                      placeholder={t('Start Date')}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChangeRep} />
                  </div>



                      <button onClick={handleNextMonth} style={{height:'2.4rem'}} className="margLeft5 margTop9-5 add-item-button">
                      {currentDate2.format('MMMM YYYY')}

                  </button>
            </div>
         </div>
         {(errorMessage.length) ?
        <>
          <div  style={{display:'flex',width:'100%',margin:'1rem',marginLeft:'0rem',padding:'0.3rem'}} className={ "confirmation_popup alert_error"}>
        {errorMessage[0]+errorMessage[1].toUpperCase()+errorMessage.slice(2)}
        </div>
        </> : null }
         <div className='scroll-container-perf sm-scrollbar'>
                    { salesAndOrders(dashboardCards)}
                    </div>



        </>
      }




     {showDownload &&
        <div className="overlay mt-4" role="dialog">
          <div className="dialog sm-scrollbar">
            <div className="dialog__content">
              <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={onUploadClose} />
              <h2 className="dialog__title subheading-text-medium fontMedium">Download reports</h2>
              <hr />

                <div className="dialog__body_container flexRow mt-4 justifyContentCenter flexWrap">
                <div className={`dialog__container_item input-container`}>
                 <div style={{alignItems:"center"}} className={`dialog__description`}>
                <div className='flexCol'>
                <label className={`fontRegular req mt-2`}>Report Type</label>
                  <select className='select-input-galley1'
                  onChange={onReportTypeChange}
                  >
                    {reportsType.map((_) => {
                      return (
                        <option value={_.value} key={_.value}>{_.label}</option>
                      )
                    })}
                  </select>
                </div>
                <div className='flexCol'>
                { reptType==="crewCommission"||reptType==="crewVariance" ||reptType==="crewReconVariance"?  <>
            <label  className={`sub-heading-title-req req margBottom10`}>Select Crew</label>
              <Select
              options={crewMembers}
              value={crewMembers?.filter(function (option) {
                return (
                  crewName.length && crewName[0]&&
                  crewName.find((sc) => sc.label === option.label)
                );
              })}
              onChange={onCrewChange}
              styles={newCustomStyles}
              placeholder="Search Crew"
              isSearchable={true}
              isClearable={true}
              className=""
            /></> :<></> }
                </div>



     {   reptType==='customerInteractionsReport'?<div className={`dialog__container_item input-container`}>
         <div className={`dialog__description`}>
            <label  className={`sub-heading-title-req req margBottom10`}>Select Crew</label>
        <AllMultiSelect
        options={crewMembers.filter(fl=>fl.value!=="")}
        defaultValue={[]}
        isMulti
        styles={ {
          input: provided => ({
            ...provided,
            color: '#fff'
          })
        ,
        control: (defaultStyles) => ({
          ...defaultStyles,
          backgroundColor: "#162E3E",
          width: '17rem',
          boxShadow: "none",
        })
      }
      }
        isSearchable
        components={{ Option, MultiValue }}
        onChange={handleChange}
        allowSelectAll={true}
        value={optionSelectedCrew}
        placeholder={`Select Crew`}

      />
      </div>
      </div>
:<></>}

                <div className={`dialog__container_item input-container`}>
                <div style={{alignItems:"center"}} className={`dialog__description`}>
                  <div> <label className={`fontRegular req`}>Start Date</label>
                    <NGODatePicker
                      style={{width: '17rem'}}
                      value={reportDate.startDate}
                      name="startDate"
                      isclearable={true}
                      placeholder={t('Start Date')}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChangeRep} />
                  </div>
                  <div> <label className={`fontRegular req`}>End Date</label>
                    <NGODatePicker
                      style={{width: '17rem'}}
                      value={reportDate.endDate}
                      name="endDate"
                      isclearable={true}
                      placeholder={t('End Date')}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChangeRep} />
                  </div>


                </div>


                </div>
                {   reptType==='customerInteractionsReport'?<div className={`dialog__container_item input-container`}>
         <div className={`dialog__description`}>
            <label  className={`sub-heading-title-req req margBottom10`}>Select Sector</label>
        <AllMultiSelect
        options={sectors.filter(fl=>fl.value!=="")}
        defaultValue={[]}
        isMulti
        styles={ {
          input: provided => ({
            ...provided,
            color: '#fff'
          })
        ,
        control: (defaultStyles) => ({
          ...defaultStyles,
          backgroundColor: "#162E3E",
          width: '17rem',
          boxShadow: "none",
        })
      }
      }
        isSearchable
        components={{ Option, MultiValue }}
        onChange={handleChangeSector}
        allowSelectAll={true}
        value={optionSelectedSector}
        placeholder={`Select Sector`}

      />
      </div>
      </div>
:<></>}
                      {reportDownloadErr && (
                  <div className='flexRow justifyContentCenter alignItemsCenter' style={{marginTop:"1.5rem",width:"100%"}}>
                    <p style={{width:"100%"}} className='listErrorMessagesCls'>{reportDownloadErr}</p>
                  </div>
                )}

                <button type="button" onClick={onDownloadReportFinal} disabled={state.disabled} className="btn btn-info mt-4" style={{ color: "white",width:"100%" }}>

                  { isDownloading?<div className='flexRow justifyContentCenter alignItemsCenter'><img src={LoadingIn}style={{marginRight:'5px',width:'35px',height:'35px'}} alt="Loading" /> Processing...</div>:<span><span><img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} /></span> Download</span>}
                </button>

              </div>
</div>
                </div>
            </div>
          </div>
        </div>
      }


      {
      t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
      }
    </div>
  )
}
