import React from "react";
import Select from "react-select";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    borderColor: "#fff",
    border: "0.7px solid #ffff",
    borderRadius: "3px",
    backgroundColor: "#162c3b",
    width:"13rem"
  }),
  input: (styles) => ({ ...styles,textAlign:"left",color:"#fff" }),
  placeholder: (styles,state) => ({ ...styles, textAlign: "left",color:  state.isFocused ? '#6e7c84' : '#fff' }),
  singleValue: (styles) => ({ ...styles,textAlign:"left",color:"#fff" }),
  options: (styles) => ({...styles,position:"relative",textAlign:"left"}),
  indicatorSeparator: (provided) => ({ ...provided,display: 'none'}),
  menuList: (provided) => ({ ...provided,textAlign: 'left'}),
};

const CustomSelect = (props) => {
  const { options,placeHolder,onChange } = props;
  return (
    <div className="selectWrapper">
      <Select
        defaultValue={null}
        options={options}
        styles={colourStyles}
        placeholder={placeHolder}
        onChange={onChange}
        isClearable={true}
        isSearchable={true}
      />
    </div>
  );
};

export default CustomSelect;
