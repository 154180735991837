/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import "../../../assets/styles/controlpanel.scss";
import { NGODatePicker } from "../../../common"
import { CONTROL_PANEL_VIEWS } from "../../../utils/configs";
import { ControlPanelCaterer } from "./Caterer";
import { ControlPanelCatering } from "./Catering";
import { ControlPanelOperations } from "./Operations";
import { DFPAPIEndpoint } from "../../../utils/axios";
import moment from "moment";

import VIEW_ICON from "../../../assets/dashboard.svg";
import OPEN_ICON from "../../../assets/controlpanel.svg";
import MONITOR_ICON from "../../../assets/icons/calendar.svg";
import GIT_ICON from "../../../assets/icons/calendar.svg";

const defaultState = {
  showOpenDateDropDown: null,
  showSelectViewDropDown: null,
  selectedViewId: 'catering',
  filteredDate: new Date()
}

export const ControlPanelInner = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ ...defaultState });
  const showDropDownViewsPanel = (e) => {
    e.stopPropagation();
    setState((_) => ({ ..._, showOpenDateDropDown: false, showSelectViewDropDown: true }))
  };
  const showDropDownOptionsPanel = (e) => {
    e.stopPropagation();
    setState((_) => ({ ..._, showOpenDateDropDown: true, showSelectViewDropDown: false, filteredDateTemp: _.filteredDate }))
  };
  const handleDocumentClick = (e) => {
    e.stopPropagation();
    setState((_) => ({ ..._, showOpenDateDropDown: false, showSelectViewDropDown: false, filteredDateTemp: null }))
  }

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick)
    return () => {
      document.removeEventListener("click", handleDocumentClick)
    };
  }, [])
  useEffect(() => {
    fetchFlightPlan()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filteredDate])
  const handleStopProp = (e) => {
    e.stopPropagation();
  }
  const handleViewChange = (e) => {
    e.stopPropagation();
    setState((_) => ({ ..._, showSelectViewDropDown: false, selectedViewId: e.target.value }))
  }
  const handleDataChange = (e) => {
    setState((_) => ({ ..._, filteredDateTemp: e.target.value }))
  }
  const fetchFlightPlan = async () => {
    if (state.selectedViewId === CONTROL_PANEL_VIEWS[1] || state.selectedViewId === CONTROL_PANEL_VIEWS[2]) {
      const date = moment(state.filteredDate).format('YYYY-MM-DD')
      const plans = await DFPAPIEndpoint.get(`operations/flightPlans/dates/${date}T00%3A00%3A00%2B05%3A30`)
      setState((_) => ({ ..._, flightPlans: plans.flightPlan }))
    }
  }
  const handleDateOpen = () => {
    setState((_) => ({ ..._, filteredDate: _.filteredDateTemp || _.filteredDate, showOpenDateDropDown: null }))
  }
  return (
    <div className="ctrl-container flexCol full">
      <section className="ctrl-card flexCol full">
        <div className="flexRow space-btn ctrl-caption">
          <span>{t('pages.controlPanel.title')}</span>
          <span>{t(`pages.controlPanel.views.${state.selectedViewId}.titleView`)}</span>
        </div>
        <div className="ctrl-filters">
          <div className="filterItem">
            <div className="fltrItmContent">
              <div className="viewBtnBlk">
                <button className={"viewBtn " + (state.showSelectViewDropDown ? 'show' : '')}>
                  <div className="fltrItmIconBlk" onClick={showDropDownViewsPanel}>
                    <img src={VIEW_ICON} alt="viewIcon" />
                  </div>
                  {t("pages.controlPanel.buttons.views")}
                </button>
              </div>
              {
                state.showSelectViewDropDown && (
                  <div className={"controlViewsPanel "} onClick={handleStopProp}>
                    <div className="dropdown">
                      <div className="flexCol">
                        <div className="item-button viewBtn">
                          <select className="select-input" value={state.selectedViewId} onChange={handleViewChange}>
                            {
                              CONTROL_PANEL_VIEWS.map(viewId => {
                                return <option value={viewId} key={viewId}>{t(`pages.controlPanel.views.${viewId}.title`)}</option>
                              })
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
            <div className="fltrItmFoot">
              <small>{t(`pages.controlPanel.sections.layout`)}</small>
            </div>
          </div>
          <div className="devider"></div>
          <div className="filterItem">
            <div className="fltrItmContent">
              <div className="openBtnBlk">
                <button className={"openBtn " + (state.showOpenDateDropDown ? 'show' : '')}>
                  <div className="fltrItmIconBlk" onClick={showDropDownOptionsPanel}>
                    <img src={OPEN_ICON} alt="openIcon" />
                  </div>
                  {t("pages.controlPanel.buttons.date")}
                </button>
              </div>
              {
                state.showOpenDateDropDown && (
                  <div className={"controlViewsPanel "} onClick={handleStopProp}>
                    <div className="dropdown">
                      <div className="flexCol">
                        <NGODatePicker
                          isClearable={false}
                          value={state.filteredDateTemp}
                          placeholder={t('effectiveDate')}
                          placeholderText={t('datePickerPlaceholder')}
                          onChange={handleDataChange} />
                        <button className="item-button openBtn" onClick={handleDateOpen}>
                          <span>{t("pages.controlPanel.buttons.open")}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
            <div className="fltrItmFoot">
              <small>{t(`pages.controlPanel.sections.filters`)}</small>
            </div>
          </div>
        </div>
        <div className="flexCol full-flex">
          <ControlPanelCaterer
            selectedDate={state.filteredDate}
            show={state.selectedViewId === CONTROL_PANEL_VIEWS[0]} />
          <ControlPanelCatering
            selectedDate={state.filteredDate}
            flightPlans={state.flightPlans}
            show={state.selectedViewId === CONTROL_PANEL_VIEWS[1]} />
          <ControlPanelOperations
            selectedDate={state.filteredDate}
            flightPlans={state.flightPlans}
            show={state.selectedViewId === CONTROL_PANEL_VIEWS[2]} />
        </div>

        <div className="ctrl-card-footer">
          <div className="item viewType">
            <img src={MONITOR_ICON} alt="monitorIcon" />&nbsp;<span>{t(`pages.controlPanel.views.${state.selectedViewId}.title`)}</span>
          </div>
          <div className="item gitVersion">
            <img src={GIT_ICON} alt="branchIcon" />&nbsp;<span>1.0</span>
          </div>
        </div>
      </section>
    </div>
  );
}; 