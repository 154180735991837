import React from 'react';
import LoadingIn from '../assets/loadingIn.gif';
import LoadingGIF from '../assets/loading.gif';


export const Loading = (props) => {
  const isLabel = Boolean(props.label);
  const imgStyle = {
    width: props.size || '50px',
    height: props.size || '50px',
  };

  return (
    <div className={`${isLabel ? 'flexRow' : 'flexRow'} justifyContentCenter alignItemsCenter full-flex ${props.className || ''}`}>
      <img src={imgStyle.width==='60px'?LoadingGIF:LoadingIn} className='page-loading' style={imgStyle} alt="Loading" />
      {
        isLabel && <label>{props.label}</label>
      }
    </div>
  );
};