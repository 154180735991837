


/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useState,useEffect } from "react";
import {translationLength } from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import {DFPAPIEndpointGalley } from "../../../utils/axios";
import '../../../assets/styles//styles.scss';
import '../../../assets/styles/modal.scss';
import '../../../assets/styles/styles.scss';
import LoadingGIF from '../../../assets/loadingIn.gif';
import minus from '../../../images/icons/minusgaly.png';
import plus from '../../../images/icons/plus.png';
import galley1akasa from '../../../assets/images/galley1akasa.png';
import galley2akasa from '../../../assets/images/galley2akasa.png';
import galley3akasa from '../../../assets/images/galley3akasa.png';
import { galleyComponentData5,galleyComponentData6,galleyComponentData7} from "../../../utils/configs";
import { _ } from 'ag-grid-community';
import usePermissions from "../../../utils/userPermissions";
import { GalleyLoadingModal } from './galleyLoadingModal';
const EditFormFields = [
  {label: 'ID', attribute: 'trolleyNo', type: 'text', disabled: false, },
  {key: 1, label: 'Trolley Items', attribute: 'trolleyItem', name: 'trolleyItem', type: 'selectSearch'
        , defaultValue: ''
        , value: ''
        , className: 'select-search-cls'
        , options: []
        , multiple: true
        , search: true
        , autoComplete: 'on'
        , autoFocus: false
        , disabled: false, placeholder: 'Items'
    }
];


export const  StandardGalleyLoadingAka=() =>{
  const { t } = useTranslation();
  const [toggleState, setToggleState] = useState(null);
  const [galleyIndex, setGalleyIndex] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [initTab,setInitTab]=useState(null);
  const [airCraftLoadingSel,setAircraftLoadingSel]=useState({model:'',galley:'',businessclassName:false,economy:false,premEconomy:false})
  const [ selectedGalley, setSelectedGalley ] = useState({})
  const [galleyDetails, setGalleyDetails] = useState([]);
  const [aircraftOptions, setAircraftOptions] = useState({});
  const [galleyArr, setGalleyArr] = useState([]);
  const [galleyCarts, setGalleyCarts] = useState([]);
  const [galleyCartsDet, setGalleyCartsDet] = useState([]);
  const [cartsStatus,setCartsStatus]=useState({fullCart:0,halfCart:0,smu:0,stowage:0});
  const [cartsGalleyLoading, setCartsGalleyLoading] = useState([]);
  const [galleyItems,setGalleyItems]=useState([]);
  const [galleyLoadingMessage,setGalleyLoadinMessage]=useState('');
  const [saveGalley,setSaveGalley]=useState(false);
  const [saveAlert,setSaveAlert]=useState({action:false,index:null,cancel:false});
  const [trolleyItems,setTrolleyItems]=useState([]);
  const [editGalley,setEditgalley]=useState({});
  const [showAddTrolley,setShowAddTrolley]=useState({trolleyNo:""});
  const [boxes, setBoxes] = useState([]);
  const [cartsCheck,setCartsCheck]=useState({fullCart:0,halfCart:0,smu:0,stowage:0});
  const [imageGalley,setImageGalley]=useState(null);
  const [galleyBoxes, setGalleyBoxes] = useState([]);
  const [titleEdit, setTitleEdit] = useState('Update Galley Details');
  const [displayItems, setDisplayItems] = useState([]);
  const [cartsCon,setCartsCon]=useState([]);
  const [greaterCarts,setGreaterCarts]=useState({});
  const [cartsMax,setCartsMax]=useState([]);
  const [boxId, setBoxId] = useState(null);
  const [save,setSave]=useState(false);

  const { hasPermission: createGalleyPlan } = usePermissions('create', "configuration.fsc.galley-design");
  const { hasPermission: editGalleyPlan } = usePermissions('edit', "configuration.fsc.galley-design");
  const { hasPermission: deleteGalleyPlan } = usePermissions('delete', "configuration.fsc.galley-design");
  const { hasPermission: viewDetailsGalleyPlan } = usePermissions('view', "configuration.fsc.galley-design");
  const getGalleyLists=(gl)=>{
    let galleyNames=[]
      if(gl.length){
      galleyNames = gl.reduce(
      (acc, item,index) =>[
      ...acc,
      { key: index + 1, label: item.name, value: item.name },

      ],
      [{ key: 0, label: "Select Galley", value: "" }]
      );
      setAircraftOptions((prev)=>{return {...prev,galleyNames}});
      }
  }
  useEffect(() => {
    fetchAirtcraftsType();
    fetchGalleyCatergories();
  }, [])

  useEffect(() => {
    if (save) {
      const saveGalleyConfig = async () => {
   if(true){
    const {id}=selectedGalley
    let body={}
    if(galleyArr.find((ar=>ar.name===(galleyIndex||initTab)))){
    const gId=galleyArr.find((ar=>ar.name===(galleyIndex||initTab)))._id
    body={
    "aircraftTypeId":id,
    "galleyId": gId,
    "carts": getCartsToSave()
    }

    }
     try {
          const resp = await DFPAPIEndpointGalley.post(`galley/loading/Plan/save`, body);
          if (resp.success) {
            setSaveGalley(true);
            setTabColor();
            setBoxId(null);
            const resSave = await fetchAirtcraftsType();
            if (resSave) {
              setGalleyDetails(resSave.items);
            }
            setGalleyLoadinMessage(resp.message);
            setSaveAlert((prev) => ({ ...prev, action: false, cancel: true }));
            setTimeout(() => {
              setSaveGalley(false);
            }, 4000);
          }
          if (!resp.success) {
            setGalleyLoadinMessage(resp.message.splice(0, 8));
          }
        } catch (error) {
          setBoxId(null);
          setGalleyLoadinMessage(error.message);
        }
   }

      };
      const timeoutId = setTimeout(saveGalleyConfig, 1500);

      return () => {
        clearTimeout(timeoutId);
        setSave(false);
      };
    }
  }, [greaterCarts,save]);



  useEffect(() => {
    const objName=galleyBoxes.find(gb=>gb.id===boxId)
  if(objName&&objName?.name){
    if(boxes.find(bx=>bx.boxId===boxId)){
    const bxObj=  boxes.find(bx=>bx.boxId===boxId)
      bxObj['code']=objName.name
    }
  }
  }, [galleyBoxes,boxes])


  useEffect(() => {
    if(saveAlert.index&&!saveAlert.cancel){
      setToggleState(saveAlert.index);
      setGalleyIndex(saveAlert.index)
    }
  }, [saveAlert])


  useEffect(() => {
    if(cartsCon&&(galleyIndex||initTab)){
       const cartsPr=cartsCon?.find(fx=>{
      if (galleyIndex === null) {
        return fx.index === initTab;
      } else {
        return fx.index === galleyIndex;
      }
    })
    setCartsMax(cartsPr)
    setGreaterCarts({});
    }

  }, [galleyIndex,initTab,boxes,trolleyItems])



  useEffect(() => {
    if(boxes.length && imageGalley.length){
     const imageName= boxes.map(bx=> bx.imageGalley)
     if(imageName[0]!==imageGalley){
      setBoxes([])
     }
    }
  }, [boxes,galleyIndex,imageGalley])


  useEffect(() => {
    verifyCartsLimit()
  }, [boxes,galleyIndex,trolleyItems,editGalley])




  useEffect(() => {
    if(boxes&&boxes.length){
      const updatedB = galleyBoxes.map(bItem => {
        const matchingA = boxes.find(aItem => aItem.boxId === bItem.id);

        if (matchingA && matchingA.code === "" && bItem.name !== "") {
          return { ...bItem, name: "" };
        }

        return bItem;
      });
     setGalleyBoxes(updatedB);
    }
    else {
      const updatedB = galleyBoxes.map(bItem => ({ ...bItem, name: "" }));
      setGalleyBoxes(updatedB);
    }
  }, [boxes,galleyIndex,airCraftLoadingSel])




useEffect(() => {
  if(boxes&&boxes.length){
    for (const kItem of galleyBoxes) {
    const matchingLItem = boxes.find(lItem => lItem.code === kItem.name);
    if (!matchingLItem&&!boxId) {
    kItem.name = "";
    }
    }
  }
}, [boxes,galleyBoxes])

  useEffect(() => {
    if(galleyDetails){
      const actModel= galleyDetails.reduce(
      (acc, item, index) => [
      ...acc,
      { key: index + 1, label: `${item.model}`, value:item.model },
      ],
      [{ key: 0, label: "Select Aircraft", value: "" }]
      );

      if(actModel.length ){
      setAircraftOptions({model:actModel});

      }
    }
  }, [galleyDetails])


  useEffect(() => {
      const cartObj=cartsGalleyLoading.find(f=>f.index===galleyIndex||initTab)
      if(cartsGalleyLoading && galleyCartsDet &&cartObj){
        let configuredCarts=[];
     const k=  galleyCartsDet.filter(f=>(f.id==='full'||f.id==='half')).map(m=>m.cartQuantityAllots)
     let fullCartCount = 0;
     let halfCartCount = 0;

     for (let i = 0; i < k.length; i++) {
       for (let j = 0; j < k[i].length; j++) {
         const cartType = k[i][j].cartType;
         if (cartType === 'fullCart') {
           fullCartCount++;
         } else if (cartType === 'halfCart') {
           halfCartCount++;
         }
       }
     }

         configuredCarts = galleyCartsDet.map(a => ({
          cart: a.name,
          cartQuant: (a.name === "Full Cart") ? fullCartCount : (a.name === "Half Cart") ? halfCartCount : a.cartConfigCount.typesConfiguredQty
        })).reduce((acc, item) => {
          const existingItem = acc.find(obj => obj.cart === item.cart);
          if (existingItem && existingItem.cart!=='Full Cart' && existingItem.cart!=='Half Cart') {
            existingItem.cartQuant += item.cartQuant;
          } else {
            acc.push({ cart: item.cart, cartQuant: item.cartQuant });
          }
          return acc;
        }, []);


        galleyBoxes.forEach((obj1) => {
          const bName = galleyCartsDet.find((obj2) => obj2.boxId === obj1.id);
          if (bName) {
            obj1.name = bName.code;
          }
        });
        const cartsFilled = configuredCarts.reduce((obj, item) => {
          let key = item.cart.replace(/\s+/g, '');
          key = key.toLowerCase()
          if (key === 'fullcart') key = 'fullCart';
          if (key === 'halfcart') key = 'halfCart';
          if (key === 'smu') key = 'smu';
          if (key === 'misc.stowage') key = 'stowage';
          obj[key] = item.cartQuant;
          return obj;
        }, {index:(galleyIndex||initTab)});

      setCartsStatus(cartsFilled)
      getCartDetails();
      }
      else{
      setCartsStatus({fullCart:0,halfCart:0,smu:0,stowage:0})
      }

  }, [cartsGalleyLoading,galleyCartsDet,galleyArr,saveGalley])

const verifyCartsLimit=()=>{
  let cartLenSmu=0
  let cartLenStowage=0
  let cartLenHalf=0
  let cartLenFull=0

  if(boxes && (galleyIndex||initTab)){
    boxes.forEach(bx=>{
      const {id,cartQuantityAllots}=bx;
      if(id==='smu'){
       cartLenSmu+=bx.cartQuantityAllots.length
    }
    if(id==='stowage'){
      cartLenStowage+=bx.cartQuantityAllots.length
   }
    cartQuantityAllots.forEach(caRt=>{
      if (caRt.cartType === 'fullCart') {
        cartLenFull++;
      } else if (caRt.cartType === 'halfCart') {
        cartLenHalf++;
      }
    })
  })
}
if(boxes.length===0){
  setCartsCheck((_)=>{
    const obj={..._};
    for (const key in obj) {
      obj[key] = 0;
    }
    return obj;
  })

}
setCartsCheck((_)=>{return{..._,smu:cartLenSmu,fullCart:cartLenFull,halfCart:cartLenHalf,stowage:cartLenStowage}})
}

  const setTabColor=()=>{
    galleyArr.forEach((el) => {
      if((initTab===galleyIndex&&(el.name===initTab||el.name===galleyIndex))||(!galleyIndex&&(el.name===initTab))||(initTab!==galleyIndex&&(el.name===galleyIndex)))
      {el.saved=true}
    });
    getGalleyTabs();
    getCartDetails();
  }
  const onClickBox=(e,id)=>{
  if(!e.target.alt && e.target.tagName !== 'INPUT'){
if(boxes.find(bx=>bx?.boxId===id)===undefined){
    setShowAddTrolley({trolleyNo:id});
      const galleyObj=boxes.find(b=>b.boxId===id);
      if(galleyObj){
      setEditgalley(galleyObj)
      }
      let editAddFieldsDep=[];
     galleyItems&& galleyItems.map( (fieldValue, index) => {
      editAddFieldsDep.push({ key: fieldValue.customerId, name: fieldValue.name, value: fieldValue.name, label: fieldValue.name })
      return fieldValue;
      });
      setTrolleyItems(editAddFieldsDep);

      EditFormFields.map( editRow => {

      if (editRow.attribute === 'trolleyItem' ) {
      editRow.options = trolleyItems;
      }
      return editRow;
      });
}
  }
  }

  const convert=(arr) =>{
    const boxIdMap = {};
    return arr.reduce((newArr, item) => {
      const data = boxIdMap[item.boxId];
      if (data) {
        data.cartQuantityAllots = data.cartQuantityAllots.concat(item.cartQuantityAllots);
      } else {
        const copy = { ...item };
        boxIdMap[item.boxId] = copy;
        newArr.push(copy);
      }
       return newArr
    }, []);
  }


  const fetchGalleyCatergories=async()=>{
    try {
      const resp = await DFPAPIEndpointGalley.get(`galley/items/category`);
        if(resp.success){
        setGalleyItems(resp.items)
        }
        }
        catch (error) {

        }

  }
    const filterData = {
      action: 4,
      collection: '',
      filterOperation: 'or',
      filters: [],
      limit: 10,
      page: 1,
      searchParam: ''
    };
    const fetchAirtcraftsType = async (page = 1, perPage = 50) => {
      filterData.page = page;
      filterData.limit = perPage;
      filterData.filters =  [
        {
            aircraftFor: "akasa"
        }
    ];
      filterData.searchParam = searchKey;
      try{
        const res = await DFPAPIEndpointGalley.post('galley/aircraftTypes/process', filterData);
        if(res){
        setGalleyDetails(res.items);
        return res;
        }
      }
      catch(err){

      }

    }
        const onCloseAddGalley=()=>{
          setShowAddTrolley({trolleyNo:""});
          setDisplayItems([]);
          setTitleEdit("Update Galley Details")
          setEditgalley({})
        }

        const getCartType = (cart) => {
          const cartDescriptions = {
            'fullCart': 'Full Cart',
            'halfCart': 'Half Cart',
            'smu': 'SMU',
            'stowage': 'Misc. Stowage',
          };

          return cartDescriptions[cart] || '';
        };

const getCartsCategory=()=>{
    const final = boxes.map(box => {
    return {
    cartId: box.cartType,
    galleyCartCode: box.trolleyNo,
    itemsCategory: box.trolleyItems.map(item => {
    return {
      categoryId: galleyItems.find(i => item.name === i.name)._id
    }
    })
    }
    });
  return final
}

const getItemsCart=(val)=>{
 const resp= val?.map((item,index) => {
    return {
      typeId:index+1,
      name: galleyItems?.find(i => i.name === item?.name)?.name
    }
    })
    return resp;
}


const getBoxName=()=>{
  const objName=galleyBoxes.find(gb=>gb.id===showAddTrolley.trolleyNo)?.name
 return objName
}
const getCartsId = (option) => {
  const { cartOptions, cartType } = option;

  if (cartOptions === '0' || cartOptions === '1') {
    return galleyCartsDet.find(ct => ct?.id === cartType)?._id;
  } else if (cartOptions === '2' && (cartType === 'fullCart' || cartType === 'halfCart')) {
    return galleyCartsDet.find(ct => ct?.id === (cartType === 'fullCart' ? 'full' : 'half'))?._id;
  }

  return null;
};


      const getCartQunatityAllots=(updated)=>{
        return [
          {
              code: generateRandomVariable()||'897GH',
              cartType: updated.cartType?updated.cartType:updated.cartOptions==='1'?'smu':'stowage',
              typesOfItems:getItemsCart(updated.cartItems)
          }
      ]
      }

      const onSubmitGalleyBoxes=(updated)=>{
        const{fullCart,halfCart,smu,stowage}=updated
       const res= Object.values(cartsGalleyLoading.reduce((acc, el) => {
          el.fullCart=fullCart;
          el.halfCart=halfCart;
          el.smu=smu;
          el.stowage=stowage;
          el.index=galleyIndex||initTab
          acc[el.index] = el;
          return acc;
        }, {}));
      setCartsGalleyLoading([...res])
      const galleyObj=boxes.find(b=>b.boxId===showAddTrolley.trolleyNo);
      if(updated && !Boolean(galleyObj)&&!Object.keys(editGalley).length){
      setBoxes(( currentValue ) => [ ...currentValue, {
        code: getBoxName(),
        id:(updated.cartOptions==='2'&&updated.cartType==='fullCart')?'full':(updated.cartOptions==='2'&&updated.cartType==='halfCart')?'half':updated.cartOptions==='1'?'smu':'stowage',
        name: (updated.cartOptions==='2'&&updated.cartType==='fullCart')?'Full Cart':(updated.cartOptions==='2'&&updated.cartType==='halfCart')?'Half Cart':updated.cartOptions==='1'?'SMU':'Misc. Stowage',
        _id:getCartsId(updated),
        quantity:getCartQunatityAllots(updated).length,
        boxId: `${showAddTrolley.trolleyNo}`,
        cartQuantityAllots:getCartQunatityAllots(updated),
        index:galleyIndex||initTab,
        imageGalley
    } ])

      setShowAddTrolley({trolleyNo:""});
      }
      if(galleyObj && !Object.keys(editGalley).length){
    const arr=  convert([...boxes,{
      code: getBoxName(),
      id:(updated.cartOptions==='2'&&updated.cartType==='fullCart')?'full':(updated.cartOptions==='2'&&updated.cartType==='halfCart')?'half':updated.cartOptions==='1'?'smu':'stowage',
      name: (updated.cartOptions==='2'&&updated.cartType==='fullCart')?'Full Cart':(updated.cartOptions==='2'&&updated.cartType==='halfCart')?'Half Cart':updated.cartOptions==='1'?'SMU':'Misc. Stowage',
      quantity:getCartQunatityAllots(updated).length,
      _id:getCartsId(updated),
      boxId: `${showAddTrolley.trolleyNo}`,
      cartQuantityAllots:getCartQunatityAllots(updated),
      index:galleyIndex||initTab,
      imageGalley
  } ])

setBoxes(arr);

     setShowAddTrolley({trolleyNo:""});
      }
      if(Object.keys(editGalley).length&&updated){
        const replaceCart={
          code: updated.code,
          cartType: updated.cartType,
          typesOfItems: getItemsCart(updated.cartItems)
      }


        function replaceObject(array, searchCode, replacementObj) {
          array.forEach(obj => {
            obj.cartQuantityAllots.forEach((subObj, index) => {
              if (subObj.code === searchCode) {
                obj.cartQuantityAllots[index] = replacementObj;
              }
            });
          });
        }
        replaceObject(boxes, editGalley.code, replaceCart);
        setShowAddTrolley({trolleyNo:""});
        setEditgalley({});
      }
      }

      const showCartItems=(item,id)=>{
      setShowAddTrolley({trolleyNo:id});
      setTitleEdit("Cart Items")
      setDisplayItems(item);
      let editAddFieldsDep=[];
      galleyItems&& galleyItems.map( (fieldValue, index) => {
       editAddFieldsDep.push({ key: fieldValue.customerId, name: fieldValue.name, value: fieldValue.name, label: fieldValue.name })
       return fieldValue;
       });
       setTrolleyItems(editAddFieldsDep);

       EditFormFields.map( editRow => {

       if (editRow.attribute === 'trolleyItem' ) {
       editRow.options = trolleyItems;
       }
       return editRow;
       });
      }


      const showCartDesign=(crt,id)=>{
      if(crt.length){
      const cartStat=crt.map((c,index)=>(<>
      <div className="cart-display" title="Edit Cart" onClick={(e) => onClickEditCart(e, id, c.code)}>
      <div key={index} className="flexRow"  style={{ padding: '0.5rem' }}>
      <u className="cart-type">{getCartType(c.cartType)}</u>
      <div className="flexRow"  title="Remove Cart" style={{ marginLeft: 'auto' }} key={index}>
      {/* <img className="galley-icons" src={editGalleyItem}  alt='GalleyEdit' /> */}
      <img className="galley-icons" src={minus}  onClick={(e) => onClickRemoveCart(e, id, c.code)} alt='GalleyRemove' />
      </div>
      </div>
      <br/>
      {getTrolleyItems(c,id)}
      </div>

      </>))
      return cartStat
      }

      }

      const getTrolleyItems=(tr,id)=>{
        if(tr){
        const listItems =
        tr.typesOfItems?.map((i,index) => (
        <li className="box-items" key={index}>{i.name}</li>
        ))
        const count=listItems?.length>1?'items':'item'
        return (<><div className="list-items"  title='View Cart'  style={{lineHeight:'1.5rem',color:"#ffffff",display:'flex',fontWeight:'bold',justifyContent:'center'}} >{`${listItems?.length} ${count}`}</div>
        </>
        );
        }


        }
    const getCartsToSave=()=>{
        const idsBoxes = boxes.map(obj => obj._id);
        const objAdd = galleyCartsDet.filter(obj => !idsBoxes.includes(obj._id));
        boxes.push(...objAdd);

        const boxesUpdated = boxes.map(item2 => {
          const match = galleyBoxes.find(item1 => item1.id === item2.boxId);
          if (match) {
            return { ...item2, code: match.name };
          }
          return item2;
        });
        return boxesUpdated;
      }

      const generateRandomVariable=()=> {
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var variable = '';

        for (var i = 0; i < 5; i++) {
          var randomIndex = Math.floor(Math.random() * characters.length);
          variable += characters[randomIndex];
        }

        return variable;
      }

      const onSaveGalleyLoading=async()=>{
        setSave(true);
        const cartsPr=cartsCon.find(fx=>{
              if (galleyIndex === null) {
                return fx.index === initTab;
              } else {
                return fx.index === galleyIndex;
              }
            })
            setCartsMax(cartsPr)


for(let key in cartsPr){
  if(cartsCheck.hasOwnProperty(key)){
    if(cartsCheck[key]>cartsPr[key]){
      setGreaterCarts((_)=>{return{..._,[key]:cartsCheck[key]}})
    }
    else if(cartsCheck[key]===cartsPr[key]){
      setGreaterCarts((_)=>{
        const updatedCart={..._};
        delete updatedCart[key];
        return updatedCart
      })
    }
  }
}
      }


  useEffect(() => {
    if(galleyDetails){
        const actModel= galleyDetails.reduce(
        (acc, item, index) => [
        ...acc,
        { key: index + 1, label: `${item.model}`, value:item.model },
        ],
        [{ key: 0, label: "Select Aircraft", value: "" }]
        );

        if(actModel.length ){
        setAircraftOptions({model:actModel});

        }
    }
  }, [galleyDetails])

  useEffect(() => {
    if(galleyIndex||initTab){
      setBoxId(null);
      setBoxes(galleyCartsDet.filter(item => item.index && item.imageGalley))
      const specGal=galleyDetails.find((g,index)=>g.model===selectedGalley.model)?.galleys
      setGalleyCarts(specGal);
      let index = galleyArr.find(x => x.name === (galleyIndex || initTab))?.name;
      getGalleyTabs();
      if(index&&index.length>0){
  if (boxes.length) {
        if (index.includes('G1')) {
          setGalleyBoxes(galleyComponentData7);
          setImageGalley(galley3akasa);
        } else if (index.includes('G2')) {
          setGalleyBoxes(galleyComponentData6);
          setImageGalley(galley2akasa);
        } else if (index.includes('G4')) {
          setGalleyBoxes(galleyComponentData5);
          setImageGalley(galley1akasa);
        } else {

        }
      } else {
        if (index.includes('G1')) {
          setGalleyBoxes(galleyComponentData7.map(item => ({ ...item, name: "" })));
          setImageGalley(galley3akasa);
        } else if (index.includes('G2')) {
          setGalleyBoxes(galleyComponentData6.map(item => ({ ...item, name: "" })));
          setImageGalley(galley2akasa);
        } else if (index.includes('G4')) {
          setGalleyBoxes(galleyComponentData5.map(item => ({ ...item, name: "" })));
          setImageGalley(galley1akasa);
        } else {

        }
      }
      }

    }
  }, [galleyIndex,initTab])

  useEffect(() => {
    if(galleyDetails&&galleyDetails.length){
      const specGal=galleyDetails.find((g,index)=>g.model===selectedGalley.model)?.galleys
      setGalleyCarts(specGal)
    }
  }, [saveGalley,galleyDetails])

  useEffect(() => {
    setBoxes(galleyCartsDet.filter(item => item.index && item.imageGalley))
  }, [galleyIndex,initTab,galleyCartsDet])

  useEffect(() => {
    if (galleyCarts && galleyCarts.length) {
      galleyCarts?.forEach((gc) => {
          if (gc.name === (galleyIndex || initTab)) {
            setGalleyCartsDet(gc.carts);
          }
      });
    }

  }, [galleyCarts,galleyIndex,initTab,saveGalley])

  useEffect(() => {
    if(galleyCartsDet){
      let cartsCount=galleyCartsDet.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.quantity }), {});
    if(Object.keys(cartsCount).length){
    setCartsGalleyLoading([{fullCart:cartsCount['Full Cart'],halfCart:cartsCount['Half Cart'],smu:cartsCount['SMU'],stowage:cartsCount['Misc. Stowage'],index:(galleyIndex||initTab)} ])
    }
    const indices = [...new Set(cartsGalleyLoading.map(item => item.index))];
    indices.forEach(ele => {
      setCartsGalleyLoading(( currentValue ) => [ ...currentValue, cartsGalleyLoading.findLast((element) => element.index === ele) ])
    })
    }
  }, [galleyCartsDet,saveGalley])


  useEffect(() => {
    if(galleyArr&&galleyArr.length){
     setInitTab(galleyArr[0].name)
    galleyArr.forEach((el) => {
      el.saved = false;
    });
   getGalleyTabs();

   const newCartsCon = galleyArr.map((gl) => {
    const userDefinedCarts = gl.galleyCartConfig?.userDefined?.map((ct) => {
      return { ...ct };
    });

    return {...userDefinedCarts, index: gl.name };
  });
  const resArr=[];

  newCartsCon.forEach((item, index) => {
  const modObj = {};
    for(let c in item){
      if (item.hasOwnProperty(c)) {

     if(item[c].hasOwnProperty('full')){

      modObj['fullCart'] = item[c].full;

     }
     else if(item[c].hasOwnProperty('half')){
      modObj['halfCart'] = item[c].half;
     }
     else if(item[c].hasOwnProperty('smu')){
      modObj['smu']=item[c].smu;
     }
    else if(item[c].hasOwnProperty('stowage')){
      modObj['stowage']=item[c].stowage;
    }
    else if(typeof item[c]==='string'){
      modObj['index']=item[c];
    }
      }
    }
    resArr.push(modObj);
  });
  setCartsCon((_)=>{return[..._,...resArr]})

  }

    setGalleyIndex(initTab);
  }, [galleyArr])


  const onAircraftChange=(e,ac=null)=>{
    setAircraftLoadingSel((prev)=>{return {...prev,model:e.target.value}});
  const galleySelected=galleyDetails.find(gl=>gl.model===e.target.value)

  if(galleySelected){
    setSelectedGalley(galleySelected)
    setGalleyArr(galleySelected.galleys);
    getGalleyLists(galleySelected.galleys);
    toggleTab(galleySelected?.galleys[0]?.name);
  }
  }

const onChangeBoxName=(e,id)=>{

  setBoxId(id);
setGalleyBoxes(galleyBoxes.map(g=>{if(g.id===id){
  g.name=e.target.value
}
return g
}))
}

const onClickAddCart=(e,id)=>{
  if(e.target.alt==="GalleyAdd" && createGalleyPlan){
    setShowAddTrolley({trolleyNo:id});
    setEditgalley({})
    let editAddFieldsDep=[];
   galleyItems&& galleyItems.map( (fieldValue, index) => {
    editAddFieldsDep.push({ key: fieldValue.customerId, name: fieldValue.name, value: fieldValue.name, label: fieldValue.name })
    return fieldValue;
    });
    setTrolleyItems(editAddFieldsDep);

    EditFormFields.map( editRow => {

    if (editRow.attribute === 'trolleyItem' ) {
    editRow.options = trolleyItems;
    }
    return editRow;
    });
  }

}
  const onClickRemoveCart=(e,id,code)=>{

  if(e.target.alt==="GalleyRemove" && deleteGalleyPlan){
  const deleteCart=boxes.find(b=>b.boxId===id).cartQuantityAllots.filter(cr=>cr.code===code)
  if(deleteCart.length){
  boxes.forEach(obj => {
  obj.cartQuantityAllots = obj.cartQuantityAllots.filter(subObj => subObj.code !== deleteCart[0]?.code);
  });
  }
  setBoxes(boxes.filter(obj => obj.cartQuantityAllots.length > 0));
  }
  }

    const onClickEditCart=(e,id,code)=>{
    if(!e.target.alt && editGalleyPlan){
    setShowAddTrolley({trolleyNo:id});
    const editBoxes=boxes.find(b=>b.boxId===id).cartQuantityAllots.filter(cr=>cr.code===code)
    if(editBoxes.length){
    editBoxes
    .forEach((el) => { el.id = id});
    setEditgalley(editBoxes[0])
    }
    let editAddFieldsDep=[];
    galleyItems&& galleyItems.map( (fieldValue, index) => {
    editAddFieldsDep.push({ key: fieldValue.customerId, name: fieldValue.name, value: fieldValue.name, label: fieldValue.name })
    return fieldValue;
    });
    setTrolleyItems(editAddFieldsDep);

    EditFormFields.map( editRow => {

    if (editRow.attribute === 'trolleyItem' ) {
    editRow.options = trolleyItems;
    }
    return editRow;
    });
    }
    }

const showGalleyLoading=()=>{

  return(
  <div className="container-fluid dashboard-summary-container">
      {
        <>
        {/* {!boxes.length?<h6 className="subheading-text-medium-title sub-heading-title-req fontLight text-danger flexRow flex-ctr">Click boxes to configure galley items</h6>:<></>} */}
          <div className='flexRow margBot10 margTop8'>
          <div className='alignItemsCenter'>
          <div className="container-fluid first">
          <div className="row tab-pane">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
          {!airCraftLoadingSel.model.includes('Select Aircraft')&&galleyArr.length!==0?<><a className="d-block image-block">
         {imageGalley&& viewDetailsGalleyPlan && <><img className="Images_galley_ak" src={imageGalley}  alt='Galley'/>
          {
          galleyBoxes
          .map((_,index) => {
          return (
            <>
          <div id={_.id} className="ImageArea sm-scrollbar"
          onClick={(e)=>onClickBox(e,_.id)}
          style={{
          width: _.width,
          height: _.height,
          bottom: _.bottom,
          left: _.left,
          overflowY:'auto'
          }}
          key={index}
          >
<div className="flexRow justifyContentSpaceBetween">
  <label htmlFor={'boxNames'} style={{ position: 'relative', width: '65%' }}>
    <input
      id={'boxNames'}
      name='carrierCode'
      onChange={(e) => onChangeBoxName(e, _.id)}
      value={_.name}
      style={{ borderRadius: '5px', width: '100%',padding:'0.5rem' }}
      type={'text'}
      placeholder={"Id"}
      className='edit_input_galley'
    />
  </label>
  <img
    className={`galley_icons_add ${imageGalley.includes('galley1akasa') ? 'akasaGalleyFull' : 'akasaGalleyHalf'}`}
    src={plus}
    onClick={(e) => onClickAddCart(e, _.id)}
    alt='GalleyAdd'
  />
</div>



          {boxes.map((item,index) => {
          if(item.boxId === _.id&& item.index===(galleyIndex||initTab)){
          return (<>
             <div className='galley_box flexCol' key={index}>
         { showCartDesign(item.cartQuantityAllots,_.id)}
          </div>
          </>
          )
          }
          return null;
          }
          )}
          </div></>
          )
          })

          }
          </>
          }
          </a></>:<div style={{width:'270px',top:'35vh',left:'35vh'}} className="image-block">No Galleys Found !</div>}
          </div>
          </div>
         </div>

      </div>

      </div>
      <div>
      </div>

        </>
      }
      {showAddTrolley?.trolleyNo.length>0 && (
          <GalleyLoadingModal
          key={showAddTrolley?.trolleyNo}
          trolleyName={showAddTrolley?.trolleyNo}
          imageGalley={imageGalley}
          title={titleEdit}
          airlines={'akasa'}
          galleyCarts={galleyCarts}
          editGalley={editGalley}
          galleyCartsDet={galleyCartsDet}
          tab={galleyIndex||initTab}
          trolleyItems={trolleyItems||[]}
          displayItems={displayItems}
          cartsGalleyLoading={cartsGalleyLoading}
          setGalleyCarts={setGalleyCarts}
          onClose={()=>onCloseAddGalley()}
          onSubmit={onSubmitGalleyBoxes}
          />
        )
     }

    </div>)
}

const getGalleyTabs=()=>{

  const res=galleyArr.map((gl,index)=>{
    return( <>
      <button key={index} style={{width:'180px',marginTop:'0.95rem',marginBottom: '1.2rem'}}
      className={(toggleState||initTab)===gl.name &&!gl.saved? "tabs active-tabs" :(toggleState||initTab)===(gl.name||initTab)&&(gl.saved)? "tabs  active-tabs active-tabs-save":gl.saved?"tabs active-tabs-save":"tabs"}
      onClick={() => toggleTab(gl.name)}
      >
      { gl.name}
      </button>
       </>

    )
}
)
return res

}




const getCartDetails=()=>{
  const {fullCart,halfCart,smu,stowage}=cartsStatus;
if(galleyCartsDet){
    return( <>      {Boolean(saveGalley) ?
        <>
          <div  style={{display:'flex',width:'100%',marginTop:'1rem'}} className={ "confirmation_popup_galley " + (!saveGalley ? 'alert_error' : 'alert_success') }>
        {galleyLoadingMessage}
        </div>
        </> : null }
    <div className="flexCol" style={{overflowX:'auto'}}>
      <div className="flex_container_galley">
     <div className="flexCol">
        <div className='galley-carts'>
        <div className='flexCol floatLeft'>
       {cartsMax? <ul  className="ul-class">
        <span className="justifyContentCenter alignItemsCenter margLeft16" style={{textDecoration: 'underline'}}>Galley Details</span> <br/>
        <br/>
        <li className='floatLeft'>{fullCart>0?<span className="tb-tabs">Full Cart</span>:<span>Full Cart</span>}</li>
        <li className='floatRight margLeft10'>{fullCart>0?<><span className="round-border-count">{fullCart}</span><span>&#8725;</span><span className="round-border">{cartsMax['fullCart']}</span></>:<span className="round-border">{fullCart}</span>}</li><br/>
        <br/>
        <li className='floatLeft'>{halfCart>0?<span className="tb-tabs">Half Cart</span>:<span>Half Cart</span>}</li>
        <li className='floatRight margLeft10'>{halfCart>0?<><span className="round-border-count">{halfCart}</span><span>&#8725;</span><span className="round-border">{cartsMax['halfCart']}</span></>:<span className="round-border">{halfCart}</span>}</li><br/>
        <br/>
        <li className='floatLeft'>{smu>0?<span className="tb-tabs">SMU</span>:<span>SMU</span>}</li>
        <li className='floatRight margLeft10'>{smu>0?<><span className="round-border-count">{smu}</span>/<span className="round-border">{cartsMax['smu']}</span></>:<span className="round-border">{smu}</span>}</li><br/>
        <br/>
        <li className='floatLeft'>{stowage>0?<span className="tb-tabs">Misc.Stowage</span>:<span>Stowage</span>}</li>
        <li className='floatRight margLeft10'>{stowage>0?<><span className="round-border-count">{stowage}</span><span>&#8725;</span><span className="round-border">{cartsMax['stowage']}</span></>:<span className="round-border">{stowage}</span>}</li><br/>
        <br/>
        </ul>:<></>}
        </div>
        </div>

</div>
</div>
<button  disable={save} style={{width:'1rem',marginTop:'1rem'}} className="save-button-galley subheading-text-medium-title fontLight" onClick={onSaveGalleyLoading}> {save&&!Object.keys(greaterCarts).length&&!saveGalley?<div className='flexRow justifyContentCenter alignItemsCenter'><img src={LoadingGIF}style={{margLeft:'5px',margTop:'5px',width:'50px',height:'50px'}} alt="Loading" /><span>Saving...</span></div>:saveGalley?<>Saved Successfully</>:<>Save</>}</button>


  </div></>

  )
}

}
const getTabContents=()=>{
    return((toggleState||initTab)&&(galleyIndex||initTab) &&(toggleState||initTab )=== (galleyIndex||initTab)?
    <div className={(toggleState||initTab) === (galleyIndex||initTab) ? "content_default  active-content" : "content_default"}>
      <>
      {showGalleyLoading()}
      </>
    </div>:<></>)

}

  const toggleTab = (index) => {
  setToggleState(index);
  setGalleyIndex(index)

  };
  return (
    <div>
    <div className="flexRow" style={{overflowY: 'auto' }}>
    <div className="bloc-tabs width20Galley column-trolley">
    <div>
    <label className="req mb-2 margBottom10">
    Aircraft Model :
    <br />
    <div className="margTop8 width100">
    {Boolean(aircraftOptions) ? (
    <button className="saleType-div margRight30">
    <select  className={`select-item-ak`} onChange={onAircraftChange} value={airCraftLoadingSel?.model} id="saleTypeId">
    { aircraftOptions.model?.map( (item,index) => (
    <option key={index} value={item.label}>{item.label}</option>
    ))
    }
    </select>
    </button>
    ) : (
    <></>
    )}
    </div>
    </label>
    </div>




    <div className="flexRow width100 margBot10">
                <div className="alignItemsCenter">

                  <div className="bloc-tabs-trolley">
                  {!airCraftLoadingSel.model.includes('Select Aircraft')&&galleyArr&&galleyArr.length?getGalleyTabs():<></>}
                  </div>

                    {!airCraftLoadingSel.model.includes('Select Aircraft')&&galleyCartsDet&&galleyCartsDet.length &&galleyArr.length ?(getCartDetails()):null}
                </div>
              </div>
    </div>
    <div style={{display:'flex',width:'80%',overflowY:'scroll'}}>
    {  getTabContents()}
    </div>
    </div>
    {
    t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
    }
    </div>
  );
}
